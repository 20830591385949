import { Fragment, useState  } from 'react' 
import { __ } from 'src/libs/utilities'
import FormType from './FormType' 

const FieldForm = (props: any) :JSX.Element => {   
    const [values, setValues] = useState( Array.isArray( props.value ) ? props.value : [ ] )
    const onValue = (_value: any, field: string, i: number ) =>
    {
        const val = values.map((v: any, ii:number) => {
            return ii === i ? _value : v 
        }) 
        setValues( val )      
        // console.log( _value, field, props.n, i, val ) 
        props.onChange( _value, field, props.n , i)
    } 
    return <div className={`row data-list ${ props.field.require ? "text-danger" : "" } `}>
        <div className={
            `col-md-4 col-12 text-md-right text-center d-flex flex-column justify-content-center align-items-end`
        }>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='require-sign mr-4 h-100'>
                    {
                        props.field.require 
                            && 
                            <div className='d-flex user-select-none descr px-2 pt-1 bg-danger text-light'>
                                {__("Required field")}
                            </div> 
                    }
                </div> 
                <div>
                    <div className='title'> 
                        { __( props.field.name ) }
                    </div>
                    <div className='descr'>
                        { __( props.field.description ) }
                    </div> 
                </div>
            </div>
                
                      
        </div>
        <div className='col-md-8 col-12 px-0 '>
        {
            Array(props.field.count).fill(0).map( (field: any, i: number) => 
                { 
                    //const values = Array.isArray( props.value ) ? props.value : [ ]
                    return <Fragment key={ field + "_" + i }>
                    { 
                        <div className='d-flex align-items-center w-100' >
                            {
                                props.field.count > 1
                                    ? 
                                    <span className="numm">{i + 1}.</span>
                                    :
                                    <span className="numm">  </span>
                            }
                            <FormType
                                type={ props.field.type }
                                values={ props.values }
                                value={ values[i] }
                                field={ field }
                                m={ props.n }
                                i={ i }
                                className="p-0 m-0"
                                onChange={ (value: any ) =>  onValue(value, field, i) }
                            /> 
                        </div> 
                    }
                    </Fragment>
                }) 
        }  
        </div>
    </div>
    
} 
export default FieldForm