
import { ID, Icon, URL } from "@/libs/interfaces/layouts";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { IErmakMap, IErmakSpace, defaultSpace } from "./interfaces";

export interface IErmakStore {
    currentSpace: IErmakSpace
    currentMap: IErmakMap
    currentMapEdit: IErmakMap
    mapCellSize:number
    isStoreOpen: boolean
}

export const useErmakStore: any = create( 
    devtools( 
        persist<IErmakStore>( 
            (set: any, get: any ) => ({ 
                currentSpace: defaultSpace(),
                currentMap: {} as IErmakMap,
                currentMapEdit: {} as IErmakMap,
                mapCellSize: 90,
                isStoreOpen: false
            }),
            {
                name: 'pe-ermak-storage', 
                storage: createJSONStorage( () => localStorage ), 
            } 
        )
    )
)
