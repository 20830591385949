 import { __ } from '@/libs/utilities'
import { Intent, Tag } from '@blueprintjs/core' 

const TagFilters = (props : any) :JSX.Element => { 
    const setFields = () =>
    {
        props.onChangeField( "onIsFields", !props.isFields )
    }
    const setCriteries = () =>
    {
        props.onChangeField( "onIsCriteries", !props.isCriteries )
    }
    return <div className='d-flex align-items-center flex-wrap'>
        <Tag 
            round 
            className='px-3 py-1 m-1 pointer' 
            minimal={!props.isFields} 
            intent={Intent.WARNING} 
            onClick={setFields} 
        >
            { __( !props.isFields ? "Show Member's extend fields" : "Hide Member's extend fields" ) }
        </Tag>
        <Tag 
            round 
            className='px-3 py-1 m-1 pointer' 
            minimal={!props.isCriteries}
            intent={Intent.WARNING} 
            onClick={setCriteries} 
        >
            { __( !props.isCriteries ? "Show Member's criteries rating" : "Hide Member's criteries rating" ) }
        </Tag>
    </div> 
}  
export default TagFilters