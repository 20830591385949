import defaultTracks from "@/modules/pe-fest-module/data/defaultTracks"
import { ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { Carousel } from "@/libs/useful" 
import { __, setMaxHeightByClass, wordTrim } from "@/libs/utilities" 
import {  useEffect, useRef } from "react"

/* Protopia Ecosystem component */
export interface ITrackDefaultCarouselProps {
    onChoose: (trackData: any) => void
    tracks: ITrack[]
    setIsPopover: (isPopover: boolean) => void
}
const TrackDefaultCarousel = (props: ITrackDefaultCarouselProps): JSX.Element => {

    const myRef = useRef<HTMLDivElement>( null )
    
    const onMouseLeaveHandler = (e: Event) => {
        const domNode: any = myRef.current;
        if (!domNode || !domNode.contains(e.target)) {
            props.setIsPopover(false) 
        } 
    }

    useEffect(() => {
        setTimeout(() =>  document.body.addEventListener("click", onMouseLeaveHandler), 300)
        return () => document.body.removeEventListener("click", onMouseLeaveHandler)
    }, [])

    const afterOffset = () => {
        setMaxHeightByClass("track-card-body")
    }
    const _tracks: ITrack[] = defaultTracks()
    .filter(track => {
        return !props.tracks.map(tr => tr.id).includes(track.id)  
    })
    return <div className=" short-container px-5 py-3" style={{width: "100vw"}} ref={ myRef } >
        <Carousel
            infinite={true}
            height={ 445 }
            width={ 900 }
            count={ 1 }
            transitionDuration={ 900 }
            autoplay={false}
            afterOffset={afterOffset}
            aroowShift={-40}
        > 
            {
                Array( Math.ceil( _tracks.length / 3 ) ).fill(1)
                    .map((__, i) => {
                        return <Carousel.Page key={i}>
                            <div className={`item item-${ i % 5 +222 } d-flex`}>                                        
                                {
                                    Array(3).fill(1)
                                    .map((___, ii) => {
                                        const track:ITrack = _tracks[ i * 3 + ii ] 
                                        return track
                                            ?
                                            <TrackDefaultCard 
                                                item={track}  
                                                onChoose={props.onChoose} 
                                                key={track.id}
                                            />
                                            :
                                            <div className="col-md-4" />
                                    })
                                }  
                                <div className="d-none position-absolute m-3 bg-danger text-light p-4 lead z-100">
                                    {i}    
                                </div> 
                            </div>                     
                        </Carousel.Page>
                    } )
                // _tracks 
                //     .map((track, i) => <Carousel.Page  key={track.id}>
                //         <div className={`item item-${ i % 3 + 1 } d-flex`}>
                //             <TrackDefaultCard 
                //                 item={track}  
                //                 onChoose={props.onChoose}
                //             />
                //         </div>
                //     </Carousel.Page> )
            }
        </Carousel>
    </div>
}
export default TrackDefaultCarousel


/* Protopia Ecosystem component */
export interface ITrackDefaultCardProps {
    item: ITrack
    onChoose: (item:ITrack) => void
}
const TrackDefaultCard = ({item, ...props}: ITrackDefaultCardProps): JSX.Element => {
    const col = "4"
    return <div className={` position-relative pointer hover col-${col} `}>
        <div className="card">
            <div
                className="card-img overflow-hidden"
                style={{ 
                    backgroundImage: `url(${item.illustration})`, 
                    height: 200
                }}  
            > 
            </div>
            <div className='track-icon' style={{ backgroundImage: `url(${item.thumbnail})`, top: 160 }} />
            <div
                className="card-header px-3 d-flex flex-centered flex-column track-card-header"
                title={ item.title }
                style={{
                height: 76,
                overflow: "hidden",
                padding: "0 1.25em",
                position: "relative",
                display: "table",
                paddingTop: 14
                }}
            >
                <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                    { __(item.title) }  
                </h5>
            </div>
            <div className="track-card-body px-3 ">
                <ul className="list-group list-group-flush flex-grow-100 ">
                    <li className="list-group-item h-100 d-flex flex-column"> 
                        <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: wordTrim(item.content, 11) }} />
                    </li>
                </ul>  
            </div>
            
            <div className="p-3 flex-centered">
                <div 
                    className="btn btn-outline-secondary btn-sm px-4"
                    onClick={() => props.onChoose( item ) }
                >
                    {__("Add new")}
                </div>
            </div>
        </div> 
    </div>
} 