import { useState } from "react"
import { CommentInputForm, Likes } from "src/libs/useful"
import { __ } from "src/libs/utilities"
import { Button, ButtonGroup, Collapse, Icon, Intent } from "@blueprintjs/core"
import { HTML, URL } from "src/libs/interfaces/layouts" 
import { IEvent } from "../../data/interfaces"
import EventTypeLabel from "../labels/EventTypeLabel"
import PlaceLabel from "../labels/PlaceLabel"
import DateLabel from "../labels/DateLabel"
import EventRequestForm from "../event/EventRequestForm" 
import SubscribeForm from "./SubscribeForm"


interface IProps {
    item : IEvent
    onClose: () => void
    onOpenLogin: () => void
}
const EventContent = (props: IProps) : JSX.Element => {
    const [isCommentOpen, setIsCommentOpen] = useState<boolean>(false)

    const onContentOpen = () => setIsCommentOpen(!isCommentOpen)
    const onSendComment = (comment: HTML) => {

    }
    const footerBtns: JSX.Element = <>
        <Collapse isOpen={isCommentOpen} transitionDuration={1000} className="anti-double-row-left">
            <CommentInputForm 
                item={props.item} 
                className="border-top border-secondary px-4 pb-2"
                onSend={ onSendComment }
                onAfterSend={onContentOpen}
                footer={<Button minimal onClick={onContentOpen}>{__("Close")}</Button>}
            />
        </Collapse>
        <div className="flex-centered border-top border-secondary">
            <ButtonGroup className="my-2"> 
                <Button minimal large onClick={ props.onClose } className="hint hint--top" data-hint={__("Close")}>
                    <Icon icon="cross" size={22} />
                </Button>
                <SubscribeForm dataType="Event" item={props.item} onOpenLogin={props.onOpenLogin} className="" />            
                <EventRequestForm item={props.item} className=" " />
                <Likes dataType="Event" item={props.item} className=" " />
                <Button minimal intent={Intent.NONE} large onClick={onContentOpen} className=" hint hint--top px-4" data-hint={__("Do comment")}>
                    <Icon icon="comment" />
                </Button>
            </ButtonGroup>
        </div>
    </> 
    return <div className="h-100"> 
        <div className=" h-100"> 
            <Event item={props.item} footer={footerBtns}/> 
        </div> 
    </div>
} 

export default EventContent

interface IEventProps {
    item: IEvent
    footer: JSX.Element
}
export const Event = (props: IEventProps) : JSX.Element => { 
    return <div className="h-100"> 
        <div className="row h-100">
            <div className="col-md-6 col-12 d-flex flex-column">
                <div className="thumbnail topos-event-thumbnail" style={{ backgroundImage: `url(${props.item.thumbnail})`}} />
                <Gallery item={props.item}/>
            </div>
            <div className="col-md-6 col-12 h-100 d-flex flex-column"> 
                <div className="tab-container overflow-y-auto flex-grow-100">
                    <div className="display-6 p-3">
                        {props.item.title}
                    </div>
                    <EventTypeLabel item={props.item} className=" flex-wrap" />
                    <PlaceLabel places={props.item.places} className="mb-1  " />
                    <DateLabel item={props.item} className="mb-3  d-flex" />
                    <div className="pr-3 " dangerouslySetInnerHTML={{ __html: props.item.post_content }} />
                    
                </div>
                {props.footer}
            </div>
        </div> 
    </div>
} 

const Gallery = (props:any): JSX.Element => {
    return !!props.item.images?.length 
    ?
    <div className="gallery">
    {
       props.item.images.map((image:URL) => {
        return <GalleryImage image={image} key={image} />
       }) 
    }                
    </div>
    :
    <></>
}

const GalleryImage = (props:any) :JSX.Element => {
    return <div className="thumbnail w-100" style={{backgroundImage: `url(${props.image})`}}></div>
}