import { IUser, useMainStore } from "@/settings/zustand";
import { AppToaster } from "@/libs/useful";
import { Intent } from "@blueprintjs/core";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MUTATION_EXTERNAL_TOKEN_ACTION } from "../../data/actionTypes";
import actions from "../../data/actions";

const TGLoginFinishForm = ( props:any ) :JSX.Element => {
    const navigate = useNavigate()
    const [searchParams, ] = useSearchParams();
    useEffect(() => {
        const onCallBack = ( user: IUser, external: string, label:string="" ) => {
            // console.log( user, external, label )
            actions( MUTATION_EXTERNAL_TOKEN_ACTION, {user, external})
            .then(
                (response: any) => {
                    console.log( useMainStore.getState().location )
                    // onResponse( response )
                },
                ((error) => {
                    console.log( error )
                    AppToaster.show({
                        message: "No telegram user exists",
                        intent: Intent.DANGER,
                        className: "p-4"
                    })
                })
            )
        } 
        const id = searchParams.get("id")
        const first_name = searchParams.get("first_name")
        const last_name = searchParams.get("last_name")
        const username = searchParams.get("username")
        if(id) { 
            const user : IUser = {
                id, 
                display_name: `${first_name} ${last_name}`,
                email: `${username}@telegram.ru`,
                roles:[]
            }
            onCallBack( user, "telegram", )
        }
        else {
            // AppToaster.show({
            //     message: "No telegram user exists",
            //     intent: Intent.DANGER,
            //     className: "p-4"
            // }) 
        }
        navigate(useMainStore.getState().location) 
        useMainStore.setState({ location: "" })
               
    })
    return (
        <div>
            
        </div>
    )
} 

export default TGLoginFinishForm