import { GET_TEST_ACTION } from "src/modules/pe-tests-module/data/actionTypes"
import { actions } from "src/modules/pe-tests-module/data/actions"
import { IPETest } from "src/modules/pe-tests-module/data/interfaces"
import { Loading } from "src/libs/useful"
import { shuffle } from "src/libs/utilities"
import { useEffect, useState } from "react"
import SingleTest from "src/modules/pe-tests-module/views/single/SingleTest"

const ConquestSingleTest = (props:any) : JSX.Element => {
     const [item, setItem] = useState<IPETest>( props.item as IPETest )
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        actions(GET_TEST_ACTION, { id: props.item.id })
            .then((result:IPETest) => {
                setItem({
                    ...result, questions: 
                    result.is_shuffle ? shuffle(result.questions) : result.questions
                } )
                setIsLoading(false)
            })
    }, [])
    if( isLoading) 
    {
        return <div className="layout-state w-100 h-100 flex-centered">
            <Loading />
        </div>
    }  

    return <div className="p-0 h-100 w-100 overflow-y-auto-thin">
        <SingleTest
            item={item}
        />
    </div>
}
 

export default ConquestSingleTest