import React from "react"
import { __ } from "@/libs/utilities"
import { concatRouting } from "src/libs/layouts"
import {getAllWidgets, getWidgets} from "@/libs/utilities" 
import {IMenuItem, IRoutingData} from '@/libs/interfaces/layouts'

interface IAsideProps {
  route: IRoutingData
}
const Aside = ({ route }: IAsideProps) : JSX.Element => {
  if(Object.keys(getWidgets).length === 0)
  {
    getAllWidgets( getWidgets )
  }
  const aside: any[] = route
    ?
    concatRouting().filter( 
      ( e: IMenuItem ) => { 
        return route.split("/")[1] === e.route.toString() 
      } 
    )[0].aside 
    :
    []
  const __widgets: any[] = aside.map((e: any, i: number) => {
    if ( getWidgets[e.component] ) {
      const ElWidget = getWidgets[e.component].default 
      return (
        <div className="aside-widget col-12" key={i}>
          <div className="aside-widget-title">
            {__(e.title)}
          </div>
          {
            ElWidget ? <ElWidget route={route} {...e.args} /> : null
          }
        </div>
      )
    }

    return (
      <small className="m-5 text-secondary text-center">
        {__("No widget exists: ") + e.component}
      </small>
    )
  })
  return <div className="row">
      {__widgets}
    </div> 
} 

export default Aside