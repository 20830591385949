import { useEffect, useState } from "react"
import { Callout } from "@blueprintjs/core"
import { Likes } from "src/libs/useful"
import { __ } from "src/libs/utilities"
import { actions } from "../../data/actions"
import { GET_EVENTS_ACTION } from "../../data/actionTypes"
import { IEvent } from "../../data/interfaces"
import { Event } from "../components/EventContent"
import EventRequestForm from "../event/EventRequestForm"
import SubscribeForm from "../components/SubscribeForm"
import Moment from "react-moment"
import { IToposPostsStore, useToposPostsStore } from "../../data/store"

const Today = (props: any) : JSX.Element => { 
  const  events: IEvent[] = useToposPostsStore((state:IToposPostsStore) => state.events)
 
  return <div className="container">
    <div className="display-5 my-3 text-center mb-3 text-uppercase">
      <span className="mr-3">{__("today") }</span>
      <Moment locale="ru" format="D MMMM YYYY ">
        {new Date()}
      </Moment>
    </div>
    <div className="">  
    {
      !!events.length
        ?
        events.map(event => {
          return <div key={event.id} className="pb-1 border-bottom border-secondary">
            <Event item={event} footer={<div className="d-flex">
              <SubscribeForm dataType="Event" item={event} onOpenLogin={props.onOpenLogin} className="" />
              <EventRequestForm item={event} className="my-3 col-12  flex-centered " />
              <Likes dataType="Event" item={event} className=" " />
            </div>} />
          </div> 
        })
        :
        <Callout className="p-4">
          { __("No element exists") }
        </Callout>
    } 
    </div>
    
  </div>
} 

export default Today