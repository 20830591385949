
export function sprintf( text:string, impl:string, impl2:string="", impl3:string="",  ) {	// Return a formatted string
  //
  // +   original by: Ash Searle (http://hexmen.com/blog/)
  // + namespaced by: Michael White (http://crestidg.com)

  const regex = /%%|%(\d+\$)?([-+#0 ]*)(\*\d+\$|\*|\d+)?(\.(\*\d+\$|\*|\d+))?([scboxXuidfegEG])/g
  const a = arguments; let i = 0; const
    format = a[i++]

  // pad()
  const pad = function (str: string, len: number, chr: string, leftJustify: any) {
    const padding = (str.length >= len) ? "" : Array(1 + len - str.length >>> 0).join(chr)
    return leftJustify ? str + padding : padding + str
  }

  // justify()
  const justify = function (value: any, prefix: string, leftJustify: any, minWidth: any, zeroPad: any) {
    const diff = minWidth - value.length
    if (diff > 0) {
      if (leftJustify || !zeroPad) {
        value = pad(value, minWidth, " ", leftJustify)
      } else {
        value = value.slice(0, prefix.length) + pad("", diff, "0", true) + value.slice(prefix.length)
      }
    }
    return value
  }

  // formatBaseX()
  const formatBaseX = function (value: any, base: number, prefix: string, leftJustify: any, minWidth: number, precision: any, zeroPad: any) {
    // Note: casts negative numbers to positive ones
    const number = value >>> 0
    prefix = prefix && number && { 2: "0b", 8: "0", 16: "0x" }[base] || ""
    value = prefix + pad(number.toString(base), precision || 0, "0", false)
    return justify(value, prefix, leftJustify, minWidth, zeroPad)
  }

  // formatString()
  const formatString = function (value: any, leftJustify : any, minWidth: number, precision: any, zeroPad: any) {
    if (precision != null) {
      value = value.slice(0, precision)
    }
    return justify(value, "", leftJustify, minWidth, zeroPad)
  }

  // finalFormat()
  const doFormat = function (substring: string, valueIndex: any, flags: any, minWidth: any, _: any, precision: any, type: string) {
    if (substring === "%%") return "%"

    // parse flags
    let leftJustify = false; let positivePrefix = ""; let zeroPad = false; let
      prefixBaseX: any = false
    for (let j = 0; flags && j < flags.length; j++) {
      switch (flags.charAt(j)) {
        case " ": positivePrefix = " "; break
        case "+": positivePrefix = "+"; break
        case "-": leftJustify = true; break
        case "0": zeroPad = true; break
        case "#": prefixBaseX = true; break
      }
    }

    // parameters may be null, undefined, empty-string or real valued
    // we want to ignore null, undefined and empty-string values
    if (!minWidth) {
      minWidth = 0
    } else if (minWidth === "*") {
      minWidth = +a[i++]
    } else if (minWidth.charAt(0) === "*") {
      minWidth = +a[minWidth.slice(1, -1)]
    } else {
      minWidth = +minWidth
    }

    // Note: undocumented perl feature:
    if (minWidth < 0) {
      minWidth = -minWidth
      leftJustify = true
    }

    if (!isFinite(minWidth)) {
      throw new Error("sprintf: (minimum-)width must be finite")
    }

    if (!precision) {
      precision = "fFeE".indexOf(type) > -1 ? 6 : (type == "d") ? 0 : void (0)
    } else if (precision === "*") {
      precision = +a[i++]
    } else if (precision.charAt(0) === "*") {
      precision = +a[precision.slice(1, -1)]
    } else {
      precision = +precision
    }
    var number: number
    // grab value using valueIndex if required?
    let value: string = valueIndex ? a[valueIndex.slice(0, -1)] : a[i++]

    switch (type) {
      case "s": return formatString(String(value), leftJustify, minWidth, precision, zeroPad)
      case "c": return formatString(String.fromCharCode(+value), leftJustify, minWidth, precision, zeroPad)
      case "b": return formatBaseX(value, 2, prefixBaseX, leftJustify, minWidth, precision, zeroPad)
      case "o": return formatBaseX(value, 8, prefixBaseX, leftJustify, minWidth, precision, zeroPad)
      case "x": return formatBaseX(value, 16, prefixBaseX, leftJustify, minWidth, precision, zeroPad)
      case "X": return formatBaseX(value, 16, prefixBaseX, leftJustify, minWidth, precision, zeroPad).toUpperCase()
      case "u": return formatBaseX(value, 10, prefixBaseX, leftJustify, minWidth, precision, zeroPad)
      case "i":
      case "d": {
        number = parseInt( value  )
        var prefix = number < 0 ? "-" : positivePrefix
        value = prefix + pad(String(Math.abs(number)), precision, "0", false)
        return justify(value, prefix, leftJustify, minWidth, zeroPad)
      }
      case "e":
      case "E":
      case "f":
      case "F":
      case "g":
      case "G":
      {
        number = +value
        prefix = number < 0 ? "-" : positivePrefix
        const method = ["toExponential", "toFixed", "toPrecision"]["efg".indexOf(type.toLowerCase())]
        const textTransform: any = ["toString", "toUpperCase"]["eEfFgG".indexOf(type) % 2]
        // value = prefix + Math.abs( parseInt(number) )[ method ]( precision )
        return justify(value, prefix, leftJustify, minWidth, zeroPad)[textTransform]()
      }
      default: return substring
    }
  }

  return format.replace(regex, doFormat)
}

export function extractYoutubeVideoID(url: string) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
  const regExp2 = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/g
  const match = url.match(regExp)
  if (match && match[7].length == 11) {
    return match[7]
  }

  return ""
}
export function getYoutubeThumbnail(id: string ) {
  return `https://img.youtube.com/vi/${id}/0.jpg`
}

export function getYoutubeData(id: string) {
  const url = `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}`
  return fetch(url)
}

export function extractVimeoVideoID(url = "") {
  if (!url) url = ""
  const regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
  const match = url.match(regExp)
  if (match) {
    return match[4]
  }

  return ""
}
export function getVimeoThumbnail(id : string) {
  return fetch(`http://vimeo.com/api/v2/video/${id}.xml`)
  // return "https://img.youtube.com/vi/" + id + "/0.jpg";
}

export function padZero(string: any ){
  return ( "00" + string.toString() ).slice(-2);
}
export interface ITimes {
  days?: number,
  hrs?: number,
  mins?: number,
  secs?: number
}
export const timeToReadableString = (time: number): ITimes => {
  if (time < 0)
    time = 0;
  var hrs = ~~(time / 3600 % 24),
    mins = ~~((time % 3600) / 60),
    secs = ~~(((time % 3600) % 60 )/ 1)
  return { hrs, mins, secs }
}

//return seconds
export const durationFromITimes = (time: ITimes) => {
  return (time.days || 0) * 24 * 3600 + (time.hrs || 0) * 3600  + (time.mins || 0 ) * 60 + (time.secs || 0 )
}
