import { Route, Routes } from "react-router"
import { IPETest, TEST_CURRENT_MODE } from "../../data/interfaces" 
import Start from "./Start" 
import Finish from "./Finish"
import Results from "./Results"
import Question from "./Question"
import Test from "./Test"
import TestTimer from "./TestTimer"
import { useEffect } from "react"
import { useTestsStore } from "../../data/store"

export interface ITestProps {
    item: IPETest
    mode?: TEST_CURRENT_MODE
}
const SingleTest = (props: ITestProps) : JSX.Element => {
    useEffect(() => {
        useTestsStore.setState({currentTest: props.item})
        return () => {
            useTestsStore.setState({currentTest: {}})
        }
    }, [])
    return <>
        <Routes>
            <Route element={<Test {...props} />}>
                <Route index element={ <Start {...props} /> } />
                <Route path="finish" element={ <Finish {...props} /> } />
                <Route path="results" element={ <Results {...props} /> } />
                <Route path="question/:question_id" element={ <Question {...props} /> } />
            </Route>
        </Routes> 
    </> 
} 

export default SingleTest