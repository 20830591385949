const { useSwiper } =  require("swiper/react") 

/* Protopia Ecosystem component */
export interface IPrevButtonProps {
    onClick: () => void
}
const PrevButton = (props: IPrevButtonProps): JSX.Element => {
    const swiper = useSwiper()
    const onClick = () => { 
        swiper.slidePrev()
        props.onClick()
    }
    return <div className='pe-prev-button-container' onClick={onClick} >
        <i className="fas fa-chevron-left" />
    </div>
}
export default PrevButton