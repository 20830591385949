 
import { useDataStore } from 'src/modules/pe-basic-module/data/store'
import { useTestsStore } from 'src/modules/pe-tests-module/data/store'
import { DO_ACTION_ACTION, GET_MATRIX_ACTIONS, GET_MINE_MANITU_ACTIONS, GET_MY_PIXELS_ACTION, GET_PARTNERS_ACTION, UNSET_MATRIX_ACTIONS } from './actionTypes'
import asyncControllers from './controllers'
import { useManitu, useManituStore } from './store'


export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    switch (action) {
        case DO_ACTION_ACTION: 
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_MATRIX_ACTIONS: 
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {
                        useManitu.setState({matrix: response.getMatrix.matrix})
                        resolve( response.getMatrix )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case UNSET_MATRIX_ACTIONS: 
            promise = new Promise((resolve, reject) => {  
                useManitu.setState({matrix: null})
                resolve( null ) 
            })
            return promise
        case GET_MY_PIXELS_ACTION: 
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        useDataStore.setState({
                            PETest: [
                                ...( useDataStore.PETest || [] ),
                                ...response.getMyTests
                            ]
                        })
                        resolve( response.getMyTests )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_MINE_MANITU_ACTIONS: 
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {  
                        useManituStore.setState({ userMana: response.getUserManitu })
                        useManituStore.setState({ userMaxMana: JSON.parse( JSON.stringify( response.getUserManitu ) ) })
                        useManituStore.setState({ selfMatrix : response.getUserMatrix })
                        useManituStore.setState({ manaTypes: response.getManaTypes })
                        useTestsStore.setState({  testCategories: response.getTestCategories })
                        resolve( response.getUserMatrix )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_PARTNERS_ACTION: 
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.users )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        default:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
    }

} 

export const syncAction = (action: string, data: any): any => {
    switch(action) { 
        default:
            return action
    }
    
}