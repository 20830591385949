import { Intent, OverlayToaster, Toaster } from "@blueprintjs/core"
import { DO_ACTION_ACTION } from "../data/actionTypes"
import { actions } from "../data/actions"
import { IManituActionType, IManituCell } from "../data/interfaces"
import { useManitu } from "../data/store"
import { IDoActionReturn } from "../data/mocks/doAction"

const useSpell = () => {
    const spelHandler = new SpellHandler()
    return spelHandler
}
export default useSpell

class SpellHandler {
    act = (cell: IManituCell, action: IManituActionType): Promise<any> => {
        return new Promise((resolve, reject) => {
            useManitu.setState({ isActionChoose: false })
            useManitu.setState({ choosedAction: null })
            actions(DO_ACTION_ACTION, { cell, action })
                .then((response: IDoActionReturn ) => {
                    resolve(response)
                })
                .catch(error => {
                    const myToaster: Toaster = OverlayToaster.create({ position: "bottom" })
                    myToaster.show({ message: "Unknown error. Repeat please", timeout: 5000, intent: Intent.DANGER });
                    reject(error)
                })
        })
    }
}