import { Tag } from "@blueprintjs/core"
import { IEvent } from "../../data/interfaces"
import Moment from "react-moment"

interface IProps {
    item: IEvent
    className?: string
}
const DateLabel = (props: IProps) : JSX.Element => {
    const trimMonth = ( date:string ) : string =>
    {
        const d = date.split(" ")
        return d[1]
    }
    return  <div className={`${props.className}  d-flex`}>
        <div  className="m-1">
            <SingleDateLabel date={props.item.start}/>  
        </div> 
        <div  className="m-1">
            <SingleDateLabel date={props.item.finish} /> 
        </div> 
    </div> 
} 
export default DateLabel

export const SingleDateLabel = (props:any) : JSX.Element => {
    return <div  className="date-label">
        <div className="date-month-label">
            <i className="far fa-clock mr-2 hidden"></i> 
            <Moment locale="ru" format="MMM" >
                {new Date(props.date)}
            </Moment> 
        </div>
        <div className="date-day-label">
            <Moment locale="ru" format="DD" >
                {new Date(props.date)}
            </Moment> 
        </div>
        <div className="date-year-label">
            <Moment locale="ru" format="YYYY" >
                {new Date(props.date)}
            </Moment> 
        </div> 
    </div> 
}