import { ILike, IPost, LIKE_TYPE } from "src/libs/interfaces/layouts"
import { __ } from "src/libs/utilities"
import { Button, Dialog, Intent, Popover } from "@blueprintjs/core"
import { likes } from "./likes/likes"
import { Fragment, useEffect, useState } from "react"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_POST_LIKES_ACTION } from "@/modules/pe-basic-module/data/actionTypes"

interface IProps {
    item: IPost
    dataType: string
    className: string
}
const Likes = (props: IProps): JSX.Element => {
    const [likes, setLikes] = useState<ILike[]>([])
    useEffect(() => {
        actions(GET_POST_LIKES_ACTION, {dataType:props.dataType, id: props.item.id})
            .then( response => {
                setLikes(response)
            })
    }, [])
    const onLike = () => {

    }
    return <Popover  
        hoverOpenDelay={400} 
        interactionKind="hover" 
        position="top-right"
        content={ <LikesList likes={likes} /> }
        modifiers={{
            arrow: { enabled: false },
            flip: { enabled: true },
            preventOverflow: { enabled: false },
        }}
    >
        <Button minimal intent={Intent.NONE} large onClick={ onLike } className=" px-3" data-hint={__("Like")}>
            <span className="flex-centered">
                <i className="far fa-heart mr-3"></i> 
                <span>{ likes?.length }</span>
            </span>
        </Button>
    </Popover >
} 

export default Likes

interface ILikeList {
    likes: ILike[]
}
const LikesList = (props:ILikeList) :JSX.Element => {
    const _likes: any = likes()
    return <div className="d-flex flex-row ">
    {
        Object.keys(_likes).map(( like:string ) => {
            const likes = props.likes.filter(lk => lk.type === like)
            const count : number = likes.length
            return <Popover  
                hoverOpenDelay={ 400 } 
                //disabled={ !count }
                interactionKind="hover" 
                position="top"
                content={ !!count ? <LikesUsersList likes={likes} type={_likes[like]}/> : <div className="py-1 px-2">{ __( _likes[like].title ) }</div> } 
            > 
                <Button minimal className="" key={like}>
                    <div className="pe-like-elem position-relative">
                    {
                        _likes[like].icon
                    }
                    {
                        !!count && <div className=" pe-like-count ">{count}</div>
                    }  
                    </div> 
                </Button>
            </Popover >
        })
    }
    </div>
}
const LikesUsersList = (props:any) : JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean> (false)
    return <div>
        <div className="pt-1 text-center">{ __( props.type.title ) }</div>
        <div className="d-flex flex-row">
            {
                props.likes.map((like:ILike, i:number) => {
                    return i  <  4 
                    ?                
                    <div className="d-flex flex-column p-1 pe-w-100 pe-h-100" key={i}>
                        <div className="avatar-large" style={{backgroundImage: `url(${like.user?.avatar})`}} />
                        <small className="small text-overflow">{like.user?.display_name}</small>
                    </div>
                    :
                    null
                })
            }
            {
                props.likes.length > 4 && <Fragment >
                    <div className="flex-centered p-1 pe-w-100 pe-h-100 pointer" onClick={ () => setIsOpen(true) }>
                        +{ props.likes.length - 4 }
                    </div>
                    <Dialog
                        isOpen={isOpen}
                        onClose={()=> setIsOpen(false)}
                    >
                        <div className="p-4 flex-centered flex-wrap">
                        {
                            props.likes.map((like:ILike, i:number) => {
                                return  <div className="d-flex flex-column p-1 pe-w-100 pe-h-100" key={i}>
                                    <div className="avatar-large" style={{backgroundImage: `url(${like.user?.avatar})`}} />
                                    <small className="small text-overflow">{like.user?.display_name}</small>
                                </div> 
                            })
                        }
                        </div>
                    </Dialog>
                </Fragment> 
            }
        </div>
    </div>
}