import { Outlet } from "react-router"
import Breadcrumbs from "./Breadcrumbs"

const TestGenerator = ():JSX.Element => {
    return <div className="short-container d-flex h-100 ">
        <Breadcrumbs />
        <Outlet />
    </div> 
} 

export default TestGenerator