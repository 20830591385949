import { IState, useMainStore } from "@/settings/zustand"
import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { template } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom" 
import { isRouteOpen } from "../utils/isRouteOpen"

/* Protopia Ecosystem component */
export interface IFooterMenuItemProps {
    item: IMenuItem
}
const FooterMenuItem = ({item}: IFooterMenuItemProps): JSX.Element => {    
    const landId:ID = useMainStore( ( state: IState ) => state.landId )
    const event_types: string[] =  useMainStore( ( state: IState ) => state.event_types ) || []
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState<boolean>(isRouteOpen(item, landId, event_types))
    useEffect(() => {
        setIsOpen( isRouteOpen(item, landId, event_types) )
    }, [ item, landId, event_types ])
    return <div className='pe-footer-menu-item-container '>
        <NavLink
            to={item.route} 
            className={` hover   ${ isOpen ? "opened" : "closed" }`}
        >
            <div 
                style={{
                    color: `${template().footer?.color}`
                }}
            >
                {__(item.title)}
            </div>
        </NavLink>
    </div>
}
export default FooterMenuItem