
import {  DocumentNode } from "graphql"
import { useAppoloStore, useMainStore } from "../../../../settings/zustand" 
import { 
    getChangeName,
    getInputTypeName,
    getMutationArgs,
    getQueryArgs, 
    getQueryName, 
    mutationEdit, 
    querySingle, 
    querySingleName, 
} from "src/libs/layouts" 
import {  
    GET_TEST_ACTION
} from "../actionTypes"  
import { test } from "./test"
import { tests } from "./tests"
const { gql } = require("@apollo/client/core")

const asyncControllers =  async(action:string, data: any) => {
    let response : any, 
        query : DocumentNode,
        query_name : string, fields : any
        
    const apolloClient: any = useAppoloStore.getState().client
    switch( action )
    {
        case GET_TEST_ACTION:  
            response = new Promise<any>( 
                (resolve, reject) => { 
                    resolve({
                        getTest: tests().filter(test => test.id === data.id)[0]
                    })
                } 
            )
            return response   
        default: 
            return  new Promise<any>( (resolve, reject) => { resolve(`Unknown action type: ${action}`)} )
    }
}
export default asyncControllers