
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware"; 
import { IPETest, IPETestCategory } from "./interfaces";
import { ID } from "src/libs/interfaces/layouts";

export interface ITestsStore {
    currentTest: IPETest
    currentStep:number
    waybill: string[]
    tryCount: number
    results: ID[]
    genTest: IPETest
    genStep: number,    
    testCategories: IPETestCategory[]
    currentTestCategories: IPETestCategory[]
}
export const useTestsStore: any = create( 
    devtools( 
        persist<ITestsStore>( 
            (set: any, get: any ) => ({ 
                currentTest: {} as IPETest,
                currentStep: 0,
                waybill: [],
                tryCount: 0,
                results: [],
                genTest: {} as IPETest,
                genStep: 0,
                testCategories : [],
                currentTestCategories: []
            }),
            {
                name: 'pe-tests-storage', 
                storage: createJSONStorage( () => localStorage ), 
            } 
        )
    )
)