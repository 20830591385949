import { Intent } from '@blueprintjs/core';
import React from 'react';
//import styles from './vk.scss';
import { AppToaster } from "@/libs/useful";
import { __ } from '@/libs/utilities';
import config, { vk_api_key } from '@/settings/config';
import { IUser } from '@/settings/zustand';

class VKLogin extends React.Component {
  props:any
  state = {
    isSdkLoaded: false,
    isProcessing: false,
    isDisbled: false, 
    src: ""
  };

  componentDidMount() {
    if (document.getElementById('vk-jssdk')) {
      this.sdkLoaded();
      return;
    }
    this.setFbAsyncInit();
    this.loadSdkAsynchronously();
  }

  setFbAsyncInit() { 
    window.vkAsyncInit = () => 
	  {
      try{
        console.log( vk_api_key() )
        window.VK.init({ apiId: vk_api_key() })
        this.setState({ isSdkLoaded: true, isDisbled: false });
      }
      catch(e:any) {
        console.error(e.message)
      }
    };
  }

  sdkLoaded() {
    this.setState({ isSdkLoaded: true });
  }

  loadSdkAsynchronously() 
  {
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.src = 'https://vk.com/js/api/openapi.js?139';
    el.async = true;
    el.id = 'vk-jssdk';
    document.getElementsByTagName('head')[0].appendChild(el);
  }

	checkLoginState = (response: any) => 
	{
		// console.log( response );
		this.setState({ isProcessing: false });
		console.log( response.session === null);
    if( response.session === null ) 
    { 
      AppToaster.show({
        intent: Intent.DANGER,
        icon: "tick", 
        message: __("Sign in escaped"),
      })
      return
    }        
		window.VK.Api.call(
			"users.get",
			{
        uid: response.session.user.id, 
        fields:"photo_100,contacts,email,screen_name", 
        v:"5.89"
      },
			(r: any) => {
				if (this.props.callback) 
				{
          // console.log( r )
					// r.response[0].href = response.session.user.href; 
          fetch( r.response[0].photo_100 )
            .then(response => response.blob())
            .then(blob => {
              const file = new Blob([blob], { type: 'image/jpg' });
              const fileUrl = URL.createObjectURL(file);
              const user: IUser = {
                id: r.response[0].id,
                display_name: r.response[0].screen_name === `id${r.response[0].id}`
                  ?
                  `${r.response[0].first_name} ${r.response[0].last_name}`
                  : 
                  `${r.response[0].screen_name}`,
                roles: [],
                email: r.response[0].email
                  ?
                  r.response[0].email
                  :
                  `${ r.response[0].id }@vk.com`,
                phone: r.response[0].home_phone,
                avatar: r.response[0].photo_100,
                externalId: r.response[0].id,
              }
              this.props.callback( user, "vk" );		
            });
          return
          	
				}
			});
	};

	click = () => 
	{
		if (!this.state.isSdkLoaded || this.state.isProcessing || this.props.disabled) 
		{
			return;
		}
		this.setState({ isProcessing: true });
		window.VK.Auth.login( this.checkLoginState, ( 4194304 ) )//4194304 - email 
	};

  style() 
  {
    return //<style dangerouslySetInnerHTML={{ __html: styles }}/>;
  }

  containerStyle() 
  {
    const style: any = { transition: 'opacity 0.5s' };
    if (this.state.isProcessing || !this.state.isSdkLoaded || this.props.disabled) {
      style.opacity = 0.6;
    }
    return Object.assign(style, this.props.containerStyle);
  }

  render() {
    const { disabled, callback, apiId, ...buttonProps } = this.props;
    const className = this.state.isDisbled &&  !vk_api_key() ? ' bg-secondary untouchble opacity_25' : ''
    return <div
          {...buttonProps}
          className={`soc vk ${this.props.className} ${className}`}
          onClick={this.click}
          value={this.props.value || "VK" }
        >
      <i className="fab fa-vk" /> 
    </div> 
  }
}

export default VKLogin;

declare global {
  interface Window {
      VK?: any
      vkAsyncInit: () => void
  }
}