import { IErmakMapCellType } from "../interfaces";

export const ermakMapCellTypes = (): IErmakMapCellType[] => {
    let list: IErmakMapCellType[] = [
        { 
            id: "1",
            title: "Инфаструктурный слот",
            post_content: "Обустроенная территоря городского кровня. Все необходимые коммуникации, энергия Благоустроенное жильё для персонала.",
            color: "#718e3eEE" 
        },
        { 
            id: "2",
            title: "Равнина",
            post_content: "",
            color: "#718e3e55"
        },
        { 
            id: "2-1",
            title: "Гео-стационарная орбита",
            thumbnail: "/assets/img/ermak/space-elevator.jpg",
            post_content: "Круговая орбита, расположенная над экватором Земли (0° широты), находясь на которой, искусственный спутник обращается вокруг планеты с угловой скоростью, равной угловой скорости вращения Земли вокруг оси.",
            color: "#0d0d26EE" 
        },
        {
            id: "3-1",
            title: "Пылевой сырт",
            post_content: "",
            color: "#4d3d0099"
        },
    ]
    return list
}