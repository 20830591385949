import { Button, Dialog } from "@blueprintjs/core"
import React, { useState } from "react"
import { Json } from "@/libs/interfaces/layouts"

const JSONRowElement = (props: JSONElementProps) : JSX.Element =>
{
    const [isOpen, onOpen] = useState(false)
    console.log( props.json )
    return <>
        <Button onClick={() => onOpen( !isOpen )}>
            { isOpen ? "Hide" : "Show"} {props.json.length}
        </Button>
        <Dialog className="large" isOpen={ isOpen } onClose={ () => onOpen( !isOpen ) }>
            <div className="p-4 overflow-x-auto overflow-y-auto" style={{ maxHeight: "80vh" }}>
                <pre
                    className={` ${props.col}`}
                    style={{ ...props.style, width:"100%", overflow: "unset" }}
                >
                    { props.json }
                </pre>
            </div>
        </Dialog>
    </>
}
export default JSONRowElement

export interface JSONElementProps {
    style: any
    json: Json,
    col: any
}