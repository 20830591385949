import { initArea } from '@/libs/utilities/getWidget'
import { mainMenu } from '@/libs/layouts'
import { useMainStore, IState } from "@/settings/zustand"
import HeaderMenuMainButton from './HeaderMenuMainButton'
import HeaderUnderMenu from './HeaderUnderMenu'
import { useEffect, useState } from 'react'
import { IMenuItem } from '@/libs/interfaces/layouts'
import isRouteChildren, { isRouteExists } from '@/modules/pe-basic-module/data/isRouteChildren'

const LayoutHeaderMenu = (props: ILayoutHeaderMenuProps) : JSX.Element => {
  const user = useMainStore(( state: IState ) => state.user)
  const layouts = useMainStore((state: IState) => state.layout )
  const [menu, setMenu] = useState<IMenuItem[]>(mainMenu())
  useEffect(() => {
    setMenu( mainMenu() )
  }, [ user, layouts ])
  
  const onRoute = ( rt: string ) => {

  }

  const mainMenu1: any[] = menu 
    .filter( e => isRouteExists( e ) )
      .map( (e, i) => {
        if ( isRouteChildren(e) ) {
          return <HeaderUnderMenu i={i} item={e} key={i} />
        } 

        return (
          <HeaderMenuMainButton
            item={ e }
            i={ i }
            key={ i }
            onRoute={ onRoute }
            pt="1"
          />
        )
      } )
  
  return <div className={` main-menu `}>
    {
      initArea(
        "before-header-menu",
        { ...props }
      )
    }
    { mainMenu1 }
    {
      initArea(
        "after-header-menu",
        { ...props }
      )
    }
  </div>
} 

export default LayoutHeaderMenu

interface ILayoutHeaderMenuProps {
    onHumburger: () => void
}