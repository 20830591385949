import { __ } from "src/libs/utilities"
import { ITestProps } from "./SingleTest"
import Results from "./Results"

 
const Finish = ( props : ITestProps) : JSX.Element => {
    return <div className=" container">
        <div className="pe-matchings-finish" >
            <div 
                dangerouslySetInnerHTML={{ __html: __("Results") }} 
                className={"pe-matchings-finish-label " } 
            />
            <div className="pe-matching-results">
                <Results { ...props } />
            </div>
        </div>
    </div>
} 

export default Finish