import { Outlet, useNavigate } from "react-router"
import { NavLink } from "react-router-dom"
import { useDataStore } from "src/modules/pe-basic-module/data/store"
import { IErmakSpace } from "../../data/interfaces"
import { IErmakStore, useErmakStore } from "../../data/store"
import StoreDrawer from "./StoreDrawer"

/* Protopia Ecosystem component */
export interface IErmakSpacesProps {

}
const ErmakSpaces = (props: IErmakSpacesProps): JSX.Element => {
    const currentSpace : IErmakSpace = useErmakStore((state:IErmakStore) =>state.currentSpace )
    return <div 
        className='pe-ermakspaces-container' 
        style={{
            backgroundColor: currentSpace?.color,
        }}
    >
        <div 
            className="pe-ermakspaces-bg" 
            style={{
                backgroundImage: `url(${currentSpace?.thumbnail})`,
                backgroundPositionY: currentSpace?.thumbnailPositionY,
            }}
        />
        <div className="pe-ermakspaces">
            <div className="pe-ermakspaces-menu">
                <ErmakSpacesMenu />
            </div>
            <div className="pe-ermakspaces-c">
                <Outlet />
            </div>            
        </div>
        <StoreDrawer />
    </div>
}
export default ErmakSpaces

const ErmakSpacesMenu = () : JSX.Element[] => {
    const PEErmakSpace: IErmakSpace[] = useDataStore((state: any) => state.PEErmakSpace) || []
    const orderZ = (a: IErmakSpace, b: IErmakSpace) => {
        return a.z < b.z ? 1 : -1
    }
    const spaces = PEErmakSpace
        .filter( space => typeof space.z !== "undefined" )
        .sort(orderZ)

    return spaces.map((space, i) => {
        return <ErmakSpaceMenuButton 
            item={space} 
            key={space.id} 
            prev={spaces[ i - 1 ]}
            next={spaces[ i + 1 ]}
        />
    })
}

interface IErmakSpaceMBProps {
    item: IErmakSpace
    prev?: IErmakSpace
    next?: IErmakSpace
}
const ErmakSpaceMenuButton = (props: IErmakSpaceMBProps) : JSX.Element => {
    const navigate = useNavigate()
    const currentSpace : IErmakSpace = useErmakStore((state:IErmakStore) =>state.currentSpace )
    const onClick = () => {
        navigate( props.item.id.toString() )
        useErmakStore.setState({currentSpace : props.item })
    }
    return <div 
        className={`pe-ermakspaces-btn ${currentSpace.id === props.item.id ? "active" : ""}`} 
        onClick={ onClick } 
        style={{ 
            background: `linear-gradient(
                to bottom, 
                ${props.item.color} 22%, 
                ${props.item.color} 50%,
                ${props.item.color} 75%,
                ${props.next ? props.next.color : props.item.color} 100%
            )` 
        }}
    >
        <div>
            {props.item.title}
        </div>
    </div>
}