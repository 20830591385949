import { Outlet } from "react-router"
import TestCatFilter from "./TestCatFilter"

interface IProps {

}
const TestsCont = (props: IProps) => {
  return (
    <div className="container-float h-100 flex-centered"> 
        <Outlet /> 
    </div>
  )
}

export default TestsCont