import { Navigate, Route, Routes } from "react-router"
import { IToposStore, useToposStore } from "../../data/store"
import ToposLayoutContainer from "../components/ToposLayoutContainer" 
import Year from "./Year"
import Month from "./Month"
import Week from "./Week"
import Day from "./Day"
import Today from "./Today"
import { labels } from "../../data/labels"
import LandscapeContainer from "./LandscapeContainer"
import useEvent from "../../hooks/useEvent"

const LandscapeForm = (props: any) : JSX.Element => {
    useEvent("-1")
    const range: number = useToposStore((state:IToposStore) => state.range)
    return <div className="w-100 h-100">
        <Routes> 
            <Route path="/*" element={<ToposLayoutContainer />} >
                <Route path="/*" element={ <LandscapeContainer onOpenLogin={props.onOpenLogin}/> } >
                    <Route index element={ <Navigate replace to={ labels[ range ] } /> } /> 
                    <Route path="year" element={ <Year /> } />
                    <Route path="month" element={ <Month /> } />
                    <Route path="week" element={ <Week /> } />
                    <Route path="day" element={ <Day /> } />
                    <Route path="today" element={ <Today /> } />
                </Route>
            </Route>
        </Routes> 
    </div>
} 

export default LandscapeForm