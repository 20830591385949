import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface IImproveBtnProps {

}
const ImproveBtn = (props: IImproveBtnProps): JSX.Element => {
    return <div className='pe-slot-btn no-lock'>
        <svg
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
            viewBox="0 0 313.4 298.1"
        >
            <path d="M58.4,211c-5.5-7.5-10.5-13.9-15-20.6c-1.6-2.5-2.9-5.7-3-8.6c-0.3-14.2-0.2-28.3-0.1-42.5c0-6.7,2-8.7,8.5-9.8
                c1.9-0.3,3.8-1.8,5.1-3.3c8.9-10.3,17.4-21.1,26.6-31.2c19-20.8,50.3-20.7,69.5,0c5.2,5.6,10,11.6,14.9,17.5
                c0.8,0.9,1.5,1.8,2.8,3.3c0.2-1.8,0.5-3,0.5-4.1c0-12.2,0-24.3,0-36.5c0-7.4,2.5-10.1,9.8-10.1c27.2,0,54.3,0,81.5,0
                c6.5,0,9.7,2.5,9.8,7.4c0.1,5-3,7.3-9.6,7.4c-1.5,0-3,0-4.9,0c0,5,0,9.7,0,14.3c0.3,0.2,0.7,0.5,0.9,0.4
                c9.3-1.7,14.7,3.5,19.9,10.2c5.4,7,11.7,13.3,17.1,20.2c1.9,2.4,3.3,5.9,3.4,8.9c0.2,37.3,0.2,74.6,0.1,112c0,6.9-2.7,9.6-9.7,9.6
                c-6.2,0.1-12.3,0.1-18.5,0c-2.7-0.1-4,0.6-5,3.4c-5.5,15.9-18.5,25.3-34.5,25.5c-15.8,0.2-29.2-9.4-34.8-25.1
                c-1-2.9-2.3-3.8-5.3-3.8c-13.5,0.1-27,0.1-40.5,0c-2.8,0-4,0.7-4.9,3.5c-5.4,15.8-18.5,25.2-34.6,25.3
                c-15.9,0.1-29.3-9.4-34.7-25.2c-1.1-3.1-2.5-3.7-5.3-3.7c-6.3,0.1-12.7,0.1-19,0c-6.3-0.1-9-2.9-9.1-9.3c-0.1-8.5,0-17,0-25.5
                c0-7.3,2.6-9.9,9.9-10C52.5,211,54.9,211,58.4,211z M55.5,144.5c0,12.4-0.1,23.9,0.1,35.4c0,1.6,1.2,3.4,2.2,4.8
                c5.4,7.6,11.1,15,16.3,22.7c1.9,2.8,4.1,3.7,7.4,3.7c37.1-0.1,74.3-0.1,111.4-0.1c1.6,0,3.2-0.2,5.3-0.3c0-2.3,0-4.2,0-6.2
                c0-32.8,0-65.6,0-98.5c0-9.2,2-11.1,11.1-11.1c9.9,0,19.9,0,29.9,0c0-5.2,0-9.8,0-14.6c-18.7,0-37.1,0-56,0c0,2.1,0,3.9,0,5.7
                c0,15.7,0,31.3,0,47c0,9.5-2,11.5-11.3,11.5c-36.7,0-73.3,0-110,0C60.1,144.5,58.1,144.5,55.5,144.5z M213.5,110.1
                c0,33.8,0,67.2,0,100.6c22.7,0,45.1,0,67.5,0c0-12.1,0-23.8,0-35.8c-14.3,0-28.3,0-42.2,0c-8.2,0-10.5-2.3-10.5-10.2
                c0-12.5,0-25,0-37.5c0-5.6,0-11.2,0-17C223.1,110.1,218.5,110.1,213.5,110.1z M70.7,129.4c30.2,0,59.1,0,87.9,0
                c0.4-0.4,0.7-0.8,1.1-1.2c-9.2-9.6-17.4-20.4-27.8-28.6c-11-8.8-28.2-6.5-38,3.4C85.9,110.9,79,119.9,70.7,129.4z M281.3,159.7
                c0-8.2,0.1-15.9-0.1-23.7c0-1-0.7-2.2-1.4-3C274,126,268,119,262,112.1c-0.9-1-2.3-2.1-3.6-2.2c-4.9-0.2-9.9-0.1-15-0.1
                c0,16.9,0,33.3,0,49.8C256,159.7,268.3,159.7,281.3,159.7z M228.5,269.8c11.9-0.1,21.8-10.2,21.6-22.2
                c-0.1-11.7-10.1-21.4-21.9-21.4c-11.6,0-21.7,10.1-21.7,21.6C206.5,259.7,216.8,269.9,228.5,269.8z M108.3,269.8
                c11.9,0,22-10.1,21.9-22c-0.1-11.6-10.1-21.7-21.7-21.7c-11.7,0-21.7,9.8-21.9,21.5C86.3,259.4,96.5,269.8,108.3,269.8z
                M138.6,226.1c1.8,4.5,3.3,8.5,5.2,12.3c0.5,1.1,2.3,2.3,3.6,2.3c14,0.2,27.9,0.1,41.9,0c1.2,0,3.1-0.8,3.5-1.7
                c2-4.1,3.5-8.4,5.3-12.9C178.2,226.1,158.5,226.1,138.6,226.1z M55.4,240.8c5.1,0,9.7,0.1,14.4-0.1c1.1,0,2.6-0.8,3-1.7
                c1.9-4.1,3.5-8.4,5.2-12.7c-7.6,0-15.1,0-22.6,0C55.4,231.2,55.4,235.7,55.4,240.8z M258.5,226.1c2.2,5.1,4.3,9.8,6.3,14.5
                c5.3,0,10.7,0,16.2,0c0-5,0-9.6,0-14.5C273.4,226.1,266.1,226.1,258.5,226.1z"/>
            <path d="M93.3,177.9c0,3.7,0.2,7.3,0,11c-0.2,4.2-2.6,6.8-6.9,7.1c-4.4,0.3-7.4-2.2-7.7-6.2c-0.5-8-0.5-16,0-23.9
                c0.3-4.1,3.4-6.4,7.8-6.1c4.3,0.4,6.6,2.9,6.7,7.2C93.5,170.6,93.3,174.2,93.3,177.9z"/>
            <path d="M123.3,178.2c0,3.5,0.1,7,0,10.5c-0.2,4.2-2.4,6.8-6.7,7.3c-4.1,0.4-7.8-2.2-8-6.5c-0.4-7.6-0.4-15.3,0-23
                c0.2-4.3,3.7-7,7.7-6.8c4.1,0.2,6.8,3,7,7.5C123.4,170.8,123.3,174.5,123.3,178.2z"/>
            <path d="M153.3,177.5c0,3.7,0.1,7.3,0,11c-0.2,4.5-2.9,7.4-7,7.5c-4.1,0.1-7.5-2.5-7.7-6.9c-0.3-7.5-0.3-15,0-22.5
                c0.2-4.3,3.5-7.1,7.6-7c4.1,0.1,7,2.9,7.2,7.3C153.5,170.5,153.3,174,153.3,177.5z"/>
            <path d="M183.3,177.8c0,3.7,0.1,7.3,0,11c-0.2,4.2-2.5,6.8-6.8,7.2c-4.1,0.4-7.7-2.3-7.9-6.6c-0.3-7.6-0.3-15.3,0-23
                c0.2-4.3,3.7-6.9,7.8-6.7c4.1,0.2,6.7,3,6.9,7.6c0,0.3,0,0.7,0,1C183.3,171.4,183.3,174.6,183.3,177.8
                C183.3,177.8,183.3,177.8,183.3,177.8z"/>
            <path d="M235.7,247.8c0.1,4-3.1,7.4-7,7.6c-4.2,0.2-7.5-3.1-7.5-7.5c0-4.2,3-7.2,7.2-7.2C232.6,240.8,235.6,243.7,235.7,247.8z"/>
            <path d="M108.2,240.8c4.2-0.1,7.4,2.8,7.6,6.9c0.2,4.2-3.2,7.8-7.4,7.8c-4.1,0-7.2-3.1-7.2-7.3C101.1,243.9,104,240.8,108.2,240.8z
                "/>
        </svg>
        {__("Improve")}
    </div>
}
export default ImproveBtn