import { ReactElement } from "react"
import VKLogin from "./VKLogin"
import YandexLogin from "./YandexLogin"
import TelegramLogin from "./TelegramLogin"
import OdnoklassnikiLogin from "./OdnoklassnikiLogin"

const onYandex = () => {

}

export interface IExternal {
    componentLogin: ReactElement<any, any> | any
} 
export interface IExternals {
    [key: string]: IExternal
} 

export const externals = () : IExternals => {
    const list : IExternals = {
        vk: {
            componentLogin: VKLogin
        },
        yandex: {
            componentLogin: YandexLogin
        },
        telegram: {
            componentLogin: TelegramLogin
        },
        odnoklassniki: {
            componentLogin: OdnoklassnikiLogin
        },
        /*
        github: {
            componentLogin: <div className="soc github" onClick={  onYandex }>
                <i className="fab fa-github" />
            </div>
        },
        gitlab: {
            componentLogin: <div className="soc gitlab" onClick={  onYandex }>
                <i className="fab fa-gitlab" />
            </div>
        },
        teletiktokgram: {
            componentLogin: <div className="soc tiktok" onClick={  onYandex }>
                <i className="fab fa-tiktok" />
            </div>
        },
        whatsapp: {
            componentLogin: <div className="soc whatsapp " onClick={  onYandex }>
                <i className="fab fa-whatsapp" />
            </div>
        },
        googlePlus: {
            componentLogin: <div className="soc google-plus  " onClick={  onYandex }>
                <i className="fab fa-google-plus-g" />
            </div>
        },
        behance: {
            componentLogin: <div className="soc behance  " onClick={  onYandex }>
                <i className="fab fa-behance" />
            </div>
        },
        dribbble: {
            componentLogin: <div className="soc dribbble  " onClick={  onYandex }>
                <i className="fab fa-dribbble" />
            </div> 
        },
        codepen: {
            componentLogin: <div className="soc codepen  " onClick={  onYandex }>
                <i className="fab fa-codepen" />
            </div>
        },
        viber: {
            componentLogin: <div className="soc viber" onClick={  onYandex }>
                <i className="fab fa-viber" />
            </div>
        },
        slack: {
            componentLogin: <div className="soc slack" onClick={  onYandex }>
                <i className="fab fa-slack" />
            </div>
        },
        steam: {
            componentLogin: <div className="soc steam" onClick={  onYandex }>
                <i className="fab fa-steam" />
            </div>
        },
        stackOverflow: {
            componentLogin: <div className="soc stack-overflow" onClick={  onYandex }>
                <i className="fab fa-stack-overflow" />
            </div>
        },
        skype: {
            componentLogin: <div className="soc skype " onClick={  onYandex }>
                <i className="fab fa-skype" />
            </div>
        },
        pinterest: {
            componentLogin: <div className="soc pinterest  " onClick={  onYandex }>
                <i className="fab fa-pinterest" />
            </div>
        },
        */
    }
    return list
}