import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { INPC } from "src/modules/pe-fabula-module/data/interfaces"

/* Protopia Ecosystem component */
export interface ITileEpisodeNPCLabelProps {
    item: INPC
}
const TileEpisodeNPCLabel = ({item, ...props}: ITileEpisodeNPCLabelProps): JSX.Element => {
    const { width, height } = useScreenSize()
    return <div 
        className='pe-tile-episode-npc-sprite'
        data-top={item.top}
        data-left={item.left}
        data-z={item.z}
        data-width={item.width}
        data-height={item.height}
        style={{
            top: `${item.top}%`,
            left: `${item.left}%`, 
            width: `${ item.width / 256 * item.z }%`,
            height: `${ ( item.height ) / 256 * item.z }%`,
        }}
    > 
        {
            true && <div 
                className='pe-tile-episode-npc-sprite'
                style={{ 
                    transform: `scaleX(${!!item.scaleX ? "-1" : "1"})`,
                    backgroundImage: `url(${item.avatar})`,
                }}
            />
        } 
        <div className="pe-tile-episode-npc-label">

        </div>
    </div>
}
export default TileEpisodeNPCLabel