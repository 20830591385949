
  
import ManituForm from "./manitu/ManituForm" 
import BasicState from "src/libs/basic-view"
import "../assets/css/style.scss" 
import { useManituStore } from "../data/store"

export default class ManituView extends BasicState {
    props: any
    stateDidMount = () => {
        document.documentElement.style.setProperty("--manituCellSize", `${useManituStore.getState().size}px`);
    } 
    addRender():JSX.Element| null {
        return <ManituForm {...this.props} /> 
    }
}