// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { IFabulaHero, IPEFabula, IPEStory, PE_STORY_PHASE } from "../../data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "../../data/store"
import { useNavigate } from "react-router-dom"
import { ID } from "src/libs/interfaces/layouts"
import { actions } from "../../data/actions"
import { CHANGE_HERO_ACTION, GET_STORY_ACTIONS } from "../../data/actionTypes"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import NextButton from 'src/libs/useful/gallery/NextButton';
import PrevButton from 'src/libs/useful/gallery/PrevButton'; 
import { useState } from 'react';
import { __ } from 'src/libs/utilities';
import { Button } from '@blueprintjs/core';
import { clearFabula, clearStory } from '../../hooks/story';

const {  Navigation, Pagination, Scrollbar, A11y } =  require("swiper/modules") 
const {   Swiper, SwiperSlide } =  require("swiper/react")  

/* Protopia Ecosystem component */
export interface IFabulaProps {

}
const Fabula = (props: IFabulaProps): JSX.Element => { 
    const navigate = useNavigate() 
    const user: IUser = useMainStore((state: IState) => state.user)
    const fabula : IPEFabula = useStorytellingStore((state: IStorytellingStore) => state.fabula) 
    const story : IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    const [ i, setI ] = useState(0)
    const [ start, setStart ] = useState( 0 )
    
    const goto = (id: ID, hero: IFabulaHero) => {
        if( user.id ) {
            actions(GET_STORY_ACTIONS, {id})
                .then(() => {
                    actions(CHANGE_HERO_ACTION, { hero })
                        .then(() => navigate(`story/${ id }/${ story.phase === PE_STORY_PHASE.START ? `play` : `intro` }`))
                })
            //navigate(`story/${id}`)
        }
        else {
            useMainStore.setState({ isOpenLogin: true })
        }
    }
    const to = (i:number) => {
        setI(i) 
    } 
    const onPrev = () => { 
        setStart( start - 1 )
    }
    const onNext = () => { 
        setStart( start + 1 )
    }
    const onResetFabula = () => {
        clearStory()
        clearFabula()
        window.location.reload()
    }
    if(!fabula.id) {
        return <></>
    }
    return <div className='h-100 pe-fabula-container'>  
        <Swiper 
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}  
            loop 
            loopAddBlankSlides 
            slidesPerView={1}  
            mousewheel
        >
            {
                fabula.PEStory.map((item, i) => {
                    return <SwiperSlide key={i}>
                        <div 
                            className="pe-fabula-story-card w-100"
                            onClick={() => to( parseInt( (item.id).toString() ) )} 
                            key={item.id}
                        >
                            <div 
                                className="pe-fabula-story-card-bg"
                                style={{ backgroundImage: `url(${item.thumbnail})`}} 
                            />
                            <div className="pe-fabula-story-card-content">
                                <div className="h display-6 px-3 p-1 bg-dark text-light mt-auto max_w_500 w-100 text-center">
                                    { item.title }
                                </div>
                                <div 
                                    className="my-1 mx-4 text-center z-index-1 max_w_500 p-3 bg-dark text-light" 
                                    dangerouslySetInnerHTML={{__html: item.post_content}} 
                                />
                                <div className=" z-index-1 d-flex flex-wrap w-100 justify-content-center mt-auto mb-5"> 
                                    <div onClick={ () => {}}>
                                        <div className="avatar-square-btn" style={{backgroundImage:`url(${item.thumbnail})`}}>
                                            { __("Excursion") }
                                        </div> 
                                    </div> 
                                    {
                                        item.heros?.map(hero => {
                                            return <div 
                                                key={hero.id} 
                                                onClick={ () => goto(item.id, hero) }
                                                className={user.id ? `` : ` user-select-none `}
                                            >
                                                <div 
                                                    className="avatar-square-btn"
                                                    style={{ backgroundImage: `url(${hero.avatar})`}}    
                                                >
                                                    { hero.display_name || hero.id.toString() }
                                                </div> 
                                            </div> 
                                        })
                                    } 
                                </div>
                            </div>
                            
                        </div>
                    </SwiperSlide>    
                })
            } 
            <PrevButton onClick={onPrev} /> 
            <NextButton onClick={onNext}/>  
        </Swiper> 
        <Button className='position-absolute z-index-100 top right m-4 lead ' intent="danger" onClick={onResetFabula} >
            {__("Reset Fabula data")}
        </Button>
    </div>
}
export default Fabula