import { __ } from "src/libs/utilities"
import TestGeneratorForm from "src/modules/pe-tests-module/views/generator/TestGeneratorForm"

const NewTestForm = () : JSX.Element => {
    return <div className="w-100 h-100 flex-grow-100">
        <div className="display-7 ">{__("Add new test")}</div>
        <TestGeneratorForm />
    </div> 
} 

export default NewTestForm