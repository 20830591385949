import { useEffect, useState } from "react"
import { IUserMana } from "../../data/interfaces"
import { IManaProps } from "../../data/manas"
import { IManituStore, useManituStore } from "../../data/store"

interface IProps {
    mana: IManaProps
}

const ManaSingleIndicator = ({ mana }: IProps) => {
    const userMana: IUserMana = useManituStore((state:IManituStore) => state.userMana)
    const userMaxMana: IUserMana = useManituStore((state:IManituStore) => state.userMaxMana)
    const [current, setCurrent] = useState<number>(userMana[ mana.id ].count)
    const [max, setMax] = useState<number>(userMaxMana[ mana.id ].count) 
    useEffect(() => {
        setCurrent( userMana[ mana.id ].count )
        setMax(  userMaxMana[ mana.id ].count )
    }, [userMana, userMaxMana]) 

    return <div className="pe-manitu-mana-s-indicate" >
        <div 
            className="pe-mmsi"
            style={{
                backgroundColor: mana.color,
                height: `${ current / max * 100 }%`
            }}
        />
    </div> 
}

export default ManaSingleIndicator