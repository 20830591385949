import { IUser } from "src/settings/zustand"
import { IManituActionType, IManituCell, IManituMatrix, IUserMana } from "../../interfaces"
import { IDoActionReturn } from "../doAction"
import { useManitu, useManituStore } from "../../store"

const DoStorm = ( cell: IManituCell, action: IManituActionType, user: IUser ): IDoActionReturn => {
    const matrix: IManituMatrix = useManitu.getState().matrix
    const userMana: IUserMana = useManituStore.getState().userMana
    
    // cache old parameter
    let oldValue: number = cell.defense
    let newValue: number = cell.defense

    /*
    // verify price and the user's solvency
    if( !!action.price.filter(p => userMana[p.type].count < p.nominal ).length ) {
        return {
            oldValue,
            newValue,
            action
        }
    }

    // get payment
    let _userMana = {...userMana}
    action.price.forEach(p => {
        _userMana[p.type].count = _userMana[p.type].count - p.nominal
    }) 
    useManituStore.setState({ userMana : _userMana})
    */
   
    // set results


    return {
        oldValue,
        newValue: oldValue + 5,
        action
    }
} 

export default DoStorm