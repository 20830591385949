import { Color } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import IScalarForm, { SCALAR_TYPES } from "@/libs/scalars/interface/IScalarForm"
import Scalar from "@/libs/scalars/scalars/Scalar" 

const LayoutTemplateExtendParamsWidget = (props: IScalarForm): JSX.Element => {
    return <Scalar
        {...props}
        enabledForm={<LayoutTemplateExtendParamsEnabled {...props} />}
        desabledForm={<LayoutTemplateExtendParamsDesabled {...props} />}
    />
}

export default LayoutTemplateExtendParamsWidget

const LayoutTemplateExtendParamsEnabled = (props: IScalarForm): JSX.Element => {
    const onChange = ( field:string, value: any | Color ) => {

    }
    return <div className=" d-flex flex-column w-100 my-2 p-0 ">
        <FieldInput
            title={"Default image"}
            type={SCALAR_TYPES.MEDIA}
            value={props.value?.bgThumbnail}
            height = {260}
            editable 
            onChange={value => onChange("bgThumbnail", value)}
        />        
        <FieldInput
            title={"Background thumbnail"}
            type={SCALAR_TYPES.MEDIA}
            value={props.value?.thumbnail}
            height = {260}
            editable 
            onChange={value => onChange("thumbnail", value)}
        />
        <FieldInput
            title={"Background blur"}
            type={SCALAR_TYPES.SLIDER}
            value={props.value?.bgBlur}
            min={1}
            max={10}
            stepSize={1}
            editable 
            onChange={value => onChange("bgBlur", value)}
        /> 
        <FieldInput
            title={"Background Color"}
            type={SCALAR_TYPES.COLOR}
            value={props.value?.backgroundColor}
            editable 
            onChange={value => onChange("backgroundColor", value)}
        />
        <FieldInput
            title={"Background opacity"}
            type={SCALAR_TYPES.SLIDER}
            value={props.value?.bgOpacity}
            min={0}
            max={1}
            stepSize={.1}
            editable 
            onChange={value => onChange("bgOpacity", value)}
        />
        <FieldInput
            title={"Text color"}
            type={SCALAR_TYPES.COLOR}
            value={props.value?.color}
            editable 
            onChange={value => onChange("color", value)}
        />
    </div>
}
const LayoutTemplateExtendParamsDesabled = (props: IScalarForm): JSX.Element => {
    return <div className=" d-flex flex-column w-100 my-2 p-0 ">
        LayoutTemplateExtendParams Desabled
    </div>
}