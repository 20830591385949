import React, { ChangeEvent, useEffect, useState } from "react" 
import { Tabs, Tab, Card, Button, Dialog, Intent } from "@blueprintjs/core"
import { __ } from "../utilities"
import { adapter } from "../layouts"
import { ID, URL, Color } from "../interfaces/layouts" 
import WPMediaLibrary from "./media/WPMediaLibrary"
import WPMediaUploader, { IWPMediaUploaderResult } from "./media/WPMediaUploader"
import './media/MediaChooser.css'  
import { File } from "buffer"
 
export interface IMediaChooserProps {
	isURLHide?: boolean,
	isUploadHide?: boolean,
	hideLib?: boolean,
	isHideClearBtn?: boolean,
	style?: any
	id: ID,
	ID?: ID,
	prefix?: string,
	url: URL,
	bg?: Color,
	height: number | string, 
	width?: number | string, 
	padding?: number,
	isDescr?: boolean,
	isFileNameHide?: boolean,
	accept?: string
	tab?: string
	onChange: (src: string, file: any, name: string ) => void
	server_url? : URL
	limitSize: number
} 

const MediaChooser = ({ ...props }: IMediaChooserProps):JSX.Element => {
	const [tab, setTab] = useState('ld')
	const {isURLHide, isUploadHide} = props 
	if(isUploadHide)
	{
		return <TabSrc {...props} />
	}
	else if(isURLHide)
	{
		return <TabChooser {...props} />
	}
  	return <div className="w-100">
		<Tabs id="TabsExample" vertical onChange={(tab: string) => setTab(tab)} selectedTabId={ tab } className="w-100">
			<Tab
				id="ld"
				className="w-100"
				title={
					<div className="flex-centered flex-column w-100 pt-2">
						<i className="fas fa-cloud-upload-alt fa-1x" />
						<div className="small">
							{__("Upload")}
						</div>
					</div>
				}
				panel=<TabChooser {...props} />
			/>
			<Tab
				id="url"
				className="w-100"
				title={
					<div className="flex-centered flex-column w-100 pt-2" >
						<i className="fas fa-link fa-1x "/>
						<div className="small">
							{__("URL")}
						</div>
					</div> 
				}
				panel=<TabSrc {...props} />
			/>
		</Tabs>
	</div> 
} 

export default MediaChooser

const LIMIT_MESAGE: string = __("Maximum file size exceeded: ")

const TabChooser = (props: IMediaChooserProps) : JSX.Element => {
	const [name, setName] = useState( "" )
	const [file, setFile] = useState<File | null>( null )
	const [width, setWdth] = useState<any>( props.width )
	const [url, setUrl] = useState<any>( props.url || "" )
	const [ext, setExt] = useState<string>( url?.substring(url.lastIndexOf(".") + 1) || "" )
	const [limitMessage, setLimitMessage] = useState<string>( '' )
	const { 
		style, 
		id, 
		prefix, 
		height,  
		padding, 
		bg, 
		isDescr, 
		isFileNameHide,
		accept, 
		hideLib, 
		onChange, 
		limitSize
	 } = props
	useEffect(() => {
		setUrl( props.url )
		setName( "" )
		setExt( props.url?.substring((url || "").lastIndexOf(".") + 1) || "" )
	}, [props.url])
	let image = url 
	if (url) 
	{
		
	} 
	else 
	{ 
		image = ""
	} 
	// const isImage : boolean = ["jpg", "jpeg", "gif", "svg", "png", "bmp"].includes( ext )
	// 	|| 
	// 	( 
	// 		typeof url == "string" 
	// 			? 
	// 			( url || " ").toString().indexOf("data:image/") !== -1 
	// 			: 
	// 			false 
	// 	) 
	const isImage : boolean = true
	const _height = height || 70
	const _padding = typeof padding !== 'undefined' ? padding : 20
	const bstyle = {
		...style,
		height: typeof _height === "number" ? _height + _padding * 2 : `calc(${_height} + ${_padding * 2}px)`,
		margin: "3px 3px 3px 0",
		minWidth: width ? width : typeof _height === "number" ? _height + _padding * 2 : `calc(${_height} + ${_padding * 2}px)`,
		backgroundColor: bg, 
		padding: _padding,
	}
	// console.log(bstyle);
	const istyle: React.CSSProperties = {
		...style,
		position: "relative",
		display: "inline-flex",
		justifyContent: "center",
		alignItems: "center",
		minWidth: width ? width :  _height,
		height: _height,
		overflow: "hidden",
	}

	const onClear = () => {
		setName('')
		setUrl('')
		setExt('')
		setWdth( _height )
		setLimitMessage( '' )
		if( onChange) onChange( '', null, '' )
	}
	const onUpload = (media: IWPMediaUploaderResult) => {
		console.log( media )
	}
	const onImageChange = ( evt: ChangeEvent<HTMLInputElement> ) => {
		if(!evt)	return 
		const _height: number | string = height 
			? 
			height 
			: 
			70
		const target: HTMLInputElement = evt.target as HTMLInputElement
		const files: FileList = target.files as FileList
		const file = files[0] 		
		if (!file) return
		if(limitSize && file.size > limitSize) {
			setLimitMessage(LIMIT_MESAGE + Math.floor( file.size / 1000 ) + ' kB') 
			return
		}
		setLimitMessage( '' )
		const img: HTMLImageElement = document.createElement("img")
		img.height = typeof _height === "string" ? parseInt( _height ) : _height
		img.id = `${prefix}imagex` 
		img.alt = "" 
		const reader: any = new FileReader()
		reader.setName = setName
		reader.setFile = setFile
		reader.setUrl = setUrl
		reader.setExt = setExt
		reader.setWdth = setWdth
		reader.id = id
		reader.onChange = onChange
		
		reader.onload = (function (aImg) {
			return function (e: ProgressEvent) { 
				const imgTarget = 	e.target as FileReader 
				reader.setName( file.name )
				reader.setExt( file.name?.substring(file.name.lastIndexOf(".") + 1) || "" )
				reader.setUrl( imgTarget.result ) 
				var img: any = new Image();
				img.onload = () => {
					if( !props.width ) 
						reader.setWdth( img.width / img.height * ( typeof _height === "string" ? parseInt( _height ) : _height ) )
				}
				img.src = imgTarget.result
				//
				reader.onChange(img.src, file, file.name) 
			}
		}(img))
		reader.readAsDataURL(file) 
	}

	const delbtn = url !== "" && url !== undefined && !props.isHideClearBtn
		? 
		<div
			className="btn btn-link"
			style={{
				alignSelf: "start", padding: "3px 6px", marginTop: 4, lineHeight: "3px",
			}}
			onClick={ onClear }
		>
			<i className="fas fa-times" />
		</div>
		: 
		null
	

	const cont = isImage
		?
		<div
			className="image-picto"
			style={{
				height: _height,
				width: width ? width : _height,
				backgroundSize:"cover",
				backgroundPosition:"center",
				backgroundImage: isImage ? `url(${image})` : ''
			}}
		/>
		:
		<div>
			<div className={`fi fi-${ ext.length < 5 ? ext: ext } fi-size-xs`}>
				<div className="fi-content">{ ext.length < 5 ? ext: ext  }</div>
			</div>
		</div>
	const descr = isDescr || !isFileNameHide
		? 
		<span className="media-chooser-descr">
			{ name }
		</span>
		: 
		null
	return ( 
		<div 
			className="media-chooser-cont" 
			style={{ 
				display: "flex", 
				flexDirection: "row", 
				height: typeof _height === "number" ? _height + 16 : `calc(${_height} + 16px)` 
				}}
		>
			<div 
				className={`media_button my_image_upload ${!url ? 'image' : ''}`} 
				style={bstyle} 
				data-image_id={id} 
				prefix={prefix}
			>
				<div className="pictogramm " id={prefix + (id || "").toString()} style={istyle}>
					{ cont }
					<input
						type="file"
						name="image_input_name"
						accept="image/*" //accept={ accept ? accept : "*"}
						style={{
							opacity: 0, 
							width: "100%", 
							height: "100%", 
							position: "absolute", 
							top: 0, 
							left: 0,
						}}
						onChange={onImageChange}
					/>
				</div>
			</div>
			<div className="media-chooser-ext d-flex flex-column">
				<div className="d-flex align-items-center">{delbtn} {descr}</div>
				<div className="small mt-auto ml-2 text-danger ">{ limitMessage }</div>
				
				{ 
					adapter() === "wp" && !hideLib
						?
						<WPMediaUploader
							url={url}
							name={ name }
							file={ file } 
							onUpload={onUpload}
						/>
						:
						null
				}
			</div>
			<div 
				className=" small font-italic text-left position-absolute bottom ml-5 pl-5 pt-2" 
				dangerouslySetInnerHTML={{ 
					__html: 
					limitSize ? __('Maximum file size:') +'<b>' +  Math.floor(limitSize/1000) + '</b> kB' : '' 
				}} 
			/>  
		</div>
	)
}

const TabSrc =  (props: IMediaChooserProps) : JSX.Element => { 
	const [isOpen, setOpen] = useState<boolean>(false)
	const [url, setUrl] = useState<URL>( props.url?.indexOf('empty.png') > 0 ? '' : props.url )	
	useEffect(() => {
		setUrl((props.url))
	}, [props.url])
	const _height = props.height || 70
	const _padding = props.padding || 20
	let image = !props.isUploadHide && url !== "false" ? url : ""
	if (url && url !== "false" ) {
		if (typeof url === "string" && url.indexOf("data:image/") !== 0) {
			image = url
		}
	}
	const bstyle = {
		height: typeof _height === "number" ? _height + _padding * 2 : `calc(${_height} + ${_padding * 2}px)`,
		margin: "3px 3px 3px 0",
		width: props.width ? props.width : typeof _height === "number" ? _height + _padding * 2 : `calc(${_height} + ${_padding * 2}px)`,
		minWidth: props.width ? props.width : typeof _height === "number" ? _height + _padding * 2 : `calc(${_height} + ${_padding * 2}px)`,
		backgroundColor: props.bg,
		padding: _padding,
		backgroundImage: `url(${image})`,
		backgroundSize: "cover",
		backgroundPosition:"center",
		boxShadow: "none",
	}

	const onImageUrl = ( evt: any ) => {
		const element = evt.target as HTMLInputElement;
		setUrl( element.value )
		if(props.onChange)
			props.onChange( element.value, null, "" )
	}
	const onLibrary = (data: any) =>
	{ 
		setUrl( data.large )
		if(props.onChange)
			props.onChange( data.large, -1, data.id)
	}
	const onClear = ( ) =>
	{ 
		setUrl( "" )
		if(props.onChange)
			props.onChange( "", {}, "" )
	}

	return <div
		style={{ height: typeof _height === "number" ? _height + 162 : `calc(${_height} + 16px)` }}
		className="d-flex  w-100"
	> 
		<div 
			className="media_button " 
			style={ bstyle }  
			data-image-id={ props.id } 
			prefix={ props.prefix } 
			onClick={ () => image && image !== "false" ? setOpen( !isOpen ) : console.log( "no image" ) }
		/>
		<div className="d-flex w-100 align-items-start">
			<div className="w-100">
				<input className="input dark form-control ml-2 w-100 " value={image} onChange={onImageUrl} />
				{ 
					adapter() === "wp" && !props.hideLib
						?
						<WPMediaLibrary
							onLibrary={onLibrary}
						/>
						:
						null
				}
			</div> 
		</div>
		<Dialog
			isOpen={isOpen}
			onClose={ () => setOpen( !isOpen) }
		>
			<img alt={ props.id + "" } src={ image } style={{ maxHeight: 700, height: "100%", margin: 0 }} />
			<Button
				intent={Intent.DANGER}
				icon="cross"
				className="position-absolute top right m-1 px-2 py-1"
				onClick={ () => setOpen(false) }
			/>
		</Dialog>
	</div>
}
