import { __ } from "src/libs/utilities"

const menu1 = () => {
    return [
        {
            _id: "autotraining",
            title: __("Autotraining"),
            icon: "/assets/img/manitu/icon4.png",
            thumbnail: "/assets/img/manitu/icons/starshadows.jpg",
            color: "#12234C"
        },
        {
            _id: "research",
            title: __("Research"),
            icon: "/assets/img/manitu/icon1.png",
            thumbnail: "/assets/img/manitu/icons/circle.jpg",
            color: "#12234C"
        },
        // {
        //     _id: "define",
        //     title: __("Define"),
        //     icon: "/assets/img/manitu/icon2.png",
        //     thumbnail: "/assets/img/manitu/icons/leafshield.jpg",
        //     color: "#0F720F" 
        // },
        {
            _id: "impact",
            title: __("Impact"),
            icon: "/assets/img/manitu/icon5.png",
            thumbnail: "/assets/img/manitu/icons/arrowcluster.jpg",
            color: "#601D02"
        }
    ]
}
 
export default menu1