import React from 'react' 
import BasicState from "@/libs/basic-view"  
import ProjectListForm from './projectList/ProjectListForm'
import PhaseLabel from './PhaseLabel'
import AdminFeedLink from '@/modules/pe-admin-module/views/dataTable/AdminFeedLink'

class ProjectListView extends BasicState {
    props: any
    addRender = () => { 
        return <>
            <ProjectListForm {...this.props} />
            <div className="position-absolute top" >
                <PhaseLabel />
            </div>          
            <div className="position-absolute top right" >
                <AdminFeedLink {...this.props} feed_data_type="PEFestProject" />
            </div>  
        </>
    }
} 

export default ProjectListView