import {  MAP_DIALOG_MODE } from "src/libs/yandexMap"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { Button, Dialog, Icon } from "@blueprintjs/core"
import PlaceContent from "../components/PlaceContent"
import { MAP_FILTER } from "../../data/interfaces"
import EventContent from "../components/EventContent"
import PlaceCluster from "./PlaceCluster"
import EventCluster from "./EventCluster"
import TeamContent from "../components/TeamContent"
import TeamCluster from "./TeamCluster"

interface IMapDialogProps {
    isOpen: boolean
    type: MAP_FILTER
    mode: MAP_DIALOG_MODE
    data: any
    size: DIALOG_SIZE
    onClose: () => void
    onOpenLogin: () => void
}
const MapDialog = (props: IMapDialogProps): JSX.Element => {
  return <Dialog
    isOpen={props.isOpen}
    className={props.size}
    portalContainer={document.getElementById("modal-root")!}
    //onClose={props.onClose}
  >
    <div className=" h-100">
        <DialogContent 
          type={props.type}  
          mode={props.mode} 
          data={props.data} 
          onClose={props.onClose} 
          onOpenLogin={props.onOpenLogin}
        />
    </div>
    <Button minimal onClick={props.onClose} className="position-absolute top right px-3 py-2">
        <Icon icon="cross" size={20} color="#FFFFFF"/>
    </Button>
  </Dialog>
} 

export default MapDialog

const DialogContent = ({ type, mode, data, onClose, onOpenLogin }:any) : JSX.Element => {
    switch(mode) {
        case MAP_DIALOG_MODE.MARKER_DATA:
            switch(type) {
              case MAP_FILTER.TEAM:
                return <TeamContent item={ data } onClose={ onClose } onOpenLogin={onOpenLogin}/>
              case MAP_FILTER.EVENTS:
                return <EventContent item={ data } onClose={ onClose } onOpenLogin={onOpenLogin}/>
              case MAP_FILTER.PLACES:
              default:
                return <PlaceContent item={ data } onClose={ onClose } onOpenLogin={onOpenLogin}/>
            }
        case MAP_DIALOG_MODE.CLUSTER_DATA:
          switch(type) {
            case MAP_FILTER.TEAM:
              return <TeamCluster items={ data } onClose={ onClose } onOpenLogin={onOpenLogin}/>
            case MAP_FILTER.EVENTS:
              return <EventCluster items={ data } onClose={ onClose } onOpenLogin={onOpenLogin}/>
            case MAP_FILTER.PLACES:
            default:
              return <PlaceCluster items={ data } onClose={ onClose } onOpenLogin={onOpenLogin}/>
          }    
        case MAP_DIALOG_MODE.NONE:
        default:
            return <div>NONE</div>
    }
}