import { IFabulaMedia } from "@/modules/pe-fabula-module/data/interfaces"
import { useEffect, useState } from "react"
import Speech from "./Speech"

/* Protopia Ecosystem component */
export interface ISpeechEngineProps {
    item: IFabulaMedia
}
const SpeechEngine = (props: ISpeechEngineProps): JSX.Element => { 
    const [ beat, setBeat ] = useState<number>(0)

    const addBeat = () => { 
        if( props.item.speech.length > beat ) {
            setBeat( beat + 1 ) 
        }
    } 

    useEffect(() => {
        setBeat( 0 ) 
    }, [ props.item ])
    
    useEffect(() => {
        window.addEventListener("keydown", addBeat)
        return () => {
            window.removeEventListener("keydown", addBeat)    
        }
    }, [ beat ])

    const onNextBeat = () => {
        setBeat( beat + 1 )
    }
    return <div className='pe-speech-engine-container' >
        <Speech 
            item={ props.item } 
            beat={ beat } 
            onNextBeat={ onNextBeat }    
        /> 
    </div>
}
export default SpeechEngine