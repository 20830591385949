import Layouts from "@/libs/layouts"
import { AppToaster } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Intent, Position, Tooltip } from "@blueprintjs/core"

const CopyRouteEngine = () : JSX.Element =>
{
    const copy = () =>
    {
        const data = JSON.stringify( Layouts() ) 
        const elemDiv = document.createElement('div');
        elemDiv.style.cssText = 'position:absolute; z-index:-100; width:100%; top:0; left:0;'
        elemDiv.setAttribute("id", "myInputCont")
        const textarea = document.createElement("textarea")
        textarea.setAttribute("id", "myInput")
        elemDiv.appendChild(textarea)
        textarea.value = data 
        document.body.appendChild( elemDiv )
		const copyText: HTMLTextAreaElement = document.getElementById("myInput") as HTMLTextAreaElement
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
        document.getElementById("myInputCont")!.remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick", 
			message: __("Routing map copy to clipbord"),
		})
    }
    return <Tooltip content={ __( "Copy routings to clipboard" ) } position={Position.TOP_RIGHT} >
        <div className='btn btn-link text-secondary mr-3 w-100 btn-sm ' onClick={ copy } >
            <i className="fas fa-clone" /> 
        </div>
    </Tooltip>
}
export default CopyRouteEngine