import { IPEStory, PE_STORY_PHASE } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { useEffect, useState } from "react"
import StoryIntroSlide from "./StoryIntroSlide"
import { useNavigate } from "react-router"
import { component_url } from "src/libs/utilities/links"
import { actions } from "src/modules/pe-fabula-module/data/actions"
import { UPDATE_STORY_ACTION } from "src/modules/pe-fabula-module/data/actionTypes"
import { Overlay2 } from "@blueprintjs/core"
import SoundVolumeEngine from "../pult/SoundVolumeEngine"

/* Protopia Ecosystem component */
export interface IStoryIntroProps {
    isMainIntro: boolean
    onFinishPlay: () => void
}
const StoryIntro = (props: IStoryIntroProps): JSX.Element => {
    const PeStory : IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    const [ slideOrder, setSlideOrder ] = useState( 0 )
    const navigate = useNavigate()
    const onStartPlay = () => {
        if( props.isMainIntro) {
            actions(UPDATE_STORY_ACTION, {field: "phase", phase: PE_STORY_PHASE.QUEST})
            navigate( `${ component_url("FabulaView") }/story/${PeStory.id}/quest`)
        }
        else {
            props.onFinishPlay()
        }
    }
    useEffect(() => {
        if( !PeStory.intro.length ) {
            onStartPlay()
        }
    }, [])
    const onNextBeat = () => {
        if( slideOrder < PeStory.intro.length - 1) {
            setSlideOrder( slideOrder => slideOrder + 1 )
        }
        else {
            onStartPlay()
        }
    }
    return !!PeStory.intro.length
        ? 
        <>
            <div className='pe-story-intro-container' onClick={ onStartPlay } >
                <StoryIntroSlide
                    item={ PeStory.intro[slideOrder] }
                    slideOrder={slideOrder}
                    onNextBeat={onNextBeat}
                />
            </div>            
            <SoundVolumeEngine />
        </> 
            
        :
        <></>    
}
export default StoryIntro