import { ID } from '@/libs/interfaces/layouts'
import { __, data_type_feed_url } from '@/libs/utilities'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GET_FEST_DIARIES_ACTIONS } from '../../data/actionTypes'
import { actions } from '../../data/actions'
import { IDiary, IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import DiaryPost from './DiaryPost'

const LastDiary = (props : any) :JSX.Element | null => {
    const festival: IFestival   = useFestDataStore( (state: IFestDataStor) => state.festival )
    const festId : ID           = useFestDataStore( (state: IFestDataStor) => state.festId )
    const [posts, setPosts]     = useState<IDiary[]>( [] as IDiary[] )
    useEffect( () => {
        actions(GET_FEST_DIARIES_ACTIONS, {count:3, land_id: festId })
            .then((resp : IDiary[]) => { 
                console.log( resp )
                setPosts( resp ) 
            })
    }, [ festId ] )
    if(!festival.isDiary) return null
    return <div className=" colored ">
        <div className="short-container">
            <div className="row justify-content-center">
                <div className="col-md-12 d-flex justify-content-center pt-5 pb-3">
                    <div className="role_descr">
                        {__("Last Diaries from Projects")}
                    </div>
                </div>
                {
                    posts.map((post: IDiary) => {
                        return <DiaryPost
                            key={ post.id }
                            item={ post }
                        />
                    })
                }   
                <div className='col-12 d-flex justify-content-center'>
                    <Link 
                        to={ `/${data_type_feed_url("PEFestDiary")}`}
                        className="btn btn-link"
                    >
                        {__("All diaries")}
                    </Link>
                </div>
            </div>
        </div>
    </div>
} 

export default LastDiary