
import { useEffect } from "react"
import { useNavigate } from "react-router"
import { BREACK_STORY_ACTION, GET_FABULA_ACTIONS, STORY_TICK_ACTION } from "src/modules/pe-fabula-module/data/actionTypes"
import { actions } from "src/modules/pe-fabula-module/data/actions"
import { IPEStory, TAKT } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import FabulaPult from "../pult/FabulaPult"
import Tile from "./Tile"

/* Protopia Ecosystem component */
export interface IStoryProps {

}

let cnt: any 
const Story = (props: IStoryProps): JSX.Element => { 
    const PeStory : IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    
    const navigate = useNavigate()
    useEffect(() => {
        actions(
            GET_FABULA_ACTIONS, 
            { id:"1" }
        ) 
        useStorytellingStore.setState({ isBreakOpen: false })
        cnt = setInterval( () => {
            if( !useStorytellingStore.getState().isPaused )
            {
                actions( STORY_TICK_ACTION, {} )
                    .then((res: number) => {
                        if( res >= PeStory.duration ) {
                            actions(BREACK_STORY_ACTION, {} )
                            .then(res => navigate("break"))
                        }
                    }) 
            }
        }, TAKT )
        return () => {
            // clearStory();
            clearInterval( cnt )
        }   
    }, []) 
 
    return <>
        <div 
            className='pe-taleform-container  menu-row overflow-auto w-100 ' 
        >
            <div 
                className="pe-fabula-map"
                style={{
                    width: PeStory.map?.width,
                    height: PeStory.map?.height,
                    backgroundImage: `url(${PeStory.background})`
                }}
            >
                {
                    Array( PeStory.map?.tileX ).fill(1).map((__, i) => {
                        return <div key={i}> 
                        {
                            Array( PeStory.map?.tileY ).fill(1).map((__, ii) => {
                                return <Tile
                                    x={ i }
                                    y={ ii }
                                    item={ PeStory.map?.tiles.filter(tile => tile.x === i && tile.y === ii)[0] } 
                                    key={ ii }
                                />
                            })
                        }
                        </div>
                    })
                }
            </div>
            <div className="p-md-4 position-absolute">
                <span className="px-3 py-1 h bg-dark text-light display-7">
                    { PeStory.title }
                </span>
            </div>
        </div>
        <FabulaPult /> 
    </>
} 
export default Story