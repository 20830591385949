import { IMenuItem } from "@/libs/interfaces/layouts" 
import { Popover, Position } from "@blueprintjs/core"
 
import LoginPanelUnderMenuBtn from "./LoginPanelUnderMenuBtn"

interface IProps {
    children: JSX.Element | JSX.Element[] | null
    menuItem: IMenuItem
    className?: string
}
const LoginPanelPosMenuBtn = (props: IProps) : JSX.Element => { 
    let podmenus: JSX.Element = Array.isArray(props.menuItem.children) && !!props.menuItem.children.length
        ?
        <Popover 
            interactionKind="hover"
            modifiers={{ arrow: { enabled: false } }}
            position={ Position.LEFT_TOP }
            content={<ul className="unsign m-0 p-0 d-flex flex-column">
            {
                props.menuItem.children.map((item: IMenuItem, i) => { 
                    return <LoginPanelUnderMenuBtn
                        key={i}
                        i={i}
                        item={item}
                    /> 
                })
            }
            </ul>}
        >
            <li className="lmenu" >
                {props.children}
            </li>
        </Popover>
        :
        <li className="lmenu" >
            {props.children}
        </li>
    return podmenus
} 

export default LoginPanelPosMenuBtn