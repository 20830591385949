import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import { schema } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { Button, Tooltip } from "@blueprintjs/core"
import { IAdminStore, useAdminStore } from "../../data/store"
import { useState } from "react"
import { useParams } from "react-router"
import { useDataStore } from "@/modules/pe-basic-module/data/store"

const VisibleCheckBox = (props:any) :JSX.Element => {
    const {landId} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const items: any[] = useDataStore((state: any) => state[props.data_type] )
    //const items: any[] = useAdminStore( (state:IAdminStore) => state.items )
    //const setItems: ((items: any[]) => void)    = useAdminStore( (state:IAdminStore) => state.setItems )
    const onClick = () => {
        const post_status = props.item.post_status 
            ?
            props.item.post_status !== "publish"
                ?
                "publish"
                :
                "draft"
            :
            false
        if(!post_status) return 
        setIsLoading(true)
        actions(UPDATE_SINGLE_TYPE, { 
            data_type: props.data_type, 
            id: props.item.id, 
            item: { post_status },
            landId 
        })
            .then(
                response => {
                    setIsLoading(false)
                    const itms: any[] = [...items].map((item: any) => {
                        if(item.id === response.id )
                        {
                            return response
                        }
                        return item
                    })
                    const state: any = {}
                    state[props.data_type] = itms
                    useDataStore.setState( state )
                    // setItems([...items].map((item: any) => {
                    //     if(item.id === response.id )
                    //     {
                    //         return response
                    //     }
                    //     return item
                    // }))
                    //console.log( items )
                },
                error => {
                    console.error(error); 
                })
    }
    const item: any = items.filter(item => item.id === props.item.id)[0] || {}
    return schema()[props.data_type].apollo_fields.post_status
        && 
        <Tooltip
            content={ __( item.post_status &&  item.post_status !== "publish" ? "Hidden" : "Visibled")}
            interactionKind="hover"
        >
            <Button 
                minimal 
                icon={ 
                    isLoading
                        ?
                        "repeat"
                        :
                        item.post_status && item.post_status !== "publish" 
                            ?
                            "eye-off"
                            :
                            "eye-open"
                } 
                className={isLoading ? `ml-1 fa fa-spin ` : `ml-1`}
                onClick={onClick}
            />  
        </Tooltip>
}
 
export default VisibleCheckBox