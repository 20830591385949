import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { LayoutIcon } from "src/libs/useful"

/* Protopia Ecosystem component */
export interface IAudioSettingsProps {

}
const AudioSettings = (props: IAudioSettingsProps): JSX.Element => {
    const voices: boolean = useStorytellingStore((state: IStorytellingStore) => state.voices)
    const music: boolean = useStorytellingStore((state: IStorytellingStore) => state.music)
    const effects: boolean = useStorytellingStore((state: IStorytellingStore) => state.effects)
    
    const onChange = ( audio: string ) => {
        const a = !!useStorytellingStore.getState()[audio]
        useStorytellingStore.setState({[audio] : !a})
    }
    
    return <div className='pe-audio-settings-container'> 
        <div 
            className={`pe-fabula-audio-setting-btn ${voices ? "active" : ""}`}
            onClick={ () => onChange("voices") }
        > 
            <LayoutIcon
                src="/assets/img/fabula/npc-audio-icon.svg"
                width={75}
                height={75}
            /> 
        </div>
        
        <div 
            className={`pe-fabula-audio-setting-btn ${music ? "active" : ""}`}            
            onClick={ () => onChange("music") }
        >
            <LayoutIcon
                src="/assets/img/fabula/music-audio-icon.svg"
                width={75}
                height={75}
            />
        </div> 
        
        <div 
            className={`pe-fabula-audio-setting-btn ${effects ? "active" : ""}`}
            onClick={ () => onChange("effects") }
        >
            <LayoutIcon
                src="/assets/img/fabula/effect-audio-icon.svg"
                width={75}
                height={75}
            />
        </div> 
    </div>
}
export default AudioSettings