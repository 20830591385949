import { useToposStore } from "../pe-topos-module/data/store"
import ExternalProjectFieldsForm from "./views/festAdmin/ExternalProjectFieldsForm"
import MainPageForm from "./views/festAdmin/MainPageForm"
import SettingsForm from "./views/festAdmin/SettingsForm"

const init = () => {
    return new Promise<any>((resolve, reject) => {
        // console.log("init fest")
        useToposStore.setState({
            eventTypeAdminData: {
                ...useToposStore.getState().eventTypeAdminData,
                Festival: {
                    'main-page' : {
                        title: "Main page design",
                        group: "Festival setting", 
                        component: MainPageForm,
                        position: "left",
                        icon: <i className="fas fa-suitcase"></i> 
                    },
                    settings : {
                        title: "Settings",
                        group: "Festival setting", 
                        component: SettingsForm,
                        position: "left"
                    },
                    projects :  {
                        title: "Projects",
                        data_type: "PEFestProject",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left",
                        icon: <i className="fas fa-university"></i>
                    },
                    tracks :  {
                        title: "Tracks",
                        data_type: "PEFestTrack",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left"
                    },
                    destricts :  {
                        title: "Destricts",
                        data_type: "PEFestDestrict",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left"
                    },
                    schools :  {
                        title: "Education Organizations",
                        data_type: "PEFestSchool",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left"
                    },
                    honeycombs :  {
                        title: "Honeycombs",
                        data_type: "PEFestHoneycombs",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        position: "left"
                    },
                    "ex-fields" : {
                        title: "Project ex-fields",
                        group: "Festival setting",
                        undergroup: "Landscape management",
                        component: ExternalProjectFieldsForm,
                        position: "right"
                    },
                    criteries :  {
                        title: "Criteries",
                        data_type: "PEFestCritery",
                        group: "Festival setting",
                        undergroup: "Referee management",
                        position: "left",
                        icon: <i className="fas fa-tachometer-alt"></i>
                    },
                    categories :  {
                        title: "Categories",
                        data_type: "PEFestCategory",
                        group: "Festival setting",
                        undergroup: "Referee management",
                        position: "left",
                        icon: <i className="fas fa-tachometer-alt"></i>
                    },
                    milestones :  {
                        title: "Milestones",
                        data_type: "PEFestMilestone",
                        group: "Festival setting",
                        undergroup: "Referee management",
                        position: "left"
                    },
                    ganres :  {
                        title: "Ganres",
                        data_type: "PEFestGanre",
                        group: "Festival setting",
                        undergroup: "Referee management",
                        position: "left"
                    },
                }
            }
        })
        resolve( true )
        reject( false )
    }) 
}
export default init