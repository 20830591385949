import Moment from "react-moment"
import { Link } from "react-router-dom"
import { getSingleRoute } from "src/libs/layouts"
import { wordTrim } from "src/libs/utilities"
import { INews } from "../../data/interfaces"

interface IProps {
    item: INews
}
const ToposSingleNews = (props: IProps) : JSX.Element => {
    const route = getSingleRoute("News")?.route
    const url : string = route ? `/${ route }/${ props.item.id }` : "" 
    return <Link to={url} className="topos-card-news flex-sm-row flex-column text-force-contrast ">
        <div className="back card" />
        <div className="thum-cont">
            <div className="thumbnail" style={{backgroundImage: `url(${ props.item.thumbnail })`}} />
        </div> 
        <div className=" topos-card-news-content position-relative text-dark">
            <div className=" ">
                <div className=" p-3 ">
                    <div className="text-uppercase small mb-2  opacity_75">
                        { props.item.address }
                    </div>
                    <div className="title text-uppercase mb-2">
                        { props.item.title }
                    </div> 
                    <div className=" mb-2 opacity_75">
                        { wordTrim( props.item.post_content, 6 ) }
                    </div>
                    <div className="text-uppercase small">
                        <Moment locale="ru" format="D.MM.YYYY ">
                            { props.item.date }
                        </Moment>
                        
                    </div>
                </div> 
            </div>
        </div>
    </Link>
    
} 

export default ToposSingleNews