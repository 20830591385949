import React from 'react'  
import { IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'

const FormFieldGroup = (props: any) :JSX.Element | null =>
{ 
    // console.log(props)
    return <div className='row align-items-center'>
        <div className='col-md-4 stroke-dotted text-overflow pr-0'>{ props.fFMatrix?.name }:</div> 
        <div className='col-md-8 ml-15 pl-0'>
        {
            props.fields?.map( (field: any, i: number) => (
                <div 
                    className='px-3 py-1 mx-1 mb-1 pointer title text-md-left text-center' 
                    key={field + "_" + i}
                >
                    {field }
                </div> 

            ))
        }    
        </div>        
    </div>
}

interface IProps {
    isFields: boolean
    className: string
    form_fields: any[]
}
const FormFieldMonitor = (props: IProps) :JSX.Element | null => 
{
    const festival:IFestival = useFestDataStore((state: IFestDataStor) => state.festival )
    if(!props.isFields) return null
    const fFMatrix: any[] = festival.extendedFields || []
    
    return <div className={ props.className } >
            {
                Array.isArray(props.form_fields)
                    ?
                    props.form_fields.map((ff: any, i: number) => {
                        return <FormFieldGroup fFMatrix={fFMatrix[i]} fields={ ff } key={i} i={i} />
                    })
                    :
                    null
            }
        </div> 
}

FormFieldMonitor.propTypes = {}

export default FormFieldMonitor
