import React from "react" 
import BasicState from "@/libs/basic-view"  
import { getAllRoutes, } from "src/libs/layouts" 
import EditorCabinetForm from "./edtorCabinet/EditorCabinetForm"
import { IMenuItem } from "@/libs/interfaces/layouts" 


class EditorCabinetView extends BasicState  {  
    render() {
        return <EditorCabinetForm {...this.props} />
    }
}

export default EditorCabinetView

export const getEditoCabinetRoute = () =>
{
    const routes = getAllRoutes().filter( (route: IMenuItem) => route.component === "EditorCabinetView" )
    return routes[0]

} 