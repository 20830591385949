import { AppToaster } from '@/libs/useful'
import { GET_OPTIONS_ACTION, GET_SIGN_TYPE, PREVIEW_LAYOUT_ACTION, SET_SIGN_TYPE, UPDATE_DICTIONARY_ACTION, UPDATE_OPTIONS_ACTION, UPDATE_SINGLE_TYPE } from './actionTypes'
import asyncControllers from './controller'
import { IAdminStateProps, SIGN_TYPE } from './interfaces'
import { useAdminStore } from './store'
import { Intent } from '@blueprintjs/core'
import { __, initDictionary } from '@/libs/utilities'
import { useMainStore } from '@/settings/zustand'
import { layoutInit } from '@/libs/layouts'
import { IDictionary } from '@/libs/interfaces/layouts'

export const actions = (action: string, data: any): Promise<any> => {
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null))
    let signType: SIGN_TYPE
    switch (action) {
        case SET_SIGN_TYPE:
            promise = new Promise((resolve) => {
                signType = data || localStorage.getItem("cabinet_sign_type") as SIGN_TYPE
                useAdminStore.getState().setSignType(signType)
                localStorage.setItem("cabinet_sign_type", signType)
                resolve(signType)
            })
            return promise
        case UPDATE_SINGLE_TYPE:
            promise = new Promise((resolve) => {
                asyncControllers(action, data).then(response => {
                    resolve(data)
                })
            })
            return promise
        case GET_SIGN_TYPE:
            promise = new Promise((resolve) => {
                signType = useAdminStore((state: IAdminStateProps) => state.signType)
                localStorage.setItem("cabinet_sign_type", signType)
                resolve(signType)
            })
            return promise
        case GET_OPTIONS_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        console.log( response )
                        resolve( response.data.getOptions )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case PREVIEW_LAYOUT_ACTION: 
            promise = new Promise((resolve, reject) => { 
                //useMainStore.setState({layout: data})
				layoutInit( data )
                resolve(true)
            })
            return promise
        case UPDATE_DICTIONARY_ACTION: 
            promise = new Promise((resolve, reject) => { 
                // TODO:: update by controller 
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        //console.log( response.data.changeDictionary.dictionary )
                        try {
                            const dictionary: IDictionary = JSON.parse(response.data.changeDictionary.dictionary)
                            useMainStore.setState({ dictionary })
                            useMainStore.setState({ dictionary_version: useMainStore.getState().dictionary_version + 1 })
                            initDictionary("ru-RU", {})
                        }
                        catch(e) {}
                        finally {
                            resolve( response.data.changeOptions )
                        }                       
                        
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
                resolve( data.dictionary )
            })
            return promise
        case UPDATE_OPTIONS_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        console.log( response )
                        resolve( response.data.changeOptions )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        default:
            return promise
    }

} 