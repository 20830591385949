import React, { useEffect, useState } from "react"
import {PEHelp} from "@/libs/useful"

const LayoutServices = (props: any) :JSX.Element =>
{
    const [isHelpOpen, onHelp] = useState(false)
    const [help_url, onHelpURL] = useState(null)
    useEffect(() =>
    {

    }, [])
    return <>
        <PEHelp
            isOpen={isHelpOpen}
            onClose={onHelp}
            url={help_url!}
        />
    </>
}
export default LayoutServices