import { IPETest, PE_TEST_QUESTION_TYPE } from "../interfaces"
import { testCategories } from "./testCategories"

export const test7 = (): IPETest => {
    return {
        id: 7,
        title: "Краснодарский край",
        post_content: "Викторина Краеведение  »  4 класс",
        post_author: {
            id: "1",
            display_name: "Геннадий Глазунов",
            roles: []
        },
        testCategory: testCategories().filter(t => t.id === 1)[0],
        pe_academic_discipline: [{
            color: '#214700',
            id: '25',
            order: 6,
            post_content: '',
            thumbnail: 'false',
            title: 'Литература',
        }],
        thumbnail: "https://i.pinimg.com/236x/ea/49/cc/ea49ccc82221eabc4ad32a1902551ad2.jpg",
        questions: [
            {
                id: 1,
                title: "Какой природной зоны нет на территории Краснодарского края?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "лесостепь",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "степь",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "тайга",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 2,
                title: "Мой край расположен на материке:",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Евразия",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Африка",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Северная Америка",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 3,
                title: "Самые плодородные почвы нашего края:",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "каштановые",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "чернозём",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "серые лесные",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 4,
                title: "Какой из регионов не граничит с Краснодарским краем?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Волгоградская область",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Республика Адыгея",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Ставропольский край",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 5,
                title: "К полезным ископаемым относятся:",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "кирпич,бетон,бензин",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "песок,стекло,древесина",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "нефть,газ.глина",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 6,
                title: "Учёный,который изучает историю,обычаи,традиции различных народов:",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "этнограф",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "психолог",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "археолог",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 7,
                title: "Время появления древних людей на Кубани:",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "5 тысяч лет назад",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "более 50 тысяч лет назад",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "более миллиона лет назад",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 8,
                title: "В какой природной зоне нашего края выращивают хурму,инжир,фейхоа?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "альпийские луга",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "субтропики",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "степи",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 1,
                title: "Какой из водоёмов искусственный:",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "озеро",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "лиман",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "водохранилище",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                title: "Найди лишнее в определении:«Кубань — главная ...... в России»",
                id: 10,
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "кузница",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "житница",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "здравница",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 11,
                title: "Что собой напоминает жилище меотов?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "конус",
                        thumbnail: "https://sportishka.com/uploads/posts/2022-03/thumbs/1647362084_66-sportishka-com-p-tipi-zhilishche-indeitsev-turizm-krasivo-f-71.jpg",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    }, 
                    {
                        id: 3,
                        title: "гриб",
                        thumbnail: "https://otvet.imgsmail.ru/download/4e589c2173ef8b166a79d92c6a2bd06f_i-11317.jpg",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "корзину",
                        thumbnail: "https://avatars.mds.yandex.net/i?id=90286c6cfa3e472ba563e47f2359a61ffea97eca-9181163-images-thumbs&n=13",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 1,
                title: "На сколько частей делилась хата казака?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "на две",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "на три",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "на четыре",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
        ],
        is_show_answer:false, 
        is_show_results:true, 
        is_shuffle:true, 
        is_timed:false, 
        duration: 20,
        try_count: 10
    }
}