import { yandex_map_api_key } from "@/settings/map"
import { useEffect, useId, useState } from "react"
import { IMarker } from "."
import presets from "./presets.json"
import { ID } from "../interfaces/layouts"

let cluster: any 
export interface IMapProps {
    id?: ID
    coordinates?: any[]
    width?: number | string
    height?: number | string
    markers?: IMarker[] 
    children?: null | JSX.Element | JSX.Element[]
    onCoordinates?: (coordinates: number[]) => void
    onClick?: (coordinates: number[]) => void
    onClusterClick?: (e: any, coordinates: number[], clusterData: any[]) => void
    onWeel?: (coordinates: number[]) => void
    onLoad?: () => void
    onBeforeDrawMap?: () => IMapProps 
    isOptimizeZoom? : boolean
}
const Map = (props: IMapProps) :JSX.Element => {
    const [map, setMap]         = useState<any>(false) 
    
    let id: ID = useId() as ID
    id = props.id || id
    useEffect(() => {
        const { ymaps } = window
        const { ymapReady } = window
        if (ymapReady || ymaps) { 
            drawMap()
            return
        }
        window.ymapReady = true
        const el: any = document.createElement("script") 
        el.type = "text/javascript"
        el.src = `https://api-maps.yandex.ru/2.1/?load=package.full&lang=ru_RU&apikey=${yandex_map_api_key()}`
        el.async = true
        el.id = "ymap-jssdk"
        el.onload = function () {
            window.ymaps?.ready(() => { 
                drawMap()
            })
            if(props.onLoad) props.onLoad()
        }
        document.getElementsByTagName("head")[0].appendChild(el) 
        window.ymaps?.ready( drawMap )
        }, []) 

    useEffect(() => { 
        //console.log( "MAP.markers:", props.markers )
        if( cluster ) {
            cluster.removeAll()  
            addMarkers()   
        }  
    }, [ props.markers ])
    
    const drawMap = () => {
        let data: any = {}
        if(props.onBeforeDrawMap) {
            data = {...data, ...props.onBeforeDrawMap( )}
        }
        const _map = new window.ymaps.Map(
            `map_${ id }`, 
            {
                center:  data.coordinates || props.coordinates || [ 55.76, 37.64 ],
                zoom: props.coordinates ? props.coordinates[2] : 7,
                controls: [  ] //'geolocationControl'
            }
        )
        var customItemContentLayout2 = window.ymaps.templateLayoutFactory.createClass(
			// Флаг "raw" означает, что данные вставляют "как есть" без экранирования html.
			'<div  class="ballon_header lead text-center font-weight-light">{{ properties.balloonContentHeader|raw }}</div>' +
			'<div class="ballon_body text-center">{{ properties.balloonContentBody|raw }}</div>'
			// +'<div class="ballon_footer text-center text-secondary">{{ properties.balloonContentFooter|raw }}</div>'
		); 
        
        const markers = data.markers || props.markers || []
        if(Array.isArray(markers)) {            
            var clusterer = new window.ymaps.Clusterer(
                {
                    //// Зададим макет метки кластера.
                    // clusterIconLayout: window.ymaps.templateLayoutFactory.createClass(
                    //     '<div class="clusterIcon"><div class="place_flag"></div>{{ properties.geoObjects.length }}</div>'
                    // ),
                    preset: 'islands#invertedVioletClusterIcons',
                    //groupByCoordinates: true,
                    clusterDisableClickZoom: true,
                    clusterOpenBalloonOnClick: false,
                    clusterBalloonContentLayout: 'cluster#balloonCarousel',
                    clusterBalloonItemContentLayout: customItemContentLayout2,
                    clusterBalloonContentLayoutWidth: 230,
                    clusterBalloonContentLayoutHeight: 110,
                    // Макет метки кластера pieChart.
                    clusterIconLayout: 'default#pieChart',
                    // Радиус диаграммы в пикселях.
                    clusterIconPieChartRadius: 35,
                    // Радиус центральной части макета.
                    clusterIconPieChartCoreRadius: 15,
                    // Ширина линий-разделителей секторов и внешней обводки диаграммы.
                    clusterIconPieChartStrokeWidth: 3,
                    clusterHideIconOnBalloonOpen: false,
                    geoObjectHideIconOnBalloonOpen: false,
                    gridSize:128
                },
                {
                    gridSize:128
                }
            )
            clusterer.hint = ''
            cluster = clusterer
            _map.geoObjects.add(clusterer)
            setTimeout(() => addMarkers(), 30)
            if( props.onClusterClick ) {
                clusterer.events.add("click", (e:any) => {
                    const children = e.get("target").properties._data.geoObjects
                    if(!children)  return // маркер тоже кластер!!!
                    props.onClusterClick!(
                        e, 
                        [...e.get("coords"), e.get("map").getZoom()], 
                        children?.map( (g:any) => g.properties._data.markerData.data )
                    )
                })
            }
            
            
        }
        if(props.onClick) { 
            _map.events.add("click", (e:any) => {
                const coords: number[] = e.get("coords")
                coords.push(e.get("map").getZoom() )
                if( props.onClick ) {
                    props.onClick(coords ) 
                }   
            })
        }
        if(props.onWeel) {
            _map.events.add("wheel", (e:any) => {
                const coords = e.get("map").getCenter()
                coords.push(e.get("map").getZoom())
                if( props.onWeel ) {
                    props.onWeel(coords ) 
                }   
            })
            _map.events.add("actionend", (e:any) => {
                const coords = e.get("map").getCenter()
                coords.push(e.get("map").getZoom())
                if( props.onWeel ) {
                    props.onWeel(coords ) 
                }   
            })
        }
        if(props.isOptimizeZoom) {            
            // console.log( cluster.getBounds() )
            // _map.setBounds(cluster.getBounds(), {
            //     checkZoomRange: true, zoomMargin:11
            // }); 
        }
        if( !map ) {
            setMap( _map) 
        }
    }
    const addMarkers = ( _markers: IMarker[] = []) => {
        const markers: IMarker[] = _markers.length > 0  ? _markers : props.markers || []
        //console.log( "MAP.addMarkers", props.markers )
        if( !Array.isArray(markers)) return 
        markers.forEach(( marker: IMarker ) => {
            const _marker = new window.ymaps.Placemark(
                Array.isArray(marker.coordinates) ? marker.coordinates : [ 55.76, 37.64 ],
                {
                    geometry : {
                            type: "Point",
                            coordinates: Array.isArray(marker.coordinates) ? marker.coordinates : [ 55.76, 37.64 ]
                    }, 
                    propirties: { 
                        hintContent: marker.hintContent,
                        clusterCaption: 'Geo object №1',
                        balloonContentBody: 'Balloon content for geo object №1'
                    },
                    markerData: {...marker}, 
                    hintContent:  marker.hintContent,
                },
                {
                    draggable: marker.draggable, 

                     // Необходимо указать данный тип макета.
					iconLayout: 'default#imageWithContent',
					// Своё изображение иконки метки.
					iconImageHref: 'assets/img/topos/circle.svg',
					// Размеры метки.
					iconImageSize: [ presets.iconSize , presets.iconSize ],
					// Смещение левого верхнего угла иконки относительно
					// её "ножки" (точки привязки).
					iconImageOffset: [-presets.iconSize/2, -presets.iconSize/2],
					iconImageFill: "#FFFFFF",
					// Смещение слоя с содержимым относительно слоя с картинкой.
					iconContentOffset: [0,0],
					// Макет содержимого.
					iconContentLayout: MarkerIcon({ 
                        color: marker.color || '#455968', 
                        iconSize: presets.iconSize, 
                        iconBorder: presets.iconBorder,
                        iconPadding: presets.iconPadding,
                        iconBorderRadius: presets.iconBorderRadius,
                        icon: marker.icon
                    }), 
					hasBalloon: false,
					hasHint: true,  
                    iconColor: marker.color || '#455968'
                }
            ) 
            if(marker.onClick) {
                _marker.events.add("click", (e:any) => {
                    marker.onClick!(e, [...e.get("coords"), e.get("map").getZoom()], marker.data)
                })
            }
            cluster.add( _marker ) 
        })
        
    }
    return (
        <div className="w-100 position-relative" style={{ height : props.height }}>   
            <div 
                id={ `map_${id}` } 
                style={{ width: props.width || 600, height: props.height || 400 }}
            /> 
            {props.children}
        </div>
    )
} 

export default Map



declare global {
    interface Window { 
        ymaps: any; 
        ymapReady: any; 
    }
}


const MarkerIcon = ({ color, iconSize, iconBorder, icon, iconPadding, iconBorderRadius }: any) => { 
    return window.ymaps.templateLayoutFactory.createClass(
        `<div 
            style="width:${iconSize - iconBorder * 2 + iconPadding}px;
            height:${iconSize - iconBorder * 2 + iconPadding}px; 
            border:${iconBorder}px solid ${ color }; 
            -webkit-border-radius: ${iconBorderRadius}%;
            border-radius: ${iconBorderRadius}%;
            background-color: #FFFFFF;" 
        >
            <div 
                style='background-image:url(${icon}); 
                width:  ${iconSize - iconBorder * 2 + iconPadding}px; 
                height: ${iconSize - iconBorder * 2 + iconPadding}px; 
                background-size:${iconSize - iconBorder * 2 }px ${iconSize - iconBorder * 2 }px; 
                background-position: center;
                background-repeat: no-repeat; 
                transform: translateX(-${ iconBorder }px) translateY(-${ iconBorder }px);
                '
            >
            </div>        
        </div>`
    );
}
