import { useEffect, useRef, useState, Children, cloneElement } from 'react'
import Page from './Page'
import { CarouselContext } from './carousel-context' 
import './Carousel.css' 
import '../style.scss'

const TRANSITION_DURATION = 300

interface ICarouselProps {
  children: any[]
  infinite: boolean
  height?: number | string | 200
  width?: number |  450
  transitionDuration?: number | 300
  count: number | 1
  autoplay: boolean 
  afterCreate? : () => void
  afterOffset? : () => void
  aroowShift?:number

}
export const Carousel = ({ children, infinite, count, autoplay, ...props }: ICarouselProps) => {
  const [ _interval, set_Interval] = useState<any>( )
  const [ offset, setOffset ] = useState(0)
  const [ width, setWidth ] = useState( props.width )
  const [ pages, setPages ] = useState<any[]>( [] )
  const [ clonesCount, setClonesCount ] = useState({ head: 0, tail: 0 })
  const [ transitionDuration, setTransitionDuration ] = useState( props.transitionDuration || TRANSITION_DURATION )

  const windowElRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (infinite) {
      setPages([
        cloneElement(children[Children.count(children) - 1]), // head: 1
        ...children,
        cloneElement(children[0]), // tail: 1
      ])
      setClonesCount({ head: 1, tail: 1 })
      return
    }
    setPages(children) 
  }, [children, infinite])

  useEffect(() => {
    const resizeHandler = () => {
      const windowElWidth = windowElRef.current!.offsetWidth 
      // console.log('resized', windowElWidth)
      setWidth(windowElWidth)
      setOffset(-(clonesCount.head * ( width || 450))) // to prevent wrong offset
    } 
    resizeHandler()
    window.addEventListener('resize', resizeHandler) 
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [clonesCount, width])

  useEffect(() => {
    if (transitionDuration === 0) {
      setTimeout(() => {
        setTransitionDuration(props.transitionDuration || TRANSITION_DURATION)
      }, props.transitionDuration || TRANSITION_DURATION)
    }
  }, [transitionDuration, props.transitionDuration])

  useEffect(() => {
    if (!infinite) return

    // с элемента 0 (clone) -> к предпоследнему (реальный)
    if (offset === 0) {
      setTimeout(
        () => {
          setTransitionDuration(0)
          setOffset(-(( width || 450) * (pages.length - 1 - clonesCount.tail)))
        }, 
        props.transitionDuration || TRANSITION_DURATION
      )
      return
    }
    // с элемента n (clone) -> к элементу 1 (реальный)
    if (offset === -(( width || 450) * (pages.length - 1))) {
      setTimeout(
        () => {
          setTransitionDuration(0)
          setOffset(-(clonesCount.head * ( width || 450)))
        }, 
        props.transitionDuration || TRANSITION_DURATION
      )
      return
    }
  }, [offset, infinite, pages, clonesCount, width])

  useEffect(() => { 
    if(autoplay) {
      set_Interval(
          setInterval(() => {
            setOffset((currentOffset) => {
              const newOffset = currentOffset - ( width || 450)
              const maxOffset = -(( width || 450) * (pages.length - 1))
              return Math.max(newOffset, maxOffset)
            })
          }, 2000)
      )
    }
    else {
      clearInterval( _interval )
    }
    return () => {
      clearInterval( _interval )
    }
  }, [autoplay])
  
  useEffect(() => {
    if(props.afterOffset) {
      props.afterOffset()
    }
  }, [ offset ])

  const handleLeftArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + ( width || 450)
      return Math.min(newOffset, 0)
    })
  }
  const handleRightArrowClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - ( width || 450)
      const maxOffset = -(( width || 450) * (pages.length - 1))
      return Math.max(newOffset, maxOffset)
    })
  }

  return (
    <CarouselContext.Provider value={{ width: ( width || 450) / (count | 1) }}>
      <div className="main-container" style={{ height: props.height}}>
        <div 
          className="carousel-arrow prev" 
          onClick={handleLeftArrowClick} 
          style={{left: props.aroowShift ? props.aroowShift : 0}}
        >
          <i className="fas fa-angle-left" />
        </div>
        <div className="window" ref={windowElRef}>
          <div
            className="all-pages-container"
            style={{
              transform: `translateX(${offset}px)`,
              transitionDuration: `${transitionDuration}ms`,
            }}
          >
            {pages}
          </div>
        </div>
        <div 
          className="carousel-arrow next" 
          onClick={handleRightArrowClick} 
          style={{right: props.aroowShift ? props.aroowShift : 0}}
        >
          <i className="fas fa-angle-right" />
        </div>
      </div>
    </CarouselContext.Provider>
  )
}

Carousel.Page = Page
