import { Navigate, Route, Routes } from "react-router"
import SingleMenuItemForm from "./SingleMenuItemForm"
import { IAdminMenuStore, useAdminMenuStore } from "../../data/store"
import { ID } from "@/libs/interfaces/layouts" 

const MenuForm = (props:any) : JSX.Element => {
  const currentRouteID: ID = useAdminMenuStore( ( state:IAdminMenuStore ) => state.currentRouteID )
  
  
  return <div className="layout-state">
    <Routes>
      <Route path="*" element={<Navigate to={ currentRouteID.toString() } />} />
      <Route path=":id" element={<SingleMenuItemForm />} />
    </Routes>
  </div> 
} 

export default MenuForm