
import { IOpponent } from "@/modules/pe-ludens-module/data/interfaces"
import { useEffect, useState } from "react"
import { Route, Routes } from "react-router"
import { GET_PARTNERS_ACTION } from "../../data/actionTypes"
import { actions } from "../../data/actions"
import AttackContainer from "./attack/AttackContainer"
import Opponents from "./attack/Opponents"
import OpponentMatrix from "./matrix/OpponentMatrix"
 
const Attack = () : JSX.Element => {  
  const [users, setUsers] = useState<IOpponent[]>([])
  useEffect(() => {
    actions(GET_PARTNERS_ACTION, {}).then(resp => setUsers(resp ))
  }, [])  
  return <Routes>
    <Route element={<AttackContainer />}>
      <Route index element={ <Opponents users={users} /> } />
      <Route 
        path="opponents/:userId" 
        element={ <OpponentMatrix users={users} /> } 
      />
    </Route>
  </Routes>  
}
 

export default Attack