import { IGoods } from "../interfaces"

export const goods = () : IGoods[] => {
    let _list : IGoods[] = [
        {
            id: 6,
            title: `Новогодние игрушки из Удмуртии`,
            undertitle: ``,
            thumbnail: "https://thumb.tildacdn.com/tild3933-3235-4637-b563-306563393134/oxFsyuZb8Mg.jpg",
            post_content: `Новогодние игрушки, сделанные ребятами своими руками. Наставник: Бегенеева  Елена Рудольфовна`,
            date: "2022",
            url: "",
            images: [],
            address: "",
            tags: [],
        },
        {
            id: 7,
            title: `Брендовый блокнот и сувениры из села Маминское`,
            undertitle: ``,
            thumbnail: "https://static.tildacdn.com/tild3961-3030-4563-b663-323735386465/IMG_20231011_111148.jpg",
            post_content: `Активисты Мамнского историко-краеведческого музея представляют свой лот на витрине призов нашего Марафона!
            <p>И это: брендовый блокнот центра и сувенир от активистов Маминского школьного музея. Ребята сейчас, как раз занимаются разработкой сувенирной продукции своего села.
            <p>Спасибо! Отличный сувенир!`,
            date: "2022",
            url: "",
            images: [],
            address: "",
            tags: []
        },
        {
            id: 8,
            title: `Городецкий пряник`,
            undertitle: ``,
            thumbnail: "https://static.tildacdn.com/tild3462-3436-4532-b230-633266646165/photo.jpeg",
            post_content: `<p> <i>
            «И на вкус хорош, и лицом пригож» — так говорят о знаменитых городецких пряниках. Лакомство с трёхсотлетней историей издавна поражало своей красотой. Раньше без них невозможно было представить ни одно торжество. Городецкий пряник весом 20 фунтов (больше 8 килограммов) дарили и царской семье Николая II. Излюбленное угощение привозили и на Нижегородскую ярмарку, откуда оно расходилось за пределы губернии.
            </i><p>
            Городецкие пряники!
            <p>Вот такой вкусный приз достанется одной из команд !`,
            date: "2022",
            url: "",
            images: [],
            address: "",
            tags: []
        },
        {
            id: 9,
            title: `Сувенирная продукция Полевского археологического отряда`,
            undertitle: ``,
            thumbnail: "https://static.tildacdn.com/tild6234-3536-4931-b535-306163383138/5bcoYz1Zy3U.jpg",
            post_content: `<p> Археологический отряд "Беркут" привозит интересные и уникальные находки из своих экспедиций. Некоторые - хранятся в Музее клуба, некоторые "уезжают" в серьёзные археологические музейные коллекции.
            <p>В своей лаборатории ребята научились делать копии своих находок и создали уникальные сувениры на основе своих находок.
            <p>Именно такой набор сувениров достанется одной из команд-участников`,
            date: "2022",
            url: "",
            images: [
                "https://static.tildacdn.com/tild3931-3761-4131-b638-356663336534/AaZmNTdx49M.jpg",
                "https://static.tildacdn.com/tild3135-6337-4234-b737-633732373432/DJi1ZoAMKBY.jpg" 
            ],
            address: "",
            tags: []
        }, 
        {
            id: 10,
            title: `Книги, написанные в Царском Селе`,
            undertitle: ``,
            thumbnail: "https://static.tildacdn.com/tild6235-3230-4032-b232-383136663261/Qj8ymUDMTB0.jpg",
            post_content: `<p> Команда Музейного комплекса школы №606, Пушкин, С-Петербург предлагает ещё один вариант для призового фонда!
            Набор "Книги, написанные в Царском Селе":
            <p>1. А.С. Пушкин "Сказка о Царе Салтане" с иллюстрированным комментарием, рисунками Ивана Билибина. Мягкая обложка, 46 с.
            <p>2. М.Ю. Лермонтов "Мцыри"/ серия "Библиотечка школьника". Мягкая обложка, 62 с.
            <p>3. Д.Н. Мамин-Сибиряк "Алёнушкины сказки" (Букинистический экземпляр, издание 1989 года), Мягкая обложка, 32 с.
            <p>4. Стикерпак "Книги, написанные в Царском Селе" (количество экземпляров по числу участников проекта)`,
            date: "2022",
            url: "",
            images: [
                "https://static.tildacdn.com/tild3830-3639-4235-b130-323736663839/02tHKrhN6fI.jpg",
                "https://static.tildacdn.com/tild3661-3166-4261-b433-396265386436/XfgNKuamXI4.jpg" 
            ],
            address: "",
            tags: []
        },
        {
            id: 11,
            title: `Книги (и не только) полезные и очень интересные! от Марии Михайловны Ленской`,
            undertitle: ``,
            thumbnail: "https://static.tildacdn.com/tild3432-3663-4931-b864-373931653762/photo_2023-09-27_11-.jpg",
            post_content: `<p> Прекрасный подарок от куратора трека "Топос. Краткий метр" Марии Михайловны, Санкт- Петербург:
            <p>Лот 20: "От идеи до злодея. Учимся создавать истории вместе с PIXAR".Книга
            <p>Лот 21: "Путь к волшебству", Т.Д. Зинкевич-Евстигнеева. Книга;
            <p>Лот 22: "Культура русского народа" (обычаи, обряды, занятия, фольклор). Книга
            <p>Лот 23: Символическая кружка в виде объектива
            
            <p>А еще к каждому из этих призов будет маленький, но очень душевный подарочек, который будет сюрпризом для тех, кому достанутся эти призы`,
            date: "2022",
            url: "",
            images: [
                "https://static.tildacdn.com/tild6263-3939-4064-a230-663863353935/photo_2023-09-27_11-.jpg",
                "https://static.tildacdn.com/tild3661-3166-4261-b433-396265386436/XfgNKuamXI4.jpg",
                "https://static.tildacdn.com/tild3965-3537-4136-a537-646463303631/photo_2023-09-27_11-.jpg",
                "https://static.tildacdn.com/tild3136-3266-4035-b637-303039646439/photo_2023-09-27_11-.jpg",
            ],
            address: "",
            tags: []
        },
        {
            id: 12,
            title: `Сувенир «Игрушка-свистулька «Курский соловей» в традициях кожлянских мастеров`,
            undertitle: ``,
            thumbnail: "https://static.tildacdn.com/tild3465-3939-4966-b232-666364633963/IMG_6584.JPG",
            post_content: `<p>Призы для программы Лето с Краефестом от детского объединения «Туристы-исследователи», участников весеннего исследовательского Марафона "КраеФест-2023", в треке «Брендирование в краеведении. Сувенир –память о месте», проект "Путешествие по Курскому краю".
            <p>Сувенир «Игрушка-свистулька «Курский соловей» в традициях кожлянских мастеров.
            <p>Педагог дополнительного образования ОБУДО «Курский областной центр туризма» Котова Елена`,
            date: "2022",
            url: "",
            images: [
                "https://static.tildacdn.com/tild3536-6534-4063-b832-343266386666/IMG_6585.JPG",
                "https://static.tildacdn.com/tild3232-3762-4664-a338-323730393964/IMG_6588.JPG",
                "https://static.tildacdn.com/tild6338-6337-4363-a530-363630303233/IMG_6590.JPG",
                "https://static.tildacdn.com/tild6533-3532-4935-b666-643533643561/IMG_6592.JPG"
             ],
            address: "",
            tags: []
        },
        {
            id: 13,
            title: `Набор стикерпаков`,
            undertitle: ``,
            thumbnail: "https://static.tildacdn.com/tild3932-6366-4661-a464-376331656436/photo_2023-07-07_13-.jpg",
            post_content: `<p>Набор стикерпаков, посвященных основным событиям и мероприятиям, организованных в разное время проектно-исследовательской лабораторией "Топос. Краеведение": акселератор образовательных экосистем, Исследовательская школа в Екатеринбурге, исследовательская школа в Ульяновске`,
            date: "2022",
            url: "",
            images: [ ],
            address: "",
            tags: []
        },
        {
            id: 1,
            title: `"БесКРАЙние ФЕСТиваль" `,
            undertitle: `Брошюра под ред. Глазуновой О.В`,
            thumbnail: "https://static.tildacdn.com/tild6630-3462-4661-a138-353533396265/Screenshot_7.jpg",
            post_content: `В сборнике собраны материалы, статьи, иллюстрации, фотографии, которые помогут составить представление о событиях, этапах, педагогических технологиях, методиках, программах этого большого комплексного мероприятия. Адресован педагогам, наставникам проектной и исследовательской деятельности, краеведам, лидерам школьных команд`,
            date: "2022",
            url: "https://kraeved.press/assets/files/бескрайний%20фестиваль.pdf",
            images: [],
            address: "",
            tags: []
        },
        {
            id: 2,
            title: `Типовая модель для создания новых мест дополнительного образования для туристско-краеведческой направленности`,
            undertitle: `Брошюра под ред. Глазуновой О.В`,
            thumbnail: "https://thumb.tildacdn.com/tild3933-6437-4438-b363-333532353931/Screenshot_10.jpg",
            post_content: `Материалы разработки модели создания новых мест для детского дополнительного образования туристско-краеведческой направленности. Национальный проект "Образование". Федеральный проект "Успех каждого ребенка".
            <p>Редакция: О.В. Глазунова, А.С Обухов, Л.М. Проценко, А.В. Павлов`,
            date: "2020",
            url: "https://kraeved.press/assets/files/бескрайний%20фестиваль.pdf",
            images: [],
            address: "",
            tags: []
        },
        {
            id: 3,
            title: `Образовательный дизайн программ и проектов в туризме и краеведении`,
            undertitle: ``,
            thumbnail: "https://static.tildacdn.com/tild3236-6431-4230-a632-383432356336/Screenshot_11.jpg",
            post_content: `Материалы разработки модели создания новых мест для детского дополнительного образования туристско-краеведческой направленности. Национальный проект "Образование". Федеральный проект "Успех каждого ребенка".
            <p>Редакция: О.В. Глазунова, А.С Обухов, Л.М. Проценко, А.В. Павлов`,
            date: "2020",
            url: "https://kraeved.press/assets/files/программаТопос_v1.pdf",
            images: [],
            address: "",
            tags: []
        },
        {
            id: 4,
            title: `Проектно-исследовательская педагогическая лаборатория «Топос. Краеведение»`,
            undertitle: `Сборник `,
            thumbnail: "https://static.tildacdn.com/tild6461-3032-4265-b838-646362396365/Screenshot_8.jpg",
            post_content: `Авторы: Глазунова О.В., Обухов А.С, Проценко Л.М., Кожаринов М.Ю.
            <p>Программа «Топос. Краеведение» ━ о синтезе традиционного подхода краеведов, исследователей и современного экосистемного подхода к развитию открытых образовательных сред, подготовке наставников и научных руководителей, об организации современного молодежного краеведческого движения.`,
            date: "2020",
            url: "https://kraeved.press/assets/files/бескрайний%20фестиваль.pdf",
            images: [],
            address: "",
            tags: []
        },
    ]

    return _list as IGoods[]
}