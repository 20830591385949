import { useMainStore } from "./zustand"

const setConfig = (configData: any) : any => {
  let config: any = useMainStore.getState().config
  config = {...config, ...configData} 
  useMainStore.setState({config})
  return config
}
export function initConfig(configData : any) : Promise<boolean>
{
  let config: any = useMainStore.getState().config
  if( configData.server_url === "external") {
    return new Promise<boolean>((resolve, reject) => {
      fetch("/assets/config.json")
      .then(response => response.json())
      .then(
        (resp: any) => {
          setConfig({ ...config, ...configData, server_url: resp.url }) 
          resolve(true)
        },
        (error) => {
          console.error(error)
          reject(false)
        }
      )
    })
    
  }
  else {
    setConfig({...config, ...configData})
    return new Promise<boolean>(resolve => resolve(true))  
  }
   
}

export function google_analytics__() {
  let config: any = useMainStore.getState().config
  return config.google_analytics ? config.google_analytics : "UA-000000-01"
}

export function app_url() {
  let config: any = useMainStore.getState().config
  if (config.app_url) return config.app_url
}

export function gitlab_private_token() {
  let config: any = useMainStore.getState().config
  if (config.gitlab_private_token) return config.gitlab_private_token
}

export function link_type__() {
  let config: any = useMainStore.getState().config
  if (config.link_type) return config.link_type
}

export function server_url__() {
  let config: any = useMainStore.getState().config
  if (config?.server_url) return config.server_url
}

export function sourse_url__() {
  let config: any = useMainStore.getState().config
  if (config.server_url) {
    const ser = [...config.server_url.split("/")]
    ser.pop()
    return ser.join("/")
  }
}
export function upload_url__() {
  let config: any = useMainStore.getState().config
  if (config.upload_url) return config.upload_url
}

export function assertion_token__() {
  let config: any = useMainStore.getState().config
  if (config.assertion_token) return config.assertion_token
}

export function yandex_map_api_key__() {
  let config: any = useMainStore.getState().config
  if (config.yandex_map_api_key) return config.yandex_map_api_key
  return "NONE"
}
export function vk_api_key() {
  let config: any = useMainStore.getState().config
  if (config.vk_api_key) return config.vk_api_key
  return "NONE"
}
export function yandex_access_token() {
  let config: any = useMainStore.getState().config
  if (config.yandex_access_token) return config.yandex_access_token
  return "NONE"
}
export function yandex_client_secret() {
  let config: any = useMainStore.getState().config
  if (config.yandex_client_secret) return config.yandex_client_secret
  return "NONE"
}
export function yandex_client_id() {
  let config: any = useMainStore.getState().config
  if (config.yandex_client_id) return config.yandex_client_id
  return "NONE"
}

export function app_layouts__() {
  let config: any = useMainStore.getState().config
  if (config.app_layouts) return config.app_layouts
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let config: any = useMainStore.getState().config
  return config
}