import { __ } from "src/libs/utilities"
import { Intent, Tag } from "@blueprintjs/core" 
import EventRequestForm from "../event/EventRequestForm"
import { events } from "../../data/mocks/events"
import ToposBreaks from "../ads/ToposBreaks"
import ToposLinks from "./ToposLinks"

const ToposHead = () : JSX.Element => {
 
    return (
        <div className="topos-head-container w-100 flex-centered ">
            <div className="row w-100 h-100">
                <div className="col-xl-8 col-12 topos-head-left">
                    <div className="thumbnail w-100 h-100 card-top-img" style={{backgroundImage:`url(https://wpfest.ru/wp-content/uploads/sites/103/2023/08/6370ec8a04dfc1205584c6a4f846c2ac-1.jpg)`}}>
                        <div className="position-absolute bottom w-100 p-3 z-index-100">
                            <div className="pb-3">
                                <Tag className="px-3 py-1 text-uppercase" round intent={Intent.SUCCESS} >{__("Special project")}</Tag> 
                            </div>                            
                            <div className="row">
                                <div className="col-xl-7 ">
                                    <div className="h display-5 text-light">Что мы натворили?</div>
                                    <div className="description">Межрегиональный просветительский марафон. Финальный этап</div>
                                    
                                </div>
                                <div className="col-xl-5 d-flex align-items-end justify-content-xl-end mt-2">
                                    <EventRequestForm  item={ events()[0] } buttonClassName="py-3 px-4 text-uppercase rounded"/>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="col-xl-4 col-12 topos-head-right bg-secondary-super-light">
                    <ToposBreaks />
                    <ToposLinks />
                </div>
                <div className="col-md-2 bg-dark d-none">

                </div>
            </div>
        </div>
    )
} 

export default ToposHead