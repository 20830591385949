import { IFestival, IProject } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from "@/modules/pe-fest-module/data/store"
import ProjectMobileNavy from "./ProjectMobileNavy" 
import { useState } from "react"
import ProjectMobileInfo from "./ProjectMobileInfo"
import ProjectMobileRatings from "./ProjectMobileRatings"
import ExpertDescriptions from "../ExpertDescriptions"
import ProjectEditForm from "../ProjectEditForm" 
import { useUserOwner } from "@/modules/pe-fest-module/hooks/project"
import ProjectMobileDescriptions from "./ProjectMobileDescriptions"

const { A11y,  } =  require("swiper/modules") 
const { Swiper, SwiperSlide, } =  require("swiper/react")  

/* Protopia Ecosystem component */
export interface IProjectMobileFormProps {

}
const ProjectMobileForm = (props: IProjectMobileFormProps): JSX.Element => { 
    const festival: IFestival   = useFestDataStore((state: IFestDataStor) => state.festival) || {}  
    const item: IProject        = useFestProjects( (state:IFestProjectsProps) => state.currentProject ) || {} 
    const isUserOwner: boolean  = useUserOwner( item, festival.id )
    const [activeIndex, setActiveIndex] = useState<number>(0)

    return <div className='pe-project-mobile-form-container'>
        <Swiper 
            modules={[ A11y]}
            spaceBetween={0}
            slidesPerView={1} 
            onSlideChange={ ( swiper: any ) => setActiveIndex(swiper.activeIndex) }
        >
            <SwiperSlide>
                <div className="screen settings-screen " >
                    <div className="overflow-y-auto h-100">
                        <ProjectMobileInfo />
                    </div> 
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="screen rating-screen " >
                    <div className="overflow-y-auto h-100">
                        <ProjectMobileRatings />
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="screen descriptions-screen p-0" >
                    <div className="h-100">
                        <ProjectMobileDescriptions />
                    </div>
                </div>
            </SwiperSlide>
            {
                isUserOwner && <SwiperSlide>
                    <div className="screen manage-screen " >
                        <div className="overflow-y-auto h-100">
                            <ProjectEditForm />
                        </div>
                    </div>
                </SwiperSlide>
            } 
            <ProjectMobileNavy  setActiveIndex={setActiveIndex} activeIndex={activeIndex} />
        </Swiper>
        <div className="pe-fest-project-title-mobile h">
            { item.title }
        </div>

    </div>
}
export default ProjectMobileForm