import { Button, ButtonGroup, Dialog, Icon, Intent } from "@blueprintjs/core" 
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import Layouts, {getAllRoutes, updateLayouts} from "src/libs/layouts"
import FieldInput from "@/libs/scalars"
import { __ } from "@/libs/utilities" 
import { ID, LayoutsTags } from "@/libs/interfaces/layouts"
import { IFolderProps } from "../../data/interfaces"

const FolderSign = (props: any) : JSX.Element =>
{
    // console.log(props)
    const [ title, setTitle ]       = useState<string>( props.title )
    const [ folders, setFolders ]   = useState<any>( props.folders || [] )
    const [ childrenCount, setChildrenCount ]   = useState<number>( 0 )
    const [ isOpen, setOpening ]    = useState<boolean>( false ) 
    const [ isEditOpen, setEditOpening ]    = useState<boolean>( false ) 
    const [ isSelect, setSelect ]   = useState<boolean>( props.isSelect ) 
    const [ isHide, setHide ]       = useState<boolean>( props.isHide ) 
    const [ isDeleteOpen, setDeleteOpen ]    = useState<boolean>( false ) 
    const [ do_content, setDoContent ]       = useState<string>( "to_root" ) 
    
    useEffect( () => {
       const count =  props.allRoutes?.filter( ( ar: any ) => ar.folder === props.id && ar.is_cabinet ).length || 0
       setChildrenCount( count )
    }, [ props.allRoutes ] )
    useEffect( () => {
        setSelect( props.isSelect )
    }, [ props.isSelect ] ) 

    useEffect( () => {
        setTitle( props.title )
    }, [ props.title ] ) 

    const onAdd = () =>
    {
        let ___folders: any = {...Layouts().folders}
        const nextId = ___folders.max_id++
        const newFolder: IFolderProps = {
            id: nextId,
            title: `folder ${nextId}`,
            level: props.level,
            folders: [],
            hidden: false
        }
        console.log( ___folders )
        const _folders = [...folders, newFolder]
        setFolders(_folders)
        ___folders.folders = _folders
        folders.max_id = nextId
        updateLayouts( LayoutsTags.folders, folders )
        setOpening(true) 
    }
    const onOpen = () =>
    {
        setOpening(!isOpen)
        setSelect( true )
        props.onSelect(props.id)
    }
    const onSelect = (id: ID) =>
    {
        let _folders= [...folders]
        _folders = _folders.map(folder =>
        {
            return { 
                ...folder,
                isSelect:  folder.id === id
            }
        })
        setFolders( _folders )
        props.onSelect( id )
    }
    const onHide = (id : ID) =>
    {
        const h = !isHide
        setHide(h)
        props.onHide( id, h )
    }
    const folderSigns = isOpen 
        ?
        folders.map((folder: IFolderProps ) =>
        {
            return <FolderSign 
                key={folder.id} 
                {...folder} 
                level={folder.level  ? folder.level + 1 : 1 }
                onSelect={ onSelect }
                isSelect={ folder.isSelect }
                onHide={onHide}
                isHide={ folder.hidden }
                onDelete={props.onDelete}
            />
        })
        :
        []
    const onDeleteOpen = () =>
    {
        const routes = getAllRoutes()
        routes?.filter(r => r.folder === props.id).length > 0
            ?
            setDeleteOpen(!isDeleteOpen)
            :
            onDelete()
    }    
    const onDelete = () =>
    {
        props.onDelete(props.id)
    }
    const onEditOpen = () =>
    {
        setEditOpening(!isEditOpen)
    }
    const doCont = (evt: any ) =>
    {
        setDoContent(evt.currentTarget.getAttribute("name"))
    }
    const onTitle = ( value: string ) =>
    { 
        setTitle( value )
    }
    const onEdit = () =>
    { 
        props.onEditFolder( props.id, { title } )
        setEditOpening(!isEditOpen)
    }
    return <>
        <div className={`folder-sign ${ isSelect ? " active " : "" } ${ isHide ? " hide " : "" }`} >
            <div
                className="btn text-left d-flex align-items-center"
                data-icon="folder-open"
                style={{
                    flexGrow:100,
                    cursor:"pointer",
                    paddingTop:5,
                    paddingBottom:5,
                    paddingRight:20,
                    paddingLeft: props.level * 10
                }} 
                onClick={ onOpen  }
            >
                <Icon icon={ props.icon ? props.icon : ( isOpen ? "folder-open": "folder-close" ) } />
                <span 
                    className="ml-2" 
                    dangerouslySetInnerHTML={{ __html: props.title }}
                />
            </div>
            <div className="btn-child align-items-center ">
                <div className="py-0 px-2 mx-1 bg-secondary-dark text-force-contrast opacity_5">
                    { childrenCount }
                </div>
                {
                    props.isHideHide || Layouts().app.init_method === "local"
                        ?
                        null
                        :
                        <>
                            <Button minimal onClick={ onEditOpen }>
                                <Icon icon={ `edit` } color={ isSelect ? "#EEE" : "#111" } />
                            </Button>
                            <Button minimal onClick={ () => onHide(props.id) }>
                                <Icon icon={ isHide ? `eye-off` : `eye-open`} color={ isSelect ? "#EEE" : "#111" } />
                            </Button>
                            <Button minimal onClick={ onAdd } className="hidden" >
                                <Icon icon="plus" color={ isSelect ? "#EEE" : "#111" } />
                            </Button>
                            <Button minimal onClick={ onDeleteOpen } >
                                <Icon icon="minus" color={ isSelect ? "#EEE" : "#111" } />
                            </Button>
                        </>
                } 
            </div>
            <Dialog
                className="little"
                title={__("What to do with the contents of the folder?")}
                isOpen={isDeleteOpen}
                onClose={() => setDeleteOpen(false)}
            >
                <div className="p-4">
                    <div>                        
                        <label className="_check_green_">
                            <input type="radio" id="do_content" name="to_root" checked={do_content === "to_root"} onChange={doCont} />
                            {__("Move to root")}
                        </label>
                    </div>
                    <div>
                        <label className="_check_green_">
                            <input type="radio" id="do_content" name="delete" checked={do_content === "delete"} onChange={doCont} />
                            {__("Delete permanently")}
                        </label>
                    </div>
                    
                </div>
            </Dialog>
            <Dialog
                className="little"
                title={__("Edit Folder")}
                isOpen={ isEditOpen }
                onClose={ onEditOpen }
            >
                <div className="p-4">
                    <FieldInput
                        type="string"
                        title="Title"
                        value={ title }
                        on={onTitle}
                        onChange={onTitle}
                    />
                    <div className="pt-3">
                        <ButtonGroup >
                            <Button intent={Intent.SUCCESS} onClick={ onEdit }>
                                {__("Edit")}
                            </Button>
                            <Button intent={Intent.DANGER} data-icon="cress" onClick={ onEditOpen } />
                        </ButtonGroup>
                    </div>
                </div>
            </Dialog>
        </div>
        {folderSigns}
    </>
}
export default FolderSign