import { IFabulaMediaType, IPEFabulaMapTile } from "../../interfaces";

export const Alexeevskaya = () :IPEFabulaMapTile => {
    return {
        id: "3",
        title: "Алексеевская роща",
        post_content: "",
        enabled: true,
        x: 2,
        y: 0,
        map_id: "2",
        thumbnail: "/assets/img/fabula/Bear/bear3.png",
        episodes: [
            {
                id: "31",
                title: "Галерея Гагарина",
                post_content: "",
                tile_id: "3",
                visible: true,
                x: 18,
                y: 38,
                media: [ 
                    {
                        id: "311",
                        title: "Галерея Гагарина",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear311.png",
                        speech:[],
                        media:[
                            {
                                id: "3111",
                                title: "Экспозиция музея краеведения и истории космонавтики",
                                post_content: "",
                                type: IFabulaMediaType.MULTI_SCREEN,
                                parameters: {
                                    screen: {
                                        top: 0,
                                        left: 220,
                                        width: "calc(80% - 230px)",
                                        height: "100%"
                                    },
                                    items: [
                                        { 
                                            id: "1",
                                            type: IFabulaMediaType.HTML,
                                            title: "Юрий Алексеевич Гагарин",
                                            thumbnail: "/assets/img/fabula/Bear/Gagarin_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 0, 
                                                left: 10, 
                                                width: 150, 
                                                height: 150
                                            },
                                            content : "<p>Юрий Алексеевич Гагарин (9 марта 1934 — 27 марта 1968) — лётчик-космонавт СССР, Герой Советского Союза, кавалер высших знаков отличия ряда государств, почётный гражданин многих российских и зарубежных городов.</p><p>12 апреля 1961 года Юрий Гагарин стал первым человеком в мировой истории, совершившим полёт в космическое пространство.</p><p>Полковник ВВС СССР, военный лётчик 1-го класса, заслуженный мастер спорта СССР.</p><p>В последующие годы Гагарин вёл большую общественно-политическую работу, окончил Военно-воздушную инженерную академию имени профессора Н. Е. Жуковского, работал в Центре подготовки космонавтов и готовился к новому полёту в космос.</p>",
                                        },
                                        { 
                                            id: "2",
                                            type: IFabulaMediaType.HTML,
                                            title: "Николай Егорович Жуковский",
                                            thumbnail: "/assets/img/fabula/Bear/Zhukov_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 160, 
                                                left: 10, 
                                                width: 150, 
                                                height: 150
                                            },
                                            content : "<p>Николай Егорович Жуковский (5 (17) января 1847 — 17 марта 1921) — русский учёный-механик, основоположник гидро- и аэродинамики.</p><p> Окончил физико-математический факультет Московского университета по специальности прикладная механика в 1868 году.</p><p> С 1885 года преподавал в Московском университете гидродинамику, а с 1886 года — как экстраординарный профессор кафедры прикладной механики.</p><p> В 1902 году руководил сооружением аэродинамической трубы всасывающего типа при механическом кабинете Московского университета, а в 1904 году возглавил первый в Европе аэродинамический институт.</p><p> Заслуженный профессор Московского университета (1911), почётный член Московского университета (1916), заслуженный профессор Императорского Московского технического училища (с 1918 г. — Московского высшего технического училища).</p><p>Член-корреспондент Императорской Академии наук по разряду математических наук (1894).</p>",
                                        },
                                        { 
                                            id: "3",
                                            type: IFabulaMediaType.HTML,
                                            title: "Булганинские дачи",
                                            thumbnail: "/assets/img/fabula/Bear/bulg_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 320, 
                                                left: 10, 
                                                width: 150, 
                                                height: 280
                                            },
                                            content : "<p>С 1947 года в Алексеевской роще построили государственную дачу для Николая Булганина, на фото она выглядит потрясающе!</p><p> В 60-х годах дачей пользовался Юрий Гагарин, куда приезжал из Звездного городка</p><p> В состав национального парка Лосиный остров Алексеевская роща перешла в 1990 году, а в 1999 году открыт экоцентр «Старинная русская охота» и небольшой музей при ней. Но сейчас, как мы видим музей закрыт‚статуй больше нет на территории, а от изразцов печи остался всего один....и вопрос тоже один - а его вообще приведут в порядок? Или это уже заброшка?</p>",
                                        },
                                        { 
                                            id: "4",
                                            type: IFabulaMediaType.HTML,
                                            title: "Николай Александрович Булганин",
                                            thumbnail: "/assets/img/fabula/Bear/Bulganin_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 610, 
                                                left: 10, 
                                                width: 150, 
                                                height: 150
                                            },
                                            content : "<p>Николай Александрович Булганин (30 мая (11 июня) 1895 — 24 февраля 1975) — советский государственный деятель.</p><p>Член Президиума (Политбюро) ЦК КПСС (1948–1958), член ЦК партии (1937–1961).</p><p>Маршал Советского Союза (1947, лишён этого звания в 1958 году), генерал-полковник.</p><p>Входил в ближайшее окружение И. В. Сталина.</p>",
                                        },
                                    ]
                                },
                                x: 52,
                                y: 86,
                                thumbnail: "/assets/img/fabula/Bear/bear3111.png",
                                speech:[],
                                media:[
                                    
                                ],
                            },
                            {
                                id: "312",
                                title: "Поляна",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 82,
                                y: 80,
                                thumbnail: "/assets/img/fabula/Bear/bear312.jpg",
                                speech:[],
                                media:[
                                    
                                ],
                            },
                        ],
                    }
                ]
            },
            {
                id: "32",
                title: "Всесезонная водно-спортивная галлерея Пехра",
                post_content: "",
                tile_id: "3",
                visible: true,
                x: 68,
                y: 58,
                media: [ 
                    {
                        id: "321",
                        title: "Всесезонная водно-спортивная галлерея Пехра",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear321.png",
                        speech:[],
                        media:[ ],
                    }
                ]
            },
            {
                id: "33",
                title: "Лесничество",
                post_content: "",
                tile_id: "3",
                visible: true,
                x: 34,
                y: 48,
                media: [ 
                    {
                        id: "331",
                        title: "Ремонтная база Алексеевского лесничества",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear331.jpg",
                        speech:[],
                        media:[                                        
                            {
                                id: "3311",
                                title: "Строительство просеки",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 2,
                                y: 84,
                                thumbnail: "/assets/img/fabula/Bear/bear3311.jpg",
                                speech:[],
                                media:[                                                 
                                    {
                                        id: "33111",
                                        title: "Мобильное бунгало у ручья",
                                        post_content: "",
                                        type: IFabulaMediaType.IMAGE,
                                        x: 2,
                                        y: 84,
                                        thumbnail: "/assets/img/fabula/Bear/bear33111.png",
                                        speech:[],
                                        media:[ 
                                            {
                                                id: "331111",
                                                title: "Стратосферный «Буран» Президента Академии наук",
                                                post_content: "",
                                                type: IFabulaMediaType.IMAGE,
                                                thumbnail: "/assets/img/fabula/Bear/bear331111.jpg", 
                                                x: 2,
                                                y: 74,
                                                speech:[],
                                                media:[]
                                            }
                                        ],
                                    },
                                ],
                            }
                         ],
                    }
                ]
            },
        ]
    }
}