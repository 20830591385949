import { ILayoutTemplateAside, DefaultLayoutTemplateAside, Color } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import IScalarForm, { SCALAR_TYPES } from "@/libs/scalars/interface/IScalarForm"
import { BooleanEnabled } from "@/libs/scalars/scalars/Boolean"
import { ColorEnabled } from "@/libs/scalars/scalars/Color"
import Scalar from "@/libs/scalars/scalars/Scalar" 
import { Collapse } from "@blueprintjs/core"
import { useState } from "react" 


const LayoutTemplateAsideWidget = (props: IScalarForm): JSX.Element => { 
    return <Scalar
        {...props}
        enabledForm={<LayoutTemplateAsideEnabled {...props} />}
        desabledForm={<LayoutTemplateAsideDesabled {...props} />}
    />
}

export const LayoutTemplateAsideEnabled = (props: IScalarForm): JSX.Element => {
    const [is, setIs] = useState<boolean>( !!props.value )
    const [data, setData] = useState<ILayoutTemplateAside>( 
        ( { ...props.value } || { ...DefaultLayoutTemplateAside} ) as ILayoutTemplateAside
    )
    const onChange = (value: any) => {
        if (props.onChange) {
            props.onChange(value, props.field, "")
        }
    }
    const onIsChange = (value:boolean) => {
        setIs(value)
        onChange(value ? data: false)
    }
    const onColorChange = (field: "bg" | "color" | "active", value: Color) => {
        let _data: ILayoutTemplateAside = {...data}
        _data[field] = value
        setData( _data )
    }
    return <div className=" d-flex flex-column w-100 my-2 p-0 ">
        <BooleanEnabled
            value={is}
            title=""
            enabledLabel="Available. Changed palette"
            disabledLabel="Missing"
            onChange={ value => onIsChange(value) }
        />
        <Collapse isOpen={is} className="w-100">
            <FieldInput
                editable 
                value={data.bg}
                type={SCALAR_TYPES.COLOR}
                title="Background color"
                onChange={ value => onColorChange("bg", value) }
            />
            <FieldInput
                editable 
                value={data.color}
                type={SCALAR_TYPES.COLOR}
                onlyPlainColor
                title="Text color"
                onChange={ value => onColorChange("color", value) }
            />
            <FieldInput
                editable 
                type={SCALAR_TYPES.COLOR}
                value={data.active}
                onlyPlainColor
                title="Active color"
                onChange={ value => onColorChange("active", value) }
            />
        </Collapse>
    </div>
}
export const LayoutTemplateAsideDesabled = (props: IScalarForm): JSX.Element => {
    return <div dangerouslySetInnerHTML={{ __html: props.value?.toString() }} />
}

export default LayoutTemplateAsideWidget