import React, { useEffect, useState } from 'react'  
import LayoutMenuMainMobyle from './LayoutMenuMainMobyle'
import LayoutMenuMainScreen from './LayoutMenuMainScreen'
import { WINDOW_WIDTH } from '@/libs/interfaces/layouts'

const LayoutMenuMain = () => {
  const [isMobyle, setMobyle] = useState( window.innerWidth < WINDOW_WIDTH.TABLET )
  const onResizeHandler = () => {
    setMobyle( window.innerWidth < WINDOW_WIDTH.MOBILE )
  }
  useEffect(() => {
    window.addEventListener("resize", onResizeHandler)
    return () => window.removeEventListener("resize", onResizeHandler)
  }, [])
  return isMobyle 
    ? 
    <LayoutMenuMainMobyle />
    :
    <LayoutMenuMainScreen />
} 

export default LayoutMenuMain