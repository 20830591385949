import IScalarForm from "../interface/IScalarForm"
import Scalar from "./Scalar" 
import { useEffect, useState } from "react" 
import GalleryItem from "./gallery/GalleryItem"
import GalleryDesabled from "./gallery/GalleryDesabled"
import AddItemForm from "./gallery/AddItemForm" 

const Gallery = (props: IScalarForm): JSX.Element => {
    return <Scalar
        { ...props } 
        enabledForm={<GalleryEnabled {...props} />}
        desabledForm={<GalleryDesabled {...props} />}
        onClear={ 
            () => { 
                if( props.onChange ) {
                    props.onChange( [], props.field, "" )
                }  
            }
        }
    /> 
}
export default Gallery
 
const GalleryEnabled = (props: IScalarForm) :JSX.Element => {
    const [images, setImages] = useState< string[] >( Array.isArray(props.value) ? props.value :  [] )
    const [files, setFiles] = useState<any[]>([])
    useEffect(() => {
        setImages( props.value )
    }, [ props.value ])
    
    const onAddItem = () => {
        setImages([...images, ""])
        setFiles( [...files,  ""])
    }
    const onItemChange = (i: number, value: any, data: any) => {
        let imgs = [...images]
        imgs[i] = value
        setImages( imgs )
        let fls = [...files]
        fls[i] = data
        setFiles( fls )
        if(props.onChange) {
            props.onChange( imgs, props.field, fls.map(f => f.name).join(",") )
        }
    }
    const onRemove = (i : number) => {
        let imgs    = [...images].filter((__, n) => n !== i)
        let fls     = [...files ].filter((__, n) => n !== i)
        setImages( imgs )
        setFiles( fls )
        if(props.onChange) {
            props.onChange( imgs, props.field, fls.map(f => f.name).join(",") )
        }
    }
    const onPrev = ( i: number ) => {
        if( i < 1 ) return
        let imgs    = [...images]
        let fls     = [...files ]
        const remImg = imgs.splice( i, 1 )
        imgs.splice(i - 1, 0, remImg[0] )
        const remFls = fls.splice( i, 1 )
        fls.splice(i - 1, 0, remFls[0] )
        setImages( imgs )
        setFiles( fls )
        if(props.onChange) {
            props.onChange( imgs, props.field, fls.map(f => f.name).join(",") )
        }
    }
    return <div className='pe-gallery-container d-flex flex-wrap'>
         {
            images.map(( img, i ) => {
                    return <GalleryItem
                        key={ i }
                        i={ i }
                        { ...props }
                        value={ img }
                        onChange={ (value:any, field: string, data: any) => onItemChange(i, value, data) }
                        onRemove={ () => onRemove(i)}
                        onPrev={ onPrev }
                    />
                })
        }
        {/* <Dnd
            items={images}
            setItems={setImages}
            files={files}
            setFiles={setFiles}
            scalars={props}
        /> */}
        <AddItemForm {...props} onChange={onAddItem} />
    </div>
} 