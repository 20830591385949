import { useEffect, useState } from "react"
import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import { IMarker,  MAP_DIALOG_MODE,  Map as YandexMap } from "src/libs/yandexMap"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { __ } from "src/libs/utilities"
import { IEvent, IEventType, IPlace, IPlaceType, ITeam, MAP_FILTER } from "../../data/interfaces" 
import MapDialog from "./MapDialog" 
import { IToposPostsStore, IToposStore, useToposPostsStore, useToposStore } from "../../data/store"
import { eventTypes } from "../../data/mocks/eventTypes"
import { actions } from "../../data/actions"
import { GET_EVENTS_ACTION, GET_PLACES_ACTION, GET_TEAM_ACTION } from "../../data/actionTypes"
import { placeTypes } from "../../data/mocks/placeTypes"
import { IMapProps } from "@/libs/yandexMap/Map"


interface _IMapProps {
    onOpenLogin: () => void,
    setIsReloading: (isLoading: boolean) => void
}
const Map = (props: _IMapProps) : JSX.Element => {
    const [events, setEvents] = useState<IEvent[]>( useToposPostsStore.getState().events ) 
    const [isOpen, setIsOpen] = useState<boolean>(false) 
    const [markers, setMarkers] = useState<any[]>([])
    const [dialogData, setDialogData] = useState<any>(<></>) 
    const [mode, setMode] = useState<MAP_DIALOG_MODE>( MAP_DIALOG_MODE.NONE ) 
    const mapCoords: number[] = useToposStore((state: IToposStore) => state.mapCoords)
    const setMapCoords: ((mapCoords: number[]) => void) = useToposStore((state: IToposStore) => state.setMapCoords)
    const mapMode: MAP_FILTER = useToposStore((state: IToposStore) => state.mapMode)
    const setMapMode: ((mapMode: MAP_FILTER) => void) = useToposStore((state: IToposStore) => state.setMapMode)
    
    const updateEvents = (es: IEvent[] ) => {
        let _markers: any[] = [] 
        es.forEach((event: IEvent) => { 
            const eventType: IEventType = eventTypes().filter( p => p.id === event.type[0] )[0] || eventTypes()[0]
            const place: IPlace[] = event.places
            if( event.coordinates && event.coordinates[0] ) {
                // console.log( event.eventTypes )
                _markers.push({
                    dataType:"Event",
                    coordinates: event.coordinates, 
                    draggable: false, 
                    hintContent: event.title, 
                    icon: event.eventTypes[0]?.icon,
                    onClick: onMarkerClick ,
                    data: event,
                    color: eventType?.color 
                })
            }
            place.forEach( p => {
                const marker: any = {
                    dataType:"Event",
                    coordinates: p.coordinates, 
                    draggable: false, 
                    hintContent: event.title, 
                    icon: event.eventTypes[0]?.icon,
                    onClick: onMarkerClick ,
                    data: event,
                    color: eventType?.color 
                }
                _markers.push(marker)
            })
        })
        setMarkers( () => _markers ) 
    }
    const updatePlases = () => {
        actions(GET_PLACES_ACTION, {})
            .then((response) => { 
                setMarkers(
                    response.map((place: IPlace) => {
                        const placeType: IPlaceType = placeTypes().filter( p => p.id === place.type[0] )[0] || placeTypes()[0]
                        return {
                            dataType:"Place",
                            coordinates: place.coordinates, 
                            draggable: false, 
                            hintContent: place.title, 
                            icon: placeType.icon,
                            onClick: onMarkerClick ,
                            data: place,
                            color: placeType?.color 
                        }
                    })
                )
            })
    }
    const updateTeams = () => {
        actions(GET_TEAM_ACTION, {})
            .then((response) => { 
                let markers: any[] = []
                //console.log( response )
                response.forEach((team: ITeam) => {
                    const place: IPlace[] = team.places
                    if( team.coordinates && team.coordinates[0] ) {
                        markers.push({
                            dataType:"Team",
                            coordinates: team.coordinates, 
                            draggable: false, 
                            hintContent: team.title, 
                            icon: team.teamTypes[0]?.icon,
                            onClick: onMarkerClick ,
                            data: team,
                            color: team.teamTypes[0]?.color 
                        })
                    }
                    place.forEach( p => {
                        const marker: any = {
                            dataType:"Team",
                            coordinates: p.coordinates, 
                            draggable: false, 
                            hintContent: team.title, 
                            icon: team.teamTypes[0]?.icon,
                            onClick: onMarkerClick ,
                            data: team,
                            color: team.teamTypes[0]?.color 
                        }
                        markers.push(marker)
                    })
                })
                setMarkers( markers ) 
            })
    }

    const updateMarkers = (_events: IEvent[] | null = null) => {
        switch(mapMode) {
            case MAP_FILTER.PLACES:
                updatePlases()
                break
            case MAP_FILTER.TEAM:
                updateTeams()
                break            
            case MAP_FILTER.EVENTS: 
            default:
                if( _events ) 
                {
                    updateEvents( _events )
                }
                else {
                    actions(GET_EVENTS_ACTION, {})
                        .then((response) => { 
                            updateEvents( response )
                        })
                } 
                break
        }
    }
    
    useEffect(() => useToposPostsStore.subscribe(
        (state:IToposPostsStore) => {
            setEvents(state.events)
            updateMarkers( state.events ) 
        }
    ), [])

    useEffect (() => { 
        updateMarkers() 
    }, [mapMode ])

    const onFilter = (filter: MAP_FILTER) => {
        setMapMode(filter)
    }
    
    const onClick = (coords : number[]) => {
        //setMapCoords(coords)
    }
    const onClusterClick = ( evt:any, coords: number[], data: any[] ) => { 
        setDialogData( data )
        setMode( MAP_DIALOG_MODE.CLUSTER_DATA )
        setIsOpen( true )
    }
    const onWeel = (coords : number[]) => {
        setMapCoords(coords) 
    }
    const onMarkerClick = ( evt:any, coords: number[], data: any ) => {
        //console.log( data )
        setDialogData( data )
        setMode( MAP_DIALOG_MODE.MARKER_DATA )
        setIsOpen( true )
    }
    const onBeforeDrawMap = () : IMapProps => {
        // console.log( markers )
        updateMarkers()
        props.setIsReloading( false )
        return  { markers }
    }
    return <>  
        <YandexMap
            width="100%"
            height="100%"
            coordinates={ mapCoords }
            markers={ markers }
            onClick={onClick}
            onClusterClick={onClusterClick}
            onWeel={onWeel}
            onBeforeDrawMap={onBeforeDrawMap}
        >
            <ButtonGroup className="position-absolute m-2">
            {
                ( Object.keys(MAP_FILTER)  as Array<keyof MAP_FILTER> )
                    .map((f ) => {
                        const ddd = f.toString()
                        return <Button 
                            icon="map-marker"
                            key={ddd}
                            onClick={() => onFilter( ddd as MAP_FILTER )} 
                            className={ mapMode === ddd ? " untouchble " : "" }
                            intent={ mapMode === ddd ? Intent.DANGER : Intent.NONE }
                        >
                            {__( ddd )}
                        </Button> 
                    })
            } 
            </ButtonGroup>
        </YandexMap>  
        <MapDialog 
            isOpen={isOpen}
            mode={mode}
            type={mapMode}
            data={dialogData}
            size={DIALOG_SIZE.LARGE_ELEGNSE}
            onClose={() => setIsOpen(false)}
            onOpenLogin={props.onOpenLogin}
        />
    </>
}
export default Map