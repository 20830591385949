import { IUser } from "src/settings/zustand"
import { IManituActionType, IManituCell, IManituMatrix, IUserMana } from "../../interfaces"
import { useManitu, useManituStore } from "../../store"
import { IDoActionReturn } from "../doAction"

export const DoDiamand = ( cell: IManituCell, action: IManituActionType, user: IUser ): IDoActionReturn => {
    const matrix: IManituMatrix = useManitu.getState().matrix
    const userMana: IUserMana = useManituStore.getState().userMana
 
    // cache old parameter
    let oldValue: number = cell.defense
    let newValue: number = cell.defense
    
    /*
    // verify price and the user's solvency
    if( !!action.price.filter(p => userMana[p.type].count < p.nominal ).length ) {
        return {
            oldValue,
            newValue,
            action
        }
    }

    // get payment
    let _userMana = {...userMana}
    action.price.forEach(p => { 
        _userMana[p.type].count = _userMana[p.type].count - p.nominal
    }) 
    useManituStore.setState({ userMana : _userMana})
    */

    // set result
    let _matrix = {...matrix} 
    useManitu.setState({matrix: {
        ...matrix, 
        cells : _matrix.cells.map(c => {
            if(cell.id === c.id)
            {
                newValue = c.defense + 10
                c.defense = newValue
            }
            return c
        })
    }})

    return {
        oldValue,
        newValue,
        action
    }
}