import { schema } from "@/libs/layouts"
import { __, data_type_feed_url } from "@/libs/utilities"
import { Link } from "react-router-dom"

/* Protopia Ecosystem component */
export interface IThumbnailProps {
    singled_data_type: string
    data: any
}

export const Thumbnail = (props: IThumbnailProps): JSX.Element => {
    let thumbnail: JSX.Element | null
    const data_scheme = schema()[ props.singled_data_type ] 
    const getOrderLayout = () =>
    { 
        const order = props.data?.order
            ? 
            <div className="single-data-type-order">
                {props.data.order}
            </div>
            :
            null
        return order
    }
    const getPostTypeLayout = () =>
    {
        const { singled_data_type } = props
        return <span className="single-data-type-type mr-2">
           { __(schema()[singled_data_type].name)} 
        </span>
    }
    const getTitleLayout = () =>
    {
        const { singled_data_type, data } =  props
        const data_scheme = schema()[singled_data_type] 
        let title = data_scheme.visibled_value ? data_scheme.visibled_value : "title"
        return <span className="single-data-type-title">
            { data[title]?.toString() }
        </span>
    }
    Object.keys(data_scheme.apollo_fields).forEach( field => {
        if (
            field === "thumbnail" 
            && props.data[field]  
            && props.data[field] !== "false" 
            // && props.external_settings?.show_fields.filter(e => e === field).length > 0
        ) 
        {                
            thumbnail = <div
                className=" col-12 single-data-type-thumbnail "
                style={{ backgroundImage: "url(" + props.data[field] + ")" }}
            >                    
                <div className=" container ">
                    {getOrderLayout()}
                </div>
                <div className="single-data-type-thumbnail-titles container">
                    
                    { getPostTypeLayout() } 
                    { getTitleLayout() } 
                    <Link 
                        className="single-data-type-btn ml-auto" 
                        to={ "/" + data_type_feed_url( props.singled_data_type ) }
                    >
                        { __( "all" ) } { __( schema()[props.singled_data_type].plural ) }
                    </Link> 
                </div>
            </div>
        }
    })
    return !!thumbnail! 
        ?
        thumbnail 
        :
        <div 
            className=" col-12 single-data-type-thumbnail"                 
            style={{ }}
        >
            <div className=" container ">
                { getOrderLayout() }
            </div>
            <div className="single-data-type-thumbnail-titles container "> 
                { getPostTypeLayout() } 
                { getTitleLayout() } 
                <Link className="single-data-type-btn ml-auto" to={ "/" + data_type_feed_url( props.singled_data_type ) }>
                    { __( "all" ) } { __( schema()[ props.singled_data_type ].plural ) } 
                </Link> 
            </div>
        </div>
}
 