
import { create } from "zustand"
import { createJSONStorage, devtools, persist } from "zustand/middleware"
import { IManituActionType, IManituMatrix, IOrganoidType, IUserMana, ORGANOID_TYPE } from "./interfaces"
import { IManaProps } from "./manas"
import { organoidTypes } from "./organoidTypes"
 
export interface IManituStore {
    matrixRows:   number
    matrixColumns: number
    maxDefense: number
    size:number
    defaultBrokenCells: number
    organoidsTypes: IOrganoidType[]
    manaTypes: IManaProps[]
    userMana: IUserMana
    userMaxMana: IUserMana
    selfMatrix: IManituMatrix
}
export const useManituStore: any = create( 
    devtools(
        persist<IManituStore>( 
            (set: any, get: any ) => ({
                matrixRows:   9,
                matrixColumns: 9,
                maxDefense: 7,
                size: 50,
                defaultBrokenCells: 0,
                organoidsTypes: organoidTypes(),
                manaTypes: [] as IManaProps[],
                userMana: {} as IUserMana, // mineMana()
                userMaxMana: {} as IUserMana, // mineMana()
                selfMatrix: {} as IManituMatrix, // getCurrentUserMatrix(),
                
            }),
            {
                name: 'pe-manitu-storage', 
                storage: createJSONStorage( () => localStorage ), 
            } 
        )
    )
)

export interface IManitu {
    isActionPanelOpen: boolean
    isActionChoose: boolean
    choosedAction?: IManituActionType // current choose action 
    matrix?: IManituMatrix // current active matrix 
}
export const useManitu: any = create(
    devtools<IManitu>( 
        (set: any, get: any ) => ({
            isActionPanelOpen: false ,
            isActionChoose: false,
        })
    )
)