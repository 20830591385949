import { useEffect, useState } from "react"
import { IEvent } from "../../data/interfaces"
import { actions } from "../../data/actions"
import { useParams } from "react-router"
import { GET_SINGLE_EVENT_TYPE_ACTION } from "../../data/actionTypes"
import { Link } from "react-router-dom"
import { __, data_type_feed_url } from "src/libs/utilities"
import { schema } from "src/libs/layouts"
import { LeftLayout, RightLayout, Type } from "src/modules/pe-basic-module/views/singleData/SingleDataTypeForm"
import AdminItemLink from "src/modules/pe-admin-module/views/single/AdminItemLink"  
import { Loading } from "src/libs/useful"

const EventTypeForm = (props: any) :JSX.Element => {
    const [item, setItem] = useState<IEvent>({} as IEvent)
    const [curNavId, setCurNavId] = useState( "tab0" )
    const [isLoading, setIsLoading] = useState<boolean>(true)
    
    const params = useParams()
    useEffect(() => {
        actions(GET_SINGLE_EVENT_TYPE_ACTION, {id: Number(params.landId)})
            .then((response: IEvent | null) => {
                console.log( response, Number(params.landId) )
                if(response) {
                    setItem(response)
                    setIsLoading(false)
                }
                
            })
    }, [])
    if(isLoading) {
        return <Loading />
    }
    const getTitleLayout = () =>
    {  
        return <span className="single-data-type-title">
            { item.title?.toString() }
        </span>
    }
    const getColumnProportia = (): number[] => {
        return [8, 4]
    }
    return <div className="layout-state">
        <div className="single-data-type-thumbnail" style={{ backgroundImage: `url(${ item.thumbnail })` }}>
            <div className="single-data-type-thumbnail-titles container ">  
                { getTitleLayout() } 
                <Link className="single-data-type-btn ml-auto" to={ "/" + data_type_feed_url( "Event" ) }>
                    { __( "all" ) } { __( schema()[ "Event" ].plural ) } 
                </Link> 
            </div>
        </div>
        <div className={ `container position-relative ` }>
            <div className="row">
                <div className={`px-3 col-md-${ getColumnProportia()[0] }`}>
                    <div className="row">
                        <Type 
                            {...props} 
                            singled_data_type="EventType"
                            field={ "post_content" } 
                            data={item} 
                        /> 
                    </div>
                    <div>
                        <LeftLayout {...props} curNavId={curNavId} setCurNavId={setCurNavId} /> 
                    </div>    
                </div>
                <div className={`px-3 col-md-${ getColumnProportia()[1] }`}>
                    <RightLayout 

                        {...props} 
                        singled_data_type="EventType"
                        data={item} 
                    /> 
                </div>
            </div>
            <div className="position-absolute right top mt-3 mr-4" >
                <AdminItemLink singled_data_type={ "Event" } />
            </div>
        </div>
        
    </div>
}

export default EventTypeForm
