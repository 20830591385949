import { ID } from "@/libs/interfaces/layouts"


export const GET_PROJECT_MILESTON = ( projectId:ID, land_id: ID="") => {
  const s = land_id
        ?
        `land_id: "${land_id}"`
        :
        ``  
  return `query getProjectData {
    getPEFestProjectMilestones(id: "${ projectId }"  ${s}) {
        id
        averageRating
        milestone {
          id
          title
          is_auto_rait
          is_attachments_required
          post_content
        }
        ratings {
          id
          expertId
          display_name
          criteryId
          rating
          description
        }
        attachments {
          ID
          data
          type
          member_id
          milestone_id
          descr
        }
        experts {
          id
          display_name
        }
        descriptions
    }
  }`
}