
import { Color } from "src/libs/interfaces/layouts"
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { IDoActionReturn } from "@/modules/pe-manitu-module/data/mocks/doAction"

let duration = 0
let x: number = 0
let y: number = 0
let color: Color = "" 
let width: number = 0
let height: number = 0 

interface IAnimationLabelProps {
    width: number
    height: number
    animationPadding: number
}

export interface RefALabel {
    start: ( 
        x: number,
        y: number,
        color: Color,
        result: IDoActionReturn, 
        duration?: number
    ) => void
}

const AnimationLabel = (props: IAnimationLabelProps, ref: Ref<RefALabel>) : JSX.Element => {
    const [result, setResult ] = useState<IDoActionReturn>( {} as IDoActionReturn )
    const [isStart, setStart ] = useState<boolean>( false ) 
    const [ frame, setFrame  ] = useState<number>( 0 ) 
    const loop = () => { 
        if(frame >= duration) {
            // console.log( "loop", duration, frame >= duration, isStart )
            setStart( (isStart: boolean) => false )
        }
        else {
            setFrame( frame => frame + 1 )
        }
    }
    useEffect(() => {
        const timer = setInterval( () => loop(), 1 )
        return () => clearInterval( timer )
    }, [])

    const start = ( 
        _x: number, 
        _y: number, 
        _color: Color,
        _result: IDoActionReturn,   
        _duration: number = 1000
    ) => { 
        setResult( _result )
        x       = _x
        y       = _y
        color   = _color
        duration = _duration || 0
        setFrame( 0 )
        setStart(() => true)
    }

    const getLabel = () => {
        const {newValue, oldValue} = result
        return frame < duration 
            ? 
            oldValue  + Math.floor(( newValue - oldValue ) * ( frame / duration ) ) 
            : 
            ""
    }

    useImperativeHandle(ref, () => ({ start }))

    return <div
        className="pe-matrix-animation-container"
        style={{ width: props.width, height: props.width }}
    >
        {
            duration && ( !isNaN( getLabel() ) || !!getLabel() )
                ?
                <div 
                    className="pe-manitu-animated-label"
                    style={{
                        top: y - 22.5, 
                        left: x  - 22.5, 
                        opacity: 2 - ( frame + 0 ) / duration,
                        transform: `scale(${ duration/ frame *.1 + 1 })`
                    }} 
                >
                    { getLabel() }
                </div>
                :
                null
        }
    </div> 
}

export default forwardRef(AnimationLabel)