import { Callout } from "@blueprintjs/core";
import React from "react"
import Layouts,{ getAllRoutes } from "src/libs/layouts"
import { __ } from "@/libs/utilities"
import { useParams } from 'react-router-dom'; 
import { ISingleFolderProps } from "../../data/interfaces";
import { SingRoute } from "../SingRoute";
import { ID } from "@/libs/interfaces/layouts";


const Folder = (props: ISingleFolderProps): JSX.Element =>
{
    const params = useParams()   
    const currentFolderId:any = typeof params.folderId == "undefined"
        ?
        "-1"
        :
        params.folderId.toString()  
    const routes: any = getAllRoutes()  
    // console.log( currentFolderId ) 
    const views = Object.keys( routes )
        .filter( ( view: string | number ) => routes[view].is_cabinet )
        .filter( view => {  
            // console.log( routes[view].folder, currentFolderId, typeof params.folderId, routes[view].folder == currentFolderId )          
            // eslint-disable-next-line eqeqeq
            return routes[view].folder == currentFolderId  
                // eslint-disable-next-line eqeqeq
                || ( routes[view].folder === null && currentFolderId == -1 ) 
                // eslint-disable-next-line eqeqeq
                || ( routes[view].folder === -1   && currentFolderId == -1 ) 
                // eslint-disable-next-line eqeqeq
                || ( typeof routes[view].folder === "undefined" && currentFolderId == -1 ) 
        })
        .map(( view, i ) => { 
            const module = Layouts().modules[ routes[view].module ] 
            return <SingRoute 
                view={view}
                key={view} 
                module={module}
                routes={routes}
                folder={props.folder}
                moveToFolder={props.moveToFolder}
                onChangeRoute={props.onChangeRoute}
                onRemoveRoute={props.onRemoveRoute}
            />
            
        })
    return <div className=" container ">
        {
            props.folder?.hidden 
                ?
                <div className="position-absolute bg-light display-5 w-100 h-100 z-index-100 opacity_5 p-5 d-flex justify-content-center">
                    {__("This folder's pages has been excluded from the sitemap and is not displayed to site visitors")}
                </div> 
                :
                null
        }
        {/* FOLDER {params.folderId } */}
        <div className="row">
            { 
                views.length > 0 
                    ?
                    views
                    :
                    <Callout className="p-5 text-center">
                        {__("Elements no exists")}
                    </Callout>
            }
        </div>
        
    </div>
}
export default Folder