import { isRole } from "@/settings/zustand/user"
import { __, sprintf } from "@/libs/utilities"
import { Button, Intent, Popover, PopoverInteractionKind, Position } from "@blueprintjs/core"
import { ILand } from "../../data/interfaces"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { GET_FEED_ACTION } from "../../data/actionTypes"
import { ScalableDialog } from "@/libs/useful"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import { useState } from "react"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"

/* Protopia Ecosystem component */
export interface IFestPatternLabelProps {
    item: ILand 
}
const FestPatternLabel = (props: IFestPatternLabelProps): JSX.Element => {
    const [isChange, setIsChange] = useState<boolean>(false)
    const [patternDescription, setPatternDescription] = useState<string>(props.item.domain_content)
    const onChange = () => {
        actions(
            UPDATE_SINGLE_TYPE,
            {
                data_type: "Land",
                id: props.item.id,
                item: {
                    isPattern       : !props.item.isPattern,
                    domain_content  : patternDescription
                }
            }
        )
            .then(() => {
                actions(GET_FEED_ACTION, { data_type: "Land" })
                setIsChange(false)
            })
    }
    return <div className='p-1'>
        {
            isRole([ "administrator" ])
                ?
                <Popover 
                    position={Position.LEFT_BOTTOM}
                    content={ 
                        <div className="p-4 large-square">
                            <div>
                                <div className="lead mb-3">
                                {
                                    __(
                                            !props.item.isPattern 
                                            ? 
                                            "Set this at pattern" 
                                            : 
                                            "This is pattern site" 
                                        ) 
                                }    
                                </div>
                                <div >
                                {
                                    __(
                                            !props.item.isPattern 
                                            ? 
                                            "Press me to set this as Pattern for over new Festivals. At the time of creating a new Festival, the author can copy the assessment structure and landscape from this Festival as from a Pattern." 
                                            : 
                                            "Creators of new Festivals will be able to use this one as a template: landscape, rating system and other unique settings. Click me to exclude this Pattern from the list." 
                                        ) 
                                }    
                                </div>
                            </div>
                        </div> 
                    }
                    interactionKind={ PopoverInteractionKind.HOVER }
                >
                    <Button 
                        intent={ props.item.isPattern ? Intent.SUCCESS : Intent.DANGER}
                        icon="duplicate"
                        onClick={ () => setIsChange(true) }
                    />
                </Popover>
                
                :
                <></>
        }
        <ScalableDialog
            dialogSize={DIALOG_SIZE.NORMAL}
            isOpen={ !!isChange }
            onClose={ () => setIsChange(false) }
        >
            <div className="p-5">
            {
                props.item.isPattern
                ?
                sprintf(
                    __("Are you realy want change pattern status of «%s» "),
                    props.item.name 
                )
                :
                <>
                    <div className="lead">
                        {__("Set pattern description and apply by Pattern")}
                    </div> 
                    <FieldInput
                        type={SCALAR_TYPES.TEXT}
                        editable
                        vertical={VerticalType.VERTICAL}
                        commentary={__("Describe in a few phrases the main differences between the settings of this Festival. Other people will see this description when choosing your Festival as a template")}
                        value={patternDescription}
                        onChange={(value => setPatternDescription(value))}
                    />
                </>
            }
            </div>
            <div className="d-flex">
                <Button intent="danger" fill large onClick={() => setIsChange(false)}>
                    {__("No")}
                </Button>
                <Button intent="success" fill large onClick={ onChange }>
                    {__("Yes")}
                </Button>
            </div>
        </ScalableDialog>
    </div>
}
export default FestPatternLabel