  
  import Moment from "react-moment"
  import { Link } from 'react-router-dom'
  import { Button, Tag } from '@blueprintjs/core'
  import { getSingleRoute, getVisibleValue } from "@/libs/layouts"
  import { __ } from "@/libs/utilities" 
  import getRandomImg, { RandomImgCategory } from '@/assets/data/getRandomImg'
  
  const UserSingleFeedView = (props: any) : JSX.Element => { 
    const routeSingle = getSingleRoute(props.item.__typename)
    const link = routeSingle ? routeSingle.route : null 
    const linkBlock = link
      ?
      <Link
          to={"/" + link + "/" + props.item.id }
          className="ml-auto"
      >
          <Button 
              minimal
              rightIcon="chevron-right" 
          >
              {__("More")}
          </Button>
      </Link>
      :
      null
  
    let thumb: string = "thumbnail"
    switch(props.item.__typename)
    {
        case "User":
            thumb = "avatar"
            break
        default:
            thumb = "thumbnail"
    } 
  
    const imgSrc : string = props.item[ thumb ]
        ?
        props.item[ thumb ]
        :
       getRandomImg({ i: props.i }) //   null
      
    const linkBack =  link
      ?
      <Link
          to={"/" + link + "/" + props.item.id }
          className="ml-auto"
      >
          <div 
              className="post-thumbnail m-0" 
              style={{
  
                  backgroundImage:"url("+ imgSrc + ")"
              }}
          >
              
          </div>
      </Link>
      :
      <div 
          className="post-thumbnail m-0" 
          style={{
  
              backgroundImage:"url("+ imgSrc + ")"
          }}
      >
          
      </div>      
    let colClass = " col-12 " 
    
    const tags:RegExp = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g
    const words: string[] = props.item.user_descr?.replace(tags, '').split( ' ' )
    const content: string = words?.slice(0, 20).join(' ')
    return <div className={` ${colClass} ${props.cardClass} my-3 `} key={ props.item.id }>
      <div className="m-0 p-0 d-flex flex-row h-100 card"> 
        {linkBack} 
        <div className="d-flex flex-column p-4 flex-grow-100 overflow-hidden" title={ props.item[ getVisibleValue( props.data_type ) ] } >
          <h5 className='text-overflow'>
            { props.item[ getVisibleValue( props.data_type ) ] }
          </h5>
          <div 
            dangerouslySetInnerHTML={{ __html : `${ content }...` }}
          />
          <div className="d-flex flex-wrap mt-auto align-items-end">  
            { props.item?.roles.map( ( role: string ) => <Tag round minimal  className="m-1 px-3 py-1" key={role} title={__(role)}>{__(role)}</Tag> ) }
            { linkBlock }
          </div>
        </div>
      </div>
    </div>
  }
   
  export default UserSingleFeedView

