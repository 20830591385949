import { FABULA_CHARACTERY, FABULA_MODE, FABULA_STAFF_ACTION, IFabulaMediaType, IPEStory, PE_STORY_PHASE, SPEECH_FINISH_TYPE, SPEECH_POSITION } from "../interfaces"
import { Alexeevskaya } from "./fabula2/Alexeevskaya"
import { Bobobshka } from "./fabula2/Boboshka"
import { Gorenki } from "./fabula2/Gorenki"
import { Kachalinskaya } from "./fabula2/Kachalinskaya"
import { Kriogenmash } from "./fabula2/Kriogenmash"
import { speech } from "./fabula2/speech"

export const PEFabulaStory2 = ()  : IPEStory => {
    const story : IPEStory = {
        id: "2",
        title: "Балашиха. Чёрный туман",
        post_content: "<p>2045 год. Большой уезд Балашиха.</p><p> ...</p>",
        phase: PE_STORY_PHASE.NO_START,
        intro: [
            {
                id: "1",
                title: "",
                post_content: "",
                thumbnail: "/assets/img/fabula/Bear/intro/topos-present2.jpg",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: 1.4,
                npc: "",
                order:0,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 8000,
                position: SPEECH_POSITION.FULL
            }, 
            {
                id: "space-1",
                title: "",
                post_content: "",
                thumbnail: "/assets/img/fabula/Bear/intro/earth-morning-0.jpg",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: 1.4,
                npc: "",
                order: 1,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 1500,
                position: SPEECH_POSITION.FULL
            },
            {
                id: "space-11",
                title: "8 часов 14 минут 23 секунды утра по Москве",
                post_content: "<p>Высота: 44 тысячи метров.</p><p>Температура за бортом: -48 градусов Цельсия.</p><p>Давление: 10<sup><small>-3</small></sup>кг/см2</p>",
                thumbnail: "/assets/img/fabula/Bear/intro/earth-morning-0.jpg",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: 1.4,
                npc: "",
                order: 1,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 11000,
                position: SPEECH_POSITION.FULL
            },
            {
                id: "space-11",
                title: "Пассажирский стратолет-матка класса «Скалапендра». Борт № 12",
                post_content: "<p>Следует по кольцевому маршруту Евразия-2</p>",
                thumbnail: "/assets/img/fabula/Bear/intro/earth-morning-1.jpg",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: 1.4,
                npc: "",
                order: 2,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 9000,
                position: SPEECH_POSITION.FULL
            },
            {
                id: "space-11",
                title: "Пассажирский стратолет-матка класса «Скалапендра». Борт № 12",
                post_content: "Окрестности узлового хаба Владимир",
                thumbnail: "/assets/img/fabula/Bear/intro/space-1.png",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: 1.4,
                npc: "",
                order: 2,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 5000,
                position: SPEECH_POSITION.FULL
            },
            {
                id: "space-12",
                title: "Пассажирский стратолет-матка класса «Скалапендра». Борт № 12",
                post_content: "0 часов 24 минуты до прибытия к узловому хабу «Домен Балашихи-пассажирский»",
                thumbnail: "/assets/img/fabula/Bear/intro/space-1.png",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: 1.4,
                npc: "",
                order: 3,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 6000,
                position: SPEECH_POSITION.FULL
            },
            {
                id: "space-2",
                title: "",
                post_content: "Пассажирский автожир класса «Жужелица» из узла «Владимир-пассажирский» произвёл выгрузку пассажиров и отчалил в хаб приписки.",
                thumbnail: "/assets/img/fabula/Bear/intro/autozhir-1.jpg",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: 1.4,
                npc: "",
                order: 4,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 7000,
                position: SPEECH_POSITION.FULL
            }, 
            {
                id: "legs-1",
                title: "Палуба пассажирского отсека",
                post_content: "",
                thumbnail: "/assets/img/fabula/Bear/intro/legs.jpg",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3", 
                musicVolume: 1,
                npc: "",
                order: 5,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 1700,
                position: SPEECH_POSITION.FULL
            },
            {
                id: "legs-2",
                title: "Палуба пассажирского отсека",
                post_content: "Здравствуйте, мои милые, кислородо-зависимые друзья.",
                thumbnail: "/assets/img/fabula/Bear/intro/legs.jpg",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                audioSource: "/assets/data/fabula/Bear/speech/Nicole.-My-name-is-Masha1.mp3",
                musicVolume: 1,
                npc: "",
                order: 5,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 0,
                position: SPEECH_POSITION.FULL
            },
            {
                id: "hand-2",
                title: "",
                post_content: "<p>Меня зовут — Masha.  </p>",
                thumbnail: "/assets/img/fabula/Bear/intro/hand.jpg",
                audioSource: "/assets/data/fabula/Bear/speech/Nicole.-My-name-is-Masha2.mp3",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: .7,
                npc: "Masha",
                order: 6,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 0,
                position: SPEECH_POSITION.FULL
            },
            {
                id: "Masha-1",
                title: "",
                post_content: "<p> Я — заместитель главы музеев домена Балашиха. Я здесь, чтобы представить Вам  наш замечательный домен -- город контрастов, лесов, парков, уютных уединенных усадеб и крупных научных коммун. Музеи нашего домена по своей известности могут поспорить только с его трнспортной связностью со всеми крупнейшими мировыми доменами </p>",
                thumbnail: "/assets/img/fabula/Bear/intro/1.jpg",
                music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                musicVolume: .7,
                npc: "Masha",
                order: 7,
                isHero: false,
                isDisposable: false,
                completed: false,
                finishType: SPEECH_FINISH_TYPE.PASSED,
                passedPause:0,
                passedBefore: 0,
                duration: 14000,
                position: SPEECH_POSITION.FULL
            },
        ],
        characteries: [
            {
                id: "force",
                title: "Force",
                post_content: "",
                thumbnail: "",
                color: "#975555",
                type: FABULA_CHARACTERY.FORCE
            },
            {
                id: "luck",
                title: "Luck",
                post_content: "",
                thumbnail: "",
                color: "#5b9a5b",
                type: FABULA_CHARACTERY.LUCK
            },
        ],
        heros: [
            {
                id: "1",
                display_name: "Школьники из Саранска",
                avatar: "/assets/img/fabula/Bear/saranskAvatar.jpg",
                quest:[
                    {
                        id: "hand-2",
                        title: "",
                        post_content: "<p>В этой комфортабельно каюте — группа молодых людей.</p>",
                        thumbnail: "/assets/img/fabula/Bear/intro/hand-2.jpg",
                        music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                        npc: "Masha",
                        musicVolume: .55, 
                        musicStart: 87.582035,
                        order:0,
                        isHero: false,
                        isDisposable: false,
                        completed: false,
                        finishType: SPEECH_FINISH_TYPE.PASSED,
                        passedPause:0,
                        passedBefore: 0,
                        duration: 4000,
                        position: SPEECH_POSITION.FULL
                    },
                    {
                        id: "door-1",
                        title: "",
                        post_content: "<p>Они возвращаются из этнографической экспедиции.</p>",
                        thumbnail: "/assets/img/fabula/Bear/intro/door-1.jpg",
                        music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                        npc: "Masha",
                        musicVolume: .55, 
                        musicStart: 87.582035,
                        order:0,
                        isHero: false,
                        isDisposable: false,
                        completed: false,
                        finishType: SPEECH_FINISH_TYPE.PASSED,
                        passedPause:0,
                        passedBefore: 0,
                        duration: 4000,
                        position: SPEECH_POSITION.FULL
                    },
                    {
                        id: "saransk-quest-1",
                        title: "",
                        post_content: "<p>При посадке в статопорте Саранска с ними случилось преинтереснейшее проишествие...</p>",
                        thumbnail: "/assets/img/fabula/Bear/intro/schoolChildren1.jpg",
                        music: "/assets/data/fabula/Bear/music/intro-music.mp3",
                        npc: "Masha",
                        musicVolume: .55,  
                        order: 1,
                        isHero: false,
                        isDisposable: false,
                        completed: false,
                        finishType: SPEECH_FINISH_TYPE.PASSED,
                        passedPause:0,
                        passedBefore: 0,
                        duration: 16000,
                        position: SPEECH_POSITION.FULL
                    },
                ],
                type: "Person",
                roles: [],
                luck: 6,
                charm: 6,
                dexterity: 6,
                force: 6,
                health: 4,
                fullness: 20,
                currentFullness: 20,
                tileID: "3",
                mediaID: "311",
                staff: [ "3" ],
                top: 70,
                left: 50,
                z: 125,
                width: 50,
                height: 50, 
            },
            {
                id: "2",
                display_name: "Агент безопастности",
                avatar: "/assets/img/fabula/Bear/agentAvatar.jpg",
                quest:[],
                type: "Person",
                roles: [],
                luck: 0,
                charm: 0,
                dexterity: 0,
                force: 0,
                health: 8,
                fullness: 20,
                currentFullness: 20,
                tileID: "4",
                mediaID: "411",
                staff: [ "1" ], 
                top: 70,
                left: 50,
                z: 125,
                width: 50,
                height: 50,
            },
        ],
        NPC: [
            //Masha
            {
                id: "Masha",
                display_name: "Masha",
                tileID: "intro",
                avatar: "/assets/img/fabula/Bear/intro/Masha_avatar.jpg",
                mediaID: "-1",
                type: "Intro",
                luck: 0, 
                charm: 0, 
                dexterity: 0,
                force:0,
                health: 0,
                aggression: 0,
                cowardice: 0,
                staff: [], 
                roles: [],
                top: 0,
                left: 0,
                z: 0,
                width: 0,
                height: 0
            },
            //mosquito-1
            {
                id: "1",
                display_name: "Mosquito-00002",
                tileID: "9",
                avatar: "/assets/img/fabula/Bear/mosquito.png",
                mediaID: "bear921111",
                type: "Mosquito",
                luck: 0, 
                charm: 0, 
                dexterity: 0,
                force:0,
                health: 1,
                aggression: 6,
                cowardice: 0,
                staff: [], 
                roles: [],
                top: 50,
                left: 36,
                z: 200,
                width: 12,
                height: 16,
                scaleX: true,
                sprite: true
            },
            //mosquito-2
            {
                id: "2",
                display_name: "Mosquito-00001",
                tileID: "9",
                avatar: "/assets/img/fabula/Bear/mosquito.png",
                mediaID: "92111",
                type: "Mosquito",
                luck: 0, 
                charm: 0, 
                dexterity: 0,
                force:0,
                health: 1,
                aggression: 6,
                cowardice: 0,
                staff: [], 
                roles: [],
                top: 73,
                left:36,
                z: 100,
                width: 12,
                height: 16,
                scaleX: true,
                sprite: true
            },
            // kgm-guide-1
            {
                id: "3", 
                display_name: "Робот-экскурсовод",
                tileID: "6",
                mediaID: "61",
                aggression: 0,
                cowardice: 0,
                luck: 0,
                charm: 0,
                dexterity: 0,
                force: 0,
                health: 1,
                staff: [],
                type: "Guide",
                top: 0,
                left: 0,
                z: 0,
                width: 0,
                height: 0,
                roles: [],
                sprite: false,
                avatar: "/assets/img/fabula/Bear/kgm-guide1.jpg"
            },
            // kgm-tour-guide-2
            {
                id: "4", 
                display_name: "Робот-гид",
                tileID: "6",
                mediaID: "61",
                aggression: 0,
                cowardice: 0,
                luck: 0,
                charm: 0,
                dexterity: 0,
                force: 0,
                health: 100,
                staff: [],
                type: "Guide",
                top: 0,
                left: 0,
                z: 0,
                width: 0,
                height: 0,
                roles: [],
                sprite: false,
                avatar: "/assets/img/fabula/Bear/kgm-guide2.jpg"
            },
        ],
        speech : speech(),
        users: [],
        isFullness: true,
        duration: 1000 * 60 * 12 * 4,
        stepDuration: 1000 * 60 * 12,
        mode: FABULA_MODE.QUEST,
        thumbnail: "/assets/img/fabula/Bear/bear0.png",
        staff: [
            {
                id: "1",
                title: "Электорнный пропуск на «Криогенмаш»",
                post_content: "",
                type: "KEY",
                disposable: false,
                thumbnail: "/assets/img/fabula/Bear/electronic_key_kreogenmash.png"
            },
            {
                id: "2",
                title: "Электорнный пропуск на «Криогенмаш»",
                post_content: "",
                type: "KEY",
                disposable: false,
                thumbnail: "/assets/img/fabula/Bear/electronic_key_kreogenmash.png"
            },
            {
                id: "3",
                title: "Модуль невидимости",
                post_content: "",
                type: "KEY",
                disposable: false,
                thumbnail: "/assets/img/fabula/Bear/invisibilityModule.jpg"
            },
            {
                id: "4",
                title: "Общественный энергетический фонтанчик",
                post_content: "",
                type: "THING",
                disposable: false,
                thumbnail: "",
                action: FABULA_STAFF_ACTION.CHARGE,
                x: 27,
                y: 76,
            },
            {
                id: "5",
                title: "Общественный энергетический фонтанчик",
                post_content: "",
                type: "THING",
                disposable: false,
                thumbnail: "",
                action: FABULA_STAFF_ACTION.CHARGE,
                x: 56,
                y: 61,
            },
            {
                id: "6",
                title: "Свободный терминал БОИ",
                post_content: "Большой Общественный Информаторий отвечает на вопросы, выполняет запросы.",
                type: "INTERLOCUTOR",
                disposable: false,
                thumbnail: "",
                action: "Kriogenmash-boy",
                x: 19,
                y: 41,
            },
            {
                id: "7",
                title: "Свободный терминал БОИ",
                post_content: "Большой Общественный Информаторий отвечает на вопросы, выполняет запросы.",
                type: "INTERLOCUTOR",
                disposable: false,
                thumbnail: "",
                action: "Kriogenmash-boy",
                x:90,
                y: 41,
            },
        ],
        background: "",
        map: {
            id: "1",
            title: "Балашиха-2045",
            post_content: "",
            width: 300,
            height: 300,
            tileX: 3,
            tileY: 3,
            tiles: [
                Alexeevskaya(),
                Bobobshka(),
                Kachalinskaya(),
                Kriogenmash(),
                Gorenki(),
            ]
        },
    }
    return story
}