import React, { useEffect, useState } from 'react' 
import { concatRouting, isMenuLeft, moduleExists, template } from '@/libs/layouts'
import {useMainStore, IState  } from '@/settings/zustand'
import { useLocation } from 'react-router'
import { __, initArea, isCapability } from '@/libs/utilities'
import { NavLink } from 'react-router-dom'
import { IMenuItem } from '@/libs/interfaces/layouts'

const LayoutMenuMainScreen = ( ) : JSX.Element => {
    const [isOpen, setOpen] = useState(false) 
    const [chldrn, setChldrn] = useState<any>([]) 
    const user = useMainStore((state: IState ) => state.user)
    const location = useLocation()
    let grndchldrn: any[] = [], 
        grnd: React.JSX.Element[] | null = []
    
    const getFirstRoute = () => {
        const url = location.pathname.split("/")[1]
        return url || ""
    }
    const getParent = () : any[] => {
        const rts = getFirstRoute()
        // console.log(rts);
    
        let routing: IMenuItem[] = concatRouting()    
        return routing.filter((e: any) => e.route === rts) || []
    }

    const getChildren = () : any[] => {
        const chldrn = getParent()
        if (chldrn.length > 0) {
        // console.log( chldrn[0].children );
        if (chldrn[0].children && chldrn[0].children.length > 0) {
            return chldrn[0].children
        }
        return []
        }
    
        return []
    }
    const getGrandChildren = (chldrn : any) : any[] =>  {
        if (!chldrn) return [] 
        if (chldrn.children && chldrn.children.length > 0) {
        return chldrn.children
        }
        return []
    }
    
    const firstRoute = getParent()
    const childrenss = getChildren()
    useEffect(() => 
    {
        if ( childrenss
            && !firstRoute[0]?.hide_slider
            && template().left_menu === "pictogramm" 
        ) { 
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const _chldrn = childrenss
                .filter((e) => {
                    const isModuleExists: boolean = moduleExists(e.module)
                    const isRole = isCapability(e.capability, user)          
                    const isNotLogged: boolean = e.islogged === true && !user.id
                    const isLeft: boolean = !!e.is_left 
                    return !isModuleExists && !isRole && isLeft && !isNotLogged 
                })
                .map((e, i) => {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    grndchldrn = getGrandChildren(e)
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    grnd  = grndchldrn
                        ? 
                        grndchldrn.map((element, ii) => (
                        <div key={ii + element.route} className="left-cont-child-grnd">
                            <NavLink
                            className="list-element-child grnd" 
                            to={ `/${ getFirstRoute()}/${e.route}/${element.route}` }
                            >
                            {__(element.title)}
                            </NavLink>
                            {
                                initArea(
                                "menu-left-element",
                                { 
                                    data: { ...element },
                                    level: 2,
                                    pathname: `/${getFirstRoute()}/${e.route}/${element.route}`,
                                    i: ii,
                                },

                                )
                            }
                        </div>
                        ))
                    :							
                    null
        
                    return (
                        <div className="list-element-nest" key={i}>
                            <NavLink
                            className="list-element" 
                            to={`/${getFirstRoute()}/${e.route}`}
                            key={i}
                            >
                                {__(e.title)}
                            </NavLink>
                            {
                                initArea(
                                "menu-left-element",
                                { 
                                    data: { ...e },
                                    level: 1,
                                    pathname: `/${getFirstRoute()}/${e.route}`,
                                    i,
                                },
                                )
                            }
                            {grnd}
                        </div>
                    )
                })
            // console.log(_chldrn )
            setChldrn(  _chldrn )
            setOpen(    _chldrn.length > 0)
        }
    }, [ location.pathname ])
    // console.log( isMenuLeft(), isOpen )
    return isMenuLeft()  
        ?
        <>
            <div className={`layout-menu-main ${isOpen ? " open " : ""}`}> 
                {chldrn}
            </div> 
        </>
        :
        <></> 
} 

export default LayoutMenuMainScreen
 