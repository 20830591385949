import { __ } from "@/libs/utilities"
import { ICritery, IProject, IRating } from "../../data/interfaces" 
import { IUser } from "@/settings/zustand"
import { Tag } from "@blueprintjs/core"

interface IProps {
    critery: ICritery
    project:IProject
    index: number
    ratings: IRating[]
    users: IUser[]
}
const ProjectCriteryRow = ({critery, project, ratings, users, index}: IProps) : JSX.Element => { 
    const rData: JSX.Element[] = ratings
        .filter(( rating:IRating ) => rating.criteryId === critery.id)
        .map(( rating:IRating ) => {
            const expert:IUser = users.filter((user:IUser) => user.id === rating.expertId)[0]
            const display_name: string = expert ? expert.display_name! : rating.expertId.toString()
            return <div key={ rating.id } className=" row my-2 align-items-center">
                <div className="col-md-1 "> 
                    <Tag className='title m-0' minimal>{ rating.rating }</Tag> 
                </div>  
                <div className="col-md-3 "> 
                    <span className=' small m-0'>
                        { display_name }
                    </span> 
                </div>
                <div className="col-md-8 "> 
                    { rating.description && <span className='ml-0 small '>{ rating.description }</span> }
                </div>
            </div>  
    })
    return <tr className={` `}>
        <td> { index + 1 } </td>
        <td> { critery.title } </td>
        <td> { rData } </td>
        <td> { 0 } </td> 
    </tr>
} 

export default ProjectCriteryRow