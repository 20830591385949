import React, { useEffect, useState } from 'react' 
import { IFestival, ISettingsPageFormProps } from '../../data/interfaces'
import { Card, Icon, Slider } from '@blueprintjs/core' 
import {__, clearHTML} from '@/libs/utilities'
import { Loading, MediaChooser } from '@/libs/useful'
import FieldInput from '@/libs/scalars'    
import { actions } from '../../data/actions'
import { GET_FESTIVAL_SETTINGS_ACTION } from '../../data/actionTypes'
import { SCALAR_TYPES, VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { useAppoloStore } from '@/settings/zustand'
import gql from 'graphql-tag'
import { ID } from '@/libs/interfaces/layouts'
import { useFestDataStore } from '../../data/store'
import { useParams } from 'react-router'

const MainPageForm = (props: ISettingsPageFormProps) : JSX.Element => {
    const params = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [festival, setFestival] = useState<IFestival>({} as IFestival) 
    const [defaultFest, setDefaultFest] = useState<string>("") 
    useEffect(() => {
        actions(GET_FESTIVAL_SETTINGS_ACTION, {id: params.landId})
            .then(
                response => { 
                    setFestival(response)
                    setIsLoading(false)
                    setDefaultFest(JSON.stringify( response ))
                }
            )
        
    },[])
    if( isLoading) return <Loading />

    const onValueChange = (field: keyof IFestival, value: any) =>
    {
        let fest: IFestival = {...festival}
        fest[field] = ["content", "reglament"].includes(field) 
            ?  
            completeHTML( value )
            :
            value
        setFestival( fest )
    }
    const completeHTML = (html:string) => {
        return "<p>" + html.split("<p>")
            .filter((p:string, i: number) => i < 2 && p !== "<p>")
            .join("<p>")
            .replace(/\n/gi, "</p><p>")
    }
    const onDefaultImage = ( url: string, file: File, ID: ID ) => { 
        let fest: IFestival = {...festival}
        fest.defaultThumbnail = url
        fest.defaultThumbnailName = file.name
        setFestival( fest )
    }
    const updateFestival = () => {
        // console.log( festival )
        const apolloClient: any = useAppoloStore.getState().client
        const land_id = params.landId 
            ?
            ` land_id:"${params.landId}" `
            :
            ``
        const request = `
            mutation changeFestival {
                changePEFestival (
                    input: {
                        enabledRules: ${ festival.enabledRules }
                        isShowReglament: ${ festival.isShowReglament }
                        isShowMap: ${ festival.isShowMap }
                        isShowLaureates: ${ festival.isShowLaureates }
                        isShowTracks: ${ festival.isShowTracks }
                        reglamentTitle: "${ festival.reglamentTitle }" 
                        defaultThumbnail: "${ festival.defaultThumbnail }"
                        content: "${ clearHTML(festival.content || "" ) }"
                        reglament: "${ clearHTML(festival.reglament || "" ) }"
                        ${
                            festival.defaultThumbnailName 
                            ?
                            `defaultThumbnailName: "${ festival.defaultThumbnailName }"`
                            :
                            ""
                        }
                    }
                    ${land_id}
                ) {
                    content
                    reglamentTitle
                    reglament
                    enabledRules
                    isShowReglament
                    isShowMap
                    defaultThumbnail
                }
            }`
        console.log( request )
        apolloClient.mutate({
            mutation : gql`${request}`
        })
        .then( ( response: any ) => {
            console.log( response.data.changePEFestival ) 
            setFestival({
                ...festival,
                ...response.data.changePEFestival 
            })
            setDefaultFest(JSON.stringify( {
                ...festival,
                ...response.data.changePEFestival 
            }))
            useFestDataStore.setState({
                festival: {
                    ...festival,
                    ...response.data.changePEFestival 
                }
            })
        })
    }
    return (
        <div className="position-relative mb-5">
            <div 
                className={ ` position-sticky mr-2 mt-2 top` }
                style={{ zIndex: 100 }}
            >
                <div 
                    className={` mt-2 position-absolute right btn btn-sm btn-${defaultFest !== JSON.stringify(festival) ? 'danger' : 'secondary opacity_5 untouchble'}`}
                    onClick={updateFestival} 
                >
                    <Icon icon="tick" className="mr-2" /> {__("Update Festival data")}
                </div>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='display-5 mb-3'>{ __(props.title) }</div>
            </div>
            <div className='row'>
                <div className='col-12 '> 
                    <Card interactive={true} className="p-4 mb-2">
                        <div className="lead mb-1">{__("Page of Festival rules")}</div>
                        <input
                            type="checkbox"
                            className="_checkbox"
                            checked={ festival.enabledRules || false }
                            onChange={ evt => onValueChange("enabledRules", evt.target.checked) }
                            value="1"
                            id="enabled_rules"
                        />
                        <label htmlFor="enabled_rules"></label>
                        
                    </Card> 
                    {
					    !festival.enabledRules
                            ?
                            null
                            :
                            <>
                                <Card className="p-4 mb-2">
                                    <div className="lead mb-1">{__("Default image")}</div>
                                    <div className="row">
                                        <div className=" col-12 ">
                                            <MediaChooser
                                                url={festival.defaultThumbnail!}
                                                prefix={"icon"}
                                                height={230}
                                                width={"100%"}
                                                padding={5}
                                                onChange={ onDefaultImage }
                                                id={ festival.defaultThumbnail! }
                                                isURLHide={ true }
                                                isDescr={ false }
                                                hideLib
                                                ID="headerImg"
                                                limitSize={ 4000000 }
                                            />
                                            <div className="small mt-1 text-right">{
                                                __("This is the header image for the home page.")}
                                            </div>
                                        </div> 
                                    </div>
                                </Card>			
                                <Card interactive={true} className="p-4 mb-2">
                                    <div className="lead mb-1">{__("Show rules page?")}</div>
                                    <input
                                        type="checkbox"
                                        className="_checkbox"
                                        checked={ festival.isShowReglament || false }
                                        onChange={ evt => onValueChange("isShowReglament", evt.target.checked) }
                                        value="1"
                                        id="is_show_reglament"
                                    />
                                    <label htmlFor="is_show_reglament"></label> 
                                </Card>
                                <Card className="p-4 mb-2" data-element="client_url">
                                    <div className="lead mb-1">{__("Content of description page")}</div>  
                                    <FieldInput 					
                                        vertical={ VerticalType.VERTICAL }
                                        editable
                                        value={
                                            festival.content   
                                            || 
                                            "--"
                                        }
                                        commentary={__("Content of this page put to «About» link.")}
                                        type={SCALAR_TYPES.TEXT}  
                                        onChange={ value => onValueChange("content", value) }
                                    />
                                </Card> {/*  */}
                                <Card className="p-4 mb-2" data-element="client_url">
                                      
                                    <FieldInput			
                                        vertical={ VerticalType.VERTICAL }
                                        editable
                                        value={
                                            festival.reglamentTitle  
                                            || 
                                            "--"
                                        }
                                        commentary={__('Set reglament title')}
                                        type={SCALAR_TYPES.STRING}  
                                        onChange={ value => onValueChange("reglamentTitle", value) }
                                    /> 
                                    <div className='mb-4' />
                                    <FieldInput 					
                                        vertical={ VerticalType.VERTICAL }
                                        editable
                                        value={
                                            festival.reglament  
                                            || 
                                            "--"
                                        }
                                        //placeholder={__('Content of reglament page')}
                                        commentary={__('Content of reglament page')}
                                        type={SCALAR_TYPES.TEXT}  
                                        onChange={ value => onValueChange("reglament", value) }
                                    /> 
                                </Card>
                                <Card interactive={true} className="p-4 mb-2">
                                    <div className="lead mb-1">{__("Show map of Members")}</div>
                                    <input
                                        type="checkbox"
                                        className="_checkbox"
                                        checked={ festival.isShowMap || false }
                                        onChange={ evt => onValueChange( "isShowMap", evt.target.checked ) }
                                        value="1"
                                        id="is_show_map"
                                    />
                                    <label htmlFor="is_show_map"></label>
                                    <small className="text-secondary" style={{ position:"absolute", marginTop:10 }}>
                                        {__("Interactive Russia region map in statistic footer")}
                                    </small>

                                    <div className="lead mb-1 mt-4">{__("Show Honnor hall")}</div>
                                    <input
                                        type="checkbox"
                                        className="_checkbox"
                                        checked={ festival.isShowLaureates || false }
                                        onChange={ evt => onValueChange( "isShowLaureates", evt.target.checked ) }
                                        value="1"
                                        id="isShowLaureates"
                                    />
                                    <label htmlFor="isShowLaureates"></label>
                                    <small className="text-secondary" style={{ position:"absolute", marginTop:10 }}>
                                        {__("Show|hide laureates block on main page")}
                                    </small>

                                    <div className="lead mb-1 mt-4">{__("Show Tracks")}</div>
                                    <input
                                        type="checkbox"
                                        className="_checkbox"
                                        checked={ festival.isShowTracks || false }
                                        onChange={ evt => onValueChange( "isShowTracks", evt.target.checked ) }
                                        value="1"
                                        id="isShowTracks"
                                    />
                                    <label htmlFor="isShowTracks"></label>
                                    <small className="text-secondary" style={{ position:"absolute", marginTop:10 }}>
                                        {__("Show|hide tracks block on main page")}
                                    </small>
                                </Card>	
                            </>
				    }
                </div>
            </div>
        </div>
  )
} 

export default MainPageForm