import { IUser, useMainStore } from "@/settings/zustand" 
import { ID } from "@/libs/interfaces/layouts"
import { getQueryArgs } from "src/libs/layouts"
 
export const GET_OWN = ( land_id: ID = -1 ) => {
    const user:IUser = useMainStore.getState().user
    return `query getOwnProjects {
        getPEFestProjects( 
            paging:{ 
                relation: "OR"
                metas : [
                    {
                        key: "tutor_id"
                        value_numeric: ${ user?.id }
                        compare: "=="
                    },        
                    {
                        key: "leader_id"
                        value_numeric: ${ user?.id }
                        compare: "=="
                    }
                ] 
            }
            ${
                land_id && land_id !== -1
                    ?
                    `land_id: "${ land_id }"`
                    :
                    ""
            }
        ) 
        {
            ${ getQueryArgs("PEFestProject") }
        }
    }`
        
} 