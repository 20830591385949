import React, { useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"  
import {LayoutIcon} from '@/libs/useful'
import { __ } from "@/libs/utilities"
import { useMainStore, IState } from "@/settings/zustand"
//import { isCapability } from "../../../layouts/user" 
import { ID, IMenuItem } from "../../interfaces/layouts"
import { isRouteOpen } from "../utils/isRouteOpen"

 

const PictogrammMenu = (props: IMenuItem ) => { 
	const landId:ID = useMainStore( ( state: IState ) => state.landId )  
    const event_types: string[] =  useMainStore( ( state: IState ) => state.event_types ) || []
    const [isOpen, setIsOpen] = useState<boolean>(isRouteOpen(props, landId, event_types))
    useEffect(() => {
        setIsOpen( isRouteOpen(props, landId, event_types) )
    }, [ props, landId, event_types ])
    const current = useMainStore(( state: IState ) => state.current) 
	const location = useLocation()
  	return <div
	  	className={
			`position-relative pictogramm-menu-element${
			props.i === current ? " active" : ""}
			${ isOpen ? "opened" : "closed" }`
		}
  	>
		<NavLink
			to={`/${props.route}`}  
			className={`layout-left-btn ${ location.pathname === `/${props.route}` ? "active" : ""}`}  
		>
			<div className="layout-menu-icon">
				<LayoutIcon
					src={props.icon}
					className="left-menu-icon"
				/>
			</div>
			<div className={`layout-menu-left-label `}>
				{__(props.title)}
			</div>
		</NavLink>
	</div> 
} 
export default PictogrammMenu
 

// class PictogrammMenu extends Component {
// 	state = {
// 	  current: this.props.current,
// 	  hover: false,
// 	}

// 	componentWillReceiveProps(nextProps) {
// 	  this.setState({
// 	    current: nextProps.current,
// 	  })
// 	}

// 	render() {
// 	  const {
// 	    children, route, icon, title 
// 	  } = this.props
// 	  // const isRole = isCapability( capability);
// 	  // if(isRole) return "";
// 	  const arrow = children && children.length > 0
// 	    ? <span className="arrow fas fa-caret-right" />
// 	    : null
// 	  return (
// 		<div
// 			className={
// 						`position-relative pictogramm-menu-element${
// 						this.props.i === this.state.current ? " active" : ""}`
// 					}
// 		>
// 			<NavLink
// 				to={`/${route}`}
// 				exact
// 				strict
// 				className={`layout-left-btn ${this.props.location.pathname === `/${route}` ? "active" : ""}`}
// 				route={route}
// 				isActive={( match ) => {
// 							setTimeout(() => {
// 								const offset = $(".layout-left-btn.active").offset()
// 					$("#mobile-bar").offset({ top: offset ? offset.top : 65 })
// 					}, 50)
// 					if (match) {
// 								// console.log(match);
// 								// console.log(location);
// 								// console.log(this.props.location);
// 								// console.log(this.props.match);
// 							}
// 				}}
// 				activeClassName="active"
// 				>
// 				<div className="layout-menu-icon">
// 					<LayoutIcon
// 						src={icon}
// 						className="left-menu-icon"
// 					/>
// 				</div>
// 				<div className={`layout-menu-left-label ${this.state.hover ? "hover" : null}`}>
// 					{__(title)}
// 				</div>
// 			</NavLink>
// 			{arrow}
// 			{
// 				initArea(
// 					"menu-left-element",
// 					{
// 					...this.props,
// 					data: { ...this.props },
// 					level: 0,
// 					pathname: `/${route}`,
// 					i: this.props.i,
// 					state: this.state,
// 					},
// 				)
// 			}
//   		</div>
// 	  )
// 	}

// 	toggleHover() {
// 	  this.setState({ hover: !this.state.hover })
// 	}
// }
// export default withRouter(PictogrammMenu)
