import { useEffect, useState } from "react"
import { Navigate, Route, Routes } from "react-router"
import { GET_MINE_MANITU_ACTIONS } from "../../data/actionTypes"
import { actions } from "../../data/actions"
import Attack from "./Attack"
import Autotraining from "./Autotraining"
import Defense from "./Defense"
import Manitu from "./Manitu" 
import { Loading } from "src/libs/useful"
import JoinManituForm from "./JoinManituForm"
import { IState, IUser, useMainStore } from "src/settings/zustand" 

const ManituForm = (props: any): JSX.Element => {
    const user: IUser = useMainStore((state: IState) => state.user)
    const [isLoading, setIsLoading] = useState<boolean>(!!user.id)
    const [isUserHaveMatrix, setUserHaveMatrix] = useState<boolean>(!!user.id)
    useEffect(() => {
        if(!!user.id) {
            actions(GET_MINE_MANITU_ACTIONS, {})
                .then((response) => setUserHaveMatrix( !!response ) )
                .finally( () => setIsLoading(false) )
        } 
    }, [user.id])
    if (isLoading) {
        return <div className="w-100 h-100 flex-centered">
            <Loading />
        </div>
    }
    return isUserHaveMatrix
        ?
        <Routes>
            <Route element={<Manitu />} >
                <Route path="*" element={<Navigate to="autotraining" />} />
                <Route path="autotraining" element={<Autotraining />} /> 
                <Route path="define" element={<Defense />} />
                <Route path="impact/*" element={<Attack />} />
            </Route>
        </Routes>
        :
        <JoinManituForm />
}

export default ManituForm