import BasicState from "@/libs/basic-view"
import AutoCreatedUsersForm from "./festAdmin/AutoCreatedUsersForm"

export default class AutoCreatedUsersView extends BasicState {
    props: any
    addRender = () => {  
        return <div className="container mb-5"> 
            <AutoCreatedUsersForm { ...this.props } /> 
        </div> 
    }
}
