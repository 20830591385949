import React from 'react' 
import BasicState from "@/libs/basic-view"    
import MainPageForm from './festAdmin/MainPageForm' 

class MainPageAdminView extends BasicState {
    props: any
    addRender = () => {  
        return <div className="container mb-5"> 
            <MainPageForm { ...this.props } /> 
        </div> 
    }
} 

export default MainPageAdminView