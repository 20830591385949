import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { useEffect, useState } from "react"
import { ILudens, ILudensPhase } from "../../data/interfaces"
import { __ } from "src/libs/utilities"
import { Icon } from "@blueprintjs/core"
import { LoaderBallsLine } from "src/libs/useful"
import { actions } from "../../data/actions"
import { GET_Ludens_DATA_ACTION, UPDATE_Ludens_DATA_ACTION } from "../../data/actionTypes"
import { ILudensStore, useLudensStore } from "../../data/store"


const LudensAdminForm = () :JSX.Element => {
    const currentGameId = useLudensStore((state: ILudensStore) => state.currentGameId)
    const [isLoading, setIsLoading] = useState<boolean>( true )
    const [value, setValue] = useState<ILudens>( {} as ILudens )
    const [defaultValue, setDefaultValue] = useState<string>("") 
    useEffect(() => {
        actions(GET_Ludens_DATA_ACTION, {id: currentGameId} )
            .then((resp:ILudens) => {
                setValue( resp )
                setDefaultValue(JSON.stringify( resp ))
                setIsLoading(false)
            })
    }, []) 
    const onChange = (val: any, field: keyof ILudens, data: any) => {
        let _value: any = JSON.parse( JSON.stringify( value || {} ) ) 
        _value[ field! ] = val
        setValue( _value )
    }
    const updateLudens = () => { 
        actions(UPDATE_Ludens_DATA_ACTION, { id: currentGameId, data: value } )
            .then((resp:ILudens) => {
                setValue( resp )
                setDefaultValue(JSON.stringify( resp ))
                setIsLoading(false)
            })
    }
    return <div className="short-container position-relative mb-5">
        <div 
                className={ ` position-sticky mr-2 mt-2 top` }
                style={{ zIndex:100 }}
            >
                <div 
                    className={` mt-2 position-absolute right btn btn-sm btn-${defaultValue !== JSON.stringify(value) ? 'danger' : 'secondary opacity_5 untouchble'}`}
                    onClick={updateLudens} 
                >
                    <Icon 
                        icon={ 
                            defaultValue === JSON.stringify(value) //
                                ?  
                                "tick" 
                                :
                                isLoading ? "repeat" : "blank"
                        } 
                        className={ isLoading ? "mr-2 fa fa-spin" : "mr-2" } 
                    /> 
                    { __( "Update Game data" ) }
                </div>
            </div>
            <div className='d-flex align-items-center '>
                <div className='display-5 mb-2 mr-3'>{ __("Game control") }</div>
                { isLoading && <LoaderBallsLine /> } 
            </div>
            

        <FieldInput
            type={SCALAR_TYPES.STRING}
            value={value.title}
            field="title"
            editable
            title={__("Title")}
            onChange={onChange}
        />
        <FieldInput
            type={SCALAR_TYPES.HTML}
            value={value.description}
            field="description"
            editable
            title={__("Description")}
            onChange={onChange}
        />
        <FieldInput
            type={SCALAR_TYPES.RADIO}
            values={[
                {
                    _id: ILudensPhase.BEFORE,
                    title: __("Before")
                },
                {
                    _id: ILudensPhase.DURING,
                    title: __("During")
                },
                {
                    _id: ILudensPhase.AFTER,
                    title: __("After")
                },
            ]}
            value={value.phase}
            field="phase"
            editable
            title={__("Phase")}
            onChange={onChange}
        />
        <FieldInput
            type={SCALAR_TYPES.ICON}
            value={value.icon}
            field="icon"
            editable
            title={__("Icon")}
            onChange={onChange}
        />
        <FieldInput
            type={SCALAR_TYPES.MEDIA}
            value={value.thumbnail}
            field="thumbnail"
            editable
            height={250}
            title={__("Thumbnail")}
            onChange={onChange}
        />
        <FieldInput
            type={SCALAR_TYPES.DATE}
            value={value.start}
            field="start"
            editable
            title={__("Start")}
            onChange={onChange}
        />
        <FieldInput
            type={SCALAR_TYPES.NUMBER}
            value={value.currentCircleId}
            field="currentCircleId"
            editable
            title={__("Current circle Order")}
            onChange={onChange}
        />
        <FieldInput
            type={SCALAR_TYPES.TIME_DURATION}
            value={value.circleDuration}
            field="circleDuration"
            editable
            title={__("Circle duration")}
            onChange={onChange}
            default={1}
        /> 

        <div className="display-5">
            {
                __("Pixels settings")
            }
        </div>
        
        <FieldInput
            value={value.pixelTestPrice}
            editable
            type={SCALAR_TYPES.SLIDER}
            min={0}
            max={2}
            stepSize={.1}
            labelStepSize={.2}
            field="pixelTestPrice"
            title={__("pixel Test Price")}
            onChange={onChange}
        />
        <FieldInput
            value={value.pixelTestHardness}
            editable
            type={SCALAR_TYPES.SLIDER}
            min={0}
            max={20}
            stepSize={1}
            labelStepSize={2}
            field="pixelTestHardness"
            title={__("pixel Test Hardness")}
            onChange={onChange}
        />
        <FieldInput
            value={value.pixelOpenProblemPrice}
            editable
            type={SCALAR_TYPES.SLIDER}
            min={0}
            max={2}
            stepSize={.1}
            labelStepSize={.2}
            field="pixelOpenProblemPrice"
            title={__("pixel Open Problem Price")}
            onChange={onChange}
        />
        <FieldInput
            value={value.pixelOpenProblemHardness}
            editable
            type={SCALAR_TYPES.SLIDER}
            min={0}
            max={20}
            stepSize={1}
            labelStepSize={2}
            field="pixelOpenProblemHardness"
            title={__("pixel Open Problem Hardness")}
            onChange={onChange}
        />

        <div className="w-100 my-5">.</div>
    </div>
} 

export default LudensAdminForm