import { IUser, useLayoutStore, useMainStore } from "src/settings/zustand"
import { GET_FEED_ACTION } from "../pe-basic-module/data/actionTypes"
import actions from "../pe-basic-module/data/actions"
import { GET_USER_RESOURCES_TYPE } from "./data/actionTypes"
import { actions as ludensActions } from "./data/actions"

const init = () => {
    return new Promise<any>((resolve, reject) => {
        const user: IUser = useMainStore.getState().user
        actions(GET_FEED_ACTION, {data_type: "PEResourceType"})
        ludensActions(GET_USER_RESOURCES_TYPE, {user})
        useLayoutStore.setState({
            footerHeight : useLayoutStore.getState().footerHeight + 42
        })
        resolve( true )
        reject( false )
    }) 
}
export default init