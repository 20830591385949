import React, { Component } from "react"
import { Routes, Route } from "react-router" 
import { getAllViews} from "@/libs/utilities"  
import { IRoutingData } from "@/libs/interfaces/layouts" 
import NoMatchView from "../views/NoMatchView"

const components: any = {}
//importAll(require.context("../libs/wpfestREST", false, /\.js$/))
type FreeRouteWidgetProps = {
  link_route: string[],
  components: any[],
  component_names: string[],
  preroute: string,
  props: any,

}

class FreeRouteWidget extends Component<FreeRouteWidgetProps> {
  render() {
    // console.log( this.props )
    // console.log(`${this.props.preroute}/${this.props.link_route}`)
    const link_routes = Array.isArray(this.props.link_route) ? this.props.link_route : [this.props.link_route] 
    const __components = Array.isArray(this.props.components)
      ? 
      this.props.components
      : 
      []
    const routes =  link_routes.map((route, i) => {
      const MyComponent = components[__components[i]] 
        ? 
        components[__components[i]] && components[__components[i]].default 
          ?
          components[__components[i]].default
          :
          <NoMatchView />
        : 
        this.props.component_names[i]
          ?
          searchComponent( this.props.component_names[i] )
          :
          <NoMatchView />
      
      const targ_route = this.props.preroute
        ?
        `/${this.props.preroute}/${route}`
        :
        `/${route}`
      const singleProps = Array.isArray(this.props.props) ? this.props.props[i] : this.props.props
      //console.log( targ_route )
      //console.log( MyComponent )
      //console.log( singleProps )
      return (
        <Route
          key={ i } 
          path={ targ_route }
          element={ (            
             MyComponent
                ?
                <MyComponent 
                  route={{
                    icon:"--",
                    title:"---"
                  }}
                  {...this.props} 
                  {...singleProps}
                  title=" "
                  icon=" "
                />
                :
                <NoMatchView />
            )
        }
        />
      )
    })
    return <Routes>
      {routes}
    </Routes>
  }
}
export default FreeRouteWidget

export const searchComponent = (componentName: string): JSX.Element | null =>
{
  let components: any = {}
  getAllViews( components )
  return components[componentName] ? components[componentName].default : null 
}
