import { yandex_access_token, yandex_client_id, yandex_client_secret } from "@/settings/config"
import { IUser } from "@/settings/zustand"
import { useEffect, useState } from "react"

interface IProps {
    callback: (data: any) => void
}
const YandexLogin = (props: IProps) : JSX.Element => {
  const [isSDKLoading, setIsSDKLoading] = useState(false)
  useEffect(() => {
    if (document.getElementById('yandex-api-jssdk')) {
      setIsSDKLoading(true)
    }
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.src = 'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js';
    el.async = true;
    el.id = 'yandex-api-jssdk';
    document.getElementsByTagName('head')[0].appendChild(el);
    el.onload = () => {
      // setIsSDKLoading(true)
      // console.log("YANDEX SDK LOADED")
      init()
    }
  }, [])

  const init = () => {
    // console.log(window.YaAuthSuggest)
    // window.YaAuthSuggest.init(
    //   {
    //     client_id: '6bf64859473a4268834dfa9cee9dff69',
    //     response_type: 'token',
    //     redirect_uri: 'htp://localhost'
    //  },
    //   "htp://localhost", 
    // )
    //   .then((res:any) => res.handler())
    //     .then((data: any) => console.log('Сообщение с токеном', data))
    //     .catch((error: any) => console.log('Обработка ошибки', error));
  }

  // if(!isSDKLoading) return <></>

  const onYandex = () => {
    const access_token =  yandex_access_token() 
    const clienID = yandex_client_id()
    const urlAccess = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${ clienID }`
    const url = `https://login.yandex.ru/info?format=json`
		var headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `OAuth ${access_token}`
		};
        fetch( url, { headers } )
            .then(r => {
                // console.log( r )
                try 
                {
                    const res = r.json()
                    // console.log( res )	
                    return res;  
                }
                catch(e)
                {
                    console.log( e)
                }            
            })
                .then( res => {
                    // console.log( res )
                    const user: IUser = {
                      id: res.id,
                      externalId: res.id,
                      //login: res.display_name + "_yandex",
                      email: res.default_email,
                      roles:[],
                    }
                    // res.email = res.default_email
                    // res.roles = [] 
                    // res.href = res.display_name + "_yandex"
                    // res.oauth_type = "yandex"
                    props.callback( user )
                })
  }
  return <div className="soc yandex" onClick={ onYandex }>
        <i className="fab fa-yandex" />
    </div> 
} 

export default YandexLogin


declare global {
  interface Window {
      YaAuthSuggest?: any 
  }
}