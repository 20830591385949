import { Button } from "@blueprintjs/core"
import React, { useEffect, useState } from "react" 
import Layouts from "src/libs/layouts"
import { __ } from "@/libs/utilities"
import { ID } from "@/libs/interfaces/layouts"
import { useLocation } from "react-router"
import FolderSign from "./FolderSign" 
import { IFolderProps } from "../../data/interfaces"

const Folders = (props: any) : JSX.Element =>
{
    const location = useLocation()  
    const [folders, setFolders] = useState<IFolderProps[]>( props.folders )  
    useEffect(() => {
        console.log( props.folders )
       // setFolders( props.folders )

    }, [ props.folders ])
    const currentRoute : any = location.pathname.replace("/" + props.curRoute + "/folder/", "").split("/")[props.level]
    const onSelect = (id: ID) => {
        let _folders= [...folders]
        _folders = _folders.map((folder: IFolderProps) =>
        {
            return { 
                ...folder,
                isSelect:  folder.id === id
            }
        })
        setFolders( _folders )
        //setMainSelect(id === -1)
        props.onSelect(id)
    }
    const onHide = (id: ID) => {
        let _folders= [...folders]
        _folders = _folders.map((folder: IFolderProps) =>
        {
            return { 
                ...folder,
                is_hidden:  folder.id === id
            }
        })
        setFolders( _folders )
        props.onHide(id)
    }
    const onDelete = (id: ID) => {
        let _folders= [...folders]
        _folders = _folders.filter((folder: IFolderProps) =>
            {
                return folder.id !== id
            })
            setFolders( _folders )
            props.onDelete(_folders )
    }
    const onEditFolder = (id: ID, data: any) => {
        let _folders= [...folders]
        _folders = _folders.map((folder: IFolderProps) =>
            {
                if(folder.id === id)
                {                    
                    console.log( folder, data, {...folder, ...data} )
                    return {...folder, ...data}
                }
                else
                {
                    return folder 
                }
            })
        setFolders( _folders )
        // console.log( id, data, _folders )
        props.changeFolders({ ...Layouts().folders, folders: _folders }) 
    }
    const flds = [ 
        <FolderSign 
            key={ -1 } 
            level={ 1 }
            onSelect={ onSelect }
            // eslint-disable-next-line eqeqeq
            isSelect={ currentRoute == -1  || !currentRoute }
            isHideHide
            id={-1}
            icon="more"
            title=""
        />,
        Array.isArray(folders)
            ?
            folders.map( folder => <FolderSign 
                key={ folder.id } 
                { ...folder } 
                allRoutes={props.allRoutes}
                curRoute={ props.curRoute }
                //curRoute={ currentRoute }
                level={ 1 }
                onSelect={ onSelect }
                // eslint-disable-next-line eqeqeq
                isSelect={ folder.id == currentRoute }
                onHide={ onHide }
                isHide={folder.hidden}
                onDelete={onDelete}
                onEditFolder={onEditFolder}
            /> )
            :
            []
    ]
    const addFolder = () =>
    {
        const nextId = props.max_id + 1
        const newFolder :  IFolderProps = {
            id: nextId,
            title: `folder ${nextId}`,
            level: props.level,
            hidden: false
        }
        const _folders = [ ...folders, newFolder ]
        setFolders(_folders)
        props.changeFolders({ max_id: nextId, folders: _folders }) 
    }
    const openAll = () =>
    {

    }
    const closeAll =() =>
    {

    }
    return <div>
        <div className="p-2 d-flex">
           <Button 
                minimal
                icon="folder-new"
                className="hint hint--top d-flex justify-content-center "
                data-hint={__("Add folder")}
                onClick={addFolder}
            /> 
           <Button 
                minimal 
                icon="folder-open"
                onClick={openAll}
                data-hint={__("Open all")}
                className="hidden hint hint--top d-flex justify-content-center"
            > 
            </Button> 
           <Button 
                minimal 
                icon="folder-close"
                onClick={closeAll}
                data-hint={__("Close all")}
                className="hidden hint hint--top d-flex justify-content-center"
            > 
            </Button> 
        </div>
        {flds}
        
    </div>
}
export default Folders