
import React, { useState } from 'react' 
import { Outlet } from 'react-router'
import { IBasicViewProps } from '@/libs/basic-view'
import { LayoutIcon } from "@/libs/useful"
import { initArea, __ } from "@/libs/utilities"  
import { ID, WINDOW_WIDTH } from "@/libs/interfaces/layouts" 
import Layouts, { getAllRoutes, help_url } from "src/libs/layouts"
import { useNavigate } from 'react-router'
import { Button } from '@blueprintjs/core'
import Folders from './Folders'
import CurrentFolderMenu from './CurrentFolderMenu'
import {IFolderProps} from '../../data/interfaces'
import SignTypeSwitcher from './SignTypeSwitcher'

const CabinetLayout = (props: IBasicViewProps) : JSX.Element => {

  const [isLeftClosed, setIsLeftClosed] = useState<boolean>( window.innerWidth < WINDOW_WIDTH.TABLET )
  const [currentFolderId, setCurrentFolderId] = useState<ID>( -1 )
  const [folders, setFolders] = useState<IFolderProps[]>( Layouts().folders.folders || [] )
  const [max_id, setMax_id] = useState<ID>( Layouts().folders.max_id )
  const navigate = useNavigate() 

  //container classes
  const leftClass = isLeftClosed
      ? "tutor-left-aside-2 menu-aside closed"
      : "tutor-left-aside-2 menu-aside"

  const mainClass = isLeftClosed
      ? "tutor-main-2 pr-0 opened"
      : "tutor-main-2 pr-0"
    
  const help = props.help_url
    ?
    <span>
      <Button 
        icon="help" 
        minimal 
        onClick={ () => window.open(help_url()! + props.help_url!, '_blank') } 
      />
    </span> 
    :
    null

  // handlers
  const changeFolders = ( folders: any ) => {
    setFolders(folders.folders)
    setMax_id(folders.max_id)       
    // updateLayouts("folders", folders)
    onSaveFolders()
  }

  const onSelect = (folderId: ID) => { 
      let _folders: IFolderProps[] = [ ...folders ].map(folder => {
          return folder.id === folderId
              ? 
              {...folder, isSelect: true}
              :
              {...folder, isSelect: false}
      })
      setCurrentFolderId(folderId)
      setFolders(_folders) 
      navigate( "/" + props.route + "/folder/" + folderId ) 
  }

  const onHide = (folderId: ID) => {
    let _folders: IFolderProps[] = [ ...folders ].map(folder => {
      return folder.id === folderId
          ? 
          { ...folder, is_hidden: !folder.hidden }
          :
          { ...folder }
    })
    setFolders(_folders)
    onSaveFolders()
  }

  const onDelete = (folders: IFolderProps[]) =>
  {
    setFolders(folders)
    onSaveFolders()
  }
  const onAddRoute = ( data: any ) => {
    if(props.onAddRoute) props.onAddRoute(data)
  }
  const onSaveFolders = () => {

  }
  

  return  <div className="layout-state p-0">
    <div className="tutor-row menu-row">
      <div className={leftClass}>
          <div className="layout-state-head menu-header-22">
              <LayoutIcon
                  isSVG
                  src={ props.icon || "" }
                  className="layout-state-logo "
              />
              <div className="layout-state-title">
                  { help }
                  <span dangerouslySetInnerHTML={{ __html: __(props.title || "") }} /> 
              </div>
          </div>
          <div className="small mx-3 mb-3 " dangerouslySetInnerHTML={{ __html: __(props.description || "") }} />
          <Folders 
              curRoute={ props.route }
              folders={ folders } 
              allRoutes={ getAllRoutes() }
              max_id={ max_id } 
              level={0} 
              changeFolders={ changeFolders} 
              onSelect={ onSelect}
              onHide={onHide}
              onDelete={onDelete} 
          />
          {
              initArea("admin-menu-left-aside",
                  { ...props })
          }
      </div>
      <div className={`h-100 ${mainClass}`}>
        <div
            className="clapan-left"
            onClick={() => setIsLeftClosed(!isLeftClosed)}
        >
            <div className={`fas fa-caret-${!isLeftClosed ? "left" : "right"}`} />
        </div>
        <div className="menu-header-22 flex-centered">
            <div className='container d-flex'>
              <CurrentFolderMenu
                currentFolderId={ currentFolderId } 
                onSelect={ onSelect }
                onAddRoute={ onAddRoute }
              />
              <div className='ml-auto'><SignTypeSwitcher /></div>
            </div> 
        </div>
        <div className=" p-4  overflow-y-auto">
          <Outlet />
        </div>
      </div>
      <div className="tutor-right-aside-2">
      {
          initArea(
              "admin-menu-right-aside",
              { ...props },
          )
      }
      </div> 
    </div> 
  </div> 
}
export default CabinetLayout