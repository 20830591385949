import { ButtonGroup, Collapse, Tag } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { GET_FEED_ACTION } from "src/modules/pe-basic-module/data/actionTypes"
import actions from "src/modules/pe-basic-module/data/actions"
import { useDataStore } from "src/modules/pe-basic-module/data/store"
import { LoaderBallsLine } from "src/libs/useful"
import { IAcademicDiscipline } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface IFilterFormProps {
    onFilter: (discipline: IAcademicDiscipline[]) => void
    filter: IAcademicDiscipline[]
}
const FilterForm = (props: IFilterFormProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const disciplines:IAcademicDiscipline[] = useDataStore((state: any) => state.PEAcademicDiscipline) || [] 
    useEffect(() => {
        actions(GET_FEED_ACTION, {data_type: "PEAcademicDiscipline"})
            .then(response => {
                //setDisciplines( response.feed ) 
                setIsLoading(false)
                // props.onFilter( response.feed )
            })
    }, [])

    const onFilter = (discipline: IAcademicDiscipline) => {
        if( !!props.filter.filter( d => d.id === discipline.id ).length ) {
          props.onFilter( props.filter.length > 1 
            ? 
            props.filter.filter( d => d.id !== discipline.id ) 
            : 
            [ ...disciplines ].filter( d => d.id !== discipline.id )  
          )
        }
        else {
          props.onFilter( [ ...props.filter, discipline ] )
        }
    }
    return <div className='pe-filterform-container w-100'>
        <Collapse isOpen={!isLoading}>
            <div className="w-100">
                <ButtonGroup className="flex-wrap">
                {
                    disciplines.map(discipline => {
                        const select: boolean = !!props.filter.filter(d => d.id === discipline.id).length
                        return <Tag 
                            minimal={ select } 
                            key={discipline.id}
                            style={{
                                backgroundColor: select ? discipline.color : "transparent", 
                            }} 
                            className={`
                                ml-1 my-1 px-3 py-2 rounded2 pointer hover text-uppercase
                                ${ select ? " text-light " : " text-force-contrast text-black"}
                            `} 
                            onClick={() => onFilter( discipline )}
                        >
                            {discipline.title}
                        </Tag>
                    })
                }  
                </ButtonGroup> 
            </div>
        </Collapse>
        <Collapse isOpen={isLoading}>
            <div className="h_45">
            {
                isLoading 
                ? 
                <LoaderBallsLine fill={"#C60000"}/>
                :
                null
            }
            </div>
        </Collapse>
        
    </div>
}
export default FilterForm