import { useState } from "react" 
import { HTML, ID, IPost } from "../interfaces/layouts" 
import { __ } from "../utilities"
import { Button, Icon } from "@blueprintjs/core"
import EmojiButton from "./comments/EmojiButton";
import { EmojiClickData } from "emoji-picker-react";

interface ICommentInputProps {
    className: string
    item: IPost
    parentID?: ID | string
    title?: string
    buttonLabel? : string
    onSend?: (comment: HTML) => void
    onBeforeSend?: (comment: HTML) => void
    onAfterSend?:  (comment: HTML) => void
    footer? : JSX.Element
    noTitle?: boolean
}
const CommentInputForm = ({ ...props }: ICommentInputProps): JSX.Element => {
    const [comment, setComment] = useState<HTML>("")
    const onComment = (value: HTML) => {
        setComment(value)
    }
    const onSend = () => {
        if(props.onBeforeSend) {
            props.onBeforeSend(comment)
        }
        if( props.onSend) {
            props.onSend(comment)
        }
        else {
           console.log("send Comment") 
        }        
        if(props.onAfterSend) {
            props.onAfterSend(comment)
        }
    }
    const onEmoji = (emojiData: EmojiClickData) => {
        setComment((inputValue) => inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji))
    }
    return <div className={`pe-comment-input-form-container ${props.className} `}>
        { 
            !props.noTitle && 
            <div className="pe-comment-form-title lead my-2 d-flex align-items-center">
                <Icon icon="edit" size={22} className="mr-3"/>
                {
                    __(props.title ? props.title : "Do comment")
                }
            </div>
        }
        <div className="d-flex">
            <textarea 
                value={comment}
                rows={1}
                onChange={(evt:React.ChangeEvent<HTMLTextAreaElement> ) => {
                    onComment( evt.currentTarget.value )
                }}
                className="form-control input dark glex-grow-100"
            />
            <EmojiButton onChoose={onEmoji}/>
        </div>
        
        <div className="d-flex justify-content-end w-100">
            <Button minimal onClick={onSend} >
                {__( props.buttonLabel ? props.buttonLabel : "Do comment")}
            </Button>
            {props.footer}
        </div>
    </div> 
}

CommentInputForm.propTypes = {}

export default CommentInputForm