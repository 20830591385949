import { useEffect, useState } from "react"
import { __ } from "src/libs/utilities"
import { IToposPostsStore, useToposPostsStore } from "../../data/store"
import { actions } from "../../data/actions"
import { Loading } from "src/libs/useful"
import { IVideo } from "../../data/interfaces"
import { GET_VIDEO_FEED_ACTION } from "../../data/actionTypes"
import ToposSingleVideo, { loadApi } from "../video/ToposSingleVideo"

const ToposVideos = () : JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true) 
    const videos : IVideo[] = useToposPostsStore( (state:IToposPostsStore) => state.videos )
    useEffect(() => {
        Promise.all([ 
            actions(GET_VIDEO_FEED_ACTION, {count: 6}),
            loadApi()
        ])
        .finally( () => setIsLoading( false ) )
    }, [])
    if(isLoading) return <div className="layout-state bg-secondary-super-light "><Loading /></div> 
    return <div className="w-100 bg-secondary-super-light p-4">
        <div className="container">
            <div className="display-6 h">{__("Videos")}</div>
            <div className="row px-3">
                {
                videos
                    .filter( (n, i) => i < 7 )
                    .map( n => <div className="col-lg-6 col-xl-4 col-md-12 col-sm-12 col-12 card rounded-0 p-0" key={n.id}>
                        <ToposSingleVideo item={n} isPlay={false} />
                    </div> )
                } 
            </div>
        </div>        
    </div>
}

ToposVideos.propTypes = {}

export default ToposVideos