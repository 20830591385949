import { useEffect, useState } from "react"
import { FRAME_TRANSITION_TYPE } from "src/libs/interfaces/layouts"
import FrameTransition from "src/libs/useful/FrameTransition"
import { IErmakMap, IErmakSpace } from "../../data/interfaces"
import ErmakMapBtn from "./ErmakMapBtn"

/* Protopia Ecosystem component */
export interface ISpaceProps {
    item: IErmakSpace
}
const Space = ({item}: ISpaceProps): JSX.Element => {
    const [maps, setMaps] = useState<IErmakMap[]>(item.maps)
    const [prevMaps, setPrevMaps] = useState<IErmakMap[]>([])
    const [z, setZ] = useState<number>(item.z)
    const [move, setMove] = useState<FRAME_TRANSITION_TYPE>(FRAME_TRANSITION_TYPE.MOVE_DOWN)

    useEffect(() => {
        const sw = () => {
            setPrevMaps(maps)
            setMaps(item.maps)
        }
        sw()
        setMove( item.z > z ? FRAME_TRANSITION_TYPE.MOVE_DOWN : FRAME_TRANSITION_TYPE.MOVE_UP )
        setZ( item.z )
    }, [ item.maps ])

    return <div className='pe-space-container overflow-y-auto'>
        <FrameTransition
            prevous = { <> {
                prevMaps.map( map => {
                    return <ErmakMapBtn item={map} key={map.id} />
                })
            } </> }
            next = {<> {
                maps.map( map => {
                    return <ErmakMapBtn item={map} key={map.id} />
                })
            } </> }
            type = { move }
        />
    </div>
}
export default Space