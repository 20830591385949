import { __ } from "@/libs/utilities"
import { ILand } from "../../data/interfaces"
import { Intent, Tag } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IEventTypeLabelProps {
    item: ILand
}
const EventTypeLabel = (props: IEventTypeLabelProps): JSX.Element => {
    return <div>
    {
        props.item.event_types.map(et => {
            return <Tag 
                className="m-1 px-3"  
                intent={Intent.SUCCESS} 
                minimal={!props.item.isPattern}
                round 
                key={et}
            >
                {et} 
            </Tag>
        })
        
    }
    {
        props.item.isPattern && <Tag className='m-1 px-3' minimal >
            {__(`pattern`)}
        </Tag> 
    }
    </div>
}
export default EventTypeLabel

 