
import { DocumentNode } from "graphql"
import { useAppoloStore } from "../../../settings/zustand"
import {
    GET_SPACES_ACTION
} from "./actionTypes"
import { ermakMapCellTypes } from "./mocks/ermakMapCellTypes"
import { spaces } from "./mocks/spaces" 

const asyncControllers =  async(action:string, data: any) => {
    let response : any, 
        query : DocumentNode,
        query_name : string, fields : any
        
    const apolloClient: any = useAppoloStore.getState().client
    switch( action )
    {
        case GET_SPACES_ACTION: 
            response = new Promise<any>( (resolve, reject) => {
                resolve({
                    getSpaces: spaces(),
                    getMapCellTypes: ermakMapCellTypes()
                })
            })      
            return response   
        default: 
            return  new Promise<any>( (resolve, reject) => { resolve(`Unknown action type: ${action}`)} )
    }
}
export default asyncControllers