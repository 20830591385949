import { LIKE_TYPE } from "@/libs/interfaces/layouts"

export const likes = () : any => {
    let _list = {
        [LIKE_TYPE.ANGRY]: {
            id: LIKE_TYPE.ANGRY,
            title: "Angry",
            icon: <i className="fas fa-angry"></i>
        },
        [LIKE_TYPE.CLOWN]: {
            id: LIKE_TYPE.CLOWN,
            title: "Clown",
            icon: <i className="fas fa-grin-tongue-squint"></i>
        },
        [LIKE_TYPE.DOWN]: {
            id: LIKE_TYPE.DOWN,
            title: "Dislike",
            icon: <i className="fas fa-thumbs-down"></i>
        },
        [LIKE_TYPE.FIRE]: {
            id: LIKE_TYPE.FIRE,
            title: "Fire",
            icon: <i className="fas fa-fire-alt"></i>
        },
        [LIKE_TYPE.LIKE]: {
            id: LIKE_TYPE.LIKE,
            title: "Like",
            icon: <i className="fas fa-heart"></i>
        },
        [LIKE_TYPE.UP]: {
            id: LIKE_TYPE.LIKE,
            title: "Like",
            icon: <i className="fas fa-thumbs-up"></i>
        },
    } 

    return _list
}