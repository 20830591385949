import { Dummy, Loading } from "src/libs/useful"
import { __ } from "src/libs/utilities"
import { Button } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

const Conquest = (props: any) : JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [__html, set__html] = useState<string>("") 
    useEffect(() => {
        fetch("/assets/data/manitu/conquestPixels-ru-RU.html")
          .then( response => response.text() ) 
          .then( html =>  {
            set__html(html) 
            setIsLoading(false)
          })
         
      }, [])
    if( isLoading) 
    {
        return <div className="layout-state w-100 h-100 flex-centered">
            <Loading />
        </div>
    }  
    return <div className="p-4 h-100 overflow-y-auto-thin">
        <div className="container mb-5 d-flex ">
            <div className="row "> 
                <div className="col-md-3">
                    <div className="my-0" dangerouslySetInnerHTML={{__html}} /> 
                </div> 
                <div className="col-md-9 my-3 pe-conquest-btn-group">  
                    <ConquestBtn 
                        label={__("Tests")}
                        path="tests"
                        thumbnail="https://i.pinimg.com/474x/1c/cc/0d/1ccc0d2dc40a4a06577b8043120b7ba4.jpg" 
                    /> 
                    {/* <ConquestBtn 
                        label={__("Questions")} 
                        path="questions"
                        thumbnail="https://i.pinimg.com/474x/ac/52/44/ac52442e9f26b3114625d9d3fc64bb2d.jpg"
                    />  */}
                    <ConquestBtn 
                        label={__("Tasks")} 
                        path="media"
                        thumbnail="https://i.pinimg.com/474x/f1/02/2d/f1022dea9d32c1306aabad3c6bdc04f8.jpg"
                    /> 
                    {/* <ConquestBtn 
                        label={__("Conquest reseach")} 
                        path="reseach"
                        thumbnail="https://i.pinimg.com/564x/e2/b2/e0/e2b2e07e4ab3763685370283496b5726.jpg"
                    />   */}
                </div>
            </div>
        </div>
    </div>
} 

export default Conquest

const ConquestBtn = (props: any) : JSX.Element => {
    return <NavLink to={props.path} className="pe-conquest-pixel-btn">
        <Dummy
            height={300}
            width={300}
        />
        <div 
            className=" position-absolute d-flex justify-content-center align-items-end w-100 h-100"
            style={{backgroundImage:`url(${ props.thumbnail})`}}
        >
            <div className="label">
                {__( props.label)}
            </div>
        </div>
    </NavLink>
}