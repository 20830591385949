import BasicState from "@/libs/basic-view"
import NoMatchForm from "./no-mathc/NoMatchForm"

class NoMatchView extends BasicState  { 
  addRender() {
    return <NoMatchForm {...this.props} />
  }
}

export default NoMatchView
