import RegisterProject from "./register-project"


const helps = (): any =>
{
    return {
        "register-project": {
            component   : RegisterProject,
            id          : "register-project"
        }
    }
}
export default helps