import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { ILand } from "../../data/interfaces" 
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"

/* Protopia Ecosystem component */
export interface IFestivalSiteGalleryFormProps {
    item: ILand
    className?: string
    onChange: (value: string[], names: string[] ) => void
}
const FestivalSiteGalleryForm = (props: IFestivalSiteGalleryFormProps): JSX.Element => {
    const onChange = ( value: any, field: string, data: string) => {  
        props.onChange(value, data.split(","))
    }
    return <FieldInput
        type={SCALAR_TYPES.GALLERY}
        editable
        vertical={VerticalType.VERTICAL} 
        width={155}
        height={155}
        value={props.item.images}
        onChange={onChange}
    />
}
export default FestivalSiteGalleryForm