import { IErmakItems, IErmakMapCell } from "../../data/interfaces"
import ErmakSlotGallery from "./ErmakSlotGallery";

/* Protopia Ecosystem component */
export interface IErmakSlotItemProps {
    cell: IErmakMapCell | undefined
}
const ErmakSlotItem = (props: IErmakSlotItemProps): JSX.Element => {
    const items = props.cell?.items || [];
    const item: IErmakItems = items[0]
    return <div 
        className='pe-ermak-slot-content flex-centered'
        
    >
        <div 
            className='pe-ermakcell-items-container w-100 h-100'
            style={{
                backgroundImage: `url(${ item?.thumbnail })`
            }}
        >
            
        </div>
        <ErmakSlotGallery item={item} />
        <div className="title">{item?.title}</div>
        <div className="small">{item?.post_content}</div>
    </div>
}
export default ErmakSlotItem