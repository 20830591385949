import { Outlet, useNavigate, useParams } from "react-router"
import { ITestProps } from "./SingleTest"
import { Button } from "@blueprintjs/core"
import { __ } from "src/libs/utilities"
import { useEffect } from "react"
import StepsMonitor from "./StepsMonitor"
import TestTimer from "./TestTimer"
import { ITestsStore, useTestsStore } from "../../data/store"

const Test = ({ item }: ITestProps) : JSX.Element => {
  const navigate = useNavigate()
  const params = useParams() 
  const waybill: string[] = useTestsStore((state:ITestsStore) => state.waybill)
  const currentStep: number = useTestsStore((state:ITestsStore) => state.currentStep)
  const tryCount: number = useTestsStore((state:ITestsStore) => state.tryCount) 
  useEffect(() => { 
    useTestsStore.setState({waybill : [
      "", 
      ...item.questions.map(q => `question/${q.id.toString()}`), 
      "finish", 
      //"results"
    ]})
    useTestsStore.setState( {currentStep : params.question_id ? Number(params.question_id) + 1 : 0 } ) 
    useTestsStore.setState( { results : [] } ) 
    useTestsStore.setState( { tryCount : 0 } ) 
  }, [])

  useEffect(() => {
    navigate( waybill[ currentStep ] )
    if( currentStep === 0 ) {
      useTestsStore.setState( { results : [] } ) 
    }
  }, [currentStep])

  const onPrev = () => {
    const next = currentStep === 0 ?  waybill.length - 1 : currentStep - 1
    navigate( waybill[ next ] )
    useTestsStore.setState( { currentStep : next } ) 
  }
  const onNext = () => {
    const isFinish: boolean = currentStep === waybill.length - 1
    const next = isFinish ? 0 : currentStep + 1
    useTestsStore.setState( { currentStep : next } ) 
    if(isFinish) {
      useTestsStore.setState( { tryCount : tryCount + 1 } ) 
    }
  }
  const isTimed: boolean = !!item.is_timed && currentStep > 0 && currentStep <= item.questions.length
  
  return (
    <div className="w-100 h-100 d-flex flex-column pb-5 mb-5">
        <StepsMonitor item={ item } current={ currentStep } full={ item.questions.length + 1 }/>
        <div className="flex-grow-100 overflow-y-auto ">
          <Outlet />
        </div>
        <div className="flex-centered p-0 mb-4">
          {
            item.try_count >= tryCount && !isTimed
              ?
              <>
                <Button large minimal onClick={onNext}>
                  { __( getLabel( currentStep, item.questions.length + 1 ) ) }
                </Button>
                {/* { currentStep !== 0 && <Button large minimal onClick={onPrev} icon="caret-left" /> } */}
              </>
              :
              isTimed
                ?
                <TestTimer onBreak={ onNext } duration={item.duration || 0}/>
                :
                null
          } 
        </div> 
    </div>
  )
}

export default Test

const getLabel = (current: number, full: number): string => {
  switch( current ) {
    case 0:
      return "Start"
    case full:
      return "Replay"
    case 1:
    default:
      return "Next"
  }
}