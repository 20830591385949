 

import Moment from "react-moment" 
import FeedComponent from "src/modules/pe-basic-module/views/core/FeedComponent" 
import { IPEVKPost } from "src/libs/interfaces/layouts"

interface IProps {
    item: IPEVKPost
}
const PEVKPost = (props: IProps, i: number) : JSX.Element => {
    return <FeedComponent 
        data_type="PEVKPost"
        item={props.item}
        i={i}
        //className={Classes.SKELETON}
        footer={<Footer item={props.item} />}
    />
}
 

export default PEVKPost

const Footer = ( props: any) : JSX.Element => {
    return <div className="flex-centered flex-wrap w-100"> 
        <a href={props.item.link} target="_blank"  rel="noreferrer" className="flex-centered p-2 text-force-contrast hover">
            <div className="fab fa-vk"></div>
        </a>
        <a href={props.item.link} target="_blank"  rel="noreferrer" className="flex-centered mx-3 text-force-contrast hover">
            <div className="avatar rounded-circle mx-2" style={{ backgroundImage: `url(${props.item.post_author?.avatar})`}}></div>
            <span>{ props.item.post_author?.display_name }</span>
        </a>
        <div className="post-date mx-1 p-2">
            <Moment locale="ru" format="D.MM.YYYY ">
                {new Date(props.item.post_date)}
            </Moment>
        </div> 
        <div className="post-date ml-auto p-2 ">
            <span className="far fa-heart" />
            <span className="mx-1">{props.item.likes.length}</span>
        </div> 
        <div className="post-date mx-1 p-2">
            <span className="far fa-comment" />
            <span className="mx-1">{props.item.comments.length}</span>
        </div> 
    </div> 
}