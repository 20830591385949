import { useState } from "react"
import Banner from "./Banner"

const BannerContainer = () : JSX.Element | undefined | boolean => {
    const [count, ] = useState<number>(Math.round( Math.random() * 2 ) + 1)
    return false && <div>
        {
            Array(count).fill(1).map((_, i:number) => {
                return <Banner i={i} key={i} />
            })
        }
    </div> 
} 

export default BannerContainer