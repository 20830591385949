
import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import { useState } from "react"
import { URL } from "src/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { __ } from "src/libs/utilities"

const AddNewAdForm = ({onOpen, ...props} : any) : JSX.Element => {
    const [ title, setTitle] = useState<string>("")
    const [ post_content, setPostContent] = useState<string>("")
    const [ date, setDate] = useState<string>("")
    const [ thumbnail, setThumbnail] = useState<string>("")
    const [ link, setLink] = useState<URL>("")

    const onThumbnail = (value: any, field: string="", data: any) => {
        setThumbnail(value) 
    }

    return <div className=" h-100 w-100 d-flex flex-column ">
        <div className="flex-grow-100 w-100 overflow-y-auto">
            <div className="short-container my-5">
                <FieldInput
                    title={__("Title")}
                    value={ title }
                    editable
                    onChange={value => setTitle( value )}
                    className={"display-6 form-control input dark"}
                />
                <FieldInput
                    title={__( "Thumbnail" )}
                    value={ thumbnail }
                    type={ SCALAR_TYPES.MEDIA }
                    hideLib
                    isFileNameHide
                    isURLHide
                    height={ 230 }
                    width={ 180 }
                    editable
                    onChange={onThumbnail} 
                />
                <FieldInput
                    title={__( "Content" )}
                    value={ post_content }
                    type={ SCALAR_TYPES.TEXT }
                    editable
                    onChange={post_content => setPostContent( post_content )} 
                />
                <FieldInput
                    title={__( "Date" )}
                    value={ date }
                    type={ SCALAR_TYPES.DATE }
                    editable
                    onChange={date => setDate( date )} 
                />
                <FieldInput
                    title={__("Link")}
                    value={ link }
                    type={SCALAR_TYPES.URL}
                    editable
                    onChange={link => setLink( link )}
                    className={"lead form-control input dark"}
                />
            </div>
        </div>
        <ButtonGroup className="mb-3 d-flex flex-centered " large>
            <Button intent={Intent.DANGER} onClick={onOpen}>
                { __( "Close" ) }
            </Button>
            <Button intent={Intent.SUCCESS} onClick={onOpen}>
                { __( "Update" ) }
            </Button>
        </ButtonGroup>
    </div>
} 

export default AddNewAdForm