import React from "react" 
import BasicState from "src/libs/basic-view"
import MyFeedForm from "./myFeed/MyFeedForm" 
import "../assets/css/style.scss"

export default class MyFeedView extends BasicState {
    props: any
    addRender = () => {
        return <MyFeedForm {...this.props} />  
    }
}