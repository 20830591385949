import { LOGOUT_ACTION } from '@/modules/pe-basic-module/data/actionTypes'
import actions from '@/modules/pe-basic-module/data/actions'
import DayNightSwitcher from '@/modules/pe-basic-module/widgets/DayNightSwitcher'
import { Button, ContextMenu, ContextMenuContentProps, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { __ } from "@/libs/utilities"
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import LoginForm from "src/modules/pe-basic-module/views/profile/LoginForm"
import { IState, IUser, useMainStore } from '../../settings/zustand'
import { DIALOG_SIZE } from '../interfaces/layouts'
import { ScalableDialog } from '../useful'
import ExtendMenu from './ExtendMenu'

interface IContextMenyProps {
    children: JSX.Element | JSX.Element[] | null 
    onHideLogin: () => void
}
const ContextMenuContainer = (props: IContextMenyProps) : JSX.Element => {
    const user: IUser = useMainStore((state: IState) => state.user )
    const isOpenLogin : boolean = useMainStore((state: IState) => state.isOpenLogin ) 
    const [isPressControll, setPressControll] = useState(false) 
    const navigate = useNavigate() 
    const setOpenLogin = (is: boolean) => {
        useMainStore.setState({isOpenLogin : is})
    }
    const pressControl = ( e:any ) => {
      if( e.key === "Control") setPressControll(true) 
    }
    const upControl = () => {
      setPressControll(false) 
    }
    const loginUser = () =>
    { 
        if( user.id ) {
            onLogout()
        }            
        else {
            setOpenLogin(!isOpenLogin)
        }		    
    }
    const onLogout = () => {
        actions(LOGOUT_ACTION, {})
        window.location.reload()
    }
    const onResult = () =>
    { 
        props.onHideLogin()
        window.location.reload()
    } 
    useEffect(() => {
        document.addEventListener("keydown", pressControl)
        document.addEventListener("keyup", upControl) 
        useMainStore.setState({ location: window.location.origin })
        return () => {
            document.removeEventListener("keydown", pressControl)
            document.removeEventListener("keyup", upControl)
        }
    }, [])
    const renderContent = React.useCallback(
        ( props : ContextMenuContentProps) => { 
            
            return <Menu> 
                <MenuItem text={__( "back" )} icon="arrow-left"  onClick={() => navigate(-1) }/> 
                <ExtendMenu menuProps={props} />
                <DayNightSwitcher type="buttons" className=" text-force-contrast "/>
                <MenuDivider />
                <MenuItem text={__( user.id ? "Log out" : "Log in" )} icon="person" onClick={ loginUser }/> 
            </Menu> 
        },
        [],
    )
    return <ContextMenu
        className="h-100"
        disabled={isPressControll}
        content={renderContent}
    >
        <>
            {props.children}
        </>
        <ScalableDialog 
            onClose={ props.onHideLogin }
            isOpen={ isOpenLogin } 
            dialogSize={ DIALOG_SIZE.LITTLE } 
        >
            <div className="p-4 row">
                <LoginForm onResult={onResult} onResultArgs={{}} />
            </div>
            <Button 
                className='position-absolute right top' 
                minimal 
                onClick={ props.onHideLogin } 
                icon="cross" 
            />
        </ScalableDialog> 
    </ContextMenu> 
}

ContextMenuContainer.propTypes = {}

export default ContextMenuContainer

