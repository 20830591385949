import { __ } from "@/libs/utilities"
import { Button, ButtonGroup, Dialog, Intent } from "@blueprintjs/core"
import { IAdminStore, IBulk, useAdminStore } from "../../data/store"
import { useState } from "react"
import { getVisibleValue, schema } from "@/libs/layouts" 
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION, UPDATE_BULK_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"
import { useParams } from "react-router"
import { IAppoloField } from "@/libs/interfaces/layouts"

const BulkEditor = (props : any) : JSX.Element => {
    const {landId} = useParams()
    const items: any[]                          = useAdminStore((state:IAdminStore) => state.items)
    const [isLoading, setIsLoading]             = useState<boolean>(false)
    const [isOpen, setOpen]                     = useState<boolean>(false)
    const [data, setData]                       = useState<any>({})
    const bulks: IBulk[]                        = useAdminStore((state:IAdminStore) => state.bulks)
    const offset: number                        = useAdminStore((state:IAdminStore) => state.offset) 
    const paging: string                        = useAdminStore((state:IAdminStore) => state.paging) 
    const pageCount: number                     = useMainStore( ( state: IState ) => state.pageCount )
    const setBulks: ((bulks: IBulk[]) => void)  = useAdminStore( (state:IAdminStore) => state.setBulks) 
    const setItems: ((items: any[]) => void)    = useAdminStore( (state:IAdminStore) => state.setItems )
    const visibleValue:string                   = getVisibleValue(props.data_type)

    const onFinallyEdit = () => {
        const _item: any = {...data }
        Object.keys( _item ).forEach( (key:string) => {
            const schem: IAppoloField = schema()[ props.data_type ].apollo_fields[ key ]
            console.log( key, schem?.type )
            if( schem?.type === SCALAR_TYPES.EXTERNAL ) {
                //console.log( schem.component )
                _item[key] = parseInt( _item[key]?.id || -1 )
            }
            if( schem?.type === SCALAR_TYPES.EXTERNAL_ARRAY ) {
                _item[key] = _item[key].map((it: any) => {
                    return parseInt( it?.id || -1 )
                })
            }
        })
        console.log( _item ) 
        setOpen( false )  
        setIsLoading( true )  
        actions( 
            UPDATE_BULK_ACTION, 
            { 
                id: bulks.filter(bulk => bulk.checked).map(bulk => bulk.id), 
                data_type : props.data_type, 
                data: _item,
                landId
            } 
        )
            .then((response) => { 
                console.log(response)
                actions(
                    GET_FEED_ACTION, 
                    { 
                      data_type: props.data_type, 
                      offset, 
                      count: pageCount, 
                      paging,
                      land_id: landId 
                    })
                    .then( response2 => {
                        setIsLoading( false )
                        setItems(response2.feed)
                        setBulks( response2.feed.map((f: any) => ({
                            id: f.id,
                            checked: false,
                            data_type: props.data_type
                        })))
                    })
            })
    }
    const onChange = (value: any, field : string) => {
        let _data: any = {...data}
        _data[field] = value
        setData( _data )
    }
    return <>
        <Button icon="cog" className=" h-100 px-4" intent={Intent.SUCCESS} onClick={() => setOpen(true)} >
            {__("Edit bulk items")}
        </Button> 
        <Dialog
            isOpen={isOpen} 
        >
            <div className="p-0 flex-centered flex-column w-100 overflow-y-auto" style={{ height: "calc(100vh - 200px)" }}> 
                <Editor 
                    data_type={props.data_type} 
                    data={data}
                    onChange={onChange}
                    landId={landId}
                /> 
            </div>
            <ButtonGroup fill>
                <Button intent={Intent.DANGER} onClick={ onFinallyEdit } className="p-3">
                    {__("Edit")}
                </Button>
                <Button intent={Intent.SUCCESS} onClick={() => setOpen(false)} className="p-3">
                    {__("Close")}
                </Button>
            </ButtonGroup>
        </Dialog>
    </>
} 

export default BulkEditor

const Editor = ({data_type, data, onChange,...props}: any) :JSX.Element => {
    const apollo_fields: any = schema()[data_type].apollo_fields
    const fields : JSX.Element[] = Object.keys(apollo_fields)
        .filter((field: any) => apollo_fields[field].editable && !apollo_fields[field].hidden && field !== "id" && field !== "title" && field !== "version" && field !== "post_content")
        .map((field: any) => { 
            return <FieldInput
                key={ field }  
                field={ field }  
                landId={props.landId}
                component={ apollo_fields[field].component }
                type={ apollo_fields[field].type }
                title={ apollo_fields[field].title }
                value={ data[ field ] }
                editable
                onChange={ onChange }
            />
        })
    return <div className="h-100 w-100">
        <div style={{height:20}}/> {fields} <div style={{height:20}}/>
    </div>
}