
/* Protopia Ecosystem component */
export interface ITileEpisodeMenuProps {
    onTalk: () => void
    onReturn: () => void
}
const TileEpisodeMenu = (props: ITileEpisodeMenuProps): JSX.Element => {
    return <div className='pe-tile-episode-menu-container'>
        <div className="main-menu-back">
            <div 
                className="pe-fabula-arrow-bottom pe-fabula-map-tile-arrow" 
                onClick={  props.onReturn }  
            />     
        </div>
    </div>
}
/*
const TileEpisodeMenu2 = (props: ITileEpisodeMenuProps): JSX.Element => {
    return <div className='pe-tile-episode-menu-container'>
        <div className="main-menu">
            <Tooltip
                position={Position.TOP}
                content={<div className="text-uppercase" >
                    {__("Talk")}
                </div>}
            >
                <Button
                    minimal
                    fill
                    className=" "
                    onClick={ props.onTalk }
                >
                    <i className="fas fa-bullhorn"></i>
                </Button>
            </Tooltip>
            <Tooltip
                position={Position.TOP}
                content={<div className="text-uppercase" >
                    {__("Return")}
                </div>}
            >
                <Button
                    minimal
                    fill
                    className=" "
                    onClick={ props.onReturn }
                >
                    <i className="fas fa-undo text-light"></i>
                </Button>
            </Tooltip> 
        </div> 
    </div>
}
*/
export default TileEpisodeMenu