import { PE_TEST_QUESTION_TYPE } from "../interfaces"
import { testCategories } from "./testCategories"

export const test3 = () => {
    return {
        id: 3,
        title: "Андерсен Х. К. «Снежная королева» Часть 1",
        post_content: "Тест по литературе для 5 класса",
        testCategory: testCategories().filter(t => t.id === 2)[0],
        pe_academic_discipline: [{
            color: '#214700',
            id: '25',
            order: 6,
            post_content: '',
            thumbnail: 'false',
            title: 'Литература',
        }],
        thumbnail: "https://i.pinimg.com/564x/35/65/d2/3565d20ddf55f1756b76dbc52afb18af.jpg",
        questions: [
            {
                id:1,
                title: "Кто «смастерил ... зеркало, в котором всё доброе и прекрасное уменьшалось», а всё «безобразное ... бросалось в глаза и казалось ещё хуже»?",
                thumbnail: "https://3.bp.blogspot.com/-LqNoHLr9Ixo/VIXPOiGe_ZI/AAAAAAAAB0A/bQ7q6p4HTpY/s1600/00-1.jpgg",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "злой король",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "тролль",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "дьявол",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "Кай",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "Снежная королева",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 2,
                title: "Что случалось с людьми, если осколок зеркала попадал им в сердце?",
                post_content: "",
                thumbnail: "https://3.bp.blogspot.com/-LqNoHLr9Ixo/VIXPOiGe_ZI/AAAAAAAAB0A/bQ7q6p4HTpY/s1600/00-1.jpg",
                answers : [
                    {
                        id: 1,
                        title: "они умирали",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "они превращались в ангелов",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "их сердце превращалось в кусок льда",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "они становились учениками школы тролля",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 3,
                title: "Что имела в виду старая бабушка, когда говорила: «Это роятся белые пчёлки!»?",
                post_content: "",
                thumbnail: "https://3.bp.blogspot.com/-nMsIUXELsk8/VIXQAd3WfGI/AAAAAAAAB1A/xyqguafQteM/s1600/08.jpg",
                answers : [
                    {
                        id: 1,
                        title: "пчёл",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "снег",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "лепестки роз",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "узоры на окне",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id:4,
                title: "Кто похитил Кая?",
                post_content: "",
                thumbnail: "https://4.bp.blogspot.com/-IJBoE97ZD4I/VIXPlGjIjYI/AAAAAAAAB0g/sk_xWFJJoHM/s1600/04.jpg",
                answers : [
                    {
                        id: 1,
                        title: "Снежная королева",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "тролль",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "его не похищали",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "дьявол",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id:5,
                title: "На чью спину привязали санки Кая?",
                post_content: "",
                thumbnail: "https://ljplus.ru/img4/s/u/surmv/golc-sn-kor0009.jpg",
                answers : [
                    {
                        id: 1,
                        title: "Снежной королевы",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "тролля",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Герды",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "белой курицы",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id:6,
                title: "Кто не поверил Герде, когда она говорила о том, что Кай умер?",
                post_content: "",
                thumbnail: "https://4.bp.blogspot.com/-rpFhFRjm87g/VIXQeOlkmMI/AAAAAAAAB1w/ez-P8WmhlkA/s1600/14.jpg",
                answers : [
                    {
                        id: 1,
                        title: "ласточки и стрижи",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "звёзды и снежинки",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "розы и горох",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "солнечный свет и ласточки",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 7,
                title: "Что Герда отдала реке для того, чтобы она вернула ей названого братца?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "белые тапочки",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "белую ленту",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "красные башмачки",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "золотые сапожки",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 8,
                title: "Кем была женщина, которая жила в домике с красными и синими стёклами в окошках и с соломенной крышей?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Снежной королевой",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "старушкой, которая изредка колдовала",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "злой колдуньей",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "бабушкой Герды",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 9,
                title: "Как старушка заколдовала Герду?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "дала ей выпить волшебный чай",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "рассказала ей сказку и уложила спать на волшебную кровать",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "дотронулась до Герды своей клюкой",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "расчесала ей волосы золотым гребешком",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 10,
                title: "Что помогло Герде вспомнить о Кае, когда она жила в домике с разноцветными стёклами в окнах?",
                thumbnail: "https://i.pinimg.com/564x/ff/5b/2b/ff5b2be7df36876632fbfbe46e6f105c.jpg",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "санки",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "снежинки",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "кусты роз",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "клумба с разными цветами",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },  
        ],
        is_show_answer:false, 
        is_show_results:true, 
        is_shuffle:true, 
        is_timed:false, 
        duration: 20,
        try_count: 10
    }
}