
import { CommentInputForm, Likes } from "src/libs/useful"
import { IPEVKPost } from "src/libs/interfaces/layouts"
import Moment from "react-moment" 
import ThumbnailTile from "./ThumbnailTile"
import MessageURL from "./MessageURL"

interface IMessageProps {
    item: IPEVKPost
}

const Message = ({item}: IMessageProps) : JSX.Element => {
  return (
    <div className="position-relative"> 
        <a href={item.link} target="_blank"  rel="noreferrer" className="vk-avatar hover">
            <div className="avatar rounded-circle mr-2" style={{ backgroundImage: `url(${item.post_author?.avatar})`}}></div>
        </a> 
        <svg className="avatar-tail " x="0px" y="0px" viewBox="0 0 20 20" >
            <path className="card_path" d="M20.1,19.7c-1.8-8-5.7-14.5-14.1-18C5.1,1.3,5.4,0,6.4,0c5.8,0,13.7,0,13.7,0L20.1,19.7z"/>
            <path className="contour"   d="M19.8,19.7C17.7,10.1,12.4,2.6,0.2,0"/> 
	        <path className="contour2"  d="M20.1,19.7c-1.8-8-5.7-14.5-14.1-18C5.1,1.3,5.4,0,6.4,0c5.8,0,13.7,0,13.7,0"/> 
        </svg>
        <div className="vk-post card">
            <div className="head">
                <div className="about mr-auto">
                    <a href={item.link} target="_blank"  rel="noreferrer" className="flex-centered mr-3 text-force-contrast hover">
                        <span>{ item.post_author?.display_name }</span>
                    </a>
                    <div className="about"> 
                        <h5 className="title h mr-auto">
                            { item.title }
                        </h5> 
                    </div>
                </div> 
                <div className="post-date mx-1 p-2">
                    <Moment locale="ru" format="D.MM.YYYY ">
                        {new Date(item.post_date!)}
                    </Moment>
                </div>
                <a href={item.link} target="_blank"  rel="noreferrer" className="flex-centered p-2 text-force-contrast hover">
                    <div className="fab fa-vk"></div>
                </a>
            </div>
            <div className="content">
                <div className="mb-3">
                    <ThumbnailTile item={item} /> 
                </div> 
                <div 
                    className="position-relative"
                    dangerouslySetInnerHTML={{ __html: item.post_content }}
                />
                <MessageURL item={item} />
            </div>
            <CommentInputForm 
                item={item} 
                className=" px-4 pb-2" 
                noTitle
            />
            <div className="footer">
                
                <div className="flex-centered flex-wrap w-100"> 
                    
                    <div className="ml-auto p-2 "> 
                        <Likes item={item} className="" dataType="PEVKPost" />
                    </div> 
                    <div className="mx-1 p-2">
                        <span className="far fa-comment" />
                        <span className="mx-1">{item.comments!.length}</span>
                    </div> 
                </div> 
            </div>
        </div>
    </div>
  )
} 
export default Message
 