import { useEffect, useState } from "react" 
import AddPixels from "./AddPixels";
import ConquestPixels from "./conquest/ConquestPixels"; 
import { Navigate, Outlet, Route, Routes } from "react-router";
 
const Research = () : JSX.Element => { 

  return <div className="container flex-grow-100 reseach-tabs-container">
    <Routes>
      <Route element={<Outlet />} >
        <Route
          path="add-pixel/*" 
          element={<AddPixels />}
        />    
        <Route
          path="*" 
          element={<Navigate to="add-pixel" />}
        />    
        <Route
          path="complete-pixels/*" 
          element={<ConquestPixels />}
        />   
        <Route
          path="verify-pixels" 
          element={<VerifyPixels />}
        />   
      </Route>
    </Routes> 
  </div>
}
export default Research 

const VerifyPixels = ( ) : JSX.Element => {
  return <div className="p-4 h-100 overflow-y-auto-thin">
    <div className="container mb-5">
      Verify Pixels
    </div> 
  </div>
}
const About = ( ) : JSX.Element => {
  const [text, setText] = useState<string>("")
  useEffect(() => {
    fetch("/assets/data/manitu/about-ru-RU.html")
      .then( response => response.text() ) 
      .then( html =>  setText(html) )
  }, [])
  return <div className="p-4 h-100 overflow-y-auto-thin" >
    <div className="container mb-5" dangerouslySetInnerHTML={{ __html: text }} />
  </div>
}
