import { useEffect, useState } from "react"
import { IPEResourceType, IUserResource } from "../../data/interfaces" 
import { ILudensStore, useLudensStore } from "../../data/store"
import { ID } from "@/libs/interfaces/layouts"

interface IProps {
    resource: IPEResourceType
}

const ResourceSingleIndicator = ({ resource }: IProps) => {
    const userResource: { [key: ID]: IUserResource } = useLudensStore((state:ILudensStore) => state.userResource)
    const userMaxResource: { [key: ID]: IUserResource } = useLudensStore((state:ILudensStore) => state.userMaxResource)
    const [current, setCurrent] = useState<number>(userResource[ resource.id ]?.count || 0)
    const [max, setMax] = useState<number>(userMaxResource[ resource.id ]?.count || 0) 
    useEffect(() => {
        setCurrent( userResource[ resource.id ]?.count || 0 )
        setMax(  userMaxResource[ resource.id ]?.count || 0 )
    }, [userResource, userMaxResource]) 

    return <div className="pe-resource-s-indicate" >
        <div 
            className="pe-mmsi"
            style={{
                backgroundColor: resource.color,
                height: `${ current / max * 100 }%`
            }}
        />
    </div> 
}

export default ResourceSingleIndicator