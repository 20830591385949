import { useDataStore } from "src/modules/pe-basic-module/data/store"
import { ID } from "src/libs/interfaces/layouts"
import { IPEResourceType, IUserResource } from "../../data/interfaces"
import { ILudensStore, useLudensStore } from "../../data/store"
import ResourceMonitorSingle from "./ResourceMonitorSingle"

 
const ResourceMonitorScreen = () : JSX.Element => {
    const userResource: { [key: ID]: IUserResource } = useLudensStore((state:ILudensStore) => state.userResource)
    const resourceTypes: IPEResourceType[] = useDataStore((state: any) => state.PEResourceType) || []
    return <div className="monitor datas widget ">
        {
            resourceTypes.map(resource => {
                return <ResourceMonitorSingle 
                    resource={ resource } 
                    count={ userResource[ resource.id ]?.count } 
                    key={ resource.id } 
                    clickable
                /> 
            })
        }
    </div> 
}

export default ResourceMonitorScreen