
import { Button, Dialog, Icon } from "@blueprintjs/core"
import { SyntheticEvent, useState } from "react"
import { __ } from "src/libs/utilities" 
import AddNewAdForm from "./AddNewAdForm"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"

const AddAdForm = () : JSX.Element | "" | 0 => {
    const [ isOpen, setIsOpen ] = useState<boolean>(false)
    const user: IUser = useMainStore( (state:IState) => state.user )
    const onOpen = ( evt: React.MouseEvent | SyntheticEvent<HTMLElement, Event> ) => {
        evt.preventDefault()
        evt.stopPropagation()
        setIsOpen( !isOpen )
    }
    return user.id && <>
        <a href="*" className="small ml-3 mt-1" onClick={ onOpen }>
            { __( "Add new Ad" ) }
        </a> 
        <Dialog
            isOpen={ isOpen }
            onClose={ onOpen }
            className={DIALOG_SIZE.FULL}
        >
            <div className="p-4 flex-centered h-100">
                <AddNewAdForm onOpen={onOpen} />
            </div>
            <Button minimal onClick={ onOpen } className="position-absolute top right px-3 py-2">
                <Icon icon="cross" size={20} color="#FFFFFF"/>
            </Button>
        </Dialog>
    </>
} 

export default AddAdForm