import { __ } from "@/libs/utilities"
import { Button, ButtonGroup, Intent, Popover, Position, Tooltip } from "@blueprintjs/core"
import { useState } from "react"

interface IProps {
    onAddMenuGroup: ( name: string ) => void
}
const AddMenuGroup = ({onAddMenuGroup} : IProps) : JSX.Element => {
    const [newMenuGroupName, setNewMenuGroupName ] = useState<string>("")
    return <Popover
        content={
        <div className='square'>
            <div>
                <small>{ __( 'Set menu name' ) }</small>
                <input
                    type='text' 
                    value={newMenuGroupName}
                    onChange={evt => setNewMenuGroupName(evt.currentTarget.value ) }
                    className='form-control input dark mt-2 mb-4'
                    placeholder={ __('use latin, numbers and _ ') }
                />
                <ButtonGroup>
                    <Button intent={Intent.SUCCESS} onClick={ () => onAddMenuGroup( newMenuGroupName ) }>
                        { __('Insert menu group') }
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        }
    >
        <Tooltip content={__('Add new Menu group')} position={Position.TOP_RIGHT} >
            <div className='btn btn-link text-secondary mr-3 w-100 btn-sm ' >
                <i className="fas fa-plus" />   
            </div> 
        </Tooltip> 
    </Popover>
} 
export default AddMenuGroup