import { Outlet, Route, Routes } from "react-router" 
import FestivalAdminList from "./FestivalAdminList"
import FestivalAdminSingle from "./FestivalAdminSingle"
import AddFestivalForm from "./AddFestivalForm"
import FestivalPatternsList from "./FestivalServerSettings"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import FestivalAdminButton from "./FestivalAdminButton"
import { isRole } from "@/settings/zustand/user"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { useEffect } from "react"
import { clearFestival } from "../../hooks/festival"
import FestivalServerSettings from "./FestivalServerSettings"
import switchToBlog from "@/modules/pe-basic-module/hooks/switchToBlog"
import NoMatchForm from "@/modules/pe-basic-module/views/no-mathc/NoMatchForm"
import { DEFAULT_404_PROPS } from "@/libs/interfaces/dummies" 

const FestivalAdminListForm = (props:any) :JSX.Element => { 
    const user: IUser = useMainStore((state: IState) => state.user)
    return <Routes>
        <Route path={`/*`} element={<Outlet />} >
            <Route path={`/*`} element={<FestivalAdminList />} >
                <Route index
                    element={
                        <Feed 
                            data_type="Land"
                            offset={0} 
                            class_name = "list-group list-group-flush container"
                            containerClassName="container-fluid"  
                            layoutComponent={"ul"} 
                            component={ FestivalAdminButton }
                            topPagiHide
                            paging={ !isRole("administrator") ? ` metas: [ {key : "demiurg_id", value: "${user.id}"}] ` : ``}
                            params={{ }}
                        />     
                    }
                />
                <Route path="settings" element={<FestivalServerSettings />} /> 
                <Route path="add-festival" element={<AddFestivalForm />} />
                <Route path='*' element={<NoMatchForm {...DEFAULT_404_PROPS} />}  />
            </Route>
            <Route 
                path={`:landId/*`} 
                element={
                    <FestivalAdminSingle 
                        path={`${props.realRoute}`}
                    />
                } 
            />
            <Route path='*' element={<NoMatchForm {...DEFAULT_404_PROPS} />}  />
        </Route>
    </Routes> 
} 

export default FestivalAdminListForm