import React from 'react' 
import { Link, useLocation } from 'react-router-dom'
import { __, initArea } from '@/libs/utilities'
import { login } from '@/libs/layouts' 
import { ILayoutUserProps } from './LayoutUser'
import { template } from '../../layouts'

const LayoutUnloginned = (props: ILayoutUserProps) => { 
  const location = useLocation()
  return <>
  {
    initArea(
      "unlogin_panel",
      { ...props },
      <Link 
        state={{ pathname: location.pathname }}
        className={`icon-unlogined mx-3 ${template().header_menu}`} 
        to={login()} 
      > 
          <i className="fas fa-sign-in-alt mr-2"></i>
          {__("Enter")} 
      </Link>,
    )
  }
  </>
} 

export default LayoutUnloginned