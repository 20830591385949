import React from "react" 
import BasicState from "@/libs/basic-view" 
import ResetPasswordForm from "./profile/ResetPasswordForm"

class RememberPasswordView extends BasicState {  
  props: any 

	addRender = () => (
  <>
    <div className="container">
      <div className="row justify-content-center"> 
        <ResetPasswordForm {...this.props} /> 
      </div>
    </div>
  </>
	) 
}
export default RememberPasswordView
