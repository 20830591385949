import React from "react" 
import BasicState from "src/libs/basic-view"
import PlaceForm from "./place/PlaceForm" 
import "../assets/css/style.scss"

export default class PlaceView extends BasicState {
    props: any
    addRender = () => {
        return <PlaceForm {...this.props} />  
    }
}