import { Button, ButtonGroup, Dialog, Popover, PopoverInteractionKind, Position, Slider } from "@blueprintjs/core"
import { useEffect, useRef, useState } from "react" 
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { __ } from "src/libs/utilities"
import { IManituActionType, IManituEnergyPrice, IUserMana, MANITU_ACTION_DISTINATION, MATRIX_MODE } from "../../../data/interfaces"
import { IManaProps } from "../../../data/manas"
import { actionTypes } from "../../../data/mocks/actionTypes"
import { IManitu, IManituStore, useManitu, useManituStore } from "../../../data/store"
import UnderMatrixClose from "./UnderMatrixClose"

interface IProps {
    mode: MATRIX_MODE
}
const UnderMatrix = (props: IProps) : JSX.Element => { 
    const size: number = useManituStore((state: IManituStore) => state.size)
    const choosedAction: IManituActionType = useManitu((state:IManitu) => state.choosedAction)
    const [isOpenMagics, setIsOpenMagics] = useState<boolean>(false)
    
    const onOpenMagics = () => {
        setIsOpenMagics(!isOpenMagics)
    }
    const onPlus = () => {
        useManituStore.setState({ size : size + 5 })
        document.documentElement.style.setProperty("--manituCellSize", `${useManituStore.getState().size}px`);
    }
    const onMinus = () => {
        useManituStore.setState({ size : size - 5 })
        document.documentElement.style.setProperty("--manituCellSize", `${useManituStore.getState().size}px`);
    }
    
    switch(props.mode) {
        case MATRIX_MODE.MINE:
            return <>
                <div className="flex-centered flex-column">
                    <ButtonGroup> 
                        <UnderMatrixClose />
                        {
                            actionTypes()
                            .filter(type => type.distination === MANITU_ACTION_DISTINATION.SELF )
                            .map((type, i) => {
                                return <ActionBtn key={type.id} i={i} type={type} />
                            })
                        } 
                        {
                            size < 100 && <Button  
                                minimal 
                                className="ml-2 " 
                                onClick={onPlus}
                            > 
                                <i className="fas fa-search-plus px-2 py-2  " />
                            </Button>
                        } 
                        {
                            size > 15 && <Button  
                                minimal 
                                className=" " 
                                onClick={onMinus}
                            > 
                                <i className="fas fa-search-minus px-2 py-2  " />
                            </Button>
                        }  
                    </ButtonGroup>
                    <div className="descr"> { choosedAction?.instruction } </div>
                </div>
                <Dialog
                    className={DIALOG_SIZE.LARGE}
                    isOpen={isOpenMagics}
                    onClose={onOpenMagics}
                    title={__("Choose action")}
                >
                    
                </Dialog>
            </>
        case MATRIX_MODE.ENEMY:
        default:
            return <>
                <UnderMatrixClose />
                <ButtonGroup> 
                    {
                        actionTypes()
                        .filter(type => type.distination === MANITU_ACTION_DISTINATION.OPPONENT )
                        .map((type, i) => {
                            return <ActionBtn key={type.id} i={i} type={type} />
                        })
                    }
                </ButtonGroup>
                {
                    size < 100 && <Button  
                        minimal 
                        className="ml-2" 
                        onClick={onPlus}
                    > 
                        <i className="fas fa-search-plus px-2 py-2  " />
                    </Button>
                } 
                {
                    size > 15 && <Button  
                        minimal 
                        className="" 
                        onClick={onMinus}
                    > 
                        <i className="fas fa-search-minus px-2 py-2  " />
                    </Button>
                } 
                <Dialog
                    className={DIALOG_SIZE.LARGE}
                    isOpen={isOpenMagics}
                    onClose={onOpenMagics}
                    title={__("Choose action")}
                >
                    
                </Dialog>
            </>
    }
} 

export default UnderMatrix

const ActionBtn = ({type, i}:any) :JSX.Element => {
    const isActionChoose: boolean = useManitu((state:IManitu) => state.isActionChoose)
    const choosedAction: IManituActionType = useManitu((state:IManitu) => state.choosedAction)
    const userMana: IUserMana = useManituStore((state:IManituStore) => state.userMana)
    const isAffordable = ():boolean => {
        return !!type.price.filter((p: IManituEnergyPrice) => {
            return userMana[p.type].count < p.nominal
        }).length
    } 
    const disabled: boolean = isAffordable()

    const myRef = useRef( null ) 
    // const onBodyClick = (e: Event) => {
    //     const domNode: any = myRef.current
    //     console.log(domNode)
    //     if ((!domNode || !domNode.contains(e.target)) && !choosedAction) {  
    //         useManitu.setState({isActionChoose: false}) 
    //         useManitu.setState({choosedAction: type}) 
    //     }
    // }
    // useEffect(() => { 
    //     document.body.addEventListener('click', onBodyClick)
    //     return () => {
    //         document.body.removeEventListener('click', onBodyClick)
    //     }
    // }, [])

    const onChooseBrian = () => {
        useManitu.setState({choosedAction: type })
        useManitu.setState({isActionChoose: true})
    }

    return <Popover
        key={ type.id }
        hoverOpenDelay={1000}
        popoverClassName={""}
        interactionKind={ PopoverInteractionKind.HOVER }
        modifiers={{
            arrow: { enabled: false },
            flip: { enabled: true },
            preventOverflow: { enabled: false },
        }}
        position={ i % 2 === 1 ? Position.BOTTOM_LEFT : Position.TOP_LEFT }
        content={ <ActionPopover
            type={type}
            disabled={disabled}
        />}
    >
        <Button 
            disabled={disabled}
            minimal={ choosedAction?.id !== type.id } 
            className={`  ${disabled ? "opacity_5" : ""}`} 
            onClick={onChooseBrian}
            ref={ myRef }
        >
            <i className={`${type.icon} px-2 py-2 `} />
        </Button>
    </Popover>
}

interface ActionPopoverProps {
    type: IManituActionType,
    disabled: boolean
}
const ActionPopover = ({type, disabled}:ActionPopoverProps) : JSX.Element => {
    return <div className={`px-3 py-2 w_250 text-light ${disabled ? "bg-danger-super-dark" : "bg-black" }`}>
        <div>
            { type.title.toString().toUpperCase() }
        </div>
        <div className="small ">
            { type.post_content }
        </div>
        <ActionNominals type={type} disabled={disabled} />
        {
            //!disabled && <ActionSlider type={type} />
        }
    </div>
}

const ActionNominals = ({type, disabled} : any) : JSX.Element => { 
    const manaTypes: IManaProps[] = useManituStore((state: IManituStore) => state.manaTypes)
    return <div className="d-flex small">
        <div>{__("Price")}:</div>
        {
            type.price.map((p: IManituEnergyPrice, i:number ) => { 
                const mana: IManaProps = manaTypes.filter( m => m.id.toString() === p.type.toString() )[0]
                return <div 
                    key={ i }
                    className="mana m-1"  
                    style={{backgroundImage: `url(${ mana.icon })`}}
                > 
                    <div className={ `mana-count `}>
                        {p.nominal}
                    </div>
                </div>
            })
        }
    </div>
}

interface ActionSliderProps {
    type: IManituActionType
}
const ActionSlider = ({ type }: ActionSliderProps) : JSX.Element => {
    const userMana: IUserMana = useManituStore((state:IManituStore) => state.userMana)
    const countMax = () => {
        let _max = Number.MAX_SAFE_INTEGER
        type.price.forEach( (p: IManituEnergyPrice) => {
            let counts = Math.floor(userMana[p.type].count / p.nominal)
           _max = Math.min(_max, counts)
        } )
        return _max
    }
    const [ value, setValue ] = useState<number>(1)
    const [ max, setMax ] = useState<number>( countMax() )

    useEffect(() =>  {
        setMax(countMax())
    }, [userMana])
        
    const onChange = (value: number) => { 
        setValue(value)
    }
    return <div className="w-100 d-flex"> 
        {
            max > 1
                &&
                <Slider
                    value={value}
                    onChange={onChange}
                    min={1}
                    max={max}
                    stepSize={1}
                    labelStepSize={ Math.floor( max / 5  + 1 )}
                /> 
        }
        <div className="pl-3">{max}</div>
    </div>
}