
import BasicState from "src/libs/basic-view"
import "../assets/css/style.scss"  
import LudensModelForm from "src/modules/pe-ludens-module/views/ludens-model/LudensModelForm"

export default class GameScienceView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <LudensModelForm 
                engines={[
                    {
                        id: "reseachs",
                        title:"Исследования",
                        thumbnail: "/assets/img/game-science/sciences_logos-01.svg",
                        path: "reseachs",
                        component: <div className="p-md-4 display-7">Исследования</div>
                    },
                    {
                        id: "patents",
                        title:"Патенты",
                        thumbnail: "/assets/img/game-science/sciences_logos-02.svg",
                        path: "patents",
                        component: <div className="p-md-4 display-7">Патенты</div>
                    },
                    {
                        id: "economy",
                        title:"Экономика",
                        thumbnail: "/assets/img/game-science/sciences_logos-03.svg",
                        path: "economy",
                        component: <div className="p-md-4 display-7">Экономика</div>
                    },
                    {
                        id: "verifies",
                        title:"Защита патентов",
                        thumbnail: "/assets/img/game-science/sciences_logos-04.svg",
                        path: "verifies",
                        component: <div className="p-md-4 display-7">Защита патентов</div>
                    },
                ]}
                modelTitle="Лаборатория «Наука»"
                {...this.props} 
            />   
        </div>
    }
}