export const GET_OPTIONS = () => {
    return `
    query getOptions {
        getOptions {
            name
            description
            adress
            init_method
            user_verify_account
            thumbnail
            default_img
            vk
            youtube
            android
            apple
            help_url
            email
            vk_client_id
            vk_client_secret
            yandex_client_id
            yandex_client_token
            yandex_client_secret
            telegramm_client_id
            icon_media_term
            user_media_term
            test_media_term 
        }
    }
    `
}