import { IEvent } from "../interfaces"
import { eventTypes } from "./eventTypes"

export const events = () : IEvent[] => {
    const list =  [
        {
            id: 1,
            title: "Что мы натворили?",
            post_content: `Уважаемые участники и наставники!
            <p> 
            Межрегиональный просветительский марафон “Что мы соТворили?!”  - финальный этап представления работ, выполненных командами краеведов в 2023 году.
            <p>             
            Марафон проводится  в рамках Межрегионального фестиваля краеведческих объединений “Краефест-2023” и является большой просветительской краеведческой программой, позволяющим командам рассказать общественности о своих исследованиях, проектах и мечтах в современных медиа-форматах.
            <p>             
            Лучшие участники Марафона войдут в составы межрегиональных сетевых редакций и  станут авторами  краеведческих изданий:
            <p>
            <ul>
            <li>Видеожурнал “Говорю как краевед” №8/2023, 
            <li>Научно-методический журнал «Исследователь/ Researcher» 2023, 
            <li>Сайт на основе краеведческих референсов и проектных работ 
            </ul>
            <p>
            Марафон - независимая общественная инициатива краеведов и наставников.  Проходит при поддержке и методическом сопровождении:
            <p>
            <ul>
            <li>Межрегионального общественного Движения творческих педагогов «Исследователь»;
            <li>редакции научно-методического журнала "Исследователь/Researcher"; 
            <li>общественной проектно-исследовательской педагогической лаборатории «Топос. Краеведение»;
            <li>редакции межрегионального краеведческого видеожурнала «Говорю как краевед»            
            </ul>
            <p>
            Для организации программы привлекаются ресурсы участников и организаторов на добровольной основе`,
            start:  '2023-09-26T13:51:50.417Z',
            finish: '2023-12-31T13:51:50.417Z',
            coordinates: [51.660702, 39.199900],
            commands: [],
            places: [
                {
                    id: 1,
                    title: "Балашиха",
                    post_content: "",
                    coordinates: [ 55.826020, 37.959141 ],
                    type: [2],
                    placeTypes:[],
                    url: "",
                    images:[],
                    data: {}  
                },
                {
                    id: 4,
                    data: {id:4, title: "", post_content: ""},
                    title: "Махачкала",
                    post_content: "",
                    coordinates: [ 42.982470, 47.503243 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""  
                },  
                {
                    id: 8,
                    data: {id:8, title: "", post_content: ""},
                    title: "Балашихинское шоссе",
                    post_content: "", 
                    coordinates: [ 55.825853, 37.917510 ],
                    type: [4],
                    placeTypes:[],
                    images:[],
                    url: ""  
                },  
            ],
            url: "",
            logo:"https://cdn-icons-png.flaticon.com/512/249/249412.png",
            thumbnail:"https://images.unsplash.com/photo-1607575151199-afe5ad921caf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
            images: [ 
                "https://images.unsplash.com/photo-1551582045-6ec9c11d8697?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1503788167281-c3b105c7a5e3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTB8NDI5OTY3OHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60"
            ],
            email: [ ],
            phone: [ ],
            type: [1, 6, 7],
            eventTypes:[]
        },
        {
            id: 2,
            title: "Экскурсия с аудиогидом по Музею хрусталя и стекла XVIII–XXI веков",
            post_content: `Гости посетят экскурсию по экспозиции, которая располагается в интерьере бывшей старообрядческой Троицкой церкви. Это целая империя стекла, посвящение талантливым мастерам города Гуся-Хрустального, который нередко называют «русским Мурано». Туристы с интересом узнают о хрустальных королях Мальцовых, рассмотрят роскошные гравированные кубки, изделия, выполненные в технике золотого рубина, посуду из уранового стекла, фиолетовые и золотисто-коричневые вазы в стиле Галле, услышат имена художников завода, историю создания авторских произведений.
            <p>
            При получении аудиогида необходим залог в размере 1000 рублей, который возвращается после сдачи аудиогида.`,
            start:  '2023-01-26T13:51:50.417Z',
            finish: '2024-01-31T13:51:50.417Z',
            commands: [],
            places: [
                {
                    id: 1,
                    title: "Балашиха",
                    post_content: "",
                    coordinates: [ 55.826020, 37.959141 ],
                    type: [2],
                    placeTypes:[],
                    url: "",
                    images:[],
                    data: {}  
                },
                {
                    id: 4,
                    data: {id:4, title: "", post_content: ""},
                    title: "Махачкала",
                    post_content: "",
                    coordinates: [ 42.982470, 47.503243 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""  
                },  
            ],
            url: "",
            logo:"https://cdn-icons-png.flaticon.com/512/249/249401.png",
            thumbnail:"https://images.unsplash.com/photo-1513016805932-501981d32562?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1885&q=80",
            images: [ 
                "https://images.unsplash.com/photo-1532968899863-5b52ef155913",
                "https://images.unsplash.com/photo-1512699347226-42125ac596cc",
                "https://images.unsplash.com/photo-1616474655260-de945dff1b31",
                "https://images.unsplash.com/photo-1503418895522-46f9804cda40",
            ],
            phone: [ ],
            email: [ "myemail@cc.com", "myEmail@boo.com" ], 
            type: [ 4, 6, 3],
            eventTypes:[]
        },
        {
            id: 3,
            title: "Экскурсия с аудиогидом по экспозиции «Дом-музей Столетовых»",
            post_content: `Экскурсия с аудиогидом по дому, в котором жила знаменитая семья Столетовых, поможет образно окунуться в атмосферу провинциального города ушедшей эпохи. Звуковое сопровождение даст возможность представить быт и занятия купеческой семьи, узнать об их образе жизни, рассмотреть милые сердцу вещицы, которые окружали членов семейства: это фотографии на стенах, посудная горка с венской кофеваркой, кресло-качалка, настенные часы, рояль.
            <p>
            Посетители услышат о представителях этой фамилии, которые прославили Россию и стали известны за ее пределами. Старший, Николай, прославленный генерал, был создателем болгарского ополчения. Александр — профессор Московского университета, известен как ученый-физик с мировым именем, открывший явление фотоэффекта.
            <p>
            В остальных комнатах дома представлены подлинные документы, рассказывающие об интересах всей семьи, об увековечивании памяти братьев Столетовых.
            <p>
            При получении аудиогида необходим залог в размере 1000 рублей, который возвращается после сдачи аудиогида.`,
            start: '2024-01-26T13:51:50.417Z',
            finish:'2024-01-31T13:51:50.417Z',
            commands: [],
            places: [
                {
                    id: 5,
                    data: {id:5, title: "", post_content: ""},
                    title: "Эль-Хубар",
                    post_content: "",
                    coordinates: [ 26.218500, 50.197207 ],
                    type: [3],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 6,
                    data: {id:6, title: "", post_content: ""},
                    title: "Луксор",
                    post_content: "",
                    coordinates: [ 25.706398, 32.630905 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },  
                {
                    id: 9,
                    data: {id:8, title: "Салтыковка", post_content: ""},
                    title: "Салтыковка",
                    post_content: "",
                    coordinates: [ 55.759105, 37.921912 ],
                    type: [2],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },  
            ],
            url: "",
            logo:"https://cdn-icons-png.flaticon.com/512/282/282724.png",
            thumbnail:"https://images.unsplash.com/photo-1550084281-5c4f2797dcf1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            images: [ 
                "https://images.unsplash.com/photo-1695327069970-a860cd037f31?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1606577459293-2792bcc30107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1654382136729-591126f728ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw0ODAyNDN8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1554260570-9140fd3b7614?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                "https://images.unsplash.com/photo-1565347878066-1feb6f92d9c10?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=6",
            ],
            phone: [ ],
            email: [ "myemail@cc.com", "myEmail@boo.com" ], 
            type: [4, 1],
            eventTypes:[]
        },
        {
            id: 4,
            title: "Мастер-класс «Секрет стеклодела»",
            post_content: `Участник мастер-класса войдет в здание церкви, построенное в начале ХХ века в русском стиле. Здесь сегодня располагается красивая экспозиция «Музей хрусталя и стекла XVIII–XXI веков». Посетитель поднимется на хоры, где находится мастерская стеклодува из г. Гусь-Хрустальный, узнает температуру плавления стекла, его состав и не только увидит создание из расплавленного стекла украшения причудливой формы, но и сам под руководством мастера сможет выполнить сувенирную фигурку, которую заберет на память.`,
            start: '2024-01-26T13:51:50.417Z',
            finish:'2024-01-31T13:51:50.417Z',
            commands: [],
            coordinates: [51.768192, 55.096572],
            places: [
                {
                    id: 5,
                    data: {id:5, title: "", post_content: ""},
                    title: "Эль-Хубар",
                    post_content: "",
                    coordinates: [ 26.218500, 50.197207 ],
                    type: [3],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 6,
                    data: {id:6, title: "", post_content: ""},
                    title: "Луксор",
                    post_content: "",
                    coordinates: [ 25.706398, 32.630905 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },   
                {
                    id: 9,
                    data: {id:8, title: "Салтыковка", post_content: ""},
                    title: "Салтыковка",
                    post_content: "",
                    coordinates: [ 55.759105, 37.921912 ],
                    type: [2],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },  
            ],
            url: "",
            logo:"https://cdn-icons-png.flaticon.com/512/282/282724.png",
            thumbnail:"https://plus.unsplash.com/premium_photo-1661411193681-f798dc3085a3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1800&q=80",
            images: [ 
                "https://images.unsplash.com/photo-1695327069970-a860cd037f31?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1606577459293-2792bcc30107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1654382136729-591126f728ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw0ODAyNDN8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1554260570-9140fd3b7614?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                "https://images.unsplash.com/photo-1565347878066-1feb6f92d9c1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            ],
            phone: [ ],
            email: [ "myemail@cc.com", "myEmail@boo.com" ], 
            type: [5, 6, 3],
            eventTypes:[]
        },
        {
            id: 41,
            title: "Зимовка",
            post_content: `Научно-популярный фильм «Зимовка» посвящен зимовке российских полярников на станции Беллинсгаузен в Антарктиде. 15 человек разного возраста, профессий и социальных слоев на целый год оказались в полной изоляции от Большой земли и поселились в нескольких щитовых домиках на зимовке. Кроме сурового антарктического климата им пришлось столкнуться с другими проблемами: с необходимостью постоянно находиться рядом и общаться, мало зная друг о друге, с жизнью вдалеке от родных и друзей. Режиссер фильма Ольга Стефанова вспоминала: «Когда я туда ехала, я ехала искать настоящих героев-полярников, которые преодолевают трудности, которые каждый день совершают маленький подвиг, а увидела, что в действительности это совершенно обычные люди в несколько необычных условиях».
            <p>
            Сама Ольга прожила весь год на зимовке вместе со своими героями и стала пятой российской женщиной за всю историю, которая зимовала в Антарктиде. Кроме режиссерской работы, Ольга Стефанова сама написала сценарий ленты и выступила в роли оператора.`,
            start: '2024-01-26T13:51:50.417Z',
            finish:'2024-01-31T13:51:50.417Z',
            commands: [],
            coordinates: [57.152771, 65.541112],
            places: [
                {
                    id: 5,
                    data: {id:5, title: "", post_content: ""},
                    title: "Эль-Хубар",
                    post_content: "",
                    coordinates: [ 26.218500, 50.197207 ],
                    type: [3],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 6,
                    data: {id:6, title: "", post_content: ""},
                    title: "Луксор",
                    post_content: "",
                    coordinates: [ 25.706398, 32.630905 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                }, 
                {
                    id: 4,
                    data: {id:4, title: "", post_content: ""},
                    title: "Махачкала",
                    post_content: "",
                    coordinates: [ 42.982470, 47.503243 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },  
            ],
            url: "",
            logo:"https://cdn-icons-png.flaticon.com/512/282/282724.png",
            thumbnail:"https://images.unsplash.com/photo-1613329969375-5faa6f501d69?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
            images: [ 
                "https://images.unsplash.com/photo-1695327069970-a860cd037f31?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1606577459293-2792bcc30107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1654382136729-591126f728ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw0ODAyNDN8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1554260570-9140fd3b7614?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                "https://images.unsplash.com/photo-1565347878066-1feb6f92d9c1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            ],
            phone: [ ],
            email: [ "myemail@cc.com", "myEmail@boo.com" ], 
            type: [1,2,5,3],
            eventTypes:[]
        },
        {
            id: 5,
            title: "Экспозиция Дмитриевского собора",
            post_content: `Дмитриевский собор — уникальный храм северо-востока Древней Руси, включенный в список Всемирного наследия ЮНЕСКО. Собор построен по воле великого владимирского князя Всеволода Большое Гнездо в конце XII века как дворцовый храм князя, хранящий реликвии святого Дмитрия Солунского, покровителя императорского двора, знати и княжеских фамилий.
            <p>
            Выполненный местными мастерами, он поражает своими архитектурными формами, монументальностью, торжественностью, богатым убранством. Фасады храма украшают сотни резных камней на библейские и мифологические сюжеты. На стенах — изображения библейского царя Давида, сидящего на троне, Александра Македонского, вознесшегося к небесам, Геракла, совершающего свои подвиги, скачущих всадников и святых, а также самых разных зверей и птиц. В сюжетах резьбы звучит идея прославления владимирской земли и княжеской власти.
            <p>
            В соборе сохранились фрески XII века византийских мастеров со сценами Страшного суда.`,
            start: '2023-12-13T13:51:50.417Z',
            finish:'2024-12-14T13:51:50.417Z',
            commands: [],
            places: [
                {
                    id: 3,
                    data: {id:5, title: "", post_content: ""},
                    title: "Нижнекамск",
                    post_content: "",
                    coordinates: [ 55.643538, 51.825105 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 5,
                    data: {id:5, title: "", post_content: ""},
                    title: "Эль-Хубар",
                    post_content: "",
                    coordinates: [ 26.218500, 50.197207 ],
                    type: [3],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 6,
                    data: {id:6, title: "", post_content: ""},
                    title: "Луксор",
                    post_content: "",
                    coordinates: [ 25.706398, 32.630905 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },  
                {
                    id: 4,
                    data: {id:4, title: "", post_content: ""},
                    title: "Махачкала",
                    post_content: "",
                    coordinates: [ 42.982470, 47.503243 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""  
                },  
            ],
            url: "",
            logo:"https://cdn-icons-png.flaticon.com/512/12331/12331329.png",
            thumbnail:"https://images.unsplash.com/photo-1635972904366-3fb9ac369ca0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
            images: [ 
                "https://images.unsplash.com/photo-1575412465945-3745ec0211fd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTJ8MzE3OTE5N3x8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1608780523936-c9c1fa752684?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTV8MzE3OTE5N3x8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1575996301730-435a292f104c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTh8MzE3OTE5N3x8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1583513702439-2e611c58e93d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8Mjd8MzE3OTE5N3x8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1575459780154-bc56e94c5076?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8Mjl8MzE3OTE5N3x8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
            ],
            phone: [ ],
            email: [ "myemail@cc.com", "myEmail@boo.com" ], 
            type: [3, 7, 6],
            eventTypes:[]
        },
        {
            id: 6,
            title: "Выставка «Старый Владимир»",
            post_content: `Музей расположен в бывшей водонапорной башне начала XX века. Это первый опыт в России, когда промышленное здание стало музеем. В башне воссоздана атмосфера губернского города конца XIX — начала XX века.
            <p>
            Посетители увидят, как выглядел Владимир на рубеже веков, узнают, чем занимались и чем жили владимирцы той эпохи: какие книги читали, какие культурные мероприятия посещали. В музее нет привычных туристам «объяснительных текстов» — только подлинные вещи и «ожившие картины» городской жизни: главная улица, дворянский дом, трактир, кабинет начальника тюрьмы и другие, — а также «комментарии» того времени — выдержки из газет, книг и журналов. Люди, жившие тогда, сами рассказывают о своем городе — с любовью, с юмором, а иногда с грустью и иронией. Наверху, в башне, устроена смотровая площадка, откуда открывается живописный вид на Владимир и окрестности.`,
            start: '2023-12-01T13:51:50.417Z',
            finish:'2024-12-31T13:51:50.417Z',
            coordinates: [59.220752, 39.891545],
            commands: [],
            places: [
                {
                    id: 3,
                    data: {id:5, title: "", post_content: ""},
                    title: "Нижнекамск",
                    post_content: "",
                    coordinates: [ 55.643538, 51.825105 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 5,
                    data: {id:5, title: "", post_content: ""},
                    title: "Эль-Хубар",
                    post_content: "",
                    coordinates: [ 26.218500, 50.197207 ],
                    type: [3],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 6,
                    data: {id:6, title: "", post_content: ""},
                    title: "Луксор",
                    post_content: "",
                    coordinates: [ 25.706398, 32.630905 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },  
                {
                    id: 4,
                    data: {id:4, title: "", post_content: ""},
                    title: "Махачкала",
                    post_content: "",
                    coordinates: [ 42.982470, 47.503243 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""  
                },  
            ],
            url: "",
            logo:"https://cdn-icons-png.flaticon.com/512/12331/12331329.png",
            thumbnail:"https://plus.unsplash.com/premium_photo-1661909918862-403588a10c0b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            images: [ 
                "https://images.unsplash.com/photo-1695418625041-c2fff3503080?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                "https://images.unsplash.com/photo-1695418624961-8a6531597fe7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                "https://images.unsplash.com/photo-1584183370970-fda237275863?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
                "https://images.unsplash.com/photo-1584348171496-aac2ac3c8602?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1931&q=80",
                "https://images.unsplash.com/photo-1695327069971-e029c5db5bab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
            ],
            phone: [ ],
            email: [ "myemail@cc.com", "myEmail@boo.com" ], 
            type: [3, 4, 1],
            eventTypes:[]
        },
        {
            id: 7,
            title: "Оханский слон",
            post_content: `Документальный научно-популярный фильм «Оханский слон» сняла Мила Кудряшова в 2018 году. Картина стала первой частью ее авторского проекта «Мой личный дракон», который был посвящен поиску динозавров в Якутии.
            <p>
            200 тысяч лет назад по берегам реки Камы бродили трогонтериевые слоны, или степные мамонты. В наши дни бивень этого гигантского животного в Оханском районе нашли пермские рыбаки. Из фильма зрители узнают, как трогонтериевый слон стал героем мюзикла «Суперкости», как в его честь появились выставки, культурные проекты, фильмы и даже гимн.
            <p>
            Премьера картины прошла в Польше, на конференции молодых палеонтологов. В России ленту впервые показали на кинофестивале «Флаэртиана».`,
            start: '2023-12-01T13:51:50.417Z',
            finish:'2024-12-31T13:51:50.417Z',
            coordinates: [59.938885, 30.313921],
            commands: [],
            places: [
                {
                    id: 3,
                    data: {id:5, title: "", post_content: ""},
                    title: "Нижнекамск",
                    post_content: "",
                    coordinates: [ 55.643538, 51.825105 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 5,
                    data: {id:5, title: "", post_content: ""},
                    title: "Эль-Хубар",
                    post_content: "",
                    coordinates: [ 26.218500, 50.197207 ],
                    type: [3],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },
                {
                    id: 6,
                    data: {id:6, title: "", post_content: ""},
                    title: "Луксор",
                    post_content: "",
                    coordinates: [ 25.706398, 32.630905 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },  
                {
                    id: 4,
                    data: {id:4, title: "", post_content: ""},
                    title: "Махачкала",
                    post_content: "",
                    coordinates: [ 42.982470, 47.503243 ],
                    type: [1],
                    placeTypes:[],
                    images:[],
                    url: ""  
                },   
                {
                    id: 9,
                    data: {id:8, title: "Салтыковка", post_content: ""},
                    title: "Салтыковка",
                    post_content: "",
                    coordinates: [ 55.759105, 37.921912 ],
                    type: [2],
                    placeTypes:[],
                    images:[],
                    url: ""   
                },  
            ],
            url: "",
            logo:"https://cdn-icons-png.flaticon.com/512/12331/12331329.png",
            thumbnail:"https://images.unsplash.com/photo-1551650254-f66f1b5d87c8",
            images: [ 
                "https://images.unsplash.com/photo-1506978520653-bb3accebb1a3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE5fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1551582045-6ec9c11d8697?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1479722842840-c0a823bd0cd6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1513183881046-a5fa074c681d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1515021593269-80bab6254eae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE3fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
            ],
            phone: [ ],
            email: [ "myemail@cc.com", "myEmail@boo.com" ], 
            type: [3, 7],
            eventTypes:[]
        },
    ] 
    return list.map(place => {
        const eventType = eventTypes().filter(pt => place.type.includes( Number(pt.id) ))
        return {...place, eventTypes: eventType}
    })
}