import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface IBuildBtnProps {
    onStartBuild: () => void
}
const BuildBtn = (props: IBuildBtnProps): JSX.Element => {
    return <div className='pe-slot-btn' onClick={props.onStartBuild}>
        <svg
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
            viewBox="0 0 313.4 298.1"
        >
            <path d="M160.9,205.5c4.9,1,9.2,2.1,13.6,2.6c1.5,0.2,3.4-1.1,4.8-2.1c16.3-12.1,32.6-24.2,48.9-36.4c15.7-11.7,31.2-7.1,38,11.2
                c0.2,0.4,0.4,0.9,0.4,0.8c4.5,0.8,8.7,0.9,12.5,2.3c15.5,5.7,19.4,25.8,6.9,37.2c-11.8,10.7-24.3,20.8-36.5,31.1
                c-0.5,0.4-1.1,0.8-1.6,1.3c-8.5,9.6-19.5,12.1-31.8,12.8c-30.1,1.9-60.1,4.5-90.1,6.8c-9.7,0.7-18.8-1.1-27.2-6.4
                c-1.8-1.2-4.3-1.7-6.5-1.7c-14.5-0.1-29-0.1-43.5-0.1c-9.5,0-11.5-2-11.5-11.3c0-12.3,0-24.7,0-37c0-7.1,2.5-9.5,9.6-9.5
                c15,0,30,0.1,45-0.2c2.5,0,5.5-1.3,7.2-3.1c11.1-11.1,21.7-22.6,32.8-33.6c12.6-12.5,33.2-7.2,37.7,9.7c1.9,7.1,0.7,13.9-4,19.8
                C164.4,201.5,162.9,203.2,160.9,205.5z M142.8,235.8c-0.1-12.5-10.3-22.6-22.7-22.4c-12.4,0.2-22.4,10.5-22.1,22.9
                c0.2,12.6,10.2,22.2,22.9,22C133.1,258.1,142.9,248,142.8,235.8z M201.5,228.8c-14.8-2.9-30.6-5.9-47.1-9.1
                c5.1,12.8,4.3,24.4-2.1,36.2c17.1-1.3,33.6-2.6,49.2-3.8C201.5,244.2,201.5,237.1,201.5,228.8z M84,222.5c-9.9,0-20.6,0-31.5,0
                c0,9.2,0,17.9,0,27c10.9,0,21.6,0,31.5,0C84,240.5,84,231.7,84,222.5z M196,212.4c7.3,1.5,13.3,2.7,19.3,3.8c1,0.2,2.4-0.2,3.2-0.8
                c10.2-8,20.5-15.9,30.5-24.1c3.3-2.7,3.4-6.4,1-9.9c-2.3-3.4-6.7-4.2-10.2-1.7C225.6,190.3,211.4,201,196,212.4z M249,233
                c8.9-7.5,17.7-14.8,26.3-22.3c3.7-3.2,4.1-7.3,1.4-10.6c-2.6-3.2-6.6-3.9-10.2-1.3c-9.4,6.8-18.7,13.8-28,20.7
                c2,2.1,4.1,3.8,5.7,5.9C245.9,227.6,247.2,230.1,249,233z M125.5,198.7c5.8,1.1,10.5,2.1,15.2,2.8c1.2,0.2,3-0.3,3.9-1.1
                c3.2-3,6.2-6.1,9-9.3c2.9-3.3,2.7-7.5,0-10.3c-3.1-3.1-7.3-3.3-10.7,0.1C137.1,186.3,131.7,192.1,125.5,198.7z M225,231.2
                c-5.3,0-9.5,4.1-9.6,9.4c-0.1,5.5,4.4,9.8,9.8,9.7c5.3-0.1,9.3-4.3,9.3-9.7C234.5,235.2,230.5,231.2,225,231.2z"/>
            <path d="M207.2,138.4c-0.4,0.5-0.6,0.7-0.7,1c-2.6,13.9-4.1,13.7-17.7,13.6c-4.5,0-9,0-13.5,0c-7.3-0.1-9.3-1.7-10.9-8.9
                c-0.4-1.8-0.9-3.5-1.4-5.9c-2.6,1.5-5,2.9-7.3,4.4c-3.8,2.4-7.3,1.9-10.4-1.2c-5.6-5.5-11.1-11-16.6-16.6
                c-3.1-3.2-3.3-6.7-1.1-10.5c1.3-2.2,2.7-4.4,4.4-7.1c-2.9-0.7-5.1-1.3-7.3-1.9c-5.7-1.4-7.3-3.4-7.4-9.4c0-7,0-14,0-21
                c0-6.7,1.4-8.3,7.9-10c2.1-0.5,4.1-1.1,6.4-1.7c-0.5-1.1-0.9-1.8-1.3-2.5C124.2,50,124.2,50,133,41.2c3.7-3.7,7.3-7.3,11-10.9
                c5.1-5,7.3-5.3,13.5-1.5c1.7,1,3.4,2,5,3c0.4-0.5,0.8-0.7,0.8-1c3-14.8,4.8-13.6,17.7-13.5c4.7,0,9.3,0,14,0
                c6.5,0,8.8,1.9,10.3,8.4c0.4,1.9,0.9,3.9,1.5,6.3c2.6-1.5,5-2.9,7.4-4.3c3.8-2.3,7.3-1.8,10.4,1.3c5.4,5.4,10.8,10.8,16.3,16.2
                c3.5,3.5,3.7,7.2,1.1,11.3c-1.2,2-2.4,3.9-4,6.5c2.2,0.6,4,1.1,5.8,1.6c7.4,1.8,8.7,3.4,8.7,10.9c0,6.7,0,13.3,0,20
                c0,6.7-1.4,8.3-8,9.9c-1.9,0.5-3.8,1-6.5,1.7c1.1,2,2.1,3.6,3,5.2c3.9,6.3,3.7,8.9-1.5,14c-4.5,4.5-8.9,9-13.4,13.4
                c-5.4,5.4-7.4,5.6-13.9,1.6C210.6,140.4,208.9,139.4,207.2,138.4z M152.7,43.8c-3.3,3.1-6.3,5.9-9.3,8.7
                c10.9,14.8,7.7,23.2-10.8,26.4c-0.1,0.6-0.3,1.2-0.3,1.9c-0.5,10.3-0.5,10.3,9.2,13.1c7.2,2.1,10.1,9.8,6.3,16.3
                c-1.5,2.5-3.1,5-4.7,7.6c1.1,1.1,1.8,2,2.6,2.8c6.3,6.4,6.3,6.5,14,1.7c3.5-2.2,6.6-2.2,10.3-0.8c5.8,2.2,6.1,7.2,7.4,12
                c0.4,1.6,1.3,3.8,2.5,4.2c7,2.4,11.5-0.2,13.2-7.2c0.1-0.5,0.1-1,0.2-1.5c1.8-7.6,9.6-10.9,16.3-7c8.2,4.8,8.2,4.8,14.8-2
                c0.7-0.7,1.3-1.5,2.2-2.5c-1.6-2.6-3.2-5.1-4.7-7.6c-3.8-6.5-0.8-14.2,6.4-16.2c9.3-2.6,9.3-2.6,9.1-12.2c0-1-0.2-1.9-0.3-2.8
                c-5.5-2.8-12.8-1-16-8.6c-3.2-7.7,3-11.9,5-17.2c-3.2-3.2-6.1-6.2-9.1-9.3c-5.6,2-9.5,8.4-17.5,5c-7.8-3.4-5.5-11.1-8.8-16.3
                c-4,0-8,0-12,0c-3.1,5.4-1,13-8.8,16.3C162.2,52.2,158.2,45.7,152.7,43.8z"/>
            <path d="M120.8,228.3c4.1,0.2,7.3,4,7,8.2c-0.3,4.1-3.6,7-7.7,6.8c-4.2-0.2-7.3-3.8-7-8C113.4,231.4,117,228.1,120.8,228.3z"/>
            <path d="M184.8,60.2c13.9,0,25.1,11,25.2,24.9c0.1,13.6-11.3,25.1-25,25.2c-13.7,0-25.1-11.3-25.2-25
                C159.7,71.5,171,60.2,184.8,60.2z M185,75.2c-5.6-0.1-10.2,4.4-10.2,9.9c0,5.5,4.7,10.2,10.2,10.1c5.3-0.1,9.8-4.5,9.9-9.8
                C195.1,79.9,190.6,75.3,185,75.2z"/>
        </svg>
        {__("Build")}
    </div>
}
export default BuildBtn