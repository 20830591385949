import { Button, Popover, Position } from "@blueprintjs/core"
import { ILand } from "../../data/interfaces"
import { useEffect, useRef, useState } from "react"
import UserSearchEngine from "@/libs/useful/UserSearchEngine"
import { IUser } from "@/settings/zustand"
import { __, sprintf } from "@/libs/utilities"
import { ScalableDialog } from "@/libs/useful"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { GET_FEED_ACTION } from "../../data/actionTypes"

/* Protopia Ecosystem component */
export interface IFestivalSiteOwnerFormProps {
    item: ILand
    className?: string
}
const FestivalSiteOwnerForm = (props: IFestivalSiteOwnerFormProps): JSX.Element => {
    const [width, setWidth] = useState<number>(200) 
    const [nextOwner, setNextOwner] = useState<IUser | null>(null)
    const ref = useRef<HTMLDivElement>( null )
    useEffect(() => { 
            setWidth(ref.current ? ref.current.offsetWidth : 200 ) 
       
    }, [])
    const onSelectUser = (user:IUser) => {
        setNextOwner(user) 
    }
    const onSwitchOwner = () => {
        setNextOwner(null)
        actions(
            UPDATE_SINGLE_TYPE,
            {
                data_type: "Land",
                id: props.item.id,
                item: {
                    demiurg_id: nextOwner?.id || "-1"
                }
            }
        )
            .then(() => {
                actions(GET_FEED_ACTION, { data_type: "Land" })
            })
    }
    return <>
        <Popover
            interactionKind="click"
            position={ Position.BOTTOM }
            modifiers={{
                arrow: { enabled: false },
                flip: { enabled: true },
                preventOverflow: { enabled: false },
            }}
            className={`w-100 ${props.className}`}
            content={<div className="p-1 " style={{width}}>
                <div className="lead p-1">{__("Transfer control to another user")}</div>
                <UserSearchEngine   
                    onSelect={onSelectUser}
                />
            </div>}
        >
            <div className='p-2 d-flex justify-content-between border border-secondary m-1 pointer hover-straght' ref={ref}>   
                { props.item?.owner_user?.display_name }
                <div className="fas fa-caret-down" />
            </div>
        </Popover>
        <ScalableDialog
            dialogSize={DIALOG_SIZE.MINIMAL}
            isOpen={ !!nextOwner }
            onClose={ () => setNextOwner(null) }
        >
            <div className="p-5">
            {
                sprintf(
                    __("Are you realy want change owner of «%s» to %s"),
                    props.item.name,
                    nextOwner?.display_name || ""
                )
            }
            </div>
            <div className="d-flex">
                <Button intent="danger" fill large onClick={() => setNextOwner(null)}>
                    {__("No")}
                </Button>
                <Button intent="success" fill large onClick={ onSwitchOwner }>
                    {__("Yes")}
                </Button>
            </div>
        </ScalableDialog>
    </>
}
export default FestivalSiteOwnerForm