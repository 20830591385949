import { Button, ButtonGroup } from "@blueprintjs/core"
import { IPETestCategory } from "src/modules/pe-tests-module/data/interfaces"
import { ITestsStore, useTestsStore } from "src/modules/pe-tests-module/data/store"

const TestCatFilter = () : JSX.Element => {
    const testCategories : IPETestCategory[] = useTestsStore( (state: ITestsStore) => state.testCategories )
    const currentTestCategories : IPETestCategory[] = useTestsStore( (state: ITestsStore) => state.currentTestCategories )
    
    const onFilter = (item: IPETestCategory) => {
        useTestsStore.setState({
            currentTestCategories: !!currentTestCategories.filter(ctc =>ctc.id === item.id).length 
                ?
                currentTestCategories.filter(ctc =>ctc.id !== item.id) 
                :
                [ ...currentTestCategories, item ]
        })
    }
    return <div className="d-flex w-100 my-3">
        <ButtonGroup className="flex-wrap ">
        {
            testCategories.map( tc => {
                return <Button 
                    minimal
                    key={tc.id} 
                    onClick={() => onFilter( tc ) }
                    active={ !!currentTestCategories.filter(t => t.id === tc.id ).length || !currentTestCategories.length}
                >
                    <div className="pe-manitu-test-filter-btn">
                        { tc.title }    
                    </div>
                </Button>
            })
        }    
        </ButtonGroup>
        
    </div>
} 

export default TestCatFilter