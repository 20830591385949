import { __ } from "src/libs/utilities" 
import ToposSingleNews from "./ToposSingleNews"
import { useEffect, useState } from "react"
import { Loading } from "src/libs/useful"
import { actions } from "../../data/actions"
import { GET_NEWS_FEED_ACTION } from "../../data/actionTypes"
import { INews } from "../../data/interfaces"
import { IToposPostsStore, useToposPostsStore } from "../../data/store"

const ToposNews = () : JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const news : INews[] = useToposPostsStore( (state:IToposPostsStore) => state.news )
  useEffect(() => {
    actions(GET_NEWS_FEED_ACTION, {}).then( () => setIsLoading( false ) )
  }, [])
  if(isLoading) return <div className="layout-state"><Loading /></div> 
  return <div className="w-100 bg-secondary-light p-4">
    <div className="container">
      <div className="display-6 h">{__("News")}</div>
      <div className="row">
        {
          news
            .filter( (n, i) => i < 7 )
            .map( n => <div className="col-xxl-6 col-xl-12 col-lg-6 col-md-12 my-3" key={n.id}>
              <ToposSingleNews item={n} />
            </div> )
        } 
      </div>
    </div>
    
  </div>
   
}

ToposNews.propTypes = {}

export default ToposNews