import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { __ } from "src/libs/utilities"
import { IErmakMap } from "../../data/interfaces"
import { IErmakStore, useErmakStore } from "../../data/store"

const ErmakMapEditorForm = (props:any) :JSX.Element => {
    const currentMapEdit: IErmakMap = useErmakStore((state: IErmakStore) => state.currentMapEdit)
    return <div className="container">
        <div>
            <FieldInput
                type={SCALAR_TYPES.EXTERNAL}
                value={currentMapEdit}
                editable
                title={__("Select Map to edit")}
            />
        </div>
    </div>
} 

export default ErmakMapEditorForm