import { FABULA_MINI_GAME, IFabulaMediaType, IPEFabulaMapTile, SPEECH_FINISH_TYPE, SPEECH_POSITION } from "../../interfaces"
import { KriogenmashLibrary } from "./KriogenmashLibrary"

export const Kriogenmash = () : IPEFabulaMapTile => {
    return {
        id: "6",
        title: "Криогенмаш",
        post_content: "",
        enabled: true,
        x: 2,
        y: 1,
        map_id: "2",
        thumbnail: "/assets/img/fabula/Bear/bear6.png",
        episodes: [
            {
                id: "61",
                title: "Музей «Криогенмаш»",
                post_content: "",
                x: 60,
                y: 60,
                tile_id: "6",
                media: [ 
                    {
                        id: "61",
                        title: "Музей «Криогенмаш»",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0, 
                        thumbnail: "/assets/img/fabula/Bear/bear611.jpg",
                        speech:[ 
                            "61-1", "61-2", "61-3", "61-4", "61-5", "61-6", "61-7",
                        ],
                        music: "/assets/data/fabula/Bear/music/kriogenmash-1.mp3",
                        media: [                                  
                            {
                                id: "611",
                                title: "Экспозиция музея истории завода",
                                post_content: "",
                                type: IFabulaMediaType.MULTI_SCREEN,
                                x: 16,
                                y: 50,
                                parameters: {
                                    screen: {
                                        top: 200,
                                        left: "32%",
                                        width: "46%",
                                        height: "calc(100% - 220px)"
                                    },
                                    items: [
                                        { 
                                            id: "1",
                                            type: IFabulaMediaType.HTML,
                                            title: "Создание",
                                            thumbnail: "/assets/data/fabula/Bear/kriogenmash/Kapitsa_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 0, 
                                                left: "32%", 
                                                width: 150, 
                                                height: 150
                                            },
                                            content : "<p>Решающую роль в развитии криогенного машиностроения и создании нашего предприятия сыграл выдающийся физик ХХ столетия, основатель и организатор отечественной криогеники и отрасли криогенной техники, лауреат Нобелевской премии, академик Петр Леонидович Капица, заложивший основы криогенной техники страны. Будучи первым начальником Главкислорода, П.Л. Капица принимал деятельное участие в реализации постановления СНК СССР, принятого в мае 1945 года, в соответствии с которым были созданы научный центр ВНИИКИМАШ, проектный институт Гипрокислород и начато строительство Балашихинского машиностроительного завода (БМЗ) - первенцев криогенной отрасли страны.</p>",
                                        },
                                        { 
                                            id: "2",
                                            type: IFabulaMediaType.HTML,
                                            title: "1940-60-е годы",
                                            thumbnail: "/assets/data/fabula/Bear/kriogenmash/1960_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 0, 
                                                left: "calc(32% + 160px)", 
                                                width: 150, 
                                                height: 150
                                            },
                                            content : "<p>Постановление правительства о создании проектного института «Гипрокислород», научно-исследовательского института кислородного машиностроения (ВНИИКИМАШ) и строительстве специализированного машиностроительного завода в Балашихе</p><p class='display-6'>1949-1950</p><p>Разработка и освоение производства жидкостных воздухоразделительных установок производительностью 1600 кг жидкого кислорода в час.</p><p class='display-6'>1950-1953</p><p>Разработка и освоение серийного производства первой и крупнейшей в Европе воздухоразделительной установки низкого давления производительностью 12 500 кубометров кислорода в час для металлургической промышленности.</p><p class='display-6'>1959-1961</p><p>Создание криогенных заправочных систем для стартового ракетно-космического комплекса по запуску пилотируемых космических кораблей в Байконуре.</p>",
                                        },
                                        { 
                                            id: "3",
                                            type: IFabulaMediaType.HTML,
                                            title: "1970-1980-е годы",
                                            thumbnail: "/assets/data/fabula/Bear/kriogenmash/1980_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 0, 
                                                left: "calc(32% + 320px)", 
                                                width: 150, 
                                                height: 150
                                            },
                                            content : "<p>С 1947 года в Алексеевской роще построили государственную дачу для Николая Булганина, на фото она выглядит потрясающе!</p><p> В 60-х годах дачей пользовался Юрий Гагарин, куда приезжал из Звездного городка</p><p> В состав национального парка Лосиный остров Алексеевская роща перешла в 1990 году, а в 1999 году открыт экоцентр «Старинная русская охота» и небольшой музей при ней. Но сейчас, как мы видим музей закрыт‚статуй больше нет на территории, а от изразцов печи остался всего один....и вопрос тоже один - а его вообще приведут в порядок? Или это уже заброшка?</p>",
                                        },
                                        { 
                                            id: "4",
                                            type: IFabulaMediaType.HTML,
                                            title: "1990-2020-е годы",
                                            thumbnail: "/assets/data/fabula/Bear/kriogenmash/1990_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 0, 
                                                left: "calc(32% + 480px)", 
                                                width: 150, 
                                                height: 150
                                            },
                                            content : "<p>Николай Александрович Булганин (30 мая (11 июня) 1895 — 24 февраля 1975) — советский государственный деятель.</p><p>Член Президиума (Политбюро) ЦК КПСС (1948–1958), член ЦК партии (1937–1961).</p><p>Маршал Советского Союза (1947, лишён этого звания в 1958 году), генерал-полковник.</p><p>Входил в ближайшее окружение И. В. Сталина.</p>",
                                        },
                                        { 
                                            id: "5",
                                            type: IFabulaMediaType.HTML,
                                            title: "2030-2040-е годы",
                                            thumbnail: "/assets/data/fabula/Bear/kriogenmash/2010_thumbn.jpg",
                                            thumbnailBounds: {
                                                top: 0, 
                                                left: "calc(32% + 640px)", 
                                                width: 150, 
                                                height: 150
                                            },
                                            content : "<p>Николай Александрович Булганин (30 мая (11 июня) 1895 — 24 февраля 1975) — советский государственный деятель.</p><p>Член Президиума (Политбюро) ЦК КПСС (1948–1958), член ЦК партии (1937–1961).</p><p>Маршал Советского Союза (1947, лишён этого звания в 1958 году), генерал-полковник.</p><p>Входил в ближайшее окружение И. В. Сталина.</p>",
                                        },
                                    ]
                                },
                                thumbnail: "/assets/img/fabula/Bear/bear611.jpg",
                                speech:[],
                                music: "/assets/data/fabula/Bear/music/kriogenmash-1.mp3",
                                media:[ ],                                
                            },
                            {
                                id: "612",
                                title: " Вход на завод",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 50,
                                y: 50, 
                                //defense: 100,
                                protection: {
                                    id: "6121",
                                    title: "Электронный замок",
                                    post_content: "Попробуйте взломать. Необходимо с двух попыток выбросить больше, чем противник.",
                                    type: FABULA_MINI_GAME.Dice,
                                    parameters : {
                                        dictionary: {
                                            Defense: "Замок",
                                            Hacker: "Хакер",
                                        },
                                        defense: [ 6, 6, 6, 6, ],
                                        attack: [ 6, 6, 6, ],
                                        times: 2,
                                        keys: [ "1", "2" ]
                                    }
                                },
                                thumbnail: "/assets/img/fabula/Bear/bear611.jpg",
                                speech:[],
                                music: "/assets/data/fabula/Bear/music/kriogenmash-1.mp3",
                                media: [                                  
                                    {
                                        id: "61211",
                                        title: "Центральный коммутатор-проходная",
                                        post_content: "",
                                        type: IFabulaMediaType.IMAGE,
                                        x: 16,
                                        y: 50,
                                        thumbnail: "/assets/img/fabula/Bear/bear61211.jpg",
                                        speech:[],
                                        music: "/assets/data/fabula/Bear/music/kriogenmash-1.mp3",
                                        media:[ 
                                           {
                                                id: "612111",
                                                title: "Вход для людей",
                                                post_content: "",
                                                type: IFabulaMediaType.IMAGE,
                                                x: 69,
                                                y: 71,
                                                thumbnail: "/assets/img/fabula/Bear/bear612111.jpg",
                                                speech:[],
                                                staff: [],
                                                music: "/assets/data/fabula/Bear/music/kriogenmash-1.mp3",
                                                media:[                                                     
                                                    {
                                                        id: "612111-terminal",
                                                        title: "Производственный терминал БОИ",
                                                        post_content: "Большой Общественный Информаторий коммуницирует логистику грузов.",
                                                        type: IFabulaMediaType.IMAGE,
                                                        x: 52,
                                                        y: 61,
                                                        thumbnail: "/assets/img/fabula/Bear/612111-terminal.jpg", 
                                                        speech:[],
                                                        staff: [ "6", "7" ],
                                                        music: "/assets/data/fabula/Bear/music/kriogenmash-1.mp3",
                                                    },
                                                 ], 
                                           },
                                           {
                                                id: "612112", 
                                                title: "Вакуумный кингстон с производственную зону",
                                                post_content: "Для людей, животных и киборгов вход -- только в скафандрах!",
                                                type: IFabulaMediaType.IMAGE,
                                                x: 51,
                                                y: 71,
                                                thumbnail: "/assets/img/fabula/Bear/bear612112.jpg",
                                                speech:[],
                                                music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                                media:[ 
                                                    {
                                                        id: "6121121",
                                                        title:"Анклавный ангар",
                                                        post_content: "Производство био-органической массы",
                                                        type: IFabulaMediaType.IMAGE,
                                                        x: 50,
                                                        y: 57,
                                                        thumbnail: "/assets/img/fabula/Bear/bear6121121.jpg",
                                                        speech:[],
                                                        music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                                        
                                                        media:[ 
                                                            {
                                                                id: "61211211",
                                                                title:"Трарзитный коммутатор",
                                                                post_content: "Распределение производственных грузов",
                                                                type: IFabulaMediaType.IMAGE,
                                                                x: 43,
                                                                y: 55,
                                                                thumbnail: "/assets/img/fabula/Bear/bear61211211.jpg",
                                                                speech:[],
                                                                music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                                                media:[ 
                                                                    {
                                                                        id: "612112111",
                                                                        title:"Модуль контроля внешней доставки",
                                                                        post_content: "Устаревший техномодуль. Заброшенная локация",
                                                                        type: IFabulaMediaType.IMAGE,
                                                                        x: 85,
                                                                        y: 59,
                                                                        thumbnail: "/assets/img/fabula/Bear/bear612112111.jpg",
                                                                        speech:[],
                                                                        music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                                                        media:[ 
                                                                            {
                                                                                id: "612112111-1",
                                                                                title: "На Качалинские сторожки",
                                                                                post_content:"",
                                                                                type: IFabulaMediaType.IMAGE,
                                                                                x: 48,
                                                                                y: 66,
                                                                                speech:[],
                                                                                media:[],
                                                                                to: "511"
                                                                            }
                                                                        ], 
                                                                   },    
                                                                ], 
                                                            },  
                                                            {
                                                                id: "61211212",
                                                                title:"Центральный 3-D принтер",
                                                                post_content: "Сердце завода",
                                                                type: IFabulaMediaType.IMAGE,
                                                                x: 41,
                                                                y: 26,
                                                                thumbnail: "/assets/img/fabula/Bear/bear61211212.jpg",
                                                                speech:[],
                                                                media:[ ],
                                                                music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                                                
                                                            },  
                                                            {
                                                                id: "61211213",
                                                                title:"Автоклав-мафусаил",
                                                                post_content: "Легендарный и крупнейший в мире фрингультинатор Исаевой",
                                                                type: IFabulaMediaType.IMAGE,
                                                                x: 3,
                                                                y: 54,
                                                                thumbnail: "/assets/img/fabula/Bear/bear61211213.jpg",
                                                                speech:[],
                                                                media:[ KriogenmashLibrary(), ],
                                                                music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                                                
                                                            },
                                                        ], 
                                                   }, 
                                                ], 
                                           },  
                                        ],                                
                                    },
                                ]
                            },                               
                            {
                                id: "613",
                                title: "Экскурсия по территории завода",
                                post_content: "Для кислородо-дышащих посетителей.",
                                type: IFabulaMediaType.IMAGE,
                                x: 86,
                                y: 33, 
                                thumbnail: "/assets/img/fabula/Bear/bear612.jpg",
                                speech: [ "613-1" ],
                                music: "/assets/data/fabula/Bear/music/kriogenmash-1.mp3",
                                media:[ ],                                
                            },
                        ]
                    },
                ],
                visible: true,
            },
            {
                id: "62",
                title: "Памятник Ленину",
                post_content: "",
                x: 90,
                y: 80,
                tile_id: "6",
                media: [                                
                    {
                        id: "621",
                        title: "Памятник Ленину",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        staff:[ "5" ],
                        thumbnail: "/assets/img/fabula/Bear/bear621.png",
                        speech:[ ],
                        media: [
                            {
                                id: "6211",
                                title: "История производственной общины «Креогенмаш»",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 50,
                                y: 67,
                                thumbnail: "/assets/img/fabula/Bear/bear6211.jpg",
                                speech:[],
                            },
                            {
                                id: "6212",
                                title: "Транспортный вакуумный клапан приёма компонентных крейсеров и дронов-доставщиков.",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 9,
                                y: 42,
                                thumbnail: "/assets/img/fabula/Bear/bear6212.png",
                                speech:[],
                                media: [
                                    {
                                        id: "62121",
                                        title: "Вакуумная мембрана для дронов-доставщиков.",
                                        label: "Вход к вакуумным мембранам", 
                                        post_content: "",
                                        type: IFabulaMediaType.IMAGE,
                                        x: 50,
                                        y: 68,
                                        thumbnail: "/assets/img/fabula/Bear/bear62121.png",
                                        speech:[],
                                        media: [
                                            
                                        ]
                                    },
                                ]
                            },
                         ]                                
                    },
                ],
                visible: true,
            },
            {
                id: "63",
                title: "Производственная комуна Криогенмаш",
                post_content: "",
                x: 10,
                y: 86,
                tile_id: "6",
                media: [                                
                    {
                        id: "631",
                        title: "Лаборатории производственной комуны Криогенмаш",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear631.jpg",
                        staff: [ "4" ],
                        speech:[],
                        media: [                                
                            {
                                id: "6311",
                                title: "Полигон лаборатории нейтрино",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 96,
                                y: 86,
                                thumbnail: "/assets/img/fabula/Bear/bear6311.jpg",
                                speech:[],
                                media: [ ]                                
                            },]                                
                    },
                ],
                visible: true,
            },
        ]
    }
}