import HelpHTMLSource from "./HelpHTMLSource"
import HelpVideo from "./HelpVideo"

/* Protopia Ecosystem component */
export interface IHelpProps { 
    item: any
}
const Help = (props: IHelpProps): JSX.Element => {
    switch(props.item.type) {
        case "html_source":
            return <> 
                <HelpHTMLSource item={props.item} />
            </>
        case "youtube":
        default:
            return <> 
                <HelpVideo item={props.item} />
            </>
    }
}
export default Help