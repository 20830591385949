import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { __ } from "src/libs/utilities"
import { IManituStore, useManituStore } from "../../data/store"
import { IOrganoidType } from "../../data/interfaces"
import { organoidTypes } from "../../data/organoidTypes"

 
const ManituAdminForm = () : JSX.Element => {
    const matrixRows: number = useManituStore((state:IManituStore) => state.matrixRows)
    const matrixColumns:number = useManituStore((state:IManituStore) => state.matrixColumns)
    const defaultBrokenCells: number = useManituStore((state:IManituStore) => state.defaultBrokenCells)
    const organoidsTypes: IOrganoidType[] = useManituStore((state:IManituStore) => state.organoidsTypes)
    const maxDefense: number = useManituStore((state:IManituStore) => state.maxDefense)
    const size: number = useManituStore((state:IManituStore) => state.size)
    
    const onField = (value: any, field: string, data: any) => { 
        useManituStore.setState( { [field]: value } )
    }
    const onOrganoidType = (value: any, field: string, data: any) => {
        console.log( value)
    }
    return <div className="container mb-5"> 
        <FieldInput
            value={matrixRows}
            editable
            type={SCALAR_TYPES.SLIDER}
            min={2}
            max={20}
            stepSize={1}
            labelStepSize={2}
            field="matrixRows"
            title={__("Matrix rows")}
            onChange={onField}
        />
        <FieldInput
            value={matrixColumns}
            editable
            type={SCALAR_TYPES.SLIDER}
            min={2}
            max={20}
            stepSize={1}
            labelStepSize={2}
            field="matrixColumns"
            title={__("Matrix columns")}
            onChange={onField}
        />
        <FieldInput
            value={defaultBrokenCells}
            editable
            type={SCALAR_TYPES.PERCENTAGE}
            min={0}
            max={5}
            stepSize={1}
            labelStepSize={1}
            field="defaultBrokenCells"
            title={__("Default max count of broken cells")}
            onChange={onField}
        />
        <FieldInput
            value={size}
            editable
            type={SCALAR_TYPES.PERCENTAGE}
            min={20}
            max={200}
            stepSize={10}
            labelStepSize={20}
            field="size"
            title={__("Matrix cell default size")}
            onChange={onField}
        />
        <FieldInput
            value={
                organoidsTypes.map(( ot: IOrganoidType ) => ot.id )
            }
            editable
            type={SCALAR_TYPES.IMAGE_CHECKBOX}
            values={ organoidTypes().map(ot => ({
                // ...ot, 
                _id: ot.id, 
                desription: ot.post_content,
                img: ot.icon, 
                height: 120,
                width: 120
            })) }
            field="organoidsTypes"
            title={__("Matrix available organoids types")}
            onChange={ onOrganoidType }
        /> 
        <FieldInput
            value={maxDefense}
            editable
            type={SCALAR_TYPES.SPEEDOMETER}
            min={1}
            max={20}
            stepSize={1}
            labelStepSize={2}
            field="maxDefense"
            title={__("Matrix cell maximum defense")}
            onChange={onField}
        />

        <div className="w-100 my-5">.</div>
    </div>
} 

export default ManituAdminForm