import { ITeamType } from "../interfaces"

export const teamTypes = (): ITeamType[] => {  
    const lorigin = `${window.location.origin}/`
    const _teamTypes = [
        {
            id: 1,
            title: "Клуб",
            post_content: "Группа товарищей",
            color: "#668d9f",
            icon: lorigin + "assets/img/topos/scanner-female-svgrepo-com.svg",
        },
        {
            id: 2,
            title: "Реконструкторы",
            post_content: "Группа товарищей",
            color: "#87b747",
            icon: lorigin + "assets/img/topos/palace-svgrepo-com.svg",
        },
        {
            id: 3,
            title: "Школьный кружок",
            post_content: "Группа товарищей",
            color: "#b7478c",
            icon: lorigin + "assets/img/topos/desk-lamp-svgrepo-com.svg",
        },
        {
            id: 4,
            title: "Семейная команда",
            post_content: "Группа товарищей",
            color: "#b0b747",
            icon: lorigin + "assets/img/topos/couple-svgrepo-com.svg",
        },
    ]

    return _teamTypes
}