import { IManitu, useManitu } from "../../../data/store" 
import { IManituMatrix } from "../../../data/interfaces"
import { IState, IUser, useMainStore } from "src/settings/zustand"

interface IProps { 
    
}
const Opponents = (props:IProps) : JSX.Element => { 
    return <div>
        <Men /> 
        <Avatar />
    </div> 
} 

export default Opponents

const Men = (props:any) => {
    return  <svg 
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        x="0px" 
        y="0px"
        height={300}
        viewBox="0 0 257.7 411.2"
        xmlSpace="preserve"
        style={{ marginRight:40, cursor: "pointer" }}
        onClick={props.onClick}
    > 
        <g>
            <path className={ props.isSelect ? "men is_select" : "men " } fill="#00000077" d="M68.3,402.6c17.2,0.1,34.3,0.1,51.5,0.2c1.3,2.4,0.9,3.8-2.2,3.8c-15.3,0-30.5-0.1-45.8,0
                C68.7,406.6,68,405.2,68.3,402.6z"/>
            <path className={ props.isSelect ? "men is_select" : "men " } fill="#00000077" d="M1.8,402.6c17.2,0,34.3,0.1,51.5,0.1c0.3,2.7-0.7,3.9-3.6,3.9c-14.8-0.1-29.6-0.1-44.3,0
                C2.2,406.6,1.5,405.2,1.8,402.6z"/>
            <path className={ props.isSelect ? "men is_select" : "men " } fill="#00000077" d="M116.5,396.1c-9.5-4.3-18.9-8.6-29-11.3c0.4-4,0.3-3.1,0.7-7.1c6.4-0.5,6.2-0.6,6.3-7.5c0-3.2-2.7-5-2.7-8.5
                c0.3-51,0.2-102,0.2-153c0-2.2-0.3-4.5-0.2-6.5c1.5-4.2-0.1-8.9,1.8-13c2.3,10.2,4.9,20.3,6.8,30.6c1.5,8.1,6.5,16.3,1.1,24.8
                c-0.4,0.7-0.3,1.8-0.2,2.8c0.2,1.6-0.8,3.6,0.7,4.8c1.6,1.2,2-2.3,3.8-1.3c0.4,3.2,0.7,6.3,1.1,9.4c0.2,1.6,0.2,3.3,2.1,3.8
                c1.9,0.5,3.4-0.4,4.5-1.8c2.2-2.9,3.5-6.2,3.7-9.9c0.4-6-1.3-11.8-1.7-17.6c-1.8-25.2-2.8-50.5-5-75.6c2.1-2.7,2-6.2-0.1-9.2
                c-0.4-2.3-1.1-5.3-1.5-7.6c1.1-5.6,0.5-11.1-1.6-16.4c-0.1-2.3-0.2-4.7-0.2-7c0.9-6.2,1.7-12.3-2.2-17.8c-0.2-0.2-0.3-0.3-0.6-0.3
                c-0.3-0.3-0.6-0.6-0.9-1c-1.6-2.1-3.8-3.1-6.4-3.5c-8.2-1.2-16.3-2.8-24.5-3.2c-0.3-3-0.5-6.1-0.8-9.1c0.5-2.1,1.1-5.1,1.6-7.2
                c2.1-3.8,2.8-8.4,7.8-10.6c3.1-1.3,3.8-5.4,3.5-9c-0.3-3.9-3.6-2.3-5.6-3c-0.3-0.1-0.5-0.8-0.7-1.2c0.8-3.8,0.3-7.8,1.4-11.6
                c0.6-2,0.1-4.2-1.7-5.5c-2-1.5-3.5-2.8-2.4-5.6c0.3-0.8-0.4-2.1-1.5-1c-1.7,1.8-2.1,0.6-2.9-0.8c-2.3-4.1-5.8-4.4-9.6-2.5
                c-2.6,1.3-5.1,1.7-8.1,1.3c-4.2-0.6-8.4,0-12.1,2.4c-0.6,0.4-1.1,1-1.7,1.6c3.9,2.8-1.4,4.4-0.8,6.7c0.5,1.9-3.4,6,2.6,5.4
                c1-0.1,0.7,1.2,0.8,1.9c0.1,1.6,0.2,3.1,0.3,4.7c-0.3,2.1,1.1,5.1-2.6,5c-2.1-0.1-3.6,1.9-3.1,3.9c0.9,3.8,1.9,7.6,6,9.2
                c0.9,0.3,1.7,0.6,2.1,1.6c3.5,7.6,5.4,15.4,3.7,23.8c-6.8,0.8-13.6,1.4-20.3,2.5c-7.9,1.3-11.1,4.7-12.1,11.9
                c-1.2,3.7-1.6,7.5-0.4,11.3c0,0.5,0,1.1,0,1.6c-0.2,0.4-0.3,0.8-0.5,1.2c-0.3,5.6-0.7,11.2-1,16.8c1,1.5,0.7,2.9,0.3,4.4
                c0.4-1.5,0.7-2.9-0.3-4.4c-1,5.5-2,11-3,16.6c-0.2,1.2,0.1,2.5,1.2,3.4c-0.7,10.4-1.9,20.8-2.3,31.2c-0.4,11.2-2,22.4-2.1,33.6
                c-0.1,9.7-3.1,19.1-2.5,28.8c0.3,4.1,1.6,7.6,4.1,10.7c1,1.2,2.5,2,4.2,1.5c1.6-0.5,1.9-2,2.1-3.4c0.5-3.3,0.8-6.7,1.1-10
                c1.8-0.2,2,2.7,3.7,1.6c1.5-0.9,0.7-2.5,0.8-3.9c0.2-2.5,0-5.2-1.5-7.2c-2.1-2.8-1.5-5.4-0.8-8.3c2.5-10.9,5.4-21.8,7.2-32.8
                c1.7,0,2.5,1.4,3.7,2.2c0,53.2,0,106.5-0.1,159.7c0,1.6,0.8,3.6-0.8,4.9c-2.8,2.2-1.1,5.1-1.4,7.7c-0.5,5.3,4.1,1.9,5.9,3.3
                c0.1,0.3,0.1,0.8,0.1,1.1c0,2.4-0.1,3.6-0.1,5.9c-9.4,2.8-18.3,7-27.4,10.7c-3,1.2-4.3,3.9-4.5,7.1c17.2,0,34.3,0.1,51.5,0.1
                c0-3.2,0-6.4-0.1-9.7c-0.1-1.7,0.7-3.9-1.8-4.7l0-10.7c0.9-0.1,1.9-0.3,2.8-0.2c4.1,0.7,5.1-0.9,5.2-5c0.1-3.7-2.4-6.1-2.4-9.7
                c0.2-43.1,0.2-86.1,0.1-129.2c0-3.1,0.5-4.4,4-4.3c3.5,0,4.1,1.3,4.1,4.4c-0.1,43.1,0,86.1-0.1,129.2c0,1.5,0.2,3-1.1,4.4
                c-2.7,2.7-1.6,6.4-1,9.3c0.5,2.2,3.9,0.5,5.9,1c1.5,0.4,3.3,0,4.3,2.5c-1.1,3.1-1.8,5.2-2.9,8.3c-1.7,1-1.2,2.7-1.3,4.1
                c-0.2,3.4,0.3,6.8-0.6,10.1c17.2,0.1,34.3,0.1,51.5,0.2C120.2,399.8,118.9,397.2,116.5,396.1z"/>
        </g>
        <g>
            <path className={ props.isSelect ? "men is_select" : "men " } fill="#00000077" d="M207,402.2c16.4,0.1,32.8,0.1,49.3,0.2c1.2,2.3,0.9,3.6-2.1,3.6c-14.6,0-29.2-0.1-43.8,0
                C207.4,406.1,206.7,404.7,207,402.2z"/>
            <path className={ props.isSelect ? "men is_select" : "men " } fill="#00000077" d="M143.3,402.2c16.4,0,32.8,0.1,49.3,0.1c0.3,2.6-0.6,3.7-3.5,3.7c-14.1-0.1-28.3-0.1-42.4,0
                C143.7,406.1,143,404.7,143.3,402.2z"/>
            <path className={ props.isSelect ? "men is_select" : "men " } fill="#00000077" d="M253.1,396c-9-4.1-18.1-8.2-27.7-10.8c0.4-3.9,0.3-2.9,0.6-6.8c6.1-0.5,6-0.6,6-7.1c0-3.1-2.6-4.8-2.6-8.1
                c0.3-48.8,0.2-97.6,0.2-146.4c0-2.1-0.3-4.3-0.2-6.3c1.4-4-0.1-8.5,1.7-12.5c2.2,9.8,4.6,19.5,6.5,29.3c1.5,7.8,6.2,15.6,1,23.7
                c-0.4,0.7-0.3,1.8-0.2,2.6c0.2,1.6-0.8,3.4,0.7,4.6c1.5,1.2,1.9-2.2,3.6-1.2c0.3,3,0.7,6,1,9c0.2,1.5,0.2,3.1,2,3.6
                c1.8,0.5,3.3-0.4,4.3-1.8c2.1-2.8,3.3-5.9,3.6-9.5c0.4-5.7-1.2-11.3-1.6-16.9c-1.7-24.1-2.6-48.3-4.8-72.4c2-2.5,1.9-5.9-0.1-8.8
                c-0.4-2.2-1-5.1-1.4-7.3c1.1-5.4,0.4-10.6-1.5-15.7c-0.1-2.2-0.2-4.5-0.2-6.7c0.9-5.9,1.6-11.8-2.1-17.1c-0.2-0.1-0.3-0.3-0.5-0.3
                c-0.3-0.3-0.6-0.6-0.9-0.9c-1.6-2-3.6-3-6.1-3.4c-7.8-1.1-15.6-2.7-23.5-3.1c-0.3-2.9-0.5-5.8-0.8-8.7c0.4-2,1.1-4.9,1.5-6.9
                c2-3.6,2.7-8.1,7.4-10.1c3-1.3,3.6-5.2,3.3-8.7c-0.3-3.7-3.4-2.2-5.4-2.9c-0.3-0.1-0.4-0.7-0.7-1.1c0.7-3.6,0.3-7.4,1.4-11.1
                c0.6-1.9,0.1-4.1-1.6-5.3c-1.9-1.4-3.3-2.7-2.3-5.4c0.3-0.8-0.4-2-1.5-0.9c-1.6,1.7-2.1,0.6-2.8-0.7c-2.2-4-5.5-4.3-9.2-2.4
                c-2.5,1.3-4.9,1.6-7.7,1.2c-4-0.6-8.1,0-11.6,2.3c-0.6,0.4-1.1,1-1.6,1.5c3.7,2.7-1.4,4.2-0.8,6.4c0.5,1.8-3.3,5.8,2.5,5.1
                c0.9-0.1,0.7,1.1,0.7,1.8c0.1,1.5,0.2,3,0.3,4.5c-0.3,2,1,4.9-2.5,4.8c-2-0.1-3.4,1.8-3,3.8c0.9,3.6,1.8,7.2,5.8,8.8
                c0.8,0.3,1.6,0.5,2,1.5c3.3,7.2,5.2,14.7,3.6,22.7c-6.5,0.8-13,1.4-19.5,2.4c-7.6,1.2-10.6,4.5-11.6,11.4
                c-1.2,3.6-1.5,7.1-0.4,10.8c0,0.4,0,1.1,0,1.5c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3,5.4-0.6,10.7-1,16.1c0.9,1.4,0.7,2.8,0.3,4.2
                c0.4-1.4,0.6-2.8-0.3-4.2c-1,5.3-2,10.6-2.9,15.8c-0.2,1.2,0.1,2.4,1.1,3.2c-0.7,10-1.8,19.9-2.2,29.9c-0.4,10.7-1.9,21.4-2,32.1
                c-0.1,9.3-3,18.2-2.4,27.5c0.2,3.9,1.5,7.3,4,10.2c1,1.2,2.4,1.9,4,1.4c1.5-0.5,1.8-1.9,2-3.2c0.4-3.2,0.7-6.4,1.1-9.6
                c1.7-0.2,1.9,2.5,3.6,1.6c1.4-0.8,0.7-2.4,0.8-3.7c0.2-2.4,0-5-1.4-6.9c-2-2.7-1.4-5.2-0.8-8c2.4-10.5,5.2-20.8,6.9-31.4
                c1.6,0,2.4,1.3,3.6,2.1c0,50.9,0,101.9-0.1,152.8c0,1.6,0.8,3.4-0.7,4.7c-2.7,2.1-1,4.9-1.3,7.4c-0.5,5.1,3.9,1.8,5.7,3.1
                c0.1,0.3,0.1,0.7,0.1,1.1c0,2.2-0.1,3.4-0.1,5.7c-9,2.7-17.5,6.7-26.2,10.2c-2.8,1.1-4.2,3.7-4.3,6.8c16.4,0,32.8,0.1,49.3,0.1
                c0-3.1,0-6.2-0.1-9.2c-0.1-1.6,0.7-3.7-1.7-4.5l0-10.2c0.9-0.1,1.8-0.3,2.7-0.2c3.9,0.7,4.9-0.9,5-4.8c0.1-3.5-2.3-5.8-2.3-9.2
                c0.2-41.2,0.1-82.4,0.1-123.6c0-2.9,0.5-4.2,3.8-4.2c3.3,0,3.9,1.3,3.9,4.2c-0.1,41.2,0,82.4-0.1,123.6c0,1.4,0.2,2.9-1,4.2
                c-2.6,2.6-1.6,6.1-0.9,8.9c0.5,2.1,3.7,0.5,5.6,0.9c1.5,0.4,3.1,0,4.1,2.4c-1.1,3-1.7,4.9-2.8,7.9c-1.6,1-1.1,2.6-1.2,4
                c-0.2,3.2,0.2,6.5-0.6,9.7c16.4,0.1,32.8,0.1,49.3,0.2C256.7,399.5,255.4,397.1,253.1,396z"/>
        </g>
        <g>
            <path className={ props.isSelect ? "man is_select" : "man " }  fill="#1C212B" stroke="#999" strokeWidth="2" strokeMiterlimit="10" d="M137,404.7c18.3,0.1,36.6,0.1,54.9,0.2c1.3,2.6,1,4-2.4,4c-16.3,0-32.5-0.1-48.8,0
                C137.5,409,136.8,407.4,137,404.7z"/>
            <path className={ props.isSelect ? "man is_select" : "man " } fill="#1C212B" stroke="#999" strokeWidth="2" strokeMiterlimit="10" d="M66.1,404.7c18.3,0,36.6,0.1,54.9,0.1c0.3,2.9-0.7,4.2-3.8,4.1c-15.8-0.1-31.5-0.1-47.3,0
                C66.6,409,65.8,407.5,66.1,404.7z"/>
            <path className={ props.isSelect ? "man is_select" : "man " } fill="#1C212B" stroke="#999" strokeWidth="2" strokeMiterlimit="10" d="M188.4,397.8c-10.1-4.5-20.2-9.1-30.9-12c0.4-4.3,0.3-3.3,0.7-7.6c6.8-0.6,6.7-0.7,6.7-8
                c0-3.4-2.9-5.3-2.9-9.1c0.3-54.4,0.2-108.8,0.2-163.2c0-2.3-0.3-4.8-0.2-7c1.6-4.5-0.1-9.4,1.9-13.9c2.4,10.9,5.2,21.7,7.2,32.6
                c1.6,8.7,6.9,17.3,1.1,26.4c-0.5,0.7-0.3,2-0.2,3c0.2,1.7-0.9,3.8,0.8,5.2c1.7,1.3,2.1-2.5,4-1.4c0.4,3.4,0.8,6.7,1.1,10
                c0.2,1.7,0.3,3.5,2.3,4c2,0.5,3.6-0.4,4.8-2c2.4-3.1,3.7-6.6,4-10.6c0.4-6.4-1.4-12.5-1.8-18.8c-1.9-26.9-2.9-53.8-5.4-80.7
                c2.3-2.8,2.2-6.6-0.2-9.8c-0.5-2.4-1.1-5.7-1.6-8.1c1.2-6,0.5-11.8-1.7-17.4c-0.1-2.5-0.2-5-0.3-7.4c1-6.6,1.8-13.1-2.4-19
                c-0.2-0.2-0.4-0.3-0.6-0.4c-0.3-0.3-0.7-0.7-1-1c-1.7-2.2-4-3.4-6.8-3.7c-8.7-1.2-17.3-3-26.1-3.5c-0.3-3.2-0.6-6.5-0.8-9.7
                c0.5-2.2,1.2-5.5,1.7-7.7c2.2-4,3-9,8.3-11.3c3.3-1.4,4.1-5.8,3.7-9.6c-0.4-4.1-3.8-2.4-6-3.2c-0.4-0.1-0.5-0.8-0.7-1.2
                c0.8-4.1,0.3-8.3,1.5-12.3c0.6-2.1,0.1-4.5-1.8-5.9c-2.2-1.6-3.7-3-2.5-6c0.4-0.9-0.5-2.3-1.6-1c-1.8,1.9-2.3,0.6-3.1-0.8
                c-2.4-4.4-6.2-4.7-10.2-2.7c-2.8,1.4-5.5,1.8-8.6,1.3c-4.5-0.7-9,0-13,2.5c-0.7,0.4-1.2,1.1-1.8,1.7c4.1,3-1.5,4.7-0.9,7.1
                c0.5,2-3.7,6.4,2.8,5.7c1-0.1,0.8,1.3,0.8,2c0.2,1.7,0.2,3.3,0.3,5c-0.3,2.2,1.1,5.5-2.8,5.3c-2.2-0.1-3.8,2-3.3,4.2
                c1,4,2,8.1,6.4,9.8c0.9,0.4,1.8,0.6,2.3,1.7c3.7,8.1,5.7,16.4,4,25.3c-7.2,0.9-14.5,1.5-21.7,2.7c-8.5,1.4-11.8,5-12.9,12.7
                c-1.3,4-1.7,8-0.5,12c0,0.5,0,1.2,0,1.7c-0.2,0.4-0.3,0.9-0.5,1.3c-0.4,6-0.7,11.9-1.1,17.9c1,1.6,0.7,3.1,0.3,4.7
                c0.4-1.6,0.7-3.1-0.3-4.7c-1.1,5.9-2.2,11.8-3.2,17.7c-0.2,1.3,0.1,2.7,1.2,3.6c-0.8,11.1-2,22.1-2.4,33.3
                c-0.4,11.9-2.2,23.8-2.3,35.8c-0.1,10.3-3.3,20.3-2.7,30.7c0.3,4.3,1.7,8.1,4.4,11.4c1.1,1.3,2.6,2.1,4.4,1.6
                c1.7-0.5,2-2.1,2.2-3.6c0.5-3.6,0.8-7.1,1.2-10.6c1.9-0.2,2.1,2.8,4,1.7c1.6-0.9,0.8-2.7,0.9-4.1c0.2-2.7,0-5.6-1.6-7.7
                c-2.2-3-1.6-5.8-0.9-8.9c2.6-11.7,5.8-23.2,7.6-35c1.8,0,2.7,1.5,4,2.4c0,56.8,0,113.5-0.1,170.3c0,1.7,0.9,3.8-0.8,5.2
                c-3,2.4-1.2,5.5-1.4,8.2c-0.6,5.7,4.4,2,6.3,3.5c0.1,0.4,0.1,0.8,0.1,1.2c0,2.5-0.1,3.8-0.1,6.3c-10,3-19.5,7.5-29.2,11.4
                c-3.2,1.3-4.6,4.1-4.8,7.6c18.3,0,36.6,0.1,54.9,0.1c0-3.4,0-6.9-0.1-10.3c-0.1-1.8,0.8-4.1-1.9-5l0-11.4c1-0.1,2-0.3,3-0.2
                c4.4,0.7,5.4-1,5.6-5.3c0.1-3.9-2.6-6.5-2.6-10.3c0.2-45.9,0.2-91.8,0.1-137.8c0-3.3,0.5-4.7,4.3-4.6c3.7,0,4.3,1.4,4.3,4.7
                c-0.1,45.9-0.1,91.8-0.1,137.8c0,1.6,0.3,3.2-1.1,4.6c-2.8,2.9-1.8,6.8-1,9.9c0.6,2.4,4.1,0.5,6.3,1c1.6,0.4,3.5,0,4.6,2.7
                c-1.2,3.3-1.9,5.5-3.1,8.8c-1.8,1.1-1.3,2.8-1.4,4.4c-0.2,3.6,0.3,7.2-0.6,10.8c18.3,0.1,36.6,0.1,54.9,0.2
                C192.4,401.7,191.1,399,188.4,397.8z"/>
        </g>
    </svg> 
}

const Avatar = () : JSX.Element => {
    const user: IUser = useMainStore((state: IState) => state.user)
    const matrix: IManituMatrix = useManitu((state: IManitu) => state.matrix)
    if( user.id === matrix?.user?.id)
    {
        return <></>
    } 
    return <div className="pe-manitu-opponent-avatar-btn" style={{position: "absolute", top: 70, left: 60}} >
        <div className="pe-manitu-opponent-avatar" style={{ backgroundImage: `url(${ matrix?.user?.avatar })` }} />
    </div>
}
