import { IPETest } from "../../data/interfaces"

interface IStepMonitorProps {
    item: IPETest
    current: number
    full:number
}
const StepsMonitor = (props: IStepMonitorProps): JSX.Element => {
    return <div className="pe-matching-steps-monitor-cont">
        {
            Array(props.full).fill(1).map((__, i) => {
                return <div className={` step ${ i < props.current ? "past" : "" } `} key={ i } >

                </div>
            })
        } 
    </div> 
}


export default StepsMonitor