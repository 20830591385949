import React, { Component } from "react"
import { Link } from "react-router-dom"
import { __ } from "@/libs/utilities"

type FreeNavLinkWidgetProps = {
  preroute?: string,
  link_route?: string,
  className?: string,
  content: string,
  label?: string
}
class FreeNavLinkWidget extends Component<FreeNavLinkWidgetProps> {
  render() {
    const url = this.props.preroute
      ?
      `${this.props.preroute}/${this.props.link_route}`
      :
      this.props.link_route
        ?
        `${this.props.link_route}`
        :
        "/"
    return (
      <Link
        to={url} 
        className={this.props.className}   
      >
        {this.props.content}
        {__(this.props.label || "")}
      </Link>
    )
  }
}
export default FreeNavLinkWidget
