import { GET_FEED_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"
import { Button } from "@blueprintjs/core"
import { useEffect, useState } from "react" 

interface IProps {
    onFestival: ( festival:ILand ) => void

}
const ChoosePatterns = ( { onFestival } : IProps ): JSX.Element => {
    const [patterns, setPatterns] = useState<ILand[]>([])
    useEffect(() => {
        actions(
            GET_FEED_ACTION,
            {
                data_type: "Land",
                paging: ` metas: {key: "isPattern", value: "1" } `
            }
        )
        .then((resp: any ) => {
            setPatterns(resp.feed) 
        })
    }, [])
    return <div className='w-100'>
        {
            patterns.map( pattern => {
                return <Button minimal fill onClick={() => onFestival( pattern ) }>
                    { pattern.name }
                </Button>
            })
        }
    </div>
}
export default ChoosePatterns