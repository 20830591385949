import { IOpponent } from "src/modules/pe-ludens-module/data/interfaces" 

export const players = () : IOpponent[] => {
    return [
        {
            id: 767,
            display_name: "[Горынытч]",
            roles: [],
            avatar: "/assets/img/manitu/avatars/dragon1.jpg"
        },
        {
            id: 766,
            display_name: "[Хозяйство]",
            roles: [],
            avatar: "/assets/img/manitu/avatars/dragon2.jpg"
        },
        {
            id: 765,
            display_name: "[Организация]",
            roles: [],
            avatar: "/assets/img/manitu/avatars/dragon3.jpg"
        },
        {
            id: 764,
            display_name: "[Колхоз]",
            roles: [],
            avatar: "/assets/img/manitu/avatars/dragon4.jpg"
        },
        {
            id: 763,
            display_name: "[Город]",
            roles: [],
            avatar: "/assets/img/manitu/avatars/dragon5.jpg"
        },
        {
            id: 1,
            display_name: "Микола де Родшильд",
            roles: [],
            avatar: "/assets/img/manitu/avatars/1.svg"
        },
        {
            id: 2,
            display_name: "Агафья Фуфайкина",
            roles: [],
            avatar: "/assets/img/manitu/avatars/2.svg"
        },
        {
            id: 3,
            display_name: "Шарлиз Макуализайкина",
            roles: [],
            avatar: "/assets/img/manitu/avatars/3.svg"
        },
        {
            id: 4,
            display_name: "Сусанна Вовощах",
            roles: [],
            avatar: "/assets/img/manitu/avatars/4.svg"
        },
        {
            id: 5,
            display_name: "Руслан Махамов",
            roles: [],
            avatar: "/assets/img/manitu/avatars/5.svg"
        },
        {
            id: 6,
            display_name: "Чарльз Чехов",
            roles: [],
            avatar: "/assets/img/manitu/avatars/6.svg"
        },
        {
            id: 7,
            display_name: "Алефтина Семёнова",
            roles: [],
            avatar: "/assets/img/manitu/avatars/7.svg"
        },
        {
            id: 8,
            display_name: "Жанаа Пух",
            roles: [],
            avatar: "/assets/img/manitu/avatars/8.svg"
        },
        {
            id: 9,
            display_name: "Степан Махмудов",
            roles: [],
            avatar: "/assets/img/manitu/avatars/9.svg"
        },
        {
            id: 10,
            display_name: "Валентина Сурыгина",
            roles: [],
            avatar: "/assets/img/manitu/avatars/10.svg"
        },
        {
            id: 11,
            display_name: "Маргарита Флёр",
            roles: [],
            avatar: "/assets/img/manitu/avatars/11.svg"
        },
        {
            id: 12,
            display_name: "Шалман Косуев",
            roles: [],
            avatar: "/assets/img/manitu/avatars/12.svg"
        },
        {
            id: 13,
            display_name: "Макар Телятин",
            roles: [],
            avatar: "/assets/img/manitu/avatars/1.svg"
        },
        {
            id: 14,
            display_name: "Варлам Шагурин",
            roles: [],
            avatar: "/assets/img/manitu/avatars/2.svg"
        },
        {
            id: 15,
            display_name: "Ольга Камо",
            roles: [],
            avatar: "/assets/img/manitu/avatars/3.svg"
        },
        {
            id: 16,
            display_name: "Олег Маташов",
            roles: [],
            avatar: "/assets/img/manitu/avatars/4.svg"
        },
        {
            id: 17,
            display_name: "Тарас Загоруйко",
            roles: [],
            avatar: "/assets/img/manitu/avatars/5.svg"
        },
        {
            id: 18,
            display_name: "Борис Странный",
            roles: [],
            avatar: "/assets/img/manitu/avatars/6.svg"
        },
        {
            id: 19,
            display_name: "Поромон Михайлов",
            roles: [],
            avatar: "/assets/img/manitu/avatars/7.svg"
        },
        {
            id: 20,
            display_name: "Эдуард Апельсинов",
            roles: [],
            avatar: "/assets/img/manitu/avatars/8.svg"
        },
        {
            id: 21,
            display_name: "Лариса Мандаринкина",
            roles: [],
            avatar: "/assets/img/manitu/avatars/9.svg"
        },
        {
            id: 22,
            display_name: "Дмитрий Вопрос",
            roles: [],
            avatar: "/assets/img/manitu/avatars/10.svg"
        },
    ]
}