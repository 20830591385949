
import { Outlet, useNavigate } from "react-router"
import { Icon } from "@blueprintjs/core"
import { __ } from "src/libs/utilities"
import TimeMenu from "../TimeMenu"
import TimeWidget from "./TimeWidget" 
import { IToposStore, useToposStore } from "../../data/store"  
import { labels } from "../../data/labels"

const ToposLayoutContainer = ({ children, isReloading, className,  ...props }: any) : JSX.Element => {
    const range: number = useToposStore((state:IToposStore) => state.range) 
    const navigate = useNavigate() 
    const onChange = (value:  number) => { 
        navigate( labels[value] )
    }
    const onChangeDate = (date: number) => {

    } 
    return <div className={ ` topos-calendar-container ` }>
    <div className={ className ? className : " topos-map-content " }> 
        <div className={`topos-content `}>
            <TimeMenu onChange={ onChange } />
            { children }
            <Outlet />
        </div> 
        <div className="topos-left-clapan d-lg-block d-none">
            <div className="display-7 text-uppercase mx-3 mt-1 mb-1 d-flex">
                <div className={isReloading ? "fa fa-spin" : ""}>
                    <Icon 
                        size={20} 
                        icon={isReloading ? "repeat" : "blank"}
                    />   
                </div> 
                <div className="ml-3">{ __( labels[ range ] ) }</div> 
            </div>
            <TimeWidget onChangeDate={ onChangeDate } />
        </div> 
    </div> 
</div>
}

export default ToposLayoutContainer