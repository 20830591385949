import { IPEFabula } from "../interfaces";
import { PEFabulaStory } from "./fabula1";
import { PEFabulaStory2 } from "./fabula2";

export const PEFabula = () : IPEFabula => {
    const fabula : IPEFabula = {
        id: "1",
        title: "Барминград-2025",
        post_content: "",
        PEStory: [ PEFabulaStory2(), PEFabulaStory(), ]
    }
    return fabula
}


