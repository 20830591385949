import React, { useEffect } from 'react' 
import { ILayoutBodyProps } from '@/libs/interfaces/layouts' 
import { template } from '@/libs/layouts'
import { initArea } from '../../utilities/getWidget'
import LayoutHeader from '../LayoutHeader'
import LayoutContent from '../LayoutContent'
import LayoutFooter from '../LayoutFooter'
import actions from '@/modules/pe-basic-module/data/actions'
import { GET_THEME_ACTION } from '@/modules/pe-basic-module/data/actionTypes'
import { IState, useMainStore } from '@/settings/zustand'
import LayoutFooterMenuStrip from './LayoutFooterMenuStrip'

const LayoutBodyLocal = (props: ILayoutBodyProps) => {    
    const layouts = useMainStore((state: IState) => state.layout )
    useEffect(() => {
        actions( GET_THEME_ACTION, {} )
    }, [ layouts ])
    const def = <div className="layout block w-100">
        {
            !template().header 
                ? 
                null
                : 
                initArea(
                    "layout-header",
                    { ...props },
                    <LayoutHeader />,
                ) 
        } 
        <LayoutContent />
        <LayoutFooter />
        <LayoutFooterMenuStrip />
    </div> 
    return  initArea(
        "layout-app",
        { ...props },
        def
    )
}
 

export default LayoutBodyLocal