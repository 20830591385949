import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import {CodeEditor} from "src/libs/useful"

const HTML = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<HTMLEnabled {...props} />}
        desabledForm={<HTMLDesabled {...props} />}
    /> 
}  

export default HTML

export const HTMLEnabled = (props: IScalarForm) : JSX.Element =>  {
    
    const onChange = (fileData: string ) => {
        if( props.onChange) props.onChange( fileData, props.field, "" )
    }
    return <div className={`d-flex w-100 align-items-start ${props.className ? props.className : ""}`}> 
        <div className=" pe-code-editor pb-5">
            <CodeEditor
                value={ props.value?.toString().replaceAll( "<!-- /wp:paragraph -->", "" ) || "" }
                onChange={ onChange }
            />
        </div>
    </div>
}

export const HTMLDesabled = (props: IScalarForm) : JSX.Element => {
    return <div dangerouslySetInnerHTML={{__html: props.value?.toString().replaceAll("<!-- wp:paragraph -->", "")}} />
}