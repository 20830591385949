export const GET_FABULA_ACTIONS: string = 'get_fabula' 
export const GET_STORY_ACTIONS: string = 'get_story' 
export const CHANGE_HERO_ACTION: string = 'change_hero' 
export const MOVE_HERO_ACTION: string = 'move_hero' 
export const STORY_TICK_ACTION: string = 'story_tick' 
export const BREACK_STORY_ACTION: string = 'break_story' 
export const UPDATE_STORY_ACTION: string = 'update_story' 
export const UPDATE_EPISODE_ACTION: string = 'update_episode' 
export const UPDATE_MEDIA_ACTION: string = 'update_media'  
export const STAFF_EXECUTE_ACTION: string = 'staff_execute' 
