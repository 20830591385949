import { IPETest } from "../interfaces"
import { test } from "./test"
import { test2 } from "./test2"
import { test3 } from "./test3"
import { test4 } from "./test4"
import { test5 } from "./test5"
import { test6 } from "./test6"
import { test7 } from "./test7"
import { test8 } from "./test8"

export const tests = (): IPETest[] => {
    return [
        test(),
        test2(),
        test3(),
        test4(),
        test5(),
        test6(),
        test7(),
        test8(),
    ]
}