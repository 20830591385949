import { template as allTemplates} from "@/libs/layouts"
import FieldInput from "@/libs/scalars"
import { ILayoutTemplate, templateTypes} from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useState } from "react"
import { Tab, Tabs } from "@blueprintjs/core"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"

const TemplateForm = (props: any) : JSX.Element => {
    const [template, changeTemplate] = useState<ILayoutTemplate> (allTemplates() as ILayoutTemplate)
    const [tabId, setTabId] = useState("Layout")

    const onChange = (value: any, field: string , data: any) => {
        let _template: any = {...template } as ILayoutTemplate
        _template[ field as keyof ILayoutTemplate ] = value
        changeTemplate( _template )
    }
    return <div className="layout-state">
        <div className="short-container">
            <Tabs 
                animate 
                large
                renderActiveTabPanelOnly
                selectedTabId={tabId} 
                id="TabsExample"
                onChange={ newTabId => setTabId( newTabId.toString() ) }
            >
            {
                [ "Layout", "Head", "Over", "Default Views design" ].map(tab => { 
                    return <Tab id={tab} title= { __(tab) } key={tab} 
                    panel={
                        <div>
                            {
                                Object.keys( template )
                                    .filter( (key: string) => templateTypes()[ key ]?.tab === tab )
                                    .map( (key: string, i) => {
                                        return <FieldInput
                                             editable
                                             vertical={VerticalType.VERTICAL}
                                             { ...templateTypes()[ key ] }   
                                             field={ key }
                                             value={ template[ key as keyof ILayoutTemplate ] }
                                             key={ key }
                                             onChange={ onChange }
                                        />
                                    })
                            }
                        </div>
                         
                    }
                    />
                })
            } 
            </Tabs>
        </div>
        
    </div>

} 

export default TemplateForm