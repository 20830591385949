import Moment from "react-moment"
import { IToposPostsStore, IToposStore, useToposPostsStore, useToposStore } from "../../data/store"
import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { useState } from "react" 
import { IEvent } from "../../data/interfaces"
import { actions } from "../../data/actions"
import { GET_EVENTS_ACTION } from "../../data/actionTypes"
import BannerContainer from "./BannerContainer"

interface IProps {
    onChangeDate: (date: number) => void
}
const TimeWidget = (props : IProps) : JSX.Element => {
    const [date, setDate] = useState<number>(Date.now())  
    const range: number = useToposStore((state:IToposStore) => state.range)
    const  setEvents: ((events: IEvent[]) => void) = useToposPostsStore((state:IToposPostsStore) => state.setEvents)
    
    const loadEvents = ( _date: number ) => {        
        const start: number = (new Date( _date )).getTime()
        const finish: number = start + 1000 * 60 * 60 * 24
        actions(GET_EVENTS_ACTION, { period: {start, finish} } )
            .then(( response: IEvent[] ) => { 
                setEvents( response )
            })
    }

    const onChangeDate = (_date: number) => {
        setDate(_date || Date.now())
        loadEvents( _date )
        props.onChangeDate( date || Date.now() )
    }
    let d: JSX.Element
    switch(range) {
        case 1:
            d = <div className="ml-3">
                <FieldInput
                    type={SCALAR_TYPES.DATE}
                    value={date}
                    onChange={ onChangeDate }
                    editable
                    notClear
                />
            </div>
            break
        case 2:
        case 3:
        case 4:
            d =  <>{ range }</>
            break
        case 0:
        default:
            const now: number = Date.now()
            const end: number = now + 1000 * 60 * 60 * 24
            d =  <div className=" m-3 d-flex flex-column">
                <Moment locale="ru" format="D MMMM YYYY HH:MM">
                    { now }
                </Moment>   
                <Moment locale="ru" format="D MMMM YYYY  HH:MM">
                    { end }
                </Moment>
            </div> 
            break 
    }
    return <div> 
        <div className="topos-banner-container">
            <BannerContainer />
        </div>
        
    </div>
} 

export default TimeWidget