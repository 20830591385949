import { IFabulaEpisode, IPEFabulaMapTile } from "src/modules/pe-fabula-module/data/interfaces"

/* Protopia Ecosystem component */
export interface ITileEpisodeProps {
    item: IPEFabulaMapTile | IFabulaEpisode | undefined
    onClick: ( e: any ) => void
}
const TileContent = ({item, onClick, ...props}: ITileEpisodeProps): JSX.Element => {
    
    return < >
        <div className="bg" style={{ backgroundImage: `url(${ item?.thumbnail})`}} onClick={onClick}/>
        <div className="p-1 text-light dt">
            <div className="pe-fabula-map-tile-title">
                { item?.title } 
            </div> 
            <div className="pe-fabula-map-tile-content">
                { item?.post_content}
            </div> 

        </div>
    </>
}
export default TileContent