import React from "react"
import BasicState from "@/libs/basic-view"  
import {__} from '@/libs/utilities'
import FestAdminForm from "./festAdmin/FestAdminForm" 
import CabinetMenu from "./festAdmin/CabinetMenu"
import '../assets/css/index.scss'
import PhaseLabel from "./PhaseLabel"

class FestAdminView extends BasicState {
    props: any
    /*
    *   add menu and link to source to header of View
    */
    layouteHeader = () => {
        return <div className="d-flex w-100 ml-4">
            <CabinetMenu />
            <a 
                href={this.props.extend_params?.serviceUrl} 
                target="_blank" 
                rel="noreferrer"
                className="btn btn-sm btn-link px-0 flex-centered "
            >
                {__("Source")}
            </a>
        </div>
    }
    addRender = () => {  
        return  <>
            <FestAdminForm {...this.props} />
            <div className="position-fixed" >
                <PhaseLabel />
            </div>
        </> 
    }
}

export default FestAdminView 