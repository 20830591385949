import { MANITU_ACTION_ANIMATION_TYPE } from "src/modules/pe-manitu-module/data/interfaces"
import { Color } from "src/libs/interfaces/layouts"
import { useEffect, useRef, useState, forwardRef, useImperativeHandle, Ref } from "react"

// determine color
const hue = (Math.random() * 360)
let rafId = 0
let duration = 0
let x: number = 0
let y: number = 0
let color: Color = ""
let type: MANITU_ACTION_ANIMATION_TYPE = MANITU_ACTION_ANIMATION_TYPE.EXPLODE
let width: number = 0
let height: number = 0
let secondary: IParticleProps = {} as IParticleProps

export interface IChpockProps {
    width: number
    height: number
    animationPadding: number
}
export interface RefType {
    start: (
        x: number,
        y: number,
        color: Color,
        type: MANITU_ACTION_ANIMATION_TYPE,
        duration?: number,
        width?: number,
        height?: number,
    ) => void
}

let particles: IParticleProps[] = []
const Chpock = (props: IChpockProps, ref: Ref<RefType>): JSX.Element => {
    const numbers = 90
    const PI2 = Math.PI * 2

    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null)
    const [frame, setFrame] = useState<number>(0)
    const refCanvas = useRef(null)
    useEffect(() => {
        if (refCanvas.current) {
            setCtx((refCanvas.current! as HTMLCanvasElement).getContext('2d'))
        }
        return () => {
            cancelAnimationFrame(rafId)
        }
    }, [])

    const createParticles = (
        _x: number,
        _y: number,
        _color: Color,
        _duration: number,
        _type: MANITU_ACTION_ANIMATION_TYPE,
        _width: number,
        _height: number
    ) => {
        setFrame(0)
        x = (_x)
        y = (_y)
        color = (_color)
        width = (_width || 0)
        height = (_height || 0)
        duration = _duration || 0
        switch (_type) {
            case MANITU_ACTION_ANIMATION_TYPE.STORM:
                storm(_x, _y, _color, 30, _width, _height)
                break;
            case MANITU_ACTION_ANIMATION_TYPE.BOLT:
                bolt(_x, _y, _color, 30, _width, _height)
                break
            case MANITU_ACTION_ANIMATION_TYPE.HURA:
                hura(_x, _y, _color)
                break
            case MANITU_ACTION_ANIMATION_TYPE.EXPLODE:
            default:
                explode(_x, _y, _color, 30)
        }
    }
    const boom = (type: MANITU_ACTION_ANIMATION_TYPE,) => {
        const startRadius = 30
        switch (type) {
            case MANITU_ACTION_ANIMATION_TYPE.STORM:
                storm(x, y, color, startRadius, width, height)
                break;
            case MANITU_ACTION_ANIMATION_TYPE.EXPLODE:
            default:
                explode(x, y, color, startRadius)
        }
    }

    const explode = (x: number, y: number, color: Color, startRadius: number = 0) => {
        let numParticles = numbers;
        let p: IParticleProps[] = []
        while (numParticles--) {
            const direction = Math.random() * PI2;
            const velocity = randomBetween(1, 4);
            const radius = 3 + (Math.random() * 40);
            const explode = true;
            const particle = new Particle({
                x: x + Math.cos(direction) * radius,
                y: y + Math.sin(direction) * radius,
                direction,
                velocity,
                radius,
                color,
                explode
            });
            p.push(particle);
        }
        particles = [...p]
    }

    const storm = (
        x: number,
        y: number,
        color: Color,
        duration: number,
        width: number,
        height: number
    ) => {
        let numParticles = 13;
        while (numParticles--) {
            const direction = .19 * PI2;
            const velocity = randomBetween(3, 4);
            const radius = 1 + (Math.random() * 2);
            const explode = false;
            const particle = new Particle({
                x: x + Math.random() * width - width / 3 * 2 + 20,
                y: y + Math.random() * height - height / 3 * 2,
                direction,
                velocity,
                radius,
                color,
                explode
            });
            particles.push(particle);
        }
    }

    const bolt = (
        x: number,
        y: number,
        color: Color,
        duration: number,
        width: number,
        height: number
    ) => {
        let numParticles = 50;
        while (numParticles--) {
            const friction = .96
            const direction = .22 * PI2;
            const velocity = randomBetween(1, 4);
            const radius =  10 + (Math.random() * 20) 
            const explode = true;
            const particle = new Particle({
                x: x + Math.cos(direction) * radius - 25,
                y: y + Math.sin(direction) * radius - 120,
                direction,
                velocity,
                radius,
                color,
                friction,
                explode
            });
            particles.push(particle);
        }
    }

    const hura = (
        x: number,
        y: number,
        color: Color,
    ) => {
        let numParticles = 50
        while (numParticles--) {
            const friction = .9
            const direction = Math.random() * PI2
            const velocity = randomBetween(1, 2)
            const radius = 10 + (Math.random() * 20)
            const explode = true;
            const _x = x + Math.cos(direction) * 18
            const _y = y + Math.sin(direction) * 18
            const particle = new Particle({
                x: _x,
                y: _y,
                direction,
                velocity,
                radius,
                color,
                friction,
                explode
            });
            particles.push(particle);
        }
    }

    const secondartExpliode = (particle: IParticleProps) => {
        return new Particle({
            x: particle.x,
            y: particle.y,
            radius: Math.random() < .9993 
                ? 
                particle.radius * 1 + 2
                :
                particle.radius * 24 + 1,
            direction: particle.direction,
            velocity: particle.velocity * (randomBetween(2, 1)),
            explode: false,
            color: particle.color
        })
    }
    const secondartHura = (particle: IParticleProps) => {
        return new Particle({
            x: particle.x,
            y: particle.y,
            radius: particle.radius * 24,
            direction: Math.random() * PI2,
            velocity: particle.velocity * (randomBetween(5, 4)),
            friction: (particle.friction || .9) * 1.13,
            color: particle.color,
            explode: false
        })
    }

    const redrawParticles = () => {
        if (ctx) ctx.globalCompositeOperation = 'destination-out';
        if (ctx) ctx.fillStyle = 'hsla(0, 0%, 0%, 0.5)';
        if (ctx) ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        if (ctx) ctx.globalCompositeOperation = 'lighter';
        particles.forEach((particle, index) => {
            const fill = `hsl(${hue}, 80%, 50%)`;
            Math.random() < .01  
                ? 
                drawStar(
                    ctx ,
                    particle.x,
                    particle.y,
                    5,
                    particle.radius * 2.8,
                    particle.radius,
                    particle.color
                )
                :
                drawArc(ctx, particle.x, particle.y, particle.radius, particle.color ) 

            // update particle's properties
            if (particle.update) particle.update();

            // check if particle should explode and create new particles 
            if (Math.abs(particle.radius) <= 1 && particle.explode) {
                particle.explode = false;
                let children = 90;
                let sp: any
                while (children--) {
                    switch (type) {
                        case MANITU_ACTION_ANIMATION_TYPE.HURA:
                            sp = secondartHura(particle)
                            break
                        default:
                            sp = secondartExpliode(particle)
                    }
                    particles.push(sp);
                }
            }
            if (particle.radius <= 0.1 || particle.velocity <= 0.1) {
                particles.splice(particles.indexOf(particle), 1);
            }
        });
    }
    const loop = () => {
        redrawParticles()
        if (duration > 0) {
            --duration
            boom(type)
        }
        if (!!particles.length) {
            rafId = requestAnimationFrame(loop)
            setFrame(frame + 1)
        }
        else {
            cancelAnimationFrame(rafId)
            setFrame(0)
        }
        // console.log( frame )
    }
    const start = (
        _x: number,
        _y: number,
        _color: Color,
        _type: MANITU_ACTION_ANIMATION_TYPE,
        _duration: number = 0,
        _width: number = 0,
        _height: number = 0
    ) => {
        type = (_type)
        createParticles(_x, _y, _color, _duration, _type, _width, _height)
        loop()
    }
    useImperativeHandle(ref, () => ({ start }))

    return <div
        className="pe-matrix-chpock-container"
        style={{ width: props.width, height: props.width }}
    >
        <canvas
            width={props.width}
            height={props.height}
            ref={refCanvas}
        >
        </canvas>
    </div>
}
export default forwardRef(Chpock)

interface IParticleProps {
    direction?: number
    velocity: number
    decay?: number
    gravity?: number
    radius: number
    friction?: number
    x: number
    y: number
    color: Color
    explode?: boolean
    update?: () => void
}
class Particle {
    velX: number
    velY: number
    direction: number
    velocity: number
    friction: number = 0.9
    decay: number
    gravity: number
    radius: number
    x: number
    y: number
    explode: boolean
    color: Color

    constructor(options: IParticleProps) {
        this.direction = options.direction || 0
        this.velocity = options.velocity || 0
        this.radius = options.radius || 10
        this.color = options.color
        this.x = options.x || 0
        this.y = options.y || 0
        this.explode = !!options.explode

        this.velX = Math.cos(this.direction) * this.velocity;
        this.velY = Math.sin(this.direction) * this.velocity;

        this.friction = options.friction || .9;
        this.decay = randomBetween(90, 91) * 0.01;
        this.gravity = this.radius * 0.01;
    }

    public update() {
        this.x += this.velX;
        this.y += this.velY;

        this.velX *= this.friction;
        this.velY *= this.friction;
        this.velocity *= this.friction;

        // uncomment for a gravity like effect
        // this.velY += this.gravity;

        this.radius *= this.decay;
        this.gravity += 0.05;
    }
}


// draw and update every existing particle
const drawArc = (
    ctx: CanvasRenderingContext2D | null,
    cx: number,
    cy: number,
    radius: number,
    color: Color
) => {
    const PI2 = Math.PI * 2
    ctx!.beginPath()
    ctx!.fillStyle = color;
    ctx!.arc(cx, cy, radius, 0, PI2);
    ctx!.fill();
    ctx!.closePath();
}
/*
takes the x,y coordinates, the number of spikes, the inner and the outer radius of the spikes
*/
const drawStar = (
    ctx: CanvasRenderingContext2D | null,
    cx: number,
    cy: number,
    spikes: number = 5,
    r0: number,
    r1: number,
    color: Color
) => {
    var rot = Math.PI / 2 * 3, x = cx, y = cy, step = Math.PI / spikes

    ctx!.strokeStyle = color;
    ctx!.beginPath();
    ctx!.moveTo(cx, cy - r0)
    for (let i = 0; i < spikes; i++) {
        x = cx + Math.cos(rot) * r0;
        y = cy + Math.sin(rot) * r0;
        ctx!.lineTo(x, y)
        rot += step

        x = cx + Math.cos(rot) * r1;
        y = cy + Math.sin(rot) * r1;
        ctx!.lineTo(x, y)
        rot += step
    }
    ctx!.lineTo(cx, cy - r0)
    ctx!.stroke();
    ctx!.closePath();
}

const randomBetween = (min: number, max: number) => ~~(Math.random() * (max - min + 1)) + min;