import React, { useEffect, useState } from 'react' 
import { IBasicViewProps } from '@/libs/basic-view'
import { Route, Routes } from 'react-router'
import Folder from './Folder'
import CabinetLayout from './CabinetLayout'
import { Json, ID, IMenuItem } from "@/libs/interfaces/layouts"
import Layouts from "src/libs/layouts"
import { ErrorFolder } from './ErrorFolder'
import { actions } from '../../data/actions'
import { SET_SIGN_TYPE } from '../../data/actionTypes'

const EditorCabinetForm = (props : IBasicViewProps): JSX.Element => {
    
    const [routing, setRouting] = useState(Layouts().routing ) 
    useEffect(() => {
        actions(SET_SIGN_TYPE, null)
    }, [])
    const moveToFolder = ( routeData: any, folderId: ID ) => { 
        let _routing = {...routing}
        Object.keys(routing).forEach((menu : string) => {
            routing[menu].forEach((route: IMenuItem, i: number) => {
                const route1: IMenuItem | undefined = searchRoute( route, routeData, changeFolder, { folderId } )
                if(!route1)  return
                _routing[menu].splice(i, 1, route1) 
                setRouting({ ..._routing, menu: _routing[menu] }) 
            })
        }) 
        setRouting({ ...routing })
        onSaveMenus()
    }
    const changeFolder = ( route: IMenuItem, args: any ) => {
        return { ...route, folder: args.folderId }
    }
    const searchRoute = ( 
        parent : IMenuItem, 
        routeData: IMenuItem, 
        func: (parent:any, args: any) => IMenuItem, 
        args: any 
    ) => {
        if(
                parent.route        === routeData.route 
            &&  parent.title        === routeData.title
            &&  parent.module       === routeData.module
            &&  parent.icon         === routeData.icon
            &&  parent.component    === routeData.component
            &&  parent.data_type    === routeData.data_type
            &&  JSON.stringify(parent.extend_params) === JSON.stringify(routeData.extend_params)         
            &&  parent.target_id    === routeData.target_id         
            &&  parent.is_left      === routeData.is_left         
        )
        {
            parent = func(parent, args)
            //console.log( parent )
            return parent
        }
        if( Array.isArray( parent.children ) )
        {
            parent.children.forEach( ( child: IMenuItem ) => searchRoute( child, routeData, func, args ) )
        }
    }

    
    const onAddRoute = () =>
    {
        
        onSaveMenus()
    }
    const onRemoveRoute = () => { 

        onSaveMenus()
    }
    const onChangeRoute = ( ) => {
        
        onSaveMenus()
    }
    


    const onSaveMenus = () => {
        const json: any = { json: JSON.stringify( routing ) }
        console.log( json )
    }
   
    return <Routes>
        <Route element={ <CabinetLayout {...props} onAddRoute={onAddRoute}/> }>
            <Route
                index 
                element={ ( 
                    <Folder
                        moveToFolder={ moveToFolder }
                        onChangeRoute={ onChangeRoute }
                        onRemoveRoute={ onRemoveRoute }
                    />                                
                ) }
            />  
            <Route
                path={ "folder/:folderId" } 
                element={ (
                    <Folder 
                        moveToFolder={moveToFolder}
                        onChangeRoute={onChangeRoute}
                        onRemoveRoute={onRemoveRoute}
                    />  
                )}
            />
            <Route
                path={ "*" } 
                element={ <ErrorFolder /> }
            />
        </Route>                            
    </Routes>
} 

export default EditorCabinetForm