import getRandomImg from "src/assets/data/getRandomImg" 
import { IAds } from "../interfaces"

export const ads = () : IAds[] => {
    return [
        {
            id: 1,
            title: "Набираем участников",
            post_content: "Тренинг личного роста «Ребята» приглашает к совместному акту нарциссизма.",
            thumbnail: getRandomImg(),
            date: '2024-12-31T13:51:50.417Z',
            url:"",
            images: [],
            priority: 10
        },
        {
            id: 2,
            title: "Ищем партнеров для поездки на Краефест-2003",
            post_content: "Кто-нибудь собирается на Краефест из Тамбова? Давайте вместе?",
            thumbnail: getRandomImg(), 
            date: '2024-03-07T13:51:50.417Z',
            url:"",
            images: [],
            priority: 10
        },
        {
            id: 3,
            title: "Ищем опытного игротехника",
            post_content: "Мы делаем новую настолку про своё село Кукуево. Есть ли среди нас опытный игротехник, готовый нас проконсультировать и принять участие в разработке?",
            thumbnail: getRandomImg(),
            date: '2024-12-31T13:51:50.417Z',
            url:"",
            images: [],
            priority: 10
        }, 
        {
            id: 10,
            title: "Сегодня, 15.09 в 15.00 (мск) встречаемся в клубе \"Топос\".",
            post_content: "Спрашивают - кого приглашают? Отвечаем - друзей и товарищей🤝. ☕Посидим, обсудим, попьем чайку. Посиделки, одним словом - душевные❤ и полезные💪",
            thumbnail: "https://sun9-46.userapi.com/impg/9pCU3Y87wGxUoRIHBxwnbmNl--qCwLaZHjN4gg/e8ADy3tQudw.jpg?size=952x537&quality=96&sign=8bdeb6e1e710af696ca6881484dff97a&c_uniq_tag=Wi1W3eIpDQnsA11qIsZ7Rt2gU5qfaYDuE1TXjJluEGU&type=album",
            date: '2024-12-31T13:51:50.417Z',
            url:"https://vk.com/mytopos?w=wall-191335239_2073",
            images: [],
            priority: 10
        },
        {
            id: 11,
            title: "ЛЕТО С КРАЕФЕСТОМ - 2023",
            post_content: "Напоминаем, что в субботу, 2 сентября в 10.00 (мск) состоится онлайн встреча наставников и организаторов (zoom). Ссылка появится в чате наставников (ТГ), в пятницу, после 12.00 (Мск).",
            thumbnail: "https://sun9-8.userapi.com/impg/jAVA51nZymYkh7ElEZ__nCyFB3STyJlO0_87Kw/rR0VKM-YzMc.jpg?size=564x564&quality=96&sign=5bb20d6501e6b38522172ce2d0f65ca2&c_uniq_tag=fwyDAxx71bAySEHRmItTZp94RGLpzix504RqHUYqT0k&type=album",
            date: '2024-12-31T13:51:50.417Z',
            url:"https://vk.com/mytopos?w=wall-191335239_2063",
            images: [],
            priority: 10
        },
        {
            id: 7,
            title: "Екатеринбург. Библиотека Белинского",
            post_content: "6 октября 2023 года в конференц-зале областной библиотеки им.В.Г.Беллинского пройдет «Вечер памяти Владислава Петровича Крапивина». В этот день мы будем вспоминать Командора и читать любимые отрывки из его произведений. Приглашаем всех. Вход свободный.",
            thumbnail: "https://sun9-28.userapi.com/c1937/u7194717/92042934/x_268f3715.jpg",
            date: '2024-10-06T13:51:50.417Z',
            url:"https://vk.com/mytopos?w=wall-191335239_2107",
            images: [],
            priority: 10
        }, 
        {
            id: 8,
            title: "",
            post_content: "стартует наш первый игротехнический модуль \"Настольная дидактика\карточные игры\лабиринт\"",
            thumbnail: "https://sun9-31.userapi.com/impg/_MhZ-E133VNiYoVZ2Ym_VzwJ_rALx0IjT7G61g/VH3i1g22Geo.jpg?size=1008x568&quality=96&sign=231f523519cb56ab6ba3aab616a658a4&c_uniq_tag=y3jbYuWO5C4RLNRUwieV8FgBDfjb5bIWhQaWHRH7w-4&type=album",
            date: '2024-10-02T13:51:50.417Z',
            url:"https://vk.com/mytopos?w=wall-191335239_2103",
            images: [ 
                "https://sun9-7.userapi.com/impg/nqywLKyX0SQV33Hmp3kx5cikVC3UKAwgzLGgxQ/ykJcv0GeGeA.jpg?size=2560x1698&quality=95&sign=a2ec8f31bb0254a7791e075556897d32&type=album",
            ],
            priority: 10
        }, 
        {
            id: 4,
            title: "Проект «Феникс» собирает друзей для участия в конкурсе туризма",
            post_content: "Ищем партнёров в Набережных Челнах, готовых участвовать и вообще...",
            thumbnail: getRandomImg(),
            date: '2024-06-11T13:51:50.417Z',
            url:"",
            images: [],
            priority: 10
        },
        {
            id: 5,
            title: "Ищем партнеров для поездки на Краефест-2003",
            post_content: "Кто-нибудь собирается на Краефест из Тамбова? Давайте вместе?",
            thumbnail: getRandomImg(),
            date: '2023-12-22T13:51:50.417Z',
            url:"",
            images: [],
            priority: 10
        },
        {
            id: 6,
            title: "Ищем опытного игротехника",
            post_content: "Мы делаем новую настолку про своё село Кукуево. Есть ли среди нас опытный игротехник, готовый нас проконсультировать и принять участие в разработке?",
            thumbnail: getRandomImg(),
            date: '2024-02-21T13:51:50.417Z',
            url:"",
            images: [],
            priority: 10
        }, 
    ]
}