import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { __ } from "@/libs/utilities"
import { get, exec_route } from "src/libs/layouts"
import { IRoutingData } from "@/libs/interfaces/layouts"

type FreeMenuProps = {
  menu_id: string,

}
class FreeMenu extends Component<FreeMenuProps> {
  render() {
    const menuData = exec_route(get(this.props.menu_id))
    if (!this.props.menu_id) {
      return (
        <div className="alert alert-danger">
          Empty free menu data in sector «routes» in layouts
        </div>
      )
    }
    if (!menuData) {
      return (
        <div className="alert alert-danger">
          No contents in sector «{this.props.menu_id}» in layouts
        </div>
      )
    }
    const menu = menuData.map((e: IRoutingData, i: number) => (
      <NavLink
        to={e.route}
        className="free-menu-item" 
        key={e.route + i}
      >
        <span>{__(e.title)}</span>
      </NavLink>
    )) 
    return (
      <div className="free-menu-container">
        {menu}
      </div>
    )
  }
}
export default FreeMenu
