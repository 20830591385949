import { IPost } from "@/libs/interfaces/layouts"

export const friends = (): IPost[] => {
    return [
        {
            id: 1,
            title: "Топос-лаборатория",
            post_content: ""
        },
        {
            id: 2,
            title: "КраеФест - межрегиональный и всероссийский фестиваль краеведческих объединений",
            post_content: ""
        },
        {
            id: 3,
            title: "Вопросы краефест",
            post_content: ""
        },
        {
            id: 4,
            title: "Трекеры марафона 2023",
            post_content: ""
        },
        {
            id: 5,
            title: "Трекеры марафона 2023",
            post_content: ""
        },
    ]
}