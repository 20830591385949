import { FABULA_CHARACTERY, FABULA_MODE, IFabulaMediaType, IPEStory, PE_STORY_PHASE } from "../interfaces"

export const PEFabulaStory = ()  : IPEStory => {
    const story : IPEStory = {
        id: "1",
        title: "Барминград. Чёрный туман",
        post_content: "<p>2045 год. Международная лунная база Барминград.</p><p>На строительстве стационарного поселения в Барминграде и соседней станции Линкольнвилль происходит ряд происшествий, которые дают повод предположить версию о вмешательстве чей-то злонамеренной воли...</p>",
        phase: PE_STORY_PHASE.NO_START,
        characteries: [
            {
                id: "force",
                title: "Force",
                post_content: "",
                thumbnail: "",
                color: "#975555",
                type: FABULA_CHARACTERY.FORCE
            },
            {
                id: "luck",
                title: "Luck",
                post_content: "",
                thumbnail: "",
                color: "#5b9a5b",
                type: FABULA_CHARACTERY.LUCK
            },
        ],
        intro:[

        ], 
        NPC: [],
        speech: [],
        users: [],
        duration: 1000 * 60 * 30 * 4,
        stepDuration: 1000 * 60 * 30,
        isFullness: true,
        mode: FABULA_MODE.QUEST,
        thumbnail: "/assets/img/fabula/Lunar/lunarMain1.jpg",
        heros: [
            {
                id: "1",
                display_name: "Агент международной безопастности",
                avatar: "/assets/img/fabula/Bear/agentAvatar.jpg",
                quest:[],
                type: "Person",
                roles: [],
                luck: 3,
                charm: 0,
                dexterity: 0,
                force: 0,
                health: 100,
                tileID: "4",
                mediaID: "411",
                staff: [],
                top: 90,
                left: 50,
                z: 200,
                width: 50,
                height: 45,
            }
        ],
        staff : [],
        background: "/assets/img/fabula/Lunar/lunarMap.jpg",
        map: {
            id: "1",
            title: "Чёрный туман",
            post_content: "",
            width: 1500,
            height: 1500,
            tileX: 10,
            tileY: 10,
            tiles: [
                {
                    id: "16",
                    title: "В глубине кратера",
                    post_content: "",
                    enabled: false,
                    x: 2,
                    y: 7,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar20.jpg",
                    episodes: [

                    ]
                },
                {
                    id: "15",
                    title: "Кратер Мохано",
                    post_content: "",
                    x: 1,
                    y: 7,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar17.png",
                    episodes: [

                    ]
                },
                {
                    id: "14",
                    title: "Обрыв кратера Мохано",
                    post_content: "Странный лифт, сооруженный, по-видимому, американцами. В округе -- никаких следов.",
                    x: 1,
                    y: 6,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar_14.png",
                    episodes: [
                        {
                            id: "141",
                            title: "Кабина лифта",
                            post_content: "",
                            x: 45,
                            y: 48,
                            tile_id: "1",
                            media: [                                
                                {
                                    id: "1411",
                                    title: "Кабина лифта",
                                    post_content: "",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar13.png",
                                    speech:[]
                                }
                            ],
                            visible: true
                        },
                    ]
                },
                {
                    id: "13",
                    title: "Восточный склон в кратер Мохино",
                    post_content: "",
                    x: 2,
                    y: 6,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar12.png",
                    episodes: []
                },
                {
                    id: "12",
                    title: "Плато Мохино",
                    post_content: "",
                    x: 3,
                    y: 6,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar11.png",
                    episodes: []
                },
                {
                    id: "11",
                    title: "Северо-западный склон кратера Тихо Браге",
                    post_content: "",
                    x: 4,
                    y: 6,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar4.png",
                    episodes: [
                        {
                            id: "11",
                            title: "Дальний ангар",
                            post_content: "",
                            x: 22,
                            y: 44,
                            tile_id: "1",
                            media: [                                
                                {
                                    id: "111",
                                    title: "Строительный ангар",
                                    post_content: "",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar111.jpg",
                                    speech:[],
                                }
                            ],
                            visible: true
                        },
                        {
                            id: "12",
                            title: "База",
                            post_content: "",
                            x: 60,
                            y: 52,
                            tile_id: "1",
                            media: [

                            ],
                            visible: true
                        },
                        {
                            id: "13",
                            title: "База 2",
                            post_content: "",
                            x: 48,
                            y: 40,
                            tile_id: "1",
                            media: [

                            ],
                            visible: true
                        },
                        {
                            id: "14",
                            title: "Сломанный добывающий краулер",
                            post_content: "",
                            x: 12,
                            y: 19,
                            tile_id: "1",
                            media: [
                                {
                                    id: "141",
                                    title: "Сломанный добывающий краулер",
                                    post_content: "Странная чёрная жидкость. Не испаряется.",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar141.png",
                                    speech:[],
                                }
                            ],
                            visible: true
                        },
                    ],
                },
                {
                    id: "1",
                    title: "Северный сквер Гагарина",
                    post_content: "",
                    x: 5,
                    y: 6,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar9.png",
                    episodes: [
                        {
                            id: "11",
                            title: "Северный сквер Гагарина, дом 1",
                            post_content: "",
                            x: 27,
                            y: 36,
                            tile_id: "1",
                            media: [
                                {
                                    id: "110",
                                    title: "Центральная площадь",
                                    post_content: "",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar110.jpg",
                                    speech:[],
                                }
                            ],
                        },
                        {
                            id: "12",
                            title: "Северный сквер Гагарина, дом 2",
                            post_content: "",
                            x: 69,
                            y: 20,
                            tile_id: "1",
                            media: [ 
                                {
                                    id: "121",
                                    title: "Центральная площадь",
                                    post_content: "",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar_111.jpg",
                                    speech:[],
                                },
                            ],
                        },
                    ]
                },
                {
                    id: "2",
                    title: "Корпус «Барминград-6»",
                    post_content: "",
                    x: 5,
                    y: 7,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar5.jpg",
                    episodes: [
                        {
                            id: "21",
                            title: "Капитанский мостик",
                            post_content: "",
                            x: 61,
                            y: 29,
                            tile_id: "1",
                            media: [
                                {
                                    id: "440",
                                    title: "Мостик",
                                    post_content: "",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar4_01.jpg",
                                    speech:[],
                                }
                            ],
                            visible: true
                        },
                    ]
                },
                {
                    id: "3",
                    title: "Корпус «Барминград-1»",
                    post_content: "",
                    x: 7,
                    y: 6,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar7.png",
                    episodes: []
                },
                {
                    id: "4",
                    title: "Корпус «Барминград-2»",
                    post_content: "",
                    x: 6,
                    y: 6,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar8.png",
                    episodes: [
                        {
                            id: "41",
                            title: "Второй уровень. Кафе пилотов.",
                            post_content: "",
                            x: 22,
                            y: 42,
                            tile_id: "4",
                            media: [                                
                                {
                                    id: "440",
                                    title: "Кафе",
                                    post_content: "",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar8_0.jpg",
                                    speech:[],
                                    media : [
                                        {
                                            id: "441",
                                            title: "Разговор в кафе",
                                            post_content: "<p> -- Здравствуй.</p><p> -- Здравствуй.</p><p> -- Сияешь&</p><p> -- Сияю.</p>",
                                            type: IFabulaMediaType.IMAGE,
                                            x: 13,
                                            y: 58,
                                            thumbnail: "/assets/img/fabula/Lunar/lunar8_21.jpg",
                                            speech:[]
                                        },
                                        {
                                            id: "442",
                                            title: "Разговор в кафе 2",
                                            post_content: "<p> -- Здравствуй.</p><p> -- Здравствуй.</p><p> -- Сияешь&</p><p> -- Сияю.</p>",
                                            type: IFabulaMediaType.IMAGE,
                                            x: 65,
                                            y: 54,
                                            thumbnail: "/assets/img/fabula/Lunar/lunar8_3.jpg",
                                            speech:[]
                                        },
                                        {
                                            id: "443",
                                            title: "Разговор в кафе 3",
                                            post_content: "<p> -- Который час?</p><p> -- Двенадцатый примерно...</p>",
                                            type: IFabulaMediaType.IMAGE,
                                            x: 80,
                                            y: 57,
                                            thumbnail: "/assets/img/fabula/Lunar/lunar8_2.jpg",
                                            speech:[]
                                        },     
                                    ]
                                }, 
                            ],
                            visible: true
                        },
                        {
                            id: "42",
                            title: "Склад",
                            post_content: "",
                            x: 52,
                            y: 44,
                            tile_id: "4",
                            media: [
                                {
                                    id: "440",
                                    title: "Складской ангар",
                                    post_content: "",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar8_421.png",
                                    speech:[],
                                    media: [
                                        {
                                            id: "4401",
                                            title: "Ангар 12А",
                                            post_content: "",
                                            type: IFabulaMediaType.IMAGE,
                                            x: 41,
                                            y: 55,
                                            thumbnail: "/assets/img/fabula/Lunar/lunar8_30.jpg",
                                            speech:[],
                                            media: [
                                                {
                                                    id: "44011",
                                                    title: "Встреча с вахтенным купором",
                                                    post_content: "",
                                                    type: IFabulaMediaType.IMAGE,
                                                    x: 77,
                                                    y: 65,
                                                    thumbnail: "/assets/img/fabula/Lunar/lunar8_33.jpg",
                                                    speech:[],
                                                },
                                                
                                            ]
                                        },
                                        {
                                            id: "4402",
                                            title: "Ангар 12Б",
                                            post_content: "",
                                            type: IFabulaMediaType.IMAGE,
                                            x: 2,
                                            y:60,
                                            thumbnail: "/assets/img/fabula/Lunar/lunar444001.jpg",
                                            speech:[],
                                            media: [
                                                {
                                                    id: "44021",
                                                    title: "Встреча с младшим помощником батлера",
                                                    post_content: "",
                                                    type: IFabulaMediaType.IMAGE,
                                                    x:20,
                                                    y:70,
                                                    thumbnail: "/assets/img/fabula/Lunar/lunar8_34.jpg",
                                                    speech:[],
                                                },
                                                {
                                                    id: "44022",
                                                    title: "Ангар 12Б",
                                                    post_content: "",
                                                    type: IFabulaMediaType.IMAGE,
                                                    x: 51.5,
                                                    y: 64,
                                                    thumbnail: "/assets/img/fabula/Lunar/lunar44022.jpg",
                                                    speech:[],
                                                    media: [
                                                        {
                                                            id: "440221",
                                                            title: "Бак",
                                                            post_content: "",
                                                            type: IFabulaMediaType.IMAGE,
                                                            x:20,
                                                            y:70,
                                                            thumbnail: "/assets/img/fabula/Lunar/lunar440221.jpg",
                                                            speech:[],
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            id: "4403",
                                            title: "Рабочая смена",
                                            post_content: "",
                                            type: IFabulaMediaType.IMAGE,
                                            x: 98,
                                            y:60,
                                            thumbnail: "/assets/img/fabula/Lunar/lunar8_32.jpg",
                                            media: [ ],
                                            speech:[]
                                        },
                                    ]
                                }
                            ],
                            visible: true
                        },
                        {
                            id: "43",
                            title: "Жилой блок. Кают-компания",
                            post_content: "",
                            x: 95,
                            y: 64,
                            tile_id: "4",
                            media: [
                                {
                                    id: "430",
                                    title: "Кают-компания",
                                    post_content: "",
                                    type: IFabulaMediaType.IMAGE,
                                    x:0,
                                    y:0,
                                    thumbnail: "/assets/img/fabula/Lunar/lunar430_1.jpg",
                                    speech:[],
                                }  
                            ],
                            visible: true
                        },
                    ]
                },
                {
                    id: "5",
                    title: "Корпус «Барминград-5»",
                    post_content: "",
                    x: 6,
                    y: 7,
                    enabled:true,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar6.png",
                    episodes: []
                },
                {
                    id: "51",
                    title: "Корпус «Барминград-Центральный",
                    post_content: "Космодром. Центральный пункт орбитальной связи.",
                    x: 6,
                    y: 8,
                    enabled:true,
                    map_id: "1",                    
                    thumbnail: "/assets/img/fabula/Lunar/lunar14.png",
                    episodes: []
                },
                {
                    id: "17",
                    title: "Линкольн-гроув",
                    post_content: "",
                    enabled: true,
                    x: 1,
                    y: 1,
                    map_id: "1",
                    thumbnail: "/assets/img/fabula/Lunar/lunar15.png",
                    episodes: [

                    ]
                },
            ]
        },
    }
    return story
}