import React from 'react' 

const HoneycombsLabel = (props: any) : JSX.Element | null => { 
      return props.honeycombs
          ?
          <div className={props.className ? props.className : `honeycombs-label`}>
              { props.honeycombs?.title }
          </div> 
          :
          null 
      
  } 
  
  export default HoneycombsLabel