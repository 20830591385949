import { __ } from "@/libs/utilities"

interface IProps {
    item: any
    view: any
    i: number
}
const ModuleView = ({item, view, i, ...props}: IProps) :JSX.Element => {
    return <div className="p-2 border-bottom-1 border-secondary-light"  >
        <div className="title">
            { __( view?.title ) } 
        </div>  
        <div className="descr text-force-contrast mt-1">
            { __( view?.description )  } 
        </div>   
    </div>
} 

export default ModuleView