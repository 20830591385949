import React from 'react' 
import BasicState from "@/libs/basic-view"     
import SettingsForm from './festAdmin/SettingsForm'

class SettingsAdminView extends BasicState {
    props: any
    addRender = () => {  
        return <div className="container mb-5"> 
            <SettingsForm { ...this.props } /> 
        </div> 
    }
} 

export default SettingsAdminView