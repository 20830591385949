import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import useScreenSize from "../../hooks/useScreenSize"
import LoginRegisterScreen from "./LoginRegisterScreen"
import LoginRegisterMobile from "./LoginRegisterMobile"

/* Protopia Ecosystem component */
export interface ILoginRegisterFormProps {

}
const LoginRegisterForm = (props: ILoginRegisterFormProps): JSX.Element => {
    const {width} = useScreenSize()
    return width > WINDOW_WIDTH.TABLET
        ?
        <LoginRegisterScreen />
        :
        <LoginRegisterMobile />
}
export default LoginRegisterForm