import { IState, useMainStore } from "src/settings/zustand"
import { initArea } from '../../utilities/getWidget'
import LayoutLoginedUser from './LayoutLoginedUser'
import LayoutUnloginned from './LayoutUnloginned'

const LayoutUser = (props: ILayoutUserProps) => {
    return (
		<>
			{
				initArea(
					"before-profile",
					{ ...props },
                    
				)
			}
            <LayuotUserPanel {...props}/>
        </>
    )
}
 

export default LayoutUser

export interface ILayoutUserProps {
    isOpen: boolean
}

const LayuotUserPanel = (props: ILayoutUserProps) => { 
    const user = useMainStore(( state: IState ) => state.user)
    return user?.id ? <LayoutLoginedUser {...props}/> : <LayoutUnloginned {...props}/>
}