
import { create } from "zustand";
import { SIGN_TYPE } from "./interfaces";
import { ID, ILayoutData } from "@/libs/interfaces/layouts";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import Layouts, { modules } from "@/libs/layouts";

export const useAdminStore: any = create<IAdminStore>((set: any, get: any ) => ({
    signType: SIGN_TYPE,
    setSignType: (signType:SIGN_TYPE) => set( ( ) => ({ signType }) ),
    bulks: [],
    setBulks: (bulks: IBulk[]) => set( ( ) => ({ bulks }) ),
    items: [],
    setItems: ( items: any[] ) => set(() => ({ items })),
    data_type: "",
    setData_type: ( data_type: string ) => set(() => ({ data_type })),
    offset: 0,
    setOffset :(offset: number) => set( () => {
        // console.log( offset )
        return {offset : offset}
    }),
    paging: "",
    setPaging: (paging: string) => set( ( ) =>  ({paging})),
    currentDictModule: ""
}))

export interface IAdminStore {
    signType: typeof SIGN_TYPE
    setSignType: (signType:SIGN_TYPE) => void
    bulks: IBulk[]
    setBulks: (bulks: IBulk[]) => void
    items: any[]
    setItems: ( items: any[] ) => void
    data_type: string
    setData_type: ( data_type: string ) => void
    offset: number
    setOffset: (offset: number) => void
    paging: string
    setPaging: (paging: string) => void,
    currentDictModule : string
}
export interface IBulk {
    checked: boolean,
    id: ID
    data_type: string
}

export interface IAdminMenuStore {
    currentRouteID: ID
    currentMenuGroup: string
    currentViewsSelected: string[]
    layoutsClone: ILayoutData
}

export const useAdminMenuStore: any = create( devtools( persist<IAdminMenuStore>( (set: any, get: any ) => ({
    currentRouteID: "",
    currentMenuGroup: "main_menu",
    currentViewsSelected: Object.keys( modules() ) || [],
    layoutsClone: {...Layouts()}
}),
{
    name: 'pe-admin-menu-storage', 
    storage: createJSONStorage( () => localStorage ), 
} 
)))