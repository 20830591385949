
import { initArea } from "src/libs/utilities"
import "../assets/css/style.scss"
import CircleMonitor from "./CircleMonitor"
const MainLudensPanel = () : JSX.Element => {
    return <div className="ludens-panel-container">
        {
            initArea(
                "main-ludens-panel", 
                { } ,
                <CircleMonitor />
              )
        } 
    </div> 
} 

export default MainLudensPanel