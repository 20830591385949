import { Tab, Tabs } from "@blueprintjs/core"
import { useParams } from "react-router"
import Feed from "../core/Feed"
import FeedDataTypeView from "../FeedDataTypeView"
import { ID } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"


/* Protopia Ecosystem component */
export interface ILeftLayoutProps {
    external_settings: any
    curNavId: ID
    setCurNavId : (curNavId: ID) => void
}
export const LeftLayout = ( props: ILeftLayoutProps ): JSX.Element | null => {
    const params = useParams()
    const getPaging = ( feed: any ) =>
    { 
        switch(feed.filter_type)
        {
            case "taxonomies":
                return `taxonomies :{ tax_name : "${feed.field}", term_ids : [ ${params.id} ] }`
            case "meta":
                return `metas :[{key :"${feed.field}", value :[ "${params.id}" ] }]`
            default: 
                switch(feed.field)
                {
                    case "children":
                        return `parent: "${params.id}"`
                    default:
                        return ` ${feed.field}: "${params.id}" ` 
                }
        }
    }
    const _tabs = Array.isArray( props.external_settings?.feeds)
        ?
        props.external_settings.feeds.map((feed: any, i: number) =>
        {
            return <Tab  
                key={i} 
                title={ <span> {__(feed.name[1])} </span> }
                id={"tab" + i} 
                panel={
                    <div> 
                        <Feed 
                            component={ FeedDataTypeView }
                            data_type={feed.component}
                            offset={0}
                            count={310}
                            is_hide_pagi={false}
                            class_name={` row `}
                            paging={ getPaging(feed)}
                            params={{ 
                                cardClass: " py-3 "
                            }}
                        />
                    </div>
                }
            />
        })       
        :
        null

        return <Tabs selectedTabId={ props.curNavId } onChange={ curNavId => props.setCurNavId( curNavId) } >
            {_tabs}
        </Tabs> 
}