import { useEffect, useState } from "react" 
import { useParams } from "react-router"

interface ITestTimerProps { 
    duration: number
    onBreak: () => void
}
 
 
const TestTimer = (props: ITestTimerProps): JSX.Element => { 
    const params = useParams()  
    
    const [count, setCount] = useState(0); 
    const [incr, setIncr] = useState(1); 
  
    useEffect(() => { 
        setIncr( 100 / props.duration)
        //Implementing the setInterval method 
        const interval = setInterval(() => {  

            if(count<100)
                setCount(count + incr)
            else 
                props.onBreak() 
        }, 1000); 
  
        //Clearing the interval 
        return () => clearInterval(interval); 
    }, [count]); 
    
    useEffect(() => {
        setCount(0)
    }, [ params.question_id ])

     

    return <div className="pe-matching-timer-container">
        <div 
            className="pe-matching-timer" 
            style={{width : `${count}%`}}
        /> 
    </div>
}  
export default TestTimer