

import React from 'react';
import ReactDOM from 'react-dom/client'; 
import ProtopiaEcosystem from './ProtopiaEcosystem';
import reportWebVitals from './reportWebVitals';
import config from "./config/config.json"
import layoutConfig from "./config/layouts.json"
import dictionary from "./config/ru-RU.json"
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <ProtopiaEcosystem       
        config={config}
        layoutConfig={layoutConfig}
        dictionary={dictionary}  
      />
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render( <RouterProvider router={router} /> );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 
