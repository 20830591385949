import { IAcademicDiscipline } from "@/modules/pe-game-science-module/data/interfaces"
import { IPETest, PETestQuestion } from "@/modules/pe-tests-module/data/interfaces"
import { IUser } from "src/settings/zustand"
import { Email, ID, URL, HTML, IMenuItem, POST_STATUS, IPost, Role, Color, Icon } from "src/libs/interfaces/layouts"


export interface IManituCell {
    id: ID
    rowID : number 
    i: number
    defense: number
    isBroken?: boolean
}

export interface IManituRow {
    id: number
    cells: IManituCell[]
}

export interface IManituMatrix {
    rows: IManituRow[],
    cells: IManituCell[]
    user: IUser
    organoids: IOrganoid[]
}

export enum MATRIX_MODE {
    MINE="MINE",
    PARTNER="PARTNER",
    ENEMY="ENEMY"
}

export enum MANA_TYPE {
    MANA="MANA",
    DEFENCE="DEFENSE",
    RICH="RICH",
    IMPACT="IMPACT"
}

export enum MANITU_ENERGY_TYPE {
    MANA="MANA",
    DEFENCE="DEFENSE",
    RICH="RICH",
    IMPACT="IMPACT"
} 

export interface IManituEnergyPrice {
    type: MANITU_ENERGY_TYPE
    nominal: number
}

export interface IMana {
    type: MANA_TYPE
    count: number
}

export interface IManituAction {
    id: ID
    type: IManituActionType
    count: number
    time?: Date
    rowOrder: number
    cellOrder: number
    opponent: IUser
    recipient: IUser
}

export enum MANITU_ACTION_DISTINATION {
    SELF="SELF",
    OPPONENT="OPPONENT"
}
export enum MANITU_ACTION_ANIMATION_TYPE {
    EXPLODE="EXPLODE",
    STORM="STORM",
    BOLT="BOLT",
    HURA="HURA"
}
export interface IManituActionType extends IPost {
    price: IManituEnergyPrice[]
    distination: MANITU_ACTION_DISTINATION
    instruction: string
    icon: Icon
    color: Color
    animationType: MANITU_ACTION_ANIMATION_TYPE
    animationDuration?: number
}

export interface IUserMana {
    id:ID
    user: IUser
    [MANA_TYPE.MANA]: IMana
    [MANA_TYPE.DEFENCE]: IMana
    [MANA_TYPE.RICH]: IMana
    [MANA_TYPE.IMPACT]: IMana
    actions?: IManituAction[] 
} 

export interface ICellMana extends IUserMana { 
    cell: IManituCell
}

export enum ORGANOID_TYPE {
    DEFENSE="DEFENSE",
    ENERGY_KINESIS="ENERGY_KINESIS", //https://superpowers.fandom.com/ru/wiki/Энергокинез
    CHANSE="CHANSE",
    CHEMISTRY="CHEMISTRY",
    COMMUNICATION="COMMUNICATION",
    EMPATY="EMPATY",
    TELE_KINESIS="TELE_KINESIS"
}
export interface IOrganoid extends IPost {
    type: IOrganoidType
    power: number
    cells: cellVar[]
}

export type cellVar = {
    x: number
    y:number
}


export interface IOrganoidType extends IPost {
    type: ORGANOID_TYPE
    icon: Icon
    min: number
    max: number
    fill: Color
}

export interface ICellDescriptionProps {
    item: IManituCell
    actions: IManituAction[] 
    organoid: IOrganoid
}

export interface IPixel extends IPost {
    type: PIXEL_TYPES,
    url?: URL
    test?: IPETest
    question?: PETestQuestion
    hardness: number | 0 | 1 | 2 | 3 | 4 |5 | 6 | 7 | 8 | 9,
    price: number,
    pe_academic_discipline: IAcademicDiscipline[]
}
export enum PIXEL_TYPES {
    TEST="TEST",
    QUESTION="QUESTION",
    YOUTUBE="YOUTUBE", 
    OPEN_PROBLEM="OPEN_PROBLEM", 
}