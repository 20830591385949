import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { Button, Collapse, Icon } from "@blueprintjs/core"
import { IAdminMenuStore, useAdminMenuStore } from "../../data/store"
import {default as UUID} from "node-uuid";
import { useNavigate } from "react-router";
import { SyntheticEvent, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

 
interface IProps { 
    children: any
    isMayVisible?: boolean
    isClosed?: boolean
    isNavigate?: boolean
    item: IMenuItem
    parentRoute: string
    i: number
    level: number
    onVisibleChange?: (isHidden:boolean, item : IMenuItem ) => void
}
const MenuItemBtn = (props : IProps) : JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(!props.isClosed)
    const navigate = useNavigate() 
    const currentRouteID: ID = useAdminMenuStore( ( state:IAdminMenuStore ) => state.currentRouteID )
    const select = () => { 
        useAdminMenuStore.setState({ currentRouteID : props.item.id })
        if(props.isNavigate)
            navigate( props.item.id.toString() )
    }
    const onOpen = (evt: SyntheticEvent ) => {
        evt.stopPropagation()
        evt.preventDefault()
        setIsOpen(!isOpen)
    }
    return <div className="flex-grow-100 w-100">
        <div 
            className={`
                menu-elem  
                level_${ props.level } 
                ${props.item.is_hidden ? "opacity_5" : "" } 
                text-force-contrast 
                ${currentRouteID === props.item.id ? "active" : ""}
            `}
            onClick={ select }
        >
            <div className="d-flex w-100">
                {
                    props.isMayVisible && <div>
                        <VisibleBtn 
                            onChange={ (isHidden:boolean) => {
                                if( props.onVisibleChange )
                                    props.onVisibleChange( isHidden, props.item) 
                            } }
                            isHidden={props.item.is_hidden}
                        />
                    </div>
                }
                
                <div className="menu-edit-item-btn " title={ __(props.item.title) }>
                    <div className="title text-overflow text-left">
                        { __(props.item.title) }
                    </div>
                    <Collapse isOpen={currentRouteID === props.item.id} className="p-0 position-relative">
                        <div className="descr w-100 text-left">
                            {__(props.item.description)}
                        </div>
                        <div className="w-100 text-left">
                            { props.parentRoute + "/" + props.item.route }
                        </div>
                    </Collapse>
                    
                </div>
                <div className="flex-centered flex-column">
                    {  props.children }
                    {
                        !!props.item.children?.length 
                        && 
                        <Button minimal onClick={onOpen}>
                            <Icon icon={isOpen ? "caret-up" : "caret-down"} />
                        </Button>
                    }   
                </div>
            </div>
        </div>
        <Collapse isOpen={isOpen} className="p-0 position-relative">
            {
                /* 
                props.item.children?.length 
                &&
                <Droppable droppableId={`${props.item.id}`}>
                    {
                        (provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    props.item.children?.map((item, index) => (
                                        <Draggable draggableId={item.id} index={index}>
                                            { (provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        //snapshot={snapshot}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="p-3 border border-secondary display-5">
                                                            { index }
                                                        </div>  
                                                    </div>
                                                );
                                            } }
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )
                    }
                </Droppable>
                */
                props.item.children?.map((child: IMenuItem, i) => {
                    const item = { ...child, id: child.id || UUID.v4() }
                    return <MenuItemBtn 
                        item={item}
                        i={i} 
                        parentRoute={ props.parentRoute + "/" + props.item.route }
                        key={child.id} 
                        level={props.level+1} 
                        onVisibleChange={(isHidden: boolean)=> {
                            console.log( isHidden )
                            if( props.onVisibleChange )
                                props.onVisibleChange( isHidden, item )
                        } } 
                    > 
                    </MenuItemBtn>
               
                }) 
                
            }
        </Collapse>  
    </div>
} 
export default MenuItemBtn

export const VisibleBtn = (props:any) : JSX.Element =>  {
    const onClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault()
        evt.stopPropagation()
        console.log( !props.isHidden )
        props.onChange( !props.isHidden )
    }
    return <Button minimal onClick={onClick} className="mr-2">
        <Icon icon={props.isHidden ? "eye-off" : "eye-open"} />
    </Button>
}