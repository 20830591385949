import { Callout, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { __, shuffle } from "src/libs/utilities"
import { PETestQuestion, PETestQuestionAnswer } from "../../data/interfaces"
import { ITestProps } from "./SingleTest"
import Answer from "./Answer"
import { ID } from "@/libs/interfaces/layouts"
import { useTestsStore } from "../../data/store"

let timeout : any = -1
const Question = ({ item }: ITestProps) : JSX.Element => {
    const params = useParams() 
    const [question, setQuestion]= useState<PETestQuestion>({} as PETestQuestion)
    const [choosedId, setChoosedId]= useState<ID>( -1 )
    const [isRight, setIsRight]= useState<ID>( -1 )
    const [isLocked, setIsLocked]= useState<boolean>( false )

    useEffect(() => { 
        const question = item.questions.filter(q => q.id.toString() === params.question_id)[0] 
        setQuestion( {...question, answers: shuffle(question.answers)} )
        setChoosedId(-1)
        setIsRight(-1)
        setIsLocked(false)
    }, [params.question_id])

    useEffect(() => { 
        return () => clearTimeout( timeout )
    }, [])

    const onChoose = ( answer: PETestQuestionAnswer ) => { 
        if(item.is_show_answer) { 
            setIsRight(question.right_answer_id)
        } 
        setChoosedId( answer.id ) 
        setIsLocked( item.is_show_answer )
        const results = [...useTestsStore.getState().results]
        results[ useTestsStore.getState().currentStep - 1 ] = answer.id
        useTestsStore.setState({ results })
        if(item.is_timed) {
            timeout = setTimeout(() => {
                const next = useTestsStore.getState().currentStep + 1
                useTestsStore.setState({ 
                    currentStep: next
                })
            }, 800)
            
        } 
    }
    const _answers = question.answers?.map(answer => {
        return <Answer
            key={answer.id} 
            isRight={isRight === answer.id}
            item={answer}
            isSelect={ answer.id === choosedId }
            onChange={onChoose}
        />
    }) 
    return question.id 
        ?
        <div className="pe-matching-single flex-centered flex-column h-100">
            <div className="pe-matching-content short-container" > 
                { 
                    question.thumbnail &&
                    <div className="pe-matching-media">
                        <img src={question.thumbnail} className="pe-matching-image" alt="" />
                    </div>
                }
                <div className="pe-matching-title">
                    {question.title} 
                </div>
                <div className="pe-matching-descr">
                    {question.post_content}
                </div>
                <div 
                    className={`pe-matching-answers
                        ${!!question.answers?.filter(a => a.thumbnail).length 
                            ? 
                            "flex-row" 
                            : 
                            "flex-column"
                        }
                        ${ isLocked ? " untouchble opacity_75" : "" }
                    `}>
                    {_answers}
                </div>
            </div>
        </div>
        :
        <Callout intent={Intent.DANGER} className="p-5">
            { __("No element exists")}
        </Callout> 
} 
export default Question