import { IState, useMainStore } from "src/settings/zustand"
import Matrix from "./matrix" 
import { MATRIX_MODE } from "../../data/interfaces"
import Man from "./matrix/Man"
import { Collapse } from "@blueprintjs/core"

 
const Defense = () : JSX.Element => {
  const user = useMainStore((state:IState) => state.user ) 
  return <div className="w-100 h-100">
    <div  
      className={` man-div position-absolute bottom`}
      style={{ }}
    >
      <Man 
        fill="#15161C"
        stroke="#1E2028"
        height={330}
        className={`man untouchble `}
      /> 
    </div>
    <div className=" position-absolute right" >
      <Matrix user={user}  mode={MATRIX_MODE.MINE} />
    </div> 
  </div>   
} 

export default Defense