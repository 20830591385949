import { __ } from "src/libs/utilities"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import { Button, Intent } from "@blueprintjs/core"
import { IPost } from "src/libs/interfaces/layouts" 
import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { GET_USER_SUBSCRIBED_ACTION } from "../../data/actionTypes"

interface IProps {
    dataType: string
    item: IPost
    className: string
    onOpenLogin: () => void
}
const SubscribeForm = (props: IProps) :JSX.Element => { 
    const user:IUser = useMainStore((state:IState) =>state.user)
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
    useEffect(() => {
        actions(GET_USER_SUBSCRIBED_ACTION, {})
    }, [])
    return user.id 
        ?
        isSubscribed
            ?
            <div>{__("You are subscribe")}</div>
            :
            <Button intent={Intent.NONE} large className={props.className || ""}>
                {__("Subscribe")}
            </Button> 
        :
        <Button minimal className={props.className || ""} onClick={props.onOpenLogin}>
            {__("Login to subscribe")}
        </Button>
} 

export default SubscribeForm