import { getSingleRoute, schema } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { Icon, Tag } from "@blueprintjs/core"
import Moment from "react-moment"
import { Link } from "react-router-dom"

/* Protopia Ecosystem component */
export interface ITypeProps {
    contentClassName: string
    data: any
    singled_data_type: string
    field: string
}
export const Type = (props:ITypeProps): JSX.Element | null => {
    const { data, singled_data_type, field} = props 
    const data_scheme = schema()[singled_data_type]
    const visibled_value = schema()[singled_data_type]?.visibled_value || "title"
    if (!data[field] || field === "thumbnail_name" || field === "id" || field === "order") return null;
    const className = ` col-12 my-2 ${props.contentClassName} `
    if (visibled_value === field) {
        return <div className={className}>
            <div className="lead title mb-2" dangerouslySetInnerHTML={{__html: data[field].toString()}} /> 
        </div>
    }
    else {
        switch (data_scheme.apollo_fields[field].type) {
            case "media":
                return field === "thumbnail"
                    ?
                    null
                    :
                    <img
                        src={data[field]}
                        alt={data_scheme.apollo_fields[field].title}
                        style={{
                            maxWidth: "100%",
                            height: 350
                        }}
                    />
            case "external":
                console.log(data[field])
                let extFields: ( string | JSX.Element )[] | Element | string = ""
                if (data_scheme.apollo_fields[field].kind === "type") {
                    extFields = Object.keys(data[field]).map((ef, i) => {
                        const visibled_value = schema()[data_scheme.apollo_fields[field].component].visibled_value
                        return ef === visibled_value
                            ?
                            <Link to={  `/${getSingleRoute( data_scheme.apollo_fields[field].component )?.route || "" }/${ data[field].id }` } >
                                <Tag className="px-2 title" title={data[field][ef]} key={i}>
                                    {data[field][ef]}
                                </Tag>
                            </Link>                                
                            :
                            //data_type_link_url( data_scheme.apollo_fields[field].component ) 
                            <></>
                    })
                }
                else {
                    extFields = "data[field].toString()"
                }
                return <div className={className}>
                    {__(data_scheme.apollo_fields[field].title)}: <span className="">{extFields}</span>
                </div>
            case "array":
                return <div className={className}>
                    {__(data_scheme.apollo_fields[field].title)}: <span className="px-2">
                        {
                            Array.isArray(data[field]) && data[field].length > 0
                                ?
                                data[field].map((e : any, i: number) => {
                                    return <Tag key={i}>
                                        {
                                            typeof e == "string" || typeof e === "number"
                                                ?
                                                e.toString()
                                                :
                                                e.title
                                        }
                                    </Tag>
                                })
                                :
                                <span className="px-2">{__("not exists")}</span>
                        }
                    </span>
                </div>
            case "text":
                return <div className={className} dangerouslySetInnerHTML={{ __html: data[field].toString() }} />
            case "int":
                return <div className={className}>
                    {__(data_scheme.apollo_fields[field].title)}: <span className="title">{data[field].toString()}</span>
                </div>
            case "date":
                return <div className={className}>
                    {__(data_scheme.apollo_fields[field].title)}:
                    <span>
                        <Moment locale="ru" format="D MMMM YYYY HH:mm" className="title pl-2">
                            {new Date(data[field])}
                        </Moment>
                    </span>
                </div>
            case "boolean":
                return data[field]
                    ?
                    <div className={className}>
                        {__(data_scheme.apollo_fields[field].title)}
                        <Icon icon="tick" className="text-success ml-2" />
                    </div>
                    :
                    <div className={className}>
                        {__(data_scheme.apollo_fields[field].title)}
                        <Icon icon="cross" className="text-danger ml-2" />
                    </div>

            default:
                return <div className={className}>
                    { field !== "post_content" ? __(data_scheme.apollo_fields[field].title) : "" }: 
                    <span className="text-larger" dangerouslySetInnerHTML={{__html: data[field].toString()}} />
                </div>

        }
    }
} 