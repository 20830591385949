import { useEffect, useState } from "react"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { ScalableDialog } from "src/libs/useful"
import { __ } from "src/libs/utilities"
import { IManituAction, IManituActionType, IOrganoid } from "../../../data/interfaces"
import { IManitu, useManitu } from "../../../data/store"
import useSpell from "../../../hooks/useSpell"
import { ICellProps } from "./Cell"
import CellMineDescription from "./CellMineDescription"
import Defense from "./Defense"
import Organoid from "./organoid/Organoid"
import { IDoActionReturn } from "@/modules/pe-manitu-module/data/mocks/doAction"

const CellMine = (props: ICellProps) :JSX.Element => { 
    const isActionChoose: boolean = useManitu((state:IManitu) => state.isActionChoose)
    const choosedAction: IManituActionType = useManitu((state:IManitu) => state.choosedAction)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [attack, setAttack] = useState<number>( -1 )
    const [organoid, setOrganoid] = useState<IOrganoid>( {} as IOrganoid )
    const speller = useSpell()
    useEffect(() => { 
        const a = props.actions.reduce(( prev: number, cur: IManituAction ) =>  {
            return prev + cur.count  
        }, 0)
        setAttack( a )
    }, [props.actions])
    useEffect(() => {
        const myOrganoid: IOrganoid = props.organoids.filter( o => {
           return  !!o.cells.filter(c => c.x === props.item.i && c.y === props.item.rowID).length
        })[0]
        if( myOrganoid ) {
            setOrganoid( myOrganoid )
        }
    }, [])
    
    let added = ""
    if(!!props.actions.length) {
        added += " border border-danger "
    }
    if( attack > props.item.defense ) {
         
    }
    if( !!props.item.isBroken ) {
        added += " cell-crashed "
    }
    if(isActionChoose) {
        added += " cell-by-action "
    }
    const onAction = () => {
        if(isActionChoose) { 
            speller.act(props.item, choosedAction)
                .then((response: IDoActionReturn) => {
                    console.log( response )
                    props.onActionResult( props.item, choosedAction, response )
                })
        }
        else {
            setIsOpen(!isOpen)
        } 
    }
    return <>
        <div 
            className={`cell ${ added  } `} 
            data-cell-id={ props.item.id } 
            onClick={ onAction }
            title={ props.item.isBroken ? __("Broken cell") : undefined}
        >  
            <Organoid item={ props.item } organoid={ organoid } isBroken={props.item.isBroken}/>
            {
                !props.item.isBroken 
                    ?
                    <>
                        <Defense defense={ props.item.defense } attack={ attack } />
                    </>
                    :
                    null
            }    
        </div> 
        <ScalableDialog
            isOpen={isOpen}
            onClose={ () => setIsOpen( !isOpen ) }
            dialogSize={ DIALOG_SIZE.LITTLE }
        >
            <div className="p-0"> 
                <CellMineDescription 
                    item={props.item} 
                    actions={props.actions} 
                    organoid={organoid} 
                /> 
            </div>
        </ScalableDialog>
    </>
} 

export default CellMine