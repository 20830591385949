

import { LayoutIcon } from "src/libs/useful"
import { getContrastColor } from "src/libs/utilities"
import { IPlace, IPlaceType } from "../../data/interfaces"

interface IProps {
    item: IPlace
}
const PlaceTypeLabel = ({item}: IProps) :JSX.Element => {
    return <div className="d-flex">
        {
            item.placeTypes.map((pt: IPlaceType) => {
                return <div 
                    className=" m-1 d-flex align-items-center rounded-pill pointer" 
                    key={pt.id} 
                    style={{background:pt.color}}
                >
                    <span className="topos-placetype-label-icon">
                        <LayoutIcon
                            src={ pt.icon}
                            className=""
                            style={{ width:16, height: 16 }}
                        />
                    </span>
                    <span className="px-3 py-1" style={{ color: getContrastColor(pt.color) }}>
                        {pt.title}
                    </span>
                </div>
            })
        }
    </div> 
} 

export default PlaceTypeLabel