import { Route, Routes } from "react-router-dom"
import { IPETest, IPETestCategory } from "src/modules/pe-tests-module/data/interfaces"
import { tests } from "src/modules/pe-tests-module/data/mocks/tests"
import { ITestsStore, useTestsStore } from "src/modules/pe-tests-module/data/store"
import ConquestSingleTest from "./ConquestSingleTest"
import TestCard from "./TestCard"
import TestsCont from "./TestsCont"
import TestCatFilter from "./TestCatFilter"

const Tests = () : JSX.Element => {
    const currentTestCategories : IPETestCategory[] = useTestsStore( (state: ITestsStore) => state.currentTestCategories )
    const _tests: IPETest[] =  tests().filter(t => {
        return currentTestCategories
            .map( c => c.id).includes( t.testCategory?.id || -1 )
            || !currentTestCategories.length 
    })
    return <Routes> 
        <Route element={<TestsCont />}>
            <Route index element={
                <div className=" container h-100 flex-centered flex-column">
                    <TestCatFilter />
                    <div className="row w-100 mb-5"> 
                        {
                            [ ..._tests, ].map(test => {
                                return <TestCard 
                                    key={ test.id }
                                    test={ test } 
                                />
                            })
                        }
                    </div>
                </div>
            } />
            {
                _tests.map(test => {
                    return <Route 
                        key={test.id}
                        path={`${test.id}/*`} 
                        element={
                            <ConquestSingleTest 
                                item={test}
                            />
                        } 
                    />
                })
            } 
        </Route>
    </Routes>  
}

export default Tests