
import { DocumentNode } from "graphql"
import { useAppoloStore } from "../../../settings/zustand"
import {
    GET_CIRCLE_TICK_ACTION, GET_Ludens_DATA_ACTION, GET_USER_RESOURCES_TYPE, UPDATE_Ludens_DATA_ACTION
} from "./actionTypes"
import { useLudensStore } from "./store"
import { durationFromITimes } from "src/libs/utilities/sprintf" 
import { updateCircleData } from "./moks/updateCircleData"
import { userResources } from "./moks/userResources"

const asyncControllers =  async(action:string, data: any) => {
    let response : any 
    switch( action )
    {
        case GET_Ludens_DATA_ACTION:  
            response = new Promise<any>( (resolve, reject) => { 
                resolve({
                    title: useLudensStore.getState().title,
                    description: useLudensStore.getState().description,
                    icon: useLudensStore.getState().icon,
                    thumbnail: useLudensStore.getState().thumbnail,
                    phase: useLudensStore.getState().phase,
                    circleDuration: useLudensStore.getState().circleDuration, 
                    start: useLudensStore.getState().start,
                    currentCircleId: useLudensStore.getState().currentCircleId,
                    currentStart: useLudensStore.getState().currentStart,
                    pixelTestPrice: useLudensStore.getState().pixelTestPrice,
                    pixelTestHardness: useLudensStore.getState().pixelTestHardness,
                    pixelOpenProblemPrice: useLudensStore.getState().pixelOpenProblemPrice,
                    pixelOpenProblemHardness: useLudensStore.getState().pixelOpenProblemHardness,
                })
            })            
            return response 
        case UPDATE_Ludens_DATA_ACTION:  
            response = new Promise<any>( (resolve, reject) => { 
                console.log(data.data)
                useLudensStore.setState({ ...data.data })
                resolve({
                    title: useLudensStore.getState().title,
                    description: useLudensStore.getState().description,
                    icon: useLudensStore.getState().icon,
                    thumbnail: useLudensStore.getState().thumbnail,
                    phase: useLudensStore.getState().phase,
                    circleDuration: useLudensStore.getState().circleDuration,
                    start: useLudensStore.getState().start,
                    currentCircleId: useLudensStore.getState().currentCircleId,
                    currentStart: useLudensStore.getState().currentStart,
                    pixelTestPrice: useLudensStore.getState().pixelTestPrice,
                    pixelTestHardness: useLudensStore.getState().pixelTestHardness,
                    pixelOpenProblemPrice: useLudensStore.getState().pixelOpenProblemPrice,
                    pixelOpenProblemHardness: useLudensStore.getState().pixelOpenProblemHardness,
                })
            })            
            return response 
        case GET_CIRCLE_TICK_ACTION:   
            
            response = new Promise<any>( (resolve, reject) => { 

                // simulating server
                let currentCircleId: number = useLudensStore.getState().currentCircleId
                const circleDuration: number = durationFromITimes( useLudensStore.getState().circleDuration ) * 1000
                const currentStart: number = useLudensStore.getState().currentStart
                let duration: number = ( (new Date()).getTime() - currentStart) 
                if(duration > circleDuration) {
                    useLudensStore.setState({ currentCircleId: ++currentCircleId })
                    useLudensStore.setState({ currentStart: (new Date()).getTime() })
                    duration = 0
                    updateCircleData( currentCircleId )
                }
                resolve ({
                    getCurrentDuration: duration / circleDuration * 100,
                    getLinearDuration: duration,
                    getRestTime: circleDuration - duration,
                    currentCircleId
                })
            } )
            return response 
        case GET_USER_RESOURCES_TYPE:
            response = new Promise<any>( (resolve, reject) => { 
                resolve({
                    getUserResources: userResources(data.user)
                })
            })
            return response
        default: 
            return  new Promise<any>( (resolve, reject) => { resolve(`Unknown action type: ${action}`)} )
    }
}
export default asyncControllers