import { IState, useMainStore } from "@/settings/zustand"
import { moduleExists, profile, template } from "@/libs/layouts"
import { LayoutIcon } from '@/libs/useful'
import { __, isCapability } from "@/libs/utilities"
import { useEffect, useRef, useState } from 'react'
import { ILayoutUserProps } from './LayoutUser'

import { LOGOUT_ACTION } from '@/modules/pe-basic-module/data/actionTypes'
import actions from '@/modules/pe-basic-module/data/actions'
import DayNightSwitcher from '@/modules/pe-basic-module/widgets/DayNightSwitcher'
import { NavLink } from 'react-router-dom'
import { IMenuItem, Role } from '../../interfaces/layouts'
import LoginPanelPosMenuBtn from './LoginPanelPosMenuBtn'
import UserAvatar from './UserAvatar'

const components: any = {} 
const LayoutLoginedUser = (props: ILayoutUserProps) => {
    const user = useMainStore(( state: IState ) => state.user)  
    const myRef = useRef( null )
    const [ height, setHeight ] = useState<number>( 0 )
    const [ isOpen, setOpen ] = useState<boolean>( props.isOpen )

    const onMouseLeaveHandler = (e: Event) => {
        const domNode: any = myRef.current;
        if (!domNode || !domNode.contains(e.target)) {
            //height = 0 
            setOpen(props.isOpen)
            setHeight( 0 )
        } 
    }

    useEffect(() => {
        document.body.addEventListener("click", onMouseLeaveHandler)
        return () => document.body.removeEventListener("click", onMouseLeaveHandler)
    }, [])

    const profile_routing = profile()
        .filter((item:IMenuItem) => moduleExists(item.module))
    let profile_menu : any[] = [
        <li className="lmenu podmenu" key="profile_menu_0">
            <NavLink
                className="" 
                to="/profile"
            >
                <LayoutIcon
                    isSVG
                    src="/assets/img/user.svg"
                    className="personal-menu__icon mr-3"
                />
                {__("My Profile")}
            </NavLink>
        </li>
    ] 
    const onToggle = () => { 
        const newHeight: number = (document.getElementById("person_menu") as HTMLElement).clientHeight 
        setHeight( newHeight)
        setOpen(!isOpen) 
    }
    const logout = () => { 
        actions(LOGOUT_ACTION, {})
        window.location.reload()
    }

    if (profile_routing.length > 0) 
    {
        profile_menu = [
            ...profile_menu, 
            profile_routing
            .filter((menuItem:IMenuItem, i) => {
                return !menuItem.is_hidden && moduleExists(menuItem.module)
            })
            .map((menuItem:IMenuItem, i) => {
                const isRole = isCapability(menuItem.capability, user)
                if (isRole) return <></>
                return <LoginPanelPosMenuBtn key={i} menuItem={menuItem}>
                    {
                        Array.isArray(menuItem.children) && !!menuItem.children.length 
                            ?
                            <i className="position-absolute mt-3 fas fa-caret-left pl-2" />
                            :
                            <></>
                    }
                    <NavLink to={`${menuItem.realRoute}`}>
                        <LayoutIcon
                            isSVG
                            src={menuItem.icon}
                            className="personal-menu__icon mr-3"
                        />
                        <span className=''>{ __(menuItem.title) }</span>
                    </NavLink> 
                </LoginPanelPosMenuBtn>
            })
        ]
    }
        
    const login_panel = template().login_panel && Array.isArray(template().login_panel)
        ? 
        template().login_panel.map((e: IMenuItem, i: number) => {
            switch (e.component) {
                case "NavLink":
                    return <NavLink
                        key={i}
                        className={`w-100 btn ${e.route}`}
                        to={`/${e.route}`}
                    >
                        {__(e.title)}
                    </NavLink> 
                default:
                    const ElWidgetL = components[e.component].default
                    return <ElWidgetL
                        key={i} 
                        {...e} 
                        user={user} 
                        logout={ logout } 
                    />
            }
        })
        :
        <div
            className={`icon-logined p-0 m-0 ${ isOpen ? " active44" : ""} ${template().header_menu ? template().header_menu : ''} `}
            onClick={ onToggle }
            ref={ myRef }
        >
            <div className='header-menu-icon p-0 m-0'>
                <UserAvatar />
            </div>
            

            <div className="user-name px-2 d-md-block d-none">  
               { user?.display_name }
            </div>
            <div className="chevron-down-light ml-3 mt-1 pointer" />
            <div className="logined-menu" style={{ height: height }} >
                <ul id="person_menu">
                    {profile_menu}
                    <li className="lmenu podmenu">
                        <div className='pl-4 py-2'>
                        {
                            user.roles.map((role: Role) => <div className='p-1 small opacity_5 text-light ml-4' key={role}>{ __( role ) }</div>)
                        }
                        </div>
                    </li>
                    <li className="lmenu podmenu px-3">
                        <DayNightSwitcher type="buttons" className="text-light"/>
                    </li>
                    <li onClick={ logout } className="lmenu podmenu exit">
                        <LayoutIcon
                            isSVG
                            src="fas fa-sign-out-alt"
                            className="personal-menu__icon mr-3"
                        />
                        {__("logout")}
                    </li>
                </ul>
            </div>
        </div>
    return (
        <>
            {/*<Widgets areaName="login_panel" data={{ ...props }} defArea={login_panel} /> */}
            { login_panel }
        </>
    )
} 

export default LayoutLoginedUser