import { FESTIVAL_RATING_TYPES } from "./interfaces";

export const raitingTypes = () => ([
    {
        _id : FESTIVAL_RATING_TYPES.INDEPENDENT_GRADE,
        title: "Independent grade",
        commentary: "The expert assigns a score by pressing the button with the desired numerical value"
    },
    {
        _id : FESTIVAL_RATING_TYPES.PROCENTAGE,
        title: "Procentage",
        commentary: "Expert uses a slider to rate in percentages"
    },
    {
        _id : FESTIVAL_RATING_TYPES.EACH_CATEGORY,
        title: "for each category",
        commentary: ""
    }
])


// {
//     _id : FESTIVAL_RATING_TYPES.PROCENTAGE,
//     title: "Procentage",
//     commentary: "The Expert assesses by manipulating the sliders, the sum of the values of which does not exceed 100"
// },