import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import { ITeam, IEvent, IPlace, All } from "../../data/interfaces"
import { useState } from "react" 
import EventContent from "../components/EventContent"
import { ID } from "@/libs/interfaces/layouts"

interface IProps {
    items: All[]
    onClose: () => void
    onOpenLogin: () => void
}
const EventCluster = (props:IProps) :JSX.Element => {
    const [current, setCurrent] = useState<All>(props.items[0] )
    let itemIDs: ID[] =[]
    return (
        <div className="w-100 h-100 d-flex">
            <div className="topos-dmap-dialog-menu overflow-y-auto">
                <ButtonGroup vertical className="w-100">                    
                {
                    props.items.map((item, i) => {
                        if( itemIDs.includes(item.id )) return null
                        itemIDs.push(item.id)
                        return <Button 
                            minimal={current.id !== item.id} 
                            fill 
                            className="py-3 px-3 text-light d-flex"
                            alignText="right" 
                            key={item.id}
                            intent={current.id === item.id ? Intent.DANGER : Intent.NONE}
                            onClick={() => setCurrent(props.items[i])}
                        >
                            <span>{item.title}</span> 
                        </Button>
                    })
                }
                </ButtonGroup>
            </div>
            <div className="topos-dmap-dialog-content">
                <EventContent item={current as IEvent} onClose={props.onClose} onOpenLogin={props.onOpenLogin}/>
            </div>
        </div>
    )
} 

export default EventCluster