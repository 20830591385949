import { Dummy } from "src/libs/useful"
import getRandomImg, {  RandomImgCategory } from "src/assets/data/getRandomImg"
interface IBannerProps {
  i:number
}
const Banner = (props: IBannerProps) : JSX.Element => {
  return (
    <div className="topos-banner pointer" style={{backgroundImage: `url(${ getRandomImg() })`}}>
      <Dummy width={100} height={75} />
      <div className="position-absolute w-100 h-100 flex-centered text-light">
        <div className="bg-dark p-2 text-uppercase small">
          Banner
        </div> 
      </div>
    </div>
  )
}
 

export default Banner