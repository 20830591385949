import randomImgs from './randomImgs.json'

const getRandomImg = (props : IRandomImageProps | null = {NotOnlySrc: false} ) => {
    let ret: any[] = randomImgs, imgObj: any
    if( props && props.cat ) {
        ret = randomImgs.filter( img => img.cat === props.cat)
    }
    if (props && props.i) {
        imgObj = ret[ props.i % ret.length ]
    }
    imgObj = ret[ Math.floor( (ret.length - 1 ) * Math.random() )   ]
    return props && props.NotOnlySrc ? imgObj : imgObj.src
}
export default getRandomImg

export interface IRandomImageProps {
    cat?: RandomImgCategory
    i? : number
    NotOnlySrc?: boolean
}
export enum RandomImgCategory {
    AUTO = "auto",
    FOOD = "food",
    LIFESTYLE = "lifestyle",
    LANDSCAPE = "landscape",
    HUMEN = "humen",
}