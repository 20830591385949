import { IState, useMainStore } from "@/settings/zustand"
import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { getRoute, moduleExists, template } from "@/libs/layouts"
import { LayoutIcon } from "@/libs/useful"
import { __, isCapability } from "@/libs/utilities"
import { Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { isRouteOpen } from "../utils/isRouteOpen"

interface IProps {
    item: IMenuItem
    i: number
    onRoute: (route: string) => void
    pt?: string
}
const HeaderMenuMainButton = ({item, i, ...props}: IProps) :JSX.Element => {
    const user = useMainStore(( state: IState ) => state.user)
    const landId:ID = useMainStore( ( state: IState ) => state.landId )
    const [isOpen, setIsOpen] = useState<boolean>( false ) 
    useEffect(() => useMainStore.subscribe(
        ( state: IState ) => setIsOpen( isRouteOpen( item, landId, state.event_types ) )
    ), [ item, landId ])
    const onRoute = () => {
        props.onRoute( getRoute(item) )
    } 
    const delay = ` transition-delay-${ Math.floor( Math.random() * 4 ) * 200 }`
    const duration = ` transition-duration-${ Math.floor( Math.random() * 4 ) * 500 } ${delay} `
    return <NavLink 
        onClick={ onRoute }
        to={ { pathname: `/${ getRoute(item) }` } }
        key={ i }
        className={`pt-${props.pt}  ${ isOpen ? "opened" : "closed" }`}
    >
        <div className={ `header-menu-element ${ template().header_menu } ${ isOpen ? "opened" : "closed" } ${ duration }` }>
            <LayoutIcon
                src={ item.icon }
                className="header-menu-icon" 
            />
            <span className='header-menu-label'>
                <span>
                    { __( item.title ) }
                </span>
                {
                    !!(item.children || []).filter( ch => {
                        return (!ch.is_hidden && moduleExists( ch.module )) || isCapability(ch.capability, user)
                      }).length && 
                    <Icon icon="caret-down" className=" ml-2"/>
                } 
            </span>
        </div>
    </NavLink> 
} 

export default HeaderMenuMainButton


