import React from 'react' 
import { Outlet, useParams } from 'react-router'
import { IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import ContactForm from './ContactForm'
import { NavLink } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { component_url } from '@/libs/utilities/links'
import { isRole } from '@/settings/zustand/user'

const AboutContainer = ( ) : JSX.Element => {
    const {landId} = useParams()
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const bg = festival.defaultThumbnail 
        ?
        {
            backgroundImage: `url(${ festival.defaultThumbnail || "/assets/img/fest/fest-default.jpg" })`, 
        } 
        :
        { 
            width:"100%", 
            height:360,
            background: "linear-gradient(rgb(166 166 166) 0%, rgb(230 230 230) 100%)",
            backgroundImage: `url(/assets/img/fest/thumbnails/fest-default.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center"
        }
    const getTitleLayout = () =>
    {  
        return <span className="single-data-type-title">
            { festival.title?.toString() }
        </span>
    }
    const toAdmin = `${component_url("FestivalAdminListView")}/${landId}`
    return  <div className=" w-100 ">
        <div style={ bg } className="single-data-type-thumbnail position-relative">
            <div className="short-container single-data-type-thumbnail-container"> 
                {/*
                <div className="position-absolute top right">
                    {
                        ( isLoading || isPojectsLoading ) 
                            && <LoaderBallsLine />
                    }
                </div> 
                */}
                <div className="main-fest-contacts h-100">
                    <ContactForm />
                </div>
                <div className="single-data-type-thumbnail-titles container ">  
                    { getTitleLayout() } 
                </div>
                {/* <div className="w-100 text-light d-flex justify-content-between flex-sm-row flex-column align-items-center align-items-md-end">
                    <div className="">
                        <div className="main-fest-logo-cont">
                            <div 
                                className="thumbnail main-fest-logo" 
                                style={{
                                    backgroundImage: `url(${festival.logotype})`,
                                    height: 60,
                                    width: 60
                                }} 
                            />
                        </div>                
                        <div className="display-7 d-flex justify-content-md-start justify-content-center">
                            <span className=" main-fest-title ">
                                { festival.title }
                            </span>
                        </div>
                        <div className="">
                            <span className="main-fest-description mb-4 d-flex justify-content-md-start justify-content-center">
                                { festival.description }
                            </span>
                        </div>
                    </div> 
                </div> */}
                {
                    isRole("administrator") && <NavLink
                        to={toAdmin}
                        className={"position-absolute right bottom"}
                    >
                        <Button minimal icon="unlock" />
                    </NavLink>
                }
                
            </div>
        </div>
        <Outlet />  
    </div>
} 

export default AboutContainer