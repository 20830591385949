 
import { useDataStore } from 'src/modules/pe-basic-module/data/store'
import { GET_TEST_ACTION } from './actionTypes'
import asyncControllers from './mocks/controllers' 
import { IPETest } from './interfaces'


export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    switch (action) {
         case GET_TEST_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        // console.log( response )
                        useDataStore.setState({
                            PETest: [
                                ...(
                                    ( useDataStore.PETest || [] )
                                        .filter(( t: IPETest ) => t.id === response.getTest.id )
                                ),
                                response.getTest
                            ]
                        })
                        resolve( response.getTest )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        default:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
    }

} 

export const syncAction = (action: string, data: any): any => {
    switch(action) { 
        default:
            return action
    }
    
}