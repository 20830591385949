import { ID, Icon, URL } from "@/libs/interfaces/layouts";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { IPEResourceType, ILudensPhase, IUserResource } from "./interfaces";
import { ITimes } from "@/libs/utilities/sprintf";

export interface ILudensStore {
    title: string
    description: string
    icon: Icon
    thumbnail: URL
    phase: ILudensPhase
    currentCircleId: number
    circleDuration: ITimes 
    currentStart: number
    currentGameId: ID,
    pixelTestPrice: number
    pixelTestHardness: number
    pixelOpenProblemPrice: number
    pixelOpenProblemHardness: number
    resourceTypes: IPEResourceType[]
    userResource: { [key: ID]: IUserResource }
    userMaxResource: { [key: ID]: IUserResource }
}
export const useLudensStore: any = create( 
    devtools( 
        persist<ILudensStore>( 
            (set: any, get: any ) => ({ 
                currentGameId: -1,
                title: "",
                description: "",
                icon: "",
                thumbnail: "",
                phase: ILudensPhase.BEFORE,
                currentCircleId: 1,
                circleDuration: { hrs : 2 }, 
                currentStart: (new Date()).getTime(),
                pixelTestPrice: 1,
                pixelTestHardness: .5,
                pixelOpenProblemPrice: 1.8,
                pixelOpenProblemHardness: 1,
                resourceTypes: [],
                userResource: {},
                userMaxResource: {}
            }),
            {
                name: 'pe-ludens-storage', 
                storage: createJSONStorage( () => localStorage ), 
            } 
        )
    )
)