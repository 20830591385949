import AdminItemLink from '@/modules/pe-admin-module/views/single/AdminItemLink';
import { Loading } from '@/libs/useful';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_SINGLE_ACTIONS } from '../../data/actionTypes';
import actions from '../../data/actions';
import { LeftLayout } from './LeftLayout';
import { RightLayout } from './RightLayout';
import { Thumbnail } from './Thumbnail';
import { Type } from './Type';

export {
    LeftLayout, RightLayout, Thumbnail, Type
};

const SingleDataTypeForm = (props: any) :JSX.Element => { 
    const [isLoading, setIsLoading] = useState( true )
    const [data, setData] = useState<any>({})
    const [curNavId, setCurNavId] = useState( "tab0" )
    const params = useParams()
    useEffect(() => {
        setCurNavId( "tab0" )
        const { singled_data_type } = props
        actions(GET_SINGLE_ACTIONS, {data_type: singled_data_type, id: params.id } )
            .then( res => {
                console.log( singled_data_type, res )
                setData(res || {})
                setIsLoading(false)
            })
    }, [])
    if( isLoading ) {
        return <Loading />
    }
    const getColumnProportia = (): number[] => {
        return [8, 4]
    }
    return <div className="p-0" style={{marginLeft:-20, marginRight:-20, marginTop:-20}}>
            <div className="row m-0 position-relative" >
                <Thumbnail { ...props } data={ data } />
                <div className={ ` boo container ` }>
                    <div className="row">
                        <div className={`px-3 col-md-${ getColumnProportia()[0] }`}>
                            <div className="row"> 
                                <Type 
                                    {...props} 
                                    field={ 
                                        props.singled_data_type === "User" ? "user_descr" : "post_content" 
                                    } 
                                    data={data} 
                                />
                            </div>
                            <div>
                                <LeftLayout {...props} curNavId={curNavId} setCurNavId={setCurNavId} /> 
                            </div>    
                        </div>
                        <div className={`px-3 col-md-${ getColumnProportia()[1] }`}>
                            <RightLayout {...props} data={data} /> 
                        </div>
                    </div>
                </div>
                <div className="position-absolute right top mt-3 mr-4" >
                    <AdminItemLink singled_data_type={ props.singled_data_type } />
                </div>
            </div>
        </div>
} 

export default SingleDataTypeForm






