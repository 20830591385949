import { __ } from "src/libs/utilities"
import { Popover, Position } from "@blueprintjs/core" 
import { IManitu, useManitu } from "src/modules/pe-manitu-module/data/store"

interface IDefenseProps {
    defense: number
    attack: number 
}
const Defense = (props: IDefenseProps) : JSX.Element => {
    const isActionChoose: boolean = useManitu(( state:IManitu ) => state.isActionChoose)
    if(
        !props.defense && 
        !props.attack && 
        props.defense - props.attack < 1 
    ) {
        return <></>
    }
    return <div className="cell-defense m-auto"> 
        <Popover
            interactionKind="hover"
            position={Position.BOTTOM}
            className={`position-absolute bottom right ${isActionChoose ? "untouchble" : ""}`}
            content={
                <div className="p-3">
                    <div>
                        <span className="mr-2">{ __("Full define") }</span>
                        <span className=" title ">{ props.defense }</span>
                    </div>
                    <div>
                        <span className="mr-2">{__("Full attack")}</span>
                        <span className=" title ">{ props.attack }</span>
                    </div>
                </div>
            }
        >
            <div className="defense-label">
                { props.defense - props.attack }
            </div> 
        </Popover> 
    </div>
}
 

export default Defense