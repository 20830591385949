import { Button, ButtonGroup } from "@blueprintjs/core"
import React, { useState } from "react"
import Layouts from "src/libs/layouts" 
import { __ } from "@/libs/utilities"
import EditPageDialog from "./EditPageDialog"
import { ID } from "@/libs/interfaces/layouts"

interface ICurrentFolderData {
  folder: ID
  is_cabinet?: boolean
  module?: any
  component?: any
  html?: any
}
const CurrentFolderMenu =  (props: any): JSX.Element =>
{
    const [data, setData] = useState<ICurrentFolderData>({ 
      folder: props.currentFolderId,
      is_cabinet: true 
    })
    const [isEditOpen, setEditOpen] = useState(false) 
    const onField = (value: any, field: ID) =>
    {
        console.log( value, field )
        let _data: any = { ...data }
        _data[ field ] = value
        if(field === "component")
        {
            const _module = Layouts().views[ value ]?.module 
            _data.module = _module
            //setModule(Layouts().modules[_module])
        }
        setData( _data )
        
    }
    const onEditOpen = () =>
    {
        setEditOpen( !isEditOpen )
    }
    const onSaveRote = () =>
    {
        props.onAddRoute( data )
        setEditOpen( false )
    }
    const onPageTypeField = (value: string) =>
    { 
        let _data: any = {...data}
        delete _data.component
        delete _data.html
        _data[value] = ""
        setData( _data )
    }
    return <> 
        <ButtonGroup className="d-flex"  large >
            {
                props.currentFolderId > 0
                    ?
                    <Button
                        icon="arrow-up"
                        minimal
                        onClick={() => props.onSelect(-1)}
                    >
                        {__("to root")}
                    </Button>
                    :
                    null
            }
            
            <Button
                icon="add-to-artifact"
                minimal
                onClick={ onEditOpen }
            >
                {__("Add page")}
            </Button>
        </ButtonGroup>  
        <EditPageDialog
             data={data} 
             onEditOpen={onEditOpen}
             onField={onField}
             onPageTypeField={onPageTypeField}
             onSaveRote={onSaveRote}
             isEditOpen={isEditOpen}
             setEditOpen={setEditOpen}
             jsonData={`{folder:${props.currentFolderId}}`}
             dialogTitle="New page settings"
             saveButtonTitle="Insert new Page"
        />
    </>
}

export default CurrentFolderMenu