import React from "react"
import { __ } from "src/libs/utilities" 
import { festSatuses } from '../data/festStatuses'
import { FEST_ADMIN_STATUSES, IFestival } from "../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../data/store"
  
interface IPahseLabelProps {
	phase?: FEST_ADMIN_STATUSES | undefined,
	className?: string
}
const PhaseLabel = (props: IPahseLabelProps) : JSX.Element => { 
	const festival : IFestival = useFestDataStore((state:IFestDataStor) => state.festival)
	const phase = props.phase ? props.phase : festival.status
	return phase 
		? 
		<div className={`status-descr mr-md-auto ml-md-0 mx-auto ${ props.className || "" } `}>
			{ __( festSatuses()[ phase || FEST_ADMIN_STATUSES.BEFORE_START_PHASE ]?.title ) }
		</div> 
		:
		<></>
} 

export default PhaseLabel
	  
