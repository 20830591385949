import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import FieldInput from "src/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "src/libs/scalars/interface/IScalarForm"
import { Button, Icon } from "@blueprintjs/core"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ISoundVolumeEngineProps {

}
const SoundVolumeEngine = (props: ISoundVolumeEngineProps): JSX.Element => {
    const [isVol, setIsVol] = useState<boolean>(false)
    const audioVolume : number = useStorytellingStore((state: IStorytellingStore) => state.audioVolume)
    const music : boolean = useStorytellingStore((state: IStorytellingStore) => state.music)
    const voices : boolean = useStorytellingStore((state: IStorytellingStore) => state.voices)
    const effects : boolean = useStorytellingStore((state: IStorytellingStore) => state.effects)
    
    const onAudioVolume = (value: number) => { 
        useStorytellingStore.setState({ audioVolume: value })
    }
    const onClick = () => {
        const is = !(voices && music && effects) 
        useStorytellingStore.setState({
            voices : is,
            music : is,
            effects : is
        }) 
    }

    return <div 
        className={`pe-sound-volume-engine-container`} 
        onMouseEnter={() => setIsVol(true) }
        onMouseLeave={() => setIsVol(false)}
    >
        <Button 
            className={`pe-sound-volume  ${voices && music && effects ? "" : "no" }`} 
            minimal 
            onClick={onClick}
        >
            <Icon 
                icon={ voices && music && effects ? "volume-off" : "volume-up" } 
                size={22} 
                color="#FFF"
            />
        </Button>
        <div className={`sound-input ${!isVol ? "d-none" : ""}`}>
            <FieldInput
                vertical={VerticalType.HORIZONTAL} 
                editable
                notClear
                className="form-control input dark"
                type={SCALAR_TYPES.SLIDER}
                value={audioVolume}
                min={0}
                max={1}
                stepSize={.01}
                labelStepSize={1}
                onChange={ onAudioVolume }
            />
        </div>
        
    </div>
}
export default SoundVolumeEngine