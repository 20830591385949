import TelegramLoginButton from "./TelegramLoginButton"

/* Protopia Ecosystem component */
export interface ITelegramLoginProps {
    callback: (response: any) => void
}
const TelegramLogin = (props: ITelegramLoginProps): JSX.Element => {
    return <div className="soc telegram overflow-hidden" >
        <TelegramLoginButton
            botName="kraefestBot" 
            dataOnauth={(user: any) => console.log( user)}
            dataAuthUrl="/tg-login" 
            className=" opacity_01 "
        >
            <i className="fab fa-telegram-plane-0" />
        </TelegramLoginButton>
        <i className="fab fa-telegram-plane untouchble d-none" />
    </div> 
}
export default TelegramLogin