
import { ScalableDialog } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Button, ButtonGroup, Collapse } from "@blueprintjs/core"
import { useState } from "react"
import { useParams } from "react-router"
import Feed from "src/modules/pe-basic-module/views/core/Feed"
import { DIALOG_SIZE, ID } from "src/libs/interfaces/layouts"
import { IMedia } from "../../data/interfaces"
import MediaElement from "./MediaElement"
import MediaThumbnail from "./MediaThumbnail"
import Uploader from "./Uploader"

const MediaListForm = (props:any) :JSX.Element => {
    const params = useParams()
    const landId: ID = params.landId || ""
    // console.log(landId)
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [current, setCurrent] = useState<IMedia | null>(null)
    const [i, setI] = useState<number>(0)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const onOpen = (item: IMedia, _i: number) => {
        setCurrent( item )
        setI(_i)
        setIsDialogOpen(true)
    }
    const onClose = () => {
        setCurrent( null )
        setIsDialogOpen(false)
    }
     
    const prev = () => {
         
    }
    const next = () => {
        
    }
    return <div className="w-100">
        <div className="container-fluid px-3 pt-3">
            <ButtonGroup className="px-2"> 
                <Button 
                    minimal 
                    onClick={()=>setIsOpen(!isOpen)}
                >
                    {__(isOpen ? "Close" : "Add new")}
                </Button>
                <Button 
                    minimal 
                >
                    {__("Bulk actions:")}
                </Button>
            </ButtonGroup> 
        </div>
        <div className="container-fluid px-3 ">
            <Collapse isOpen={isOpen} className="px-2">
                <Uploader />
            </Collapse>
        </div>
        
        <Feed
            data_type="Media"
            offset={0}
            class_name='row flex-centered'
            containerClassName="container-fluid mx-3" 
            component={ MediaThumbnail }
            land_id={landId}
            params={{
                onOpen 
            }}
        />
        <ScalableDialog
            dialogSize={DIALOG_SIZE.LARGE}
            isOpen={isDialogOpen}
            onClose={onClose}
        >
            <MediaElement 
                item={current || {} as IMedia } 
                prev={prev}
                next={next}
            />
        </ScalableDialog>
    </div>
} 

export default MediaListForm
 