import { getVisibleValue } from '../../../../libs/layouts'
import Moment from "react-moment"
import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { __, data_type_admin_url } from "@/libs/utilities" 
import getRandomImg from '@/assets/data/getRandomImg'

const DataCard = (props: any) : JSX.Element => { 
    const routeSingle = data_type_admin_url(props.item.__typename)
    const link = routeSingle ? routeSingle : null
    var postDateBlock = props.post_date
        ?
        <div className="post-date">
            <Moment locale="ru" format="D.MM.YYYY ">
                {new Date(props.item.post_date)}
            </Moment>
        </div>
        :
        null
    const linkBlock = link
        ?
        <Link
            to={"/" + link + "/" + props.item.id }
            className="ml-auto"
        >
            <Button 
                minimal
                rightIcon="chevron-right" 
            >
                {__("More")}
            </Button>
        </Link>
        :
        null

    let thumb: string = "thumbnail"
    switch(props.item.__typename)
    {
        case "User":
            thumb = "avatar"
            break
        default:
            thumb = "thumbnail"
    }
    let orderLabel: JSX.Element = <></>
    if(props.order)
    {
        orderLabel = <div className="single-data-type-order position-absolute ">
            {props.order}
        </div>
    }

    const imgSrc : string = props.item[ thumb ]
        ?
        props.item[ thumb ]
        :
        getRandomImg({ i: props.i }) //   null
        
    const linkBack =  link
        ?
        <Link
            to={"/" + link + "/" + props.item.id }
            className="ml-auto"
        >
            <div 
                className="post-thumbnail m-0" 
                style={{

                    backgroundImage:"url("+ imgSrc + ")"
                }}
            >
                
            </div>
        </Link>
        :
        <div 
            className="post-thumbnail m-0" 
            style={{

                backgroundImage:"url("+ imgSrc + ")"
            }}
        >
            
        </div>      
    let colClass = " col-12 " 
    if( props.params?.col )
    {
        switch(parseInt( props.params?.col ))
        {
            case 2:
                colClass = " col-md-6 "
                break;
            case 3:
                colClass = " col-md-4 "
                break;
            case 4:
                colClass = " col-md-3 "
                break;
            case 1:
            default:                
                colClass = " col-12 "
                break;

        }
    }

    const tags:RegExp = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g
    const words: string[] = props.item.post_content?.replace(tags, '').split( ' ' )
    const content: string = words?.slice(0, 20).join(' ')
    return <div className={` ${colClass} ${props.cardClass} my-3 `} key={ props.item.id }>
        <div className="m-0 p-0 d-flex flex-row  h-100 card"> 
            {linkBack}
            {orderLabel}
            <div className="d-flex flex-column p-4 flex-grow-100 overflow-hidden" title={ props.item[ getVisibleValue( props.data_type ) ] } >
                <label className="mt-1 position-absolute top right _check_">
                    <input type="checkbox" id={props.item.id} />
                </label>
                <h5 className='text-overflow'>
                    { props.item[ getVisibleValue( props.data_type ) ] }
                </h5>
                <div 
                dangerouslySetInnerHTML={{ __html : `${content}...` }}
                />
                <div className="d-flex mt-auto align-items-end">
                    {postDateBlock}
                    {linkBlock}
                </div>
            </div>
        </div>
    </div>
}
 
export default DataCard