import React from 'react' 
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const String = ( props: IScalarForm ) => {
  return (
    <Scalar { ...props } />
  )
} 

export default String