import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { ID } from "src/libs/interfaces/layouts"
import { useEffect, useState } from "react"
import { IFabulaStaff } from "src/modules/pe-fabula-module/data/interfaces"

/* Protopia Ecosystem component */
export interface IStoryStuffIconProps {
    item: IFabulaStaff
}
const StoryStuffIcon = (props: IStoryStuffIconProps): JSX.Element => {
    const executeStuff: ID = useStorytellingStore( (state: IStorytellingStore) => state.executeStuff )
    const [tm, setTm] = useState<any>(null)
    const [execute, setExecute] = useState<boolean>(false)
    useEffect(() => {
        clearTimeout(tm)
    }, [])
    useEffect(() => {
        if( executeStuff === props.item.id ) {
            setExecute(true)
            useStorytellingStore.setState({ executeStuff: "-1" })
            setTm(
                setTimeout(() => {
                    setExecute(false) 
                }, 1000)
            )
        } 
    }, [ executeStuff, props.item ])
    const onClick = () => {
        // useStorytellingStore.setState({ executeStuff: props.item.id })
    }
    return <div className='pe-story-stuff-icon-container' onClick={onClick}>
        <div 
            className={`pe-story-stuff-icon ${execute ? " execute " : ""}`}
            style={{
                backgroundImage: `url(${props.item.thumbnail})`
            }}
        >

        </div>
        <div className="pe-stuff-icon-label">
            { props.item.title }
        </div>
    </div>
}
export default StoryStuffIcon