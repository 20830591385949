import { Navigate, Route, Routes } from "react-router" 
import Month from "./Month"
import Year from "./Year"
import Week from "./Week"
import Today from "./Today" 
import Day from "./Day"
import { IToposStore, useToposStore } from "../../data/store"
import ToposLayoutContainer from "../components/ToposLayoutContainer"
import { labels } from "../../data/labels"
import useEvent from "../../hooks/useEvent"

const ToposAficheForm = (props: any) :JSX.Element => {
    useEvent("-1") 
    const range: number = useToposStore((state:IToposStore) => state.range)
    return <div className="w-100 h-100">
        <Routes>
            <Route path="/*" element={<ToposLayoutContainer />} >
                <Route index element={ <Navigate replace to={ labels[ range ] } /> } /> 
                <Route path="year" element={ <Year /> } />
                <Route path="month" element={ <Month /> } />
                <Route path="week" element={ <Week /> } />
                <Route path="day" element={ <Day /> } />
                <Route path="today" element={ <Today /> } />
            </Route>
        </Routes> 
    </div>
} 

export default ToposAficheForm