import { useEffect, useState } from "react"  
import { IResourceSingleProps } from "./ResourceMonitorSingle"
 
const ResourceMonitorAnimation = ( {resource, ...props}:IResourceSingleProps ) : JSX.Element => {
    const [duration, ] = useState<number>(150) 
    const [frame, setFrame] = useState<number>(100)
    const [count, setCount] = useState<number>(props.count)
    const [isStart, setisStart] = useState<boolean>(false) 
    const [seed, ] = useState<number>(  Math.floor(Math.random() * 2)  )
    const [animationDelay, ] = useState<string>(  `${ Math.random() * 400 }ms`  )
    const [animationDuration, ] = useState<string>(  `${ Math.random() * .5 + 1.5 }s`  )
    useEffect(() => {  
        const intervalID = setInterval( () => {
            if( frame < duration ) {
                setFrame(frame => frame + 1 )
            }
            else {
                setisStart( false ) 
                setCount(props.count)
            }
        }, 10 ) 
        return () => {
            clearInterval(intervalID) 
        } 
    }, [ frame ])

    useEffect(() => {
        setisStart( props.count !== count )
        setFrame( 0 )
    }, [props.count])
    
    const disp: number = props.count - count
    return <div 
        className={`pe-manitu-monitor-animation ${isStart ? "animate" : " " } `}
        
    > 
        <div 
            className={`pens  alt${seed} `}
            style={{
                top: 150, 
                animationDelay, 
                animationDuration, 
                color: resource.color,
                borderColor: resource.color
            }}
        >
            { disp > 0 ? `+${disp}` : disp }
        </div>
    </div>
}

export default ResourceMonitorAnimation