import { IUser, useAppoloStore, useMainStore } from 'src/settings/zustand'
import { DocumentNode } from 'graphql'
import { DO_ACTION_ACTION, GET_MATRIX_ACTIONS, GET_MINE_MANITU_ACTIONS, GET_MY_PIXELS_ACTION, GET_PARTNERS_ACTION, GET_USER_MANITU_ACTIONS } from './actionTypes'
import { useManitu, useManituStore } from './store'
import { getCurrentUserMatrix, getDefaultMatrix } from './matrix'
import { shuffle } from 'src/libs/utilities'
import { players } from './mocks/players'
import { getUserMana, manaMocks, mineMana } from './mocks/manaMocks'
import { myPixels } from './mocks/myPixels'
import { testCategories } from 'src/modules/pe-tests-module/data/mocks/testCategories'
import { IManituAction, IManituMatrix, IUserMana } from './interfaces'
import doAction from './mocks/doAction'
import { manas } from './manas'
import { tests } from 'src/modules/pe-tests-module/data/mocks/tests'
const { gql } = require('@apollo/client/core')

const asyncControllers = async (action: string, data: any) => {
  let response: any, query: DocumentNode, query_name: string, fields: any

  const apolloClient: any = useAppoloStore.getState().client
  switch (action) {
    case DO_ACTION_ACTION:
      response = new Promise<any>((resolve, reject) => {
        const matrix: IManituMatrix = useManitu.getState().matrix 
        setTimeout(() => {
          const { cell, action} = data  
          const result = doAction( cell, action, matrix.user )
          //console.log( result )
          if( result ) {
            resolve( result )
          }
          else{
            reject({  })
          }
          
        }, 300)
      })
      return response
    case GET_MATRIX_ACTIONS:
      response = new Promise<any>((resolve, reject) => {
        setTimeout(() => {
          const matrixActions: IManituAction[] = getUserMana(data.user).actions || []
          const matrix: IManituMatrix = !data.isMine 
            ? 
            useManituStore.getState().selfMatrix // getDefaultMatrix(data.user) 
            : 
            useManituStore.getState().selfMatrix
          // console.log( data.user )
          resolve({
            getMatrix: {
              matrixRows: useManituStore.getState().matrixRows,
              matrixColumns: useManituStore.getState().matrixColumns,
              matrixActions,
              matrix
            },
          })
        }, 300)
      })
      return response
    case GET_PARTNERS_ACTION:
      response = new Promise<any>((resolve, reject) => {
        resolve({
          users: shuffle(players().splice(0, 100)),
        })
      })
      return response
    case GET_MY_PIXELS_ACTION:
      response = new Promise<any>((resolve, reject) => {
        //"PEOpenProblem"
        // PETest
        resolve({
          getMyTests : tests(),

        })
      })
      return response
    case GET_MINE_MANITU_ACTIONS:
      response = new Promise<any>((resolve, reject) => {
        resolve({
          getUserManitu: mineMana(),
          getUserMatrix: getCurrentUserMatrix(),
          getTestCategories: testCategories(),
          getManaTypes: manas(),
        })
      })
      return response
    case GET_USER_MANITU_ACTIONS:
      response = new Promise<any>((resolve, reject) => {
        resolve({
          getUserManitu: getUserMana(data.user),
        })
      })
      return response
    default:
      return new Promise<any>((resolve, reject) => {
        resolve(`Unknown action type: ${action}`)
      })
  }
}
export default asyncControllers
