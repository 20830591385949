import { useEffect, useState } from "react"
import { Loading } from "src/libs/useful"
import { shuffle } from "src/libs/utilities"
import { GET_TEST_ACTION } from "../../data/actionTypes"
import { actions } from "../../data/actions"
import { IPETest } from "../../data/interfaces"
import SingleTest from "../single/SingleTest"

const TestsForm = (): JSX.Element => {
    const [item, setItem] = useState<IPETest>({} as IPETest)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        actions(GET_TEST_ACTION, {id:1})
            .then((result:IPETest) => {
                setItem({
                    ...result, questions: 
                    result.is_shuffle ? shuffle(result.questions) : result.questions
                } )
                setIsLoading(false)
            })
    }, [])
    if( isLoading) 
    {
        return <div className="layout-state w-100 h-100 flex-centered">
            <Loading />
        </div>
    }
    return <div className="layout-state">
        <SingleTest
            item={item}
        />
    </div> 
} 

export default TestsForm