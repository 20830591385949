
import { Fragment, useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { IFestAdminProps } from '../../data/interfaces'
import Cabinet from './Cabinet' 
import DataTableForm from './DataTableForm'  
import CabinetBreadcrumbs from './CabinetBreadcrumbs'
import DataForm from '@/modules/pe-admin-module/views/single/DataForm'  
import { getAdminRoutes } from '@/modules/pe-topos-module/views/admin/getAdminRoutes'

const FestAdminForm = (props: IFestAdminProps): JSX.Element => {
    const allRoutes = getAdminRoutes( ) 
    
    return <div className='container mb-5'> 
        <Routes>
            <Route path='/*' element={<Form route={props.route} title={props.title} />}>
                <Route 
                    index
                    element={<Cabinet />}
                />
                {
                    Object.keys(allRoutes).map((path: string, i: number) => {
                        let ElComponento: JSX.Element 
                        if(allRoutes[path].data_type)
                        { 
                            return <Fragment key={ i }> 
                                <Route  
                                    path = { `${ path }/:id` }
                                    element = { 
                                        <DataForm
                                            data_type={ allRoutes[path].data_type }
                                            route={ 
                                                props.route 
                                                    ?
                                                    `${ props.route }/${ path }`
                                                    :
                                                    path 
                                            }
                                        />
                                    }
                                />
                                <Route  
                                    path={ `${path}/*` }
                                    element={ 
                                        <DataTableForm 
                                            data_type={ allRoutes[path].data_type } 
                                            path={ 
                                                props.route 
                                                    ?
                                                    `${ props.route }/${ path }`
                                                    :
                                                    path 
                                            }  
                                            title={allRoutes[path].title}
                                        /> 
                                     }
                                />
                            </Fragment> 
                        }
                        else if(allRoutes[path].component) {
                            const Component = allRoutes[path].component 
                            ElComponento = <Component  
                                key={i}
                                route={ path } 
                                title={allRoutes[path].title}
                            />
                        }
                        else
                        {
                            ElComponento = <div className='display-5' key={i}>
                                {allRoutes[path].title}
                            </div>
                        }
                        return <Route 
                            key={i}
                            path={ `${path}/*` }
                            element={ ElComponento }
                        /> 
                    })
                } 
            </Route>
        </Routes>
    </div> 
} 

export default FestAdminForm

export const Form = (props:any) :JSX.Element => { 
    return <>
        <div>
            <CabinetBreadcrumbs 
                title={props.title}
                route={props.route}
            />
        </div>
        <div>
            <Outlet />
        </div>
    </>
}