import { ID } from "@/libs/interfaces/layouts"
import { IMedia } from "../../data/interfaces"

/* Protopia Ecosystem component */

export interface IMediaThumbnailProps {
    item: IMedia
    i: number
    data_type: string
    height: number
    params : any
    selectId: ID
}
const MediaThumbnail = ({item, selectId, ...props}: IMediaThumbnailProps): JSX.Element => {

    return <>
        <div  
            className={ "wp-thumbnail " + ( item?.id === selectId ? " active " : "" ) }
            data-id={ item?.id }
            style={{ backgroundImage: "url(" + item?.url +")" }}
            onClick={() => props.params.onOpen(item, props.i)}
        />
    </>
}
export default MediaThumbnail