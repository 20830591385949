import { Callout, Intent } from "@blueprintjs/core"
import { FC } from "react"
import { IFabulaEpisode, IFabulaMedia, PROTECTION_MODE } from "src/modules/pe-fabula-module/data/interfaces"
import { miniGames } from "src/modules/pe-fabula-module/data/miniGames"
import { __ } from "src/libs/utilities"
//import Dice from "../mini-game/Dice"
import Tennisus from "../mini-game/Tennisus"

/* Protopia Ecosystem component */
export interface IProtectionProps {
    mode: PROTECTION_MODE
    item: IFabulaEpisode | IFabulaMedia
    onWin: () => void
    onLose: () => void
}
const Protection = ({item, mode, onWin, onLose, ...props} : IProtectionProps): JSX.Element => {
    switch( mode ) {
        case PROTECTION_MODE.BREAK:
            return <Break item={item} onWin={onWin} onLose={onLose}  mode={mode} />
        case PROTECTION_MODE.HACK:
            return <Hack item={item} onWin={onWin} onLose={onLose}  mode={mode}  />
        case PROTECTION_MODE.CHO0SE:
        default:
            return <></>
    }
}
export default Protection

const Break = ({ item, onWin, onLose } : IProtectionProps) : JSX.Element => {
    let data = { defense:[ 6 ], attack: [ 6 ] }
    if(item.defense! > 6) {
        data = {defense: [6, 6], attack:[ 6 ]}
    }
    else if(item.defense! > 3) {
        data = {defense: [ 6 ], attack: [ 6 ]}
    }
    else  {
        data = {defense: [ 6 ], attack: [ 6, 6 ]}
    }
    return <div className="fabula-break-container">
        <Tennisus
            isActive
            onWin={onWin}
            onLose={onLose}
            dictionary={{}}
            { ...data }
        />
    </div>

}
const Hack = ({ item, onWin, onLose }: IProtectionProps) : JSX.Element => {
    if( !item.protection ){
        return <Callout p-5 intent={Intent.DANGER}>
            {__("Error Episode configuration!")}
        </Callout>
    }
    const ElComponent: FC = miniGames()[ item.protection.type ].component 
    return <div className="fabula-hack-container">
        <ElComponent  {...item.protection.parameters} isActive onWin={onWin}  onLose={onLose} />
    </div>

}