import { eventTypes } from 'src/modules/pe-topos-module/data/mocks/eventTypes'
import { Widgets, __ } from 'src/libs/utilities'
import Moment from "react-moment" 
import { default as ElEventTypeLabel } from "src/modules/pe-topos-module/views/labels/EventTypeLabel"
import { Button, Icon, Intent } from '@blueprintjs/core'
import { IFestival, ILand } from 'src/modules/pe-fest-module/data/interfaces'
import { IFestDataStor, useFestDataStore } from 'src/modules/pe-fest-module/data/store'
import FestivalGallery from 'src/modules/pe-fest-module/views/about/FestivalGallery'

const About = (props: any) : JSX.Element => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const site: ILand = useFestDataStore((state: IFestDataStor) => state.site)
    const isPatternAlarm: boolean = useFestDataStore((state: IFestDataStor) => state.isPatternAlarm)
    const trimMonth = ( date: string ) : string => {
      const d = date.split(" ")
      return d[1]
    } 
    return <>
        <div className="short-container"> 
            <div className="row w-100 btn-group my-3 mx-auto hidden ">
                
            </div>
        </div>
        <div className="short-container pb-5 px-md-0 px-3 ">
            <div className="row">
            <div className=" col-md-4  px-xl-3 px-0 mt-3 mt-xl-0 ">
                <div className="d-flex text-light">
                {
                    festival.startDate
                        ?
                        <div className=" mb-3 w-50">
                            <div className="p-4 d-flex flex-column flex-centered bg-dark-secondary hover">
                                <span className="small">{__("Start date")}</span> 
                                <div className="display-5 title mt-3" style={{ lineHeight: .7 }}>
                                    <Moment locale="ru" format="D">
                                        { parseInt(festival.startDate) * 1000 }
                                    </Moment>
                                </div>
                                <div className="title mb-1">
                                    <Moment locale="ru" format="D MMMM"  filter={ trimMonth }>
                                        { parseInt(festival.startDate) * 1000 }
                                    </Moment>
                                </div>
                                <div className="small">
                                    <Moment locale="ru" format="YYYY года">
                                        { parseInt(festival.startDate) * 1000 }
                                    </Moment> 
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    festival.finishDate
                        ?
                        <div className=" mb-3 w-50">
                            <div className="bg-dark p-4 d-flex flex-column flex-centered hover">                        
                            <span className="small">{__("Finish date")}</span>
                            <div className="display-5 title mt-3" style={{ lineHeight: .7 }}>
                                <Moment locale="ru" format="D">
                                    { parseInt(festival.finishDate) * 1000 }
                                </Moment>
                            </div>
                            <div className="title mb-1">
                                <Moment locale="ru" format="D MMMM" filter={ trimMonth }>
                                    { parseInt(festival.finishDate) * 1000 }
                                </Moment>
                            </div>
                            <div className="small">
                                <Moment locale="ru" format="YYYY года">
                                    { parseInt(festival.finishDate) * 1000 }
                                </Moment>
                            </div>
                            </div>
                        </div>
                        :
                        null
                }
                </div>
            </div>
            <div className="col-md-8 mt-0">
                <div className={` d-flex justify-content-between w-100 mb-1`}> 
                    <ElEventTypeLabel 
                        item={{...site, eventTypes: eventTypes().filter(et => site.event_types.includes(et.title))}} 
                        className="" 
                    />
                    {
                        site.isPattern && !isPatternAlarm &&
                        <Button 
                            minimal
                            intent={Intent.DANGER}
                            className=' pe-surface'
                            icon="style"
                            title={ __("This Event is pattern for over") }
                            onClick={ () => useFestDataStore.setState({ isPatternAlarm: true }) }
                        />
                    }
                </div>
                {
                    site.isPattern && isPatternAlarm && <div className="p-4 alert alert-danger my-3 flex-centered" >
                        <Icon icon="issue" size={45} />
                        <div className="flex-grow-100 text-center">
                            <h4>{__("This Event is pattern for over")}</h4>
                            <div dangerouslySetInnerHTML={{ __html: site.domain_content }} />  
                        </div>
                        <Button 
                            minimal 
                            intent={Intent.DANGER}
                            className='position-absolute top right m-1'
                            onClick={() => useFestDataStore.setState({ isPatternAlarm: false })}
                            icon='cross'
                        />
                    </div>
                }
                <div 
                    className=""
                    dangerouslySetInnerHTML={{ __html : festival.content?.replace(/<\/?span[^>]*>/ig,"") || '' }}
                />
            </div>
            </div>
        </div>
        <Widgets 
            areaName={ "event-about" }
            data={ {} }
            defArea={ null }
        />
        <FestivalGallery />
    </> 
} 

export default About