import { modules } from "@/libs/layouts"
import Module from "./Module"
import { useState } from "react"

const ModulesForm = (props:any) : JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean[]>([])
  const onOpen = (i: number) => {
    setIsOpen( Object.keys(modules()).map((m, n) => i === n ? !isOpen[ i ] : false ) )
  }
  return <div className="layout-state">
  {
    Object.keys(modules()).map((module, i) => {
      return <Module
        isOpen={ isOpen[i] }
        item={ modules()[module] }
        key={ i }
        i={ i }
        onOpen={ onOpen }
      />
    })
  }
  </div>  
}

export default ModulesForm