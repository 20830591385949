 
import { useDataStore } from 'src/modules/pe-basic-module/data/store'
import { GET_SPACES_ACTION } from './actionTypes'
import asyncControllers from './controllers' 


export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    switch (action) {
        case GET_SPACES_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {
                        useDataStore.setState({
                            PEErmakSpace: response.getSpaces,
                            PEErmakMapCellType: response.getMapCellTypes
                        }) 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                ) 
            })
            return promise
        default:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
    }

} 

export const syncAction = (action: string, data: any): any => {
    switch(action) { 
        default:
            return action
    }
    
}