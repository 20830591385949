import { Color, URL } from "src/libs/interfaces/layouts"
import { MANA_TYPE } from "./interfaces"

export interface IManaProps {
    id: MANA_TYPE
    icon: URL
    title: string,
    color:Color
}
export const manas = () : IManaProps[] => {
    return [
        {
            id: MANA_TYPE.MANA,
            icon: "/assets/img/manitu/icons/mana.svg",
            title: "Mana",
            color: "greenyellow"
        },
        {
            id: MANA_TYPE.RICH,
            icon: "/assets/img/manitu/icons/rich.svg",
            title: "Rich",
            color: "Gold"
        },
        {
            id: MANA_TYPE.IMPACT,
            icon: "/assets/img/manitu/icons/impact.svg",
            title: "Impact",
            color: "Crimson"
        },
        {
            id: MANA_TYPE.DEFENCE,
            icon: "/assets/img/manitu/icons/defense.svg",
            title: "Defense",
            color: "HotPink"
        },
    ]
}