import { __ } from "src/libs/utilities";
import { Email, ID, URL, HTML, IMenuItem, POST_STATUS, IPost, Role } from "@/libs/interfaces/layouts"

import {default as UUID} from "node-uuid";
import { IAcademicDiscipline } from "@/modules/pe-game-science-module/data/interfaces";

export interface IPETestCategory extends IPost {

}
export interface IPETest extends IPost {
    is_show_answer: boolean
    is_show_results?: boolean
    is_shuffle?: boolean
    is_timed?: boolean
    duration?: number
    try_count: number
    publish_type?: PE_TEST_PUBLISH_TYPE
    questions: PETestQuestion[]
    testCategory?: IPETestCategory
    pe_academic_discipline : IAcademicDiscipline[]
}
export enum PE_TEST_PUBLISH_TYPE {
    STANDART="STANDART",
    ELECTRONIC_COURSE="ELECTRONIC_COURSE",
    INTRAMURAL_COURSE="INTRAMURAL_COURSE",
}
export interface PETestQuestion extends IPost {
    answers: PETestQuestionAnswer[]
    right_answer_id: ID
    hidden?: boolean
    hardness?: number
    is_deleted?: boolean
    single: boolean
    type: PE_TEST_QUESTION_TYPE
    penalty?: string
    test? : IPETest

}
export enum PE_TEST_QUESTION_TYPE {
    MULTI_CHOOSE="MULTI_CHOOSE",
    TRUE_FALSE="TRUE_FALSE",
    MATCHING="MATCHING",
    
}
export interface PETestQuestionAnswer extends IPost {
    question?: PETestQuestion
    is_deleted?: boolean
    is_subquestion: boolean
    subquestion_answer?: PETestQuestionAnswer
    order: number
}

export enum TEST_CURRENT_MODE {
    START="START",
    FOLLOW="FOLLOW",
    FINISH="FINISH",
    GET_RESULTS="GET_RESULTS",
}

export const defaultTest = (): IPETest => {
    return {
        id: UUID.v4(),
        title: __("New Test"),
        post_content:"",
        questions: [],
        is_show_answer: false,
        try_count: 0,
        pe_academic_discipline : [{
            id: 1111111111,
            title: "Test discipline",
            post_content: "",
            order: -1,
            color: "#C60000"
        }]
    }
}