import { Card, Overlay, Popover, Position } from "@blueprintjs/core"
import { useEffect, useRef, useState } from "react"
import { ID } from "src/libs/interfaces/layouts"
import { __ } from "src/libs/utilities"
import { IErmakMap, IErmakMapCell } from "../../data/interfaces"
import { IErmakStore, useErmakStore } from "../../data/store"
import StoreDrawer from "./StoreDrawer"
import ErmakSlot from "./ErmakSlot"
import ErmakCellItems from "./ErmakCellItems"

/* Protopia Ecosystem component */
export interface IErmakMapCellProps {
    map: IErmakMap
    x: number
    y: number
    id: ID
}
const ErmakMapCell = (props: IErmakMapCellProps): JSX.Element => {
    const ref = useRef(null)
    const mapCellSize: number = useErmakStore((state: IErmakStore) => state.mapCellSize)
    const [item, setItem] = useState<IErmakMapCell | undefined>( undefined )
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
    const [bound, setBound] = useState<DOMRect>({} as DOMRect )
    useEffect(() => {
        setItem( props.map?.cells?.filter(cell => cell.id.toString() === props.id.toString())[0])
    }, [props.map ])

    const toggleOverlay = () => {
        setIsOverlayOpen(!isOverlayOpen)
    }
    const onOpen = () => {
        if( !!ref.current ) {
            const bn: DOMRect = (ref.current! as HTMLElement).getBoundingClientRect() 
            setBound(bn)
            setIsOverlayOpen(!isOverlayOpen)
        }
        
    }
    const onClosed = (node:HTMLElement) => { 
        const n = document.getElementsByClassName("ermal-slot-portal")
        console.log( n[0] )
        document.body.removeChild( n[0] )
    }
    return <div 
        className='pe-ermakmap-cell-container'
        style={{
            minWidth: mapCellSize,
            minHeight: mapCellSize, 
        }}
        ref={ref}
    >
        <Popover
            content={<div className="pe-ermak-map-cell-popover">
                { item?.type?.title || __("Vulgaris cell")}
            </div>}
            position={Position.TOP}
            interactionKind="hover"
            hoverOpenDelay={750}
            className="w-100 h-100 top left position-absolute"
        >
            <div className="w-100 h-100 p-2"
                style={{ 
                    backgroundColor: item?.type?.color
                }}
                onClick={ onOpen }
            >
                <div className="id">
                    { props.id }
                </div>
                <ErmakCellItems cell={item}/>
            </div>
        </Popover> 
        <Overlay 
            isOpen={isOverlayOpen} 
            onClose={toggleOverlay}
            // onClosed={onClosed}
            backdropClassName={`flex-centered`}
            portalClassName={`ermal-slot-portal`}
        >
            <ErmakSlot item={item} bound={bound} />
        </Overlay>
    </div>
}
export default ErmakMapCell