import { default as UUID } from "node-uuid"
import { shuffle } from "src/libs/utilities"
import { IOrganoid, IOrganoidType, cellVar } from "../../../../data/interfaces" 
import { useManituStore } from "../../../../data/store"

const OrganoidGenerator = ( 
    type: IOrganoidType, 
    organoids: IOrganoid[] = []
) :IOrganoid => { 
    const count: number = ~~( Math.random() * (type.max - type.min) ) +  type.min - 1
    const rows: number = useManituStore.getState().matrixRows
    const columns: number = useManituStore.getState().matrixColumns

    let organoid : IOrganoid = {
        title: type.title,
        post_content: type.post_content,
        type, 
        power: count, 
        id: UUID.v4(),
        cells: [] 
    }

    const getStatedCell = () : cellVar => { 
        const randomY = ~~(Math.random() * rows)
        const randomX = ~~(Math.random() * columns)
        return {x:randomX, y: randomY}
    }
    
    const getStartVerified = () : false | cellVar => {
        const cell = getStatedCell()
        const yes: boolean = !organoids.filter((o: IOrganoid) => {
            return !o.cells.filter(c => {
                const x = cell.x
                const y = cell.y
                return c.x !== x && c.x + 1 !== x && c.x - 1 !== x  &&
                    c.y !== y && c.y !== y + 1 &&  c.y !== y - 1
            }).length
        }).length 
        return !yes ? yes : cell
    }
    const verifyStartedCell = () : any => {
        let cell: false | cellVar = false, i:number = 0
        while( !cell && i < 100 ) {
            cell = getStartVerified() 
            i++
        }
        return cell
    }

    const getNextCell = (cells: cellVar[] ): cellVar => {
        // выбираем уже существующую "палубу", к которой приторочим следующую
        const rootCell: cellVar = cells[ ~~( Math.random() * cells.length ) ]
        // отрезаем края матрицы
        let d : string[] = shuffle( distinations ).filter(dd => {
            return  (rootCell.x === 0 && dd !== "left")             ||
                    (rootCell.x === columns - 1 && dd !== "right")  ||
                    (rootCell.y === 0 && dd !== "top")          ||
                    (rootCell.y === rows - 1 && dd !== "bottom") 
        }) 
        // выбираем направление, куда пришьём следующую "палубу" 
        const distination: string   = d[0]
        // возвращаем координаты выбранные для следующей "палубы"
        switch(distination) {
            case "top"      : return {x: rootCell.x , y: rootCell.y - 1}
            case "bottom"   : return {x: rootCell.x , y: rootCell.y + 1} 
            case "left"     : return {x: rootCell.x - 1 , y: rootCell.y} 
            case "right"    : 
            default         : return {x: rootCell.x + 1 , y: rootCell.y} 
        } 
        // TODO: default -- ошибочный. Выбрать другой 
    }

    const verifyNextCell = (cells : cellVar[] ): false | cellVar => {
        const cell = getNextCell( cells )
        if( !!cells.filter( c => c.x === cell.x && c.y === cell.y ).length ) {
            return false
        }
        const yes: boolean = !organoids.filter((o: IOrganoid) => {
            return !o.cells.filter(c => {
                const x = cell.x
                const y = cell.y
                return c.x !== x && c.x + 1 !== x && c.x - 1 !== x  &&
                    c.y !== y && c.y !== y + 1 &&  c.y !== y - 1
            }).length
        }).length 
        return !yes ? yes : cell
    }
    const getVerifiedNextCell = ( cells : cellVar[] ) : any => {
        let cell: false | cellVar = false, i:number = 0
        while( !cell && i < 100 ) {
            cell = verifyNextCell( cells ) 
            i++
        }
        return cell
    } 
    organoid.cells = [ verifyStartedCell() ] 
    Array(count || 0).fill(1).forEach(c => {
        const next : false | cellVar = getVerifiedNextCell( organoid.cells )
        if(!!next) {
            organoid.cells.push(next) 
        }
    })
    return organoid
}  
export default OrganoidGenerator

const distinations: string[] = ["top", "bottom", "right", "left" ]

 