import { useEffect } from "react";

/* Protopia Ecosystem component */
export interface IRoundSliderProps {
    value: number
    radius?: number
}
const RoundSlider = (props: IRoundSliderProps): JSX.Element => {
    useEffect(() => {
        var canvas = document.getElementById('canvas')
        Slider(canvas, { 
            handleRadius: 0.1, 
            mainArcRadius: props.radius || 80,
            totalScalePoints: 10,
            lineWidth: 12,
            scalePointLabels: [ ],
            value: props.value || 0
        });
    }, [props.value, props.radius])
    return <div className='pe-round-slider-container'>
        <canvas 
            id="canvas" 
            width="350"    
			height="250" 
        />
    </div>
}
export default RoundSlider

function Slider( canvas : any, options : any) {
    let value = options.value 
    let offsetX = options.value * 2
    let isDragging = false;
  let canvasX = canvas.getBoundingClientRect().x;
    let canvasContext: any = null;
    let totalScalePoints = options.totalScalePoints || 6;
  let scalePointLabels = options.scalePointLabels || ['1','2','3'];
    let handle = {
        x: 100,
        y: 0,
        radius: options.handleRadius || 20,
        color: options.handleColor || "blue"
    };
    let mainArc = {
        x: 200,
        y: 200,
        radius: options.mainArcRadius || 100,
        startAngle: -Math.PI,
        endAngle: 0,
        color: options.mainArcColor || 'rgba(0,200,40,0.5)'
    };
    
    // 	Drawing the main slider arc
    const drawMainArc = () => {
        canvasContext.save();
        canvasContext.beginPath();
        canvasContext.arc(mainArc.x, mainArc.y, mainArc.radius, mainArc.startAngle, mainArc.endAngle, 0);
        canvasContext.lineWidth= options.lineWidth || 20; 
        canvasContext.strokeStyle = mainArc.color;
        canvasContext.stroke();
        canvasContext.restore();
    };

    const drawMainArcPath = () => {
        canvasContext.save();
        canvasContext.strokeStyle = "#555";
        let gradient = canvasContext.createRadialGradient(mainArc.x ,mainArc.y,mainArc.radius - mainArc.radius/2,mainArc.x,mainArc.y,mainArc.radius);
        gradient.addColorStop(0.25,"rgba(0,0,0,0)");
        gradient.addColorStop(0.50,"rgba(0,0,0,0)");
        gradient.addColorStop(0.75,"rgba(0,0,0,0)");
        gradient.addColorStop(0.80,"black");
        gradient.addColorStop(0.81,"rgba(0,0,0,0)");
        gradient.addColorStop(0.83,"rgba(0,0,0,0)");
        gradient.addColorStop(0.85,"rgba(0,0,0,0)");
        gradient.addColorStop(0.95,"rgba(0,0,0,0)");
        gradient.addColorStop(1,"rgba(0,0,0,0)");
        canvasContext.beginPath();
        canvasContext.fillStyle = gradient;
        canvasContext.arc(mainArc.x, mainArc.y, mainArc.radius + handle.radius/2, mainArc.startAngle, -Math.PI*2, 0);
        canvasContext.stroke();
        canvasContext.fill();
        canvasContext.restore();
    }
    
    // 	draw the value of slider
    const drawValue = () => {
        canvasContext.save();
        canvasContext.translate(mainArc.x,mainArc.y);
        canvasContext.font = "24pt Arial";
        canvasContext.fillStyle = "rgba(0,0,0,1)";
        canvasContext.fillText(value, 0 - canvasContext.measureText(value).width/2,0);
        canvasContext.restore();
    };
    
    // 	draw scales
    const drawScales = () => {
        canvasContext.save();
        canvasContext.translate(mainArc.x,mainArc.y);
        canvasContext.font = "10pt Arial";
        canvasContext.fillStyle = "#555";
        canvasContext.strokeStyle = "#555";
        canvasContext.lineWidth = 1;
        var scalePointInc = Math.ceil(totalScalePoints/scalePointLabels.length);
        var tempScalePoints = scalePointLabels.concat();
        const radian = (Math.PI/totalScalePoints);
        for(var i = 0; i <= totalScalePoints; i++) {
            canvasContext.beginPath();
            canvasContext.moveTo(-130,0);
            canvasContext.lineTo(-140,0);
            canvasContext.stroke();
            if(i%scalePointInc === 0 ){
                let scaleValue = tempScalePoints.shift();
                canvasContext.fillText(scaleValue === undefined ? "--" : scaleValue, -170, 0)
            }
            canvasContext.rotate(radian);
        }
        canvasContext.restore();
    };
    
    // 	draw the handle
    const drawHandle = () => {
            canvasContext.save();
            canvasContext.translate(mainArc.x,mainArc.y);
            canvasContext.beginPath();
            canvasContext.lineWidth = 5;
            canvasContext.fillStyle = handle.color;
            canvasContext.rotate(mainArc.endAngle);
            canvasContext.arc(handle.x,handle.y, handle.radius,0,Math.PI*2, false);
            canvasContext.fill();
            canvasContext.restore();
    };
    

    // 	redraw on whole canvas
    const redraw = () => {
        canvasContext.clearRect(0, 0, canvasContext.width, canvasContext.height);
        canvasContext.canvas.width = canvasContext.canvas.width;

        mainArc.endAngle = mainArc.startAngle + offsetX/200 * Math.PI; 

        drawMainArc();
        // drawMainArcPath();
        // drawValue();
        // drawScales();
        drawHandle();
    };


// 	Drag events when the slider is dragged by user
    const onDragStart = ( event: any ) => {
        // console.log(canvasX);
        let newOffset = event.clientX - canvasX - offsetX;
        // console.log("CLIENTX:", event.clientX);
        // console.dir(event);
        //console.log("CanvasX:" + canvasX);
        // console.log("OFFsetx:", offsetX);
        //console.info("New offset:", newOffset);
        if (newOffset >= handle.x - handle.radius && newOffset <= handle.x + handle.radius)
                isDragging = true;
    };
    const onDragStop =  () => {
        isDragging = false;
    }; 
    const onDrag = (event: any) => { 
          if (isDragging) {
        if (canvas.getContext) {
            let x = event.clientX - canvasX - 100;
            if (x < 0) x = 0;
            if (x > mainArc.x) x = mainArc.x;
            value = Math.round(x / 2);
            offsetX = x;
            redraw();
        }
    }
    };

    const init = () => {
        // this.canvas = canvas;
        canvasContext = canvas.getContext('2d');
        //canvas.onmousedown = onDragStart;
        //canvas.onmouseup = onDragStop;
        //canvas.onmousemove = onDrag;
        redraw();
    };
    
    init();
}