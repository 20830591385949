
import BasicState from "@/libs/basic-view"
import EventSettingForm from "./event-setting/EventSettingForm"

export default class EventSettingView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <EventSettingForm {...this.props} />  
        </div>
    }
}