import React, { useState } from 'react'  
import { Button } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'  
import { ICritery, IFestival, IProject, IRating } from '@/modules/pe-fest-module/data/interfaces'
import CriteryCommentaryDialog from '../projectData/CriteryCommentaryDialog'
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from '@/modules/pe-fest-module/data/store'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import { actions } from '@/modules/pe-fest-module/data/actions'
import { UDATE_PROJECT_RATING_ACTION } from '@/modules/pe-fest-module/data/actionTypes'

interface ICriteryDescriptionDisplayProps {
    isExpert: boolean
    critery: ICritery
    description: string
    onOpen: () => void
}

const CriteryDescriptionDisplay = (props: ICriteryDescriptionDisplayProps) :JSX.Element | null =>  {
    const project: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject) 
    const user:IUser = useMainStore((state:IState) => state.user)
    const festival:IFestival = useFestDataStore((state:IFestDataStor) => state.festival)
    const rating: IRating | null = project.ratings?.filter(( rating:IRating ) => {
        return rating.criteryId === props.critery.id && rating.expertId === user.id 
    })[0] 
    
    const maxRating: number = festival.isIndependentGradeCritery ? props.critery.max_rating : festival.maxRating || 5
    const [isOpen, setIsOpen] = useState( false) 
    if( !props.isExpert ) return null

    const onClose = () => {}

    const onSend = (description: string) => {
        const data: any = {
            id: "-1",
            item : { 
                expertId: user.id,
                display_name: user.display_name,
                criteryId: props.critery.id,
                memberId: project.id,
                description,
                rating: rating?.rating || 0  
            }
        }
        //console.log( data )
        actions( UDATE_PROJECT_RATING_ACTION, data )
    }

    return <div className="text-secondary font-weight-light pe-surface">
        <span className=" descr-label mr-3">
            {__("Your description:")}
        </span>
        <strong className="font-italic critery-descr">
            { props.description }
        </strong>
        <Button
            minimal
            icon="edit"
            title={ __( "Edit" ) }
            onClick={() => setIsOpen( !isOpen )}
        >            
        </Button>     
        <CriteryCommentaryDialog
            description={ rating?.description || "" }
            isOpen={ isOpen }
            onOpen={ () => setIsOpen( false) }
            lockWithoutComment={ maxRating === rating?.rating || false }
            onSend={onSend}
            sendLabel={"send label"}
            isHideChangeDescr={false}
        />        
    </div>
} 

export default CriteryDescriptionDisplay