import { __ } from "@/libs/utilities"
import ExpertDescriptions, { Quotes, TextArea } from "../ExpertDescriptions"
import { isRole } from "@/settings/zustand/user"
import DescrButtonSwipe from "./DescrButtonSwipe"
import TextAreaMobile from "./TextAreaMobile"
import ProjectMobileQuotes from "./ProjectMobileQuotes"
//import Swiper from "swiper/react"

const { A11y,  } =  require("swiper/modules") 
const { Swiper, SwiperSlide, } =  require("swiper/react")  

/* Protopia Ecosystem component */
export interface IProjectMobileDescriptionsProps {

}
const ProjectMobileDescriptions = (props: IProjectMobileDescriptionsProps): JSX.Element => {
    return <div className='pe-project-mobile-descriptions-container'>
        <Swiper 
            modules={[ A11y]}
            spaceBetween={0}
            slidesPerView={1} 
            direction="vertical"
        >
            <SwiperSlide>
                <div className="screen transparent" style={{ paddingBottom: 100 }}>
                    <div className="h-100">
                        <div className="h-100">
                            <div className='row'> 
                                <div className="col-12">
                                    <div className="display-7 h mb-4 text-center">
                                        {__("Expert's parting words for the authors of Project")}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="project-mobile-quotes-container w-100 pb-4 ">
                                        <ProjectMobileQuotes />
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <DescrButtonSwipe />
                    </div>
                </div>
            </SwiperSlide>
            {
                isRole(["Expert"])
                    &&
                    <SwiperSlide>
                        <div className="screen transparent " >
                            <div className="overflow-y-auto h-100">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="lead">
                                            { __( "Your parting words for the authors of Project" ) }
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-2">
                                    <TextAreaMobile />
                                </div>  
                            </div>
                        </div>
                    </SwiperSlide>
            }
        </Swiper>
    </div>
}
export default ProjectMobileDescriptions