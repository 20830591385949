import { Button, Intent, Position, Tooltip } from "@blueprintjs/core";
import { AppToaster } from ".";
import { __ } from "../utilities";

export interface IClipboardCopyProps {
    data: any
    label?: string
    position?: Position
    answer?: string
    type?: "button" | "icon"
    fill?: boolean
}
const ClipboardCopyBtn = (props: IClipboardCopyProps) : JSX.Element => {
    const copy = () =>
    {
        const data = JSON.stringify( props.data ) 
        const elemDiv = document.createElement('div');
        elemDiv.style.cssText = 'position:absolute; z-index:-100; width:100%; top:0; left:0;'
        elemDiv.setAttribute("id", "myInputCont")
        const textarea = document.createElement("textarea")
        textarea.setAttribute("id", "myInput")
        elemDiv.appendChild(textarea)
        textarea.value = data 
        document.body.appendChild( elemDiv )
		const copyText: HTMLTextAreaElement = document.getElementById("myInput") as HTMLTextAreaElement
		copyText.select()
		copyText.setSelectionRange(0, 99999999999999999999)
		document.execCommand("copy")
        document.getElementById("myInputCont")!.remove()
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick", 
			message: __( props.answer || "Copied success" ),
		})
    }
    return props.type === "button"    
        ?
        <Button minimal onClick={ copy } fill={!!props.fill}>
            <i className="fas fa-clone mr-2" /> 
            { __( props.label || "Copy to clipboard") }
        </Button>
        :
        <Tooltip content={ __( props.label || "Copy to clipboard") } position={props.position} >
            <div className='btn btn-link text-secondary w-100 btn-sm ' onClick={ copy } >
                <i className="fas fa-clone" /> 
            </div>
        </Tooltip>
} 

export default ClipboardCopyBtn 