import { widgets } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { Button, Popover } from "@blueprintjs/core" 

const AddWidgetForm = ({onAdd}: any): JSX.Element => {
    const widgetsDatas: any = widgets() || {}
    const wList:any[] = Object.keys( widgetsDatas ).map((w:string, i) => {
        return <Button fill key={w} onClick={ onAdd} className="py-3" >
        {__(widgetsDatas[w].title)}
        </Button>
    }) 
    const eList: JSX.Element[] = Object.keys( widgetsDatas ).map((w:string, i) => {
        return <Button fill key={w} onClick={ onAdd} className="py-3" >
        {__(widgetsDatas[w].title)}
        </Button>
    }) 
        return <div className="admin-widgets-add">
        <Popover 
            content={(
                <div className="p-0">
                <div className="p-0 max-height-250 overflow-y-auto">
                    <div style={{ overflowX: "hidden", overflowY: "auto", marginBottom: 10 }}>
                        {wList}
                    </div>
                </div>
                </div>
            )}
        >
            <div className="btn btn-link text-secondary btn-sm " >
                {__("Add widgets")}
            </div>
        </Popover>
        <Popover 
            content={(
                <div className="p-0">
                <div className="p-0 max-height-250 overflow-y-auto">
                    <div style={{ overflowX: "hidden", overflowY: "auto", marginBottom: 10 }}>
                        {eList}
                    </div>
                </div>
                </div>
            )}
        >
            <div className="btn btn-link text-secondary btn-sm " >
                {__("Add extentions")}
            </div>
        </Popover>
    </div>
}

export default AddWidgetForm