import { Icon, Intent } from '@blueprintjs/core'
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import {CodeEditor} from "src/libs/useful"
import { useId } from 'react'
import { __ } from '@/libs/utilities'

const PostStatus = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<PostStatusEnabled {...props} />}
        desabledForm={<PostStatusDesabled {...props} />}
    /> 
}  

export default PostStatus

export const PostStatusEnabled = (props: IScalarForm) : JSX.Element =>  {
    
    const id = useId()
    const onChange = (evt: any) => {
        const { checked } = evt.currentTarget 
        if( props.onChange )
        {
            props.onChange( checked ? "publish" : "draft",  props.field, "" )
        }   
    }

    return <div className=" d-flex justify-content-center my-4">
        <input 
            id = {id} 
            type = "checkbox" 
            className = "_checkbox m-0" 
            checked = {  props.value } 
            onChange={onChange}
        />
        <label htmlFor={id} className="m-0">
            { __( props.value === "publish" ? "Publish" : "Draft")}
        </label>  
    </div>
}

export const PostStatusDesabled = (props: IScalarForm) : JSX.Element => {
    return props.value === "publish"
        ? 
        <Icon icon="tick" size={16} intent={Intent.SUCCESS} />
        :
        <Icon icon="cross" size={16} intent={Intent.DANGER} />
}