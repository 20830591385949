import { isRole } from "@/settings/zustand/user"
import { __, data_type_admin_url } from "@/libs/utilities"  
import { Button } from "@blueprintjs/core/"
import { Link } from "react-router-dom"

const AdminFeedLink = (props: any): JSX.Element => {
    const route = data_type_admin_url( props.feed_data_type )   
    return isRole(["administrator"])
        ?
        <Link to={`${route}`}>
            <Button minimal className="hint hint--left mr-2" data-hint={__("Administration")}> 
                <i className="fas fa-unlock-alt" />            
            </Button>
        </Link>
        :
        <></> 
} 
export default AdminFeedLink 