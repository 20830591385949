import React from 'react' 
import { Outlet, useParams } from 'react-router' 
import { NavLink } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { component_url } from 'src/libs/utilities/links'
import { isRole } from 'src/settings/zustand/user'
import ContactForm from 'src/modules/pe-fest-module/views/about/ContactForm'
import { IFestival } from 'src/modules/pe-fest-module/data/interfaces'
import { IFestDataStor, useFestDataStore } from 'src/modules/pe-fest-module/data/store'

const AboutContainer = ( ) : JSX.Element => {
    const {landId} = useParams()
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const bg = festival.defaultThumbnail 
        ?
        {
            backgroundImage: `url(${ festival.defaultThumbnail || "/assets/img/fest/fest-default.jpg" })`, 
        } 
        :
        { 
            width:"100%", 
            height:360,
            background: "linear-gradient(rgb(166 166 166) 0%, rgb(230 230 230) 100%)",
            backgroundImage: `url(/assets/img/fest/thumbnails/fest-default.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center"
        }
    const getTitleLayout = () =>
    {  
        return <span className="single-data-type-title">
            { festival.title?.toString() }
        </span>
    }
    const toAdmin = `${component_url("FestivalAdminListView")}/${landId}`
    return  <div className=" w-100 ">
        <div style={ bg } className="single-data-type-thumbnail position-relative">
            <div className="short-container single-data-type-thumbnail-container">
                <div className="main-fest-contacts h-100">
                    <ContactForm />
                </div>
                <div className="single-data-type-thumbnail-titles container ">  
                    { getTitleLayout() } 
                </div>
                {
                    isRole("administrator") && <NavLink
                        to={toAdmin}
                        className={"position-absolute right bottom"}
                    >
                        <Button minimal icon="unlock" />
                    </NavLink>
                }
                
            </div>
        </div>
        <Outlet />  
    </div>
} 

export default AboutContainer