import IScalarForm from "@/libs/scalars/interface/IScalarForm"
import { IFestival } from "../../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../../data/store" 
import { __ } from "@/libs/utilities"
import ExtendFieldSingleForm from "./ExtendFieldSingleForm"

const ExtendedFieldsEnabled = ( props: IScalarForm ): JSX.Element => {
    const festival:IFestival = useFestDataStore((state:IFestDataStor) => state.festival)
    const onChange = ( value: any, i: number, n: number ) => {
        //console.log( value, i, n )
        // снимаем флаг readonly со всех уже заполенных полей
        let newValue: any[][] = [ ...(props.value || []) ]
            .map(arr1 => [...(arr1 || []) ])
        console.log( [...newValue ] )
        if( !newValue[i]) {
            newValue[i] = []
        }
        //newValue[i][n] = value 
        //console.log( [...newValue ] )
        if( props.onChange){
            props.onChange(newValue, props.field, "")
        }
    }
    return <div className="w-100 my-3">
    {
        festival.extendedFields.map((extField: any, i: number) => {
            return <div className="row" key={i}>
                <div className="col-md-4  layout-label">
                {
                    __( extField.name )
                }
                </div>
                <div className="col-md-8 layout-data">
                    <ExtendFieldSingleForm
                        item={props.value[i]}
                        matrix={extField}
                        i={i}
                        onChange={(value: any, n: number) => onChange( value, i, n )}
                    />
                </div>
            </div>
        })
    }
    </div>
}

export default ExtendedFieldsEnabled