import React, { useState } from 'react' 
import FieldInput, {SCALAR_TYPES} from '@/libs/scalars'
import Moment from 'react-moment'
import { __ } from '@/libs/utilities'
import { Dialog, Tag } from '@blueprintjs/core'
import JSONRowElement from './JSONRowElement'
import { getVisibleValue, schema } from 'src/libs/layouts'
import DataTableCellFooter from './DataTableCellFooter'
import chroma from "chroma-js"

export interface IDataTableCellProps {
    thread: string
    item: any
    type: string
    apollo_fields: any
    data_type:string 
    className?: string
}
const DataTableCell = (props: IDataTableCellProps) : JSX.Element => { 
    return <td key={props.thread} data-thread={props.thread} className={`p-1 ${ props.className }`}>
        <DataTypeCellType 
            itemCell={props.item[ props.thread ]} 
            thread={props.thread} 
            type={ props.type }
            data_type={ props.data_type }
            apollo_fields={ props.apollo_fields }
        /> 
        <DataTableCellFooter 
            itemCell={ props.item[ props.thread ] } 
            item={ props.item }
            thread={ props.thread } 
            type={ props.type }
            data_type={props.data_type}
        />
    </td>
} 
export default DataTableCell

export const DataTypeCellType = (props: any) : JSX.Element => {
    const [isOpen, setOpen] = useState(false)
    
    switch( props.type ) {
        case SCALAR_TYPES.RADIO:
            return props.apollo_fields.values
                ? 
                props.apollo_fields.values.filter((e: any) => ( 
                    e._id
                        ? 
                        e._id === props.itemCell
                        : 
                        e === props.itemCell
                ))
                    .map(
                        ( ee: any ) => (
                            ee._id
                                ? 
                                __( ee.title )
                                : 
                                __( ee )
                        )
                    )[0]
                : 
                props.itemCell
        case SCALAR_TYPES.CHECKBOX:
            return Array.isArray( props.itemCell )
                ? 
                props.itemCell.map(( e: any, i: number ) => {
                    const elem = typeof e === "string" ? { id: e, title: e } : e 
                    return <Tag key={i}>{elem.title}</Tag>
                })
                : 
                (props.itemCell || "").toString()
        case SCALAR_TYPES.BOOLEAN:
            return <div className={`text-center data-${ props.thread }`} style={{ ...props.style }}>
                <i className={props.itemCell ? "fas fa-chevron-down text-success" : "fas fa-times text-danger"} />
            </div>
        case SCALAR_TYPES.ID:
            return <div
                className={`px-1 data-${ props.thread }`}
                title={ props.itemCell }
                style={{
                    maxWidth: 100,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }}
            >
                { props.itemCell }
            </div>
        case SCALAR_TYPES.DATE:
            const txt = props.itemCell
                ?
                <Moment locale="ru" format="D MMMM YYYY">
                    {new Date(props.itemCell * 1000)}
                </Moment>                    
                : 
                __( "Date not defined" )
            return <div className={` data-${ props.thread } `}>{txt}</div>
        case SCALAR_TYPES.RGB:
        case SCALAR_TYPES.COLOR:
            return <div 
                className={` data-${ props.thread } `}
                style={{
                    width: 17,
                    height: 17,
                    backgroundColor: props.itemCell,
                    border: "1px solid #00000020",
                    outline: "1px solid #00000020",
                    outlineOffset: 2,
                    ...props.style,
                }}
            />
        case SCALAR_TYPES.GEO:
            const geo = Array.isArray(props.itemCell) ? props.itemCell : []
            return <div className={`small opacity_5 data-${props.thread}`} style={{ ...props.style }}>
                <div>{ geo[0] }</div>
                <div>{ geo[1] }</div>
            </div>
        // case SCALAR_TYPES.MEDIA:
        //     return <div 
        //         className={ `thubnail little-square  hover data-${ props.thread }` }
        //         style={{ backgroundImage:`url(${ props.itemCell })`}} 
        //     />
        case SCALAR_TYPES.JSON:
            const json = !!props.itemCell ? JSON.stringify(JSON.parse( props.itemCell ), null, 2) : ""
            return <JSONRowElement
                style={props.style}
                json={json}
                col={props.itemCel}
            />
        case SCALAR_TYPES.EXTERNAL:
            const componentSchemaName = schema()[ props.data_type ].apollo_fields[ props.thread ].component  
            const visibled_value = getVisibleValue( componentSchemaName )
            const vv = visibled_value || SCALAR_TYPES.TITLE
            // console.log( componentSchema.visibled_value )
            return props.itemCell 
                ? 
                <Tag className="small" minimal>{props.itemCell[vv]}</Tag>
                : 
                <>Unknown type { props.data_type }</>
        case SCALAR_TYPES.ARRAY:
            // console.log( props.itemCell )
            if(!Array.isArray(props.itemCell)) return <>No array data</>            
            if(props.apollo_fields.component === SCALAR_TYPES.STRING)
            {
                return props.itemCell.map((c: string) => <Tag className="mr-1 mb-1" minimal key={c}>
                    { c }
                </Tag>)
            } 
            if(props.apollo_fields.component === SCALAR_TYPES.ARRAY)
            {
                const color_stretch = props.apollo_fields.color_stretch
                    ?
                    props.apollo_fields.color_stretch
                    :
                    ["#498957","#2A4858", 15]
                const stretch: string[] = chroma.scale( [color_stretch[0], color_stretch[1]] )
                    .mode('lch')
                    .colors(color_stretch[2]) 
                return <div className=' my-1'>
                {
                    props.itemCell.map((c: any[], i: number) => {
                        
                        const backgroundColor = stretch[ i % color_stretch[ 2 ] ]
                        const color = chroma(backgroundColor).luminance() < 0.4
                            ?
                            "text-light"
                            :
                            "text-force-black"
                        return <div className='d-flex flex-wrap mb-1' key={i}>
                        {
                            c?.map( (cc: string, kk:number) => {
                                return <Tag 
                                    round 
                                    className={`px-3 mr-1 mb-1 pointer ${color}`} 
                                    style={{ backgroundColor, maxWidth: 170 }}
                                    title={ (cc || "").toString() }
                                    minimal 
                                    key={kk}
                                >
                                    <span className='small'>{ cc }</span>
                                </Tag>
                            })
                        }
                        </div>
                    }) 
                }
                </div>
            } 
            const visibled_value2 = getVisibleValue( props.apollo_fields.component )
            const vv2 = visibled_value2 || SCALAR_TYPES.TITLE
            return props.itemCell.map((e: any, i: number) => (
                <Tag className="mr-1 mb-1" minimal key={i}>
                    {e[vv2]}
                </Tag>
            ))
        case SCALAR_TYPES.YOUTUBE_ID: 
            return <div 
                className={` data-${ props.thread } `}
                style={{ ...props.style }}
                
            >
                <div 
                    style={{
                        width: 170,
                        height: 80,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(https://img.youtube.com/vi/${ props.itemCell }/0.jpg)`,
                        cursor:"pointer",

                    }}
                    className="d-flex align-items-end"
                    onClick={() => props.itemCell ? setOpen(!isOpen) : null}
                >
                    <div className="small bg-light p-1 bg-dark text-light" dangerouslySetInnerHTML={{ __html: props.itemCell }} ></div>
                </div>
                
                <Dialog
                    isOpen={ isOpen }
                    onClose={ () => setOpen(!isOpen) }
                >
                    <iframe 
                        width="800" 
                        height="360" 
                        src={`https://www.youtube.com/embed/${ props.itemCell }`} 
                        title="YouTube video player"
                        allowFullScreen
                    />
                </Dialog>
            </div>
        case SCALAR_TYPES.NUMBER:
            return <div 
                className={` data-${ props.thread } `}
                style={{ ...props.style, textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: props.itemCell || 0 }} 
            />
        case SCALAR_TYPES.LINK:
        case SCALAR_TYPES.PHONE:
        case SCALAR_TYPES.URL:
        case SCALAR_TYPES.EMAIL:
        case SCALAR_TYPES.ADDRESS:
        case SCALAR_TYPES.SLIDER:
        case SCALAR_TYPES.INT:
        case SCALAR_TYPES.HTML:
        case SCALAR_TYPES.TEXT:
        case SCALAR_TYPES.STRING:
            return props.thread === "title"
                ? 
                <h4 
                    className={` data-${ props.thread } `}
                    style={{ ...props.style }} 
                >
                    {props.itemCell}
                </h4>
                :
                <div 
                    className={` data-${ props.thread } `}
                    style={{ ...props.style }}
                    dangerouslySetInnerHTML={{ __html: props.itemCell }} 
                /> 
        default:
            return <FieldInput 
                type={props.type} 
                value={props.itemCell}
            />
    }
}