import { MATRIX_MODE } from "../../../data/interfaces"
import { IOpponent } from "src/modules/pe-ludens-module/data/interfaces"
import Matrix from "."
import { useParams } from "react-router"
import { __ } from "src/libs/utilities"

const OpponentMatrix = ({users}: any) : JSX.Element => {
    const params = useParams();
    const user: IOpponent = users.filter( ( u: IOpponent ) => u.id.toString() === params.userId?.toString() )[0]

    return user 
        ?
        <Matrix user={user} mode={MATRIX_MODE.ENEMY} />
        :
        <div className="alarm alarm-danger p-5 lead">
            {
                __("No opponent exists")
            }
        </div>
} 

export default OpponentMatrix