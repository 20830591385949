import { Callout, Intent } from "@blueprintjs/core"
import IScalarForm from "../../interface/IScalarForm"
import { __ } from "@/libs/utilities"

const GalleryDesabled = (props: IScalarForm): JSX.Element => {
    if(Array.isArray(props.value)) {
        return <Callout intent={ Intent.NONE }>
            { __( "Elements not exists" ) }
        </Callout>
    }

    return <div className='pe-gallery-container d-flex flex-wrap'>
    {
        props.value.map((img: URL) => {
            return <div 
                key={img.toString()}
                style={{
                    backgroundImage: `url(${ props.value })`,
                    backgroundSize: "cover",
                    width: props.width ? props.width : 160,
                    height: props.height ? props.height : 160,
                    opacity: 0.8,
                    margin: 6,
                    cursor:"pointer"
                }}
                className="media-input"
            >

            </div>
        })
    }    
    </div>
} 
export default GalleryDesabled