import React, { useState } from "react"
import { Button,  Classes, Dialog, Intent, Position, OverlayToaster } from "@blueprintjs/core"  
import { __ } from "../utilities"


export const AppToaster = OverlayToaster.create({ 
  position: Position.BOTTOM_RIGHT,
  maxToasts: 4,
  className: "z-index-100",
  usePortal:true
})

 

export  const DeleteButton = (props: IDeleteButtonProps) => {
  const [isRemoveDialog, setRemoveDialog] = useState(false)
  const onRemoveDialog = () => {
    setRemoveDialog( !isRemoveDialog )
  }
  const onRemove = () => {
    setRemoveDialog( !isRemoveDialog )
    props.onRelease()
  }
  return props.isDisabled 
    ? 
    <span /> 
    :
    <>
      <div className="btn bg-danger tx-wt" onClick={ onRemoveDialog}>
        <i className="fas fa-trash-alt mr-2" />
        {" "}
        {__("Delete")}
      </div>
      <Dialog
        icon="trash"
        isOpen={ isRemoveDialog }
        onClose={ onRemoveDialog}
        title={`${__("Do you realy want delete ") + props.post_title}?`}
      >
        <div className="pt-dialog-footer">
          <div className={Classes.DIALOG_BODY} />
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>

              <Button
                onClick={ onRemoveDialog}
                text={__("oh, No! Sorry")}
              />
              <Button
                intent={Intent.PRIMARY}
                onClick={ onRemove }
                text={__("Yes, I want finaly delete this")}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </> 
} 
interface IDeleteButtonProps {
  post_title: string
  isDisabled: boolean
  onRelease: () => {}
}  
