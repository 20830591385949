import React from "react" 
import { Link } from "react-router-dom"
import Layouts from "." 
import { IMenuItem, IRoutingData } from "../interfaces/layouts"
import {default as UUID} from "node-uuid";
import { __ } from "../utilities"
import { useMainStore } from "@/settings/zustand";

export function concatRouting() : IMenuItem[] {
  let routing:IMenuItem[] = []
  for (const key in Layouts().routing) {
    routing = routing.concat(get(key))
  }
  return routing
}

export const getRealRoute = (item: IMenuItem) : string => {

  if( Array.isArray(item.route_matrix) && item.route_matrix.length === 2 ) {
    const replacer: string  = useMainStore.getState()[ item.route_matrix[1] ]
    const url: string       = replacer 
      ? 
      item.route_matrix[0].replace("%s", replacer) 
      : 
      item.realRoute
        ? 
        `${ item.realRoute }` 
        : 
        ""
    return url 
      ? 
      `${ item.realRoute }` 
      : 
      ""
  }
  return item.realRoute 
 
}
const getSignleRoute = ( data:IMenuItem, n: number, routes: IMenuItem[] ) : IMenuItem => {
  const item: IMenuItem = { 
    ...data, 
    i: data.i ? data.i : n
  } 
  if ( !!item.children?.length ) { 
    item.children = item.children.map((menuItem:IMenuItem, i: number) => {
      menuItem = {
        ...menuItem,
        route:        `${ getRoute(item) }/${ menuItem.route }`,
        parent_route: item.route,
        realRoute:    `${ getRealRoute(item) }/${ menuItem.route }`,
        i:            item.i * 1000 + i
      }
      const parent:IMenuItem = getSignleRoute(menuItem, i, routes) ;
      routes.push( parent)
      return parent
    }) 
  } 
  return item
}


export const getAllRoutes = () : IMenuItem[] => {
  const routes : IMenuItem[] = []; 
  concatRouting().forEach((menu:IMenuItem, i: number) => {
    routes.push( getSignleRoute(menu, i, routes) ) 
  }) 
  return routes
}

export const getRouteById = (id: string) : IMenuItem | null => { 
  return getAllRoutes().filter(route => { 
    return route.id === id
  })[0]
}

export function getSingleRoute(data_type : string) : IMenuItem | null{ 
  return getAllRoutes().filter( e => e.singled_data_type === data_type)[0]
}

export const getFeedRoute = ( data_type : string ) =>
{
  return getAllRoutes().filter((e) => e.feed_data_type === data_type)[0]
  
}

export const getRouteByComponent = (componentName: string) : IMenuItem | null => {
  return getAllRoutes().filter( e => e.component === componentName)[0]
}

export function getAdminRouteLink(data_type : string, content : JSX.Element) { 
  const dt:any[] = getAllRoutes().filter((e) => e.data_type === data_type) 
  if (dt[0]) {
    return <Link to={ dt[0].route }>
      {content}
    </Link> 
  }
  return content
}
export function getByRoute(route: any) {
  const rountArray = route.split("/")
  if (rountArray[0] === "") {
    rountArray.splice(0, 1)
  }
  const components: any = {}
  function importAll(r:any) {
    r.keys().forEach((key: string) => {
      const key1 = key.replace("./", "").split(".").slice(0, -1).join(".")
      components[key1] = r(key)
    })
  }
  //importAll(require.context("../libs/", false, /\.js$/))

  function getContent(route:any) {
    // return route.title;
    if (route.component) {
      const Component = components[route.component].default
      return (
        <Component
          {...route}
          onChangeStyle={(style: any) => null}
        />
      )
    } if (route.html_source) {
      const HTMLSourceState = components.HTMLSourceState.default
      return (
        <HTMLSourceState
          {...route}
          onChangeStyle={(style:any) => null}
        />
      )
    } if (route.html) {
      const HTMLState = components.HTMLState.default
      return (
        <HTMLState
          {...route}
          onChangeStyle={(style:any) => null}
        />
      )
    }
  }

  const ret = concatRouting().map((e:any) => {
    if (e.route === rountArray[0]) {
      if (rountArray.length === 1) {
        console.log(e)
        return getContent(e)
      }
      if (e.route === rountArray[1]) {
        return null
      } else {
        return null
      } 
    }
    return null
  })
  return ret
}

export function routeData(e:any = undefined,
  child:any = undefined,
  grandchild:any = undefined,
  forceRoute:any = undefined) {
  let preroute; let route; let noexact_route; let currentE; let
    capability
  if (grandchild) {
    // console.log("grandchild");
    // console.log(grandchild);
    capability = grandchild.capability
    preroute = `/${e.route}/${child.route}`
    route = typeof forceRoute !== "undefined" ? forceRoute : `${grandchild.route}`
    noexact_route = typeof forceRoute !== "undefined" ? forceRoute : `${grandchild.route}/:id`
    currentE = grandchild
  } else if (child) {
    // console.log("child");
    // console.log(child);
    capability = child.capability
    preroute = `/${e.route}`
    route = typeof forceRoute !== "undefined" ? forceRoute : child.route
    noexact_route = forceRoute || `${child.route}/:id`
    currentE = child
  } else {
    // console.log("e");
    // console.log(e);

    capability = e.capability
    preroute = ""
    route = typeof forceRoute !== "undefined" ? forceRoute : e.route
    noexact_route = forceRoute || `${e.route}/:id`
    currentE = e
  }

  return {
    currentE, preroute, route, noexact_route, capability,
  }
}

export function existRouting(key: string = "") {
  const routingArray = Layouts().routing ? Layouts().routing[key] : {};
  return routingArray && routingArray.length > 0
}

export function existRoutingChilder(key:string = "") {
  const routingArray = Layouts().routing[key]
  const r = routingArray[0] ? routingArray[0] : {}
  return r.children && r.children.length > 0
}

export function getFirstRoute(key = "") {
  return Layouts().routing[key][0]
}

export function mainPage() {
  let main = Layouts().routing.extended_routes?.filter((e: any) => e.route === "")[0]
  if (!main) {
    main = Layouts().routing.menu[0]
  }
  return main
}

export function mainMenu() {
  return exec_route(Layouts().routing.main_menu)
}

export function menu_route() {
  return exec_route(Layouts().routing.menu)
}

export function footer() {
  return exec_route(Layouts().routing.footer)
}

export function profile(): IMenuItem[]{
  return exec_route(Layouts().routing.profile)
}
export function get(key = "") {
  let dd  = Layouts().routing[key];  
  Object.keys(dd).forEach(d => {
    dd[d].menu = key
    if(dd[d].children)
    {
      Object.keys( dd[d].children ).forEach(subD =>
      {
        dd[d].children[subD].menu = key
        if(dd[d].children[subD].children)
        {
          Object.keys( dd[d].children[subD].children ).forEach(subDD =>
          {
            dd[d].children[subD].children[subDD].menu = key
          })
        }
      })
    }
  })
  return dd
}
export function routing() {
  return Layouts().routing
}
export function right_routing() {
  const r: any = {}
  for (const route in Layouts().routing) {
    console.log(route)
    r[route] = exec_route(Layouts().routing[route])
  }
  return r
}

export function link() {
  return exec_route(Layouts().routing.link)
}

export function exec_route( route_array: any[] ): any[] {
  if (!route_array) return []
  const routing = concatRouting()
  // console.log( routing )
  const rArray: any[] = []
  route_array.forEach((e, i) => {
    if (typeof e.target_id !== "undefined") {
      routing.forEach((ee, ii) => {
        if (ee && ee.route === e.target_id) {
          rArray.push({ ...ee, title: e.title, icon: e.icon })
        }
      })
    } 
    else {
      rArray.push(e)
    }
    
  })
  return rArray
}

export function default_menu(): any {
  return {
		profile:
		{
			depth: 1,
			description: "User avatar's submenu",
			icon : "profile-menu-icon.svg",
      title: "Current User profile"
		},
		extended_routes: 
		{
			depth :1,
			description: "Routes outside all menu groups",
			icon : "extend-menu-icon.svg", 
      title: "Outside routes"
		},
		main_menu :
		{
			depth:5,
			description: "Main menu group in header",
			icon : "header-menu-icon.svg", 
      title: "Header Menu"
		},
		menu:
		{
			depth : 3,
			description: "Menu group of left of page Content (may be hidden)",
			icon : "left-menu-icon.svg", 
      title: "Left Menu"
		},
		footer:
		{
			depth : 1,
			description: "Footer menu group",
			icon : "footer-menu-icon.svg", 
      title: "Footer Menu"
		}
	}
}

export const defaultMenuItem = ( currentMenuGroup = 'extended_routes') : IMenuItem => {
  const id = UUID.v4()
  return {
    id,
    title: __("New item"),
    html: __("New View"),
    route: id, 
    icon: "fas fa-coffee",
    menu: currentMenuGroup
  } as IMenuItem
}
export const initRoutes = (routes: IRoutingData): IRoutingData => {
  let routings: IRoutingData = JSON.parse(JSON.stringify(routes))
  let r: IRoutingData  = {} as IRoutingData
  Object.keys(routings).forEach(key => {
    r[key] = routings[key].map( (menu: IMenuItem) => {
      return initSingleRoute(menu, null)
    })
  })
  return r
}

const initSingleRoute = (route: IMenuItem, parent: IMenuItem | null) : IMenuItem => {
  route = { 
    ...route, 
    realRoute: parent 
        ? 
        getRealRoute( parent ) + "/" +  getRoute( route ) 
        : 
        "/" +  getRoute( route )  
  } 
  if( Array.isArray(route.children) ) {
    route.children = route.children.map(r => initSingleRoute(r, route ) )
  }
  return route
}

/*
* Formating link to menu button ONLY!!!
*/
export const getRoute = (item:IMenuItem) : string => {
  if( Array.isArray(item.route_matrix) && !!item.route_matrix.length ) {
    const replacer: string  = useMainStore.getState()[ item.route_matrix[ 1 ] ]
    const url: string       = replacer 
      ? 
      item.route_matrix[0].replace("%s", replacer) 
      : 
      item.route
    return url 
  }
  return item.route 
}