import { Route, Routes } from "react-router"
import Conquest from "./Conquest"
import ConquestCont from "./ConquestCont"
import Tests from "./Tests"
import Questions from "./Questions"
import Tasks from "./Tasks"
import Reseach from "./Reseach"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import LoginRegisterForm from "src/modules/pe-basic-module/views/profile/LoginRegisterForm"

 
const ConquestPixels = ( ) : JSX.Element => {
    const user: IUser = useMainStore((state: IState) => state.user)
    if(!user.id) {
        return <div className="w-100 h-100 flex-centered">
            <LoginRegisterForm />
        </div>
    }
    return <Routes>
        <Route element={<ConquestCont />} >
            <Route index element={<Conquest />} />
            <Route path="tests/*" element={<Tests />} />
            <Route path="questions/*" element={<Questions />} />
            <Route path="media/*" element={<Tasks />} />
            <Route path="reseach/*" element={<Reseach />} />
        </Route>
    </Routes>
  }

export default ConquestPixels

