import Dice from "../views/fabula/mini-game/Dice"
import Test from "../views/fabula/mini-game/Test"

export const miniGames = () => {
    return {
        Dice: {
            component: Dice, 
        },
        Test: {
            component: Test
        }
    }
}