
export const DELETE_ATTACHMENT = ( data:any ) => {
    const site = data.landId 
        ?
        ` land_id: "${data.landId}", `
        :
        ``
    return `mutation deleteAttachment {
        deletePEFestAttachment (  ${site} id:"${ data.id }" )
    }`
}