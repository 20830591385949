// @flow
import { useMainStore } from "@/settings/zustand";
import { URL } from "@/libs/interfaces/layouts";
import React, { ReactNode } from "react"; 

export interface ITelegaProps {
    botName: string
    buttonSize?: "large" | "medium" | "small"
    cornerRadius?: string
    requestAccess?: any
    usePic?: string
    dataOnauth : (user: any) => void
    dataAuthUrl: URL
    lang?: string | "ru" | "en"
    widgetVersion?: number
    className?: string
    children: ReactNode
}

class TelegramLoginButton extends React.Component<ITelegaProps> {
  instance: any
  constructor(props: ITelegaProps | Readonly<ITelegaProps>) {
    super(props);
    this.instance = {}
  }
  componentDidMount() {
    const {
      botName,
      buttonSize="large",
      cornerRadius,
      requestAccess="write",
      usePic="true",
      dataOnauth,
      dataAuthUrl,
      lang="ru",
      widgetVersion=9,
    } = this.props;

    window.TelegramLoginWidget = {
      dataOnauth: (user: any) => { 
        dataOnauth(user)
      }, 
    };

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?" + widgetVersion;
    script.setAttribute("data-telegram-login", botName);
    script.setAttribute("data-size", buttonSize);
    if (typeof cornerRadius !== "undefined") {
      script.setAttribute("data-radius", cornerRadius);
    }
    script.setAttribute("data-request-access", requestAccess);
    script.setAttribute("data-userpic", usePic);
    script.setAttribute("data-lang", lang);
    if (dataAuthUrl !== undefined) {
      script.setAttribute("data-auth-url", dataAuthUrl);
    } else {
      script.setAttribute(
        "data-onauth",
        "TelegramLoginWidget.dataOnauth(user)"
      );
    }
    script.async = true;
    this.instance.appendChild(script);
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{
            width: 46,
            height: 46
        }}
        ref={(component) => {
          this.instance = component;
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default TelegramLoginButton;

declare global {
    interface Window { 
        TelegramLoginWidget: any; 
    }
}