import React from 'react' 
import { NumericInput } from '@blueprintjs/core'
import { __ } from "src/libs/utilities"
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const Number = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<NumberEnabled {...props} />}
        desabledForm={<NumberDesabled {...props} />}
    />
} 
export default Number 

export const NumberEnabled = ( props: IScalarForm ): JSX.Element => { 
    const onChange = (value: number) => { 
        if( props.onChange )
        {
            props.onChange( value,  props.field, "" )
        }   
    }

    return <div className=" d-flex justify-content-center my-2 form-control p-0 input dark">
        <NumericInput
            onValueChange={onChange}
            value={props.value}
            min={props.min}
            max={props.max}
            large
            inputClassName='w-100 form-control bg-transparent border box-shadow-none'
        />
    </div>
} 
 
const NumberDesabled = ( props: IScalarForm ): JSX.Element => {
    return <div className='my-3 text-center lead'>{props.value }</div>
} 
 