import React, { Component, useState } from "react" 
import { __ } from "@/libs/utilities"
import { useNavigate } from "react-router"

interface ISearchBlockProps {
	history: any
}
 

const SearchBlock = (props: ISearchBlockProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [search, setSearch] = useState<string>("")
	const navigate = useNavigate()
	const onKey = ( evt: any ) : void => {
		if (evt.key === "Enter") {
			setIsOpen(!isOpen)
			navigate( `/search/${search}` )
		}
	}
	return <div className="sb-container">
		<div className={`sb-cont ${ isOpen ? "open" : ""}`}>
		<input
			type="text"
			onChange={ (evt: any) => setSearch( evt.target.value  ) }
			onKeyPress={ onKey }
			value={search}
			placeholder={__("Введите поисковый запрос")}
		/>
		</div>
		<div className="fas fa-search pointer fa-2x opacity_5 hover m-1" onClick={() => setIsOpen(!isOpen)} />
	</div>
} 

export default SearchBlock

// class SearchBlock extends Component<ISearchBlockProps> {
// 	state = {
// 	  isOpen: false,
// 	  search: "",
// 	}
	
// 	onToggle = () : void => { 
// 		this.setState({ isOpen: !this.state.isOpen })
// 	}

// 	onSearch = ( evt: any ) : void => {
// 		this.setState({ search: evt.target.value })
// 	}

// 	onKey = ( evt: any ) : void => {
// 		if (evt.key === "Enter") {
// 			this.setState({ isOpen: false })
// 			this.props.history.push("/search", { s: this.state.search })
// 		}
// 	}
// 	render() { 
// 	  return (
// 		<div className="sb-container">
// 			<div className={`sb-cont ${this.state.isOpen ? "open" : ""}`}>
// 			<input
// 				type="text"
// 				onChange={this.onSearch}
// 				onKeyPress={this.onKey}
// 				placeholder={__("Введите поисковый запрос")}
// 			/>
// 			</div>
// 			<div className="fas fa-search pointer fa-2x opacity_5 hover m-1" onClick={this.onToggle} />
// 		</div>
// 	  )
// 	}

// }
// export default SearchBlock 
