import { __ } from "src/libs/utilities"
import { ILand } from "src/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, useFestDataStore } from "src/modules/pe-fest-module/data/store"

/* Protopia Ecosystem component */ 
const LudensAboutWidget = (props: any): JSX.Element => {
    const site: ILand = useFestDataStore((state: IFestDataStor) => state.site)
    if( !site.event_types.includes("Role play game") ) {
        return <></>
    }
    
    return <div className='pe-ludens-about-widget-container'>
        <div className=" light-colored ">
            <div className="container ">
                <div className="row justify-content-center mt-0 py-4 px-md-5 px-2">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="role_descr">
                            {__("Homo Ludens")}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-0 pb-3  px-2">
                    content
                </div>
            </div>
        </div> 
    </div>
}
export default LudensAboutWidget