import React, { Component }  from "react"
import { Button } from "@blueprintjs/core"
import gql from "graphql-tag"  
import { __ } from "../../utilities"; 
import { ID } from "../../interfaces/layouts";

class WPMediaUploader extends Component
{
    props: any 
    
    onUpload = ( ) =>
    {
        return 
        /*
        const data = `{post_title:"${this.props.name ? this.props.name: ""}", source:"${this.props.url}"}`
        //console.log(data)
        const mutation = gql`mutation changeMedia{ changeMedia(input:${data}){id,url} }`
        this.props.client.mutate({ mutation })
            .then((result) => {
                //console.log(result.data.changeMedia)	
                if(this.props.onUpload)	
                {
                    this.props.onUpload(result.data.changeMedia)
                }			
            })
        */
    }
    render()
    {
        const {url} = this.props
        //console.log( this.props )
        const enabled = typeof url == "string" && url.indexOf("data:image/") == 0 
        return <div>
            <Button onClick={this.onUpload} disabled={!enabled} className="m-2">
                {__("Upload to remote library")}
            </Button>
        </div>
    }    
}
export default WPMediaUploader

export interface IWPMediaUploaderResult {
    url: string
    id: ID
}