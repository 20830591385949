import { DIALOG_SIZE, IMenuItem } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import SingleMenuEditForm from "./SingleMenuEditForm"
import { getRouteById } from "../../data/layoutsClone/routing"
import { ClipboardCopyBtn, ClipboardPasteBtn } from "@/libs/useful"
import { changeRoute, deleteRoute } from "../../data/layoutsClone/updateRoutings"
import { Button, ButtonGroup, Collapse, Intent } from "@blueprintjs/core"
import MoveTo from "./MoveTo"
import ConfirmButton from "@/libs/useful/ConfirmButton" 

const SingleMenuItemForm = () : JSX.Element => {
    const params = useParams()
    const [item, setItem] = useState<IMenuItem>({} as IMenuItem) 
 
    useEffect(() => {
        const route = getRouteById( params.id || "" ) 
        if( route ) {
            setItem( route )
        }
        else {
            setItem({} as IMenuItem)
        }
    }, [params.id]) 
    

    const onChange = (value: any, field: string, data: any) => { 
        let _item: any = { ...item }
        _item[ field ] = value
        setItem( _item )
        // console.log(_item)
        changeRoute( _item.id, _item )
    }
    const getRoute = (realRoute: string = "") : string => {
        const routes = realRoute.split("/")
        return routes[routes.length - 1]
    }
    const getPreRoute = (realRoute: string = "") :string => {
        const routes = realRoute.split("/")
        return routes.splice(0, routes.length - 1).join( "/" ) + "/"
    }
    const paste = (data: IMenuItem) => {
        console.log( data )
    }
    const onDelete = () => {
        deleteRoute(item.id, item)
    }

    // return <div>{ JSON.stringify( item ) }</div 
        

    return <div className="short-container">
        <Collapse isOpen={ !item.id } >
            <div className="alert alert-danger p-5">
                {__("Errot route")}
            </div>
        </Collapse>
        <Collapse isOpen={ !!item.id } >
            <div className="d-flex justify-content-between">
                <div className="display-5 mr-3"> { __(item.title) } </div>
                <div className="d-flex">
                    <ClipboardCopyBtn data={item} position="top" />
                    <ClipboardPasteBtn paste={paste} position="top" />
                </div> 
            </div>
            <SingleMenuEditForm 
                onChange={onChange}
                data={ item } 
                getRoute={getRoute}
                getPreRoute={getPreRoute}
            /> 
            <div className="mt-2">
                <ButtonGroup fill>
                    <Button intent={Intent.SUCCESS} large icon="plus">
                        {__("Add children menu item")}
                    </Button>
                    <MoveTo />
                    <ConfirmButton
                        buttonLabel={__("Delete route and menu item")}
                        buttonIntent={Intent.DANGER}
                        buttonLarge 
                        buttonIcon="trash"
                        dialogClasssName={DIALOG_SIZE.MINIMAL}
                        onConfirm={ onDelete }
                    >
                        <div className="p-4">
                            {/*                             
                            <Button intent={Intent.NONE} fill large onClick={onDelete}>
                                {__("Close")}
                            </Button>
                            <Button intent={Intent.DANGER} fill large onClick={onDelete}>
                                {__("Delete route and menu item")}
                            </Button> 
                            */}
                        </div>
                    </ConfirmButton>
                    
                </ButtonGroup>
            </div>
        </Collapse>
    </div>
} 

export default SingleMenuItemForm