
import BasicState from "src/libs/basic-view"
import MediaListForm from "./media-list/MediaListForm"

export default class MediaListView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <MediaListForm {...this.props} />  
        </div>
    }
}