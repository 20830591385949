
import BasicState from "src/libs/basic-view"
import QuaestioForm from "./quaestio/QuaestioForm"

export default class QuaestioView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <QuaestioForm {...this.props} />  
        </div>
    }
}