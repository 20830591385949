
import BasicState from "src/libs/basic-view"
import LudensAdminForm from "./ludens-admin/LudensAdminForm"

export default class LudensAdminView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <LudensAdminForm {...this.props} />  
        </div>
    }
}