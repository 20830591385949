
import React from "react";
import { create } from "zustand";   

export const useClientStore: any = create ((set: any, get: any ) => ({ 
    client: { },
    setClient: ( client: any ) => set( ( ) => ({ client }) ),
}))

export const withClientStore = ( ChildComponent:any  ) => (props: object )  => {
    const storeObj:any = useClientStore(); 
    return <ChildComponent {...props} clientStore={storeObj} /> 
};