import React from "react"
import Layouts from "src/libs/layouts"

const getAllViews = (): any => {
    let views: any = {}
    Object.keys(Layouts().views).forEach((view: string) => {
        try { 
            //console.log( `../modules/${Layouts().views[ view ].module}/views/${view}` )
            views[ view ] = require( `../modules/${Layouts().views[ view ].module}/views/${view}` ).default 
        } catch( e:any ) {
            if (typeof e === "string") {
                // console.error( e.toUpperCase() ) // works, `e` narrowed to string
            } 
            else if ( e instanceof Error) {
                // console.error(e.message) // works, `e` narrowed to Error
            }
        } 
        return null
    }) 
    return views
}
export default getAllViews