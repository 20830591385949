import React from "react"
import { NavLink,   } from "react-router-dom"
import { __ } from "@/libs/utilities" 
import BasicState from "@/libs/basic-view"
import { isCapability, getInnerHeight } from "@/libs/utilities"
import  { menu } from "src/libs/layouts"
import { IMenuItem } from "@/libs/interfaces/layouts"  
import { IUser, useMainStore } from "../../../settings/zustand"
// out
class MainAdminView extends BasicState {
  props: any
  componentDidMount()
  { 
    let max: number = 0, max2: number = 0;
    setTimeout(() => {
      var cardText: HTMLElement[] = Array.from(document.getElementsByClassName("card-text")) as HTMLElement[]
      cardText.forEach( (el: HTMLElement) => {
        max = Math.max( max, getInnerHeight( el ) ) 
      })

      cardText.forEach( (el: HTMLElement) => {
        el.setAttribute("style", `height: ${max}px`)
      }) 
      
      var cardTitle: HTMLElement[] = Array.from(document.getElementsByClassName("card-title")) as HTMLElement[]
      Array.from(cardTitle).forEach( (el: HTMLElement) => {
        max2 = Math.max( max, getInnerHeight( el ) ) 
      }) 
      Array.from(cardTitle).forEach( (el: HTMLElement) => {
        el.setAttribute("style", `height: ${max2}px`)
      }) 
  }, 30)
    
  }
  addRender() { 
    //console.log(this.props);
    const isChildParams = this.props.extend_params && Array.isArray( this.props.extend_params.child_routes )
    const user: IUser = useMainStore.getState().user
    const menus = (
      isChildParams
        ?
        menu().menu.filter((menu: IMenuItem ) => {
          return this.props.extend_params.child_routes.filter( (chr: string) => chr === menu.route ).length > 0 
        })
        :
        this.props.children
          ?
          this.props.children
          :
          []
    )
      .map((e: IMenuItem, i: number) => {
        const isRole = isCapability(e.capability, user)
        if(isRole) return null
        const url = ( isChildParams 
            ?  
            `/${e.route}` 
            : 
            `/${this.props.route}/${e.route}` ).replace(/\/+/g,'/')
        return (
          <div className="col-md-3 p-1" key={i}> 
            <div 
              className="card pe-light text-center text-secondary border-0" 
            >
              <div 
                  className="card-image"
                  style={{ 
                    width: "100%", 
                    height: "100%",
                    minHeight: 200,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${ e.thumbnail })`
                  }}
              >
                <div className="card-image-icon">
                  <div className={e.icon}></div>
                </div>
              </div>
              <div className="card-body d-flex flex-column align-items-center">                
                <h4 className="card-title p-3 " dangerouslySetInnerHTML={{ __html:__(e.title) }}/>
                <div className="card-text p-3" dangerouslySetInnerHTML={{ __html: __(e.description) }}/>
                <NavLink
                  to={url}
                  className="btn btn-outline-secondary text-right mt-auto rounded-pill px-4"
                >
                  { __( "Goto" ) }
                </NavLink>
              </div> 
            </div>
          </div>
        )
      })
    return (
      <div className="container h-100 flex-centered flex-column">
        <div className="layout-state-description" dangerouslySetInnerHTML={{__html : __(this.props.description)}}/>
        <div className="row w-100 justify-content-center mb-5">
          {menus}
        </div>
      </div>
    )
  } 
}
export default MainAdminView
