import { DISLAY_PROJECT_RESULT } from "./interfaces";
export const displayProjectResult = () => ([
    {
        _id : DISLAY_PROJECT_RESULT.AVERAGE,
        title: "Average rate"
    },
    {
        _id : DISLAY_PROJECT_RESULT.SUMMAE,
        title: "Summae of average of all ctireties rates"
    },
    {
        _id : DISLAY_PROJECT_RESULT.SUMMAE_MILESTONES,
        title: "Summae of average of all ctireties rates by milestone"
    }
])