import React from 'react' 
import BasicState from "@/libs/basic-view"
import CommunicationsForm from '../../pe-topos-module/views/admin/CommunicationsForm'

class CommunicationsAdminView extends BasicState {
    props: any
    addRender = () => {  
        return <div className="container mb-5"> 
            <CommunicationsForm { ...this.props } /> 
        </div> 
    }
} 

export default CommunicationsAdminView