import React from "react" 
import { Tag, Button, ButtonGroup, Callout, Intent } from "@blueprintjs/core"; 
import { useEffect, useState } from "react";
import { __ } from "@/libs/utilities";
import { Loading } from "@/libs/useful";
import ModuleSearchSingle from "./ModuleSearchSingle";

const npmPluginUrl = ["https://registry.npmjs.com/-/v1/search?text="]
const gitlabPluginUrl = ['https://gitlab.com/api/v4/projects?search=']
const srch = "protopia-ecosystem" 

const ModuleSearch = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [npm, setNpm] = useState([]);
    const [fullPages, setFullPages] = useState(0);
    const [offset, setOffset] = useState(0);
    const [size, setSize] = useState(50);
    useEffect(() => {
        search()
    }, [props.src])
    useEffect(() => {
        setIsLoading(true)
        search()
    }, [])
    const search = () => {
        try {
            switch (props.src) {
                case "installed":
                    break;
                case "npm":
                    fetch(npmPluginUrl[0] + srch)
                        .then(response => response.json())
                        .then(
                            res => {
                                setFullPages(res.objects.length)
                                fetch(npmPluginUrl[0] + srch + `&size=${size}&offset=${offset}`)
                                    .then(response => response.json())
                                    .then(
                                        res => {
                                            setIsLoading(false)
                                            setNpm(res.objects.map((plug:any) =>
                                                {
                                                    return {...plug.package}
                                                }))
                                        },
                                        err => {
                                            console.error(err)
                                            setIsLoading(false)
                                        }
                                    )
                            },
                            err => {
                                console.error(err)
                            }
                        )
                    break;
                case "gitlab":
                default:
                    fetch(gitlabPluginUrl[0] + srch, { method: 'GET' })
                        .then(response => response.json())
                        .then(
                            res => {
                                setFullPages(res.length)
                                fetch(gitlabPluginUrl[0] + srch, { method: 'GET' })
                                    .then(response => response.json())
                                    .then(
                                        res => {
                                            setIsLoading(false)
                                            setNpm(res.map((plug: any) => {
                                                return {
                                                    ...plug,
                                                    links: {
                                                        git: plug.http_url_to_repo,
                                                        homepage: plug.readme_url,
                                                        repository: plug.readme_url,
                                                    },
                                                    keywords:[...plug.tag_list]  
                                                }
                                            }))
                                        },
                                        err => {
                                            console.error(err)
                                            setIsLoading(false)
                                        }
                                    )
                            },
                            err => {
                                console.error(err)
                            }
                        )
                    break;
            }
        }
        catch (error) {
            console.error('Ошибка:', error);
        }
    }
    if (isLoading) {
        return <div className="layout-state p-0">
            <Loading />
        </div>
    }

    const pluginList = npm.length > 0
        ?
        npm
            .filter((module: any) => module.name !== "protopia-ecosystem")
            .map((module: any, i) => {
                return <ModuleSearchSingle module={module} key={i} />
            })
        :
        <Callout>
            {__("No search plugin in this sources")}
        </Callout>


    return <>
        <div className="d-flex flex-column">
            {pluginList}
        </div>
        <div className="p-3">
            {__("Full: ") + fullPages}
        </div>
    </>
}

export default ModuleSearch