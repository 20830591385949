import { IFestStore, useFestStore } from "@/modules/pe-fest-module/data/store"
import { __ } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"
import { useState } from "react"


const SearchEngineScreen = () :JSX.Element => {
    const search: string                        = useFestStore((state: IFestStore) => state.search)
    const setSearch:(search: string) => void    = useFestStore((state: IFestStore) => state.setSearch)
    const [ curSeach, setCurSeach ]             = useState( search )
    
    const onChange = (evt: React.FormEvent<HTMLInputElement>) =>
    {
        const search: string = evt.currentTarget.value.toString() 
        setCurSeach(search)
    }
    const onSearch = () => { 
        setSearch( curSeach )
    }
    const onClear = () => { 
        setCurSeach( "" )
        setSearch( "" )
    }
    return <div className='flex-centered ml-5 flex-md-row flex-column mr-4'>
        <div className="small ">
            {__("Search Member")}
        </div>
        <div className="d-flex ">
            <input 
                type="text"
                className="w_250 input dark input dark p-1 ml-2"
                value={ curSeach }
                onChange={ onChange }
            />
            <Button icon="search" minimal onClick={onSearch} />
            {
                curSeach
                    ?
                    <Button icon="cross" minimal onClick={onClear} />
                    :
                    null
            } 
        </div>
        
    </div>
} 

export default SearchEngineScreen