import React, { useState } from 'react' 
import { useNavigate, } from 'react-router-dom'
import { Button, Collapse, Dialog, Icon, Intent } from '@blueprintjs/core'
import { __, __cases } from '@/libs/utilities'  
import { ITrack } from '../../data/interfaces'  
import { getFeedRoute, getRoute } from '@/libs/layouts' 

interface IFestTrackCardProps {
  item: ITrack
}

const FestTrackCard = (props: IFestTrackCardProps) :JSX.Element => {  
    const navigate = useNavigate()
    const[isOpen, setOpen] = useState(false)
    const[isVideoOpen, setVideoOpen] = useState(false)
    const[random,] = useState( Math.random() )
    const routeSingle = getFeedRoute("PEFestProject")  
    const _url = routeSingle 
        ? 
        `/${ getRoute(routeSingle)}?track=${ props.item.id }` 
        : 
        "/"

    const onClick = () => {
      navigate( _url )
    }
    const onOpenClick = (evt: any) => {
      evt.stopPropagation()
      evt.preventDefault()
      setOpen(true)
    }
    const onCloseClick = (evt: any) => {
      evt.stopPropagation()
      evt.preventDefault()
      setOpen(false)
    }
    const playVideo = (evt: any) => {
      evt.stopPropagation()
      evt.preventDefault()
      setVideoOpen(true)
    }
    const stopVideo = (evt: any) => {
      evt.stopPropagation()
      evt.preventDefault()
      setVideoOpen(false)
    } 
    const getAvatar = (moderator: "moderator" | "moderator2" = "moderator") => {  
      return props.item[moderator].id
        ?        
        <div className='d-flex m-3 mt-auto pb-4'>
          <div className={ ` mr-3` } style={{ width: 100, minWidth: 100 }}>
            <div 
              className='my-avatar-cont'
              style={{
                backgroundImage: `url(${ props.item[moderator]?.avatar }?${ random })`,
                backgroundColor: '#555555'
              }}
            />
          </div> 
          <div>
            <div className=' lead text-secondary'>
              { __( moderator === "moderator" ? "Track expert" : "Track moderator assistant" ) }
            </div>
            <div className='title'>
              { props.item[moderator].display_name }
            </div>
            <Collapse isOpen={ isOpen } className={`w-100`}>
              <div className='mt-1' dangerouslySetInnerHTML={{ __html: props.item[moderator]?.user_descr }} />
            </Collapse>            
          </div>
        </div> 
        :
        null
    }  
    const trimmedContent = trimComtent( props.item.content, 20 )
    return  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
      <div 
        className="card h-100 m-0 p-0 position-relative pointer hover" 
        onClick={onClick}
      >
          <div
            className="card-img overflow-hidden"
            style={{
              backgroundImage: props.item.illustration  && props.item.illustration !== "false"  
                ?
                `url(${props.item.illustration})`
                :
                  props.item.video_id 
                  ? 
                  `url(https://img.youtube.com/vi/${ props.item.video_id }/0.jpg)` 
                  : 
                  `url('')`,
              //borderColor: gcolor,
              height: 275,
              backgroundSize: "cover",
              backgroundRepeat:"no-repeat"
            }} 
          > 
          {
            props.item.video_id
              ?
              <> 
                <Button className='play-track-video-btn' minimal small onClick={playVideo}>
                  { __( "Play video" ) }
                </Button>
                <Dialog
                  isOpen={ isVideoOpen }
                  onClose={ stopVideo }
                >
                  <div className='position-relative'>
                    <iframe 
                      title={ props.item.title }
                      width="800" 
                      height="450" 
                      src={`https://www.youtube.com/embed/${props.item.video_id}?autoplay=1`} 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      allowFullScreen
                    />
                    <Button className='position-absolute top right px-3 py-2' onClick={stopVideo} intent={Intent.DANGER}>
                      <Icon size={33} color="#FFFFFF" icon="cross" />
                    </Button>
                  </div>
                  
                </Dialog>
              </>
              :
              null
          }
          {
            props.item.is_closed
              &&
              <span 
                style={{
                  position:"absolute",
                  top: 80,
                  left: 50,
                  width:"120%",
                  transform: "rotate(15deg)",
                  marginLeft: -90, 
                  height: 40, 
                  display:"flex", 
                  alignItems:"center", 
                  justifyContent:"center", 
                  padding:"2px 12px", 
                  backgroundColor:"red",
                  whiteSpace : "nowrap",
                  fontSize: 22,
                  textTransform:"uppercase",
                  color:"#FFF"
                }}
              >
                { __( "Register closed!" ) }
              </span> 
          }
            <div className='under-track-icon'>

            </div>
          </div> 
          <div
            className="card-header d-flex flex-centered flex-column"
            title={ props.item.title }
            style={{
              height: 76,
              overflow: "hidden",
              padding: "0 1.25em",
              position: "relative",
              display: "table",
              paddingTop: 14
            }}
          >
              <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                { props.item.title }  
              </h5>
              <div>
                <span className='mr-2'>{ props.item.count || 0 }</span>
                <span>{__cases(props.item.count, "projects")}</span>
              </div>
          </div>
          <div className='track-icon' style={{backgroundImage: `url(${props.item.thumbnail})`}} />
          <ul className="list-group list-group-flush flex-grow-100 ">
            <li className="list-group-item h-100 d-flex flex-column">              
              {
                trimmedContent.isTrim || props.item.moderator?.user_descr?.length > 0
                  ?
                  <>
                    <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                      <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                        <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: trimmedContent.content }} />
                        
                      </div>                                               
                    </Collapse>
                    <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                      <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                        <div 
                          className="h-100" 
                          dangerouslySetInnerHTML={{ __html: props.item.content }} 
                        />
                      </div>
                    </Collapse>
                    { getAvatar() } 
                    { getAvatar( "moderator2" ) }  
                    <Button 
                      icon={ isOpen ? "chevron-up" : "chevron-down"}
                      className="position-absolute w-100 bottom"
                      minimal 
                      onClick={ isOpen ? onCloseClick : onOpenClick } 
                    />                    
                  </>
                  : 
                  <>
                    <div className="p-4">
                      { trimmedContent.content }
                    </div>
                    <div className="p-4 mt-auto" >
                      { getAvatar() }   
                      { getAvatar( "moderator2" ) }                                             
                    </div>
                  </>
              } 
            </li>
          </ul>
      </div>
  </div> 
}

FestTrackCard.propTypes = {}

export default FestTrackCard

const trimComtent = (content: string, wordsCount: number) =>
{
  const trims = content.toString().split(" ")
  if(trims.length > wordsCount)
  { 
    return {
      content: trims.slice( 0, wordsCount ).join(" ") + "...", 
      isTrim: true, 
      trimContent: trims.slice( wordsCount, -1 ).join(" ") 
    }
  }
  else
  {
    return {content, isTrim:false}
  }
}