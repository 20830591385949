
import BasicState from "src/libs/basic-view" 
import LudensModelForm from "src/modules/pe-ludens-module/views/ludens-model/LudensModelForm"
import ErmakSpaceForm from "./ermak-space/ErmakSpaceForm"

export default class ErmakView extends BasicState {
    props: any
    addRender = () => { 
        return <div className="d-flex w-100 m-0 position-relative">
            <LudensModelForm 
                engines={[
                    {
                        id: "infrastructure",
                        title:"Инфраструктура",
                        thumbnail: "/assets/img/ermak/economy-logo-01.svg",
                        path: "infrastructure",
                        component: <ErmakSpaceForm />
                    },
                    {
                        id: "mass-production",
                        title:"Массовое производство",
                        thumbnail: "/assets/img/ermak/economy-logo-02.svg",
                        path: "mass-production",
                        component: <div className="p-md-4 display-7">Массовое производство</div>
                    },
                    {
                        id: "small-scale-production",
                        title:"Мелкосерийное производство",
                        thumbnail: "/assets/img/ermak/economy-logo-05.svg",
                        path: "small-scale-production",
                        component: <div className="p-md-4 display-7">Мелкосерийное производство</div>
                    },
                    {
                        id: "exchange",
                        title:"Биржа",
                        thumbnail: "/assets/img/ermak/economy-logo-04.svg",
                        path: "exchange",
                        component: <div className="p-md-4 display-7">Биржа</div>
                    },
                ]}
                modelTitle="Лаборатория «Инфраструктура»"
                {...this.props} 
            />  
        </div>
    }
}