import React from 'react' 
import { template } from '../layouts' 
import FooterMenu from './footer/FooterMenu'
import { Widgets } from '../utilities'

const LayoutFooter = (props: any) => {
  return template().footer
      ?
      <div className="layout-footer-container">
        <Widgets areaName="layout-before-footer" data={{ ...props }} />
        <div className="layout-footer-menu">
          <FooterMenu />
          <Widgets areaName="layout-footer-menu" data={{ ...props }} />
        </div>
        <div className="layout-footer">
          <Widgets areaName="layout-footer" data={{ ...props }} /> 
        </div>
        <div className="layout-under-footer">
          <Widgets areaName="layout-under-footer" data={{ ...props }} /> 
        </div>
      </div>
      :
      null
} 

export default LayoutFooter