import { template } from "@/libs/layouts"
import { initArea } from "@/libs/utilities"

const LayoutRight = () : JSX.Element | null => {
    return template().right
        ?
        <div className="p-0">
            { initArea( "layout-right", { }, ) }
        </div>
        : 
        null
} 

export default LayoutRight