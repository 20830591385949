import { IDictionary } from "@/libs/interfaces/layouts"
import { IAdminStore, useAdminStore } from "../../data/store"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { Fragment, useEffect, useState } from "react"
import { Button, Icon, Intent } from "@blueprintjs/core"
import { __, getAllDictionaries } from "@/libs/utilities"
import { IState, useMainStore } from "@/settings/zustand"
import { actions } from "../../data/actions"
import { UPDATE_DICTIONARY_ACTION } from "../../data/actionTypes"
import { ALL_DICTIONARIES_TAG, CORRECT_DICTIONARIES_TAG } from "../../data/interfaces"
import Filter from "./Filter" 

const EditDictionaryForm = (props:any) :JSX.Element => {
    const currentDictModule : string = useAdminStore((state: IAdminStore) => state.currentDictModule ) || ALL_DICTIONARIES_TAG
    const [ newWords, setNewWords ] = useState< IDictionary>(useMainStore( (state:IState) => state.dictionary )) 
    const [ words, setWords ] = useState< IDictionary>({}) 
    const [ wordsJson, setWordsJson ] = useState<string>(JSON.stringify( useMainStore.getState().dictionary ))
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ dictionary_version, ] = useState<number>( useMainStore.getState().dictionary_version )
    const [ filter, setFilter ] = useState<string>("")
    useEffect(() => useMainStore.subscribe(
        (state: IState) => {
            setNewWords(  state.dictionary )
            if( useMainStore.getState().dictionary_version !== dictionary_version ) {
                setWordsJson( JSON.stringify( state.dictionary ) )
            }
            setIsLoading(false)
        }
    ), [])
    useEffect(() => {
        try {
            const dict : IDictionary = currentDictModule === ALL_DICTIONARIES_TAG
                ?
                getAllDictionaries()    
                :
                require( `src/modules/${ currentDictModule }/lng/ru-RU.json` )
            setWords( dict )
        } catch(e) { }   
    }, [ currentDictModule ])
    
    const changeWord =(value: string, field: string) => {
        setNewWords({ ...newWords, [ field ]: value })
        //setWords({ ...words, [ field ]: value })
    }
    const updateDict = () => {
        setIsLoading(true) 
        actions(UPDATE_DICTIONARY_ACTION, { dictionary: newWords }) 
    }
    const wordsList = currentDictModule === CORRECT_DICTIONARIES_TAG
        ?
        newWords
        :
        words
    const onRemoveWord = (wordField: string) => {
        let _w = { ...newWords }
        delete _w[ wordField ]
        setNewWords( _w )
    }
    const onFilter = (text: string) => {
        setFilter( text.toLowerCase() )
    } 

    return <> 
            <div className="container position-relative">
                <div 
                    className={ ` position-sticky mr-4 mt-0 top ` }
                    style={{ zIndex:100 }}
                >
                    <div className="mt-0 position-absolute right d-flex">
                        <Filter onFilter={onFilter} /> 
                        <div 
                            className={` btn btn-sm btn-${wordsJson !== JSON.stringify(newWords) ? 'danger' : 'secondary opacity_5 untouchble'}`}
                            onClick={updateDict} 
                        >
                            <Icon 
                                icon={ 
                                    wordsJson === JSON.stringify(newWords) //
                                        ?  
                                        "tick" 
                                        :
                                        isLoading ? "repeat" : "blank"
                                } 
                                className={ isLoading ? "mr-2 fa fa-spin" : "mr-2" } 
                            /> 
                            { __( "Update Dictionary" ) }
                        </div>
                    </div> 
                </div>
                <div className="row mt-5">
                {
                    Object.keys(wordsList)
                    .filter( word => {
                        if(!!filter) {
                            return word?.toLowerCase().indexOf(filter) > -1 
                                || wordsList[word]?.toLowerCase().indexOf(filter) > -1
                                || words[word]?.toLowerCase().indexOf(filter) > -1
                                || newWords[word]?.toLowerCase().indexOf(filter) > -1 
                        }   
                        return true 
                    })
                    .map(word => {
                        return <div className="d-flex w-100" key={word} >
                            <div className="flex-grow-100">
                                <FieldInput
                                    type={ wordsList[word].length < 70 ? SCALAR_TYPES.STRING : SCALAR_TYPES.TEXT }
                                    value={ !!newWords[ word ] ? newWords[ word ] : wordsList[word] }
                                    field={ word }
                                    editable
                                    description={ word }
                                    title={ word + " "}
                                    onChange={(value, field) => changeWord(value, field)}
                                /> 
                            </div> 
                            <ClearButton 
                                isDict={ !!newWords[ word ] }
                                onRemoveWord={ () => onRemoveWord( word ) }
                            />
                        </div>  
                    })
                }
                </div>
            </div>
        </> 
} 

export default EditDictionaryForm


/* Protopia Ecosystem component */
export interface IClearButtonProps {
    onRemoveWord: ( ) => void
    isDict: boolean
}
const ClearButton = (props: IClearButtonProps): JSX.Element => {

    const onClick = () => {
        props.onRemoveWord()
    }
    return props.isDict
        ? 
        <div className="flex-centered" title={ __("Remove") }>
            <Button intent={Intent.DANGER} onClick={onClick} icon="trash" />  
        </div> 
        :
        <></>
} 