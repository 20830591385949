
import BasicState from "src/libs/basic-view"
import OpenTaskGeneratorForm from "./open-task-generator/OpenTaskGeneratorForm"

export default class OpenTaskGeneratorView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <OpenTaskGeneratorForm {...this.props} />  
        </div>
    }
}