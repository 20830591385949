
import { useState } from "react"
import chroma from 'chroma-js'
import { __ } from "@/libs/utilities"
import { useIsExpert } from "@/modules/pe-fest-module/hooks/project"
import { ICategory, ICritery, IProject, IProjectMilestone, IRating } from "@/modules/pe-fest-module/data/interfaces"
import Critery from "./Critery"
import { Collapse } from "@blueprintjs/core"
import getCategoryRating from "@/modules/pe-fest-module/data/getCategoryRating"

interface IProps {
    project: IProject
    milestone: IProjectMilestone | undefined
    item: ICategory
    criteries: ICritery[]
}

const Category = (props: IProps): JSX.Element => {
    const isExpert: boolean = useIsExpert(props.project, props.milestone?.milestone!)
    const [isCollapse, setIsCollapse] = useState(isExpert)
    let averageRating: number = getCategoryRating(props.item)
    const items: JSX.Element | JSX.Element[] = props.criteries
        .filter((critery: ICritery) => props.milestone?.milestone?.id === critery.milestone?.id)
        .sort((a: ICritery, b: ICritery) => {
            return (a?.order === b?.order)
                ?
                0
                :
                a?.order > b?.order ? 1 : -1
        })
        .map((critery: ICritery) => {
            return <Critery
                item={critery}
                key={critery.id}
                project={props.project}
                milestone={props.milestone!}
            />
        })
    const onCollapseToggle = () => {
        setIsCollapse(!isCollapse)
    }
    const categoryStyle: any = {}
    if (!!props.item?.color && /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(props.item?.color as string)) {
        const accentuated = chroma(props.item.color as string).get('hsl.l') < .77 ? "#FFFFFF" : "#6c757d"
        categoryStyle.backgroundColor = props.item.color
        categoryStyle.color = accentuated
    }
    return <div>
        <div className="row">
            <div className="col-md-7 col-sm-12 critery_cell first" style={categoryStyle}>
                <div className="fmRuTitle">
                    <span className="small pr-2 pl-4 opacity_75">
                        {__("Category")}
                    </span>
                    <span className="font-weight-bold">
                        {props.item.title}
                    </span>
                </div>
            </div>
            <div className="col-md-5  col-sm-12 critery_cell d-flex" style={categoryStyle}>
                <h4 className="my-auto">
                    {averageRating.toFixed(2)}
                </h4>
                <div
                    className={`fmRU_button ml-auto${isCollapse ? " open" : ""}`}
                    onClick={onCollapseToggle}
                >
                    <i className="fa fa-caret-right" style={{ color: "#f8f9fa" }} />
                </div>
            </div>
        </div>
        <Collapse className="owerflow-x-hidden row " isOpen={isCollapse}>
            {items}
        </Collapse>
    </div>
}

Category.propTypes = {}

export default Category

