import { useEffect, useRef, useState } from "react"
import { FRAME_TRANSITION_TYPE } from "../interfaces/layouts"
import "./frame-transition/style.scss"
import "src/assets/css/animate.css"

/* Protopia Ecosystem component */
interface ISize {
    width: number, 
    height: number
}
export interface IFrameTransitionProps {
    prevous: JSX.Element
    next: JSX.Element
    type: FRAME_TRANSITION_TYPE
    duration?: number //msecs
}
const FrameTransition = ({ prevous, next, type, ...props }: IFrameTransitionProps ): JSX.Element => {
    const [ time, setTime ] = useState<number>( 0 )
    const [ duration, setDuratrion ] = useState<number>( props.duration || 1800 )
    const [ isTransit, setIsTransit ] = useState<boolean>( true )
    const [ size, setSize ] = useState<ISize>({} as ISize)
    const prevRef = useRef(null)
    const nextRef = useRef(null)
    useEffect( () => { 
        const psize = prevRef.current ?  (prevRef.current! as HTMLElement).getBoundingClientRect() : {width:0, height: 0}
        const nsize = prevRef.current ?  (nextRef.current! as HTMLElement).getBoundingClientRect() : {width:0, height: 0}
        const _size: ISize = {
            width:  Math.max(psize.width,   nsize.width),
            height: Math.max(psize.height,  nsize.height ),
        }
        setSize(_size); 
        if( nextRef.current ) {  
            // (nextRef.current! as HTMLElement).style.pointerEvents = "none";
            (nextRef.current! as HTMLElement).style.animationDuration = `${duration}ms!important`;
            (nextRef.current! as HTMLElement).style.animationDelay = `${duration}ms!important`;
            (nextRef.current! as HTMLElement).classList.add( "animated" ); 
            (nextRef.current! as HTMLElement).classList.add( getDistination(type )[0]); 
        } 
        if( prevRef.current ) {
            (prevRef.current! as HTMLElement).style.pointerEvents = "none"; 
            (prevRef.current! as HTMLElement).style.animationDuration = `${duration}ms!important`;
            (prevRef.current! as HTMLElement).classList.add( "animated" ); 
            (prevRef.current! as HTMLElement).classList.add( getDistination(type )[1] );
        }
        const stop = () => {
            if( nextRef.current ) {
                (nextRef.current! as HTMLElement).classList.remove( "animated" );
                (nextRef.current! as HTMLElement).classList.remove(getDistination(type )[0]);
                (nextRef.current! as HTMLElement).style.pointerEvents = "unset";
            }
            if( prevRef.current ) {
                (prevRef.current! as HTMLElement).classList.remove( "animated" );
                (prevRef.current! as HTMLElement).classList.remove( getDistination(type )[1] );
                (prevRef.current! as HTMLElement).style.opacity = "0";
                (prevRef.current! as HTMLElement).style.pointerEvents = "unset";
            }
        }
        const timer = setTimeout(() => {
            stop()
        }, duration)
        return (() => {
            stop()
            clearTimeout( timer )
        })
    }, [ ] )
    return <div 
        className='pe-frametransition-container'
        style={{
            width: size.width,
            minHeight: size.height
        }}
    >
        <div 
            className="pe-ft-prev-container"
            ref={prevRef}
        >
            { prevous }
        </div> 
        <div 
            className="pe-ft-next-container"
            ref={nextRef}
        >
            { next } {/* */}
        </div>
        <div className="position-absolute display-6 bg-danger text-light z-index-100 d-none">
            { getDistination(type)[1] }
        </div>
    </div>
}
export default FrameTransition


export const getDistination = (d : FRAME_TRANSITION_TYPE): string[] => {
    switch(d) {
        case FRAME_TRANSITION_TYPE.MOVE_DOWN:
            return ["fadeInDown", "fadeOutDown"]
        case FRAME_TRANSITION_TYPE.MOVE_UP:
        default:
            return ["fadeInUp", "fadeOutUp"]
    }
}