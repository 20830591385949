import { useEffect, useState } from "react"
import { useDataStore } from "src/modules/pe-basic-module/data/store"
import { IPixel, PIXEL_TYPES } from "src/modules/pe-manitu-module/data/interfaces"
import { IPETest } from "src/modules/pe-tests-module/data/interfaces"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import { GET_MY_PIXELS_ACTION } from "../../../pe-manitu-module/data/actionTypes"
import { actions as manituActions } from "../../../pe-manitu-module/data/actions"
import { IAcademicDiscipline, IPEOpenProblem } from "../../data/interfaces"
import Treasure from "../Treasure"
import FilterForm from "../academyDiscipline/FilterForm"

interface IProps {
    treasures : any[],
    onFilter: (_filter: IAcademicDiscipline[]) => void,
    filter: any[]
}
const MyPixelsForm = ({onFilter, filter, ...props}:IProps) : JSX.Element | JSX.Element[] => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const user: IUser = useMainStore( (state: IState) => state.user )
    const PEOpenProblem: IPEOpenProblem[] = useDataStore((state:any) => state.PEOpenProblem )
    const PETest: IPETest[] = useDataStore((state:any) => state.PETest )
    useEffect(() => {
        manituActions(GET_MY_PIXELS_ACTION, {})
            .then(() => { 
                setIsLoading(false) 
            })
    }, [])
    const pixels: IPixel[] = [
        ...(PEOpenProblem?.filter(p => p.post_author?.id === user.id)
            .map(p => ({
                ...p,
                type : PIXEL_TYPES.OPEN_PROBLEM,
                hardness: 6,
                price: 11
            })) || []),
        ...(PETest?.filter(p => p.post_author?.id === user.id)
            .map(p => ({
                ...p,
                type : PIXEL_TYPES.TEST,
                hardness: 10,
                price: 32
            })) || [])
    ]
    return <>
        <FilterForm onFilter={onFilter} filter={filter} />
        {
            pixels.map(tr => {
                return <Treasure item={tr} key={tr.id} />
            }) 
        }
    </>
} 
export default MyPixelsForm