import { IPETestCategory } from "../interfaces";

export const testCategories = () : IPETestCategory[] => {
    return [
        {
            id:1,
            title: "Краеведение",
            post_content: "",
        },
        {
            id: 2,
            title: "Литература",
            post_content: "",
        },
        {
            id: 3,
            title: "Математика",
            post_content: "",
        },
        {
            id: 4,
            title: "География",
            post_content: "",
        },
        {
            id: 5,
            title: "Русский язык",
            post_content: "",
        },
    ]
}