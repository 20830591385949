import { events } from "../../data/mocks/events"
import EventCard from "../components/EventCard"

const ToposFooter = () : JSX.Element => {
    return <div className=" topos-footer-container w-100 flex-centered">
    <div className="row w-100 h-100">
        {
            events()
                .filter((_, i:number) => i < 3)
                .map( event => <EventCard item={event} key={event.id} height="100%" /> )
        }
    </div>
    </div>
}

ToposFooter.propTypes = {}

export default ToposFooter