import { __ } from "src/libs/utilities"
import { Button, ButtonGroup, Collapse, Icon, Intent  } from "@blueprintjs/core"
import { CommentInputForm, Likes } from "src/libs/useful"
import { IPlace } from "../../data/interfaces"
import { HTML, URL } from "@/libs/interfaces/layouts"
import { useState } from "react" 
import PlaceTypeLabel from "../labels/PlaceTypeLabel" 
import SubscribeForm from "./SubscribeForm"

interface IProps {
    item : IPlace
    onClose: () => void
    onOpenLogin: () => void
}
const PlaceContent = (props: IProps) : JSX.Element => {
    const [tab, setTab] = useState<string>("places")
    const [isCommentOpen, setIsCommentOpen] = useState<boolean>(false)

    const onContentOpen = () => setIsCommentOpen(!isCommentOpen)
    const onSendComment = (comment: HTML) => {

    }
    const onTab = (tab: string) => {
        setTab(tab)
    }
    const tabBtns: JSX.Element = <div className="mb-2">
            <ButtonGroup minimal>
                <Button onClick={() => onTab("places")} active={tab==="places"} className="text-force-contrast">{__("Places")}</Button>
                <Button onClick={() => onTab("events")} active={tab==="events"} className="text-force-contrast">{__("Events")}</Button>
            </ButtonGroup>
        </div>
    const footerBtns: JSX.Element = <>
        <Collapse isOpen={isCommentOpen} transitionDuration={1000} className="anti-double-row-left">
            <CommentInputForm 
                item={props.item} 
                className="border-top border-secondary px-4 pb-2"
                onSend={ onSendComment }
                onAfterSend={onContentOpen}
                footer={<Button minimal onClick={onContentOpen}>{__("Close")}</Button>}
            />
        </Collapse>
        <div className="flex-centered border-top border-secondary">
            <ButtonGroup className="my-2">
                <Button minimal large onClick={ props.onClose } className="hint hint--top" data-hint={__("Close")}>
                    <Icon icon="cross" size={22} />
                </Button>
                <SubscribeForm dataType="Place" item={props.item} className="" onOpenLogin={props.onOpenLogin} />             
                <Likes dataType="Place" item={props.item} className=" " />
                <Button minimal intent={Intent.NONE} large onClick={onContentOpen} className=" hint hint--top px-4" data-hint={__("Do comment")}>
                    <Icon icon="comment" />
                </Button>
            </ButtonGroup>
        </div>
    </> 
    let content: JSX.Element = <></>  
    switch(tab) {
        case "events":
            content = <Events item={props.item} header={tabBtns} footer={footerBtns}/>
            break
        case "places":
        default:
            content = <Place item={props.item}  header={tabBtns} footer={footerBtns}/>
    }
    return <div className="h-100">
         
        <div className=" h-100">
            { content}
        </div> 
    </div>
} 

export default PlaceContent

const Place = ({item, header, footer}: any) : JSX.Element => {
    // console.log(item)
    return <div className="h-100"> 
        <div className="row h-100">
            <div className="col-md-6 col-12 h-100 d-flex flex-column">
                <div className="thumbnail h-100" style={{ backgroundImage: `url(${item.thumbnail})`}} />
                <Gallery item={item}/>
            </div>
            <div className="col-md-6 col-12 h-100 d-flex flex-column">
                {header}
                <div className="tab-container overflow-y-auto  flex-grow-100">
                    <div className="display-6 pr-3">
                        {item.title}
                    </div>
                    <div className="my-2">
                        <PlaceTypeLabel item={item} />
                    </div>
                    <div className="pr-3 " dangerouslySetInnerHTML={{ __html: item.post_content }} />
                </div>
                {footer}
            </div>
        </div> 
    </div>
}
const Events = ({item, header, footer}: any) : JSX.Element => {
    return <div className="px-3 h-100">
        <div className="row h-100">
            <div className="col-md-6 col-12 thumbnail h-100" style={{ backgroundImage: `url(${item.thumbnail})`}}>

            </div>
            <div className="col-md-6 col-12 h-100 d-flex flex-column">
                {header}
                <div className="tab-container overflow-y-aut flex-grow-100o">
                    <div className="display-6">
                        {item.title}
                    </div>
                    <div className=" " dangerouslySetInnerHTML={{ __html: "item.post_content" }} />
                </div>
                {footer}
            </div>
        </div>
    </div>
}

const Gallery = (props:any): JSX.Element => {
    return !!props.item.images?.length 
    ?
    <div className="gallery">
    {
       props.item.images.map((image:URL) => {
        return <GalleryImage image={image} key={image} />
       }) 
    }                
    </div>
    :
    <></>
}

const GalleryImage = (props:any) :JSX.Element => {
    return <div className="thumbnail w-100" style={{backgroundImage: `url(${props.image})`}}></div>
}