import { IErmakItems, IErmakMapCell } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface IErmakCellItemsProps {
    cell: IErmakMapCell | undefined
}
const ErmakCellItems = (props: IErmakCellItemsProps): JSX.Element => {
    const items = props.cell?.items || [];
    const item: IErmakItems = items[0]
    return <div 
        className='pe-ermakcell-items-container'
        style={{
            backgroundImage: `url(${ item?.thumbnail })`
        }}
    >
   
    </div>
}
export default ErmakCellItems