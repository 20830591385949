import React, { useEffect } from 'react' 

function useClickOutside (myRef: React.MutableRefObject<null>, onOpen: (bool:boolean) => void, timeOut: number) {
    const closeLoginOpen = (e: Event) =>
    {
        const domNode: any = myRef.current;
        if (!domNode || !domNode.contains(e.target)) { 
            clearTimeout( timeOut )
            onOpen(false)
        }
    }
    useEffect(() =>
    {
        window.document.body.addEventListener("click", closeLoginOpen)
        return () => {
            window.document.body.addEventListener("click", closeLoginOpen)
            clearTimeout( timeOut )
        }
    }, [])
} 

export default useClickOutside