
/* Protopia Ecosystem component */
export interface IOdnoklassnikiLoginProps {
    callback: (response: any) => void
}
const OdnoklassnikiLogin = (props: IOdnoklassnikiLoginProps): JSX.Element => {
    /*
    TODO: add API code
    */
    const onClick = () => {
        props.callback({})
    }
    return <div className="soc odnoklassniki" onClick={ onClick }>
        <i className="fab fa-odnoklassniki" />
    </div>
}
export default OdnoklassnikiLogin