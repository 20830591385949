import { useParams } from "react-router"
import { IPETest, PETestQuestion } from "../../data/interfaces"
import { ITestsStore, useTestsStore } from "../../data/store"
import { useEffect, useState } from "react"
import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { __ } from "src/libs/utilities"
import { VerticalType } from "src/libs/scalars/interface/IScalarForm"

const Question = () : JSX.Element => {
    const genTest: IPETest = useTestsStore((state:ITestsStore) => state.genTest)
    const params = useParams()
    const [question, setQuestion] = useState<PETestQuestion>({} as PETestQuestion)
    
    useEffect(() => {
        setQuestion(genTest.questions.filter(q => q.id === params.question_id)[0] || {})
    }, [params.question_id])

    const onField = (value: any, field: keyof PETestQuestion, data: any) => {
        let q: PETestQuestion = {...question, [field]: value} as PETestQuestion
        setQuestion(q)
        useTestsStore.setState({
            genTest : {
                ...genTest,
                questions: genTest.questions.map(qu => {
                    if(qu.id === q.id)
                    {
                        return q
                    }
                    return qu
                }) 
            }
        })
    }
    return (
        <div className="px-3 py-2 w-100">
            {/* <div className="lead">
                {question.title}
            </div> */}
            <div className=" py-2 w-100">
                <FieldInput
                    editable
                    field="title"
                    title={__("Title")}
                    value={question.title}
                    onChange={onField}
                /> 
                <FieldInput
                    editable
                    type={SCALAR_TYPES.HTML}
                    field="post_content"
                    title={__("Description")}
                    value={question.post_content}
                    onChange={onField}
                /> 
                <FieldInput
                    editable
                    type={SCALAR_TYPES.MEDIA}
                    field="thumbnail"
                    title={__("Thumbnail")}
                    value={question.thumbnail}
                    onChange={onField}
                />
                <FieldInput
                    editable
                    vertical={VerticalType.VERTICAL}
                    visualization="test-question-answer" 
                    className={"w-100 m-0 px-0"}
                    field="answers"
                    title={__("Answers")}
                    value={question.answers}
                    onChange={onField}
                />
            </div>
        </div>
    )
} 

export default Question