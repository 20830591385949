import { PE_TEST_QUESTION_TYPE } from "../interfaces"
import { testCategories } from "./testCategories"

export const test4 = () => {
    return {
        id: 4,
        title: "Андерсен Х. К. «Снежная королева» Часть 2",
        post_content: "Тест по литературе для 5 класса",
        testCategory: testCategories().filter(t => t.id === 2)[0],
        pe_academic_discipline: [{
            color: '#214700',
            id: '25',
            order: 6,
            post_content: '',
            thumbnail: 'false',
            title: 'Литература',
        }],
        thumbnail: "https://i.pinimg.com/564x/be/d1/1e/bed11e25a2a6f719706be8d2e0d44ecb.jpg",
        questions: [
            {
                id:1,
                title: "В какое время года Герда сбежала из домика старушки?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "зимой",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "весной",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "осенью",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "летом",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 2,
                title: "Кто рассказал Герде историю о том, как принцесса выбирала себе жениха?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "воробей",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "олень",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "ворон",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "орёл",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 3,
                title: "Кто помог Герде попасть во дворец к принцу и принцессе?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "добрый охранник",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "ворон и ворона",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "олень",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "камердинер",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 4,
                title: "Кому принадлежат слова: «Ишь какая славненькая, жирненькая! Орешками откормлена!»?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "старухе разбойнице",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "вороне",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "принцессе",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "Каю",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 5,
                title: "Как маленькая разбойница назвала северного оленя?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "кешкой",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "плутишкой",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "пятнышком",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "бяшкой",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 6,
                title: "Кто подсказал Герде, что видел Кая в санях Снежной королевы, когда маленькая разбойница спала?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "северный олень",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "лесные голуби",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "ворон и ворона",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "белая курица",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id:7,
                title: "Где располагаются постоянные чертоги Снежной королевы?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "в Великом Устюге",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "на острове Шпицберген в Лапландии",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "неизвестно",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "в тридевятом царстве",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 8,
                title: "На чем лапландка написала послание финке?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "на пергаменте",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "на сушёной рыбе",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "на листке бумаги",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "на бересте",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 9,
                title: "Какое слово не мог сложить Кай из льдин?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "«королева»",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "«мгновение»",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "«красота»",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "«вечность»",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id:10,
                title: "Что оживило Кая?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "улыбка Герды",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "жестокость Снежной королевы",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "слёзы Герды",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "поцелуй Снежной королевы",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
             
        ],
        is_show_answer:false, 
        is_show_results:true, 
        is_shuffle:true, 
        is_timed:false, 
        duration: 20,
        try_count: 10
    }
}