 
import asyncControllers from './controllers' 


export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    switch (action) {
         
        default:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
    }

} 

export const syncAction = (action: string, data: any): any => {
    switch(action) { 
        default:
            return action
    }
    
}