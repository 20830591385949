import { Popover } from "@blueprintjs/core"
import { ID } from "src/libs/interfaces/layouts"
import { IPEResourceType, IUserResource } from "../../data/interfaces"
import { ILudensStore, useLudensStore } from "../../data/store"
import ResourceMonitorSingle from "./ResourceMonitorSingle"
import { useDataStore } from "src/modules/pe-basic-module/data/store"


const ManaMonitorTablet = () : JSX.Element => {
    const userResource: { [key: ID]: IUserResource } = useLudensStore((state:ILudensStore) => state.userResource)
    const resourceTypes: IPEResourceType[] = useDataStore((state: any) => state.PEResourceType) || []
   
    return <Popover
        interactionKind="click"
        className="position-absolute bottom right"
        modifiers={{
            arrow: { enabled: false },
            flip: { enabled: true },
            preventOverflow: { enabled: false },
        }}
        position="top-left"
        content={<div className="p-3">
            <div className="monitor datas widget p-0">
            {
                resourceTypes.map(resource => {
                    return <ResourceMonitorSingle 
                        resource={ resource } 
                        count={ userResource[ resource.id ].count }
                        clickable 
                        key={ resource.id } 
                    /> 
                })
            }
            </div> 
        </div>}
    >
        <div className="monitor datas widget "> 
            <div className="pe-manitu-resource-single-cont mobile">
                <div 
                    className="resource" 
                    style={{
                        backgroundImage: `url(${ resourceTypes[0]?.icon })`,
                        backgroundColor: resourceTypes[0]?.color
                    }}  
                >
                    <div className="resource-count">{ userResource[ resourceTypes[0]?.id ]?.count }</div>
                </div>
            </div> 
        </div> 
    </Popover> 
}

export default ManaMonitorTablet