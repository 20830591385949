import React from 'react' 
import { Intent, Tag } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'
import { IProject } from '../../data/interfaces'

interface IProps {
    className?: string
    member: IProject
}
const MemberLabel = ( props :IProps) :JSX.Element => {
  return <div className={`d-flex ${props.className}`}>
    <div className='title'>{props.member.title}</div>
    {
        props.member.track?.ID
            ?
            <div className='d-flex align-items-center mx-2'>               
                <Tag minimal round className='px-2 ' intent={Intent.PRIMARY}>
                    <div className='d-flex'>
                        <div className='descr mr-1'>{__("Track")}: </div>
                        <div className='title'>{ props.member.track.title }</div>
                    </div>
                </Tag>
            </div>
            :
            null
    }
    {
        props.member.honeycombs?.ID
            ?
            <div className='d-flex align-items-center mx-2'>
                <Tag minimal round className='px-2 ' intent={Intent.WARNING}>
                    <div className='d-flex'>
                        <div className='descr mr-1'>{__("Honeycombs")}: </div>
                        <div className='title'>{ props.member.honeycombs.title }</div>
                    </div>                    
                </Tag>
            </div>
            :
            null
    }
    
  </div>
}

MemberLabel.propTypes = {}

export default MemberLabel