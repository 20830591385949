import React, { useState } from 'react' 

const LayoutMenuMainMobyle = ( ) => {
    const [isOpen, setOpen] = useState(false)
    let openBtn: React.ReactElement = isOpen
        ?
        <div className="mobile-bar" onClick={ () => setOpen( !isOpen ) }>
            <i className="fas fa-times" />
        </div>
        :
        <></> 
    return <div>
        { openBtn }
    </div> 
} 

export default LayoutMenuMainMobyle
 