import { IFabulaMedia } from "@/modules/pe-fabula-module/data/interfaces"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface IHTMLProps {
    item: IFabulaMedia
}
const HTML = (props: IHTMLProps): JSX.Element => {
    const [html, setHtml] =useState<string | TrustedHTML>("")
    useEffect(() => {
        if(props.item.src) {
            fetch( props.item.src?.toString() )
                .then((response: any) => { 
                    return response.text()
                })
                .then((html: any) => { 
                    setHtml(html)                  
                })
                .catch(error => {
                    console.log(error)
                });
        } 
    }, [])
    return <div 
        className='pe-html-container overflow-y-auto'
        dangerouslySetInnerHTML={{__html: html}}
    />
}
export default HTML