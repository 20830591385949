import React from 'react' 
import SettingsForm from './settings/SettingsForm'
import BasicExtendView from '@/libs/basic-view/BasicExtendView' 

export class AdminSettingsView extends BasicExtendView {  
    props: any 
    center = ():JSX.Element => {  
       return <div className="w-100 mb-5">
           <SettingsForm {...this.props} />
       </div>
    }
}

export default AdminSettingsView 