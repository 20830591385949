import { Route, Routes } from "react-router"
import { IToposStore, useToposStore } from "../../data/store"
import useEvent from "../../hooks/useEvent"
import ToposLayoutContainer from "../components/ToposLayoutContainer"
import Day from "./Day"
import Month from "./Month"
import Today from "./Today"
import ToposContainer from "./ToposContainer"
import Week from "./Week"
import Year from "./Year"

const ToposForm = (props:any) : JSX.Element => { 
    useEvent("-1")
    return <div className="w-100 h-100">
        <Routes>
            <Route path="/*" element={<ToposLayoutContainer />} >
                <Route path="/*" element={ <ToposContainer /> }> 
                    {/* <Route index element={ <Navigate replace to={ labels[ range ] } /> } />  */}
                    <Route path="year" element={ <Year /> } />
                    <Route path="month" element={ <Month /> } />
                    <Route path="week" element={ <Week /> } />
                    <Route path="day" element={ <Day /> } />
                    <Route path="today" element={ <Today /> } />
                </Route>
            </Route>
        </Routes> 
    </div>
} 

export default ToposForm