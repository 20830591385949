import getRandomImg from "src/assets/data/getRandomImg" 
import { INews } from "../interfaces"

export const news = () : INews[] => {
    return [
        {
            id: 1,
            title: "«Доброшрифт» запускает арт-проект в парках Москвы",
            post_content: `4 октября, в Международный день поддержки людей с церебральным параличом, социальная кампания «Доброшрифт» запускает арт-проект в парках Москвы. Инсталляции «Добро» установлены в музее-заповеднике «Царицыно», в парке «Сокольники» и в Московском зоопарке.
            <p>
            В этом году «Доброшрифт» празднует пятилетие социальной кампании. К юбилейной дате будет запущен новый вектор проекта: «Доброшрифт» теперь можно использовать на 25 официальных языках субъектов Российской Федерации, например на татарском, бурятском, удмуртском, марийском, чувашском, башкирском, мокшанском и других. Концепция самого проекта основана на уникальном шрифте, собранном из прописей 33 детей с ДЦП.
            <p>
            За пять лет проект «Доброшрифт» и фонд «Подарок Ангелу» помогли более восьми с половиной тысячам детей получить помощь по направлениям комплексной реабилитации, а сам «Доброшрифт» стал символом солидарности и поддержки людей с неизлечимым диагнозом — «церебральный паралич». Более 30 миллионов человек каждый год узнают о сложнейшей проблеме интеграции людей с речевыми и двигательными нарушениями в обычную жизнь.`,
            thumbnail: "https://cdn.culture.ru/images/d0d7d8a5-4a12-517f-89d6-126ebcda3363/c_fill,g_center/001-min-png",
            date: '2024-12-31T13:51:50.417Z',
            images: [
                "https://cdn.culture.ru/images/4a42e5b9-5acb-55c3-af7b-ecdf268cb08c/_/3-jpg.webp",
                "https://cdn.culture.ru/images/d788fa76-815c-51a6-9324-f59c6dceb54e/_/2-jpeg.webp",
                "https://cdn.culture.ru/images/f3ea7f04-9a83-525b-b7f1-d92a7160bc29/_/11-jpg.webp"
            ],
            tags: [
                {
                    id:1,
                    title: "События",
                    post_content: ""
                },
                {
                    id: 2,
                    title: "Широкая масленица",
                    post_content: ""
                }
            ],
            address: "Владимирская оюласть",
            url:"https://www.culture.ru/news/257859/dobroshrift-zapuskaet-art-proekt-v-parkakh-moskvy", 
        },
        {
            id: 2,
            title: "В Санкт-Петербурге состоится кинофестиваль «Мир знаний»",
            post_content: `Фильмом открытия, который зрители увидят 6 октября в Доме кино, станет «Музыка звезд» режиссера Анастасии Зверьковой. Картина рассказывает о научных сотрудниках специальной астрофизической обсерватории РАН, крупнейшего в России объекта для наблюдения за Вселенной, расположенного в населенном пункте Нижний Архыз (Букино).
            <p>
            Темой «Мира знаний» в этом году станет «Невидимая работа музеев». Фильмы о научной и исследовательской деятельности музеев войдут в отдельную программу.
            <p>    
            Международная конкурсная программа фестиваля представит семь документальных картин из России, США, Великобритании, Бельгии и Непала. Эти фильмы отличает нестандартный взгляд на вопросы современной прикладной науки, а также на восприятие человечеством этих вопросов.
            <p>
            В национальный конкурс вошло 17 фильмов. В этом году в фокусе режиссеров — атомная энергетика, метеоритика, экология, этнографические исследования, орнитология, океанология, достижения в области медицины, современные изобретатели и ученые прошлого. Среди участников — как давно зарекомендовавшие себя в сфере научного кино режиссеры (Юлия Киселева, Владимир Марин), так и молодые дебютанты, находящиеся в поиске новых визуальных кодов и языков.
            <p>
            Также в рамках фестиваля состоится лаборатория научного кино Science Film Lab. В этом году программа посвящена научной деятельности музеев и проводится для студентов творческих вузов и работников музеев из Санкт-Петербурга.
            <p>
            Зрители увидят два фильма, авторы которых участвовали в Science Film Lab в 2021 году. В международный конкурс вошла работа Дмитрия Лукьянова «Тонкие поля». Он рассказывает об умирающем НИИ сельского хозяйства под Суздалем, где местные колхозники вступают в конфронтацию со столичными арендаторами плодородных земель. В национальном конкурсе можно будет увидеть фильм «Пиблокто» режиссеров Анастасии Шубиной и Тимофея Глинина. Это картина о жителях Чукотки, быт которых строится вокруг охоты на моржей и китов и защиты деревень от приходящих из тундры медведей.
            <p>
            Еще один образовательный проект фестиваля будет представлен в рамках специального показа фильмов акселератора научного и индустриального кино Сибири. Это совместный проект новосибирского кинофестиваля «Кремний» и Киностудии им. М. Горького. В рамках акселератора летом этого года творческие команды из Новосибирской области совместно с кураторами создали 12 короткометражных фильмов, лучшие из которых и будут представлены на фестивале.
            <p>
            Основной площадкой фестиваля станет Дом кино, где пройдет большинство кинопоказов. Также специальные события пройдут в Планетарии №1, книжном магазине «Порядок слов» на Фонтанке и в пространстве «Лекторий’порт». Вход на все показы фестиваля свободный по регистрации на официальном сайте.`,
            thumbnail: "https://cdn.culture.ru/images/bb4e01c9-2d6a-54ea-9733-eb22270c31f1/_/2-jpeg.webp", 
            date: '2024-03-07T13:51:50.417Z',
            images: [],
            tags: [
                {
                    id:1,
                    title: "События",
                    post_content: ""
                }
            ],
            address: "Ульяновск",
            url:"https://www.culture.ru/news/257862/v-sankt-peterburge-sostoitsya-kinofestival-mir-znanii", 
        },
        {
            id: 3,
            title: "Санкт-Петербург: «Школа Филонова»",
            post_content: `Русский музей представляет выставку «Школа Филонова». Она посвящена творчеству участников объединения «Мастера аналитического искусства». Организация существовала с 1925 по 1941 год, в нее входили ученики и последователи Павла Филонова. В экспозиции представлены работы Юрия Хржановского, Софьи Закликовской, Павла Кондратьева, Татьяны Глебовой, Алевтины Мордвиновой, Эдуарда Тэнисмана и других художников. Гости увидят картины, декорации к спектаклям «Ревизор» 1927 года, панно, иллюстрации к карело-финскому эпосу «Калевала». Государственный музей истории Санкт-Петербурга предоставил эскизы Татьяны Глебовой для оформления оперы Рихарда Вагнера «Нюрнбергские мейстерзингеры». Всего же на выставке — более 100 предметов из собрания Русского музея и частных коллекций.
            <p>
            Подробнее о выставке «Школа Филонова».`,
            thumbnail: "https://cdn.culture.ru/images/82625347-3e61-5225-af4d-2c59716cbf1c/c_fill,g_center/008-min-png",
            date: '2024-12-31T13:51:50.417Z',
            images: [],
            tags: [
                {
                    id:1,
                    title: "События",
                    post_content: ""
                }
            ],
            address: "Зарайск",
            url:"https://www.culture.ru/news/257854/samye-interesnye-vystavki-oktyabrya", 
        },
        {
            id: 4,
            title: "1 октября в кинотеатре «Октябрь» пройдет премьера сериала «Не дождетесь»",
            post_content: `В рамках показа в кинотеатре «Октябрь» состоится паблик-ток о связи поколений со звездами сериала и представителями профильных НКО. Зрители смогут задать вопрос любимым артистам. 1 октября сериал «Не дождетесь» станет доступен для просмотра в онлайн-кинотеатре PREMIER.
            <p>
            Также создатели сериала запустили одноименный сайт — недождетесь.рф. На нем публикуются не только эксклюзивные дополнительные материалы проекта, но и статьи о том, как помогать пожилым людям.
            <p>
            В эпизодах сериала задействованы подопечные фонда «Артист», который уже 15 лет поддерживает пожилых людей, посвятивших свою жизнь работе в сфере культуры. О том, кому и как помогает фонд, в интервью для портала «Культура.РФ» рассказала Ольга Каталина, Директор Благотворительного фонда поддержки деятелей искусства «Артист»:
            <p>
            В основном фонд оказывает материальную помощь в сложной жизненной ситуации и медицинскую помощь: лекарства, обследования, лечение и реабилитация, патронаж и долговременный уход, сопровождение врача-гериатра на дому. Помимо финансовой помощи, у нас накоплен большой опыт взаимодействия с медицинскими учреждениями со специализированными программами помощи возрастным людям. Среди программ Фонда есть еще очень важная для нас социальная программа «Признание в любви», в рамках которой мы организуем мероприятия и встречи с нашими ветеранами, поздравляем их с праздниками, реализуем различные проекты при поддержке наших друзей и волонтеров, не допуская того, чтобы любимые артисты проводили свою старость в забвении. За 15 лет программы фонда прошли путь от одноразовой помощи в экстренной ситуации к системной поддержке наших ветеранов. Теперь фонд ориентирован на качество и продление жизни, на регулярную поддержку тех, кто в ней нуждается.
            Ольга Каталина
            <p>
            Фонд поддерживает деятелей культуры не только в Москве. По словам Ольги Каталиной, «сначала у фонда появилось пятьдесят подопечных, потом сто, затем триста. К нам перешла картотека Дома актера от Эскиной, которая вскоре скончалась. Сейчас в базе фонда — ветераны театра и кино и люди других творческих профессий из 80 городов России, их больше 2500 человек».
            <p>
            «Мы отличаемся от других благотворительных фондов тем, что не собираем деньги на помощь конкретному человеку и не описываем его личную историю, — добавила Ольга Каталина. — Наши подопечные болезненно относятся к публичной демонстрации нужды, ведь многие из них были и остаются известными людьми. Из-за этого нашему фонду сложно собирать пожертвования на то, что называется адресной помощью. Поэтому фонд в основном поддерживает государство, корпоративные партнеры и меценаты, люди, близкие к культуре, и поклонники творчества. Они понимают: только вместе можно помочь людям, которые создали историю российской культуры».`,
            thumbnail: "https://cdn.culture.ru/images/b72b2e75-7078-566a-9ef4-f5f981181338/_/2-png.webp",
            date: '2024-06-11T13:51:50.417Z',
            images: [],
            tags: [
                {
                    id:1,
                    title: "События",
                    post_content: ""
                }
            ],
            address: "Олбденбург",
            url:"https://www.culture.ru/news/257853/1-oktyabrya-v-kinoteatre-oktyabr-proidet-premera-seriala-ne-dozhdetes", 
        },
        {
            id: 5,
            title: "В Москве проходит VI Большой детский фестиваль",
            post_content: `Фестиваль открылся 17 сентября на сцене Московского губернского театра: Тюменский большой драматический театр и режиссер Анна Фекета представили мюзикл «Вечера на хуторе близ Диканьки». Музыку к спектаклю написал композитор, лауреат всероссийских музыкальных и театральных конкурсов Евгений Карамзин. Другие события фестиваля пройдут в Московском губернском театре, Театре им. С. Образцова, Театре им. Моссовета, РАМТе и на других площадках.
            <p>
            В конкурсную программу Большого детского фестиваля войдут 15 номинаций по направлениям «Театр», «Кино», «Анимация», «Книга», а также специальные номинации «Лучший инклюзивный спектакль», «Театр — школе» и «Мой любимый край». Победителей определит детско-юношеское жюри — зрители от 8 до 16 лет. В его состав вошли 27 человек: юные актеры, музыканты, спортсмены, художники, победители всероссийских школьных олимпиад.
            <p>
            В рамках фестиваля будут реализованы специальные проекты. Так, с 30 октября по 5 ноября пройдут «БДФ Мастерские» для учащихся детских театральных студий из разных регионов России. Участники программы из Казани, Набережных Челнов, Энгельса и городов Подмосковья будут посещать лекции, участвовать в мастер-классах, смотреть и обсуждать фестивальные спектакли, а в финале покажут собственные творческие проекты на Малой сцене Московского губернского театра. Также состоится «БДФ Питчинг» — конкурс творческих идей, адресованных детскому театру. Молодые режиссеры, драматурги и продюсеры смогут презентовать свои идеи директорам и художественным руководителям театров со всей России и получить финансовую поддержку проектов.
            <p>
            «Уже в шестой раз стартует наш Большой детский фестиваль — масштабный праздник для детей и их родителей, где собрано лучшее, что делают взрослые для юной аудитории. Я рад, что интерес к фестивалю год от года растет — и зрителей, и профессионального сообщества. В этом году мы получили более 600 заявок на участие в конкурсе, в программу фестиваля отобраны 79 творческих проектов. Впереди два насыщенных фестивальных месяца, когда можно будет увидеть в Москве показы лучших детских спектаклей из 21 региона России, кино- и анимационные фильмы, узнать о новинках детской литературы и встретиться с их авторами», — отметил художественный руководитель фестиваля Сергей Безруков.
            <p>
            Подробнее о Большом детском фестивале читайте на официальном сайте.`,
            thumbnail: "https://cdn.culture.ru/images/92445d4b-d736-51a2-bf7c-6216f6848794/_/02-png.webp",
            date: '2023-12-22T13:51:50.417Z',
            images: [],
            tags: [
                {
                    id:1,
                    title: "События",
                    post_content: ""
                },
                {
                    id: 2,
                    title: "Широкая масленица",
                    post_content: ""
                }
            ],
            address: "Екатеринбург",
            url:"https://www.culture.ru/news/257841/v-moskve-prokhodit-vi-bolshoi-detskii-festival", 
        },
        {
            id: 6,
            title: "В Москве проходит фестиваль «СтудияФест»",
            post_content: `Открытие фестиваля состоялось 18 сентября в Рахманиновском зале Московской консерватории. В программу вечера вошли ранние опыты начала ХХ века: «Завод» Александра Мосолова, «Рельсы» Владимира Дешевова — и сочинения современников: «Форсаж» Владимира Тарнопольского, «Посвящение Онеггеру» Юрия Каспарова и «Parovoz Structures» Алексея Сюмака. Во втором отделении прозвучал триптих «Professor Bad Trip» — сочинение Фаусто Ромителли, в котором утонченная спектральная техника сочетается с энергетикой техно и искаженными звучаниями психоделического рока.
            <p>
            Центральным событием фестиваля станет концерт, который состоится 9 октября в Большом зале. «Студия новой музыки» представит балеты, ознаменовавшие новую эру в искусстве ХХ века: «Парад» Эрика Сати, «Треуголка» Мануэля де Фальи и «Голубой экспресс» Дариюса Мийо. Спектакли создавались для дягилевских Русских сезонов и на премьерных показах были представлены со сценографией Пабло Пикассо. В программе концерта балетная музыка будет звучать на фоне видеопроекций с реконструкциями первых постановок.
            <p>
            Третий концерт фестиваля, который пройдет 13 октября в Рахманиновском зале, приурочен к презентации хрестоматий по расширенным инструментальным техникам. Авторы пособий Анастасия Табанкова и Ольга Галочкина расскажут о новых возможностях гобоя и виолончели и представят программу, в которой ярко раскрывается необычное звучание классических инструментов.
            <p>
            19 октября концерт «Студии новой музыки» пройдет в коллаборации с Московской филармонией в рамках абонемента «Другое пространство. Continuo». В программу войдут российская премьера «Laborintus II» Лучано Берио для инструментального ансамбля, хора, женских голосов и фонограммы; Концерт для двух фортепиано с оркестром «Nuun» Беата Фуррера; мировая премьера пьесы Владимира Горлинского «За светом. Путешествие по ансамблю».
            <p>
            Заключительный концерт фестиваля состоится 30 октября в Доме культуры «ГЭС-2». Гости вечера услышат сочинения Фараджа Караева, Виктора Екимовского, Владимира Тарнопольского, Александра Раскатова.
            <p>
            Также в рамках фестиваля состоится проект «Студия | Science». В конференц-зале Московской консерватории пройдут три секции научных чтений — научно-теоретическая «30 лет новой музыки», научно-практическая «Новые инструментальные практики» и молодежная «Это будет завтра».
            <p>
            Параллельно с основными событиями фестиваля в Московской консерватории состоятся две выставки. Основная — «Студия | Объекты» — проходит до 1 ноября в арт-пространстве «Артемьев». Мультимедийно-интерактивная экспозиция с необычными реквизитами из арсенала «Студии новой музыки» дает посетителям возможность самостоятельно исследовать акустические свойства разных инструментов. В начале октября откроется вторая выставка в фойе Большого зала, где будут представлены редкие материалы из архива ансамбля.`,
            thumbnail: "https://cdn.culture.ru/images/da019cdf-9941-58e0-8038-2bdd8c9210e6/c_fill,g_center/bez-imeni-1-png",
            date: '2024-02-21T13:51:50.417Z',
            images: [],
            tags: [],
            address: "село Сважень, Чехия",
            url:"https://www.culture.ru/news/257846/v-moskve-prokhodit-festival-studiyafest", 
        }, 
    ]
}