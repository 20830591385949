import { __ } from "@/libs/utilities"
import IScalarForm from "../interface/IScalarForm"
import Scalar from "./Scalar"
import {ITimes} from "@/libs/utilities/sprintf"
import { NumberEnabled } from "./Number"
import { SliderEnabled } from "./Slider"

const TimeDuration = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<TimeDurationEnabled {...props} />}
        desabledForm={<TimeDurationDesabled {...props} />}
    /> 
}

export default TimeDuration

const TimeDurationDesabled = ( props: IScalarForm ): JSX.Element => {
    const value = {...props.value} as ITimes || {}
    return <div className="d-flex">
        <div className="m-1">{value?.hrs}:</div>
        <div className="m-1">{value?.mins}:</div>
        <div className="m-1">{value?.secs}</div>
    </div>
}
const TimeDurationEnabled = ( props: IScalarForm ): JSX.Element => {
    const onChange = (value: number, field: string, data: any="") => {
        let _value = {...props.value} || {}
        _value[field] = value
        if(props.onChange)
            props.onChange( _value, props.field, "" )
    }
    return <div className="w-100">
        <div className="row align-items-center">
            <div className="col-3 layout-label">{__("Days")}</div>
            <div className="col-9 w-100">
                <SliderEnabled
                    value={props.value?.days || 0}
                    editable
                    min={0}
                    max={100}
                    stepSize={1}
                    labelStepSize={10}
                    field="days"
                    onChange={ onChange }
                />
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-3 layout-label">{__("Hours")}</div>
            <div className="col-9 w-100">
                <SliderEnabled
                    value={props.value?.hrs || 0}
                    editable
                    min={0}
                    max={24}
                    stepSize={1}
                    labelStepSize={3}
                    field="hrs"
                    onChange={ onChange }
                />
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-3 layout-label">{__("Minutes")}</div>
            <div className="col-9 w-100">
                <SliderEnabled
                    max={60}
                    stepSize={1}
                    labelStepSize={10}
                    value={props.value?.mins || 0}
                    editable
                    field="mins"
                    onChange={ onChange }
                />
            </div>
        </div>
        <div className="row align-items-center">
            <div className="col-3 layout-label">{__("Seconds")}</div>
            <div className="col-9 w-100">
                <SliderEnabled
                    max={60}
                    stepSize={1}
                    labelStepSize={10}
                    value={props.value?.secs || 0}
                    editable
                    field="secs"
                    onChange={ onChange }
                />
            </div>
        </div>
    </div>
}