import { __ } from "@/libs/utilities"
import { Button, ButtonGroup, Dialog, Icon, Intent } from "@blueprintjs/core"
import { useState } from "react"
import { IAdminStore, IBulk, useAdminStore } from "../../data/store"
import { getVisibleValue } from "@/libs/layouts"
import { TableCheckBox } from "./DataTypeRow" 
import { DELETE_BULK_ACTION, GET_FEED_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import { IState, useMainStore } from "@/settings/zustand"
import { useParams } from "react-router"

 
const BulkDeleter = (props : any) : JSX.Element => {
    const {landId} = useParams()
    const [isLoading, setIsLoading]             = useState(false)
    const [isOpen, setOpen]                     = useState(false)
    const bulks: IBulk[]                        = useAdminStore((state:IAdminStore) => state.bulks)
    const items: any[]                          = useAdminStore((state:IAdminStore) => state.items)
    const offset: number                        = useAdminStore((state:IAdminStore) => state.offset) 
    const paging: string                        = useAdminStore((state:IAdminStore) => state.paging) 
    const pageCount: number                     = useMainStore( ( state: IState ) => state.pageCount )

    const setBulks: ((bulks: IBulk[]) => void)  = useAdminStore((state:IAdminStore) => state.setBulks) 
    const setItems: ((items: any[]) => void)    = useAdminStore( (state:IAdminStore) => state.setItems )

    const visibleValue:string = getVisibleValue(props.data_type)
 
    const onChange = (checked: boolean, _bulk: IBulk) => {
        setBulks(
            bulks.map(bulk => {
                if(bulk.id === _bulk.id) {
                    bulk.checked = checked
                }
                return bulk
            })
        )
    }
    const onFinallyDelete = () => {
        setOpen(false)
        setIsLoading( true )
        actions( DELETE_BULK_ACTION, { 
            id: bulks.filter(bulk => bulk.checked).map(bulk => bulk.id), 
            data_type : props.data_type,
            landId
        
        } )
            .then((response) => {
                setIsLoading( false )
                console.log(response)
                if(Array.isArray( response )) {
                    const bs: IBulk[] = [...bulks].filter( bulk => !response.map(r => r.id).includes(bulk.id) ) 
                    setBulks( bs )
                    const its: any[] = [...items].filter( item => !response.map(r => r.id).includes(item.id) ) 
                    setItems( its )
                }
                actions(
                    GET_FEED_ACTION, 
                    { 
                      data_type: props.data_type, 
                      offset, 
                      count: pageCount, 
                      paging,
                      land_id: landId 
                    })
                    .then( response2 => {
                        setItems(response2.feed)
                        setBulks( response2.feed.map((f: any) => ({
                            id: f.id,
                            checked: false,
                            data_type: props.data_type
                        })))
                    })
            })
    }
    return <>
        <Button  
            className= " h-100 px-4"
            intent={Intent.DANGER} 
            onClick={() => setOpen(true)}
        >
            <Icon icon={ isLoading ? "repeat" : "trash" } className={isLoading ? `mr-2 fa fa-spin ` : `mr-2 `} />
            {__("Delete bulk items")}
        </Button>
        <Dialog
            isOpen={isOpen}
            onClose={() =>setOpen(false)}
            title={__("Do you want finally delete selected items?")} 
        >
            <div className="p-5 flex-centered flex-column overflow-y-auto max-height-350 height-350">
                <div className="">
                {
                    bulks.map((bulk:IBulk, i:number) => {
                        const item: any = items.filter( item => item.id === bulk.id )[0]
                        const title: string = item ? item[visibleValue] : `no title (${bulk.id})`
                        return <div key={bulk.id} className="d-flex align-items-center my-1">
                            <TableCheckBox 
                                {...props}
                                checked={ bulk.checked }
                                onChecked={(checked:boolean) => onChange(checked, bulk) }
                            /> 
                            <div className="">{ title }</div>    
                        </div>
                    })
                }
                </div>
            </div>
            <ButtonGroup fill>
                <Button 
                    intent={Intent.DANGER} onClick={ onFinallyDelete } className="p-3">
                    {__("Yes, finally delete selected items")}
                </Button>
                <Button intent={Intent.SUCCESS} onClick={() => setOpen(true)} className="p-3">
                    {__("No")}
                </Button>
            </ButtonGroup>
        </Dialog>
    </> 
} 

export default BulkDeleter