import { IOpponent } from "@/modules/pe-ludens-module/data/interfaces"
import { useEffect, useRef, useState } from "react"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { GET_MATRIX_ACTIONS, UNSET_MATRIX_ACTIONS } from "src/modules/pe-manitu-module/data/actionTypes"
import { actions } from "src/modules/pe-manitu-module/data/actions"
import { IManitu, IManituStore, useManitu, useManituStore } from "src/modules/pe-manitu-module/data/store"
import { WINDOW_WIDTH } from "src/libs/interfaces/layouts"
import { LoaderBallsLine } from "src/libs/useful"
import { IManituAction, IManituActionType, IManituCell, IManituMatrix, MATRIX_MODE } from "../../../data/interfaces"
import AnimationLabel, { RefALabel } from "../animations/AnimationLabel"
import Chpock, { RefType } from "../animations/Chpock"
import Row from "./Row"
import UnderMatrix from "./UnderMatrix"
import { IDoActionReturn } from "@/modules/pe-manitu-module/data/mocks/doAction"
import Hoverer from "./Hoverer"


const animationPadding = 40
interface IMatrixProps {
    user?: IOpponent
    mode: MATRIX_MODE
}
const Matrix = (props: IMatrixProps): JSX.Element => { 
    const [isLoading, setIsLoading] = useState(true) 
    const [mouseX, setMouseX] = useState<number>(0) 
    const [mouseY, setMouseY] = useState<number>(0) 
    const [isHover, setIsHover] = useState<boolean>(false) 
    const [position, setPosition] = useState<any>({})

    const rows = useManituStore((state: IManituStore) => state.matrixRows)
    const columns = useManituStore((state: IManituStore) => state.matrixColumns)
    const size: number = useManituStore((state: IManituStore) => state.size)
    const { width } = useScreenSize()
    const childRef = useRef<RefType>(null)
    const labelRef = useRef<RefALabel>(null)

    // opponent actions
    const [ mactions, setMactions ] = useState<IManituAction[]>([])
    const selfMatrix: IManituMatrix = useManituStore((state: IManituStore) => state.selfMatrix)
    const matrix: IManituMatrix = useManitu((state: IManitu) => state.matrix)
    
    useEffect(() => {
        setIsLoading(true)
        actions( GET_MATRIX_ACTIONS, { user: props.user, isMine: props.mode === MATRIX_MODE.MINE })
            .then(response => {  
                setMactions(response.matrixActions)
                setIsLoading(false)
            })
    }, [ props.user, selfMatrix ])
    const getPosition = () => {
        const mtrx: Element = document.getElementsByClassName("pe-manitu-matrix-main")[0]
        if(!mtrx)return;
        const pos: any = mtrx.getBoundingClientRect()
        setPosition( pos )
    } 

    useEffect(() => {
        actions(UNSET_MATRIX_ACTIONS, {})
        getPosition() 
        window.addEventListener('resize', getPosition)
        return () => window.removeEventListener('resize', getPosition)
    }, [])
    
    const padding = width < WINDOW_WIDTH.TABLET ? 100 : 0
    const style = {
        width: columns * (size + 2) + padding * 2,
        height: rows * (size + 2) + padding * 2,
        minWidth: columns * (size + 2) + padding * 2,
        minHeight: rows * (size + 2) + padding * 2,
        padding: padding ,
        //transform: `translateX(${padding}px) translateY(${padding}px)`
    }
    const styleCont = { width: style.width + padding * 2, height: style.height + padding * 2 }

    if (!matrix || !matrix.rows || !props.user) return <div style={styleCont} />

    const onCellActionResult = ( 
        cell: IManituCell, 
        action: IManituActionType,
        result: IDoActionReturn
    ) => {
        if (childRef.current) {
            childRef.current.start( 
                ( cell.i + .5 ) * (size + 2) + animationPadding,
                ( cell.rowID + .5 ) * (size + 2) + animationPadding,
                action.color,
                action.animationType,
                action.animationDuration,
                (size + 2) * 3,
                (size + 2) * 3,
            );
        }
        if(labelRef.current) {
            labelRef.current.start( 
                ( cell.i + .5 ) * (size + 2) + animationPadding,
                ( cell.rowID + .5 ) * (size + 2) + animationPadding,
                action.color,
                result,
                200
            )
        }
    } 
    const onMove = (e: any) => { 
        setMouseX( e.pageX - position.left )
        setMouseY( e.pageY - position.top )
    }
    const onOver = () => {
        setIsHover(true)
    }
    const onOut = () => {
        setIsHover(false)
    }


    return <div className="pe-manitu-matrix-d-c flex-centered flex-column" > 
        <div className="pe-manitu-matrix-menu"> 
            <UnderMatrix mode={props.mode} />
            {
                (isLoading || !props.user) &&
                <LoaderBallsLine />
            }
        </div>
        <div 
            className="pe-manitu-matrix-drag-cont"
            onMouseMove={onMove}
            onMouseOver={onOver}
            onMouseOut={onOut}
        >
            <div 
                className="d-flex position-relative z-index-100 pe-manitu-matrix-main" 
                style={{
                    transform: `translate( -${animationPadding}px, -${animationPadding}px)`
                }}
            >  
                <Hoverer 
                    width={style.width + animationPadding * 2 }
                    height={style.height + animationPadding * 2 } 
                    animationPadding={animationPadding} 
                    isHover={isHover}
                    mouseX={mouseX}
                    mouseY={mouseY}
                />
                <AnimationLabel
                    width={style.width + animationPadding * 2 }
                    height={style.height + animationPadding * 2 } 
                    animationPadding={animationPadding} 
                    ref={labelRef}
                />
                <Chpock 
                    width={style.width + animationPadding * 2 }
                    height={style.height + animationPadding * 2 }  
                    animationPadding={animationPadding}
                    ref={childRef}
                />
            </div>
            <div className="pe-manitu-matrix-container " style={style}>
            {
                matrix?.rows?.map((row, i) => {
                    return <Row
                        key={row.id}
                        item={row}
                        mode={props.mode}
                        actions={mactions.filter(action => action.rowOrder === i)}
                        organoids={matrix.organoids}
                        onActionResult={ onCellActionResult }
                    />
                })
            }  
            </div>
        </div>
    </div>        
}

export default Matrix