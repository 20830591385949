import { __ } from "@/libs/utilities"
import ChoosePatterns from "./ChoosePatterns"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"

/* Protopia Ecosystem component */
export interface IAddFestivalMenuProps {
    onFestival: (festivalSite: ILand) => void
}
const AddFestivalMenu = (props: IAddFestivalMenuProps): JSX.Element => {
    return <div className='pe-add-festival-menu-container'>
        <div className="mb-4">
            <div className="lead px-2">
                {__("Choose pattern")}
            </div>
            <ChoosePatterns onFestival={props.onFestival}/>  
        </div>
        <div className="mb-4">

        </div>
    </div>
}
export default AddFestivalMenu