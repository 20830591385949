import { IAppData, ILayoutData, IRoutingData } from "./layouts"

export const AppDummy = () => {
    return {
        title: "",
        name: "",
        description: "",
        external_systems: [],
        scopes: "",
        adapter: "",
        user_model: "",
        help_url: "",
        init_method: "",
        roles: [],
        email: "",
        adress: "",
        user_verify_account: "",
        vk: "",
        youtube: "",
        android: "",
        apple: "",
        vk_app_id: "",
    }
}
export const RoutingDummy = () => {
    return {
        extended_routes: {},
        menu: {},
        main_menu: {},
        footer: {},
        profile: {},
        link: {},
    }
}
export const LayoutDummy = () : ILayoutData => {
    return {
        template: {},
        app: AppDummy() as IAppData,
        folders: {},
        views: {},
        widgets: {}, 
        extentions: {}, 
        'widget-area': {},
        routing: RoutingDummy() as IRoutingData,
        modules: {},
        schema: {},
    } 
}

export const DEFAULT_404_PROPS = {
    title: '404',
    icon: 'fas fa-exclamation-circle'
}