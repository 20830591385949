import { __ } from "src/libs/utilities" 
import { useNavigate } from "react-router-dom"
import { IFabulaHero, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { actions } from "src/modules/pe-fabula-module/data/actions"
import { CHANGE_HERO_ACTION } from "src/modules/pe-fabula-module/data/actionTypes"
import { IState, IUser, useMainStore } from "src/settings/zustand"

/* Protopia Ecosystem component */
export interface IStoryCoverProps {

}
const StoryCover = (props: IStoryCoverProps): JSX.Element => {
    const user: IUser = useMainStore((state: IState) => state.user)
    const PeStory : IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current) 
    const navigate = useNavigate()
    const start = (hero: IFabulaHero) => {
        if( user.id ){
            actions(CHANGE_HERO_ACTION, { hero })
                .then(() => navigate("play"))
        }
        else {
            navigate("login")
        }
    }
    return <div className='pe-story-cover-container'>
        <div 
            className="crd text-light"
            style={{backgroundImage:`url(${PeStory.thumbnail})`}}
        >
            <div className="h display-6 z-index-1">
                { PeStory.title }
            </div>
            <div className="my-1 mx-4 text-center z-index-1 " dangerouslySetInnerHTML={{__html: PeStory.post_content}} />
            <div className=" z-index-1 d-flex flex-wrap w-100 justify-content-center mt-auto"> 
                <div onClick={ () => {}}>
                    <div className="avatar-square-btn" style={{backgroundImage:`url(${PeStory.thumbnail})`}}>
                        { __("Excursion") }
                    </div> 
                </div> 
                {
                    PeStory.heros?.map(hero => {
                        return <div 
                            key={hero.id} 
                            onClick={ () => start(hero) }
                            className={user.id ? `` : ` user-select-none `}
                        >
                            <div 
                                className="avatar-square-btn"
                                style={{ backgroundImage: `url(${hero.avatar})`}}    
                            >
                                { hero.display_name || hero.id.toString() }
                            </div> 
                        </div> 
                    })
                } 
            </div>
        </div>
    </div>
}
export default StoryCover