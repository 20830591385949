import { SCALAR_TYPES, VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { jsonToData } from '@/libs/utilities'
import React, { useState } from 'react' 
import FieldInput from 'src/libs/scalars' 
import { Json } from '@/libs/interfaces/layouts'
import RussionRegionList from '@/libs/scalars/scalars/RussianRegionList'

const FormType = (props: any) : JSX.Element => {
    const [value, setValue] = useState(props.value || "")
    const onChange = (value: any) =>
    {
        setValue( value )
        props.onChange( value )
    }
    const getValues = () =>
    {
        const values = props.values || ""
        return values.split("/").map( (v: string) => {
            return {
                _id: v,
                title: v,
                color:"#FF0000"
            }
        }) 
    }
    switch(props.type)
    {
        case SCALAR_TYPES.GEO: 
            return <FieldInput 
                type={ SCALAR_TYPES.GEO } 
                className={` w-100 input dark form-control `}
                vertical={VerticalType.VERTICAL} 
                editable
                width="100%"
                value={ getGeopositionData(value) } 
                on={onChange}
                onChange={onChange}
            />
        case SCALAR_TYPES.MEDIA:
            return <FieldInput 
                type={ SCALAR_TYPES.MEDIA } 
                className={` w-100 input dark form-control `}
                vertical={VerticalType.VERTICAL} 
                editable
                value={ value ? value : "" } 
                on={onChange}
                onChange={onChange}
            />
        case SCALAR_TYPES.TAGS:
            return <FieldInput 
                type={ SCALAR_TYPES.TAGS }
                values={ getValues() }
                className={` w-100 input dark form-control `}
                vertical={VerticalType.VERTICAL}
                editable
                value={ value ? value : "" } 
                on={onChange}
                onChange={onChange}
            />
        case SCALAR_TYPES.SELECT:
            return <FieldInput 
                type={ SCALAR_TYPES.SELECT }
                values={ getValues() }
                className={` w-100 input dark form-control `}
                vertical={VerticalType.VERTICAL} 
                editable
                width={ 250 }
                height={ 80 }
                value={ value ? value : "" }
                on={onChange}
                onChange={onChange}
            /> 
        case SCALAR_TYPES.RUSSIAN_REGIONS: 
            return <FieldInput 
                type={SCALAR_TYPES.SELECT}
                className={` w-100 input dark form-control `}
                vertical={VerticalType.VERTICAL} 
                editable={true}
                value={ value ? value : "" }
                values={ RussionRegionList().map(r => ({_id: r, title: r})) }
                on={onChange}
                onChange={onChange}
            /> 
        case SCALAR_TYPES.PHONE:
            return <FieldInput 
                type={ SCALAR_TYPES.PHONE }
                className={` w-100 input dark form-control `}
                vertical={VerticalType.VERTICAL}
                editable 
                value={ value ? value : "" }
                on={onChange}
                onChange={onChange}
            />  
        case SCALAR_TYPES.STRING:
        default:
            return <FieldInput 
                type={  props.type }
                className={` w-100 input dark form-control `}
                vertical={VerticalType.VERTICAL}
                editable 
                value={ value ? value : "" }
                on={onChange}
                onChange={onChange}
            /> 
    } 
} 

export default FormType

/*
    принимаем данные в одном из 2 видов:
    1. Json
    2. [lat, let, zoom]
    @return - [lat, let, zoom]
*/
const getGeopositionData = (data: string | any[] ) => {
    if(!data) {
        return ""
    }
    if(Array.isArray(data) && data.length <=3 ) {
        return data
    }
    const geo: (string | number)[] = jsonToData( data as Json )
    return geo
}