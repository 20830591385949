import { useDataStore } from "src/modules/pe-basic-module/data/store";
import { IUser } from "src/settings/zustand";
import { IPEResourceType } from "../interfaces";

export const userResources = (user: IUser) => {
    const resourceTypes: IPEResourceType[] = useDataStore.getState().PEResourceType || []
    let uR: any = {}
    resourceTypes.forEach(rt => {
        uR[rt.id.toString()] = { count: Math.floor(Math.random()* 20) + 30 } 
    })
    return uR 
}