
import React from "react" 
import BasicState from "@/libs/basic-view" 
import VerifyUserForm from "./profile/VerifyUserForm"

class VerifyUserState extends BasicState {
    props: any

	addRender = () => <div className="container">
                <div className="row justify-content-center"> 
                    <VerifyUserForm {...this.props} /> 
                </div>
            </div>  
}
export default VerifyUserState