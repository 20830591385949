import { Callout, Intent } from "@blueprintjs/core"
import LoginRegisterForm from "src/modules/pe-basic-module/views/profile/LoginRegisterForm"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import { __ } from "src/libs/utilities"

const JoinManituForm = (): JSX.Element => {
    const user: IUser = useMainStore((state: IState) => state.user)
    return user.id 
        ?
        <Callout className="p-5 h-100 display-5 text-center flex-centered" intent={Intent.DANGER}>
            {
                __("You are not have Manitu matrix. Contact your Master.")
            }
        </Callout>
        :
        <div className="h-100 w-100 flex-centered">
            <LoginRegisterForm />
        </div>
} 

export default JoinManituForm