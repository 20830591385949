import React from 'react' 
import { EMTY_IMAGE_NAME, ICardProjectProps, IFestival } from '../../data/interfaces'
import { Link } from 'react-router-dom'
import {__} from 'src/libs/utilities'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import MemberRatingDisplay from './MemberRatingDisplay'
import { getRoute, getSingleRoute } from 'src/libs/layouts'
import TrackLabel from './TrackLabel'
import ExtendedForms from '../project/projectData/ExtendedForms'
import { useOwnerPartner, useUserOwner } from '../../hooks/project'

const TwoSizeProject = (props: ICardProjectProps): JSX.Element => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const isOwner: boolean = useUserOwner( props.item )
    const isPartner:boolean = useOwnerPartner(props.item)
    const gcolor = "#14172b"
    const routeSingle = getSingleRoute("PEFestProject") 
    const url = routeSingle ? `/${getRoute(routeSingle)}/${props.item.id}` : "/" 
    return (
        <div className="two-side-card-cont col-md-4 col-sm-6 m-0 p-0">      
            <Link className="two-side-card" to={url}>
                <div className="text-overlay">
                    <div
                        className="two-side-card-img h-100 w-100 "
                        style={{
                            backgroundImage: `url( ${
                                props.item.thumbnail && props.item.thumbnail.indexOf(EMTY_IMAGE_NAME) === -1  
                                    ? 
                                    props.item.thumbnail 
                                    : 
                                    festival.defaultProjectThumbnail 
                            })`,
                            borderColor: gcolor,
                            height: festival.memberCardHeight,
                            opacity: !props.item.is_verified ? .33 : 1
                        }}
                        data-mid={props.item.id}
                        data-fmru_type="fmru_player"
                        data-args={props.item.id}
                    >
                        <div className="card-id">
                            {props.item.order} 
                        </div>
                        <div className="bottom position-absolute w-100 mb-5"> 
                        {
                            props.item.prize
                                ? 
                                <div className='pe-small-pennant pe-pumpkit small font-monospace  mb-1'>
                                    <div>                                        
                                        {/* <div className="discr">
                                            {__("Prize:")}
                                        </div> */}
                                        <div className="data text-uppercase">
                                            {props.item.prize}
                                        </div>
                                    </div>
                                </div> 
                                :
                                null
                        } 
                        {
                            props.item.nomination
                                ? 
                                <div className='pe-small-pennant pe-pumpkit-dark small font-monospace bottom'>
                                    <div>                                        
                                        {/* <div className="discr">
                                            {__("Nomination:")}
                                        </div> */}
                                        <div className="data text-uppercase">
                                            {props.item.nomination}
                                        </div>
                                    </div>
                                </div> 
                                :
                                null
                        } 
                        </div>
                        <TrackLabel track={props.item.track} />
                    </div>
                    
                    <div
                        className="two-side-card-title"
                        title={props.item.title}
                            style={{ 
                            overflow: "hidden",
                            textOverflow: "ellipsis", 
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <h5 className="card-title m-0 d-flex w-100 justify-content-center align-items-center text-center" >
                            {props.item.title}
                        </h5>
                    </div>

                    {/* <div className="card-icons">
                        {_ganres}
                    </div> */}
                    
                    {
                        !props.item.is_verified && 
                            <div className="mine-corner-no-verified corner-card-project">
                                {__("Project has not yet been approved")}
                            </div>
                    }
                </div>
                <div className="purchase-button-container ">
                <ul className="list-group list-group-flush flex-grow-100 w-100 ">                           
                    <li className="list-group-item hidden text-overflow" style={{ height: 64 }}>
                        <span className="discr">
                            {props.item.post_content}
                        </span>
                    </li>
                    <ExtendedForms 
                        vertical={false}
                        item={ props.item } 
                        isCollapseOpen 
                        hidden="hide_in_card"
                    />
                    <li className="list-group-item py-2">
                        <span className="discr">
                            {__("Raiting:")}
                        </span>
                        <span className="data float-right">
                            <MemberRatingDisplay 
                            correct_member_rate={props.item.correctMemberRate}
                            countedRating={props.item.r2}
                            className={" "}
                            /> 
                        </span>
                    </li>
                    <li className="list-group-item py-2">
                        <span className="discr">
                            {__("Expert count:")}
                        </span>
                        <span className="data selected float-right">
                            {props.i === 0 ? "-" : props.i} 
                        </span>
                    </li> 
                </ul>
                {
                    isOwner
                    ?
                    <div className=" mine-corner over-side " style={{ top:-6, right:-6 }}>
                        { __( "My project" ) }
                    </div>
                    :                    
                    isPartner 
                        ?
                        <div className="mine-honeycombs-corner over-side z-index-100" style={{ top:-6, right:-6 }}>
                            {__("My honeycombs")}
                        </div>
                        :
                        null
                }
                </div>
                {
                    isOwner
                        ?
                        <div className="mine-corner d3d" style={{ top:14, right:8 }}>
                        { __( "My project" ) }
                        </div>
                        :                    
                        isPartner 
                            ?
                            <div className="mine-honeycombs-corner d3d z-index-100">
                                {__("My honeycombs")}
                            </div>
                            :
                            null
                }
            </Link>
        </div>
    )
} 

export default TwoSizeProject