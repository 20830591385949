import { IFabulaMediaType, IFabulaMultiScreenItem } from "src/modules/pe-fabula-module/data/interfaces"

/* Protopia Ecosystem component */
export interface ICurrentItemProps {
    item: IFabulaMultiScreenItem
}
const CurrentItem = (props: ICurrentItemProps): JSX.Element => {
    if(!props.item) {
        return <></>
    }
    switch( props.item.type) {
        case IFabulaMediaType.HTML:
            return <div className='' >
                <div className="display-4 mb-3 ml-5 h font-weight-thin">
                    { props.item.title }
                </div>
                <div 
                    className='pe-html-container pb-5'
                    dangerouslySetInnerHTML={{ __html: props.item.content }}
                />
            </div>
        default:
            return <div>
                { JSON.stringify( props.item ) } :: { props.item.type }
            </div>
    }
}
export default CurrentItem