import { Popover } from "@blueprintjs/core"
import { Fragment } from "react"
import HeaderMenuMainButton from "./HeaderMenuMainButton"
import { IMenuItem } from "@/libs/interfaces/layouts"
import HeaderMenuUnderButton from "./HeaderMenuUnderButton"

interface IProps {
    i: number
    item: IMenuItem
}
const HeaderUnderMenu = ({i, item, ...props}: IProps) :JSX.Element => {
    const children : JSX.Element[] | JSX.Element = Array.isArray(item.children)
        ?
        item.children.map((child: IMenuItem, i: number) => {
            return <HeaderMenuUnderButton item={child} key={i} i={i} parentRoute={ item.route }/>
        }) 
        :
        <div className="p-5">PODMENU</div>
    const onRoute = (route: string) => {

    }
    return <Fragment>
        <Popover
            popoverClassName="p-0 menu-popover"
            className=" p-0 d-flex "
            position="bottom-left"
            //usePortal={false}
            //enforceFocus
            interactionKind="hover"
            transitionDuration={ 50 }
            modifiers={{ arrow: { enabled: false } }}
            content={
                <div className=" podmenu-cont card2">
                    { children }
                </div>
            }
        >
            <HeaderMenuMainButton
              item={ item }
              i={ i }
              key={ i }
              onRoute={ onRoute }
              pt={"1"}
            /> 
        </Popover>
    </Fragment>
} 
export default HeaderUnderMenu