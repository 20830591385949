import { __ } from "src/libs/utilities" 
import AdsSingle from "./AdsSingle"
import { Link } from "react-router-dom"
import { Loading } from "src/libs/useful"
import { IToposPostsStore, useToposPostsStore } from "../../data/store"
import { useEffect, useState } from "react"
import { IAds } from "../../data/interfaces"
import { actions } from "../../data/actions"
import { GET_ADS_FEED_ACTION } from "../../data/actionTypes"
import { getFeedRoute } from "src/libs/layouts"
import AddAdForm from "./AddAdForm"

const ToposBreaks = () : JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const ads : IAds[] = useToposPostsStore( (state:IToposPostsStore) => state.ads )
    useEffect(() => {
        actions(GET_ADS_FEED_ACTION, {count: 20}).then( () => setIsLoading( false ) )
    }, []) 
    if(isLoading) return <div className="layout-state"><Loading /></div> 
    const link = getFeedRoute("Ad") ? `/${getFeedRoute("Ad").route}`: "/404"
    return (
        <>
            <div className="flex-grow-100 px-3 overflow-y-auto-thin">
                <div className="h display-6">{__("Ads")}</div>
                <div className="flex-centered flex-column">
                {
                    ads.map(ad => <AdsSingle item={ad} key={ad.id} />)
                }
                </div>
            </div>
            <div className="d-flex">
                <Link to={link} className="small ml-3 mt-1">
                    {__("All Ads")}
                </Link> 
                <AddAdForm />
            </div>
            
        </>
    )
} 

export default ToposBreaks