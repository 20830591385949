import { FEST_ADMIN_STATUSES } from "./interfaces";

export const festSatuses = () => ([
    {
        _id: FEST_ADMIN_STATUSES.BEFORE_START_PHASE,
        title: "Before starting Festival"
    },
    {
        _id: FEST_ADMIN_STATUSES.PREPAIRE_PHASE,
        title: "Project preparation phase"
    },
    {
        _id: FEST_ADMIN_STATUSES.PRESENTATION_PHASE,
        title: "Project submission phase"
    },
    {
        _id: FEST_ADMIN_STATUSES.AFTER_FINISH_PHASE,
        title: "Festival was closed"
    },
])