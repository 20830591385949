import { IFabulaSlide } from "src/modules/pe-fabula-module/data/interfaces"
import { SingleSpeech } from "../speech/Speech" 
import MusicsEngine from "../musics/MusicsEngine"

/* Protopia Ecosystem component */
export interface IStoryIntroSlideProps {
    item:IFabulaSlide
    slideOrder: number
    onNextBeat: () => void
}
const StoryIntroSlide = ({item, onNextBeat,  ...props}: IStoryIntroSlideProps): JSX.Element => {
    
    return <div 
        className='pe-story-intro-slide-container position-relative '
        style={{backgroundImage: `url(${ item.thumbnail })`}}
    >           
        <div className='pe-speech-engine-container' >
            <div className=" container "> 
                <SingleSpeech
                    speech={item}
                    onNextBeat={onNextBeat}
                />
                <MusicsEngine 
                    audio={ item.music } 
                    musicVolume={item.musicVolume} 
                    musicStart={item.musicStart} 
                />
            </div>
        </div>        
    </div>
}
export default StoryIntroSlide