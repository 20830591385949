
import { Slider } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { __ } from "src/libs/utilities" 
import { IToposPostsStore, IToposStore, useToposPostsStore, useToposStore } from "../data/store"
import { actions } from "../data/actions"
import { GET_EVENTS_ACTION } from "../data/actionTypes"
import { IEvent } from "../data/interfaces"
import { labels } from "../data/labels"

interface ITimeMenuProps {
    onChange: (value:number) => void
}
const TimeMenu = (props: ITimeMenuProps) :JSX.Element => {
    const range: number = useToposStore((state:IToposStore) => state.range)
    const  setRange: ((range: number) => void) = useToposStore((state:IToposStore) => state.setRange)
    const  setEvents: ((events: IEvent[]) => void) = useToposPostsStore((state:IToposPostsStore) => state.setEvents)
    const [value, setValue] = useState<number>( range )
    const [isClick, setIsClick] = useState<boolean>( false ) 
    const labelRender = (value: number, opts?: { isHandleTooltip: boolean; } | undefined): string => {
        return __(labels[value])
    }
    const loadEvents = ( _value: number ) => {        
        const start: number = Date.now()
        const finish: number = start + 1000 * 60 * 60 * 24
        actions(GET_EVENTS_ACTION, [ 0, 1 ].includes(_value) ? { period: {start, finish} } : {})
            .then(( response: IEvent[] ) => { 
                setEvents( response )
            })
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsClick( true )
        }, 200);
        loadEvents( range )
        return () => {
            clearTimeout( timer )
        }
    }, [])
    const onChange = (value:  number) => {
        setIsClick( true )
        setValue( value )
        setRange( value )
        loadEvents( value )
        props.onChange(value)
    }
    return <div className={ `topos-calendar-widgwt  ${ isClick ? " lazy " : ""}` }> 
        <div className="topos-calendar-menu"> 
            <Slider
                min={0}
                max={4}
                labelStepSize={1}
                labelRenderer={labelRender}
                className={`topos-calendar-slider`}
                onChange={onChange}
                value={value}
            />
        </div>
    </div>
}

TimeMenu.propTypes = {}

export default TimeMenu