import { Navigate, Route, Routes } from "react-router" 
import Month from "./Month"
import Year from "./Year"
import Week from "./Week"
import Today from "./Today" 
import Day from "./Day"
import { IToposStore, useToposStore } from "../../data/store"
import MapContainer from "./MapContainer"
import { labels } from "../../data/labels"

const ToposMapForm = (props: any) :JSX.Element => {
  const range: number = useToposStore((state:IToposStore) => state.range || 0)
  return <div className="w-100 h-100">
      <Routes>
          <Route path="/*" element={ <MapContainer onOpenLogin={props.onOpenLogin}/> } >
              <Route index element={ <Navigate replace to={ labels[ range ] } /> } /> 
              <Route path="year" element={ <Year /> } />
              <Route path="month" element={ <Month /> } />
              <Route path="week" element={ <Week /> } />
              <Route path="day" element={ <Day /> } />
              <Route path="today" element={ <Today /> } />
          </Route>
      </Routes> 
  </div>
}

ToposMapForm.propTypes = {}

export default ToposMapForm