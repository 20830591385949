
export const SEND_EXHORTATION = (data: any) => {
    const site = data.landId
        ?
        ` land_id: "${ data.landId }" `
        :
        ``
    return `
        mutation changePEFestExhortation {
            changePEFestExhortation( 
                    id: "${data.id || "-1"}", 
                    input: {
                       memberId: "${data.memberId}"
                       descr: "${data.descr}"     
                    },
                    ${ site }
            ) {
                memberId
                descr 
                unixtime
            }
        }
    `
}