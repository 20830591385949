import {modules} from "./modules"

export const initModules = (): Promise<boolean> => { 
    /* TODO:: сначала проверить на существование apollo.client  */
    const _modules : any = modules()
    let inits:Promise<any>[] = []
    Object.keys(_modules)
        .filter(( module: any ) => { 
            return !_modules[module].disabled 
        })
        .forEach(m => { 
            inits.push( import(`src/modules/${m}/init.tsx`) )
        })
    if( !inits.length ) {
        return new Promise<boolean>(resolve => resolve(true))
    }
    let key : number = 0
    return new Promise<boolean>((resolve, reject) => {
        const step = (stepKey: number) => { 
            if( !inits[stepKey] ) {
                reject(false)
            }
            return inits[stepKey].then( response => {
                if( stepKey < inits.length - 1) {
                    if( response?.default) {
                        response.default() 
                    } else {
                        reject(false)
                    }
                    key = stepKey + 1
                    step( key )
                }
                else {
                    resolve(true)
                }
            })
        }
        step( key )
    })
    
    //return true
}