
import {  DocumentNode } from "graphql"
import { useAppoloStore, useMainStore } from "../../../settings/zustand" 
import { 
    getChangeName,
    getInputTypeName,
    getMutationArgs,
    getQueryArgs, 
    getQueryName, 
    mutationEdit, 
    querySingle, 
    querySingleName, 
} from "src/libs/layouts" 
import {  
    EXAMPLE_ACTIONS
} from "./actionTypes"  
const { gql } = require("@apollo/client/core")

const asyncControllers =  async(action:string, data: any) => {
    let response : any, 
        query : DocumentNode,
        query_name : string, fields : any
        
    const apolloClient: any = useAppoloStore.getState().client
    switch( action )
    {
        case EXAMPLE_ACTIONS:   
            query_name = `${querySingleName("Post")}`  
            query = gql`
                query ${query_name} {
                    ${query_name} 
                }`
            response = await apolloClient.query({
                query,
                variables: {
                    input: data
                }
            })      
            return response   
        default: 
            return  new Promise<any>( (resolve, reject) => { resolve(`Unknown action type: ${action}`)} )
    }
}
export default asyncControllers