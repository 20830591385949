
import BasicExtendView from '@/libs/basic-view/BasicExtendView'
import { areas, modules } from '@/libs/layouts'
import { __ } from '@/libs/utilities'
import { Button } from '@blueprintjs/core'
import { Route, Routes } from 'react-router'
import { NavLink } from 'react-router-dom'
import WidgetsForm from './widgets/WidgetsForm'
import { IModule } from '@/libs/interfaces/layouts' 
import WidgetsHead from './widgets/WidgetsHead'
import DefaultWidget from './widgets/DefaultWidget'

export class AdminWidgetView extends BasicExtendView {
    props: any
    left = (): JSX.Element => {
        const _areas = Object.keys(areas())
        .filter((key: string) => {
            return !areas()[key].is_scalar  
        } )
        .map((key: string) => {
            const module: IModule = modules()[ areas()[key].module ] || {} 
            return <NavLink
                to={key}
                className={"pe-admin-widget-btn"}
                key={key}
            >
                <Button minimal fill alignText='left' className='position-relative'>
                    <div className="d-flex align-items-center  ">
                        <div className="small text-center hint hint--top mr-2" data-hint={ __( module.title ) }>
                            <img
                                src={ module.icon }
                                className="w_25 h_25 "
                                alt=""
                            />
                        </div>
                        <div className="flex-grow-100 py-2">
                            <div className="lead">
                                { __(areas()[ key ].title) }
                            </div>
                            <div className="descr text-force-contrast">
                                { __(areas()[ key ].description) }
                            </div> 
                        </div>
                        <div className="w_80 flex-centered">
                            <div className={`position-absolute top right mt-3 mr-2 flex-centered badge rounded-pill bg-danger text-light ${!!areas()[ key ].area.length ? "" : "hidden"} w_25 h_25 title small`}>
                                { areas()[ key ].area.length }
                            </div> 
                        </div>
                    </div>                                                                                                   
                </Button>
            </NavLink>
        })
        return <div className="d-flex flex-column">
            {_areas}
        </div>
    }
    head = () : JSX.Element => {
		return <Routes>
            <Route
                path=":widgetAreaId"
                element={ <WidgetsHead /> }
            />
        </Routes>
	}
    center = (): JSX.Element => {
        return <div className="w-100 mb-5">
            <Routes>
                <Route index element={ <DefaultWidget /> } />
                <Route
                    path=":widgetAreaId"
                    element={<WidgetsForm {...this.props} />}
                />
            </Routes>

        </div>
    }
}

export default AdminWidgetView 