import React from 'react' 
import { NumericInput } from '@blueprintjs/core'
import { __ } from "src/libs/utilities"
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const URL = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<URLEnabled {...props} />}
        desabledForm={<URLDesabled {...props} />}
    />
} 
export default URL 

const URLEnabled = ( props: IScalarForm ): JSX.Element => { 
    const onChange = (evt: any) => { 
        const value = evt.currentTarget.value
        if( props.onChange )
        {
            props.onChange( value,  props.field, "" )
        }   
    }

    return <div className=" d-flex justify-content-center my-4 form-control p-0 input dark">
        <div className="w-100 d-flex align-items-center">
            <i className="fas fa-desktop" />
			<input
				type="text"
				className="form-control input dark"
				value={ props.value || ""}
				onChange={ onChange }
			/>
		</div>
    </div>
} 
 
const URLDesabled = ( props: IScalarForm ): JSX.Element => {
    return props.value && <div className='d-flex align-items-center'>
        <div className='lead  opacity_75'> 
            <i className="fas fa-link mr-2"></i>
		</div>
        <a href={props.value} target="_blank" rel="noreferrer" className="bp3-text-overflow-ellipsis">            
            {`${props.value} `}
        </a>
    </div> 
} 
 