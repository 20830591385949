
import { Button, ButtonGroup, Intent } from '@blueprintjs/core'
import ModulesForm from './modules/ModulesForm'
import BasicExtendView from '@/libs/basic-view/BasicExtendView'
import { __ } from '@/libs/utilities'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import ModuleSearch from './modules/ModuleSearch'

export class AdminModuleView extends BasicExtendView {  
    props: any
    onCurrent = (path: string) => {

    }
    left = () : JSX.Element => {
        return <div className=" m-5 tutor-menu">
            <ButtonGroup
                vertical={true}  
                large={true}
                fill={true}
            >
                <Link to="installed">
                    <Button  minimal active={true}>
                        {__("Installed Modules")}
                    </Button>
                </Link>
                <Link to="npm">
                    <Button minimal >
                        {__("Search in NPM")}
                    </Button>
                </Link>
                <Link to="gitlab">
                    <Button minimal >  
                        {__("Search in GitLab")}
                    </Button>
                </Link>
                <Link to="docker">
                    <Button minimal >  
                        {__("Search in Docker")}
                    </Button>
                </Link>
            </ButtonGroup>
        </div>
    }
    head = () : JSX.Element => {
        return  <Routes>
            <Route path='*' element={<Navigate to="installed" />} />
            <Route path='installed' element={<></>} />
            <Route path='npm' element={<>
                <svg viewBox="0 0 780 250" width={60} aria-hidden="true">
                    <path 
                        fill="#FF0000" 
                        d="M240,250h100v-50h100V0H240V250z M340,50h50v100h-50V50z M480,0v200h100V50h50v150h50V50h50v150h50V0H480z M0,200h100V50h50v150h50V0H0V200z">
                    </path>
                </svg>
            </>} />
            <Route path='gitlab' element={<>
                <i className="fab fa-gitlab fa-2x text-danger"></i>
            </>} />
            <Route path='docker' element={<><i className="fab fa-docker fa-2x "></i></>} />
        </Routes>
    }
    center = ():JSX.Element => { 
       return <div className="w-100 mb-5">
            <Routes>
                <Route path='*' element={<Navigate to="installed" />} />
                <Route path='installed' element={<ModulesForm />} />
                <Route path='npm' element={<ModuleSearch src="npm" />} />
                <Route path='gitlab' element={<ModuleSearch src="gitlab" />} />
                <Route path='docker' element={<>Docker</>} />
            </Routes>           
       </div>
    }
}

export default AdminModuleView 