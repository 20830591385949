import { ID } from "src/libs/interfaces/layouts"
import { useStorytellingStore } from "../data/store"
import { IFabulaEpisode, IFabulaMedia, IPEFabula, IPEFabulaMapTile } from "../data/interfaces"
import { actions } from "../data/actions"
import { GET_FABULA_ACTIONS } from "../data/actionTypes"

export const clearStory = () => {
    useStorytellingStore.setState({current: {}, hero: {}, timer: 0, isPaused: false })
}
export const clearFabula = () => {
    useStorytellingStore.setState({ fabula: {} as IPEFabula })
    actions(
        GET_FABULA_ACTIONS, 
        { id:"-1" }
    )
}

export const getMediaById = (mediaId: ID): IFabulaMedia | undefined => {
    const searchMedia = (media: IFabulaMedia, mediaId: ID): IFabulaMedia | undefined => {
        let targ : IFabulaMedia | undefined = undefined 
        if(media.id === mediaId) {
            targ = media
        }
        if(!!media.media?.length ) {
            media.media.forEach(m => {
                const searchM: IFabulaMedia | undefined = searchMedia(m, mediaId)
                if( !!searchM) {
                    targ = searchM
                }
            })
        }
        return targ
    }
    let media : IFabulaMedia | undefined = undefined 
    useStorytellingStore.getState().current.map.tiles
        .forEach((tile: IPEFabulaMapTile) => {
            tile.episodes.forEach((episode: IFabulaEpisode) => {
                episode.media.forEach(m => {
                    const searchM: IFabulaMedia | undefined = searchMedia(m, mediaId)
                    if( !!searchM) {
                        media = searchM
                    }
                })
            })
        })
    
    return media
}