import { IFabulaMedia, IFabulaMediaType } from "src/modules/pe-fabula-module/data/interfaces"
import HTML from "./HTML"
import MultiScreen from "./MultiScreen"
import TileEpisodeStuffs from "../map/TileEpisodeStuffs"

/* Protopia Ecosystem component */
export interface IMediaContentProps {
    item: IFabulaMedia
}
const MediaContent = (props: IMediaContentProps): JSX.Element => {
    return <>
        <GetMediaContent   item={props.item} />
        <TileEpisodeStuffs item={props.item} />
    </> 
}
export default MediaContent


const GetMediaContent = (props: IMediaContentProps): JSX.Element => {
    switch(props.item.type) {
        case IFabulaMediaType.HTML :
            return <div className="pe-fabula-media-content-container">
                <HTML item={props.item} />
            </div>
        case IFabulaMediaType.MULTI_SCREEN :
            return <div className="pe-fabula-media-content-container">
                <MultiScreen item={props.item} />
            </div>
        default:
            return <></>
    }
    
}