import { useState } from "react" 
import { IFestival, IProject } from "../../data/interfaces"
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from "../../data/store"
import { HelpContactsDisplay } from "./HelpContent"

const RegisterProject = (props:any) : JSX.Element =>
{ 
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const currentProject: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject )
    const [ message ] = useState(
        festival.newProjectMessage
            ?
            festival.newProjectMessage
            :
            `<div class="title my-3">
                Что теперь необходимо сделать?
            </div>
            <ol> 
                <li>
                    <p>Проверьте все вкладки «Этапы».</p>
                    <p>
                        Если Вы видите предупреждение «ДЛЯ ПРОДОЛЖЕНИЯ РАБОТЫ НА ЭТОМ ЭТАПЕ АВТОРУ НЕОБХОДИМО ЗАРЕГИСТРИРОВАТЬ ВЛОЖЕНИЯ», то значит необходимо нажать кнопку «Добавить новое вложение типа Ссылка» и загрузить (или указать) нужные данные Вашего проекта (за подробностями обратитесь к ведущему Вашего трека). 
                    </p>
                </li>
                <li>
                    <p>Оцените Ваш проект во вкладках с зелёными ярлыками (этапах самооценки). До конца фестиваля Вашу оценку можно свободно изменить.</p>
                    <p>
                        Средний бал, который получит Ваш проект вычисляется по определённой формуле из значений всех оценок, выставленных Вами и сторонними экспертами.
                    </p>
                </li>
            </ol>`
    )
    return <>
        <div className="advance">
            <p className="display-6">
                Ваш проект <span className="title">«{ currentProject.title }»</span> зарегистрирован.
            </p>
            <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        <HelpContactsDisplay />
    </>
}
export default RegisterProject