import Layouts from "../layouts";
import importAll, {getAllWidgets, } from "./importAll";
const getWidgets:any = {};
const plViews: any = []; 

function importAll_2(r: any, exists = []) {
  //console.log( r.keys() )
  r.keys().forEach((key:string) => {
    let key2:string[] = key.replace("./", "").split(".").slice(0, -1).join(".").split("/");
    let key1:any = key2[key2.length - 1];

    if (exists.length === 0 || exists.filter(elem => elem === key1).length > 0) {
      // console.log( key1 )
      getWidgets[key1] = r(key);
    }
  });  
  getAllWidgets( getWidgets )
}


export default getWidgets;
export const initWidgets = () =>
{
  const plgns = Layouts().modules; //console.log( plgns )
  
  Object.keys(plgns).forEach(plugin => {
    Object.keys(plgns[plugin].widgets).forEach(widget => {
      plViews.push(widget);
    });
    Object.keys(plgns[plugin].extentions).forEach(extention => {
      plViews.push(extention);
    });
  });
  // importAll(require.context( "modules/", true, /\.js$/ ), plViews);
  // importAll_2(require.context( "modules/", true, /\.js$/ ), plViews);
}
