import React, { useEffect, useState } from 'react' 
import {__} from '@/libs/utilities'

import { Button, ButtonGroup, Menu, MenuItem, Popover } from '@blueprintjs/core'
import { useNavigate } from 'react-router'
import { getAdminRoutes } from '@/modules/pe-topos-module/views/admin/getAdminRoutes'

const CabinetMenu = (props: ICabinetMenuProps) :JSX.Element => { 
    const [menuObjs, setMenuObjs] = useState<any[]>( [] )
    const navigate = useNavigate()
    
    const allRoutes = getAdminRoutes()
    useEffect(() => {
        const _menus: any = {} 
        Object.keys(allRoutes).forEach((r: any) => { 
            if( !_menus[allRoutes[r].group] ) _menus[ allRoutes[r].group ] = []
            _menus[allRoutes[r].group].push( { href: r, ...allRoutes[r] } )
        })
        setMenuObjs( Object.keys( _menus ).map(( group: string, i: number) => {
            const exampleMenu = (
                <Menu>
                    {
                        _menus[group].map(( me:any ) => {
                            return <MenuItem  
                                text={ __(me.title) }
                                onClick={ () => navigate( me.href ) }
                            /> 
                        })
                    } 
                </Menu>
            );
            return <Popover content={exampleMenu} fill={true} placement="bottom">
                <Button key={ i } minimal rightIcon="caret-down">
                    { __(group) } 
                </Button>
            </Popover>
        }) ) 

    }, [])
    
    return (
        <div className="mr-auto">
            <ButtonGroup className='flex-wrap'>
                <Button icon="home" minimal onClick={() => navigate( "" )} />
                { menuObjs }
            </ButtonGroup>
        </div>
    )
} 
export default CabinetMenu

export interface ICabinetMenuProps {
    
}