import React, { useEffect } from 'react'  

import { IClietnFormProps } from '../../data/interfaces' 
import { actions } from '../../data/actions'
import { GET_FEED_ACTION } from '../../data/actionTypes'
import { Outlet, Route, Routes } from 'react-router'
import { NavLink } from 'react-router-dom'

const ClientForm = ( props: IClietnFormProps ): JSX.Element => { 
    useEffect( () => { 
        actions( GET_FEED_ACTION, {data_type: "Post"} )
            .then(
                response => console.log( response ),
                error => console.log(error)
            )
    }, [] )
    return (
        <Routes>
            <Route path='' element={<div>
                <div className='btn-group'>
                    <NavLink className='btn btn-sm btn-secondary mx-1' to={''}>Home</NavLink>  
                    <NavLink className='btn btn-sm btn-secondary mx-1' to={'reglament'}>Reglament</NavLink>  
                    <NavLink className='btn btn-sm btn-secondary mx-1' to={'statistic'}>Statistic</NavLink>  
                    <NavLink className='btn btn-sm btn-secondary mx-1' to={'projects'}>Project list</NavLink>  
                    <NavLink className='btn btn-sm btn-secondary mx-1 hidden' to={'project/123'}>Single project</NavLink>                
                    <NavLink className='btn btn-sm btn-secondary mx-1' to={'track-moderator'}>Track moderator</NavLink>                
                </div> 
                <Outlet />
            </div> }>
                <Route index element={ <div>Index</div> } />
                <Route path='reglament' element={ <div>Reglament</div> } />
                <Route path='statistic' element={ <div>Statistic</div> } />
                <Route path='projects' element={ <div>Projects</div> } />
                <Route path='project/:id' element={ <div>Single project</div> } />
                <Route path='track-moderator' element={ <div>Track moderator</div> } />
            </Route>
        </Routes>
    )
} 

export default ClientForm