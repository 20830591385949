   
import BasicState from "src/libs/basic-view"
import "../assets/css/style.scss"  
import ManituAdminForm from "./admin/ManituAdminForm"

export default class ManituAdminView extends BasicState {
    props: any  
    addRender():JSX.Element| null {
        return <ManituAdminForm {...this.props} /> 
    }
}