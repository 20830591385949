import { ID, IMenuItem } from "@/libs/interfaces/layouts"

export const isRouteOpen = (item: IMenuItem, landId: ID, event_types: string[]): boolean => {
    const isMatrix: boolean =  (Array.isArray(item.route_matrix) && item.route_matrix[ 2 ])
        ?
        !!landId
        :
        true 
    let yes = true 
    if( !!item.route_filters ) {
        yes = false
        item.route_filters?.forEach(( filter: any ) => {
            Object.keys( filter ).forEach( key => {
                filter[key].forEach((fk : string) => {
                    if(event_types.includes(fk)) {
                        yes = true
                    }
                })
            })
            return yes 
        }) 
        // console.log(event_types, item.route_filters, yes)
    }
    
    return isMatrix && yes
}