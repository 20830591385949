import { Link } from "react-router-dom" 
import { wordTrim } from "src/libs/utilities"
import { getSingleRoute } from "src/libs/layouts"
import { IAds } from "../../data/interfaces"
import Moment from "react-moment"


interface IProps {
    item: IAds
}
const AdsSingle = (props: IProps) : JSX.Element => {
    const route = getSingleRoute("Ad")?.route 
    const url : string = route ? `/${ route }/${ props.item.id }` : ""
    return <>
        <Link to={url} className="topos-ad">
            <div className="thumbnail" style={{ backgroundImage: `url(${props.item.thumbnail})`}} />
            <div className="content">
                <div className="title text-overflow mb-1 text-force-contrast" title={ props.item.title }>
                    { props.item.title }
                </div>
                <div className=" text-force-contrast">
                    { wordTrim( props.item.post_content, 6 ) }
                </div>
                <div className="mt-1 opacity_75 super-small text-uppercase  text-force-contrast">
                    <Moment  locale="ru" format="D.MM.YYYY" >
                        { props.item.date }
                    </Moment>
                    
                </div>
            </div>
        </Link> 
    </> 
} 

export default AdsSingle