import { ID } from "@/libs/interfaces/layouts";
import { ICategory, ICritery, IProject, IRating } from "./interfaces";
import { useFestDataStore, useFestProjects } from "./store";

const getCategoryRating = ( category:ICategory ): number => {
    const project : IProject    = useFestProjects.getState().currentProject
    const criteries: ICritery[] = useFestDataStore.getState().criteries
    const criteryIDs : ID[] = criteries
        .filter((critery:ICritery) => critery.category?.id === category.id )
        .map((critery:ICritery) => critery.id)
    const average = (project.ratings || [])
    .reduce( (acc:number, rating:IRating ) => {
        
        return acc = acc + (criteryIDs.includes(rating.criteryId) 
            ?
            rating.rating
            :
            0
        )
    }, 0) 
    return average
}
export default getCategoryRating