import React from "react"
import { ReactComponent as Loader } from './assets/loader.svg'

interface ILoadingProps {
  classNames?: string
  style?: any
  fill?: string
}
const DefaultLoaderProps = {
  style: { width: "100%", height: 70, display: "flex", justifyContent: "center" },
  fill: "#444"
}

const Loading = (props: ILoadingProps ) => {
  return (
    <div className="loader-cont">
      <div className={`fa-spin ${props?.classNames && ""} `} >
        <Loader fill={props?.fill} width={60} height={60} />
      </div>
    </div>
  );
}
export default Loading 

export const LoaderMinimal = (props: ILoadingProps = { ...DefaultLoaderProps }): JSX.Element => {
  return <i
    className="fa-spin"
    style={{
      width: 14,
      height: 14,
      borderTop: "2px #777777 solid",
      borderLeft: "2px #777777 solid",
      borderBottom: "2px #777777 solid",
      borderRadius: 20,
    }}
  />
}
