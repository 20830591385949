import { TextArea } from "../ExpertDescriptions"

const { useSwiper } =  require("swiper/react")  

/* Protopia Ecosystem component */
export interface ITextAreaMobileProps {

}
const TextAreaMobile = (props: ITextAreaMobileProps): JSX.Element => {
    const swiper = useSwiper()
    const onSend = () => {
        swiper.slideTo(0)
    }
    return <TextArea onSend={onSend} />
}
export default TextAreaMobile