import { PETestQuestion, PE_TEST_QUESTION_TYPE } from "../interfaces"

export const questions = () : PETestQuestion[] => {
    const list: PETestQuestion [] = [
        {
            id:1,
            hardness:1,
            title:"Краеведение изучает ….",
            thumbnail: "https://i.pinimg.com/564x/2a/d1/b8/2ad1b8d1cb537ac21d31e9285c9b3bd7.jpg",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "субъект Российской Федерации",
                    //thumbnail: "https://i.pinimg.com/564x/ab/ea/5a/abea5afec1f9f45270a44de5dc726d93.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "историю любой охраняемой местности",
                    //thumbnail: "https://i.pinimg.com/564x/16/86/1c/16861c1ba56ed6288d29c119364a94f8.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "окраины населенных пунктов",
                    //thumbnail: "https://i.pinimg.com/564x/b4/68/10/b468101f1cac1af5afd492c79026607f.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "культурно-исторические и природные особенности какой-либо местности",
                    //thumbnail: "https://i.pinimg.com/564x/5e/d6/f9/5ed6f9a8e4803d17145a0c4e542b8dba.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 4,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id:2,
            hardness:1,
            thumbnail:"https://i.pinimg.com/564x/0d/5b/d6/0d5bd6abcbec43aec406e7ee9d76adf6.jpg",
            title:"Основоположником «организованного» краеведения в России можно считать…..",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "В.И. Татищева",
                    post_content: "",
                    thumbnail: "https://projects.orenlib.ru/np/up/vr1/images/1_img.jpg",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: " К.Д. Ушинского",
                    thumbnail:"https://i.mycdn.me/i?r=AyH4iRPQ2q0otWIFepML2LxRL7BHZP3CZq9_TBedbiggOw&fn=w_548",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "П.Ф. Каптерева",
                    thumbnail: "https://natlibraryrm.ru/wp-content/uploads/2023/01/Mc9qLXi_BqY.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "Н.М. Карамзина",
                    thumbnail: "https://pk33.mskobr.ru/files/novosti/255%20лет%20Карамзину/scale_1200.jpeg",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 1,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 3,
            hardness:1,
            title:"Какой русский государь придавал большое значение народному краеведению?",
            post_content: "",
            thumbnail: "https://i.pinimg.com/564x/cc/dc/b8/ccdcb88b0cb014e33ec4d3f2f640eaad.jpg",
            answers : [
                {
                    id: 1,
                    title: "Алексей Михайлович",
                    thumbnail:"https://aria-art.ru/0/A/Aleksej%20Mihajlovich/1-7.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "Петр I",
                    thumbnail:"https://kniganika.ru/images/detailed/10/1014978478.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: " Екатерина II",
                    thumbnail: "https://s01.yapfiles.ru/files/2549273/_II._imperatrica..jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "Николай II",
                    thumbnail: "https://nikolay2.ru/wp-content/uploads/2022/05/slabyj-nereshitelnyj-gosudar-privedshij-stranu-k-revoljucii-1917-goda-.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 2,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 4,
            hardness:1,
            title:"Кто из краеведов первым был избран в Российскую академию наук?",
            post_content: "",
            thumbnail: "https://i.pinimg.com/564x/97/c6/87/97c68744b6c86ba1aab4616b91491c52.jpg",
            answers : [
                {
                    id: 1,
                    title: "И.Г. Прыжов",
                    thumbnail: "https://find-books.ru/laravel/public/litres/67836791/_11.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "В.В. Крестинин",
                    thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/5192222/pub_6268bb0ac6b1353dae6182f7_6268bb8cc6b1353dae626734/scale_1200",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "П.И. Рычков",
                    thumbnail: "http://самарскийкрай.рф/wp-content/uploads/2019/12/Rychkov_Petr_Ivanovich-11.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "А.Т. Болотов",
                    thumbnail: "https://ic.pics.livejournal.com/teachron/67407322/1258229/1258229_900.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 3,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 5,
            hardness:1,
            title:"Как назывался предмет, который был введен в ряде школ России в ХIХ веке?",
            post_content: "",
            thumbnail: "https://i.pinimg.com/564x/ca/18/66/ca18661d2f471fe6935727317a43eb8a.jpg",
            answers : [
                {
                    id: 1,
                    title: "Региональная история",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "Фольклор",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "Краеведение",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "Родиноведение",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 4,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 6,
            hardness:1,
            thumbnail: "https://i.pinimg.com/564x/91/cb/2c/91cb2c5d234c105ba1a9dfe71da539cd.jpg",
            title: "В каком году Напкомпрос открыл Центральное бюро школьных экскурсий?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "1924",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "1918",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "1930",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "1933",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 2,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 7,
            hardness:1,
            thumbnail: "https://i.pinimg.com/564x/f4/12/29/f41229f84f85398b989674c1813fdbe0.jpg",
            title: "В каком городе России возник первый туристский клуб учащихся?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "в Москве",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "в Санкт-Петербурге",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "в Одессе",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "в Пятигорске",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 3,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 8,
            hardness:1,
            title: "Какой выдающийся российский ученый был активнейшем сторонником массового краеведения?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "Г.Ф. Миллер",
                    thumbnail: "https://информа.рус/wp-content/uploads/2019/11/332572.jpeg",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "М.В. Ломоносов",
                    thumbnail: "https://econet.ru/media/1077/covers/62444/original.jpg?1433356061",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "К.А. Тимирязев",
                    thumbnail: "http://agrolib.ru/rastenievodstvo/item/f00/s02/e0002039/pic/000000.jpg",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "Н.М. Карамзин",
                    thumbnail: "https://pk33.mskobr.ru/files/novosti/255%20лет%20Карамзину/scale_1200.jpeg",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 2,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 9,
            hardness:1,
            title: "Как лучше всего ставить вопросы при работе с информаторами?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "в конкретной форме",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "в наводящей форме",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "в общей форме",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "чтобы можно было ответить «да» или «нет».",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 1,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 10,
            hardness:1,
            title: "Что записывают в полевой дневник?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "результаты краеведческих исследований в хронологическом порядке",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "находки",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "результаты исследования растений на полях",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "описание движения группы в экспедиции",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 1,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 11,
            hardness:1,
            title: "Как правильно фотографировать костюм?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "спереди, сбоку и сзади",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "надев на участника экспедиции",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "крупным планом со всех сторон",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "при солнечном освещении",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 4,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 12,
            hardness:1,
            title: "С чего ранее надо начинать описание традиционной жилой застройки?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "с описания декора",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "с выявления всех типов домов селения",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "с выяснения плана селения",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "с описания самого старого дома в селении",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 3,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 13,
            hardness:1,
            title: "Какие экспонаты необходимо собирать в первую очередь для составления коллекций музея,  посвященного  знаменитому земляку?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "уникальные",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "подлинные той эпохи",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "принадлежащие этому земляку",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "наиболее типичные",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 3,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 14,
            hardness:1,
            title: " Как надо вести записи при беседе с информаторами? ",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "редактируя при расшифровке",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "записывая только главное",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "дословно, без редактирования",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "быстро",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 3,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 15,
            hardness:1,
            title: "Чем занимаются археографы?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "описывают то, что нашли археологи",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "собиранием, описанием и изданием документальных памятников",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "описанием архитектурных памятников",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "изучением архивов",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 3,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 16,
            hardness:1,
            title: "Что не является разновидностью метода непосредственного наблюдения в краеведческих экспедициях?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "опрос населения",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "личные наблюдения",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "собирание коллекций",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "раскопки",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 4,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 17,
            hardness:1,
            title: "Как утверждалось одобрение произведений народных умельцев в прошлом?",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "покупкой на рынке",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "подаяниями умельцу;",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "одобрением властями",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                },
                {
                    id: 4,
                    title: "голосованием на вече",
                    post_content: "",
                    is_subquestion: false,
                    order: 4
                },
            ],
            right_answer_id: 1,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 18,
            hardness:1,
            title: "До появления книгопечатания в Москве книги переписывались в:",
            thumbnail:"https://mykaleidoscope.ru/x/uploads/posts/2022-09/1663408887_45-mykaleidoscope-ru-p-moskva-stolitsa-rodini-oboi-48.jpg",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "крупных монастырях",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "при царском дворе",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "в мастерских при крупных церковных приходах",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                }, 
            ],
            right_answer_id: 1,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 19,
            hardness:1,
            title: "В строительстве стен и башен Московского Кремля из красного кирпича в конце XV в. принимали участие:",
            thumbnail:"https://static.gazetametro.ru/media/20220909130944/f840839e120ca43290f32f42e33792838477557b2619fcfbacfe610204d52d21.jpg",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "немецкие мастера",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "итальянские мастера",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "мастера из западнорусских земель",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                }, 
            ],
            right_answer_id: 2,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
        {
            id: 20,
            hardness:1,
            title: "Остатки самого старого жилого дома в Москве были найдены:",
            post_content: "",
            answers : [
                {
                    id: 1,
                    title: "в юго-западном углу современного Кремля",
                    post_content: "",
                    is_subquestion: false,
                    order: 1
                },
                {
                    id: 2,
                    title: "в юго-западном углу современного Кремля",
                    post_content: "",
                    is_subquestion: false,
                    order: 2
                },
                {
                    id: 3,
                    title: "в районе Красной площади",
                    post_content: "",
                    is_subquestion: false,
                    order: 3
                }, 
            ],
            right_answer_id: 1,
            single: false,
            type: PE_TEST_QUESTION_TYPE.MATCHING,

        },
    ]
    return list
}