import { Drawer, Position } from "@blueprintjs/core"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { WINDOW_WIDTH } from "src/libs/interfaces/layouts"
import { __ } from "src/libs/utilities"
import { IErmakStore, useErmakStore } from "../../data/store"

/* Protopia Ecosystem component */
export interface IStoreDrawerProps {
    
}
const StoreDrawer = ( props : IStoreDrawerProps): JSX.Element => {
    const {width} = useScreenSize()
    const isOpen: boolean = useErmakStore((state:IErmakStore) => state.isStoreOpen)
    const DRAWER_SIZE = width > WINDOW_WIDTH.TABLET ? "50%" : "100%"
    return <Drawer
        isOpen={ isOpen }
        autoFocus
        canEscapeKeyClose
        usePortal 
        position={ Position.RIGHT }
        size={ DRAWER_SIZE }
        title={ __("My storage").toUpperCase() }
        onClose={ () => useErmakStore.setState({ isStoreOpen: false }) }
    >
        <div className="h-100 overflow-y-auto">
            STORE
        </div>
    </Drawer>
}
export default StoreDrawer