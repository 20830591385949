import { Intent, Position, Tooltip } from "@blueprintjs/core";
import { AppToaster } from ".";
import { __ } from "../utilities";

export interface IClipboardPasteProps { 
    label?: string
    position?: Position
    paste: (data: any ) => void
    iface?: any
}
const ClipboardPasteBtn = (props: IClipboardPasteProps) : JSX.Element => {
    const paste = () => {
        navigator.clipboard.readText().then(clipText => {
          try {
            //console.log(clipText)
            const menuDatas = JSON.parse(clipText) 
            if( props.iface && menuDatas instanceof props.iface) {
                throw new Error("No correct data!")
            }
            props.paste( menuDatas )
          } catch (e) {
            AppToaster.show({
              intent: Intent.DANGER,
              icon: 'tick', 
              message: __('Error read clipboard data'),
            })
          }
        })
      }
    return <Tooltip content={ __( props.label || "Paste from clipboard") } position={props.position} >
        <div className='btn btn-link text-secondary  w-100 btn-sm ' onClick={ paste } >
            <i className="far fa-clone" /> 
        </div>
    </Tooltip>
} 

export default ClipboardPasteBtn 