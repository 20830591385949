
import {default as UUID} from "node-uuid"
import { IManituActionType, MANITU_ACTION_ANIMATION_TYPE, MANITU_ACTION_DISTINATION, MANITU_ENERGY_TYPE } from "../interfaces"
import { __ } from "src/libs/utilities"

export const actionTypes = () : IManituActionType[] => {
    const list : IManituActionType[] = [
        {
            id: 1,
            title: __("Define"),
            post_content: __("Add one defense to one own cell"),
            icon: "fas fa-shield-alt",
            distination: MANITU_ACTION_DISTINATION.SELF,
            instruction: "Укажите клетку, защита которой будет увеличена на единицу",
            price: [
                {
                    type : MANITU_ENERGY_TYPE.DEFENCE,
                    nominal: 10
                }
            ],
            color: "#635c36",
            animationType: MANITU_ACTION_ANIMATION_TYPE.HURA
        },
        {
            id: 1333334,
            title: __("Diamand"),
            post_content: __("Add 10 defenses to one own cell"),
            icon: "fas fa-gem",
            distination: MANITU_ACTION_DISTINATION.SELF,
            instruction: "Укажите клетку, защита которой будет увеличена на 10 единиц",
            price: [
                {
                    type : MANITU_ENERGY_TYPE.DEFENCE,
                    nominal: 7
                },
                {
                    type : MANITU_ENERGY_TYPE.RICH,
                    nominal: 13
                }
            ],
            color: "#183153",
            animationType: MANITU_ACTION_ANIMATION_TYPE.HURA
        },
        {
            id: 2,
            title: __("Add organoid's node"),
            post_content: __("Increase one own organoid by one cell"),
            instruction: "Укажите клетку в которой необходимо нарастить необходимый органоид",
            icon: "fas fa-plus-square",
            distination: MANITU_ACTION_DISTINATION.SELF,
            price: [
                {
                    type : MANITU_ENERGY_TYPE.RICH,
                    nominal: 15
                },
                {
                    type : MANITU_ENERGY_TYPE.IMPACT,
                    nominal: 4
                },
                {
                    type : MANITU_ENERGY_TYPE.MANA,
                    nominal: 10
                }
            ],
            color: "#1a6e91",
            animationType: MANITU_ACTION_ANIMATION_TYPE.EXPLODE
        },
        {
            id:3,
            title: __("Heal cell after attack"),
            post_content: __("Restore broken attacked cell"),
            instruction: "Укажите разрушенную клетку, которую хотите вернуть к жизни.",
            distination: MANITU_ACTION_DISTINATION.SELF,
            icon: "fas fa-briefcase-medical",
            price: [
                {
                    type : MANITU_ENERGY_TYPE.MANA,
                    nominal: 5
                },
                {
                    type : MANITU_ENERGY_TYPE.RICH,
                    nominal: 9
                },
                {
                    type : MANITU_ENERGY_TYPE.DEFENCE,
                    nominal: 30
                }
            ],
            color: "#d73d22",
            animationType: MANITU_ACTION_ANIMATION_TYPE.EXPLODE
        },
        {
            id: 4,
            title: __("Check Cell"),
            post_content: __("Pass minimal impact to reseach. Does no damage. Sends a text message."),
            instruction: "Укажите клетку для атаки",
            distination: MANITU_ACTION_DISTINATION.OPPONENT,
            icon: "fas fa-wind",
            price: [
                {
                    type : MANITU_ENERGY_TYPE.MANA,
                    nominal: 7
                },
            ],
            color: "#6e13a9",
            animationType: MANITU_ACTION_ANIMATION_TYPE.HURA
        },
        {
            id: 5,
            title: __("Attack"),
            post_content: __("Send powered impact for break defense and demage"),
            instruction: "Укажите клетку для атаки",
            distination: MANITU_ACTION_DISTINATION.OPPONENT,
            icon: "fas fa-skull-crossbones",
            price: [
                {
                    type : MANITU_ENERGY_TYPE.IMPACT,
                    nominal: 9
                },
            ],
            color: "#f01147",
            animationType: MANITU_ACTION_ANIMATION_TYPE.BOLT
        },
        {
            id: 6,
            title: __("Storm"),
            post_content: __("Send powered field imact for break defense and demage in cells 3x3"),
            instruction: "Укажите клетку влкруг которой будет проведена атка",
            distination: MANITU_ACTION_DISTINATION.OPPONENT,
            icon: "fas fa-cloud-showers-heavy",
            price: [
                {
                    type : MANITU_ENERGY_TYPE.MANA,
                    nominal: 19
                },
                {
                    type : MANITU_ENERGY_TYPE.IMPACT,
                    nominal: 20
                },
                {
                    type : MANITU_ENERGY_TYPE.RICH,
                    nominal: 11
                },
            ],
            color: "#65b6f7",
            animationType: MANITU_ACTION_ANIMATION_TYPE.STORM,
            animationDuration:170
        },
        {
            id: 7,
            title: __("Transfer"),
            post_content: __("Broadcast mana entrgy to opponent"),
            instruction: "Укажите любую клетку матрицы, а потом ресуры для передачи.",
            distination: MANITU_ACTION_DISTINATION.OPPONENT,
            icon: "fas fa-dolly",
            price: [
                {
                    type : MANITU_ENERGY_TYPE.MANA,
                    nominal: 19
                },
                {
                    type : MANITU_ENERGY_TYPE.RICH,
                    nominal: 13
                },
            ],
            color: "#61873a",
            animationType: MANITU_ACTION_ANIMATION_TYPE.EXPLODE
        },
    ]
    return list
}