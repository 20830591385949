import { useDataStore } from "../pe-basic-module/data/store"
import { GET_SPACES_ACTION } from "./data/actionTypes"
import { actions } from "./data/actions" 
import { useErmakStore } from "./data/store"

const init = () => {
    return new Promise<any>((resolve, reject) => {
        // console.log("init ermak")
        actions(GET_SPACES_ACTION, {} ).then(response => {
            if(!useErmakStore.getState().currentSpace.id) {
                useErmakStore.setState({
                    currentSpace: useDataStore.getState().PEErmakSpace[0]
                })
            }
        })
        
        resolve( true )
        reject( false )
    }) 
}
export default init