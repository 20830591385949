import { WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { useState, useEffect } from 'react';

export interface IWindowDimantion {
  width: number
  height: number
  dimention: keyof typeof WINDOW_WIDTH
}

function getWindowDimensions() : IWindowDimantion {
  if(!window) {
    return { width: 1200, height: 968, dimention : "PANEL" }
  }
  const { innerWidth: width, innerHeight: height } = window
  const dems: string[] = Object.keys( WINDOW_WIDTH ).filter((v) => isNaN(Number(v)))
  
  const demention = dems.filter( (key: string, i: number) => {
    const max = WINDOW_WIDTH[ dems[ i ] as keyof typeof WINDOW_WIDTH ]
    const min = WINDOW_WIDTH[ (dems[ i + 1 ]) as keyof typeof WINDOW_WIDTH ]
    const bool: boolean =  window.innerWidth > min 
      && ( i < dems.length - 1
          ?
          window.innerWidth < max
          :
          true )
    return bool
  })
  return {
    width,
    height,
    dimention: ( demention[0] || "PANEL" ) as keyof typeof WINDOW_WIDTH
  };
}
export default function useScreenSize() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}