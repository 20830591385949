import React, { useEffect, useState } from 'react' 
import { schema } from '@/libs/layouts'
import Feed from './Feed'
import FeedComponent from './FeedComponent'
import { IState, useMainStore } from '../../../../settings/zustand' 
import { getView } from '../../../../libs/utilities/importAll'
import { Loading } from '@/libs/useful'
 
let FeedLayoutComponent : any
let FeedSingleComponent: any

const FeedDataTypeForm = (props: any): JSX.Element => { 
    const pageCount: number = useMainStore( (state:IState) => state.pageCount )
    const [ isLoading, setIsLoading ] = useState<boolean>(true) 
    useEffect(() => { 
        const feedLayout: Promise<any> | null =  getView( schema()[ props.feed_data_type ]?.feed_layout )
        const feadSingle: Promise<any> | null =  getView( schema()[ props.feed_data_type ][ 'feed_view' ] )
        //console.log( schema()[ props.feed_data_type ]?.feed_layout, feedLayout )
        if( feedLayout )
        {
            feedLayout.then(
                response => {
                    FeedLayoutComponent =  response?.default
                    setTimeout(() => setIsLoading(false), 100)
                }
            )
        }
        else {
            setTimeout(() => setIsLoading(false), 100)
        }
        if( feadSingle ) {
            feadSingle.then(
                response => {
                    FeedSingleComponent =  response?.default 
                    setTimeout(() => setIsLoading(false), 100)
                }
            )
        }
        return () => {
            FeedLayoutComponent = null
            FeedSingleComponent = null
        }
    }, [])
    if( isLoading) return <Loading/>
    if( FeedLayoutComponent )
    {
        //console.log("FeedLayoutComponent")
        return <FeedLayoutComponent {...props} />
    }
    else {
        //console.log("feed_view")
        const paging = props.feed_data_type_parent_id
            ?
            ` parent: "${props.feed_data_type_parent_id}" `
            :
            ``
        let ElComponento = FeedSingleComponent
            ?
            FeedSingleComponent
            :
            FeedComponent

        return <div className={`p-0 d-flex flex-column w-100 container `}>  
            <Feed
                component={ ElComponento }
                data_type={ props.feed_data_type }
                is_hide_pagi={false}
                offset={ props.offset || 0 }
                count={ pageCount }
                height={ props.height }
                paging={ paging }
                class_name={` row`}
                params={{ 
                    col: props.extend_params?.col || 2
                }}
                style={ props.style }
                containerClassName={ props.feed_containerClassName || "short-container" }
            /> 
        </div>
    }
    
    
}
 
export default FeedDataTypeForm