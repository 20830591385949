import { ID } from "@/libs/interfaces/layouts"
import { ITestProps } from "./SingleTest"
import { ITestsStore, useTestsStore } from "../../data/store"
import { IPETest } from "../../data/interfaces"
import { Icon, Intent } from "@blueprintjs/core"

const Results = ({ item, ...props }: ITestProps) : JSX.Element => {
  const currentTest : IPETest  = useTestsStore((state:ITestsStore) => state.currentTest)
  const results: ID[]   = useTestsStore((state:ITestsStore) => state.results)
  return <div className="pe-matching-results my-5">
    {
      currentTest.questions.map( (q, i) => {
        return <div className="row strob01 py-2" key={q.id} >
          <div className="col-md-6">{q.title}</div>
          <div className="col-md-1">
            <Icon
              icon={  q.right_answer_id === results[i] ? "tick" : "cross"}
              intent={q.right_answer_id === results[i] ? Intent.SUCCESS : Intent.DANGER}
            />
          </div>
          <div className="col-md-5 small ">
            {
              q.answers.filter(a => a.id === q.right_answer_id)[0]?.title
            }
          </div>
        </div>
      })
    }
  </div>
  
} 

export default Results