import { IPEVKPost } from "src/libs/interfaces/layouts" 
import { likes } from "./likes"

export const feed = () : IPEVKPost[] => {
    let _list = [
        {
            id : 1675, 
            title : "Путешествие начинается",
            post_content : "<p>🚆Путешествие начинается: 🚗Москва - 🚅С-Петербург- 🚊Карелия -🚘Санкт-Петербург🚗...<p>❤🤝Так что готова встречаться!<p>😀 Завтра утром буду уже в Питере...<p>👋Кстати, не забудьте про нашу Мастерскую  «Настольная дидактика». Она точно будет! И точно начнется в понедельник, 2 октября, в 16.00 (мск). И есть еще пару мест!😍",
            thumbnail : "https://sun1-24.userapi.com/impg/k8aaeoOw_6Cr6WY9R3WVcjQYIwhCxqtJcs3egg/4ss7enpUsfI.jpg?size=564x752&quality=96&sign=1ec04c47e20e22a33251ecbd68eb90a2&c_uniq_tag=fIquXcC0aADB6Uyn8A63gookdLQXGQ20OtG9_Nvuhs0&type=album",
            post_author: {
                id : 1,
                display_name : "Оксана Глазунова",
                avatar : "https://sun1-19.userapi.com/s/v1/ig2/cmuMvffckb-ZExgHGwXWyJi-HCmrhLB46TijSsMSTyLs2VjkzkUX2WH0GiSY8kKqG7fXa_eikn0n3Q5UF2b6LNMI.jpg?size=200x200&quality=95&crop=3,397,1917,1917&ava=1",
                roles: [],
                post_content: "",
            },
            images: [],
            post_date:"9.30.2023 17:52",
            comments: [],
            likes:[],
            link: "https://vk.com/wall53136730_1675",
            __typename: "PEVKPost"
        },
        {
            id : 1693, 
            title : "Выставка «Школа Филонова»",
            post_content : `<p>... обратила внимание, что художники "пытаются" складывать свои работы, словно мозаику. В некоторых работах образ можно поймать, а в некоторых получается, что осколки не склеиваются...
            <p>И не случайно. Время разрушения старого мироустройства, и новый индустриальный мир еще не сложился... Художники подбирают эти осколки и строят из них большие полотна. Иногда отрицание старого мироустройства вызывает протест и негодование ( у меня во всяком случае), иногда резонанс. В любом случае, есть обратная реакция, а значит есть диалог...
            <p>По залу идет женщина, громко говорит, что она этого не понимает. И это тоже диалог...
            <p>Интересно, а клиповая действительность сегодня - это тоже попытка разбить всё на осколки?.... И кто-то старательно и гротескно это "склеивает", и это тоже вызывает протест или резонанс ? Наверное, да...`,
            thumbnail : "https://sun1-98.userapi.com/impg/XIuyo2X41HWEMqHsYbD5smIeSooBFOB1xgDd6w/W7fg5YImWMg.jpg?size=1626x2160&quality=95&sign=ec95bdfa0bf75bdc3b60a0f0a65b10b4&type=album",
            post_author: {
                id : 1,
                display_name : "Оксана Глазунова",
                avatar : "https://sun1-19.userapi.com/s/v1/ig2/cmuMvffckb-ZExgHGwXWyJi-HCmrhLB46TijSsMSTyLs2VjkzkUX2WH0GiSY8kKqG7fXa_eikn0n3Q5UF2b6LNMI.jpg?size=200x200&quality=95&crop=3,397,1917,1917&ava=1",
                roles: [],
                post_content: "",
            },
            images: [],
            post_date:"10.07.2023 17:52",
            comments: [],
            likes:[],
            link: "https://vk.com/oglazunova?w=wall53136730_1693",
            __typename: "PEVKPost"
        },
        {
            id : 1688, 
            title : "Путешествие",
            post_content : `Зарядил дождь, но думаю, мы его уговорим немного уняться.
            Вечером встречаемся со спецкорами Краефеста. Припасли сюрпризы!`,
            thumbnail : "https://sun9-24.userapi.com/impg/ZS3DCB6pc5f165PAkUElq8fJ3r8jOOff77Xj9w/aATA1xNYgzs.jpg?size=453x604&quality=95&sign=6e4a75ce4f3d3155e8f80f8bac3addc0&c_uniq_tag=DqsOPUZOaM4HkYMBsxAVECLPqiCVhTqmt_cemvSyWRc&type=album",
            post_author: {
                id : 1,
                display_name : "Оксана Глазунова",
                avatar : "https://sun1-19.userapi.com/s/v1/ig2/cmuMvffckb-ZExgHGwXWyJi-HCmrhLB46TijSsMSTyLs2VjkzkUX2WH0GiSY8kKqG7fXa_eikn0n3Q5UF2b6LNMI.jpg?size=200x200&quality=95&crop=3,397,1917,1917&ava=1",
                roles: [],
                post_content: "",
            },
            images: [
                "https://sun9-36.userapi.com/impg/jKyrcLK9wm2DLeNQjZ2J3cG85BtAMuztCiYyVg/YFSLHhwlGkM.jpg?size=1626x2160&quality=95&sign=d355d8972df7a42d6a465f48a8a5c9e4&type=album",
                "https://sun9-47.userapi.com/impg/eHdlKAphmMA50WBdCFRTea3I4TbuyX97vKLsXw/P9MoHpj-fak.jpg?size=2560x1927&quality=95&sign=c4e023ac2f7e0fba5e09dacd3d4dd01e&type=album",
                "https://sun9-6.userapi.com/impg/SCkd4hhzA_iRZZiJ--tjbcy64x4P9AGzLJ3KqA/LZxGctJLCIg.jpg?size=2560x1927&quality=95&sign=4bcd57ca5c39cacf6e409ebee21fdebe&type=album",
                "https://sun1-98.userapi.com/impg/0G-4_sGURc0Q_weD-tmSmFmnl6y58hyIBdfUTg/rAjmx37qNfM.jpg?size=2560x1927&quality=95&sign=b2628cfe266fbc5db5216b951bccc65f&type=album",
                "https://sun9-51.userapi.com/impg/7d5UWAL35jI5k4OmtvTUG8qIAaffR2CQ_h9Exg/nb_rpE4zRFc.jpg?size=1626x2160&quality=95&sign=296879204f23a77ef2be39b84aa0ec4b&type=album",
                "https://sun9-62.userapi.com/impg/5sd30kiE0thQPDieufVlnXtxML1qoKjIFb7HBQ/mXIvF_CId60.jpg?size=1626x2160&quality=95&sign=81cb8ab74bd71211fc05437cf1af8a30&type=album",
            ],
            post_date:"10.04.2023 17:52",
            comments: [],
            likes:[],
            link: "https://vk.com/oglazunova?w=wall53136730_1688",
            __typename: "PEVKPost"
        },
        {
            id : 26028, 
            title : "Echeveria ‘Pruskite’ 🍒",
            post_content : "<p>Echeveria ‘Pruskite’ 🍒", 
            thumbnail : "https://sun1-93.userapi.com/impg/KSuuUv4QwGsQe-NYQPgkbddi57ryCvwq-FAv5g/AZqClJ_KI1c.jpg?size=720x405&quality=95&sign=98229e11abf032496fb3ebea45ff92af&type=album",
            post_author: {
                id : 2,
                display_name : "BOTANY - суккуленты и аксессуары",
                avatar : "https://sun1-16.userapi.com/s/v1/ig2/tlefYPYxGHv12oIYO3Iwu22IFY8xVf3hq9qwy6Pt091ZpSsE-5RHy8iA2Xyc7jIssYYoaRPeWu8xRCC-rdmQov7W.jpg?size=100x100&quality=95&crop=0,0,1180,1180&ava=1",
                roles: [],
                post_content: "",
            },
            images: [
                "https://sun1-55.userapi.com/impg/imIYLhVXDiJHMP8xnqpaT8tSGutOkBAjSrau0g/W__19ym1MZY.jpg?size=828x807&quality=95&sign=4f521930abcad03fc1131045955824ff&c_uniq_tag=KLUuTnpmtt9TQZH97IEaB9Z54himD1l808e2Tkzi1cY&type=album",
                "https://sun1-18.userapi.com/impg/q6PwTufrqYr7dEGWYfH0-OuQqlauOOJBTtvuDQ/sFwpfnfEI60.jpg?size=828x609&quality=95&sign=58141e0cf6607d7777964f4a20bd87fd&c_uniq_tag=Gq7rt1g1IxsoZEPj-0iBJsE7JFLHy5cIdAslim98Uto&type=album",
            ],
            post_date:"3.10.2023 11:37",
            comments: [],
            likes:[],
            link: "https://vk.com/wall155693908_26028",
            __typename: "PEVKPost"
        },
        {
            id : 83519, 
            title : "Сансевиерия хоббит",
            post_content : "<p>Сансевиерия хоббит",
            thumbnail : "https://sun1-86.userapi.com/impg/rHrpAHUmsPBREi-JimH5rbMpob0PkuW90OiIAQ/ernXLBwowa0.jpg?size=605x807&quality=95&sign=78bdf396ae6b8906c0110a93b5e73abe&c_uniq_tag=eUnKA4NyZLAaqz0EuyQo42J0ZURFhtDmVUHmeUjFNe4&type=album",
            post_author: {
                id : 2,
                display_name : "Сансевиерия - Sansevieria",
                avatar : "https://sun1-54.userapi.com/s/v1/ig2/FaTNBQ5ZPfTHcvMPBPY3mJUNb1L7pzyF2lUmpmpZgxDP0NCDYivvGhwaljiHi1mLl-jyzcMENrx-GqG0b91iAaax.jpg?size=100x100&quality=95&crop=0,108,1914,1914&ava=11",
                roles: [],
                post_content: "",
            },
            images: [
                "https://sun9-1.userapi.com/impg/pWcyJavlfNWH4oIMfVewTkXy0rKHwQbJObXRvg/vxsN4p1QwQo.jpg?size=617x807&quality=95&sign=f449941ca5083ec6d5359b6f591a099a&c_uniq_tag=clcad3mvTkLoWK8J_w3wxRwoewMDcRiOV-Z1tfZ4JOY&type=album",
                "https://sun1-97.userapi.com/impg/viOW8DQ1KF4cY_98qFeTTR34tNpwj2tcM_9T1w/UxCoD6WfwOk.jpg?size=340x604&quality=95&sign=c4d3aca66b2afe51597d1cdfa00312a8&c_uniq_tag=h7j0uGTcuJ6IEiBWMh6NuZcr-J_YN-4zvPtQ4k8W1jk&type=album",
                "https://sun1-15.userapi.com/impg/Y7AXyhEPyXP2Z164T8u-aMRDd-slyOM7bjpilw/6sXndHZv5oE.jpg?size=807x548&quality=95&sign=5d24fd94011edff66ffe5630e19ef5ac&c_uniq_tag=2Y4MQ3TuvaOjhHzhFrygJCk_CjU-vmHuUIapwtM0BzQ&type=album",
                "https://sun1-23.userapi.com/impg/gdu0ZHU1tdSO7p0fUg7Uk77DdHbe1PowUQxP_A/PKAkEL1MIKg.jpg?size=720x540&quality=95&sign=a91aead71abc9f4dc789751226b3fbf4&c_uniq_tag=m1KWqNcscz-697_fLxbADON4SteWr08faOusMzZxx6A&type=album",
                "https://sun1-94.userapi.com/impg/xbbTBuW06VK0o8w1h66a29Y8w-482bKktT3ebg/rEwuP2UGF3c.jpg?size=340x604&quality=95&sign=ec428b6686600c898d3882ceba1f8c4b&c_uniq_tag=Y5lqw5vIA4iOZxIf8BFpJAAXDJlagRG_CfQSRR2thXo&type=album",
            ],
            post_date:"4.10.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/wall21414491_83519",
            __typename: "PEVKPost"
        },
        {
            id : 285152, 
            title : "Прелестная трунката",
            post_content : "Трунката похожа на осколки стекла где-то на побережье, когда море их уже разгладило🫠",
            thumbnail : "https://sun1-47.userapi.com/impg/IbzPZ4_AB2OEgUrUbmEkq5VOjrDIZyNn77vqtA/JunzEiqv198.jpg?size=453x604&quality=95&sign=54fae37fe2d66fe2535a457178cf412b&c_uniq_tag=j0E31H0tKPYnWYoFiRK_hHaTnRjEGDfoBql3IXOW6mA&type=album",
            post_author: {
                id : 2,
                display_name : "Раритет - редкие суккуленты",
                avatar : "https://sun1-86.userapi.com/s/v1/if1/llCYjwVK5VbsZ…size=100x100&quality=96&crop=224,64,512,512&ava=1",
                roles: [],
                post_content: "от Георгия Аристова",
            },
            images: [
                "https://sun9-75.userapi.com/impg/x0p1giOcmfNm2pAg0Vdmr1ocEfXde_7ve-4OHQ/mwzmxVQpKiE.jpg?size=1620x2160&quality=95&sign=c96975ba3484f030e1ae840f377e9a74&type=album",
                "https://sun1-25.userapi.com/impg/HqgYKS-y4OCUWdG-1R58141_UUDEA5ClZvFOaw/0fPi9Su-5ik.jpg?size=453x604&quality=95&sign=2064af8ab96bfbb299bc4702a6d1360a&c_uniq_tag=_bb9MX89tFbGQjCHldoW7ZBWmNDvC-jk9tP6qGNKxRE&type=album",
                "https://sun1-47.userapi.com/impg/P2NK7RlaIQ2JVIfq8UKevY1W_5uB1XgTfSkBqg/i5XojxU4_9I.jpg?size=453x604&quality=95&sign=1332e1ce10840ae12c0e68b61c42966b&c_uniq_tag=wyXZXZUyzLXaZYYQwkliA0BUHNczzlrZL3eaiFKDYe0&type=album"
            ],
            post_date:"6.10.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/raritet_plants?w=wall-41122369_285152",
            url: {
                url : "https://www.youtube.com/watch?v=tjGggw3eTqQ",
                title: "Коллекция хавортий / Haworthia collection",
                site_name: "YouTube",
                image : "https://i.ytimg.com/vi/tjGggw3eTqQ/maxresdefault.jpg",
                description: "Коллекция Хавортий - виды и культивары.Моя группа Вконтакте: https://vk.com/raritet_plantsМой сайт: http://raritet-plants.ruМой Инстаграм: http://instagram.c..."
            },
            __typename: "PEVKPost"
        },
        {
            id : 284727, 
            title : "Куда делась точка роста? 🙃",
            post_content : "Видимо, туда же, куда и вся зелень ушла...",
            thumbnail : "https://sun9-11.userapi.com/impg/D4YlBHm9snCq60G42OBqk78j-Qti8auJR0Y1dA/-SghN_53q4s.jpg?size=605x807&quality=95&sign=f3f242c6dc369238a0d2fd1740caec43&c_uniq_tag=Upccp-HnOSztKz1RY3bFo6eeGCSgIFOnKLz0htBrOpw&type=album",
            post_author: {
                id : 2,
                display_name : "Раритет - редкие суккуленты",
                avatar : "https://sun1-86.userapi.com/s/v1/if1/llCYjwVK5VbsZ…size=100x100&quality=96&crop=224,64,512,512&ava=1",
                roles: [],
                post_content: "от Георгия Аристова",
            },
            images: [ ],
            post_date:"6.10.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/raritet_plants?z=photo-41122369_457330215%2Falbum-41122369_00",
            __typename: "PEVKPost"
        },
        {
            id : 284622, 
            title : "Gasteria толстенькие яркие, любимые 😍",
            post_content : "Растюхи тоже коммуницируют в своей сотовой сети.",
            thumbnail : "https://sun9-30.userapi.com/impg/YiFjU8meKWPxMa7zSmwrF4cxsjtQkwQO7C2oSQ/7xQRZ9Ekmu8.jpg?size=453x604&quality=95&sign=61cb8d475c85ce3f21b22fcbc4a934be&c_uniq_tag=98GQMHeZ9hM-rLLxCb5oLXICsJn88lyJEBGhVul4bwI&type=album",
            post_author: {
                id : 2,
                display_name : "Раритет - редкие суккуленты",
                avatar : "https://sun1-86.userapi.com/s/v1/if1/llCYjwVK5VbsZ…size=100x100&quality=96&crop=224,64,512,512&ava=1",
                roles: [],
                post_content: "от Георгия Аристова",
            },
            images: [
                "https://sun1-99.userapi.com/impg/uyFOi8pj5FLc6J94bBEg-Zd_nULsvIZ34cI7_g/fChxPm4EZLs.jpg?size=453x604&quality=95&sign=e3a003262bbe820be2514f5f4ab454c6&c_uniq_tag=_zPWB5Ao2fChiW1YAyV4e2-bpOEYXrxsahGLb0SeE9A&type=album",
                "https://sun9-30.userapi.com/impg/YiFjU8meKWPxMa7zSmwrF4cxsjtQkwQO7C2oSQ/7xQRZ9Ekmu8.jpg?size=453x604&quality=95&sign=61cb8d475c85ce3f21b22fcbc4a934be&c_uniq_tag=98GQMHeZ9hM-rLLxCb5oLXICsJn88lyJEBGhVul4bwI&type=album",
                "https://sun9-79.userapi.com/impg/14Tf25MFqlBxQLGnrFhY_8P9RVV6I6nMaW3L8A/XSWhlMlfdQg.jpg?size=1620x2160&quality=95&sign=d4af9f237cad031e5502d883639c4990&type=album",
                "https://sun9-63.userapi.com/impg/w7IhcJhc0Ca9lVcJrFJ_IUKmWVbqU9pSF-z2nA/qIIYnvoKNmo.jpg?size=1620x2160&quality=95&sign=d5dbf1e07b319b7ba9d8b01a12deae30&type=album",
                "https://sun9-65.userapi.com/impg/rC70rEx-wAtK7WuuogERvSzOzHqTeExdfSRUew/e2N9G0GTGaQ.jpg?size=1620x2160&quality=95&sign=6444aa7011451d524c29bcab151b34b5&type=album",
                "https://sun9-66.userapi.com/impg/4nNwx_X2SDp4NDynIeSCVE6uJA2jDbBlTu-i7A/9CFearvYzbA.jpg?size=1620x2160&quality=95&sign=8fe41ffa9d1db006598bec849ee382a4&type=album",
                "https://sun9-44.userapi.com/impg/UYXDgXQ5eoEdkgmiNy7QuTCJGkQ0FTs0bZPW6w/aAzZ1C8HEbU.jpg?size=1620x2160&quality=95&sign=eb79421c1d09b0ab470d3c54362bd73d&type=album",
                "https://sun9-3.userapi.com/impg/amuniJX7X_v5yFatudAbpe7UGCDr5Fd4m9eLgQ/cZH1GH7xA7k.jpg?size=1620x2160&quality=95&sign=29e1d6b9b93c548fda5b1dc950bc0eaa&type=album",
                "https://sun1-55.userapi.com/impg/cGAl4y1DZnJfcPARacjgWx_VBzVFWXUV8rAYOQ/6kddiQLP3E8.jpg?size=1620x2160&quality=95&sign=103f98f0196b0de1ab26e37d8aab3e62&type=album",
                "https://sun9-56.userapi.com/impg/hAlJk9sIh0nrD0fMp2sN7NKKr085STWRR7o2kA/34yYIJ7ABQI.jpg?size=1620x2160&quality=95&sign=89ed8bc171b9f7e5f65fff2d7b327955&type=album",
             ],
            post_date:"6.10.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/raritet_plants?z=photo-41122369_457330215%2Falbum-41122369_00",
            __typename: "PEVKPost"
        },
        {
            id : "284409_33", 
            title : "Огненная Хавортия Haworthia koelmaniorum x tesselata variegata 🔥",
            post_content : "красивая вариегатность",
            thumbnail : "https://sun9-39.userapi.com/impg/YYPyvInQAU7_1HgGmhc3mUrNxh1NQ5rwmKCZRQ/gT909CPuzpU.jpg?size=453x604&quality=95&sign=3f74571f37c0242460cda3c5a5d7b8e6&c_uniq_tag=AJHlcry2VCdXxp-tYYTzj8zboQ4CPxDjnyqr9rE4dSY&type=album",
            post_author: {
                id : 2,
                display_name : "Раритет - редкие суккуленты",
                avatar : "https://sun9-39.userapi.com/impg/YYPyvInQAU7_1HgGmhc3mUrNxh1NQ5rwmKCZRQ/gT909CPuzpU.jpg?size=453x604&quality=95&sign=3f74571f37c0242460cda3c5a5d7b8e6&c_uniq_tag=AJHlcry2VCdXxp-tYYTzj8zboQ4CPxDjnyqr9rE4dSY&type=album",
                roles: [],
                post_content: "от Георгия Аристова",
            },
            images: [
                "https://sun9-39.userapi.com/impg/YYPyvInQAU7_1HgGmhc3mUrNxh1NQ5rwmKCZRQ/gT909CPuzpU.jpg?size=453x604&quality=95&sign=3f74571f37c0242460cda3c5a5d7b8e6&c_uniq_tag=AJHlcry2VCdXxp-tYYTzj8zboQ4CPxDjnyqr9rE4dSY&type=album",
                "https://sun9-54.userapi.com/impg/b4L0Q2IT6urGRJsIYtrA3Li1gJ1NA9KoLvgWHA/jG_3WPM4rVg.jpg?size=1620x2160&quality=95&sign=669d74372e890c5c9c886768876ead21&type=album",
                "https://sun9-48.userapi.com/impg/S0Vsblz_q05nHMpGrkrd6zDIUfRSdblnzOQWOQ/XuAm-v54mC8.jpg?size=1620x2160&quality=95&sign=28963df39b6d6b71eae00697a72a5a2f&type=album",
                "https://sun9-76.userapi.com/impg/xCD1AUrF2VsrWU3PC2jAOKvhNebkLMrp_NmlvA/Ig61IIqpwxo.jpg?size=794x807&quality=95&sign=d4960f1f2e099b47a0085358a531a8ce&c_uniq_tag=75zH0-7ho0IjoEPFFviMMgx6-u4ccKejIakY7w2PHOc&type=album",
                "https://sun9-14.userapi.com/impg/O3efUEnsLgq8N4yVJSifSAuAMK5u-qTabOz0MQ/XfdGLeSNKmc.jpg?size=796x807&quality=95&sign=2666395b4692a0807dc4b8470ef6a6ef&c_uniq_tag=QuknQuIeUdV7nEsRrL1JB8NyR44DsmsOs_pCZZ5MYBc&type=album",
                "https://sun9-29.userapi.com/impg/-c-4hbWA2N2qajitPcrzPSMx_ova8WfOFHISrQ/ULkTn3PPpm0.jpg?size=736x807&quality=95&sign=b96e25f2787dbf92761a322b1f89e3fd&c_uniq_tag=8GgabV7nucDG4lsxuC8JibkslMTHmn_RxVoL8K6tSb8&type=album",
                "https://sun1-85.userapi.com/impg/RaC9i_uw9WHufiO24s8HnhxAPeZJBsQFLS6jfg/m11iZXJ_6Rk.jpg?size=807x785&quality=95&sign=fff63162fe7c981d4d29de522fb13f5e&c_uniq_tag=doF-BCOcqqCcf0QdFKsP1bd6jD8FJLANVn8TyKeVK9Y&type=album",
            
            ],
            post_date:"6.10.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/raritet_plants?w=wall-41122369_284409",
            __typename: "PEVKPost"
        },
        {
            id : 284409, 
            title : "Хорошая была маугани с белым варом",
            post_content : `Хорошая была маугани с белым варом, однако, со временем полностью побелела и засохла..(
            <p>Это риск любого растения с рандомным типом вариегатности, повлиять на это нельзя, только везение… Или может полностью позеленеть… эх..
            <p>`,
            thumbnail : "https://sun9-12.userapi.com/impg/rOUJTnYXzmOOh4CtXoDLlREv4j9EK3x5-dMwhA/VpRlUShlo4k.jpg?size=604x604&quality=95&sign=090288546396cd44f604b3ab42fd0e11&c_uniq_tag=lPomCLMK4YoMHIrKQlLEUWwIxXs6RvzPAJt6Jo6RJhE&type=album",
            post_author: {
                id : 2,
                display_name : "Раритет - редкие суккуленты",
                avatar : "https://sun9-39.userapi.com/impg/YYPyvInQAU7_1HgGmhc3mUrNxh1NQ5rwmKCZRQ/gT909CPuzpU.jpg?size=453x604&quality=95&sign=3f74571f37c0242460cda3c5a5d7b8e6&c_uniq_tag=AJHlcry2VCdXxp-tYYTzj8zboQ4CPxDjnyqr9rE4dSY&type=album",
                roles: [],
                post_content: "от Георгия Аристова",
            },
            images: [
                "https://sun9-58.userapi.com/impg/SbIbaS5QL3wx51gWpNbXA4PhgZH3c1lWjPVG7w/ftfpCiKsVB8.jpg?size=1440x1440&quality=95&sign=a1c185719af888998cf4a988078385b9&type=album",
                "https://sun9-66.userapi.com/impg/ii2QLtFliTv12bOxlCWO_UGXzcnS7wk3HrHehw/zuTAuTW71B8.jpg?size=1440x1440&quality=95&sign=9ea537749e48756313552f2deca39975&type=album",
                "https://sun9-75.userapi.com/impg/x0p1giOcmfNm2pAg0Vdmr1ocEfXde_7ve-4OHQ/mwzmxVQpKiE.jpg?size=1620x2160&quality=95&sign=c96975ba3484f030e1ae840f377e9a74&type=album",
                "https://sun9-53.userapi.com/impg/nB9Hrvw9u3fjH1iN88yElVS2edHldQpGVRPcHA/IMYtNbVoMIs.jpg?size=453x604&quality=95&sign=ccea0d3101a0e09d8d5597a231de69cb&c_uniq_tag=0jWhcNVcPeb3eSui8XWdUQTS3TnP5PAIn-0sV5VmuYQ&type=album",
            ],
            post_date:"6.10.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/raritet_plants?w=wall-41122369_284409",
            __typename: "PEVKPost"
        },
        {
            id : "2553_1", 
            title : "Хорошая была маугани с белым варом",
            post_content : `Доброго дня
            <p>Потрясающая хойя флавида зацвела
            <p>Обожаю ее золотые звезды!
            <p>Это достаточно курная хойка с большими листьями. На новых ростах лист бордовый, потом зеленеет.
            <p>Запах очень слабый и вечером. Днем не чувствую.`,
            thumbnail : "https://sun1-88.userapi.com/impg/gYQKOYYUGpx-GTr3J1q2zPLpW3Cqy2of3oFgBw/FqXGQC-bKJ4.jpg?size=1216x2160&quality=95&sign=a5bac1a9d2be49284c467c0c07f42039&type=album",
            post_author: {
                id : 12333,
                display_name : "33succulenta комнатные растения г. Владимир",
                avatar : "https://sun1-90.userapi.com/s/v1/if1/6gcSLqqn9GucpUUtaoGHnlxcyljxSlsifxCCeLjATW0myS0MQzbqXWC31L79tjzvxDBndDNF.jpg?size=100x100&quality=96&crop=2,2,2154,2154&ava=1",
                roles: [],
                post_content: "",
            },
            images: [
                "https://sun1-83.userapi.com/impg/vOE92UJuMVsdHK8t87xU3YN7eUUOk2iXGW2U2w/LGZJAiKUOfM.jpg?size=1216x2160&quality=95&sign=ddb8ee762ba304338f186002cb1b5c79&type=album",
                "https://sun9-54.userapi.com/impg/m992dfslN_WkHT8BxU1hS_FDeyZoWEvelSj2yg/upi3d0ud16s.jpg?size=1216x2160&quality=95&sign=49e688f351d2880db9108955a62fd91c&type=album",
                "https://sun1-88.userapi.com/impg/tRMR2BvpN_5jt2r0yHTknTo4u9OIczUqQZks3A/cOg76MZGA_Q.jpg?size=1216x2160&quality=95&sign=7f76a261c60820ebc0dd5113aa99c89e&type=album",
                "https://sun1-18.userapi.com/impg/QYBeji8cX6CHT5bx2ciNsB1xWgo22jHjH_j9dA/y3YiKDNVaRc.jpg?size=1216x2160&quality=95&sign=e1023aac2cdfc1344049bfbb20d49e20&type=album",
                "https://sun1-85.userapi.com/impg/Osw7siBl3nS0UyHM4hyaomRY7bb12ccPrYcmHw/i_CnN51Hbf0.jpg?size=1216x2160&quality=95&sign=5077c8a7ce970f616a39142dd82129e7&type=album",
                "https://sun1-26.userapi.com/impg/MfPj0b1kIJVLjK8ooNHMPI9HIIXYBwBy2EbI0Q/spfdusZUuzs.jpg?size=1216x2160&quality=95&sign=24aa925247cf11966a446640cf202843&type=album",
                "https://sun1-47.userapi.com/impg/Uc9hNdukPGHeNt7mEm8jr16FM4YdHWfYeVZFow/DNY0F3uSWTk.jpg?size=1216x2160&quality=95&sign=d14d04e4c7f00f59a66710d0b6ddef50&type=album",
            ],  
            post_date:"12.09.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/feed?w=wall-185877751_2553",
            __typename: "PEVKPost"
        }, {
            id : 284281, 
            title : "Хорошая была маугани с белым варом",
            post_content : `Хорошая была маугани с белым варом, однако, со временем полностью побелела и засохла..(
            <p>Это риск любого растения с рандомным типом вариегатности, повлиять на это нельзя, только везение… Или может полностью позеленеть… эх..
            <p>`,
            thumbnail : "https://sun9-12.userapi.com/impg/rOUJTnYXzmOOh4CtXoDLlREv4j9EK3x5-dMwhA/VpRlUShlo4k.jpg?size=604x604&quality=95&sign=090288546396cd44f604b3ab42fd0e11&c_uniq_tag=lPomCLMK4YoMHIrKQlLEUWwIxXs6RvzPAJt6Jo6RJhE&type=album",
            post_author: {
                id : 2,
                display_name : "Раритет - редкие суккуленты",
                avatar : "https://sun9-39.userapi.com/impg/YYPyvInQAU7_1HgGmhc3mUrNxh1NQ5rwmKCZRQ/gT909CPuzpU.jpg?size=453x604&quality=95&sign=3f74571f37c0242460cda3c5a5d7b8e6&c_uniq_tag=AJHlcry2VCdXxp-tYYTzj8zboQ4CPxDjnyqr9rE4dSY&type=album",
                roles: [],
                post_content: "от Георгия Аристова",
            },
            images: [
                "https://sun9-58.userapi.com/impg/SbIbaS5QL3wx51gWpNbXA4PhgZH3c1lWjPVG7w/ftfpCiKsVB8.jpg?size=1440x1440&quality=95&sign=a1c185719af888998cf4a988078385b9&type=album",
                "https://sun9-66.userapi.com/impg/ii2QLtFliTv12bOxlCWO_UGXzcnS7wk3HrHehw/zuTAuTW71B8.jpg?size=1440x1440&quality=95&sign=9ea537749e48756313552f2deca39975&type=album",
                "https://sun9-75.userapi.com/impg/x0p1giOcmfNm2pAg0Vdmr1ocEfXde_7ve-4OHQ/mwzmxVQpKiE.jpg?size=1620x2160&quality=95&sign=c96975ba3484f030e1ae840f377e9a74&type=album",
                "https://sun9-53.userapi.com/impg/nB9Hrvw9u3fjH1iN88yElVS2edHldQpGVRPcHA/IMYtNbVoMIs.jpg?size=453x604&quality=95&sign=ccea0d3101a0e09d8d5597a231de69cb&c_uniq_tag=0jWhcNVcPeb3eSui8XWdUQTS3TnP5PAIn-0sV5VmuYQ&type=album",
            ],
            post_date:"6.10.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/raritet_plants?w=wall-41122369_284409",
            __typename: "PEVKPost"
        },
        {
            id : 2553, 
            title : "Хорошая была маугани с белым варом",
            post_content : `S. hybrid Anjani blue ice (Indonesia )`,
            thumbnail : "https://sun1-86.userapi.com/impg/7sw541qKjBhD-9T2JgNisufyj0xxm5GxsNagBQ/eg32hQ_qEbY.jpg?size=720x960&quality=95&sign=cb327e701dcf612f9add9830df18ad9f&c_uniq_tag=HFdlBtgJV-WfqZc4xSKSpwSiq9tTdlcE2xZQwYVVCaU&type=album",
            post_author: {
                id : 12333,
                display_name : "Сансевиерия - Sansevieria",
                avatar : "https://sun1-90.userapi.com/s/v1/if1/6gcSLqqn9GucpUUtaoGHnlxcyljxSlsifxCCeLjATW0myS0MQzbqXWC31L79tjzvxDBndDNF.jpg?size=100x100&quality=96&crop=2,2,2154,2154&ava=1",
                roles: [],
                post_content: "",
            },
            images: [ ],  
            post_date:"12.09.2023 20:47",
            comments: [],
            likes:[],
            link: "https://vk.com/public21414491?w=wall-21414491_83808",
            __typename: "PEVKPost"
        },
    ]
    return _list.map(vkpost => {
        const _likes = likes().filter(() => Math.random() > .7)
        return { ...vkpost, likes: _likes }
    })
}