import React, { useEffect, useState } from 'react' 
import { Link, Params, useParams } from 'react-router-dom'
import { __ } from "@/libs/utilities"
import { LoaderLine } from "@/libs/useful"
import actions from '../../data/actions'
import { VERIFY_USER_ACTION } from '../../data/actionTypes'
import { FINISH_RESTORE_STATUSES } from './FinishRestorePasswordForm'
import { Card } from '@blueprintjs/core'

const VerifyUserForm = (props: any): React.ReactElement => {
    const [isVerified, setIsVerified] = useState(FINISH_RESTORE_STATUSES.START)
    const params: Readonly<Params<string>> = useParams<string>()
    useEffect(() => {
        actions(VERIFY_USER_ACTION, params)
            .then( resp => setIsVerified( resp ) )
    }, [] )

    const start = () : React.ReactElement => {
        return <div className="row text-center justify-content-center"> 
            <Card elevation={3} interactive className="p-5 lead">
                {__("Finish restore password")}
                <LoaderLine />
            </Card>
        </div> 
    }

    const success = () => <div className="row text-center">
        <Card elevation={3} interactive className="p-5 lead"> 
            <div className="col-12 lead">
                {__("Your email address has been successfully verified. Please login with your email and password.")}
            </div>
            <div className="col-12 mt-4">
                <Link
                    className="btn btn-danger btn-sm"
                    to="/"
                >
                {__("Return to main page")}
                </Link>
            </div>
        </Card>
    </div>

    const notSuccess = () => <div className="row text-center">
        <Card elevation={3} interactive className="p-5 lead"> 
            <div className="col-12 lead">
                {__("The email address has not been verified. Check the link in the email again.")}
            </div>
            <div className="col-12 mt-4">
                <Link
                    className="btn btn-danger btn-sm"
                    to="/"
                >
                    {__("Return to main page")}
                </Link>
            </div>
        </Card>
    </div>

    switch(isVerified)
    {
        case FINISH_RESTORE_STATUSES.SUCCESS:
            return success()
        case FINISH_RESTORE_STATUSES.NOT_SUCCESS:
            return notSuccess()
        default:
        case FINISH_RESTORE_STATUSES.START:
            return start()
    } 
} 

export default VerifyUserForm