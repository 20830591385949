import React from "react" 
import BasicState from "src/libs/basic-view"
import NewsForm from "./news/NewsForm" 
import "../assets/css/style.scss"

export default class NewsView extends BasicState {
    props: any
    addRender = () => {
        return <NewsForm {...this.props} />  
    }
}