import { IEventType } from "../interfaces"

export const eventTypes = () : IEventType[] => {    
    const lorigin = `${window.location.origin}/`
    return [
        {
            id: 1,
            title: "Festival",
            post_content: "",
            color: "#813381",
            icon: lorigin + "assets/img/topos/stage-light-svgrepo-com.svg"
        },
        {
            id: 10001,
            title: "Maraphon",
            post_content: "",
            color: "#5a689f",
            icon: lorigin + "assets/img/topos/stage-light-svgrepo-com.svg"
        },
        {
            id: 2,
            title: "Concert",
            post_content: "",
            color: "#9966cc",
            icon: lorigin + "assets/img/topos/megaphone-sound-marketing-svgrepo-com.svg"
        },
        {
            id: 3,
            title: "Tea party",
            post_content: "",
            color: "#CC3300",
            icon: lorigin + "assets/img/topos/croissant-svgrepo-com.svg"
        },
        {
            id: 4,
            title: "Excursion",
            post_content: "",
            color: "#666333",
            icon: lorigin + "assets/img/topos/broker-svgrepo-com.svg"
        },
        {
            id: 40001,
            title: "Expedition",
            post_content: "",
            color: "#666333",
            icon: lorigin + "assets/img/topos/compass-tool-geometry-svgrepo-com.svg"
        },
        {
            id: 40002,
            title: "Competition",
            post_content: "",
            color: "#666333",
            icon: lorigin + "assets/img/topos/trophy-champion-winner-svgrepo-com.svg"
        },
        {
            id: 40003,
            title: "Conference",
            post_content: "",
            color: "#666333",
            icon: lorigin + "assets/img/topos/presentation-chart-fill-svgrepo-com.svg"
        },
        {
            id: 5,
            title: "Workshop",
            post_content: "",
            color: "#663300",
            icon: lorigin + "assets/img/topos/apron-svgrepo-com.svg"
        },
        {
            id: 6,
            title: "Interactive program",
            post_content: "",
            color: "#9933cc",
            icon: lorigin + "assets/img/topos/wing-chair-svgrepo-com.svg"
        },
        {
            id: 7,
            title: "Online",
            post_content: "",
            color: "#6600FF",
            icon: lorigin + "assets/img/topos/scanner-profile-svgrepo-com.svg"
        },
        {
            id: 8,
            title: "Quest",
            post_content: "",
            color: "#5588FF",
            icon: lorigin + "assets/img/topos/magic-wand-svgrepo-com.svg"
        },
        {
            id: 10,
            title: "Role play game",
            post_content: "",
            color: "#332d70",
            icon: lorigin + "assets/img/topos/rocket-launch-launch-marketing-svgrepo-com.svg"
        },
        {
            id: 9,
            title: "Research lab",
            post_content: "",
            color: "#18153b",
            icon: lorigin + "assets/img/topos/microscope-svgrepo-com.svg"
        },
        {
            id: 11,
            title: "Labyrinth",
            post_content: "",
            color: "#3a5153",
            icon: lorigin + "assets/img/topos/rocket-launch-launch-marketing-svgrepo-com.svg"
        },
    ]
}