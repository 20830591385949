import { IFestival, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { IUser } from "@/settings/zustand"
import { DIALOG_SIZE, ID, URL } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { ScalableDialog } from "@/libs/useful"
import UserSearchEngine from "@/libs/useful/UserSearchEngine"
import { __, wordTrim } from "@/libs/utilities"
import { Button, ButtonGroup, EditableText, Icon, Intent, Popover, Position } from "@blueprintjs/core"
import { useEffect, useRef, useState } from "react"

/* Protopia Ecosystem component */
export interface ITrackEditCardProps {
    item: ITrack
    festival: IFestival
    onDelete: () => void
    onChange: ( field: keyof ITrack, value: any ) => void
}
const TrackEditCard = (props: ITrackEditCardProps): JSX.Element => {
    const [evailabled, setEvailabled] = useState<boolean>( !!props.item.moderator?.id && !!props.item.moderator2?.id )
    const [isContentEdit, setIsContentEdit] = useState<boolean>( false )
    useEffect(() => {
        setEvailabled( !!props.item.moderator?.id && !!props.item.moderator2?.id )
    }, [props.item.moderator, props.item.moderator2])
    const onTrackExpert = (user: IUser) => {
        props.onChange("moderator", user)
    } 
    const onTrackExpert2 = (user: IUser) => {
        props.onChange("moderator2", user)
    } 
    const onEditContent = (bool: boolean) => {
        setIsContentEdit(bool)
    }
    const onChangeTite = (title: string) => {
        props.onChange("title", title)
    }
    const onThumbnail = (thumbnail: string, data: any = {}) => {
        props.onChange( "thumbnail", thumbnail )
    }
    const onIllustration = (illustration: string, data: any = {}) => {
        props.onChange( "illustration", illustration )
    }
    const onChangeContent = (content: string) => {
        props.onChange("content", content)
    }
    return <div className={`pe-track-edit-card-container ${ evailabled ? "" : " no-evailabled"}`}>
        <div className="thumb position-relative" >
            <div className="position-absolute m-2 rounded-circle bg-light p-3" >
                <TrackThumbnail 
                    thumbnail={props.item?.thumbnail || "" } 
                    onChange={onThumbnail}
                />
            </div>
            <div className="position-absolute bottom right small bg-dark text-light m-2 py-1 px-2">
                {
                    props.item.order
                }
            </div>
            <TrackIllustaration 
                illustration={props.item.illustration}
                onChange={onIllustration}
            /> 
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 track-edit-title"
                    alwaysRenderInput={true} 
                    disabled={false}
                    placeholder="Edit title..."
                    selectAllOnFocus={true} 
                    value={props.item.title}
                    onChange={onChangeTite}
                /> 
            </div>
            <TrackContent
                content={props.item.content}
                isContentEdit={isContentEdit}
                onChangeContent={onChangeContent}
                onEditContent={onEditContent}
            />            
            <div className="footer">
                <TrackExpert 
                    user={props.item.moderator}  
                    onChange={onTrackExpert}
                    title={ __("Track expert") }
                    festID={props.festival.id || "-1"}
                />
                <TrackExpert 
                    user={props.item.moderator2} 
                    onChange={onTrackExpert2}
                    title={ __("Track moderator assistant") }
                    festID={props.festival.id || "-1"}
                /> 
                <Button icon="trash" minimal onClick={props.onDelete}>{__("Delete")}</Button>
            </div>
        </div>        
    </div>
}
export default TrackEditCard

interface ITrackExpertProps {
    user: IUser | null
    festID : ID
    onChange : (user: IUser) => void
    title: string
}
const TrackExpert = (props: ITrackExpertProps): JSX.Element => {
    return <Popover
        position={Position.TOP}
        content={<div className="p-3">
            <UserSearchEngine 
                user={props.user}
                onSelect={props.onChange} 
                minWidth={340}
                landId={props.festID}
            />
        </div>}
    >  
        <Button minimal > 
        {
            props.user?.id 
                ? 
                <div className="d-flex align-items-center">
                    <img src={props.user.avatar} height={27} alt="ava" className="user-ava"/>
                    <div className="ml-0" >
                        {props.user.display_name}
                    </div>
                </div>  
                : 
                <div className="d-flex align-items-center">
                    <Icon icon="person" className="mr-2" size={20}/>
                    { props.title }
                </div>
        }
        </Button>
    </Popover>
}

 
export interface ITrackContentProps {
    isContentEdit: boolean
    content: string
    onChangeContent: (content: string) => void
    onEditContent : (bool:boolean) => void
}
const TrackContent = ({
    content, 
    isContentEdit,
    onChangeContent, 
    onEditContent,
    ...props
}: ITrackContentProps): JSX.Element => {
    const myRef = useRef( null )

    const onMouseLeaveHandler = (e: Event) => {
        const domNode: any = myRef.current;
        if (!domNode || !domNode.contains(e.target)) {
            //height = 0 
            onEditContent( false )
        } 
    }
    useEffect(() => {
        document.body.addEventListener("click", onMouseLeaveHandler)
        return () => document.body.removeEventListener("click", onMouseLeaveHandler)
    }, [])

    return <div 
        className="content position-relative " 
        onClick={ () =>  onEditContent( true ) } 
        ref={ myRef }
    >
        <EditableText
            className="track-content-edit w-100 "
            alwaysRenderInput={true} 
            //disabled={ !isContentEdit }
            multiline
            placeholder="Edit content..." 
            value={ isContentEdit ? content : wordTrim( content, 50 ) }
            minLines={5}
            maxLines={5}
            onChange={onChangeContent} 
        /> 
    </div> 
} 


/* Protopia Ecosystem component */
export interface ITrackThumbnailProps {
    thumbnail: URL
    onChange: (value: any, name: string) => void
}
const TrackThumbnail = ({...props}: ITrackThumbnailProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const [thumbnail, setThumbnail] = useState( props.thumbnail )
    const [thumbnailName, setThumbnailName] = useState( "" )
    const onChange = (value: string, field: string , data: any) => {
        console.log( data )
        setThumbnail (value )
        setThumbnailName( data.name )
    }
    const onUpdate = () => {
        setIsOpen(false)
        props.onChange(thumbnail, thumbnailName)
    }
    return <>
        <img 
            src={props.thumbnail} 
            alt="" 
            style={{ width: 35, cursor: "pointer" }} 
            onClick={() => setIsOpen(true) }
        />
        <ScalableDialog
            isOpen={isOpen}
            dialogSize={DIALOG_SIZE.MINIMAL}
            onClose={() => setIsOpen(false)}
        >
            <div className="w-100 p-4">
                <FieldInput
                    type={SCALAR_TYPES.MEDIA}
                    isURLHide
                    isFileNameHide
                    editable
                    value={thumbnail}
                    onChange={onChange}
                />
            </div>
            <ButtonGroup fill>
                <Button intent={Intent.DANGER} large onClick={ () => setIsOpen( false ) } >
                    {__( "Close" )}
                </Button>
                <Button intent={Intent.SUCCESS} large onClick={ onUpdate } >
                    {__( "Update" )}
                </Button>
            </ButtonGroup>
        </ScalableDialog>
    </>
}

/* Protopia Ecosystem component */
export interface ITrackIllustarationProps {
    illustration:string
    onChange: (illustration: string, illustrationName: string) => void
}
const TrackIllustaration = (props: ITrackIllustarationProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const [thumbnail, setThumbnail] = useState( props.illustration )
    const [thumbnailName, setThumbnailName] = useState( "" )
    const onChange = (value: string, field: string , data: any) => {
        console.log( data )
        setThumbnail (value )
        setThumbnailName( data.name )
    }
    const onUpdate = () => {
        setIsOpen(false)
        props.onChange(thumbnail, thumbnailName)
    }
    return <>
        <div 
            className="w-100 h-100 thumb"
            style={{ backgroundImage:`url(${props.illustration})`, cursor: "pointer" }}
            onClick={() => setIsOpen(true) }
        />
        <ScalableDialog
            isOpen={isOpen}
            dialogSize={DIALOG_SIZE.MINIMAL}
            onClose={() => setIsOpen(false)}
        >
            <div className="w-100 p-4">
                <FieldInput
                    type={SCALAR_TYPES.MEDIA}
                    isURLHide
                    isFileNameHide
                    editable
                    height={320}
                    width={320}
                    value={thumbnail}
                    onChange={onChange}
                />
            </div>
            <ButtonGroup fill>
                <Button intent={Intent.DANGER} large onClick={ () => setIsOpen( false ) } >
                    {__( "Close" )}
                </Button>
                <Button intent={Intent.SUCCESS} large onClick={ onUpdate } >
                    {__( "Update" )}
                </Button>
            </ButtonGroup>
        </ScalableDialog>
    </>
} 