
/* Protopia Ecosystem component */
export interface IAdvenchuresFormProps {

}
const AdvenchuresForm = (props: IAdvenchuresFormProps): JSX.Element => {
    return <div className='pe-advenchuresform-container'>
        <div className="p-md-4 display-7">Приключения</div>
    </div>
}
export default AdvenchuresForm