import Feed from "src/modules/pe-basic-module/views/core/Feed"

const Tasks = () : JSX.Element => {
    return <div className="p-5 short-container">
        <Feed
            isLoader
            data_type="PEOpenProblem"
            offset={0}
        />
    </div> 
}
 

export default Tasks