import { Button, Dialog, Icon, Tag } from '@blueprintjs/core'
import UserAvatar from './UserAvatar'
import { useState } from 'react'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import { Role } from '@/libs/interfaces/layouts'
import { LayoutIcon } from '@/libs/useful'
import { __, isCapability } from '@/libs/utilities'
import { profile, } from "@/libs/layouts"
import { NavLink } from 'react-router-dom'

const UserMobile = (): JSX.Element => {
    const user:IUser = useMainStore((state:IState) => state.user)
    const setUser = useMainStore(( state: IState ) => state.setUser)
    const [isOpen, setIsOpen] = useState(false)
    
    const profile_routing: any[] = profile()
    let profile_menu : any 
    if (profile_routing.length > 0) 
    {
        profile_menu = profile_routing.map((e, i) => {
            const isRole = isCapability(e.capability, user)
            if (isRole) return ""
            let children: any
            if(Array.isArray(e.children) && e.children.length > 0)
            {
                children = <>
                    <div className="lmenu-children" >

                    </div>
                </>
            }
            else
            {

            }
            return <div className="lmenu" key={i}>
                {children}
                <NavLink
                    className="" 
                    to={`/${e.route}`} 
                    onClick={ () => setIsOpen(false) }
                >
                    <div className="d-flex">
                        <LayoutIcon
                            isSVG
                            src={e.icon}
                            className="personal-menu__icon mr-3"
                        />
                        <span className=''>{ __(e.title) }</span>
                    </div>
                </NavLink>
            </div>
        })
    } 
    else 
    {
        profile_menu = <li className="lmenu">
            <NavLink
                className="" 
                to="/profile"
                onClick={ () => setIsOpen(false) }
            >
                <div className="d-flex">
                    <LayoutIcon
                        isSVG
                        src="/assets/img/user.svg"
                        className="personal-menu__icon mr-3"
                    />
                    { __( "edit profile" ) }
                </div>
            </NavLink>
        </li>
    } 
    const logout = () => {
        setUser({})
		localStorage.removeItem("token")
        window.location.reload()
    }

    return <>
        <UserAvatar noPadding onClick={() => setIsOpen( !isOpen )} />
        <Dialog
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <Button
                minimal
                onClick={() => setIsOpen(false)}
                className="position-absolute top dialog-close-btn" 
            >
                <Icon size={22} color="#ffffff" icon="cross"/>    
            </Button>
            <div className="lead">
                <div className="display-7 mb-4">
                    {user.display_name}
                </div>
                <div className="px-4">
                    {profile_menu}
                    <div className="lmenu ">
                        <div className='pl-4'>
                        {
                            user.roles.map((role: Role) => <Tag className='py-1 px-3 m-1 ' key={role}>{ __( role ) }</Tag>)
                        }
                        </div>
                    </div>
                    <div onClick={ logout } className="lmenu exit">
                        <div className="d-flex">
                            <LayoutIcon
                                isSVG
                                src="fas fa-sign-out-alt"
                                className="personal-menu__icon mr-3"
                            />
                            {__("logout")}
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </> 
}

export default UserMobile