 
import { __ } from 'src/libs/utilities'
import { BREACK_STORY_ACTION, CHANGE_HERO_ACTION, GET_FABULA_ACTIONS, GET_STORY_ACTIONS, MOVE_HERO_ACTION, STAFF_EXECUTE_ACTION, STORY_TICK_ACTION, UPDATE_EPISODE_ACTION, UPDATE_MEDIA_ACTION, UPDATE_STORY_ACTION } from './actionTypes'
import asyncControllers from './controllers' 
import { FABULA_STAFF_ACTION, IFabulaHero, IFabulaMedia, IPEStory, TAKT } from './interfaces'
import { useStorytellingStore } from './store'


export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    let hero : IFabulaHero = useStorytellingStore.getState().hero
    let story : IPEStory = useStorytellingStore.getState().current
    switch (action) {
        case GET_FABULA_ACTIONS:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {  
                        useStorytellingStore.setState({ 
                            fabula: response.getPEFabula 
                        })
                        resolve( response.getPEFabula )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise 
        case CHANGE_HERO_ACTION:
            promise = new Promise((resolve, reject) => {  
                useStorytellingStore.setState({ hero: data.hero })
                //console.log( data.hero )
                resolve( data.hero )
            })
            return promise 
        case STAFF_EXECUTE_ACTION:
            promise = new Promise((resolve, reject) => {  
                switch(data.action) {
                    case FABULA_STAFF_ACTION.CHARGE:
                        hero.currentFullness = hero.fullness
                        useStorytellingStore.setState({ hero })
                        resolve({
                            message: __("The device is fully charged")
                        })
                        return 
                    case FABULA_STAFF_ACTION.TAKE:
                        resolve({
                            message: "Staff taken"
                        })
                        return
                    default:
                        resolve({
                            message: "The staff executed"
                        })
                        return
                } 
            })
            return promise 
        case MOVE_HERO_ACTION:
            promise = new Promise((resolve, reject) => { 
                hero = { ...useStorytellingStore.getState().hero, tileID: data.tileID }
                useStorytellingStore.setState({ hero })
                //console.log( data.tileID, hero )
                resolve( hero )
            })
            return promise 
        case UPDATE_STORY_ACTION:
            promise = new Promise((resolve, reject) => { 
                story = { ...story, [ data.field ]: data[ data.field ] }
                useStorytellingStore.setState({ current: story }) 
                resolve( story )
            })
            return promise 
        case GET_STORY_ACTIONS:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {  
                        useStorytellingStore.setState({ 
                            current:  response.getPEStory,
                        })
                        resolve( data.id )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise 
        case STORY_TICK_ACTION:
            promise = new Promise((resolve, reject) => { 
                const timer = useStorytellingStore.getState().timer + TAKT
                useStorytellingStore.setState({ timer })
                useStorytellingStore.getState().hunger()
                // console.log( useStorytellingStore.getState().hero.currentFullness )
                resolve( timer )
            })
            return promise 
        case BREACK_STORY_ACTION:
            promise = new Promise((resolve, reject) => { 
                const timer = 0, isPaused = true
                useStorytellingStore.setState({ timer, isPaused })
                resolve( timer )
            })
            return promise 
        case UPDATE_MEDIA_ACTION:
            promise = new Promise((resolve, reject) => { 
                const updateMedia = (media: IFabulaMedia, byMedia: IFabulaMedia) : IFabulaMedia => {
                    if( media.id === byMedia.id) {
                        return byMedia
                    }
                    if( Array.isArray(media.media) ) {
                        media.media = media.media.map(m => {
                            return updateMedia(m, byMedia)
                        })
                    }
                    return media
                }
                //console.log( data.media )
                let story: IPEStory = useStorytellingStore.getState().current
                let current: IPEStory = {
                    ...story,
                    map:{
                        ...story.map,
                        tiles: [
                            ...story.map.tiles.map(tile => {
                                return {
                                    ...tile,
                                    episodes: [
                                        ...tile.episodes.map(episode => 
                                        {
                                            return {
                                                ...episode,
                                                media: [
                                                    ...episode.media.map( media => updateMedia(media, data.media) )
                                                ]
                                            }
                                        })
                                    ]
                                }
                            })
                            
                        ]
                    }
                }
                useStorytellingStore.setState({ current })
            })
            return promise 
        default:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
    }

} 

export const syncAction = (action: string, data: any): any => {
    switch(action) { 
        default:
            return action
    }
    
}