import React, { useId } from 'react' 
import { Icon, Intent } from '@blueprintjs/core'
import { __ } from "src/libs/utilities"
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const Array = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<ArrayEnabled {...props} />}
        desabledForm={<ArrayDesabled {...props} />}
    />
} 
export default Array 

export const ArrayEnabled = ( props: IScalarForm ): JSX.Element => {
    const id = useId()
    const onChange = (evt: any) => {
          
    }

    return <div className=" d-flex my-4">
       ARRAY  
    </div>
} 
 
const ArrayDesabled = ( props: IScalarForm ): JSX.Element => {
    return <div className=" d-flex my-4">
    ARRAY  
 </div>
} 
 