import { CREATE_SITE_ACTION, GET_FEED_ACTION, GET_SERVICE_OPTIONS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import {actions as festAction} from "../../../data/actions"
import { IFestForm, IFestival, ILand, IProject, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { isRole } from "@/settings/zustand/user"
import { DIALOG_SIZE, ID, Role } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, translitterate } from "@/libs/utilities"
import { component_url } from "@/libs/utilities/links" 
import { Button, ButtonGroup, Card, Collapse, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router" 
import { GET_FESTIVAL_SETTINGS_ACTION } from "@/modules/pe-fest-module/data/actionTypes"
import SettingContent from "./SettingContent"
import TracksContent from "./TracksContent"
import { IUser } from "src/settings/zustand"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import ProjectsContent from "./ProjectsContent"
import UsersContent from "./UsersContent"

/* Protopia Ecosystem component */
export interface IAddFestivalContentProps {
    festivalSite?: ILand
}
const AddFestivalContent = (props: IAddFestivalContentProps): JSX.Element => {
    const navigate = useNavigate()
    const [ roles, setRoles ] = useState<Role[]>([])
    const [ festival, setFestival] = useState<IFestival>({} as IFestival)  
    const [ isOpen, setIsOpen ] = useState<string>("")
    const [ item, setItem ] = useState<IFestForm>({ title:"", domain:"" } as IFestForm) 
    const [ tracks, setTracks ] = useState<ITrack[]>( [] )
    const [ projects, setProjects ] = useState<IProject[]>( [] )
    const [ users, setUsers ] = useState<IUser[]>( [] )

    const cangeFestival = ( ste_id: ID) => {
        festAction(
            GET_FESTIVAL_SETTINGS_ACTION, 
            { id: ste_id }
        )
        .then((resp: IFestival) => { 
            setFestival(resp)
        })
    }
    useEffect(() => {
        actions(
            GET_FEED_ACTION, 
            { 
                data_type:"PEFestTrack", 
                land_id: festival.id 
            }
        )
        actions(
            GET_FEED_ACTION, 
            { 
                data_type:"PEFestProject", 
                land_id: festival.id 
            }
        )
        actions(
            GET_FEED_ACTION, 
            { 
                data_type:"User", 
                land_id: festival.id 
            }
        )
    }, [festival])
    useEffect(() => useDataStore.subscribe(
        ( state: any ) => {
            setTracks( state.PEFestTrack )
            setProjects( state.PEFestProject )
            setUsers( state.User )
        }
    ), [])
    
    useEffect(() => { 
        actions(GET_SERVICE_OPTIONS_ACTION, {})
            .then((resp: any) => { 
                setRoles(resp.roles)
            })
    }, [])

    if(!isRole(roles)) return <></>
   
    const onField = ( value: any, field: keyof IFestForm ) => {
        let d: any = {}
        if( field === "title" ) {
            const defDomain: string = translitterate()( item.title, "" ).toLowerCase() 
            if( defDomain === item.domain ) {
                d = { domain: translitterate()( value, "-" ).toLowerCase() }
            }
        }
        if(field === "finishDate" || field === "startDate") {
            value = Math.floor(value / 1000)
        }
        if(field === "pattern") {
            cangeFestival( value?.id )
        }
        setItem({ ...item, [field]: value, ...d })
    }
    const onStartCreate = () => {
        actions(
            CREATE_SITE_ACTION, 
            { 
                item: {
                    ...item, 
                    startDate: item.startDate + "", 
                    finishDate: item.finishDate + ""
                } 
            } 
        )
        .then( () => navigate(component_url("FestivalAdminListView")) )
    }

    const onLandscapeChange = ( value: any, file: any ) => {
        console.log( value, file )
    }
    const onOpen = (field: "projects" | "date"| "setting" | "tracks" | "users" | "description" | "") => {
        setIsOpen( isOpen === field ? "" : field )
    }
    const onSetTracks = (tracks: ITrack[]) => {
        setTracks(tracks)
    }
    const onSetProjects = (_projects: IProject[]) => {
        setProjects(_projects)
    }
    const onSetUsers = (_users: IUser[]) => {
        setUsers(_users)
    }
    const onRestoreTracks = () => {
        setTracks( useDataStore.getState().PEFestTrack )
    }
    const onRestoreProjects = () => {
        setProjects( useDataStore.getState().PEFestProject )
    }

    return <div className='pe-add-festival-content-container short-container'>
        <Card className="row w-100 p-0">
            <FieldInput
                value={item.title}
                vertical={VerticalType.HORIZONTAL}
                editable
                type={SCALAR_TYPES.STRING}
                title={__("Title")}
                onChange={ value => onField( value, "title")}
            />
            <div className="d-none">
                <FieldInput
                    value={item.domain}
                    vertical={VerticalType.HORIZONTAL}
                    editable
                    type={SCALAR_TYPES.STRING}
                    title={__("New Site | web address")}
                    commentary={__("Only lowercase letters (a-z) and numbers are allowed.")}
                    onChange={ value => onField( value, "domain")}
                /> 
            </div>
            <FieldInput
                value={item.pattern}
                vertical={VerticalType.HORIZONTAL}
                editable
                component="Land"
                paging={` metas: {key: "isPattern", value: "1" } `}
                type={SCALAR_TYPES.EXTERNAL}
                title={__("Pattern")}
                onChange={ value => onField( value, "pattern")}
            />
            <Collapse isOpen={!!festival.domain_content} className="w-100">
                <div className="pb-4 px-3 text-center w-100 descr text-force-contrast ">
                    {
                       festival.domain_content
                    }
                </div>
            </Collapse>
        </Card>
        <Collapse isOpen={ !!item.title && !!item.domain && !!item.pattern }>
            <Card className="row w-100 p-0">
                <ButtonGroup className="d-flex w-100">
                    <Button className={`px-4 `} minimal={isOpen !== "date"} onClick={() => onOpen( 'date' )}>
                        { __( "Dates" ) } 
                    </Button> 
                    <Button className={`px-4 `} minimal={isOpen !== "setting"} onClick={() => onOpen( 'setting' )}> 
                        { __( "Settings" ) } 
                    </Button> 
                    <Button className={`px-4 `} minimal={isOpen !== "tracks"} onClick={() => onOpen( 'tracks' )}> 
                        { __( "Tracks" ) } 
                    </Button>
                    <Button className={`px-4 `} minimal={isOpen !== "users"} onClick={() => onOpen( 'users' )}> 
                        { __( "Users" ) } 
                    </Button>
                    <Button className={`px-4 `} minimal={isOpen !== "projects"} onClick={() => onOpen( 'projects' )}> 
                        { __( "Projects" ) } 
                    </Button>
                    <Button className={`px-4 d-none`} minimal={isOpen !== "description"} onClick={() => onOpen( 'description' )}> 
                        { __( "Description" ) } 
                    </Button>
                    <Button className="px-4 ml-auto" minimal onClick={() => onOpen( '' )} icon="caret-up"> 
                        
                    </Button>
                </ButtonGroup> 
                <Collapse isOpen={isOpen === "date"}    className="w-100 overflow-y-auto">
                    <div className="row w-100">
                        <div className="col-md-6">
                            <FieldInput
                                value={item.startDate}
                                title={__("Start date")}
                                vertical={VerticalType.VERTICAL}
                                editable
                                type={SCALAR_TYPES.DATE}  
                                onChange={ value => onField( value, "startDate")}
                            />
                        </div>
                        <div className="col-md-6">
                            <FieldInput
                                value={item.finishDate}
                                title={__("Finish date")}
                                vertical={VerticalType.VERTICAL}
                                editable
                                type={SCALAR_TYPES.DATE}  
                                onChange={ value => onField( value, "finishDate")}
                            />
                        </div>
                    </div>
                </Collapse>
                <Collapse isOpen={isOpen === "setting"} className="w-100 overflow-y-auto">
                    <SettingContent
                        festival={festival} 
                        onChange={onLandscapeChange} 
                    />
                </Collapse>
                <Collapse isOpen={isOpen === "tracks"}  className="w-100 overflow-y-auto">
                    <TracksContent 
                        festival={festival} 
                        tracks={tracks} 
                        setTracks={onSetTracks} 
                        onRestore={onRestoreTracks}
                    />
                </Collapse>
                <Collapse isOpen={isOpen === "projects"}  className="w-100 overflow-y-auto">
                    <ProjectsContent
                        festival={festival} 
                        projects={projects} 
                        setProjects={onSetProjects} 
                        onRestore={onRestoreProjects}
                    />
                </Collapse>
                <Collapse isOpen={isOpen === "users"}  className="w-100 overflow-y-auto">
                    <UsersContent
                        festival={festival} 
                        users={users} 
                        setUsers={onSetUsers} 
                    />
                </Collapse>
            </Card>
        </Collapse>
        <div className="flex-centered w-100 m-4">
            <ConfirmButton
                buttonIntent={ !item.title && !item.domain ? Intent.NONE : Intent.DANGER}
                dialogClasssName={ DIALOG_SIZE.MINIMAL }
                buttonDisabled={ !item.title || !item.domain || !item.pattern }
                buttonMinimal={  !( item.title &&  item.domain && item.pattern ) }
                buttonLabel={ __( "Create new Festival" ) }
                buttonLarge
                onConfirm={onStartCreate}
            >
                <div className="p-5  flex-centered flex-column">
                    <div className="py-2 w-100 d-flex">
                        <div className="w-100 pr-3 text-right">
                            {__("Title")}:
                        </div>
                        <div className="title w-100">
                            { item.title }
                        </div>
                    </div>
                    <div className="py-2 w-100 d-flex">
                        <div className="w-100 pr-3 text-right">
                            {__("Pattern")}:
                        </div>
                        <div className="title w-100">
                            { item.pattern?.name || __("Not choosed") }
                        </div>
                    </div>
                    <div className="w-100 lead text-center">
                        { __( "Create new Festival?" ) }
                    </div> 
                </div>
            </ConfirmButton> 
        </div>
    </div>
}
export default AddFestivalContent