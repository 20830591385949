import { useEffect, useState } from "react"
import { GET_FEED_ACTION } from "src/modules/pe-basic-module/data/actionTypes"
import actions from "src/modules/pe-basic-module/data/actions"
import {actions as ludensActions} from "../data/actions"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import { WINDOW_WIDTH } from "src/libs/interfaces/layouts"
import { LoaderLine, } from "src/libs/useful"
import ResourceMonitorScreen from "./resourceMonitor/ResourceMonitorScreen"
import ResourceMonitorTablet from "./resourceMonitor/ResourceMonitorTablet"
import { GET_USER_RESOURCES_TYPE } from "../data/actionTypes"

const ResourceMonitorWidget = () : JSX.Element => {
    const {width} = useScreenSize()
    const user: IUser = useMainStore((state: IState) => state.user)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(() => {
        // actions(GET_FEED_ACTION, {data_type: "PEResourceType"})
        //     .then(() => {

        //         setIsLoading(false) 
        //     })
        // ludensActions(GET_USER_RESOURCES_TYPE, {user})
    }, [])
    if(isLoading)
    {
        return <div className="w-100 h-100 flex-centered">
            <LoaderLine />
        </div>
    }
    if(!user.id) {
        return <></>
    }
    if( width < WINDOW_WIDTH.TABLET) {
        return <div className="position-relative">
            <ResourceMonitorTablet />
        </div>
    }
    else {
        return <ResourceMonitorScreen />
    }
} 

export default ResourceMonitorWidget
