import { ID, POST_STATUS } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import { IUser } from "@/settings/zustand"
import { Button, Intent } from "@blueprintjs/core"
import { useNavigate, useParams } from "react-router"
import { IFestival, IProject, ITrack } from "../../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import SingleProjectForm from "./SingleProjectForm"


interface IMyTrackProps {
	projects:IProject[]
	track: ITrack
	route: string
	onChangeProject: (id: ID, field: keyof IProject, value: any) => void
	setIsLoading : ( bool: boolean) => void
}

export const MyTrack = ({projects, track, onChangeProject, setIsLoading, ...props}: IMyTrackProps) : JSX.Element | null =>
{    
	const {landId} = useParams()
	const navigate = useNavigate()
    const festival : IFestival = useFestDataStore( (state: IFestDataStor) => state.festival )

	const onLinkMember = (member: IProject) =>
	{
		console.log(member)
		navigate(`${props.route}/member/${member.id}`)
	}
	const onTutorChange = (value: IUser, member_id: ID) =>
	{
		setIsLoading(true)
		actions(
			UPDATE_SINGLE_TYPE,
			{
				data_type: "PEFestProject",
				id: member_id,
				item: { tutor: value.id },
				landId
			}
		)
		.then(resp => {
			onChangeProject( resp.id, "tutor", resp.tutor ) 
			setIsLoading(true)
		})
	}
	const onAuthorChange = (value: IUser, member_id: ID) =>
	{
		setIsLoading(false)
		actions(
			UPDATE_SINGLE_TYPE,
			{
				data_type: "PEFestProject",
				id: member_id,
				item: { leader: value.id },
				landId
			}
		)
		.then(resp => {
			onChangeProject( resp.id, "leader", resp.leader ) 
			setIsLoading(true)
		})
	}
	const setCloseTrack = (bool: boolean) =>
	{ 
		actions(
			UPDATE_SINGLE_TYPE,
			{
				data_type: "PEFestTrack",
				id: track?.id,
				item: { is_closed: !bool },
				landId
			}
		)
		.then( (response) => {
			console.log(response)
			actions( GET_FEED_ACTION,{ data_type:"PEFestTrack", landId } )
		})
	}
	const onChangeStatus = (member_id: ID, post_status: POST_STATUS) =>
	{
		// TODO: actions -- change track status 
		actions(
			UPDATE_SINGLE_TYPE,
			{
				data_type: "PEFestProject",
				id: member_id,
				item: { post_status },
				landId
			}
		)
		.then(resp => {
			console.log( resp )
			onChangeProject( resp.id, "post_status", resp.post_status )
		})
	}
	const allow_role_req = (member: any) =>
	{
		// TODO: actions -- allow role of User
	}
	const veto_role_req = (member: any) =>
	{
		// TODO: actions -- veto User role
	}
    const members = (
	projects.filter( member => member.track?.id === track?.id ).length > 0 
		?
		projects
			.filter( member => member.track?.id === track?.id ) 
			.map((member: IProject, i) =>
			{ 
                return <SingleProjectForm
                    key={member.id}
                    item={member}
                    route={props.route}
                    onChangeProject={onChangeProject}
                />
				// return <div className="col-12 grey2" key={member.id + "_" + i }>
				// 	<div className="row flex-centered">
				// 		<div className={`col-md-1 col-4 py-2 display-6 justify-content-end text-secondary ${ member.status === POST_STATUS.DRAFT ? "opacity_5" : "" } d-flex`} >
				// 			{member.order}
				// 		</div>
				// 		<div 
				// 			className={ `col-md-4 col-8 py-2 ${ member.status === POST_STATUS.DRAFT ? "opacity_5" : "" } d-flex flex-column track-moder-title-cell` }
				// 		>
				// 			<div className=" lead text-center text-md-left w-100">
				// 				{member.title}
				// 			</div>
				// 			<div className="d-flex mt-1 cell-hover">
				// 				<div
				// 					className="text-primary hover-straght pointer small px-2"
				// 					onClick={ () => onLinkMember( member) }
				// 				>
				// 					{__("Goto")}
				// 				</div>
				// 				{
				// 					member.post_status === POST_STATUS.DRAFT
				// 						?
				// 						<div 
				// 							className="text-danger hover-straght pointer small px-2"
				// 							onClick={() => onChangeStatus( member.id, POST_STATUS.PUBLISH )}
				// 						>
				// 							{__("Do show")}	
				// 						</div>
				// 						:
				// 						<div 
				// 							className="text-primary hover-straght pointer small px-2"
				// 							onClick={() => onChangeStatus(member.id, POST_STATUS.DRAFT)}
				// 						>
				// 							{__("Do hide")}	
				// 						</div>
				// 				}
				// 			</div>
				// 		</div>
				// 		<div className={`col-md-5 py-0 py-2 ${ member.post_status === POST_STATUS.DRAFT ? "opacity_5" : "" }`}>
				// 		{
				// 			member.tutor 
				// 			&& Array.isArray(member.tutor.roles) 
				// 			&& member.tutor.roles.filter((role: Role) => role === "Tutor").length === 0
				// 				?
				// 				<ButtonGroup large fill className="">
				// 						<Button 
				// 						className="p-2 w-100" 
				// 						intent={Intent.SUCCESS}
				// 						onClick={() => allow_role_req(member)}
				// 					>
				// 						<span className="small" dangerouslySetInnerHTML={{
				// 							__html: sprintf(
				// 									__("Give author %s tutor's capabilities"), 
				// 									`<span class='title lead'>${member.tutor?.display_name}</span><br>`
				// 								)
				// 						}} />
				// 					</Button>
				// 						<Button 
				// 						className="p-2 w-50" 
				// 						intent={Intent.DANGER}
				// 						onClick={() => veto_role_req(member)}	
				// 					>
				// 						{__("Veto")}
				// 					</Button>
				// 				</ButtonGroup>
				// 				:
				// 				<>
				// 					{
				// 						festival?.availableRoles && 
				// 						festival?.availableRoles.filter((role: Role) => role === "Tutor").length > 0
				// 							?
				// 							<FieldInput 
				// 								type={SCALAR_TYPES.EXTERNAL}
				// 								title={__("Tutor")}
				// 								editable
				// 								component={"User"}
				// 								paging={` role__in: [ "Tutor" ]`}
				// 								landId={landId}
				// 								value={  member.tutor } 
				// 								onChange={ value => onTutorChange(value, member.id) }
				// 							/>
				// 							:
				// 							null
				// 					}
				// 					{
				// 						festival?.availableRoles!.filter((role: Role) => role === "Project_author").length > 0
				// 							?
				// 							<FieldInput 
				// 								type={SCALAR_TYPES.EXTERNAL}
				// 								title={__("Project_author")}
				// 								editable
				// 								component={"User"}
				// 								paging={` role__in: [ "Project_author" ]`}
				// 								landId={landId}
				// 								value={  member.leader } 
				// 								onChange={ value => onAuthorChange(value, member.id) }
				// 							/>
				// 							:
				// 							null
				// 					}
				// 				</>
				// 		} 
				// 		</div> 
				// 		<div className="col-md-2 " >
				// 		{
				// 			member.post_status === POST_STATUS.DRAFT
				// 				?
				// 				<Button 
				// 					minimal 
				// 					fill 
				// 					intent={Intent.DANGER} 
				// 					rightIcon="eye-open" 
				// 					className="px-5"
				// 					onClick={() => onChangeStatus(member.id, POST_STATUS.PUBLISH)}
				// 				>
				// 					{__("Do show")}	
				// 				</Button>
				// 				:
				// 				<Button 
				// 					minimal 
				// 					fill 
				// 					intent={Intent.SUCCESS} 
				// 					rightIcon="eye-off" 
				// 					className="px-5"
				// 					onClick={() => onChangeStatus(member.id, POST_STATUS.DRAFT)}
				// 				>
				// 					{__("Do hide")}	
				// 				</Button>

				// 		}
				// 		</div>
				// 	</div>
				// </div>
			})
		:
		<div className="p-4 lead font-italic text-dark-primary">
		{
			__("No members in track yet")
		}
		</div>
    )
	return <div className="pe-fest-my-track-block row grey2 py-3 border-top border-secondary mb-4 ">
		<div className="col-12 d-flex flex-md-row flex-column">
			{
                track?.thumbnail 
                    ? 
                    <img src={ track?.thumbnail} alt="track" className='track-miniature'/> 
                    :
                    null
            }
			<h3 className="text-md-left text-center"> { track.title || __("Projects without track") }</h3>	
			<div className="ml-md-auto mr-md-0 mb-1 mx-auto">
			{
				track?.id && (
					track?.is_closed
						?
						<Button icon="lock" minimal intent={Intent.DANGER} onClick={() => setCloseTrack(true)}>
							<span className="text-uppercase">{__("Track now closed. Do you want to open?")}</span>
						</Button>
						:
						<Button icon="unlock" minimal intent={Intent.SUCCESS} onClick={() => setCloseTrack(false)}>
							<span className="text-uppercase">{__("Track now opened. Do you want to close?")}</span>
						</Button>
				)
			}
			</div> 
		</div>
		{ members }
	</div>
}

export default MyTrack