import React from "react" 
import BasicState from "@/libs/basic-view"
import SearchForm from "./search/SearchForm"

class SearchView extends BasicState {
  props: any
  addRender = () => { 
    return <SearchForm {...this.props} />
  }
}

export default (SearchView)
