import React, { useEffect, useState } from 'react' 
import { Button, Intent } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'  
import { IProject } from '@/modules/pe-fest-module/data/interfaces'
import { isRole } from '@/settings/zustand/user'
import actions from '@/modules/pe-basic-module/data/actions'
import { UPDATE_SINGLE_TYPE } from '@/modules/pe-basic-module/data/actionTypes'
import { useFestProjects } from '@/modules/pe-fest-module/data/store'
import { useParams } from 'react-router'

interface ICorrectNominationProps {
    item: IProject
}

const CorrectNomination = (props: ICorrectNominationProps) : JSX.Element => {
    const {landId} = useParams()
    const[isEdit, setEdit] = useState(false)
    const [nomination, setNomination] = useState(props.item.nomination)
    useEffect(() =>
    {
        setNomination( props.item.nomination )
    }, [props.item.nomination]) 
    const onNomination = () =>
    {
        // actions -- correct notification
        const data = {
            data_type: "PEFestProject",
            id: props.item.id,
            item: { nomination },
            landId: landId
        }
        actions(UPDATE_SINGLE_TYPE, data).then( (resp: IProject) => {
            console.log( resp.nomination )
            const currentProject = useFestProjects.getState().currentProject
            const setCurrentProject = useFestProjects.getState().setCurrentProject
            setEdit( false ) 
            setCurrentProject({
                ...currentProject,
                nomination: resp.nomination
            })
        })
        
    }
    const edit = () =>
    {
        return <div className="d-flex align-items-center ">
            <input 
                type="string"
                className='form-control0 input dark w_350'
                value={nomination}
                onChange={ (evt) => setNomination( evt.currentTarget.value )}
            />
            <Button
                intent={Intent.SUCCESS}
                icon="tick"
                minimal
                className='hint hint--top'
                data-hint={__("Save")}
                onClick={onNomination}
            />
            <Button
                intent={Intent.DANGER}
                icon="cross"
                minimal
                className='hint hint--top'
                data-hint={__("Cancel")}
                onClick={ () => setEdit( false ) }
            />
        </div>
    }
    const noEdit = () =>
    {
        return <div className="row align-items-center ">
            <div className="text-nowrap mx-3">
                { props.item.nomination }
            </div>
            <div className=" ">
                <Button
                    minimal
                    fill
                    icon="edit"
                    onClick={ () => setEdit( true ) }
                >
                    
                </Button>
            </div>
        </div>
    }
    return isRole([ "administrator", "track_moderator" ])
        ?
        isEdit ? edit() : noEdit()
        :
        <>{props.item.nomination}</>
}

CorrectNomination.propTypes = {}

export default CorrectNomination