
import BasicState from "@/libs/basic-view" 
import ExternalProjectFieldsForm from "./festAdmin/ExternalProjectFieldsForm"

class ExternalProjectFieldsView extends BasicState {
    props: any
    addRender = () => {  
        return <div className="container mb-5"> 
            <ExternalProjectFieldsForm { ...this.props } /> 
        </div> 
    }
} 

export default ExternalProjectFieldsView