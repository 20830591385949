const isImageExt = (extention : string): boolean => {
    return imageExts.filter( (ext:string) => {
        // console.log(ext, extention, ext === extention)
        return ext === extention 
    }).length > 0
}
export default isImageExt

export const imageExts = [
    "svg",
    "png",
    "jpg",
    "jpeg",
    "bmp"
]