import { useEffect, useState } from "react"
import { IFabulaMedia, IFabulaMultiScreenItem } from "src/modules/pe-fabula-module/data/interfaces"
import CurrentItem from "./CurrentItem"

/* Protopia Ecosystem component */
export interface IMultiScreenProps {
    item: IFabulaMedia
}
const MultiScreen = (props: IMultiScreenProps): JSX.Element => { 
    const [current, setCurrent] = useState<any>( {} )
    const [isLoad, setIsLoad] = useState(false)
    useEffect(() => {
        if(Array.isArray( props.item.parameters.items ) && !!props.item.parameters.items.length ) {
            setCurrent( props.item.parameters.items[0].id )
            // setIsLoad(true)
        }
    }, [])
    if( !props.item.parameters.screen ||  !Array.isArray( props.item.parameters.items || !isLoad) ) {
        return <></>
    }
    return <div className='pe-multi-screen-container'>
        {
            props.item.parameters.items.map(( item : IFabulaMultiScreenItem ) => {
                return <div 
                    className={`pe-multi-screen-item ${ item.id === current ? "active" : "" }`}
                    style={{
                        ...item.thumbnailBounds,
                        backgroundImage: `url(${item.thumbnail})`
                    }}
                    onClick={ () => setCurrent(item.id) }
                />
            })
        }
        <div 
            className="pe-multi-screen pe-fabula-overflow-y-auto"
            style={ props.item.parameters.screen }
        >
            <CurrentItem item={ props.item.parameters.items.filter( (item: IFabulaMultiScreenItem) => item.id === current )[0] } />
        </div>
    </div>
}
export default MultiScreen