import { Outlet } from "react-router" 
import Opponents from "./matrix/Opponents"
import Man from "./matrix/Man"
import { NavLink } from "react-router-dom"

 
const Manitu = (): JSX.Element => {
  const LUDENS_PANEL_HEIGHT = 42
  return <>
    <div className="w-100 d-flex position-relative" style={{ height: `calc(100% - ${LUDENS_PANEL_HEIGHT}px)` }}>
        <div className="flex-grow-100 position-relative pe-manitu-container overflow-y-auto ">
          <Outlet />
        </div>
        <NavLink className="opponents-btn" to="impact">
          <Opponents />
        </NavLink> 
        <NavLink className="hero-btn" to="autotraining">
          <Man 
            fill="#15161C"
            stroke="#1E2028"
            height={370}
            className={`man untouchble `}
          /> 
        </NavLink>
      </div>  
  </>
} 
export default Manitu