import { Button, Intent } from "@blueprintjs/core"
import Moment from "react-moment"
import { IAds } from "../../data/interfaces"
import { __ } from "src/libs/utilities"

interface IProps {
    item: IAds
    className?: string
}
const AdCard = ({item, className}:IProps) :JSX.Element => {
    return <div className={ ` position-relative  flex-centered flex-column ${ className }` }>
            <div className="d-flex border border-secondary " style={{maxWidth:700, height: 340}}>
                <div className="thumbnail h-100 position-relative" style={{ backgroundImage: `url(${ item.thumbnail })` }}>
                    <img src="http://fakeimg.pl/150x200" alt="fake images please?" className="unvisibled h-100"/> 
                    {/* <Button minimal className="position-absolute m-1 bottom bg-dark text-light">
                        {__("All Ads")}
                    </Button> */}
                </div>
                <div className="position-relative"> 
                    <div className="bg-secondary-light p-3 h" style={{fontWeight: 900, fontSize:40, lineHeight:0.9}}>
                        {item.title}
                    </div> 
                    <div className={`  m-3`}>
                       {item.post_content}    
                    </div> 
                    <Moment  locale="ru" format="D.MM.YYYY" className={`px-3 display-5 `}>
                       {item.date}    
                    </Moment> 
                    {
                        item.url &&
                        <a href={ item.url} target="_blank" rel="noreferrer" className="position-absolute m-1 bottom right">
                            <Button intent={Intent.SUCCESS} large>
                                {__("Ins")}
                            </Button>
                        </a> 
                    } 
                </div>
            </div>
        </div>
}

AdCard.propTypes = {}

export default AdCard