import { Navigate, Route, Routes } from "react-router"
import { useDataStore } from "src/modules/pe-basic-module/data/store"
import { IErmakSpace } from "../../data/interfaces"
import { IErmakStore, useErmakStore } from "../../data/store"
import ErmakSpaces from "./ErmakSpaces"
import Space from "./Space"


const ErmakSpaceForm = (props:any) :JSX.Element => {
    const PEErmakSpace: IErmakSpace[] = useDataStore((state: any) => state.PEErmakSpace) || []
    const currentSpace : IErmakSpace = useErmakStore((state:IErmakStore) =>state.currentSpace )
    return <Routes>
        <Route element={<ErmakSpaces /> } >
            <Route path="*" element={<Navigate to={currentSpace?.id.toString()} />} />
            {
                PEErmakSpace.map(space => <Route 
                    key={space.id}
                    path={space.id.toString() }
                    element={<Space item={space} />}
                />)
            }
        </Route>
    </Routes> 
} 

export default ErmakSpaceForm