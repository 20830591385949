import React from 'react' 

const FeedLayout = ( props: any ): JSX.Element => {
    const Layout = props.layoutComponent
        ?
        props.layoutComponent
        :
        DefaultFeedLayout
    return <Layout { ...props }> 
        { props.children }
    </Layout> 
}

export default FeedLayout

const DefaultFeedLayout = (props:any): JSX.Element => {
    return <div className={ ` ${props.containerClassName || "d-flex" } ` }> 
        <div className={ `row ${props.class_name}`  } style={ props.style }>
            { props.children }
        </div> 
        
    </div> 
}