import { PETestQuestionAnswer } from "../../data/interfaces"

interface IPEAnswerProps { 
    item: PETestQuestionAnswer
    isRight: boolean
    isSelect:boolean
    onChange: (item: PETestQuestionAnswer) => void
}
const Answer = ({item, ...props}:IPEAnswerProps): JSX.Element => {
    const onChange = () => { 
        props.onChange( item )
	}
    return <div className="pe-matching-answer-single " >
        <div className="pe-matching-answer-content">
            <label 
                className="_check_ d-flex flex-column " 
                htmlFor={`matchinger_${item.id}`}
            >
                <div className="pe-matching-answer-media">
                    {
                        item.thumbnail
                            && 
                            <img 
                                alt="" 
                                src={item.thumbnail} 
                                className="pe-matching-answer-image" 
                            /> 
                    }
                </div>
                <div className={`pe-matching-answer-title ${props.isRight ? "text-warning" : ""}`}>
                    <input
                        name="matchinger"
                        type="radio"
                        className=""
                        checked={ props.isSelect }
                        onClick={ onChange }
                        onChange={ onChange }
                        id={`matchinger_${item.id}`}
                    />
                    {item.title} 
                </div>
                {
                    item.post_content
                        &&
                        <div className="pe-matching-answer-descr">
                            {item.post_content}
                        </div> 
                }
            </label>
        </div>
    </div>  
} 

export default Answer