import { __ } from "src/libs/utilities"
import { extractYoutubeVideoID, getYoutubeThumbnail } from "src/libs/utilities/sprintf"
import { IPixel, PIXEL_TYPES } from "../../pe-manitu-module/data/interfaces"
interface ITreasureProps {
    item:IPixel
}
const Treasure = ({item}: ITreasureProps): JSX.Element => {
    switch(item.type) {
        case PIXEL_TYPES.TEST:
            return <TreasureTest item={item} />
        case PIXEL_TYPES.QUESTION:
            return <TreasureQuestion item={item} />
        case PIXEL_TYPES.YOUTUBE:
            return <TreasureYoutube item={item} />
        case PIXEL_TYPES.OPEN_PROBLEM:
            return <TreasureOpenProblem item={item} />
        default:
            return <TreasureDefault item={item} />
    }
} 

export default Treasure

const TreasureTest = ({item}: ITreasureProps): JSX.Element => {
    const pe_academic_discipline = item.pe_academic_discipline[0] || {}
    
    return <div className="pe-manitu-pixel-card">
        <div 
            className="pe-manitu-pixel-thumb" 
            style={{backgroundImage:`url(${item.thumbnail})`}}
        >
            <div className="price mr-2">
                <i className="fas fa-coins"></i>
                {item.price}
            </div>
            <div className="label">{__("Test")}</div>
            <div 
                className="academy-discipline-label"
                style={{ backgroundColor: pe_academic_discipline?.color }}
            >
                { pe_academic_discipline?.title }
            </div>
        </div>
        <div className="pe-manitu-pixel-title">
            {item.title}
        </div> 
    </div> 
}
const TreasureOpenProblem = ({item}: ITreasureProps): JSX.Element => { 
    const pe_academic_discipline = item.pe_academic_discipline[0] || {}
    return <div className="pe-manitu-pixel-card">
        <div 
            className="pe-manitu-pixel-thumb" 
            style={{backgroundImage:`url(${item.thumbnail})`}}
        >
            <div className="price mr-2">
                <i className="fas fa-coins"></i>
                {item.price}
            </div>
            <div className="label">{__("Open Problem")}</div>
            <div 
                className="academy-discipline-label"
                style={{backgroundColor:pe_academic_discipline?.color}}
            >
                {pe_academic_discipline?.title}
            </div>
        </div>
        <div className="pe-manitu-pixel-title">
            {item.title}
        </div> 
    </div> 
}
const TreasureYoutube = ({item}: ITreasureProps): JSX.Element => {
    const id = extractYoutubeVideoID( item.url || "" )
    const thumbnail = getYoutubeThumbnail(id)
    const pe_academic_discipline = item.pe_academic_discipline[0] || {}
    return <div className="pe-manitu-pixel-card">
        <div 
            className="pe-manitu-pixel-thumb" 
            style={{backgroundImage:`url(${thumbnail})`}}
        > 
            <div className="price">
                <i className="fas fa-coins mr-2"></i>
                {item.price}
            </div>
            <div className="label">{__("Youtube")}</div>
            <div 
                className="academy-discipline-label"
                style={{backgroundColor:pe_academic_discipline?.color}}
            >
                {pe_academic_discipline?.title}
            </div>
        </div>
        <div className="pe-manitu-pixel-title">
            {item.title}
        </div> 
    </div> 
}
const TreasureQuestion = ({item}: ITreasureProps): JSX.Element => {
    const pe_academic_discipline = item.pe_academic_discipline[0] || {}
    return <div className="pe-manitu-pixel-card">
        <div 
            className="pe-manitu-pixel-thumb" 
            style={{backgroundImage:`url(${item.thumbnail})`}}
        >
            <div className="price">
                <i className="fas fa-coins mr-2"></i>
                {item.price}
            </div>
            <div className="label">{__("Question")}</div>
            <div 
                className="academy-discipline-label"
                style={{backgroundColor:pe_academic_discipline?.color}}
            >
                {pe_academic_discipline?.title}
            </div>
        </div>
        <div className="pe-manitu-pixel-title">
            {item.title} 
        </div>
        
    </div> 
}
const TreasureDefault = ({item}: ITreasureProps): JSX.Element => {
    const pe_academic_discipline = item.pe_academic_discipline[0] || {}
    return <div className="pe-manitu-pixel-card">
        <div 
            className="pe-manitu-pixel-thumb" 
            style={{backgroundImage:`url(${item.thumbnail})`}}
        >
            <div className="price">
                <i className="fas fa-coins mr-2"></i>
                {item.price}
            </div>
            <div className="label">{__("Unknown")}</div>
            <div 
                className="academy-discipline-label"
                style={{backgroundColor:pe_academic_discipline?.color}}
            >
                {pe_academic_discipline?.title}
            </div>
        </div>
        <div className="pe-manitu-pixel-title">
            {item.title} 
        </div>
        
    </div> 
}