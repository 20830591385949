import React, { useState }  from "react" 
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"


const PageTypeSettings = (props: any) :JSX.Element =>
{ 
    const [data, setData] = useState(props.data)
    const onField = (value: any, field: string) =>
    {        
        props.onField( value, field )
        let _data = { ...data }
        _data[ field ] = value
        setData( _data )
    }
    return <>  
        <FieldInput
            field="pageType"
            title={__("Page Type")} 
            visualization="route-type-scalar"
            id={props.id} 
            on={ onField }
            onChange={ onField }
            editable
            value={ data.component } 
        /> 
    </>
}
export default PageTypeSettings