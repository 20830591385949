import { Tag } from "@blueprintjs/core"
import { IProject } from "../../data/interfaces"

interface IProps {
    name: string
    index: number
    project:IProject
}
const ProjectFieldRow = (props : IProps) : JSX.Element => {
    switch(props.name) {
        case "#":
            return  <div className="pe-table-cell">{props.project.order}</div>
        case "Track":
            return  <div className="pe-table-cell">{props.project.track?.title}</div>
        case "Project":
            return  <div className="pe-table-cell">{props.project.title}</div>
        case "Rating":
            return  <Tag minimal round className="px-3 py-2 m-1">
                <div className="lead">
                    { Number( props.project.actualRating ).toFixed( 2 ) }
                </div>
            </Tag>
        case "Tutor":
            return  <div className="pe-table-cell">{props.project.tutor?.display_name}</div>
        case "Project_author":
            return  <div className="pe-table-cell">{props.project.leader?.display_name}</div>
        case "leader":
            return  <div className="pe-table-cell">{props.project.leader?.display_name}</div>
    }
    return (
        <div className="pe-table-cell">{props.name}</div>
    )
} 

export default ProjectFieldRow