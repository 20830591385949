import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { ID, ITax } from "@/libs/interfaces/layouts"
import { singleName } from "@/libs/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __ } from "@/libs/utilities"
import { useParams } from "react-router"

/* Protopia Ecosystem component */
export interface IEditProjectTaxProps {
    isEditable: boolean
    isRequired: boolean 
    data_type: string
    item: ID
    onChange: (item: ITax) => void
}
const EditProjectTax = ({ 
    isRequired, 
    data_type,
    item, 
    isEditable,
    ...props
}: IEditProjectTaxProps): JSX.Element => {
    const {landId} = useParams()
    const title: string = singleName( data_type )
    const terms : ITax[] = useDataStore((state: any) => state[data_type]) || [] 
    return Array.isArray( terms) &&  terms.length > 0 
        ? 
        <div className="row data-list p-0">
            <div className="col-md-4 col-sm-4 my-auto">
                <div className='d-flex justify-content-between align-items-center w-100'>
                    {
                        isRequired && <div className='require-sign mr-4 h-100'>
                            <div className='d-flex user-select-none descr text-danger'>
                                {__("Required field")}
                            </div>  
                        </div>
                    }
                    <div className="text-right w-100">
                        <div className={`title ${isRequired ? "text-danger" : ""}`}>
                            {__( title )}
                        </div> 
                    </div>                      
                </div> 
            </div> 
            <div className="col-md-8 col-sm-8 p-0">
                <FieldInput 
                    type={ SCALAR_TYPES.EXTERNAL }
                    component={ data_type}
                    landId={landId} 
                    className={ ` w-100 d-flex justify-content-center ` }
                    vertical={ VerticalType.VERTICAL }
                    editable={ isEditable } 
                    value={ terms?.filter(t => t.id === item)[0] }  
                    onChange={ id => {
                        props.onChange(id)
                    }}
                /> 
            </div>
        </div>
        :
        <></> 
}
export default EditProjectTax