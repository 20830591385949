import React, { useState } from 'react'
import { getQueryData, } from 'src/libs/layouts'    
import DataTableFiltersPanel from '../dataTable/DataTableFiltersPanel'
import Feed from '@/modules/pe-basic-module/views/core/Feed'
import DataCard from './DataCard'
import { useParams } from 'react-router'
import { ID } from '@/libs/interfaces/layouts'

export const DataCards = ( props:any ) : JSX.Element => {
    const params = useParams() 
    const landId : ID = params.landId || ""
    const [count, setCount] = useState<number>(props.count || 10 )
    const onChangeCount = ( count: number ) => {
        setCount(count)
    }
    const clearFilter = () => {

    } 
    return <>
        <DataTableFiltersPanel
            data_type={props.data_type}
            count={count}
            onChangeCount={onChangeCount}
            clearFilter={clearFilter}
        /> 
        <Feed 
            data_type={props.data_type} 
            isLoader
            count={20} 
            offset={0} 
            order= { props.order }
            filter={ props.filter }
            class_name='row w-100'
            containerClassName="container-fluid"
            component={DataCard} 
            land_id={ landId }
            params={{
                fields: getQueryData( props.data_type ),
                route: props.route,
                col:2
            }}
        />  
        
    </>
}
