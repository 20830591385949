import { IModule, IWidget, IWidgetArea, IWidgetNest } from "@/libs/interfaces/layouts"
import { extentions, modules, widgets } from "@/libs/layouts"
import { LayoutIcon } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"

interface IProps {
    area: IWidgetArea
    item: IWidgetNest
    n: number
    onUpdate: () => void
    onRemoved: () => void
}
const Widget = (props: IProps) : JSX.Element => {
    const _widgets: any = widgets() || {}
    const _extentions: any = extentions() || {}
    const componentName: string = props.item?.component || ""
    const component: IWidget = _widgets[ componentName ] || _extentions[ componentName ] || {}
    const module: IModule = modules()[component.module] || {}
    return <Button fill minimal large className="btn-stretch border border-secondary-light my-2">
        <div className="d-flex align-items-center w-100">
            <div className="small text-center hint hint--top" data-hint={ __( module.title ) }>
                <img
                    src={ module.icon }
                    className="w_25 h_25"
                    alt=""
                />
            </div>
            <div className="flex-grow-100 py-2 px-3">
                <div className="lead ">
                    { __(component.title) }
                </div>
                <div className="descr ">
                    { __(component.description) }
                </div> 
            </div>
        </div>
    </Button>  
} 

export default Widget