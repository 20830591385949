import { IFabulaHero, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { useEffect, useState } from "react"
import { Position, Tooltip } from "@blueprintjs/core"
import { __, sprintf } from "src/libs/utilities"


/* Protopia Ecosystem component */
export interface IHeroFullnessProps {

}
const HeroFullness = (props: IHeroFullnessProps): JSX.Element => {
    const story: IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current )
    const hero: IFabulaHero = useStorytellingStore((state: IStorytellingStore) => state.hero )
    return story.isFullness && !!hero.fullness
        ?
        <div className='pe-hero-fullness-menu-container'>
            <HealthSlider />
        </div>
        : 
        <></>
}
export default HeroFullness

const HealthSlider = ( ) => {    
     const [width, setWidth] = useState<number>(100)
    const hero: IFabulaHero = useStorytellingStore((state: IStorytellingStore) => state.hero )
     
    useEffect(() => useStorytellingStore.subscribe (
        (state: IStorytellingStore) => {
            //useStorytellingStore.getState().hunger()
            if( state.hero.currentFullness ) {
                setWidth( Math.floor(state.hero.currentFullness! / state.hero.fullness! * 100))
            } 
        }
    ), [])
    return <Tooltip
        position={ Position.TOP }
        content={ sprintf( __( "Fullness: %s" ), ( hero.currentFullness || 0 )?.toFixed(0).toString() ) }
    >
        <div 
            className="pe-fabula-health-slider"
        >
            <div className="pe-fabula-health"
                style={{width : `${width}%`}}
            />
        </div>
    </Tooltip>
    
}