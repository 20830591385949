import { ID } from "@/libs/interfaces/layouts"
import { getQueryArgs } from "src/libs/layouts"
 
export const GET_PROJECT_DIARY = ( id:ID ) => {
    return ` getPEFestDiarys (
        paging: {
            metas: {
                key: "project",
                value: "${id}"
            }
        }) 
        {
            ${ getQueryArgs("PEFestDiary") }
        }
    }`
        
} 