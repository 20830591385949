import { Navigate, Route, Routes } from 'react-router'
import FestHelp from '../help/FestHelp'
import Diary from './Diary'
import Project from './Project'
import ProjectContainer from './ProjectContainer'
import ProjectEditForm from './ProjectEditForm'



/* Protopia Ecosystem component */
export interface IProjectFormProps { 

}  

const ProjectScreenForm = ( props: IProjectFormProps ): JSX.Element => {  
    return <> 
        <Routes>
            <Route path="" element={ <ProjectContainer /> } >
                <Route index element={ <Navigate to="ratings" replace /> } /> 
                <Route 
                    path='ratings'
                    element={ <Project /> }
                />
                <Route 
                    path="project-members"
                    element={ <div className="text-center my-4">MEMBERS</div> }
                />
                <Route 
                    path="management"
                    element={ <ProjectEditForm /> }
                />
                <Route 
                    path="diary"
                    element={ <Diary /> }
                />
                <Route path="*" element={ <Navigate to="ratings" replace /> } /> 
            </Route>
        </Routes>
        <FestHelp />
    </> 
} 

export default ProjectScreenForm