import { Overlay } from "@blueprintjs/core"
import { useEffect, useRef, useState } from "react"
import { FABULA_MODE, IFabulaHero, IPEFabulaMapTile, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import TileSlot from "./TileSlot"

/* Protopia Ecosystem component */
export interface ITileProps {
    item?: IPEFabulaMapTile
    x: number
    y: number
}
const Tile = (props: ITileProps): JSX.Element => {
    const ref = useRef(null)
    const current: IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    const hero : IFabulaHero = useStorytellingStore((state: IStorytellingStore) => state.hero)
    const width : number = current.map?.width / current.map?.tileX
    const height : number = current.map?.height / current.map?.tileY
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
    const [bound, setBound] = useState<DOMRect>({} as DOMRect )
    const [isEnabled, setIsEnabled] = useState<boolean>(
        props.item 
            ? 
            ( typeof props.item?.enabled === "undefined" 
                ? 
                true 
                : 
                !!props.item?.enabled 
            )
            :
            false 
    )
    useEffect(() => {
        if( current.mode === FABULA_MODE.QUEST && hero.tileID === props.item?.id ) {
            setIsOverlayOpen( true )
            const episode = props.item?.episodes[0]
            if( episode ) {
                useStorytellingStore.setState({currentEpisode: episode})
            }
        }
        else {
            setIsOverlayOpen( false )
        }
    }, [ hero ])
    const toggleOverlay = () => {
        setIsOverlayOpen(!isOverlayOpen)
    }
    const onOpen = () => {
        if( !!ref.current && isEnabled ) {
            const bn: DOMRect = (ref.current! as HTMLElement).getBoundingClientRect() 
            setBound(bn)
            setIsOverlayOpen(!isOverlayOpen)
        }
        
    }
    return <>
        <div 
            data-x={ props.x }
            data-y={ props.y }
            className={`pe-story-map-tile ${ isEnabled ? "" : "disabled" }`} 
            style={{
                left:  width * props.x,
                top:  height * props.y,
                width,
                height,
                color: "red"
            }}
            ref={ref} 
        >
        {
            hero.tileID === props.item?.id &&
                <div className="pe-fabula-mine-hero-label" onClick={ onOpen }/> 
        }
        </div>
        
        <Overlay 
            isOpen={isOverlayOpen} 
            onClose={toggleOverlay}
            backdropClassName={`flex-centered`}
            portalClassName={`tile-portal`}
        >
            <TileSlot 
                x={ props.x } 
                y={ props.y } 
                item={ props.item } 
                bound={ bound } 
                onClose={ toggleOverlay }
            />
        </Overlay>
    </>
}
export default Tile