import { useEffect, useState } from "react"
import { IEvent } from "../../data/interfaces"
import { actions } from "../../data/actions"
import { GET_EVENTS_ACTION } from "../../data/actionTypes"
import { Loading } from "src/libs/useful"
import EventCard from "../components/EventCard"
import { clearFestival } from "src/modules/pe-fest-module/hooks/festival"
import switchToBlog from "src/modules/pe-basic-module/hooks/switchToBlog"

interface IAficheProps {

}
const Afiche = (props: IAficheProps) :JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [events, setEvents] = useState<IEvent[]>([])

    useEffect(() => { 
        clearFestival()
        switchToBlog( "-1" ) 
        actions(GET_EVENTS_ACTION, {})
            .then((response) => {
                setEvents( response )
                setIsLoading(false)
            })
    }, [])
    if( isLoading ) return <Loading />

    return <div className="container-float mx-3">
        <div className="row ">
        {
            events.map( event => <EventCard item={event} key={event.id} /> )
        }
        </div>
    </div>
} 

export default Afiche