import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { ICategory } from "@/modules/pe-fest-module/data/interfaces"
import { ID } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ICategorySwitchFormProps {
    onChange: (id: ID) => void
}
const CategorySwitchForm = (props: ICategorySwitchFormProps): JSX.Element => {
    const PEFestCategory: ICategory[] = useDataStore( (state: any) => state.PEFestCategory ) || []
    const [chooseNewCategory, setchooseNewCategory] = useState<ID>("-1")
    const chooseNewCategoryHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setchooseNewCategory( evt.target.value )
        props.onChange( evt.target.value )
    }
    if(!PEFestCategory.length)
        return <></>
    return <div className='pe-category-switch-form-container mt-3'>
        
        <div className="mb-2 small" >
            { __("Choose parent category") }
        </div>
        {
            PEFestCategory.map(cat => {
                return <div className="w-100" key={`newCrit${cat.id}`}>
                <input
                    type="radio"
                    id={ `choose_${cat.id}` }
                    value={ cat.id }
                    data-id={ cat.id }
                    className="radio_full"
                    name="choose_cat"
                    onChange={chooseNewCategoryHandler}
                    checked={ chooseNewCategory === cat.id}
                />
                <label htmlFor={`choose_${cat.id}`} data-hint={cat.title}>
                    <div className="cat_color_label" style={{ backgroundColor: cat.color.toString() }} />
                </label>
            </div>
            })
        }
        <input
            type="radio"
            id={ `choose_-1` }
            value={ "-1" }
            data-id={ "-1" }
            className="radio_full"
            name="choose_cat"
            onChange={chooseNewCategoryHandler}
            checked={ chooseNewCategory === "-1" }
        />
        <label htmlFor={`choose_-1`} data-hint={__("No Category")}>
            <div className="cat_color_label" style={{ backgroundColor: "#888888" }} />
        </label>
    </div>
}
export default CategorySwitchForm