import React, { useEffect, useState } from 'react' 
import { __ } from "@/libs/utilities"
import { sprintf } from "@/libs/utilities"
import { LoaderLine } from "@/libs/useful"
import { Params, useParams } from 'react-router'
import actions from '../../data/actions'
import { SEARCH_ACTION } from '../../data/actionTypes'
import { Card } from '@blueprintjs/core'

const SearchForm = (props: any): React.ReactElement => {
    const [posts, setPosts] = useState<any[]>([])
    const [isLoading, setLoading] =useState<boolean>(true)
    const params: Readonly<Params<string>> = useParams<string>()
    useEffect(() => {
        actions( SEARCH_ACTION, {search: params.id} )
            .then( resp => {
                setPosts( resp ) 
                setLoading(false)
            })
    }, [])

    const list: React.ReactElement[] | React.ReactElement = isLoading
    ?
    <LoaderLine />
    :
    posts.map((post:any, i:number) => <Post data={post} key={post.id} /> )
    
    return <div className="container">
        <div className="row">
            <div className="col-12">
            <div className="page-title text-center mb-3">
                { sprintf( __( "Результаты поиска по запросу: %s" ), params.id! )}
            </div>
            </div>
            <div className="col-12" >
                {list}
            </div>
        </div>
    </div>
}

const Post = (props:any): React.ReactElement => {
    return <Card className="m-1">
        <div className='lead mb-2'>
            {props.data.post_title}
        </div>
        {/* <div className=' ' dangerouslySetInnerHTML={{__html: props.data.post_content.replaceAll("<!-- wp:paragraph -->", "")}} /> */}
    </Card>
}
 

export default SearchForm