import { ILayoutData } from "@/libs/interfaces/layouts"
import { IAdminMenuStore, useAdminMenuStore } from "../../data/store"
import { useEffect, useState } from "react"
import Layouts, { init_method }  from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { LAYOUT_INIT_METHODS } from "@/libs/layout-app/LayoutBody"
import { Button, Callout, Intent } from "@blueprintjs/core"
import { actions } from "../../data/actions"
import { PREVIEW_LAYOUT_ACTION } from "../../data/actionTypes"
import { AppToaster } from "@/libs/useful"

const MenuHead = () : JSX.Element => {
  const layoutsClone: ILayoutData = useAdminMenuStore((state: IAdminMenuStore) => state.layoutsClone)
  const layouts = Layouts();
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [json, setJson] = useState<string>("")

  useEffect(() => {
    setJson( JSON.stringify(layoutsClone) )
  }, [])
  useEffect(() => {
    setIsUpdated(JSON.stringify(layoutsClone) !== json)
  }, [layoutsClone, layouts])

  const onPreview = () => {
    actions(PREVIEW_LAYOUT_ACTION, {...layoutsClone})
      .then(res => {
        AppToaster.show({
          message: "Layout updated.",
          
        })
      })
  }
  return init_method() ===  LAYOUT_INIT_METHODS.EXTENDED
    ?
    <div className="d-flex w-100">
      <div className={`flex-grow-100 flex-centered btn btn-block h-100 btn-${isUpdated ? "danger" : "secondary opacity_5 untouchble"}`}>
        {__(isUpdated ? "Update Menu routes map" : "Change menu parameters")}
      </div>
      <Button large  intent={Intent.SUCCESS} onClick={onPreview}  className="px-4">
        {__("Preview ")}
      </Button>
    </div>
    
    :
    <div className="d-flex w-100">
      <Callout intent={isUpdated ? Intent.DANGER : Intent.SUCCESS} className="flex-grow-100 w-100 h-100 flex-centered flex-column">
        <div className="title text-uppercase">
          {__("The current app config does not allow for on-the-fly routing changes")}
        </div>
        <div className="">
          { __("Currently you can copy the app-config to a clipboard and use it in layouts.json when recompiling") }
        </div>
      </Callout>
      <Button large intent={Intent.SUCCESS} onClick={onPreview} className={`px-4 w-50 ${isUpdated ? "" : "untouchble opacity_5"}`}>
        <div className="title text-uppercase">
          {__("Preview new routing")}
        </div>
        <div className="small">
          {__("Attantion! Changes are lost.Reload app to return")}
        </div>
      </Button>
    </div>
    
  } 

export default MenuHead