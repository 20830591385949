import { ID } from "@/libs/interfaces/layouts"
import { IPlace } from "../interfaces"
import { placeTypes } from "./placeTypes"

export const places = (): IPlace[] => {
    const _places = [
        {
            id: 1,
            data: {id:1, title: "", post_content: ""},
            title: "Балашиха",
            post_content: `Наш уровень жизни систематически разрушается, но в течение многих лет многие американцы не до конца понимали, что происходит, потому что это происходило достаточно медленно. Но сейчас мы достигли стадии, когда покупательная способность наших денег падает, а стоимость жизни стала чрезвычайно болезненной. Благодаря быстро растущей стоимости жизни средний класс становится “классом бедных”, и бедных всё чаще вытесняют на улицы. Если мы не найдём способ изменить эти тенденции, пройдёт совсем немного времени, и мы столкнёмся с огромными социальными потрясениями.
            <p>
            Ранее сегодня я наткнулся на статью о женщине, которая нашла чек от Burger King, датированный 10 августа 1986 года.
            <p>
            В то время вы могли купить Whopper всего за 1,54 доллара.
            <p>
            Сегодня тот же самый Whopper обойдется вам в 6,79 долларов
            <p>
            Женщина была ошеломлена, обнаружив ретро-​чек Burger King 1980-х годов, который показывает ошеломляющий рост цен, который сеть быстрого питания внедрила за последние четыре десятилетия.
            <p>
            Лиза из США поделилась квитанцией в социальных сетях после того, как её мать нашла её в коробке в гараже во время ремонта своего дома.
            <p>
            Выцветшая бумага из сети быстрого питания датируется 10 августа 1986 года и содержит список из трёх бургеров Whopper, купленных за 4,62 доллара, что составляет 1,54 доллара каждый.
            <p>
            Один бургер Whopper в настоящее время стоит 6,79 доллара в сегодняшних деньгах – более чем в четыре раза больше цены, указанной в винтажном чеке.
            Другими словами, если бы у вас тогда было 6,79 доллара, вы могли бы купить четыре whoppers, и у вас всё равно остались бы деньги.
            <p>
            Вот что делает инфляция.
            <p>
            Она разрушает нашу покупательную способность.
            <p>
            Другая женщина по имени Мелани, зарабатывающая 34 доллара в час, испытывает такой финансовый стресс, что буквально старается, чтобы одной буханки ржаного хлеба хватило ей на всю неделю…
            <p>
            “Что я начала делать, так это покупать буханку ржаного хлеба и стараюсь усердно работать, чтобы одной буханки ржаного хлеба хватило мне на всю неделю. И я ем арахисовое масло, поэтому буду есть тосты с арахисовым маслом всякий раз, когда проголодаюсь ”.
            В прежние времена, если вы зарабатывали 34 доллара в час, вы могли себе позволить роскошную жизнь.
            <p>
            Но сейчас большинство людей, зарабатывающих 34 доллара в час, едва сводят концы с концами из месяца в месяц.
            <p>
            Конечно, абсурдно дорогой стала не только еда.
            <p>
            На данный момент дома в США никогда не были более недоступными, чем сейчас.
            <p>
            Недавно в Twitter было опубликовано следующее письмо Кобейси…
            <p>
            Цены на жильё с поправкой на инфляцию сейчас на 85% выше, чем в среднем за прошлый век.
            <p>
            Даже с учётом инфляции цены на жильё никогда не были настолько высокими, как сейчас.
            Фактически, цены на жильё с поправкой на инфляцию сейчас на 20% выше своего пика 2008 года, предыдущего рекордно высокого уровня за всё время.
            <p>
            Средний дом в настоящее время продаётся за пугающие 530% от среднего годового дохода.
            <p>
            Между тем, средняя плата за жильё в настоящее время составляет рекордные 49% от среднего дохода до НАЛОГООБЛОЖЕНИЯ.
            <p>
            Доступность никогда не была хуже.
            Мы никогда не видели ничего подобного за всю историю нашей страны.
            <p>
            С начала 2019 года средняя цена дома в США выросла более чем на сто тысяч долларов…
            <p>
            Фактически, сравнивая нынешние цены с уровнями до вирусной паники, данные Федеральной резервной системы Сент-​Луиса показывают, что средняя цена жилья в США выросла с 313 000 долларов в начале 2019 года до 416 000 долларов сегодня.
            Цены на аренду также совершенно сошли с ума.
            <p>
            Как я обсуждал на прошлой неделе, средняя запрашиваемая арендная плата в Соединённых Штатах сейчас превышает 2000 долларов в месяц.
            <p>
            За последние пару лет мы стали свидетелями беспрецедентного повышения арендной платы и огромное количество арендаторов получили пинок под зад.
            <p>
            Фактически, прямо сейчас мы наблюдаем цунами выселений в округе Лос-​Анджелеса…
            <p>
            С отменой мер защиты в COVIDной эпохи число арендаторов, которым грозит выселение в Лос-​Анджелесе, продолжает расти на тысячи каждый месяц.
            <p>
            С февраля по конец августа домовладельцами в городе было подано около 50 000 уведомлений о выселении, согласно данным, опубликованным в понедельник офисом контролера Лос-​Анджелеса.
            <p>
            Представитель компании сказал, что 96% из них связаны с неуплатой арендной платы, а арендодатели задолжали в совокупности 186,5 миллионов долларов.
            Так куда же пойдут все эти люди ?
            <p>
            Если они достаточно молоды, возможно, они смогут жить со своими родителями.
            <p>
            Но у многих не будет такой возможности.`,
            coordinates: [ 55.826020, 37.959141 ],
            type: [1,3,2],
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1556787692-f1a516e45948?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1928&q=80",
            images:[
                "https://plus.unsplash.com/premium_photo-1695757262775-007c3097f294?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1967&q=80",
                "https://plus.unsplash.com/premium_photo-1695302441317-5772e5f3c26c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80",
                "https://images.unsplash.com/photo-1694967336899-d6d8b08932bf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
                "https://images.unsplash.com/photo-1688435088702-e799b778d970?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
            ],
            url: ""  
        },
        {
            id: 2,
            data: {id:2, title: "", post_content: ""},
            title: "Петушки",
            post_content: `Город (с 1965 года) в Российской Федерации. Административный центр Петушинского района Владимирской области.
            <p>
            Образует одноимённое муниципальное образование город Петушки со статусом городского поселения как единственный населённый пункт в его составе.
            <p>
            Петушки расположены на левом берегу реки Клязьмы (бассейн реки Волги), в 67 км к юго-западу от Владимира, в 120 км к востоку от Москвы. Город находится на федеральной автодороге М-7 «Волга» Москва — Уфа. Железнодорожная станция.`,
            coordinates: [ 55.939234, 39.485303 ],
            type: [2],
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1552338368-06c7d392fac6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1965&q=80",
           
            images:[
                "https://images.unsplash.com/photo-1656057339655-786cadf701a7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
                "https://images.unsplash.com/photo-1652169890471-17c3e68bf920?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1596241913274-fd9f65e3a2b5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE5fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1630167554122-d71309729fa1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE3fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
            ],
            url: ""  
        },
        {
            id: 3,
            data: {id:3, title: "", post_content: ""},
            title: "Нижнекамск",
            post_content: `Махачкала́ (с 1857 по 1921 год — Петро́вск)[5] — город на юге России (на Кавказе), расположенный на берегу Каспийского моря[6]. Столица Республики Дагестан. Третий по численности населения город Северо-Кавказского региона и крупнейший город Северо-Кавказского федерального округа.
            <p>
            Образует городской округ город Махачкала. Является ядром почти миллионной Махачкалинско-Каспийской агломерации.
            <p>
            Город был основан в 1844 году как Петровское укрепление. Название было связано с тем, что, по преданию, во время Персидского похода в 1722 году на этом месте находился лагерь армии Петра I. В 1857 году город был переименован в Петровск.
            <p>
            С марта по апрель 1918 года[7], а также с ноября 1918 года по март 1920 года в ходе Гражданской войны город удерживался антибольшевистскими силами и именовался Шамиль-кала в честь имама Шамиля[8].
            <p>
            В 1922 году был переименован в Махачкала (до 1950-х годов часто писали — Махач-Кала[9]); название было дано в честь одного из организаторов советской власти в Дагестане — Магомеда Али (Махача) Дахадаева (1882—1918). Название происходит от «Махач» — сокращенной формы имени «Магомед», и от кала в значении «город, крепость»
            <p>
            За обладание «дагестанским коридором» в своё время боролись гунны, персы, арабы. Немаловажную роль в истории борьбы за обладание этим коридором сыграл город Тарки, расположенный недалеко от современной Махачкалы. Под названием «Таргу» он впервые упоминается в VIII веке у армянского историка Гевонда[11].
            <p>
            Тарки был известен уже с XV века как торговый центр, через который проходил караванный путь на город Дербент — один из древнейших городов мира. Под именем Инджи (кум. Инжи, позднее Анджи) населённый пункт известен со средневековья`,
            coordinates: [ 55.643538, 51.825105 ],
            type: [1], 
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1545230878-7de54ec69435?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80",            
            images:[
                "https://images.unsplash.com/photo-1588821323158-957a2e8b13df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDIwfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1566305061634-8768ed56c5bb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1566737779673-34274199b710?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE5fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1566237542104-77c723a65d2c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            ],
            url: ""  
        }, 
        {
            id: 4,
            data: {id:4, title: "", post_content: ""},
            title: "Махачкала",
            post_content: `Махачкала́ (с 1857 по 1921 год — Петро́вск)[5] — город на юге России (на Кавказе), расположенный на берегу Каспийского моря[6]. Столица Республики Дагестан. Третий по численности населения город Северо-Кавказского региона и крупнейший город Северо-Кавказского федерального округа.
            <p>
            Образует городской округ город Махачкала. Является ядром почти миллионной Махачкалинско-Каспийской агломерации.
            <p>
            Город был основан в 1844 году как Петровское укрепление. Название было связано с тем, что, по преданию, во время Персидского похода в 1722 году на этом месте находился лагерь армии Петра I. В 1857 году город был переименован в Петровск.
            <p>
            С марта по апрель 1918 года[7], а также с ноября 1918 года по март 1920 года в ходе Гражданской войны город удерживался антибольшевистскими силами и именовался Шамиль-кала в честь имама Шамиля[8].
            <p>
            В 1922 году был переименован в Махачкала (до 1950-х годов часто писали — Махач-Кала[9]); название было дано в честь одного из организаторов советской власти в Дагестане — Магомеда Али (Махача) Дахадаева (1882—1918). Название происходит от «Махач» — сокращенной формы имени «Магомед», и от кала в значении «город, крепость»
            <p>
            За обладание «дагестанским коридором» в своё время боролись гунны, персы, арабы. Немаловажную роль в истории борьбы за обладание этим коридором сыграл город Тарки, расположенный недалеко от современной Махачкалы. Под названием «Таргу» он впервые упоминается в VIII веке у армянского историка Гевонда[11].
            <p>
            Тарки был известен уже с XV века как торговый центр, через который проходил караванный путь на город Дербент — один из древнейших городов мира. Под именем Инджи (кум. Инжи, позднее Анджи) населённый пункт известен со средневековья`,
            coordinates: [ 42.982470, 47.503243 ],
            type: [1,2,3],
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1566893318669-b4fc326b8bcf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1780&q=80",            
             images:[
                "https://images.unsplash.com/photo-1566237542104-77c723a65d2c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1567667778211-b19f5a4e1efe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1543438853-a57366c42c90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1558267748-a210b34249c9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEwfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
            ],
            url: ""  
        },  
        {
            id: 5,
            data: {id:5, title: "", post_content: ""},
            title: "Эль-Хубар",
            post_content: `Эль-Хубар (араб. الخبر) — город в Саудовской Аравии на берегу Персидского залива. Население — 941,358 человек (2012). Вместе с городами Даммам и Дахран входит в состав столичного округа Даммам. Эль-Хубар — важнейший центр нефтяной промышленности, крупнейший нефтеналивной порт, в котором расположено множество нефтеперерабатывающих предприятий.
            <p>
            Из Эль-Хубара проложен Мост короля Фахда — комплекс из мостов и плотин общей длиной около 26 километров, ведущий через Персидский залив, через острова Джидда и остров Умм-ан-Насан в соседний Бахрейн.`,
            coordinates: [ 26.218500, 50.197207 ],
            type: [3],
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1567449394819-af00b2318cec?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8OXxXa1pjWmtQWkJjTXx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",            
            images:[
                "https://images.unsplash.com/photo-1517458047551-6766fa5a9362?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1460533893735-45cea2212645?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1856&q=80",
                "https://images.unsplash.com/photo-1487798452839-c748a707a6b2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1495573925654-ebcb91667e78?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            ],
            url: ""  
        },  
        {
            id: 6,
            data: {id:6, title: "", post_content: ""},
            title: "Луксор",
            post_content: `Луксо́р[2] (также Лу́ксор[3]; араб. الأقصر, Эль-Уксур[4]; масри الاقصر, Loʔṣor [ˈloʔsˤoɾ], Эль-Аксур[4]; местн. Logṣor [ˈloɡsˤor], копт. Ⲡⲁⲡⲉ) — город в Верхнем Египте, на восточном берегу Нила, административный центр мухафазы Луксор с населением более полумиллиона жителей (арабы, некоторое число коптов)[5].
            <p>
            В Луксоре и вокруг города находятся некоторые из важнейших археологических мест Египта. Из-за значимости и обилия памятников древнеегипетской архитектуры туризм играет важнейшую роль в городе, который посещают сотни тысяч туристов в год. В Луксоре имеется международный аэропорт, вокзал одной из первых в стране и Африке железных дорог, грузовой речной порт и пристани крупных круизных и малых прогулочных и рыбацких судов. 
            <p>
            Для большинства путешествий по Нилу Луксор является стартовой или конечной точкой.`,
            coordinates: [ 25.706398, 32.630905 ],
            type: [1],
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1662470597845-8ad58cf83a13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1936&q=80",            
            images:[
                "https://plus.unsplash.com/premium_photo-1677833638083-6e5138b718c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDh8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1666913804609-0a39f06cd4de?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1633532859638-6e3a9a951bd4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE5fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1458078066823-201a0fe98c8a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1960&q=80",
            ],
            url: ""  
        },  
        {
            id: 7,
            data: {id:7, title: "", post_content: ""},
            title: "микрорайон Авиаторов",
            post_content: `Улица в Западном административном округе Москвы на территории района Солнцево.
            <p>
            Начинается от Производственной улицы и проходит на юго-восток, слева к ней примыкают улица Богданова, Попутная улица, Проектируемый проезд № 5032, улица Щорса и Волынская улица, справа — улица Юлиана Семёнова. Переходит в Центральную улицу посёлка Румянцево. `,
            coordinates: [ 55.824072, 37.938807 ],
            type: [3],
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1568685002018-36582c6276da?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",            
            images:[
                "https://images.unsplash.com/photo-1533375954403-dcc42d37d33a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1417721955552-a49ac2d334e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1458127059791-c4e6a3c10fa6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1515859033836-a0a913c0f94a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDZ8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            ],
            url: ""  
        },  
        {
            id: 8,
            data: {id:8, title: "", post_content: ""},
            title: "Балашихинское шоссе",
            post_content: `Балашихинское шоссе начинается на 20-м километре Щёлковского шоссе за пересечением с Акуловским водоканалом Восточной водопроводной станции, в виде ответвления под острым углом с южной стороны Щёлковского шоссе. У начала Балашихинского шоссе справа расположен пост ДПС, а слева — автозаправочная станция. Недалеко, к югу от поста ДПС, в смешанном лесном массиве расположено небольшое озеро Дилёво.
            <p>
            Трасса шоссе проходит через лесной массив, в направлении с запада на восток, несколько отклоняясь к югу. На первой трети трассы имеются два поворота, правый и левый (каждый около 45 градусов), затрудняющие видимость и осложняющие движение.
            <p>
            С южной стороны, в районе остановок общественного транспорта «ДОЗ», к шоссе перпендикулярно примыкает улица Орджоникидзе.
            <p>
            Балашихинское шоссе в двух местах пересекается подъездными железнодорожными путями с нормальной колеёй, начинающимися от станции Балашиха. Западный переезд с путями был ликвидирован в связи со строительством новых жилых кварталов осенью 2010 года. По трассе разобранных рельсовых путей проложили коллекторы к новому микрорайону.
            <p>
            Балашихинское шоссе является границей между микрорайонами города Балашиха (с севера) и Горенским лесопарком (с юга).`,
            coordinates: [ 55.825853, 37.917510 ],
            type: [3],
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1642275964380-3b02ceac33ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",            
            images:[
                "https://plus.unsplash.com/premium_photo-1677620306166-ac82dbf1af7f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE2fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1426122402199-be02db90eb90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1500039436846-25ae2f11882e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1531137199527-9546e8290fd4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwyNTg4Nzg1fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            ],
            url: ""  
        },   
        {
            id: 9,
            data: {id:8, title: "Салтыковка", post_content: ""},
            title: "Салтыковка",
            post_content: `Первоначально территория современной Салтыковки являлась частью соседнего села Никольское. С XVI в. этими землями владел княжеский род Долгоруких (Долгоруковых). Благоустройство Никольского было начато князем Юрием Алексеевичем Долгоруковым.
            <p>
            Долгоруковы построили на реке Серебрянке каскад прудов, плавно переходивших один в другой. Берега прудов были украшены павильонами-купальнями. Из земли, оставшейся после сооружения одного из прудов, Жёлтого, по приказу Долгоруковых насыпали множество небольших холмов, на которых разбили парк. Были построены дворец и службы
            <p>
            Салтыковы
            <p>
            После брака княжны Натальи Владимировны Долгоруковой с Николаем Ивановичем Салтыковым усадьба перешла к княжеской фамилии Салтыковых в качестве приданого. С 1830 года принадлежало их внуку, князю Петру Дмитриевичу, и его жене Вере Фёдоровне, урождённой Стемпковской.
            <p>
            В 1863 году, во время строительства железной дороги Москва — Нижний Новгород по просьбе князя Петра Дмитриевича был построен полустанок, который первоначально именовался «Платформа Никольское», по названию деревни, расположенной с юга от железной дороги[4]. Впоследствии именем «Никольское» стала называться другая платформа, расположенная ближе к Москве, а прежнюю станцию назвали «Салтыковская», по имени князя. С северной стороны железной дороги начали строиться преимущественно дома служащих, железнодорожников. А южную часть облюбовали москвичи, которые стали возводить здесь дачи и называть это место Ново-Сокольники. 
            <p>    
            В конце концов две части объединились под названием Салтыковка`,
            coordinates: [ 55.759105, 37.921912 ],
            type: [2],
            placeTypes:[],
            thumbnail:"https://images.unsplash.com/photo-1581986595808-6ed3dd594f5b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",            
            images:[
                "https://images.unsplash.com/photo-1517792844039-e52afb564132?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8NnwyNTg4Nzg1fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1499856871958-5b9627545d1a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1526479742-d0b3103fede0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8OXwyNTg4Nzg1fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1646399590541-213bbe217677?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEwfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
            ],
            url: ""  
        }, 
    ]

    return _places.map(place => {
        const placeType = placeTypes().filter(pt => place.type.includes( Number(pt.id) ))
        return {...place, placeTypes: placeType}
    })
}