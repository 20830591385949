import React, { useEffect, useState } from 'react' 
import {ILayoutBodyProps} from '@/libs/interfaces/layouts'
import LayoutBodyStatic from './LayoutBodyStatic'
import { Loading } from '@/libs/useful'
import actions from 'src/modules/pe-basic-module/data/actions'
import { GET_DICTIONARY_ACTION } from '@/modules/pe-basic-module/data/actionTypes'

const LayoutBodyExtended = (props: ILayoutBodyProps) => {
  const [isLoad, setIsLoad] = useState<boolean>(true)
  useEffect(() => {
    // console.log("INIT extended")
    actions(GET_DICTIONARY_ACTION, {language: "ru-RU"}).finally(() => setIsLoad(false))
  }, [])
  if(isLoad)  return <div className="layout-state h-100 w-100">
    <Loading />
  </div>
  return <LayoutBodyStatic /> 
}


export default LayoutBodyExtended