import { DIALOG_SIZE, ILayoutData, IMenuItem, IRoutingData } from "@/libs/interfaces/layouts"
import { defaultMenuItem, default_menu } from "@/libs/layouts"
import { ClipboardCopyBtn, ClipboardPasteBtn } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Button, Collapse, Position, Tooltip } from "@blueprintjs/core"
import { useState } from "react"
import { IAdminMenuStore, useAdminMenuStore } from "../../data/store"
import AddMenuGroup from "./AddMenuGroup"
import CurrentMenuEngine from "./CurrentMenuEngine"
import SelectMenuGroup from "./SelectMenuGroup"
import { changeRoute } from "../../data/layoutsClone/updateRoutings"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import MenuDrawer from "./MenuDrawer" 

const Left = (): JSX.Element => { 
    const currentMenuGroup: string = useAdminMenuStore((state: IAdminMenuStore) => state.currentMenuGroup)
    const [isChooserOpen, setIsChooserOpen] = useState<boolean>(false)

    const layouts: ILayoutData = useAdminMenuStore((state: IAdminMenuStore) => state.layoutsClone)

    const onDeleteMenuGroup = () => {

    }
    const onAddMenuGroup = (name: string) => {

    }
    const paste = (data: IRoutingData) => {

    }
    const onSelectMenuGroup = (area: string) => { 
        useAdminMenuStore.setState({ currentMenuGroup: area })
    }
    const onVisibleChange = (isHidden: boolean, item: IMenuItem) => { 
        changeRoute( item.id, {...item, is_hidden: isHidden} )
    }
    const onAddMenuItem = () => {
        const layouts: any = useAdminMenuStore.getState().layoutsClone
        const rt = layouts.routing
        rt[ currentMenuGroup ] = [
            ...rt[ currentMenuGroup ],
            defaultMenuItem( currentMenuGroup )
        ]
        useAdminMenuStore.setState({ layoutsClone: {...layouts, routing: rt} })
    }
    return <div>
        <div className=' mb-2 z-index-100'>
            <div className="d-flex justify-content-between ">
                <div
                    className='title mx-3 mb-3 text-secondary text-center pointer flex-grow-100'
                    onClick={() => setIsChooserOpen(!isChooserOpen)}
                >
                    {__('Choose menu group')}
                    <i className={`ml-3 fas fa-caret-${!isChooserOpen ? "down" : "up"} `} />
                </div>
                <Tooltip content={ __( "Edit sitemap") } position={Position.TOP} >
                    <ConfirmButton
                        buttonMinimal
                        buttonIcon="edit"
                        dialogClasssName={DIALOG_SIZE.LARGE}
                        onConfirm={() => {}}
                    >
                        <MenuDrawer />
                    </ConfirmButton>
                </Tooltip>
                <ClipboardCopyBtn data={layouts} position={Position.TOP} label="Copy routings" />
                <ClipboardPasteBtn paste={paste} position={Position.TOP} label="Pasye routings" />
                <AddMenuGroup onAddMenuGroup={onAddMenuGroup} />
            </div>
            <Collapse isOpen={isChooserOpen} >
                <div className=' d-block pr-4 pl-3'>
                    <SelectMenuGroup
                        onSelectMenuGroup={onSelectMenuGroup}
                        currentMenuGroup={currentMenuGroup}
                    />
                </div>
            </Collapse>
            <div className="display-7 pt-4 mx-3">
                {__(getCurrentMenu({ value: currentMenuGroup })?.title || currentMenuGroup)}
            </div>
            <div className="mx-3 mb-3">
                {__(getCurrentMenu({ value: currentMenuGroup })?.description)}
            </div>
        </div>
        <div className='mx-2'>
            <CurrentMenuEngine
                current={currentMenuGroup}
                items={layouts.routing[currentMenuGroup] || []}
                onVisibleChange={onVisibleChange}
            />
                <div className="d-flex justify-content-end">
                    <Tooltip  content={ __( "Add Menu item" ) } >
                        <Button minimal icon="plus" onClick={onAddMenuItem} />
                    </Tooltip>
                </div>
            
        </div>
        <div>
            {
                !Object.keys(default_menu()).filter(e => e === currentMenuGroup).length
                &&
                <div
                    className='btn btn-link btn-sm text-secondary'
                    onClick={onDeleteMenuGroup}
                >
                    {__('Delete menu group')}
                </div>
            }
        </div>
    </div>
}

export default Left


interface ICurrentMenu {
    value: string
}

export const getCurrentMenu = ({ value }: ICurrentMenu) => {
    return default_menu()[value]
}