import { Tag } from "@blueprintjs/core"
import Moment from "react-moment"
import { NavLink } from "react-router-dom"
import { IFeedProps } from "src/modules/pe-basic-module/views/core/FeedComponent"
import { ILand } from "src/modules/pe-fest-module/data/interfaces"
import { eventTypes } from "src/modules/pe-topos-module/data/mocks/eventTypes"
import EventTypeLabel from "src/modules/pe-topos-module/views/labels/EventTypeLabel"
import { getSingleRoute } from "src/libs/layouts"
import { LayoutIcon } from "src/libs/useful"
import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface IFestivalButtonProps extends IFeedProps {
    item: ILand
}

const EventCard = (props: IFestivalButtonProps): JSX.Element => { 
    let colClass = " col-12 " 
    if( props.params?.col )
    {
        switch(parseInt( props.params?.col ))
        {
            case 2:
                colClass = " col-md-6 "
                break;
            case 3:
                colClass = " col-md-4 "
                break;
            case 4:
                colClass = " col-md-3 "
                break;
            case 1:
            default:                
                colClass = " col-12 "
                break;

        }
    }
    const routeSingle = getSingleRoute("Event") 
    const url = routeSingle ? `/${routeSingle.route}/${props.item.id}` : "/" 
    return <Card 
        icon={props.item.icon}
        thumbnail={props.item.defaultThumbnail}
        status={props.item.status}
        title={props.item.name}
        startDate={props.item.startDate}
        finishDate={props.item.finishDate}
        description={props.item.description}
        site={props.item}
        url={ url }
        colClass={colClass}
    /> 
}
export default EventCard

const Card = (props: any) : JSX.Element => { 
  return <div className={`p-0 ${props.colClass}`}> 
    <NavLink to={props.url} >
      <div className="card pe-light text-center text-secondary border-0 card-top-img" 
        style={{ 
          width: "100%", 
          height: 380,
          minHeight: 125,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${ props.thumbnail })`
        }}
      >
        <div className="position-absolute top left w-100 d-flex flex-column z-index-1 untouchble" style={{ height: 330 }}>
            <EventTypeLabel 
              item={{...props.site, eventTypes: eventTypes().filter(et => props.site.event_types?.includes(et.title))}} 
              className="position-adsolute top left m-1" 
              isShort
            />
            <div  
              className="card-image bg-transparent"
              style={{ 
                width: "100%", 
                height: 100,
                minHeight: 100, 
              }}
          >
            <div className="card-image-icon bg-light">
                <LayoutIcon
                    height={33}
                    width={33}
                    style={{ height: 33, width: 33, margin: 7 }}
                    src={props.icon}
                />
            </div>
          </div>
          <div className="flex-grow-100"></div>
          <div className="card-body d-flex flex-column align-items-center ">  
            <div className="card-text px-3 pb-3 w-100 flex-centered" >
              {
                props.startDate && <Tag minimal round className="mr-3 px-3 text-light"> 
                <Moment  locale="ru" format="D.MM.YYYY" >
                  { parseInt(props.startDate) * 1000 }
                </Moment>
              </Tag>
              }
              {
                props.finishDate && <Tag minimal round className="px-3 text-light">
                  <Moment  locale="ru" format="D.MM.YYYY" >
                    { parseInt(props.finishDate) * 1000 }
                  </Moment>
                </Tag>
              }
            </div> 
            <div className="card-text px-3 pb-2 " dangerouslySetInnerHTML={{ __html: __(props.description) }}/>
            <h4 className="card-title p-0 mb-1">
              <span className="py-1 px-3 " dangerouslySetInnerHTML={{ __html:__(props.title) }}/>
            </h4> 
          </div>
        </div> 
      </div>
    </NavLink>
  </div>
}