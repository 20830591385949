import React, { useState } from 'react' 
import { IDataType } from "@/libs/interfaces/layouts"
import { Card, Collapse, Tag } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'
import { modules } from '@/libs/layouts'

interface IDataTypeProps {
    item: IDataType
    title: string
    i: number
}
const DataTypeSingle = ({item, title, ...props}: IDataTypeProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onOpen = () => setIsOpen(!isOpen)       
    return <Card className="my-2">
        <div className="pointer py-3 d-flex align-items-center " onClick={onOpen} >
            <div>
                <img 
                    src={ modules()[ item.module ]?.icon || "https://gitlab.com/uploads/-/system/project/avatar/33048044/PE_logo_large.png"}
                    className='w_45 mr-3 '
                    alt={ title }
                />  
            </div>
            <div className=" flex-grow-100">
                <div className="lead">
                    { __( item.plural ) } 
                </div> 
                <div className="lead flex-grow-100 small opacity_5 ">
                    { modules()[ item.module ]?.title || item.module }  
                </div>
            </div>
            <div className="mx-2 d-flex  align-items-center ">
                { title }
            </div>
        </div>
        <Collapse isOpen={isOpen} className=''>
            <div className="d-flex flex-wrap ">
            { 
                Object.keys(item.apollo_fields)
                    .filter(key => !item.apollo_fields[ key ].hidden )
                    .map((key, i) => {
                        return <Tag className='px-3 py-1 m-1' round>
                            { __(item.apollo_fields[ key ].title) }
                        </Tag>
                    })
            }
            </div> 
        </Collapse>
        
    </Card> 
} 

export default DataTypeSingle