
import BasicState from "src/libs/basic-view"
import ErmakMapEditorForm from "./ermak-map-editor/ErmakMapEditorForm"

export default class ErmakMapEditorView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <ErmakMapEditorForm {...this.props} />  
        </div>
    }
}