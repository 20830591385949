import Layouts from "src/libs/layouts"

const defaultRoutings = (): any[] => {
    const is_left: boolean = !!Layouts().template.is_left
    const routing: any[] = [ 
        {
            title: "Sign in",
            icon: "fas fa-sign-in-alt",
            route: "login",
            component: "LoginView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "404",
            icon: "fas fa-sign-in-alt",
            route: "404",
            component: "NoMatchView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Register User",
            icon: "fas fa-sign-in-alt",
            route: "register",
            component: "RegisterView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Recover password",
            icon: "fas fa-trash-restore",
            route: "remember",
            component: "RememberPasswordView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Reset password",
            icon: "",
            route: "reset",
            component: "ResetPasswordView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Restore password",
            icon: "fas fa-cog",
            route: "restore/:id/:code",
            component: "FinishRestorePasswordView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Verify password",
            icon: "fas fa-cog",
            route: "verify/:id/:code",
            component: "VerifyUserView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "My Profile",
            icon: "fas fa-user",
            route: "profile",
            component: "ProfileView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Settings",
            icon: "fas fa-cog",
            route: "cog",
            component: "SettingsView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Search results",
            icon: "fas fa-search",
            route: "search/:id",
            component: "SearchView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Change e-mail",
            icon: "fas fa-envilope",
            route: "changeemail/:id/:code",
            component: "ChangeEmailView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
        {
            title: "Finish Telegram login",
            icon: "fab fa-telegram",
            route: "tg-login",
            component: "TGLoginFinishView",
            module: "pe-basic-module", 
            is_cabinet: false,
            is_left, 
            menu: "extended_routes"
        },
    ]
    return routing
}
export default defaultRoutings