
import BasicExtendView from '@/libs/basic-view/BasicExtendView'
import MenuForm from './menu/MenuForm'
import Left from './menu/Left' 
import MenuHead from './menu/MenuHead'
import { useAdminMenuStore } from '../data/store'
import Layouts from '@/libs/layouts'

export class AdminMenuView extends BasicExtendView {  
    props: any
    stateDidMount() {
        useAdminMenuStore.setState({
            layoutsClone: JSON.parse( JSON.stringify( Layouts() ) )
        })
    }
    left = () : JSX.Element => {
        return <Left /> 
    }
    head = () : JSX.Element => {
		return <MenuHead />
	}
    center = ():JSX.Element => {  
       return <div className="w-100 mb-5">
           <MenuForm {...this.props} />
       </div>
    }
}

export default AdminMenuView 