import React from "react" 
import BasicState from "@/libs/basic-view"
import { schema } from "src/libs/layouts"; 
import { __ } from "@/libs/utilities"
import SingleDataTypeForm from "./singleData/SingleDataTypeForm";

export class SingledDataTypeView extends BasicState {
    props: any 
    getTitle = () => {
        const singled_data_type: any = schema()[this.props.singled_data_type]
        if (singled_data_type) {
            return __(singled_data_type.name)
        }
        return this.props.singled_data_type
    } 
    renderTitle = () =>  {
        return <></>
    }  
    addRender() { 
        return <SingleDataTypeForm {...this.props} />
    }
}
 
export default SingledDataTypeView