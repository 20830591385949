import { Button, ButtonGroup } from "@blueprintjs/core"

const links = [
    {
        icon: "fab fa-vk fa-2x"
    },
    {
        icon: "fab fa-telegram-plane fa-2x"
    },
    {
        icon: "fab fa-youtube fa-2x"
    },
    {
        icon: "fas fa-link fa-2x"
    }
]
const ToposLinks = () : JSX.Element => {
  return (
    <ButtonGroup fill minimal>
        {
            links.map((link, i) => <Button minimal className="p-3 hover" key={i}>
                <span className={link.icon} />
            </Button>)
        }
    </ButtonGroup>
  )  
}

ToposLinks.propTypes = {}

export default ToposLinks