import { ID } from "@/libs/interfaces/layouts";
import { IErmakMapCellType, IErmakMapType, IErmakSpace } from "../interfaces";
import { ermakMapCellTypes } from "./ermakMapCellTypes";

export const spaces = (): IErmakSpace[] => {
    let list: IErmakSpace[] = [
        {
            id: "1",
            title: "Земля (Россия)",
            post_content: "",
            thumbnail: "/assets/img/ermak/2-large.png",
            thumbnailPositionY: "80%",
            spaces: [],
            color: "#3d3d5c",
            x: 0,
            y: 0,
            z: 0,
            maps: [
                {
                    id: "1",
                    title: "Владимир-21",
                    post_content: "",
                    type: IErmakMapType.MATRIX,
                    width: 8,
                    height: 7,
                    x: 0,
                    y: 0,
                    z: 0,
                    cells: [
                        {
                            id: "0",
                            type: getMapCellType("2")
                        },
                        {
                            id: "1",
                            type: getMapCellType("1") 
                        },
                        {
                            id: "2",
                            type: getMapCellType("1"),
                            items: [
                                {
                                    title: "Объект «Подошва»",
                                    post_content: "Стационарное сооружение для приёма стратосферных автоматических спускаемых аппаратов.",
                                    thumbnail: "http://kino.wpfest.ru/wp-content/uploads/sites/91/2023/12/Sole.jpg",
                                    gallery: [
                                        {
                                            id: "tyihmghj67",
                                            title: "Внешний вид объекта «Владимир-Подошва»",
                                            post_content: "",
                                            thumbnail: "http://kino.wpfest.ru/wp-content/uploads/sites/91/2023/12/space-orbital-city-e1703317497261.png",
                                        },
                                        {
                                            id: "22232352345", 
                                            title: "",
                                            post_content: "",
                                            thumbnail: "http://kino.wpfest.ru/wp-content/uploads/sites/91/2023/12/space-orbital-city2-e1703317620653.png",
                                        },
                                        {
                                            id: "y767", 
                                            title: "",
                                            post_content: "",
                                            thumbnail: "http://kino.wpfest.ru/wp-content/uploads/sites/91/2023/12/6-large.png",
                                        },
                                        {
                                            id: "vbfyu6rt", 
                                            title: "",
                                            post_content: "",
                                            thumbnail: "http://kino.wpfest.ru/wp-content/uploads/sites/91/2023/09/6491ed8dac51408182a11968dab11ca3.jpg.jpeg",
                                        },
                                        {
                                            id: "gt7nt8u-jhuj", 
                                            title: "",
                                            post_content: "",
                                            thumbnail: "http://kino.wpfest.ru/wp-content/uploads/sites/91/2023/03/commission__terranis_v_by_vincentiusmatthew_dco05nd-fullview.jpg.jpeg",
                                        },
                                    ],
                                    id:"11",
                                }
                            ]                               
                        },
                        {
                            id: "3",
                            type: getMapCellType("2") 
                        },
                        {
                            id: "4",
                            type: getMapCellType("2") 
                        },
                        {
                            id: "10",
                            type: getMapCellType("2") 
                        },
                        {
                            id: "11",
                            type: getMapCellType("1") 
                        },
                        {
                            id: "12",
                            type: getMapCellType("1"),
                            items: [
                                {
                                    title: "База космических сил «Ласточка»",
                                    post_content: "",
                                    thumbnail: "http://kino.wpfest.ru/wp-content/uploads/sites/91/2023/12/lastochka.jpg",
                                    id:"11",
                                }
                            ]
                        },
                        {
                            id: "13",
                            type: getMapCellType("2") 
                        },
                        {
                            id: "18",
                            type: getMapCellType("2") 
                        },
                        {
                            id: "19",
                            type: getMapCellType("2") 
                        },
                        {
                            id: "20",
                            type: getMapCellType("2") 
                        },
                    ]
                },
                {
                    id: "2",
                    title: "Калязин",
                    post_content: "",
                    type: IErmakMapType.MATRIX,
                    width: 6,
                    height: 7,
                    x: 0,
                    y: 0,
                    z: 0,
                    cells: [
                        {
                            id: "1",
                            type: getMapCellType("1") 
                        },
                        {
                            id: "2",
                            type: getMapCellType("1") 
                        },
                        {
                            id: "11",
                            type: getMapCellType("1") 
                        },
                        {
                            id: "12",
                            type: getMapCellType("1") 
                        },
                    ]
                },
            ]
        },
        {
            id: "2",
            title: "Околоземная орбита",
            post_content: "",
            thumbnail: "/assets/img/ermak/6-large.png",
            thumbnailPositionY: "70%",
            spaces: [],
            color: "#1e1e2f",
            x: 0,
            y: 0,
            z: 1,
            maps: [
                {
                    id: "2-1",
                    title: "Стратосфера. Заполярье.",
                    post_content: "",
                    type: IErmakMapType.MATRIX,
                    width: 8,
                    height: 5,
                    x: 0,
                    y: 0,
                    z: 0,
                    cells: [
                        {
                            id: "0",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "1",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "9",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "10",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "18",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "19",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "27",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "28",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "36",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "37",
                            type: getMapCellType("2-1") 
                        },
                    ]
                },
                {
                    id: "2-2",
                    title: "Приполярные орбиты",
                    post_content: "",
                    type: IErmakMapType.MATRIX,
                    width: 8,
                    height: 12,
                    x: 0,
                    y: 0,
                    z: 0,
                    cells: [
                        {
                            id: "0",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "1",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "9",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "10",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "18",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "19",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "27",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "28",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "36",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "37",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "45",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "46",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "54",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "55",
                            type: getMapCellType("2-1") 
                        },
                        {
                            id: "63",
                            type: getMapCellType("2-1") 
                        },
                    ]
                },
            ]
        },
        {
            id: "3",
            title: "Луна",
            post_content: "",
            thumbnail: "/assets/img/ermak/8-large.png",
            thumbnailPositionY: "60%",
            spaces: [],
            color: "#33334d",
            x: 0,
            y: 0,
            z: 2,
            maps: [
                {
                    id: "3-1",
                    title: "Кратер Тихо",
                    post_content: "",
                    type: IErmakMapType.MATRIX,
                    width: 5,
                    height: 5,
                    x: 0,
                    y: 0,
                    z: 0,
                    cells: [
                        {
                            id: "1",
                            type: getMapCellType("3-1") 
                        },
                        {
                            id: "6",
                            type: getMapCellType("3-1")
                        },
                        {
                            id: "12",
                            type: getMapCellType("3-1")
                        },
                        {
                            id: "18",
                            type: getMapCellType("3-1")
                        },
                    ]
                }
            ]
        },
    ]
    return list
}

const getMapCellType = (id: ID) : IErmakMapCellType => {
    return ermakMapCellTypes().filter(t => t.id === id )[0]
}