import { NavLink } from "react-router-dom"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { Color, URL, WINDOW_WIDTH } from "src/libs/interfaces/layouts"
import { Dummy } from "src/libs/useful"

/* Protopia Ecosystem component */
export interface IGSLinkButtonProps {
    color: Color
    img: URL
    title: string
    path: string
    isSepia?:boolean
}
const GSLinkButton = (props: IGSLinkButtonProps) => {
    const {width} = useScreenSize()
    return width < WINDOW_WIDTH.TABLET
        ?
        <GSLinkMobileButton {...props} />
        :
        <GSLinkScreenButton {...props} />
}
export default GSLinkButton

 
const GSLinkMobileButton = (props: IGSLinkButtonProps): JSX.Element => {
    return <NavLink className="pb-5 pixel-btn" to={props.path}>
        <Dummy
            width={10}
            height={3.6}
        />
        <div 
            className={`pixel-btn-cont ${props.isSepia ? " sepian " : ""}`}  
            style={{  backgroundImage: `url(${props.img})` }}
        />
        <div className="pixel-btn-label">
            {props.title}
        </div>
    </NavLink>
} 
const GSLinkScreenButton = (props: IGSLinkButtonProps): JSX.Element => {
    return <NavLink className="col-6 pb-5 pixel-btn" to={props.path}>
        <Dummy
            width={10}
            height={10}
        />
        <div 
            className={`pixel-btn-cont ${props.isSepia ? " sepian " : ""}`}  
            style={{  backgroundImage: `url(${props.img})` }}
        />
        <div className="pixel-btn-label">
            {props.title}
        </div>
    </NavLink>
} 