import { IUser } from "@/settings/zustand"
import { IProject, IRating } from "../../data/interfaces"

interface IProps {
    members: IProject[]
    users: IUser[]
    ratings: IRating[]
}
const Specials = (props:IProps) :JSX.Element => {
  return <div>
        Specials
    </div> 
} 

export default Specials