import { IUser } from "@/settings/zustand"
import { IProject, IRating } from "../../data/interfaces"

interface IProps {
    members: IProject[]
    users: IUser[]
    ratings: IRating[]
}
const Synthesizer = (props:IProps) :JSX.Element => {
  return <div>
        Synthesizer
    </div> 
} 

export default Synthesizer