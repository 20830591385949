import { IModule } from "@/libs/interfaces/layouts"
import Layouts, { modules } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"
import { IAdminMenuStore, useAdminMenuStore } from "../data/store"

interface IProps {
    onChoose: (key:string) => void
    value: string
}
const TypeButtonGroup = (props: IProps) : JSX.Element => {
    const currentViewsSelected = useAdminMenuStore((state:IAdminMenuStore) => state.currentViewsSelected) 
    const onChoose = (key: string) => {
        props.onChoose(key)
    }
    const onSelect = (key:string) => {
        useAdminMenuStore.setState({currentViewsSelected : currentViewsSelected.includes(key)
            ?
            currentViewsSelected.filter((s: string) => s !== key)
            :
            [ ...currentViewsSelected, key ]
        }) 
    }
    return <div className="d-flex h-100 position-relative">
        <div className="w_250 border-right border-secondary">
        {
             
        }
        </div>
        <div className="w_250 position-fixed">
        {
            Object.keys( modules() ).map(key => {
                return <Button 
                    minimal 
                    fill 
                    alignText="left" 
                    active={ currentViewsSelected.includes(key) }
                    key={ key }
                    onClick={() => onSelect(key)}
                >
                    <div className="small d-flex align-items-center py-1 untouchble">
                        <img
                            src={ modules()[key].icon }
                            className="w_25 h_25 mr-2"
                            alt=""
                        />
                        { modules()[key].title }
                    </div>
                </Button>
            })
        } 
        </div>
        <div className="flex-grow-100 w-100 h-100 overflow-y-auto " style={{minHeight: 400}}>
            {
                Object.keys(Layouts().views)
                .filter( key => currentViewsSelected.includes( Layouts().views[key]?.module ) )
                .map( key => {
                    const module: IModule = modules()[ Layouts().views[key]?.module ] || {}
                    return <Button 
                        key={key}
                        minimal 
                        fill 
                        active={props.value === key}
                        className="btn-stretch p-2 border-bottom border-secondary"
                        onClick={() => onChoose(key)}
                    >
                        <div className="d-flex align-items-center w-100">
                            <div className="small text-center hint hint--top" data-hint={ __( module?.title ) }>
                                <img
                                    src={ module?.icon }
                                    className="w_45 h_45"
                                    alt=""
                                />
                            </div>
                            <div className="pl-3 flex-column w-100 flex-grow-100">
                                <div className="lead">
                                    {  __(Layouts().views[key]?.title) }
                                </div>
                                <div className="small  text-force-contrast">
                                    {  __(Layouts().views[key]?.description) }
                                </div>
                            </div>
                        </div> 
                    </Button>
                })
            }
        </div>
    </div>
} 

export default TypeButtonGroup