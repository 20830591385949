export const DELETE_EXHORTATION = (data: any) => {
    const site = data.landId
        ?
        ` land_id: "${ data.landId }" `
        :
        ``
    return `
        mutation deletePEFestExhortation {
            deletePEFestExhortation( 
                    id: "${data.id || "-1"}",
                    ${ site }
            )
        }
    `
}