import { ID, POST_STATUS } from "@/libs/interfaces/layouts"
import { IProject } from "../../data/interfaces"
import { Button, Intent } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface IMemberStatusFormProps {
    item: IProject
    onChangeStatus: (member_id: ID, post_status: POST_STATUS) => void
}
const MemberStatusForm = ({item, onChangeStatus, ...props}: IMemberStatusFormProps): JSX.Element => {
    return item.post_status === POST_STATUS.DRAFT
    ?
    <Button 
        minimal 
        fill 
        intent={Intent.DANGER} 
        rightIcon="eye-open" 
        className="px-5"
        onClick={() => onChangeStatus(item.id, POST_STATUS.PUBLISH)}
    >
        {__("Do show")}	
    </Button>
    :
    <Button 
        minimal 
        fill 
        intent={Intent.SUCCESS} 
        rightIcon="eye-off" 
        className="px-5"
        onClick={() => onChangeStatus(item.id, POST_STATUS.DRAFT)}
    >
        {__("Do hide")}	
    </Button>
}
export default MemberStatusForm