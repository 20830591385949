
import { setContext } from 'apollo-link-context';
import { ErrorResponse, onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import gql from "graphql-tag";
import { REFRESH_JWT_TOKEN } from "./qraphql";
import { useAppoloStore } from './zustand';

const {ApolloClient, InMemoryCache, ApolloLink, Observable} = require('@apollo/client')



export function client( config: any ): any {
  const uploadLink = createUploadLink({
    uri: config.server_url ? config.server_url : null,
    // fetch: new Promise<any>((resolve, reject) =>
    //   fetch( config.server_url )
    //   .then( response => response.json() )
    //   .then( data => {
    //     console.log( data )
    //     resolve( data )
    //   }) 
    // )
  })
  const queryLink = uploadLink
  const cache = new InMemoryCache()
  const authLink = setContext((_, { headers }) => {
      let new_headers
      if (localStorage.getItem("token")) 
      {
        const token = localStorage.getItem("token") ? localStorage.getItem("token") : ""
        new_headers = {
          ...headers,
          authorization: `Bearer ${token}`,
        }
      } 
      else if (localStorage.getItem("client_token")) 
      {
        const token = localStorage.getItem("client_token") ? localStorage.getItem("client_token") : ""
        new_headers = {
          ...headers,
          authorization: `Bearer ${token}`,
        }
      } 
      else 
      {
        // actions( USER_INFO_ACTION, {} )
        new_headers = {
          ...headers,
        }
      }
      return { headers: {...new_headers,} }
  }) 
  const errorlink: any = onError(( props: ErrorResponse ): any => { 
      // if(props.networkError && props.networkError.statusCode === 401)
      // { 
      //     console.log( props.networkError )  
      // }
      if (props.graphQLErrors) 
      {
        for (const err of props.graphQLErrors) 
        {
          // console.log( err )
          if (err.extensions) {
            switch (err.extensions.code) 
            {            
              case "UNAUTHENTICATED": 
                if (localStorage.getItem("token")) 
                { 
                  localStorage.removeItem("token")
                  
                  // TODO: move to serverside cookie httponly
                  const refresh_token = localStorage.getItem("refresh")
                  const mutation = gql`${ REFRESH_JWT_TOKEN( refresh_token ) }` 
                  
                  // eslint-disable-next-line no-loop-func
                  return new Observable((observer: any) => {
                    apolloClient.mutate({ 
                      mutation, 
                      variables: { } 
                    })
                      .then( (result: any) => {
                          // console.log( result )
                          localStorage.setItem( "token", result.data.refresh.access_token, )
  
                          // TODO: move to serverside cookie httponly
                          localStorage.setItem( "refresh", result.data.refresh.refresh_token, )
                          
                          const oldHeaders = props.operation.getContext().headers;
                          props.operation.setContext({
                            headers: {
                              ...oldHeaders,
                              authorization: `Bearer ${result.data.refresh.access_token}`,
                            },
                          });
                          // return forward( operation )                  
                          // return Observable.of(operation);
                          // apolloClient.query( { query: gql`${operation.query.loc.source.body}` } )
                        },
                        (err: Error) => {
                          console.error( err )
                        })
                          .then((res: any) => {
                            console.log(res) 
                            window.location.reload()
                          })
                  })
                }
                else
                {
                  // пользователь разлогинен
                  localStorage.setItem( "token", '' )
                  localStorage.setItem( "refresh", '' ) 
                  // setTimeout(() => {
                  //   window.location.reload()
                  // }, 1000);
                } 
                break;
              case "INTERNAL_SERVER_ERROR":
                console.log(err)
                break
              case "FORBIDDEN":
                console.log(err)
                break
              default:
                console.log( props )
                // AppToaster.show({
                //   intent: Intent.DANGER,
                //   timeout: 10000,
                //   icon: "error",
                //   message: __("Error "),
                // })
                break
            }
          } 
          else 
          {
            // AppToaster.show({
            //   intent: Intent.WARNING,
            //   icon: "warning",
            //   message: __(err.message),
            // })
          }
        }
      }
    }
  )
    
  const defaultOptions: any = {
    watchQuery: {
      fetchPolicy: "network-only",
      // errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: "network-only",
      // errorPolicy: 'all',
    },
  }
  const apolloClient: any = new ApolloClient({
    link: ApolloLink.from([
      errorlink,
      authLink,
      queryLink,

    ]),
    cache,
    // defaultHttpLink: false,
    defaultOptions,
    // fetchOptions: {
    //   credentials: "include",
    //   mode: "no-cors",
    // },

  })
  // console.log( apolloClient )
  useAppoloStore.getState().setClient( apolloClient )
  return apolloClient
} 
