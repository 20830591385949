import { Navigate, Route, Routes } from "react-router"
import LudensModel from "./LudensModel"
import { PELudensEngine } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface ILudensModelFormProps {
    modelTitle: string
    engines : PELudensEngine[]
}
const LudensModelForm = (props: ILudensModelFormProps): JSX.Element => {
    return <Routes>
        <Route element={<LudensModel engines={props.engines} modelTitle={props.modelTitle} />} >
            <Route path="*" element={<Navigate to={props.engines[0]?.path || ""} />} />
            {
                props.engines.map(engine => {
                    return <Route 
                        key={ engine.id }
                        path={ `${engine.path}/*` } 
                        element={ engine.component } 
                    />
                })
            }
            
        </Route>
    </Routes>
}
export default LudensModelForm