import { areas } from "@/libs/layouts"
import { Loading } from "@/libs/useful"
import { __ } from "@/libs/utilities" 
import { Suspense, useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import Widget from "./Widget"
import { useParams } from "react-router"
import { ID, IWidgetArea } from "@/libs/interfaces/layouts" 


const WidgetsForm = (props:any) : JSX.Element => {
  
  const [area, setArea] = useState<IWidgetArea>( {} as IWidgetArea )
  const [currArea, setCurrArea] = useState<IWidgetArea>( {} as IWidgetArea ) 
  const params = useParams()
  const widgetAreaId : ID = params.widgetAreaId || ""
  useEffect(() => {
    const waTitle = Object.keys( areas() || {} ).filter( w => {
      return w === widgetAreaId.toString()
    })[0]
    if( waTitle ) { setArea( areas()[ waTitle ] ) }
  }, [ params ])
  const onUpdate = () => {

  }
  const onRemoved = () => {

  }
  const onAdd = () => {

  }
  const onDragEnd = () => {

  }
  const _widgets = !!area.area?.length 
    && 
    area.area?.map((item, index) => (
      <Draggable key={index} draggableId={item.component} index={index}>
        {
          (provided, snapshot) => {
            return <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Widget 
                area={area}
                item={item} 
                n={index} 
                onUpdate={ onUpdate} 
                onRemoved={ onRemoved} 
              />
            </div>
          }
        }
      </Draggable>
    )) 
  return (
    <div className="admin-widgets-container-main position-relative  h-100 overflow-y-auto-thin">
      <div className="admin-widgets-container">
        {
          !!area.area?.length 
            ?
            <Suspense fallback={<Loading />}>
              <DragDropContext onDragEnd={ onDragEnd }>
                <Droppable droppableId="droppable">
                  {
                    (provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {_widgets}
                      </div>
                    )
                  }
                </Droppable>
              </DragDropContext>
            </Suspense> 
            : 
            <div className="alert alert-secondary m-2  min-width-400">
              {__("No elements exist")}
            </div>
        } 
      </div> 
      {/* <div className="admin-widgets-add">
        <Popover 
          content={(
            <div className="p-0">
              <div className="p-0 max-height-250 overflow-y-auto">
                <div style={{ overflowX: "hidden", overflowY: "auto", marginBottom: 10 }}>
                  {wList}
                </div>
              </div>
            </div>
          )}
        >
          <div className="btn btn-link text-secondary btn-sm mt-2" >
            {__("Add widgets")}
          </div>
        </Popover>
        <Popover 
          content={(
            <div className="p-0">
              <div className="p-0 max-height-250 overflow-y-auto">
                <div style={{ overflowX: "hidden", overflowY: "auto", marginBottom: 10 }}>
                  {eList}
                </div>
              </div>
            </div>
          )}
        >
          <div className="btn btn-link text-secondary btn-sm mt-2" >
            {__("Add extentions")}
          </div>
        </Popover>
      </div> */}
    </div>
  )
}

export default WidgetsForm