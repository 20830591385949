import { IState, useMainStore } from "@/settings/zustand"
import { IDictionary } from "@/libs/interfaces/layouts"
import { modules } from "@/libs/layouts"
import { ClipboardCopyBtn, ClipboardPasteBtn } from "@/libs/useful"
import { __, getAllDictionaries, sprintf } from "@/libs/utilities"
import { Position } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { ALL_DICTIONARIES_TAG, CORRECT_DICTIONARIES_TAG } from "../../data/interfaces"
import { IAdminStore, useAdminStore } from "../../data/store"
import AddWord from "./AddWord"

/* Protopia Ecosystem component */
export interface IHeadProps {

}
const Head = (props: IHeadProps): JSX.Element => {
    const currentDictModule : string = useAdminStore((state: IAdminStore) => state.currentDictModule ) || ALL_DICTIONARIES_TAG
    const [ newWords, setNewWords ] = useState< IDictionary>(useMainStore( (state:IState) => state.dictionary )) 
    const [ words, setWords ] = useState< IDictionary>({})
    useEffect(() => {
        try {
            const dict : IDictionary = currentDictModule === ALL_DICTIONARIES_TAG
                ?
                getAllDictionaries()    
                :
                require( `src/modules/${ currentDictModule }/lng/ru-RU.json` )
            setWords( dict )
        } catch(e) { }   
    }, [ currentDictModule ]) 

    const onPaste = (data: any) => {
        let newDict: IDictionary = {}
         Object.keys(data)
            .forEach(wrd => {
                if( data[wrd] !== words[wrd] || newWords[wrd] === data[wrd] ) {
                    newDict[wrd] = data[wrd] 
                }
            }) 
        setNewWords({ ...newDict })
        useMainStore.setState({ dictionary: newDict })
    }
    const onAdd = (origin:string, translate: string) => { 
        console.log({ ...newWords, [origin]: translate  })
        setNewWords({ ...newWords, [origin]: translate  })
        useMainStore.setState({ dictionary: { ...newWords, [origin]: translate  } })
    }

    return currentDictModule
        ?
        <div className="w-100 h-100 flex-centered flex-column">
            <div dangerouslySetInnerHTML={{ 
                __html: currentDictModule === ALL_DICTIONARIES_TAG
                    ?
                    __( "All dictionaries" ).toUpperCase()
                    :
                    currentDictModule === CORRECT_DICTIONARIES_TAG
                        ?
                        __( "Corrects of Dictionaries" ).toUpperCase()
                        :
                        sprintf(
                            __("Correction of the vocabulary of the <span class='lead font-weight-bold'>«%s»</span> module"),
                            __(modules()[currentDictModule]?.title) || ""
                        )
            }} /> 
            <div className="d-flex">
                <ClipboardCopyBtn 
                    data={{ ...words }}
                    type="icon"
                    position={Position.TOP}
                    label={__("Copy to clipboard")}
                />
                <ClipboardPasteBtn
                    paste={onPaste}
                    position={Position.TOP}
                    label={__("Paste from clipboard")}
                />
                {
                    // currentDictModule === CORRECT_DICTIONARIES_TAG &&
                        <AddWord onAdd={onAdd}/>
                }
            </div> 
        </div>
        :
        <></>
}
export default Head