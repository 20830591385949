import { component_url } from "src/libs/utilities/links"
import { Button, Intent } from "@blueprintjs/core"
import { NavLink } from "react-router-dom"
import { ILand } from "src/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, useFestDataStore } from "src/modules/pe-fest-module/data/store"
import { Widgets, __ } from "src/libs/utilities" 

/* Protopia Ecosystem component */ 
const FabulaAboutWidget = (props: any): JSX.Element => {
    const site: ILand = useFestDataStore((state: IFestDataStor) => state.site)
    if( !site.event_types.includes("Role play game") && !site.event_types.includes("Labyrinth") ) {
        return <></>
    }
    
    return <div className='pe-fabula-about-widget-container'>
        <div className=" light-colored ">
            <div className="container ">
                <div className="row justify-content-center mt-0 py-4 px-md-5 px-2">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="role_descr">
                            {__("Storytelling last news")}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb-0 pb-3  px-2">
                    <NavLink 
                        to={ `${component_url("FestivalView")}event/${site.id}/story` }
                    >
                        <Button intent={Intent.DANGER} >
                            <div className="p-4 text-uppercase">
                                {__("Start your journey")} 
                            </div>
                        </Button>
                    </NavLink>
                </div>
            </div>
        </div> 
        <Widgets 
            areaName={ "fabula-about" }
            data={ {} }
            defArea={ null }
        />
    </div>
}
export default FabulaAboutWidget