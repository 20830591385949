import React from "react" 
import BasicState from "src/libs/basic-view"
import ToposMapForm from "./map/ToposMapForm" 
import "../assets/css/style.scss"

export default class ToposMapView extends BasicState {
    props: any
    addRender = () => {
        return <ToposMapForm { ...this.props } onOpenLogin={ this.onOpenLogin } />  
    }
}