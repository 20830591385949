import { ID, IWidgetArea } from "@/libs/interfaces/layouts"
import { areas } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import AddWidgetForm from "./AddWidgetForm"

const WidgetsHead = () : JSX.Element => {
    const params = useParams()
    const [area, setArea] = useState<IWidgetArea>( {} as IWidgetArea )
    const widgetAreaId : ID = params.widgetAreaId || ""
    useEffect(() => {
        const waTitle = Object.keys( areas() || {} ).filter( w => {
          return w === widgetAreaId.toString()
        })[0]
        if( waTitle ) { setArea( areas()[ waTitle ] ) }
      }, [ params ])
    
    const onAdd = () => {

    }
    return <div className=" text-center w-100">
      <div className="lead">
        { __( area?.title ) } 
      </div> 
      <div className="title small">
        { __( area?.description ) } 
      </div> 
      <AddWidgetForm onAdd={onAdd} />
    </div> 
} 

export default WidgetsHead