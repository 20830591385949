

import { Button, Dialog, Icon } from "@blueprintjs/core"
import { useState } from "react"
import { Dummy } from "src/libs/useful"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { ICellProps } from "./Cell"

const CellPartner = (props: ICellProps) :JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    return <>
        <div className="cell" data-cell-id={ props.item.id }> 
                    
        </div>
        <Dialog
            isOpen={isOpen}
            onClose={() => setIsOpen(!isOpen)}
            className={DIALOG_SIZE.LITTLE}
        >
            <div className="p-5">
                <Dummy width={100} height={100} />
                
            </div>
            <Button minimal onClick={() => setIsOpen(!isOpen)} className="position-absolute top right px-3 py-2">
                <Icon icon="cross" size={20} color="#FFFFFF"/>
            </Button>
        </Dialog>
    
    </>
} 

export default CellPartner 