import { IUser, useMainStore } from "@/settings/zustand"
import { IMenuItem } from "@/libs/interfaces/layouts"
import { moduleExists } from "@/libs/layouts"
import { isCapability } from "@/libs/utilities"

const isRouteChildren = (item: IMenuItem): boolean => {
    const user: IUser = useMainStore.getState().user
    return (item.children || []).filter( ch => {
        const isHidden: boolean = !ch.is_hidden
        const isModuleExists: boolean = moduleExists( ch.module )
        const isUserRole: boolean = isCapability( ch.capability, user )
        return ( isHidden && isModuleExists) || isUserRole
    }).length > 0
}
export default isRouteChildren

export const isRouteExists = (item: IMenuItem): boolean => {
    const user: IUser = useMainStore.getState().user
    const isHidden: boolean = !item.is_hidden
    const isModuleExists: boolean = moduleExists( item.module )
    const isUserRole: boolean = isCapability( item.capability, user )
    const isOnlyLogged: boolean = (!item.islogged || !!user.id)

    return isHidden && isModuleExists && !isUserRole && isOnlyLogged
}

