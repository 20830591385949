import { useState, useEffect } from 'react' 
import { Button, Dialog, Intent } from '@blueprintjs/core' 
import HelpContent from './HelpContent'
import helps from './helps'
import { __, sprintf } from '@/libs/utilities'
import { useLocation } from 'react-router'
import { IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'

const parseGetParams = (gets: string) =>
{
    let params = gets.substring(1).split("&")
    return params.map(p => p.split("="))
}
const FestHelp = () :JSX.Element => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const [isOpen, setOpen] = useState( false )
    const [phase, setPhase] = useState("")
    const location = useLocation()
    useEffect(() =>
    {
        //console.log(props)
        setTimeout(() => setStartOpen(), 800)
        
    }, [])
    const setStartOpen = () =>
    {
        if( !location?.search )  return  
        const gets = parseGetParams(location?.search)    
        const _phase = gets.filter(p => p[0] === "phase")[0]
        const isPhaseEnabled = _phase && Object.keys(helps()).filter(h => h === _phase[1]).length > 0
        if(isPhaseEnabled)   setPhase(_phase[1])
        // console.log( isPhaseEnabled )
        setOpen( gets.filter(p => p[0] === "help").length > 0 && isPhaseEnabled )  
    }
    return <Dialog
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={
            <div 
                className='lead'
                dangerouslySetInnerHTML={{
                    __html: sprintf(
                        __("Welcome to the festival <span class='font-weight-bold'>«%s»</span>"), 
                        festival.title!
                    )
                }}
            />
        }
        className=" "
    >
        <div className='p-5 overflow-y-auto' style={{maxHeight:"70vh"}}>
            <HelpContent phase={phase}/>
        </div>
        <div className='d-flex p-3 justify-content-end'>
            <Button 
                intent={Intent.SUCCESS} 
                onClick={ () => setOpen(false) }
            >
                {__( "OK, thank you" )}
            </Button>
        </div>
    </Dialog>
}

export default FestHelp