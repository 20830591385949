import React, { useEffect, useState } from 'react'  
import { initArea } from '../../utilities/getWidget'
import { isMenuLeft, menu, moduleExists, template } from '../../layouts'
import { IState, useMainStore,  } from "@/settings/zustand"
import {isCapability} from "@/libs/utilities"
import PictogrammMenu from '../menuLeft/PictogrammMenu'
import { IMenuItem } from '../../interfaces/layouts'
import HierarhicalMenu from '../menuLeft/HierarhicalMenu' 

const LayoutMenuLeft = (props: any) => {
    const user = useMainStore(( state:IState ) => state.user)    
    const [isShow, setShow] = useState( true )
    useEffect(() => {
        setIcons(getMenus())
    }, [ user ]) 
    const getMenus = () => {
        const menus: IMenuItem[] = menu().menu
        //return [] 
        return menus 
            .filter( (m: IMenuItem) => !m.is_hidden && moduleExists(m.module) )
                .map((m:any, i: number) => { 
                    const isRole = isCapability(m.capability, user)
                    const isNotLogged: boolean = m.islogged === true && !user.id
                    if (isRole || isNotLogged) return "" 
                    switch (template().left_menu) {
                        case "pictogramm":
                            return <PictogrammMenu {...m}  i={i} key={i} /> 
                        case "hierarhical":
                        default:
                            return (
                                <div key={i}>
                                    <div className={ " left-menu-group m-0 p-1 " + ( isShow ? "d-block" : "hidden" )}>
                                        <HierarhicalMenu
                                            {...m}
                                            parent_route="" 
                                            i={i}
                                            level={1}
                                            key={i}
                                        />
                                    </div>
                                </div>
                            )
                    }
                })
    }
    const [icons, setIcons] = useState( getMenus() )
    return isMenuLeft()
        ?
        <div className={ "layout-menu-left " + template().left_menu }> 
            {
                initArea(
                    "layout-before-left-menu",
                    { ...props }
                )
            }            
            { icons }
            {
                initArea(
                    "layout-after-left-menu",
                    { ...props }
                )
            } 
        </div>
        :
        null
} 

export default LayoutMenuLeft
 