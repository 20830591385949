import { IPETest } from "@/modules/pe-tests-module/data/interfaces"
import { Dummy } from "src/libs/useful"
import { NavLink } from "react-router-dom"

 interface ITestCardProps {
    test: IPETest
 }
const TestCard = ({test,...props}: ITestCardProps) : JSX.Element => {
    return <div 
        className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 pe-manitu-test-card" 
        style={{backgroundImage: `url(${test.thumbnail})`}}
        key={test.id}
    >
        <NavLink  
            to={test.id.toString()} 
            className=""
        >
            <Dummy width={12} height={12} />
            <div className="titled">
                <span>
                    {test.title}
                </span>
            </div> 
            <div className="cat-label">
                { test.testCategory?.title }
            </div>
        </NavLink>
    </div> 
} 

export default TestCard