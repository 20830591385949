import { getLayoutApp } from '@/libs/utilities';
import * as React from 'react';

const THRESHOLD = 5;

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = React.useState('');

  const blocking = React.useRef(false);
  const prevScrollY = React.useRef(0);

  React.useEffect(() => {
    const _div = getLayoutApp() 
        if (!_div) return
    prevScrollY.current = _div.scrollTop;
    const updateScrollDirection = () => {
      const scrollY = _div.scrollTop;
      // console.warn( scrollY )
      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection = scrollY > prevScrollY.current ? 'down' : 'up';
        setScrollDirection(newScrollDirection);
        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }

      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    }
    const omClickHandler = () => { 
      setScrollDirection("none")
    }

    _div.addEventListener('scroll', onScroll);
    _div.addEventListener("mousedown", omClickHandler)
    return () => {
      _div.removeEventListener('scroll', onScroll)
      _div.removeEventListener("mousedown", omClickHandler)
      
    }
  }, [scrollDirection, getLayoutApp()]);

  return scrollDirection;
};

export default useScrollDirection 