import { Gallery } from "src/libs/useful"
import { IErmakItems } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface IErmakSlotGalleryProps {
    item: IErmakItems
}
const ErmakSlotGallery = ({item, ...props}: IErmakSlotGalleryProps): JSX.Element => {
    return <div className='pe-ermakslotgallery-container'> 
        {
            item?.gallery && !!item?.gallery.length && 
                <Gallery
                    items={ item.gallery || [] }
                    itemWidth={70}
                    itemHeight={70}
                />
        }
        
    </div>
}
export default ErmakSlotGallery
