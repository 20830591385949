import { IFabulaMediaType, IPEFabulaMapTile } from "../../interfaces";

export const Bobobshka = () :IPEFabulaMapTile => {
    return {
        id: "4",
        title: "Спорткомплекс Бобошка",
        post_content: "",
        enabled: true,
        x: 0,
        y: 1,
        map_id: "2",
        thumbnail: "/assets/img/fabula/Bear/bear4.jpg",
        episodes: [ 
            {
                id: "41",
                title: "Восточная трибуна",
                post_content: "Чемпионат мира по подземному плаванию",
                tile_id: "4",
                visible: true,
                x: 40,
                y: 7,
                media: [ 
                    {
                        id: "411",
                        title: "Восточная трибуна",
                        post_content: "Чемпионат мира по подземному плаванию",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear411.jpg",
                        speech:[],
                        media:[
                            {
                                id: "4111",
                                title: "Первое дно",
                                post_content: "Соревнования на глубинное погружение",
                                type: IFabulaMediaType.IMAGE,
                                x: 50,
                                y: 75,
                                thumbnail: "/assets/img/fabula/Bear/bear4111.jpg",
                                speech:[],
                                media:[
                                    
                                ],
                            }
                        ],
                    }
                ]
            },
            {
                id: "42",
                title: "Западная трибуна",
                post_content: "В ожидании минуэта",
                tile_id: "4",
                visible: true,
                x: 85,
                y: 35,
                media: [ 
                    {
                        id: "421",
                        title: "Западная трибуна",
                        post_content: "В ожидании минуэта",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear421.jpg",
                        speech:[],
                        media:[
                            {
                                id: "4211",
                                title: "Беседо",
                                post_content: ".",
                                type: IFabulaMediaType.IMAGE,
                                x: 78,
                                y: 88,
                                thumbnail: "/assets/img/fabula/Bear/bear4211.jpg",
                                speech:[],
                                media:[
                                    
                                ],
                            },
                        ],
                    }
                ]
            },
        ]
    }
}