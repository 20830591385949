import { IPlaceType } from "../interfaces"

export const placeTypes = () : IPlaceType[] => {  
    const lorigin = `${window.location.origin}/`
    return [
        {
            id: 1, 
            title: "Хоромы",
            color: "#3caa3c",
            icon: lorigin + "assets/img/topos/cocktail-svgrepo-com.svg",
            post_content: "", 
        },
        {
            id: 2, 
            title: "Город",
            color: "#183153",
            icon: lorigin + "assets/img/topos/apartment-2-svgrepo-com.svg",
            post_content: ""
        },
        {
            id: 3, 
            title: "Клуб",
            color: "#74c0fc",
            icon: lorigin + "assets/img/topos/armchair-svgrepo-com.svg",
            post_content: ""
        },
    ]
}