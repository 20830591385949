
import { setMaxHeightByClass } from "src/libs/utilities" 
import Feed from "src/modules/pe-basic-module/views/core/Feed"
import FestivalButton from "src/modules/pe-fest-module/views/festival-view/FestivalButton"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom" 
import EventCard from "./EventCard"

/* 
    Protopia Ecosystem component 
    Feed of Events 
    переходный вариант миграции из react-pe-fest-module.
    TODO:: После удачного upgrade'a этот комментарий необходимо удалить!!!!
*/
export interface IEventsListProps {

}
const EventsList = (props: IEventsListProps): JSX.Element => {
    const [paging, setPaging] = useState<string>("")
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => { 
        setMaxHeightByClass( 'card-title' )
        setMaxHeightByClass( 'card-text' ) 
    }, []) 
    useEffect(() => {
        if( searchParams.get("filter") === "all-current" ) {
            setPaging(` metas: [ { key:"status", value: "1" }, { key:"status", value: "2" } ] `)
        }
        else if( searchParams.get("filter") === "all-before" ) {
            setPaging(` metas: [ { key:"status", value: "0" } ] `)
        }
        else if( searchParams.get("filter") === "all-finished" ) {
            setPaging(` metas: [ { key:"status", value: "3" } ] `)
        }
        else {
            setPaging("")
        }
    }, [ searchParams ]) 

    return <div className=' container h-100 flex-centered flex-column '>
        <Feed 
            data_type="Land"
            offset={0} 
            class_name='row'
            containerClassName="container"  
            component={ EventCard }
            paging={paging}
            onLoading = {( ) => {
                setMaxHeightByClass( 'card-title' )
                setMaxHeightByClass( 'card-text' ) 
            }}
            topPagiHide
            params={{
                col: 3
            }}
        /> 
    </div>
}
export default EventsList