import React from "react"
import BasicState from "@/libs/basic-view"  
import FeedDataTypeForm from "./core/FeedDataTypeForm" 
import AdminFeedLink from "@/modules/pe-admin-module/views/dataTable/AdminFeedLink"

class FeedDataTypeView extends BasicState {
    props: any
    addRender = () => { 
        return <>
            <FeedDataTypeForm {...this.props} />                      
            <div className="position-fixed right" >
                <AdminFeedLink {...this.props} />
            </div> 
        </>
    }
}

export default FeedDataTypeView 
