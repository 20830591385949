import { useAppoloStore } from '@/settings/zustand'
import { VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { Card, Icon, Slider } from '@blueprintjs/core'
import { ID } from '@/libs/interfaces/layouts'
import FieldInput, { SCALAR_TYPES } from '@/libs/scalars'
import { LoaderBallsLine, MediaChooser } from '@/libs/useful'
import { __ } from '@/libs/utilities'
import gql from 'graphql-tag'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { GET_FESTIVAL_SETTINGS_ACTION } from '../../data/actionTypes'
import { actions } from '../../data/actions'
import { cardTypes } from '../../data/cardTypes'
import { displayProjectResult } from '../../data/displayProjectResult'
import { CARD_TYPE, FESTIVAL_RATING_TYPES, FEST_ADMIN_STATUSES, IFestival, ISettingsPageFormProps } from '../../data/interfaces'
import { raitingTypes } from '../../data/raitingTypes'
import { useFestDataStore } from '../../data/store'
import FestivalStatusForm from './FestivalStatusForm'

const SettingsForm = (props: ISettingsPageFormProps ) : JSX.Element  => {
    const params = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(true) 
    const [festival, setFestival] = useState<IFestival>({} as IFestival) 
    const [defaultFest, setDefaultFest] = useState<string>("") 
    useEffect(() => {
        console.log( params.landId )
        actions(GET_FESTIVAL_SETTINGS_ACTION, {id: params.landId})
            .then(
                response => {
                    setFestival(response)
                    setIsLoading(false)
                    setDefaultFest(JSON.stringify( response ))
                }
            ) 
    },[]) 

    const updateFestival = () => {
        console.log( festival )
        const land_id = params.landId 
            ?
            ` land_id:"${params.landId}" `
            :
            ``
        const mutation: string =  `
            mutation changeFestival {
                changePEFestival (
                    input: { 
                        title: "${ festival.title }"
                        description: "${ festival.description }"
                        status: ${ festival.status }
                        clientUrl: "${ festival.clientUrl }"
                        startDate: "${ festival.startDate  }"
                        finishDate: "${ festival.finishDate }"
                        ${ festival.link1 ? 'link1:"' + festival.link1 + '"' : "" } 
                        ${ festival.link2 ? 'link2:"' + festival.link2 + '"' : ""  } 
                        ${ festival.vk ? 'vk:"' + festival.vk + '"'  : ""  } 
                        ${ festival.vk2 ? 'vk2:"' + festival.vk2 + '"' : ""  } 
                        ${ festival.email ? 'email:"' + festival.email + '"' : ""  }
                        ${ festival.email2 ? 'email2:"' + festival.email2 + '"' : ""  }
                        ${ festival.telegramm ? 'telegramm:"' + festival.telegramm + '"' : ""  }
                        ${ festival.telegramm2 ? 'telegramm:"' + festival.telegramm2 + '"' : ""  } 
                        ${ festival.phone ? 'phone:"' + festival.phone + '"' : ""  } 
                        ${ festival.phone2 ? 'phone2:"' + festival.phone2 + '"' : ""  } 
                        logotype: "${ festival.logotype }" 
                        ${ festival.logotypeName ? 'logotypeName:"' + festival.logotypeName + '"' : "" } 
                        defaultProjectThumbnail: "${ festival.defaultProjectThumbnail }"
                        ${ festival.defaultProjectThumbnailName ? 'defaultProjectThumbnailName:"' + festival.defaultProjectThumbnailName + '"' : "" }
                        memberCardType: "${ festival.memberCardType }"
                        isChangeCardType: ${ festival.isChangeCardType }
                        memberCardHeight: ${ festival.memberCardHeight } 
                        ratingType: "${ festival.ratingType }"
                        isProjectsVerify: ${ festival.isProjectsVerify }
                        displayProjectResult: "${ festival.displayProjectResult }"
                        maxRating: ${ festival.maxRating }
                        isIndependentGradeCritery: ${ festival.isIndependentGradeCritery }
                        isOwnerRate: ${ festival.isOwnerRate }
                        isExpertsCriteries: ${ festival.isExpertsCriteries }
                        isGanres: ${ festival.isGanres }
                        isRegisterUser: ${ festival.isRegisterUser }
                        isRegisterProject: ${ festival.isRegisterProject }
                        isCreateAuthorWithProject: ${ festival.isCreateAuthorWithProject }
                        isDiary: ${ festival.isDiary }
                        maxMemberId: ${ festival.maxMemberId } 
                        ${ festival.vk_client_id ? 'vk_client_id:"' + festival.vk_client_id + '"' : "" }    
                        ${ festival.vk_client_secret ? 'vk_client_secret:"' + festival.vk_client_secret + '"' : "" }    
                        availableRoles: [ "${ (festival.availableRoles || [] ).join('", "') }" ]
                    }
                    ${land_id}
                ) {
                    title
                    description
                    status
                    clientUrl
                    startDate
                    finishDate
                    isProjectsVerify
                    link1
                    link2
                    vk
                    vk2
                    email
                    email2
                    telegramm
                    telegramm2
                    phone
                    phone2
                    logotype
                    defaultProjectThumbnail
                    vk_client_id
                    vk_client_secret
                }
            }` 
        setIsLoading(true)
        const apolloClient: any = useAppoloStore.getState().client
        apolloClient.mutate({
            mutation : gql`${mutation}`
        })
            .then( ( response: any ) => {
                // console.log( response.data.changePEFestival ) 
                actions(GET_FESTIVAL_SETTINGS_ACTION, {id: params.landId})
                .then(
                    response => {
                        setFestival(response)
                        setIsLoading(false)
                        setDefaultFest(JSON.stringify( response ))
                        useFestDataStore.getState().setFestival( {...useFestDataStore.getState().festival, ...response} )
                    }
                )
            })
    }
    const onPhase = (status: FEST_ADMIN_STATUSES) => { 
        setFestival({ ...festival, status })
    }
    const onClientUrl = (data: any) => {
        setFestival({ ...festival, clientUrl: data })
    }
    const onStartDate = ( data: any ) => {
        setFestival({ ...festival, startDate: data / 1000 + "" })
    }
    const onFinishDate = (data: any) => {
        setFestival({ ...festival, finishDate: data / 1000 + "" })
    }
    const onIcon = ( url: string, file: any, ID: ID ) =>
	{ 
		setFestival({...festival, logotype: ( url! ), logotypeName: file.name})
	}
	const onDefaultImage = ( url: string, file: any, ID: ID ) =>
	{
		setFestival({...festival, defaultProjectThumbnail: url, defaultProjectThumbnailName: file.name})
	} 
    const onValueChange = (field: string, value: any) =>
	{
        console.log(field, value)
		let fest: any = {...festival}
        fest[field] = value
        setFestival( fest )
	}
    // const statusForm = festSatuses()
    //     .map((elem: any, i) => <Fragment key={i}>
    //         <div className='mb-1'> 					
    //             <input 
    //                 type={SCALAR_TYPES.RADIO}
    //                 name='card=types'
    //                 className='radio_full' 
    //                 id={'status' + elem._id}
    //                 value={ elem._id }
    //                 checked={ festival.status === elem._id }
    //                 onChange={ () => onPhase(elem._id) }
    //                 data-n={ i }
    //             />
    //             <label htmlFor={ 'status' + elem._id} data-hint={__(elem.title) }>
    //                 <div className={ festival.status === elem._id ? "cat_color_label ggreen" : "cat_color_label" } />
    //             </label>					
    //         </div>
    //     </Fragment>) 
 
    return (
        <div className="position-relative mb-5">
            <div 
                className={ ` position-sticky mr-2 mt-2 top` }
                style={{ zIndex:100 }}
            >
                <div 
                    className={` mt-2 position-absolute right btn btn-sm btn-${defaultFest !== JSON.stringify(festival) ? 'danger' : 'secondary opacity_5 untouchble'}`}
                    onClick={updateFestival} 
                >
                    <Icon 
                        icon={ 
                            defaultFest === JSON.stringify(festival) //
                                ?  
                                "tick" 
                                :
                                isLoading ? "repeat" : "blank"
                        } 
                        className={ isLoading ? "mr-2 fa fa-spin" : "mr-2" } 
                    /> 
                    { __( "Update Festival data" ) }
                </div>
            </div>
            <div className='d-flex align-items-center '>
                <div className='display-5 mb-2 mr-3'>{ __(props.title) }</div>
                { isLoading && <LoaderBallsLine /> } 
            </div>
            
            <div className='row'>
                <div className='col-12 '>
                    <Card interactive={false} className="p-4 mb-2" data-element="title"> 
                        <FieldInput 	
                            title={__("Title")}					
                            vertical={ VerticalType.VERTICAL }
                            editable
                            value={ festival.title }
                            commentary={ __('Title of festival') }
                            type={SCALAR_TYPES.STRING}  
                            onChange={value => onValueChange("title", value)}
                        />
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="title"> 
                        <FieldInput 	
                            title={__("Description")}					
                            vertical={ VerticalType.VERTICAL }
                            editable
                            value={ festival.description }
                            commentary={ __('Description of festival') }
                            type={SCALAR_TYPES.STRING}  
                            onChange={value => onValueChange("description", value)}
                        />
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="title"> 
                        <FieldInput 	
                            title={__("Domain")}					
                            vertical={ VerticalType.HORIZONTAL }
                            editable={false}
                            value={ festival.domain_type } 
                            type={SCALAR_TYPES.STRING}
                            className="lead"
                        />
                        <FieldInput 	
                            title={__("Domain description")}					
                            vertical={ VerticalType.HORIZONTAL }
                            editable={false}
                            value={ festival.descr } 
                            type={SCALAR_TYPES.STRING}
                            className="small"
                        />
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="status">
                        <div className="layout-label-vert  mb-1">{__("Status")}</div>
                        <FestivalStatusForm festival={festival} onPhase={onPhase} />
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="client_url"> 
                        <FieldInput 	
                            title={__("Oficial  Festival's URL")}					
                            vertical={ VerticalType.VERTICAL }
                            editable
                            value={ festival.clientUrl }
                            commentary={ __('Goto to main page of Festival and copy URL. This data need for correct auto e-mails') }
                            type={SCALAR_TYPES.STRING}  
                            onChange={ onClientUrl }
                        />
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="start_date, finish_date">
                        <div className="row">
                            <div className="col-md-6 col-12"> 
                                <FieldInput
                                    title={__("Start date")}						
                                    vertical={VerticalType.VERTICAL}
                                    editable
                                    value={ parseInt( festival.startDate! ) }
                                    type={SCALAR_TYPES.DATE}
                                    onChange={onStartDate}
                                />
                            </div>
                            <div className="col-md-6 col-12"> 
                                <FieldInput
                                    title={__("Finish date")}						
                                    vertical={VerticalType.VERTICAL}
                                    editable
                                    value={ parseInt( festival.finishDate! )}
                                    type={SCALAR_TYPES.DATE} 
                                    onChange={onFinishDate}
                                />
                            </div>
                        </div>
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="vk,vk2,email,email2,phone,phone2"> 
                        <div className="row">
                            <div className="col-12">
                                <div className="layout-label-vert  mb-1">{__("Contacts")}</div> 
                                <div className=" mb-1">{__("The contacts you specified will be listed on the pages of the Festival")}</div> 
                                <FieldInput
                                    title={__("Link")}	 
                                    value={festival.link1}
                                    editable
                                    type={SCALAR_TYPES.URL}  
                                    onChange={value => onValueChange("link1", value)}
                                />
                                <FieldInput
                                    title={__("Link alternative")}	 
                                    value={festival.link2}
                                    editable
                                    type={SCALAR_TYPES.URL} 
                                    onChange={value => onValueChange("link2", value)}
                                />
                                <FieldInput
                                    title={__("VKontakte")}	 
                                    value={festival.vk}
                                    editable
                                    type={SCALAR_TYPES.URL}  
                                    onChange={value => onValueChange("vk", value)}
                                />
                                <FieldInput
                                    title={__("VKontakte alternative")}	 
                                    value={festival.vk2}
                                    editable
                                    type={SCALAR_TYPES.URL}  
                                    onChange={value => onValueChange("vk2", value)}
                                />
                                <FieldInput
                                    title={__("E-mail")}	 
                                    value={festival.email}
                                    editable
                                    type={SCALAR_TYPES.EMAIL} 
                                    onChange={value => onValueChange("email", value)}
                                />
                                <FieldInput
                                    title={__("E-mail alternative")} 
                                    value={festival.email2}
                                    editable
                                    type={SCALAR_TYPES.EMAIL} 
                                    onChange={value => onValueChange("email2", value)}
                                />
                                <FieldInput
                                    title={__("Telegramm")} 
                                    value={festival.telegramm}
                                    editable
                                    type={SCALAR_TYPES.URL}  
                                    onChange={value => onValueChange("telegramm", value)}
                                />
                                <FieldInput
                                    title={__("Telegramm alternative")} 
                                    value={festival.telegramm2}
                                    editable
                                    type={SCALAR_TYPES.URL} 
                                    onChange={value => onValueChange("telegramm2", value)}
                                />
                                <FieldInput 
                                    title={__("Phone")}	 
                                    value={festival.phone}
                                    className={` w-100 input dark form-control `}
                                    editable
                                    type={SCALAR_TYPES.PHONE}  
                                    onChange={value => onValueChange("phone", value)}
                                />
                                <FieldInput 
                                    title={__("Phone alternative")} 
                                    value={festival.phone2}
                                    className={` w-100 input dark form-control `}
                                    editable
                                    type={SCALAR_TYPES.PHONE} 
                                    onChange={value => onValueChange("phone2", value)}
                                />
                            </div>
                        </div>
                    </Card>
                    <Card interactive={false} className="p-4 mb-2 pb-5" data-element="icon,default_member_thrumb">
                        <div className="layout-label-vert  mb-1">{__("Icons")}</div>
                        <div className="row">
                            <div className="col-md-6 col-12" data-element="icon">
                                <div className="small mt-1">{__("Logotype")}</div>
                                <MediaChooser
                                    url={festival.logotype!}
                                    prefix={"icon"}
                                    height={60}
                                    padding={0}
                                    onChange={ onIcon}
                                    id={festival.logotype!}
                                    isURLHide={true}
                                    isDescr={false}
                                    hideLib
                                    limitSize={2000000}
                                />
                            </div>
                            <div className="col-md-6 col-12" data-element="default_member_thrumb">
                                <div className="small mt-1">{__("Default Project's icon")}</div>
                                <MediaChooser
                                    url={festival.defaultProjectThumbnail!}
                                    prefix={"deficon"}
                                    height={60}
                                    padding={0}
                                    onChange={ onDefaultImage}
                                    id={festival.defaultProjectThumbnail!}
                                    isURLHide={true}
                                    isDescr={false}
                                    hideLib
                                    limitSize={2000000}
                                />
                            </div>
                        </div>
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="rating_type">
                        <div className="layout-label-vert  mb-1">{__("Rating method")}</div>
                        <FieldInput
                            title={__("")}						
                            vertical={VerticalType.VERTICAL}
                            editable
                            value={festival.ratingType}
                            type={SCALAR_TYPES.RADIO}
                            values={ raitingTypes() } 
                            onChange={ value => onValueChange("ratingType", value) }
                        />
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="display_project_result">
                        <div className="layout-label-vert  mb-1">{__("Display Project's result method")}</div>
                        <FieldInput					
                            vertical={VerticalType.VERTICAL}
                            value={ festival.displayProjectResult }
                            type={SCALAR_TYPES.RADIO}
                            editable
                            values={ displayProjectResult() }
                            default="average" 
                            onChange={ value => onValueChange("displayProjectResult", value) }
                        />
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="isProjectsVerify">
                        <div className="layout-label-vert  mb-1">
                            {__("Is projects need verify from moderator")}
                        </div>
                        <FieldInput					
                            vertical={VerticalType.VERTICAL}
                            value={ festival.isProjectsVerify }
                            type={SCALAR_TYPES.BOOLEAN}
                            editable 
                            default={true}
                            onChange={ value => onValueChange("isProjectsVerify", value) }
                        />
                    </Card>
                    {
                        festival.ratingType === FESTIVAL_RATING_TYPES.INDEPENDENT_GRADE 
                            || festival.ratingType === FESTIVAL_RATING_TYPES.EACH_CATEGORY
                            ?
                            <Card interactive={true} className="p-4 mb-2" data-element="max_raiting">
                                <div className="layout-label-vert  mb-1">{__("Max raiting setting for independing raiting")}</div>
                                <Slider
                                    min={0}
                                    max={10}
                                    stepSize={1}
                                    labelStepSize={1}
                                    onChange={value => onValueChange("maxRating", value)}
                                    value={festival.maxRating || 0}
                                    vertical={false}
                                />
                            </Card>
                            :
                            null
                    }
                    {
                        festival.ratingType === FESTIVAL_RATING_TYPES.INDEPENDENT_GRADE 
                        || festival.ratingType === FESTIVAL_RATING_TYPES.EACH_CATEGORY
                            ?
                            <Card interactive={false} className="p-4 mb-2" data-element="isIndependentGradeCritery">
                                <div className="layout-label-vert  mb-1">{__("Maximize grade each critery")}</div>
                                <input
                                    type="checkbox"
                                    className="_checkbox"
                                    checked={festival.isIndependentGradeCritery}
                                    onChange={evt => onValueChange("isIndependentGradeCritery", evt.target.checked)}
                                    id="isIndependentGradeCritery"
                                />
                                <label htmlFor="isIndependentGradeCritery"> </label>
                            </Card>
                            :
                            null
                    }
                    <Card interactive={false} className="p-4 mb-2" data-element="isOwnerRate">
                        <div className="layout-label-vert  mb-1">{__("Expert can evaluate projects of which he is the owner")}</div>
                        <input
                            type="checkbox"
                            className="_checkbox"
                            checked={festival.isOwnerRate || false }
                            onChange={ evt => onValueChange("isOwnerRate", evt.target.checked) }
                            id="isOwnerRate"
                        />
                        <label htmlFor="isOwnerRate"> </label>
                    </Card> 
                    <Card interactive={false} className="p-4 mb-2" data-element="is_experts_criteries">
                        <div className="layout-label-vert  mb-1">{__("Experts's criteries enabled")}</div>
                        <input
                            type="checkbox"
                            className="_checkbox"
                            checked={festival.isExpertsCriteries}
                            onChange={ evt => onValueChange("isExpertsCriteries", evt.target.checked) }
                            id="is_experts_criteries"
                        />
                        <label htmlFor="is_experts_criteries"> </label>
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="is_ganres">
                        <div className="layout-label-vert  mb-1">{__("Is ganres exists")}</div>
                        <input
                            type="checkbox"
                            className="_checkbox"
                            checked={festival.isGanres}
                            onChange={ evt => onValueChange("isGanres", evt.target.checked) }
                            id="is_ganres"
                        />
                        <label htmlFor="is_ganres"></label>
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="is_register">
                        <div className="layout-label-vert  mb-1">{__("Visitors can create accounts")}</div>
                        <input
                            type="checkbox"
                            className="_checkbox"
                            checked={ festival.isRegisterUser}
                            onChange={ evt => onValueChange("isRegisterUser", evt.target.checked) }
                            id="is_register"
                        />
                        <label htmlFor="is_register"></label>
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="is_register">
                        <div className="layout-label-vert  mb-1">{__("Visitors can create new Project")}</div>
                        <input
                            type="checkbox"
                            className="_checkbox"
                            checked={ festival.isRegisterProject}
                            onChange={ evt => onValueChange("isRegisterProject", evt.target.checked) }
                            id="is_register_project"
                        />
                        <label htmlFor="is_register_project"></label>

                        <div className="layout-label-vert  mb-1">{__("Create author account with register new Project")}</div>
                        <input
                            type="checkbox"
                            className="_checkbox"
                            checked={ festival.isCreateAuthorWithProject}
                            onChange={ evt => onValueChange("isCreateAuthorWithProject", evt.target.checked) }
                            id="isCreateAuthorWithProject"
                        />
                        <label htmlFor="isCreateAuthorWithProject"></label>
                    </Card>
                    <Card interactive={false} className="p-4 mb-2" data-element="is_diaries">
                        <div className="layout-label-vert  mb-1">{__("Project authors keep a diary")}</div>
                        <input
                            type="checkbox"
                            className="_checkbox"
                            checked={festival.isDiary}
                            onChange={ evt => onValueChange("isDiary", evt.target.checked) }
                            value="1"
                            id="is_diaries"
                        />
                        <label htmlFor="is_diaries"></label>
                    </Card> 
                    <Card interactive={false} className="p-4 mb-2" data-element="member_card">                       
                        <FieldInput
                            type={SCALAR_TYPES.IMAGE_RADIO}
                            title={__("Default Member's card")}
                            vertical={VerticalType.VERTICAL}
                            value={ festival.memberCardType } 
                            editable 
                            values={ cardTypes() } 
                            onChange={ value => onValueChange("memberCardType", value._id) }
                        /> 
                         <FieldInput
                            type={SCALAR_TYPES.BOOLEAN}
                            title={__("User may change type of cards display")}
                            vertical={VerticalType.VERTICAL}
                            value={ festival.isChangeCardType } 
                            editable 
                            values={ cardTypes() } 
                            onChange={ value => onValueChange("isChangeCardType", value ) }
                        />
                    </Card>
                    {
                        festival.memberCardType === CARD_TYPE.CARD 
                            ?
                            <Card interactive={false} className="p-4 mb-2" data-element="member_card_height">
                                <div className="layout-label-vert mb-1">{__("Height of card's thumbnail")}</div>							
                                <Slider
                                    min={0}
                                    max={400}
                                    stepSize={1}
                                    labelStepSize={50}
                                    onChange={ value => onValueChange("memberCardHeight", value) }
                                    onRelease={ value => onValueChange("memberCardHeight", value) }
                                    //labelRenderer={ festival.memberCardHeight! }
                                    value={ festival.memberCardHeight || 100 }
                                    vertical={ false }
                                />  
                            </Card>
                            :
                            null
                    } 
                    <Card interactive={false} className="p-4 mb-2" data-element="fmru_evlbl_roles">
                        <div className="layout-label-vert  mb-2">{__("Evalble special roles")}</div>
                        <FieldInput
                            type={SCALAR_TYPES.CHECKBOX}
                            vertical={VerticalType.VERTICAL}
                            value={ festival.availableRoles || [] } 
                            editable 
                            values={ festival.roles || [] } 
                            onChange={ value => onValueChange("availableRoles", value) }
                        />
                    </Card>	
                    <Card interactive={false} className="p-4 mb-2 " data-element="member_id">
                        <div className="layout-label-vert  mb-1">{__("Next new Member's order")}</div>
                        <input
                            type="number"
                            className="form-control input dark"
                            value={festival.maxMemberId}
                            onChange={ evt => onValueChange("maxMemberId", evt.target.value) } 
                        />
                    </Card>
                    <Card interactive={false} className="p-4 mb-2 " data-element="member_id">
                        <div className="layout-label-vert  mb-1">{__("VKontakte manage accounts data")}</div>
                        <FieldInput
                            type={SCALAR_TYPES.STRING}
                            editable
                            title={__("VK application ID")}
                            commentary={__("Register VK application (https://vk.com/apps?act=manage) and put this key 'Application ID'." )}
                            value={ festival.vk_client_id }
                            onChange={ data => onValueChange("vk_client_id", data ) } 
                        /> 
                        <FieldInput
                            type={SCALAR_TYPES.STRING}
                            editable
                            title={__("VK application Secret")}
                            commentary={__("Register VK application (https://vk.com/apps?act=manage) and put this key 'Service token'. Do not show this data to anyone!" )}
                            value={ festival.vk_client_secret }
                            onChange={ data => onValueChange("vk_client_secret", data ) } 
                        />  
                    </Card>
                </div>
            </div>
        </div>
    )
} 

export default SettingsForm

