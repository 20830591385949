import { ID } from "@/libs/interfaces/layouts";

export const CHANGE_PROJECT_HONEYCOMBS = ( projectId: ID, honeycombsId: ID) => {
    return `
    mutation addProjectHoneycombs {
        changePEFestProject(
          id: "${projectId}",
          input : {
            honeycombs: ${honeycombsId}
          }
        ) { 
          honeycombs {
            id
            title
          }
        }
      } 
    `
}