import { Icon, IconName, MaybeElement } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IMenuItemProps {
    isOpen: boolean
    className: string
    icon: IconName | MaybeElement
    onClick: () => void
}
const MenuItem = (props: IMenuItemProps): JSX.Element => {
    return <div className={ `pe-fabula-menu-cont ${ props.className }` }>
        <div className={ `pe-fabula-menu-item ${ props.isOpen ? "opened" : "" }`} onClick={props.onClick}>
            <Icon icon={ props.icon } color="#FFFFFF"/>
        </div> 
    </div>
}
export default MenuItem