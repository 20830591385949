import React from 'react' 
import { IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import { Icon } from '@blueprintjs/core'

const ContactForm = ( ) :JSX.Element => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    let vk: JSX.Element = <></>, 
        email: JSX.Element = <></>, 
        phone: JSX.Element = <></>, 
        telegramm: JSX.Element = <></>, 
        links: JSX.Element = <></>
    
    if( festival.link1 || festival.link2 )
    {
      links = <div className="d-flex px-2">
        <Icon icon={<i className="fas fa-link fa-2x mt-1 mx-2" />}  color="#FFF"/>
        {
          festival.link1
            ?
            <a href={ festival.link1} target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.link1 }</a>
            :
            null
        }
        {
          festival.link2
            ?
            <a href={ festival.link2} target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{festival.link2}</a>
            :
            null
        }
      </div>
    }
    if( festival.vk || festival.vk2 )
    {
      vk = <div className="d-flex px-2">
        <Icon icon={<i className="fab fa-vk fa-2x mt-1 mx-2 "/>} color="#FFF"/>
        {
          festival.vk
            ?
            <a href={festival.vk} target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.vk }</a>
            :
            null
        }
        {
          festival.vk2
            ?
            <a href={ festival.vk2 } target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.vk2 }</a>
            :
            null
        }
      </div>
    }
    if( festival.telegramm || festival.telegramm2 )
    {
      telegramm = <div className="d-flex px-2">
        <Icon icon={<i className="fab fa-telegram-plane fa-2x mt-1 mx-2" />}  color="#FFF"/>
        {
          festival.telegramm
            ?
            <a href={ festival.telegramm } target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.telegramm }</a>
            :
            null
        }
        {
          festival.telegramm2
            ?
            <a href={ festival.telegramm2 } target="_blank" rel="noreferrer" className="text-light mt-2 mx-2 hover">{ festival.telegramm2 }</a>
            :
            null
        }
      </div>
    }
    if( festival.email || festival.email2 )
    {
      email = <div className="d-flex px-2">
        <Icon icon={<i className="fas fa-envelope fa-2x mt-1 mx-2"/>} color="#FFF"/>
        {
          festival.email
            ?
            <span className="text-light mt-2 mx-2 hover">{ festival.email }</span>
            :
            null
        }
        {
          festival.email2
            ?
            <span className="text-light mt-2 mx-2 hover">{ festival.email2 }</span>
            :
            null
        }
      </div>
    }
    if( festival.phone || festival.phone2 )
    {
      phone = <div className="d-flex px-2">
        <Icon icon={<i className="fas fa-phone fa-2x mt-1 mx-2" />}  color="#FFF"/>
        {
          festival.phone
            ?
            <span className="text-light mt-2 mx-2 hover">{ festival.phone }</span>
            :
            null
        }
        {
          festival.phone2
            ?
            <span className="text-light mt-2 mx-2 hover">{ festival.phone2 }</span>
            :
            null
        }
      </div>
    }
    return <> {links} {vk} {telegramm} {email} {phone} </>
} 

export default ContactForm