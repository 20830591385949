import { Button, Icon, Popover } from "@blueprintjs/core";
import { IPETest } from "../../data/interfaces";
import { ITestsStore, useTestsStore } from "../../data/store";
import { __ } from "src/libs/utilities";
import { NavLink } from "react-router-dom";
import {default as UUID} from "node-uuid";

const Breadcrumbs = () : JSX.Element => { 
    const genTest: IPETest = useTestsStore((state:ITestsStore) => state.genTest) 
    const onAdd = () => {
        useTestsStore.setState({
            genTest : {
                ...genTest,
                questions: [
                    ...genTest.questions,
                    {
                        id: UUID.v4(),
                        title: `${__("Question")} ${genTest.questions.length}`,
                        post_content: "",
                        answers: []
                    }
                ]
            }
        })
    }
    const onRemove = (i :number) => {
        useTestsStore.setState({
            genTest : {
                ...genTest,
                questions: genTest.questions.filter((q, n) => i !== n)  
            }
        })
    }
    return <div className="pe-test-bc-container">
        <NavLink to="" className="pe-test-bs-btn">
            <Button minimal alignText="left" >
                <i className="fas fa-ellipsis-h"></i>
            </Button>
        </NavLink>
        {
            genTest.questions?.map( (q, i) => {
                return  <div className="d-flex">
                    <NavLink to={`${q.id}`} className="pe-test-bs-btn-2 flex-grow-100" key={ i }>
                        <Button minimal alignText="left" >
                            <div className="d-flex">
                                <i className="fas fa-question mr-2" /> 
                                <div className="text-overflow bs-btn">
                                    {q.title}    
                                </div> 
                            </div>
                             
                        </Button>
                    </NavLink>
                    <Popover
                        content={<div className="p-2">{__("Double")}</div>}
                        interactionKind="hover"
                        position="left"
                    >
                        <Button minimal alignText="left" onClick={onAdd} className="pe-test-btn ">
                            <i className="far fa-copy " />
                        </Button> 
                    </Popover>
                     
                    <Popover
                        content={<div className="p-2">{__("Delete")}</div>}
                        interactionKind="hover"
                        position="right"
                    >
                        <Button 
                            minimal 
                            alignText="left" 
                            onClick={() => onRemove(i)} 
                            className="pe-test-btn "
                        >
                            <i className="far fa-trash-alt " />
                        </Button>
                    </Popover> 
                </div>
            })
        }
        
       
            <Button minimal alignText="left" onClick={onAdd} className="pe-test-btn">
                <i className="fas fa-plus mr-2" />{__("add question")}
            </Button> 
        
        <NavLink to="finish" className="pe-test-bs-btn">
            <Button minimal alignText="left" >
            <i className="fas fa-check mr-2" />{__("finish")}
            </Button>
        </NavLink>
        
    </div>
} 

export default Breadcrumbs