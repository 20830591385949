import { IFabulaEpisode, IFabulaMedia, PROTECTION_MODE } from "src/modules/pe-fabula-module/data/interfaces" 
import { __ } from "src/libs/utilities"
import { Button, ButtonGroup } from "@blueprintjs/core"
import { LayoutIcon } from "src/libs/useful"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { useEffect, useState } from "react"
import Protection from "./Protection"

/* Protopia Ecosystem component */
export interface ITileProtectionScreenProps {
    item: IFabulaEpisode | IFabulaMedia
    onClick: () => void
    onClose: () => void
    onSize : (size: DIALOG_SIZE) => void
    onWin : () => void
}
const TileProtectionScreen = (props: ITileProtectionScreenProps): JSX.Element => {
    
    const [timeOut, set_timeOut] = useState<any>(null)
    const [mode, setMode] = useState<PROTECTION_MODE>(PROTECTION_MODE.CHO0SE)
    
    useEffect(() => {
        return () => { clearTimeout(timeOut) }
    }, [])

    const onBreak = () => {
        setMode(PROTECTION_MODE.BREAK)
        props.onSize(DIALOG_SIZE.LARGE)
    }
    const onHack = () => {
        setMode(PROTECTION_MODE.HACK)
        props.onSize(DIALOG_SIZE.LARGE)
    }
    const onClose = () => {
        setMode(PROTECTION_MODE.CHO0SE)
        props.onSize(DIALOG_SIZE.MINIMAL)
        props.onClose()
    }
    const onWin = () => { 
        console.log("win")
        set_timeOut(setTimeout(() => {
            props.onWin()
            onClose()
        }, 2000))
    }
    const onLose = () => { 
        console.log("lose")
        set_timeOut(setTimeout(() => {
            onClose()
        }, 2000))
    }
    const getBreakIcon = () => {
        if(!props.item.defense) return ""
        switch(props.item.defense) {
            case 4:
            case 5:
            case 6:
            case 7:
                return "/assets/img/fabula/break-icon.svg"
            case 8:
            case 9:
            case 10:
                return "/assets/img/fabula/hard-break-icon.svg"
            case 1:
            case 2:
            case 3:
            default:
                return "/assets/img/fabula/easy-break-icon.svg"
        }
    }
    const getDesctiption = () : string => {
        let defense = ""
        if(props.item.defense){
            switch(props.item.defense) {
                case 6:
                case 7:
                case 8:
                    defense = "Hard defense."
                    break
                case 9:
                case 10:
                    defense = "Very hard defense."
                    break
                case 1:
                case 2: 
                case 3: 
                    defense = "Easy defense."
                    break
                case 4:
                case 5:
                default:
                    defense = "Normal defense." 
                    break
            }
        }

        let hack = ""
        if(props.item.protection){
            const pr = props.item.protection.parameters.defense.length - props.item.protection.parameters.attack.length
            switch( pr ) {
                case -3:
                case -2:
                    hack ="Very easy protection"
                    break
                case -1:
                    hack ="Easy protection"
                    break
                case 1:
                    hack ="Hard protection"
                    break
                case 2:
                case 3:
                    hack ="Very hard protection"
                    break
                case 0:
                default:
                    hack ="Protection"
                    break
            }
        }
        return __(defense) + " " + __(hack)
    }
    return <div className='pe-tile-protection-screen-container p-5'>
        <div className="display-5 mb-1">{
            props.item.title 
                ?
                props.item.title
                :
                __("Enter closed")
        }</div>
        <div className="mt-1 title">{ 
            getDesctiption() 
        }</div>
        <div className="mb-4 small">{ 
            props.item.post_content
                ?
                props.item.post_content
                :
                __("Do what you have to do")
        }</div>
        <Protection item={props.item} mode={mode} onWin={onWin}  onLose={onLose} /> 
        <ButtonGroup vertical={false}>
            {
                !!props.item.defense && 
                    <Button minimal onClick={onBreak}>
                        <LayoutIcon
                            src={ getBreakIcon() }
                            width={80}
                            height={80}
                        />
                        <div className="text-center small title text-uppercase text-light">
                            {__("Break")}
                        </div>  
                    </Button>
            }
            {
                !!props.item.protection &&
                    <Button minimal  onClick={onHack}>
                        <LayoutIcon
                            src="/assets/img/fabula/hack-icon.svg"
                            width={80}
                            height={80}
                        />
                        <div className="text-center small title text-uppercase text-light">
                            {__("Hack")}
                        </div>
                    </Button>
            }
            
            <Button minimal onClick={ onClose }>
                <LayoutIcon
                    src="/assets/img/fabula/refuse-icon.svg"
                    width={80}
                    height={80}
                />
                <div className="text-center small title text-uppercase text-light">
                    {__("Refuse")}
                </div>
            </Button>
        </ButtonGroup>
    </div>
}
export default TileProtectionScreen