import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import { Button, Dialog, Icon, NumericInput } from "@blueprintjs/core" 
import { __ } from "../utilities" 
import FieldInput, { SCALAR_TYPES } from "../scalars"
import { IState, useMainStore } from "@/settings/zustand"
//import { initArea } from "@pe/utilities" 

export interface IPagiProps {
  all: number
  count?: number
  current: number
  containerClassName? : string
  marginPagesDisplayed?: any
  pageRangeDisplayed? : any
  isHideNumerics? : boolean
  onChoose: ( arg0: number ) => void
}
const Pagi = (props: IPagiProps):JSX.Element | null => {
  const pageCount: number = useMainStore(( state: IState ) => state.pageCount)
  const setPageCount: any = useMainStore((state: IState ) => state.setPageCount)
  const[isOpen, setIsOpen] = useState(false)
  const [current, setCurrent] = useState<number>( props.current | 0 )
  const [all, setAll] = useState<number>( props.all | 0 )
  const {marginPagesDisplayed, pageRangeDisplayed} = props
  useEffect(() => setAll(props.all), [props.all])
  useEffect(() => setCurrent( props.current ), [ props.current ]) 

  const onChooseValue = (valueAsNumber : number) : void => {
    const i = valueAsNumber - 1
    setCurrent(i)
    props.onChoose(i)
  }
  const onOpen = () => setIsOpen( !isOpen )

  return typeof current !== "undefined"
    ?
    <div className="pagination-container d-flex w-100">
      <ReactPaginate
        previousLabel=""
        nextLabel=""
        breakLabel="..."
        breakClassName="break-me"
        pageCount={all + 1}
        marginPagesDisplayed={marginPagesDisplayed ? marginPagesDisplayed : 2}
        pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : 2}
        onPageChange={(data: any) => props.onChoose(data.selected)}
        containerClassName={`pagination pagination-sm ${props.containerClassName}`}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName=" page-item"
        nextLinkClassName="page-link" 
        breakLinkClassName="page-link"
        activeClassName="active"
        forcePage={current}
      />
      <Button minimal className="hover-pagination mb-2 "><Icon icon="cog" size={ 13 } onClick={onOpen}/></Button>
      {
        props.isHideNumerics
          ?
          null
          :
          <NumericInput
            large={true} 
            min={1}
            max={all + 1}
            value={current + 1}
            inputClassName="py-4"
            className="input dark ml-auto "
            onValueChange={onChooseValue }
          />
      }
      <Dialog
        isOpen={isOpen}
        onClose={onOpen}
        className="little"
      >
        <div className="p-5">
          <Button
              minimal
              onClick={() => setIsOpen(false)}
              className="position-absolute top dialog-close-btn" 
          >
              <Icon size={22} color="#ffffff" icon="cross"/>    
          </Button>
          <div className="lead mb-4">
            {__("Count elements by page:")}
          </div>
          <div>
            <FieldInput
              editable
              type={SCALAR_TYPES.NUMBER}
              value={pageCount}
              onChange={ ( value: number ) => setPageCount( value ) }
            />
          </div>
        </div>
      </Dialog>
      {/* {
        initArea(
          "pagination",
          { current, all, onchange },
        )
      } */}
    </div>
    :
    null
} 
export default Pagi

// export default class Pagi extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       all: props.all,
//       current: props.current,
//     }
//   }

//   componentWillReceiveProps(nextProps) {
//     //console.log( nextProps );
//     this.setState({
//       all: nextProps.all,
//       current: nextProps.current,
//     })
//   }

//   render() {
//     const { all, current } = this.state
//     const {marginPagesDisplayed, pageRangeDisplayed} = this.props
//     return typeof current !== "undefined"
//       ?
//       <>
//         <ReactPaginate
//           previousLabel=""
//           nextLabel=""
//           breakLabel="..."
//           breakClassName="break-me"
//           pageCount={all + 1}
//           marginPagesDisplayed={marginPagesDisplayed ? marginPagesDisplayed : 2}
//           pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : 2}
//           onPageChange={(data) => this.props.onChoose(data.selected)}
//           containerClassName="pagination pagination-sm"
//           pageClassName="page-item"
//           pageLinkClassName="page-link"
//           previousClassName="page-item"
//           previousLinkClassName="page-link"
//           nextClassName=" page-item"
//           nextLinkClassName="page-link" 
//           breakLinkClassName="page-link"
//           activeClassName="active"
//           forcePage={current}
//         >
//           adadf
//         </ReactPaginate>
//         <NumericInput
//           large={false}
//           min={1}
//           max={this.props.all + 1}
//           value={current + 1}
//           className="input dark ml-auto"
//           onValueChange={this.onChooseValue}
//         />
//         {
//           initArea(
//             "pagination",
//             { ...this.props, ...this.state },
//           )
//         }
//       </>
//       :
//       null
//   }

//   render2() {
//     const { all, current } = this.state
//     const btns = []
//     for (let i = 0; i < all; i++) {
//       const ii = i + 1
//       const cls = i == current ? " page-item active " : " page-item "
//       // console.log(cls);
//       btns.push(<li className={cls} key={i}>
//         <div className="page-link" onClick={this.onChoose} data-i={i}>
//           {ii}
//           {" "}
//         </div>
//       </li>)
//     }
//     return (
//       <ul className="pagination pagination-sm">
//         {btns}
//       </ul>
//     )
//   }

//   onChoose = (evt) => {
//     const i = parseInt(evt.currentTarget.getAttribute("data-i"))
//     this.setState({ current: i })
//     this.props.onChoose(i)
//   }

//   onChooseValue = (valueAsNumber) => {
//     const i = valueAsNumber - 1
//     this.setState({ current: i })
//     this.props.onChoose(i)
//   }
// }
// https://github.com/AdeleD/react-paginate/blob/master/demo/js/demo.js
