import { Button } from '@blueprintjs/core'
import React from 'react' 
import { IAdminStateProps, SIGN_TYPE } from '../../data/interfaces'
import { useAdminStore } from '../../data/store'
import { actions } from '../../data/actions'
import { SET_SIGN_TYPE } from '../../data/actionTypes'

const SignTypeSwitcher = (): JSX.Element => {
    const signType: SIGN_TYPE = useAdminStore( ( state:IAdminStateProps ) => state.signType)
    const setSign = (type: SIGN_TYPE) => {
        actions(SET_SIGN_TYPE, type )
    }
    return <div>
        <Button 
            icon='list' 
            minimal 
            disabled={ signType === SIGN_TYPE.ROW }
            onClick={() => setSign( SIGN_TYPE.ROW )}
        />
        <Button 
            icon='grid-view' 
            minimal  
            disabled={ signType === SIGN_TYPE.CARD || !signType }
            onClick={() => setSign( SIGN_TYPE.CARD )}
            
        />
    </div> 
} 

export default SignTypeSwitcher