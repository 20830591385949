import { IOrganoidType, ORGANOID_TYPE } from "./interfaces"

export const organoidTypes = () : IOrganoidType[] => {
    const list: IOrganoidType[] = [
        {
            id: "defense",
            title: "Mental defense",
            thumbnail: "",
            min: 2,
            max: 5,
            icon: "/assets/img/manitu/icons/defense.jpg",
            post_content: "Every cell of organoid add some defense energies every game cirle.", 
            type: ORGANOID_TYPE.DEFENSE,
            fill: "#9be747"
        },
        {
            id: "empaty",
            title: "Empaty",
            post_content: "Supports activities related to mental connection with other Heroes", 
            thumbnail: "",
            icon: "/assets/img/manitu/icons/heart.jpg",
            min: 1,
            max: 4,
            type: ORGANOID_TYPE.EMPATY,
            fill: "#158fcc"
        },
        {
            id: "communication",
            title: "Communication",
            icon: "/assets/img/manitu/icons/knowlege.jpg",
            post_content: "Increases the “audibility” of your actions in the Manitu.", 
            thumbnail: "",
            min: 1,
            max: 3,
            type: ORGANOID_TYPE.COMMUNICATION,
            fill: "#e7479d"
        },
        {
            id: "telekinesis",
            title: "Telekinesis",
            icon: "/assets/img/manitu/icons/boom.jpg",
            post_content: "Ability to create kinetic impulse in relation to material objects.", 
            thumbnail: "",
            min: 1,
            max: 2,
            type: ORGANOID_TYPE.TELE_KINESIS,
            fill: "#c33f0d"
        },
        {
            id: "chanse",
            title: "Chanse",
            icon: "/assets/img/manitu/icons/flower.jpg",
            post_content: "Increases luck, helps find optimal solutions.", 
            thumbnail: "",
            min: 1,
            max: 2,
            type: ORGANOID_TYPE.CHANSE,
            fill: "#239706"
        },
    ]
    return list
}