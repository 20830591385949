import { Button, Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { IManituAction, IManituActionType, IOrganoid } from "src/modules/pe-manitu-module/data/interfaces"
import useSpell from "src/modules/pe-manitu-module/hooks/useSpell"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { Dummy, ScalableDialog } from "src/libs/useful"
import { IManitu, useManitu } from "../../../data/store"
import { ICellProps } from "./Cell"
import Organoid from "./organoid/Organoid"
import { IDoActionReturn } from "@/modules/pe-manitu-module/data/mocks/doAction"

const CellEnemy = (props: ICellProps) :JSX.Element => {
    const isActionChoose: boolean = useManitu((state:IManitu) => state.isActionChoose)
    const choosedAction: IManituActionType = useManitu((state:IManitu) => state.choosedAction)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [attack, setAttack] = useState<number>( -1 )
    const [organoid, setOrganoid] = useState<IOrganoid>( {} as IOrganoid )
    useEffect(() => { 
        const a = props.actions.reduce(( prev: number, cur: IManituAction ) =>  {
            return prev + cur.count  
        }, 0)
        setAttack( a )
    }, [props.actions])
    useEffect(() => {
        const myOrganoid: IOrganoid = props.organoids.filter( o => {
           return  !!o.cells.filter(c => c.x === props.item.i && c.y === props.item.rowID).length
        })[0]
        if( myOrganoid ) {
            setOrganoid( myOrganoid )
        }
    }, [])
    const speller = useSpell()

    let added = ""
    if(!!props.actions.length) {
        added += " border border-danger "
    }
    if( !!props.item.isBroken ) {
        added += " cell-crashed "
    }
    if(isActionChoose) {
        added += " cell-by-action "
    }

    const onAction = () => {
        if(isActionChoose) { 
            speller.act(props.item, choosedAction)
                .then((response: IDoActionReturn) => {
                    props.onActionResult( props.item, choosedAction, response)
                })
        }
        else {
            setIsOpen(!isOpen)
        } 
    }
    return <>
        <div 
            className={`cell ${ added  }`} 
            data-cell-id={ props.item.id } 
            onClick={ onAction }
        >   
            {
                !props.item.isBroken 
                    ?
                    <></>
                    :
                    <Organoid item={ props.item } organoid={ organoid } isBroken={props.item.isBroken}/>
            }         
        </div>
        <ScalableDialog
            isOpen={isOpen}
            onClose={() => setIsOpen(!isOpen)}
            dialogSize={ DIALOG_SIZE.LITTLE }
        >
            <div className="p-5">
                <Dummy width={100} height={100} />
                
            </div>
            <Button minimal onClick={() => setIsOpen(!isOpen)} className="position-absolute top right px-3 py-2">
                <Icon icon="cross" size={20} color="#FFFFFF"/>
            </Button>
        </ScalableDialog>
    </>
} 

export default CellEnemy 