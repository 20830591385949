
/* Protopia Ecosystem component */
export interface ITestProps {

}
const Test = (props: ITestProps): JSX.Element => {
    return <div className='pe-test-container'>
        TEST
    </div>
}
export default Test