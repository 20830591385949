import { Navigate, Outlet, Route, Routes } from "react-router" 
import { NavLink } from "react-router-dom"
import { CSSProperties } from "react"

export interface IPETabs { 
    tabs: IPETab[]
    tabId? : string
    className?: string
    style?: CSSProperties
}
export interface IPETab {
    id: string
    title: string 
    element: JSX.Element
    className?: string
    style?: CSSProperties
}
interface IPETabButtonProps {
    item: IPETab
}
const PETabs = (props: IPETabs) : JSX.Element => {
    return <div className={`pe-tabs-container ${props.className}`} style={props.style}>
        <div className="pe-tabs-btn-group">
            {
                props.tabs.map( tab => {
                    return <PETabButton 
                        key={tab.id}
                        item={tab}
                    />
                })
            }
        </div>
        <Routes>
            {
                props.tabId && <Route index element={<Navigate to={props.tabId} />} />
            }
            <Route element={<Tabs />}>
                {
                    props.tabs.map( tab => {
                        return <Route
                            path={`${tab.id}/*`}
                            key={tab.id}
                            element={tab.element}
                        />
                    })
                }
            </Route>
        </Routes>
    </div>
}
export default PETabs

const Tabs = (props: any) : JSX.Element => {
    return <div className="pe-tabs h-100">
        <Outlet />
    </div>
}

const PETabButton = (props : IPETabButtonProps): JSX.Element => {
    return <NavLink 
        className={`pe-tab-btn ${props.item.className}`} 
        style={props.item.style} 
        to={props.item.id}
    >
        {props.item.title}
    </NavLink>
}