import { IState, useMainStore } from "src/settings/zustand"
import { Button, ButtonGroup, Icon } from "@blueprintjs/core"

interface IProps {
    data: any[]
    i : number
    setI: (i: number) => void 
    setIsOpen: (isOpen: boolean) => void 
}
const DialogRight = ({ data, i, setI, setIsOpen }: IProps) : JSX.Element => {
    const isGlleryFullSize = useMainStore((state: IState) => state.isGlleryFullSize)
    const isGalleryOpenPics = useMainStore((state: IState) => state.isGalleryOpenPics) 
    const onFullSize = ( ) => {
        useMainStore.setState({ isGlleryFullSize: !isGlleryFullSize })
    }
    const onPiscOpen = () => {
        useMainStore.setState({ isGalleryOpenPics: !isGalleryOpenPics })
    }
    return <div className={`position-absolute top right h-100 d-flex`}>
        <div className="position-relative m-1">
            <div className="top right">                        
                <div className="next ">
                    <Button   
                        onClick={() => setI( i< data.length - 1 ? i + 1 : 0 )}  
                        className="bg-dark-secondary hover-straght" 
                        
                        >
                        <Icon icon="chevron-right" size={ 33 } color="#FFFFFF"/>
                    </Button>
                </div>
            </div>
            <ButtonGroup  className="bg-dark-secondary "> 
                <Button minimal onClick={ onFullSize } className="p-3 hover-straght" >
                    <i className={`fas fa-${ isGlleryFullSize ? "compress" : "expand" } text-giant text-light`} />
                </Button>
                {
                    data.length > 1 && <Button minimal onClick={ onPiscOpen } className="p-3 hover-straght" >
                        <i className={`fas fa-th text-giant  ${ isGalleryOpenPics ? "text-danger" : "text-light" }`}></i>
                    </Button>
                } 
                <Button minimal onClick={ () => setIsOpen(false) } className="p-3  hover-straght" >
                    <i className="fas fa-times text-giant text-light"></i>
                </Button>
            </ButtonGroup>
        </div>
        <div className={`h-100 transition-300 bg-dark-secondary ${isGalleryOpenPics ? "w_100 p-1 overflow-y-auto" : "overflow w_0"}` }>
            {
                data.map((img, n) => {
                    return <div 
                        className={`hover-force thumbnail gallery-icon ${n === i ?  "active" : ""}`} 
                        onClick={ () => setI(n) }
                        key={img}
                    >
                        <img src={img} alt="" />
                    </div>
                })
            }
        </div> 
    </div> 
} 

export default DialogRight