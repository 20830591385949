import { URL } from "@/libs/interfaces/layouts";
import { IRSSChannel, IRSSItem } from "../../data/interfaces";

const parseRSS = async ( src:URL ): Promise<IRSSItem[]> => {
    const urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
        if ( urlRegex.test( src )) {
            const res = await fetch(`https://api.allorigins.win/get?url=${src}`);
            console.log(res)
            const { contents } = await res.json();
            const feed = new window.DOMParser().parseFromString(contents, "text/xml");
            const _channel: NodeListOf<Element> = feed.querySelectorAll("channel");
            const _channelData: IRSSChannel = Array.from( _channel ).map((el: any) => {
                return {
                    title: el.querySelector("title").innerHTML,
                    description: el.querySelector("description").innerHTML,
                    link: el.querySelector("link").innerHTML
                }
            })[0]
            console.log( _channelData )
            const _items: NodeListOf<Element> = feed.querySelectorAll("item");
            const feedItems: IRSSItem[] = Array.from(_items).map((el: any) => ({
                link: el.querySelector("link").innerHTML.replace("<![CDATA[", "").replace("]]>", ""),
                title: el.querySelector("title").innerHTML.replace("<![CDATA[", "").replace("]]>", ""), 
                description: el.querySelector("description").innerHTML.replace("<![CDATA[", "").replace("]]>", ""), 
                enclosure: {
                    url: el.querySelector("enclosure")?.getAttribute("url"),
                    length: el.querySelector("enclosure")?.getAttribute("length"),
                    type: el.querySelector("enclosure")?.getAttribute("type")
                },
                channel: _channelData
            }));
            //console.log( feedItems )
            return feedItems 
        }
        else return []
}

export default parseRSS