import { getAdminRoutes } from "@/modules/pe-topos-module/views/admin/getAdminRoutes"
import { __ } from "@/libs/utilities"
import { Icon } from "@blueprintjs/core"
import { NavLink, useLocation } from "react-router-dom" 


/* Protopia Ecosystem component */
export interface ICabinetBreadcrumbsProps {
    route?: string 
    title?: string
}
const CabinetBreadcrumbs = (props: ICabinetBreadcrumbsProps): JSX.Element => {
    const location = useLocation() 
    const allRoutes = getAdminRoutes()
    const breadcombs: any[] = location.pathname.split(props.route || "")[1].split("/")
        .map((href, i) => ({
            href: href ? href : -1,
            text: href 
                ? 
                allRoutes[href]?.title || href
                : 
                props.title 
                    ?
                    props.title
                    :
                    "Cabinet" 
        }))
    return <div className='d-flex flex-row mb-2 card p-2'>
        <div>
            <NavLink to={ `/festival-admin` }>{ __("Events managment") }</NavLink>
            <Icon icon="chevron-right" className='mx-2'/>
        </div>
        {
            Array.isArray(breadcombs)
                ?
                breadcombs.map((comb: any, i: number) => {
                    const next = i ? <Icon icon="chevron-right" className='mx-2'/> : null
                    const element = i < breadcombs.length -1 
                        ?
                        <NavLink to={comb.href}>{ __(comb.text) }</NavLink>
                        :
                        __(comb.text)
                    return <div key={ i }>
                        { next }
                        { element }
                    </div>
                })
                :
                null
        }
    </div> 
}
export default CabinetBreadcrumbs
 