import React from "react" 
import BasicState from "src/libs/basic-view"
import AdForm from "./ads/AdForm" 
import "../assets/css/style.scss"

export default class AdView extends BasicState {
    props: any
    addRender = () => {
        return <AdForm {...this.props} />  
    }
}