import { IPETest } from "../interfaces"
import { questions } from "./questions"
import { testCategories } from "./testCategories"

export const test = (): IPETest => {
    return {
        id: 1,
        title: "Краеведение",
        post_content: "цикл вопросов по краеведению",
        testCategory: testCategories().filter(t => t.id === 1)[0],
        thumbnail: "https://i.pinimg.com/564x/59/7d/48/597d48fe296828c3364d33eb6b8fa733.jpg",
        questions: questions(),
        is_show_answer:false, 
        is_show_results:true, 
        is_shuffle:true, 
        is_timed:false, 
        duration: 20,
        try_count: 10,
        post_author: {
            id: "1",
            display_name: "Геннадий Глазунов",
            roles: []
        },
        pe_academic_discipline: [{
            id: '24',
            thumbnail: 'false',
            title: 'Краеведение',
            post_content: '',
            order: 1,
            color: '#007926',
        }],
    }
}