import React from 'react' 

const feedDataComponent = (props: any): JSX.Element => {
    return (
        <div>
            
        </div>
    )
} 

export default feedDataComponent