import { IUser } from "src/settings/zustand"
import { IManituActionType, IManituCell, IManituEnergyPrice, IUserMana } from "../interfaces"
import { useManituStore } from "../store"
import { actionTypes } from "./actionTypes"
import { DoDefine } from "./do_action/DoDefine"
import { DoDiamand } from "./do_action/DoDiamand"
import DoStorm from "./do_action/DoStorm"


export interface IDoActionReturn {
    oldValue: any
    newValue: any,
    action?: IManituActionType
}
const doAction = ( cell: IManituCell, action: IManituActionType, user: IUser ) : IDoActionReturn => { 
    let result : IDoActionReturn = { action } as IDoActionReturn 

    console.log( action.price )

    if( !verifyAction( action.price ) ) {
        return {} as IDoActionReturn
    }
    getActionPrice( action.price ) //IManituActionType

    switch(action.id) {
        case actionTypes()[0].id: //Define
            result =  DoDefine( cell, actionTypes()[0], user ) 
            return result
        case actionTypes()[1].id: //Defune 10 
            result =  DoDiamand( cell, actionTypes()[1], user ) 
            return result
        case actionTypes()[2].id: //Add organoid's node
            return result
        case actionTypes()[3].id: //Heal cell after attack
            return result
        case actionTypes()[4].id: //Check Cell
            return result
        case actionTypes()[5].id: //Attack
            return result
        case actionTypes()[6].id: //Storm
            return DoStorm( cell, actionTypes()[6], user ) 
        case actionTypes()[7].id: //Transfer
            return result
        default:
            return result
    }
}

export default doAction

const verifyAction = ( price: IManituEnergyPrice[] ) : boolean => {
    const userMana: IUserMana = useManituStore.getState().userMana 

    // verify price and the user's solvency
    return !price.filter( p => userMana[p.type].count < p.nominal ).length 
}

const getActionPrice = ( price: IManituEnergyPrice[] ) => {
    const userMana: IUserMana = useManituStore.getState().userMana
    // get payment
    let _userMana = {...userMana}
    price.forEach(p => {
        _userMana[p.type].count = _userMana[p.type].count - p.nominal
    }) 
    useManituStore.setState({ userMana : _userMana})
}