import { Button, ButtonGroup, Collapse, Intent } from '@blueprintjs/core'
import React, {useEffect, useState} from 'react' 
import { __ } from '@/libs/utilities'
import { getQueryData, getQueryArgs, apolloFields, schema } from 'src/libs/layouts'  
import { IAppoloField, APOLLO_FIELD_FILTER_TYPE, ID } from '@/libs/interfaces/layouts'
import MetaFilterElement from './MetaFilterElement'
import { useMainStore, IState  } from '../../../../settings/zustand'
import { IAdminStore, IBulk, useAdminStore } from '../../data/store'
import BulkEditor from './BulkEditor'
import BulkDeleter from './BulkDeleter'
import BulkVisibler from './BulkVisibler'

const DataTableFiltersPanel = (props: IDataTableFilterProps): JSX.Element => {
    const bulks: IBulk[]    = useAdminStore((state:IAdminStore) => state.bulks)
    const landId : ID       = useMainStore((state: IState) => state.landId )
    const { filter }                            = getQueryData( props.data_type )?.admin_data
    const [filters,]                            = useState<any[]>( [] )
    const [metaFilters, setMetaFilters]         = useState<any[]>( [] )
    const [relation, setRelation]               = useState( 'OR' )
    const [isSettingsOpen, setIsSettingsOpen]   = useState( false )
    const [isFilterOpen, setIsFilterOpen]       = useState( false )

    const pageCount: number = useMainStore(( state: IState ) => state.pageCount)
    const setPageCount: any = useMainStore((state: IState ) => state.setPageCount)
    console.log( landId )
    useEffect(() => {
        // set filters and meta_filters
        const fields: IAppoloField[] = Object.keys( apolloFields( props.data_type ) ).map( af => ({ ...apolloFields( props.data_type )[af], name: af }) )
        const _metaFilters: any[] = []
        fields.forEach( (field: any) => { 
            if( field.filter_type === APOLLO_FIELD_FILTER_TYPE.TAXONOMIES ) {

            }
            if( field.filter_type === APOLLO_FIELD_FILTER_TYPE.META ) { 
                _metaFilters.push(
                    <MetaFilterElement 
                        key={ Math.random() } 
                        field={ field } 
                    /> 
                )
            }
        }) 
        setMetaFilters(_metaFilters)
    }, [props.data_type])

    const filterBtn = filters.length > 0 || metaFilters.length > 0
	    ?
		<Button 
            minimal 
            icon={ isFilterOpen ? "filter-remove" : "filter" }
            onClick={ () => setIsFilterOpen(!isFilterOpen ) }
        >
			{ __(isFilterOpen ? "hide Filters" : "show Filters") }
		</Button>
	    :			
		null
	  const filterBlock = filters.length > 0 || metaFilters.length > 0
	    ? 
        <Collapse isOpen={isFilterOpen} className="w-100">
            <div className="p-3 pe-light mt-2">
                <div className="d-flex flex-wrap mb-2">
                    { filters }
                    { metaFilters }
                </div>
                <div className="d-flex flex-wrap">
                    <ButtonGroup className="flex-wrap ">
                        <Button
                            minimal={relation === "AND"}
                            intent={Intent.SUCCESS}
                            data-relation="OR"
                            onClick={() => setRelation('OR')}
                        >
                            { __("All by any fields")}
                        </Button>
                        <Button
                            minimal={relation === "OR"}
                            intent={Intent.DANGER}
                            data-relation="AND"
                            onClick={() => setRelation('AND')}
                        >
                            {__("Only have all fields")}
                        </Button>
                    </ButtonGroup>
                    <Button minimal onClick={props.clearFilter} className="ml-3 w-32 w-sm-100">
                        { __("clear filters") }
                    </Button>
                </div>
            </div>
        </Collapse> 
	    :			
		null 
    return <>
        <div className="d-flex">
            <Button
                minimal
                className="ml-2"
                icon="cog"
                onClick={() => setIsSettingsOpen( !isSettingsOpen )}
            >
                { __( isSettingsOpen ? "hide Settings" : "show Settings") }
            </Button>
            { filterBtn }
        </div>
        <Collapse isOpen={ isSettingsOpen } className="w-100">
            <div className="p-3 pe-light mt-2 border-bottom-dark">
                <div className="row align-items-center ">
                    <div className="h-100 col-md-5 text-md-left text-center  align-items-center">
                        {__("Count elements by page:")}
                    </div>
                    <input
                        type="number"
                        className="form-control input dark ml-2 col-md-6"
                        value={ pageCount }
                        onChange={ (evt: any ) => setPageCount( evt.target?.value )}
                    />
                </div>
            </div>
        </Collapse>
		{ filterBlock } 
        <Collapse isOpen={ !!bulks.filter((bulk: IBulk) => bulk.checked).length } className="w-100">
            <div className="p-3 pe-light mt-2 border-bottom-dark">
                <ButtonGroup className="d-flex align-items-center flex-wrap">
                    <BulkDeleter data_type={props.data_type}/> 
                    <BulkEditor data_type={props.data_type} landId={landId} /> 
                    <BulkVisibler data_type={props.data_type}/>                    
                </ButtonGroup>
            </div>
        </Collapse>
    </>
} 

export default DataTableFiltersPanel

export interface IDataTableFilterProps {
    data_type: string
    data_status?: string
    count: number
    onChangeCount : ( count: number ) => void
    clearFilter : () => void
}