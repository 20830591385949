import { ILayoutData, IMenuItem, MENU_VIEW_TYPES } from "@/libs/interfaces/layouts"
import { roles, schema } from "@/libs/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import { Collapse, Tab, Tabs } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import PageTypeSettings from "../edtorCabinet/PageTypeSettings"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { IAdminMenuStore, useAdminMenuStore } from "../../data/store"

interface IProps {
    data: IMenuItem
    onChange: ( value: any, field: string, _data: any ) => void
    getRoute : (realRoute: string) => void
    getPreRoute : (realRoute: string) => void
}
const SingleMenuEditForm = ({data, ...props}: IProps) : JSX.Element=> {
    const checkPageType = () =>
    {
        const f =  ( Object.keys(MENU_VIEW_TYPES)).filter( ( key: string ) => { 
            // console.log( MENU_VIEW_TYPES[ key ]._id, data[ MENU_VIEW_TYPES[ key ]._id as keyof IMenuItem ] )
            return typeof data[ MENU_VIEW_TYPES[ key ]._id as keyof IMenuItem ] !== "undefined" 
        })[0] || ""
        return f.toLocaleLowerCase()
    }
    const [navbarTabId, setNavbarTabId] = useState<string>("settings") 
    const [pageType, setPageType] = useState<string>( checkPageType() ) 
    const layouts: ILayoutData = useAdminMenuStore((state: IAdminMenuStore) => state.layoutsClone)
    useEffect(() => {
        setPageType( checkPageType() )
    }, [ data ])
    const onField = ( value: any, field: string, _data: any ) => { 
        props.onChange(value, field, _data)
    }
    const onPageTypeField = ( value: any ) => {
        // Object.keys(MENU_VIEW_TYPES).forEach(key => {
        //     props.onChange(
        //         null, 
        //         MENU_VIEW_TYPES[ key ]._id,
        //         _data
        //     )
        // })
        // console.log(value)
        // props.onChange(value, field, _data)
        setPageType( value )
    }
    return <div className="page-setting-container">
        <Tabs
            className="d-flex flex-column align-items-center tab-light-head w-100 " 
            selectedTabId={navbarTabId}
            vertical={false}
            large={true}
            onChange={ (nb: string) => setNavbarTabId( nb ) }
        >  
            <Tab 
                id="settings"  
                panelClassName="w-100 h-500"
                title={ __( "Settings" ) }
                panel={ <>
                    <div className=" setting-tab-panel overflow-y-auto">
                        <FieldInput
                            value={ data.title} 
                            field="title"
                            type={SCALAR_TYPES.STRING} 
                            editable
                            title={__("Title")}
                            commentary={__("How will this View appear in different menus?")}
                            onChange={ onField } 
                        />
                       <FieldInput
                            value={ data.hide_title }
                            title={ __("Hide title in screen") } 
                            type={ SCALAR_TYPES.BOOLEAN }
                            field="hide_title"
                            editable
                            onChange={ onField }
                        />
                        <FieldInput
                            value={props.getRoute(data.route)} 
                            type={SCALAR_TYPES.STRING} 
                            editable
                            commentary={ __( "Route of the View within the application" ) }
                            field="route" 
                            prefix={`${window.location.origin}/${props.getPreRoute(data.realRoute)}`}
                            postfix={
                                <a 
                                    href={`${window.location.origin }${ data.realRoute }`} 
                                    className="btn btn-link text-force-contrast"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {__("Goto")}
                                </a>
                            }
                            title={__("Route")}
                            onChange={ onField } 
                        /> 
                        <FieldInput
                            value={data.icon}
                            type={SCALAR_TYPES.ICON} 
                            editable
                            commentary={ __( "Icon of this page in menus" ) }
                            field="icon"  
                            title={__("Icon")}
                            onChange={ onField } 
                        /> 
                        <FieldInput
                            value={data.thumbnail} 
                            type={SCALAR_TYPES.MEDIA} 
                            height={200}
                            editable
                            field="thumbnail" 
                            title={__("Thumbnail")}
                            commentary={__("Identifies the View in the editor's account. Used in some components to render content")}
                            onChange={ onField } 
                        />
                        <FieldInput
                            value={data.description}  
                            type={SCALAR_TYPES.HTML} 
                            editable
                            field="description" 
                            title={__("Description")}
                            commentary={__("This description will be visible in some places in the application administration panel")}
                            onChange={ onField } 
                        />
                    </div> 
                </> }
            /> 
            <Tab 
                id="type"  
                title={__("Page type settings")}
                panelClassName="w-100 h-500"
                panel={
                    <div className="setting-tab-panel overflow-y-auto">  
                        <FieldInput
                            value={pageType}
                            type={SCALAR_TYPES.RADIO} 
                            editable
                            field="type" 
                            title={__("Content type")}
                            values={ Object.keys(MENU_VIEW_TYPES).map(key =>  MENU_VIEW_TYPES[ key ] ) }         
                            onChange={ onPageTypeField }
                        />
                        <Collapse isOpen={pageType === MENU_VIEW_TYPES.COMPONENT._id} >
                            <PageTypeSettings
                                data={data}
                                onField={onField}
                            />
                        </Collapse>
                        <Collapse isOpen={pageType === MENU_VIEW_TYPES.HTML._id} >
                            <FieldInput
                                value={ data.html }
                                editable
                                type={SCALAR_TYPES.HTML} 
                                field="html" 
                                title={__("Content")}
                                onChange={ onField } 
                            />
                        </Collapse>
                        <Collapse isOpen={pageType === MENU_VIEW_TYPES.HTML_SOURCE._id} >
                            <FieldInput
                                value={ data.html_source }
                                editable
                                type={SCALAR_TYPES.URL} 
                                field="html_source" 
                                title={__("HTML source url")}
                                commentary={ __("imported content ignored all HTML tags and JS-scripts") }
                                onChange={ onField } 
                            />
                        </Collapse>
                        <Collapse isOpen={pageType === MENU_VIEW_TYPES.SINGLED_DATA_TYPE._id} >
                            <FieldInput
                                value={ data.singled_data_type }
                                editable
                                type={SCALAR_TYPES.TAGS} 
                                values={ Object.keys(schema()).map(key => {
                                    return {
                                        _id: key,
                                        title: __(schema()[key].name)
                                    }
                                }) }
                                field="singled_data_type" 
                                title={__("Data Type")} 
                                onChange={ onField } 
                            />
                        </Collapse>
                        <Collapse isOpen={pageType === MENU_VIEW_TYPES.FEED_DATA_TYPE._id} >
                            <FieldInput
                                value={ data.feed_data_type }
                                editable
                                type={SCALAR_TYPES.TAGS} 
                                values={ Object.keys(schema()).map(key => {
                                    return {
                                        _id: key,
                                        title: __(schema()[key].name)
                                    }
                                }) }
                                field="feed_data_type" 
                                title={__("Data Type")} 
                                onChange={ onField } 
                            />
                        </Collapse>
                        <Collapse isOpen={pageType === MENU_VIEW_TYPES.SINGLE_DATA_TYPE._id} >
                            <FieldInput
                                value={ data.single_data_type }
                                editable
                                type={SCALAR_TYPES.TAGS} 
                                values={ Object.keys(schema()).map(key => {
                                    return {
                                        _id: key,
                                        title: __(schema()[key].name)
                                    }
                                }) }
                                field="single_data_type" 
                                title={__("Data Type")} 
                                onChange={ onField } 
                            />
                        </Collapse>
                        <Collapse isOpen={pageType === MENU_VIEW_TYPES.DATA_TYPE._id} >
                            <FieldInput
                                value={ data.data_type }
                                editable
                                type={SCALAR_TYPES.TAGS} 
                                values={ Object.keys(schema()).map(key => {
                                    return {
                                        _id: key,
                                        title: __(schema()[key].name)
                                    }
                                }) }
                                field="data_type" 
                                title={__("Data Type")} 
                                onChange={ onField } 
                            />
                        </Collapse>
                        
                    </div>
                }
            />   
            <Tab 
                id="design"  
                title={__("Design")}
                panelClassName="w-100 h-500"
                className=" "
                panel={
                    <div className="setting-tab-panel overflow-y-auto">
                        <FieldInput
                            //title = "Extended parameters" 
                            vertical={ VerticalType.VERTICAL }
                            commentary={ __("Design of View (background, colors)" )}
                            type={SCALAR_TYPES.BOOLEAN} 
                            visualization="extend-params-scalars"  
                            field="pageType" 
                            id={ data.id } 
                            on={ onField }
                            onChange={ onField }
                            editable
                            origin={data}
                            value={ data.extend_params } 
                            notClear
                        />
                    </div>
                }
            />   
            <Tab 
                id="SEO"  
                title={__("SEO")}
                panelClassName="w-100 h-500"
                className="hidden"
                panel={
                    <div className="setting-tab-panel overflow-y-auto">
                        SEO
                    </div>
                }
            />   
            <Tab 
                id="menu"  
                title={__("Menu")}
                panelClassName="w-100 h-500"
                className=""
                panel={
                    <div className="setting-tab-panel overflow-y-auto">
                        <FieldInput
                            value={ data.menu }
                            field="menu"
                            visualization="admin-route-menu-chooser"
                            type={SCALAR_TYPES.STRING}  
                            title={__("Menu choose")}  
                            onChange={ onField } 
                            origin={ layouts.routing }
                            editable 
                        />
                    </div>
                }
            /> 
            <Tab 
                id="permissions"  
                title={__("Permissions")}
                panelClassName="w-100 h-500"
                panel={
                    <div className="setting-tab-panel overflow-y-auto">
                        <FieldInput
                            value={ data.is_hidden }
                            title={ __("Hidden") } 
                            type={ SCALAR_TYPES.BOOLEAN }
                            field="is_hidden"
                            editable
                            onChange={ onField }
                        />
                        <FieldInput
                            title={__("Hidden for unlogged")}
                            type={SCALAR_TYPES.BOOLEAN} 
                            field="islogged"
                            editable 
                            value={ data.islogged } 
                            id="islogged"
                            onChange={onField}
                            />
                        <FieldInput
                            title={__("Exlusive access by role")}
                            type={SCALAR_TYPES.CHECKBOX} 
                            field="capability"
                            editable 
                            value={ data.capability }
                            values={ roles() }
                            id="contenttype"
                            onChange={ onField }
                        />
                    </div>
                }
            />
            <Tab 
                id="actions"  
                title={__("Actions")}
                panelClassName="w-100 h-500"
                className="hidden"
                panel={
                    <div className="setting-tab-panel overflow-y-auto">
                        Actions
                    </div>
                }
            />
            <Tab 
                id="additionally"  
                title={__("Additionally")}
                panelClassName="w-100 h-500"
                panel={
                    <div className="setting-tab-panel overflow-y-auto">
                        <FieldInput
                            value={ data.is_left }
                            type={SCALAR_TYPES.BOOLEAN} 
                            editable
                            field="is_left" 
                            title={__("Show left menu on Page")}
                            onChange={ onField } 
                        />
                        <FieldInput
                            value={ data.help_url }
                            type={SCALAR_TYPES.URL} 
                            editable
                            field="help_url" 
                            title={__("Help source")}
                            onChange={ onField } 
                        />
                        <FieldInput
                            value={ data.helps } 
                            visualization="admin-menu-item-help-youtube"
                            editable
                            notClear
                            field="helps" 
                            title={__("Help youtube source")}
                            onChange={ onField } 
                        />
                    </div>
                }
            /> 
        </Tabs>
    </div>
} 

export default SingleMenuEditForm