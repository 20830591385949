import { IPETest, PE_TEST_QUESTION_TYPE } from "../interfaces" 
import { testCategories } from "./testCategories"

export const test5 = (): IPETest => {
    return {
        id: 5,
        title: "Бажов П. «Медной горы Хозяйка»",
        post_content: "Тест по литературе для 5 класса",
        testCategory: testCategories().filter(t => t.id === 2)[0],
        pe_academic_discipline: [{
            color: '#214700',
            id: '25',
            order: 6,
            post_content: '',
            thumbnail: 'false',
            title: 'Литература',
        }],
        thumbnail: "https://i.pinimg.com/564x/c2/ea/d9/c2ead9bc5723c0be63c071e640e21e94.jpg",
        questions: [
            {
                id:1,
                title: "В какой сборник входит сказ Бажова П. «Медной горы Хозяйка»?",
                thumbnail:"https://avatars.dzeninfra.ru/get-zen_doc/1720666/pub_64d7c6bb60b16c687f15189f_64d893089d92d9510b30bfab/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "«Уральские были»",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "«Каменный цветок»",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "«Малахитовая шкатулка»",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "«Сказы о немцах»",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 2,
                title: "Укажите верное утверждение:",
                thumbnail:"https://avatars.dzeninfra.ru/get-zen_doc/10384921/pub_64d7c6bb60b16c687f15189f_64d89342cf55584e18c141c9/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Сказ и сказка - это одно и то же.",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Сказ – это вымысел с волшебно-фантастическим сюжетом.",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "В основе сказа лежат реальные события, основанные на народных преданиях и легендах, о которых повествует рассказчик.",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 3,
                title: "Куда отправились заводские парни в начале повествования?",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/30884/pub_64d7c6bb60b16c687f15189f_64d89380d5a4066e1abf7cb0/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "пасти лошадей",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "косить траву",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "в лес за грибами",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "добывать руду",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 4,
                title: "Кого увидел парень, когда проснулся?",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/1781308/pub_64d7c6bb60b16c687f15189f_64d8af079d644c0228fd8242/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Бабу Ягу",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "ведьму",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "русалку",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "женщину с косой",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 5,
                title: "По какому признаку герой понял, что перед ним предстала Хозяйка Медной горы?",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/4721351/pub_64d7c6bb60b16c687f15189f_64d8af7141ea942bb0940e0b/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "по причёске",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "по одежде",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "по украшениям",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "по речи",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 6,
                title: "Как обратилась Хозяйка к парню? Укажите имя героя.",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/59126/pub_64d7c6bb60b16c687f15189f_64d8942cb937b2784218c20c/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Пётр Иванович",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Пётр Степановича",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Степан Петрович",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 7,
                title: "Кто окружал Хозяйку? Кого она назвала своим войском?",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/1554513/pub_64d7c6bb60b16c687f15189f_64d894389d644c0228cef9e5/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "муравьёв",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "змей",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "горных духов",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "ящериц",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 8,
                title: "Что приказывает сделать Хозяйка герою?",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/1875669/pub_64d7c6bb60b16c687f15189f_64d9e5c065ccd60f0b07254b/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "самому не работать на Красногорском руднике",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "передать заводскому приказчику её наказ убираться с рудника",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "жениться на ней.",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 9,
                title: "Что Хозяйка Медной горы пообещала главному герою, если он выполнит её наказ?",
                post_content: "",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/1881616/pub_64d7c6bb60b16c687f15189f_64d8ad6565ccd60f0b7db052/scale_1200",
                answers : [
                    {
                        id: 1,
                        title: "сделать его заводским приказчиком",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "выйти за него замуж",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "вознаградить драгоценными камнями",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 10,
                title: "Героя приковали на цепь. Автор пишет: «Известно, какое время было, - крепость. Всяко галились над человеком». Что имеется в виду?",
                post_content: "",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/4715514/pub_64d7c6bb60b16c687f15189f_64d9d44ea14ed76cfb2a4e13/scale_1200",
                answers : [
                    {
                        id: 1,
                        title: "война",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "крепостное право",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "тюрьма",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 11,
                title: "Как малахитница отблагодарила главного героя за выполненное поручение?",
                post_content: "",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/1245815/pub_64d7c6bb60b16c687f15189f_64d9e567a14ed76cfb4f23d2/scale_1200",
                answers : [
                    {
                        id: 1,
                        title: "забыла о нём",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "помогла выполнить урок",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "затопила Гумешки",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 12,
                title: "Что пообещал приказчик главному герою за добычу малахитовой глыбы во сто пуд?",
                post_content: "",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/4055632/pub_64d7c6bb60b16c687f15189f_64d8a7c9424c0121ac1156f4/scale_1200",
                answers : [
                    {
                        id: 1,
                        title: "уменьшить урок",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "выдать премию",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "на волю выпустить",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "отправить в Санкт-Петербург",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 13,
                title: "Как сложилась судьба главного героя?",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/1875669/pub_64d7c6bb60b16c687f15189f_64d9e5c065ccd60f0b07254b/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Он получил вольную и уехал в Санкт-Петербург.",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Он женился на Настеньке и был счастлив.",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Степан счастья не знал и умер во время охоты на руднике.",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 14,
                title: "Почему в произведении нет счастливого финала?",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/1879615/pub_64d7c6bb60b16c687f15189f_64d9e773a14ed76cfb539b84/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Герои потеряли малахитовую шкатулку.",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Не сложилась семейная жизнь.",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Из-за богатства жизнь стала скучной.",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "Герой не смог выполнить третье поручение Хозяйки Медной горы.",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 15,
                title: "Какой черты харатера нет у главного героя?",
                thumbnail: "https://avatars.dzeninfra.ru/get-zen_doc/1222645/pub_64d7c6bb60b16c687f15189f_64d8944a6eb94c361edaacf3/scale_1200",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "доброта",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "трудолюбие",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "жадность",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "честность",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
        ],
        is_show_answer:false, 
        is_show_results:true, 
        is_shuffle:true, 
        is_timed:false, 
        duration: 20,
        try_count: 10
    }
}