import React from 'react' 
import { Link, useParams } from 'react-router-dom'
import {__} from 'src/libs/utilities'
import { getRoute, getSingleRoute } from 'src/libs/layouts'
import { EMTY_IMAGE_NAME, ICardProjectProps, IFestival } from '../../data/interfaces'
import TrackLabel from './TrackLabel'
import MemberRatingDisplay from './MemberRatingDisplay'
import { IFestDataStor, useFestDataStore } from '../../data/store' 
import { useOwnerPartner, useUserOwner } from '../../hooks/project'
import ExtendedForms from '../project/projectData/ExtendedForms'

const CardProject = (props: ICardProjectProps): JSX.Element => { 
    const isOwner: boolean = useUserOwner( props.item )
    const isPartner:boolean = useOwnerPartner(props.item)
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const gcolor = "#14172b"
    const routeSingle = getSingleRoute("PEFestProject") 
    const url = routeSingle 
        ? 
        `/${ getRoute(routeSingle)}/${props.item.id}` 
        : 
        "/" 
    const backgroundImage = props.item.thumbnail && props.item.thumbnail.indexOf(EMTY_IMAGE_NAME) === -1  
        ? 
        props.item.thumbnail 
        : 
        festival.defaultProjectThumbnail 
    return <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">      
        <Link className="card h-100 m-0 p-0 position-relative " to={url}>
            <div
                className="card-img"
                style={{ 
                    backgroundImage: `url(${backgroundImage})`,
                    borderColor: gcolor,
                    height: festival.memberCardHeight,
                    opacity: !props.item.is_verified ? .5 : 1,
                }}
                data-mid={props.item.id}
                data-fmru_type="fmru_player"
                data-args={props.item.id}
            >
                <div className="card-id">
                    {props.item.order}
                </div>
                <TrackLabel track={props.item.track} />
            </div>
            <div className="card-icons">
                {/* {_ganres} */}
            </div>
            <div
                className="card-header d-flex justify-content-center align-items-center"
                title={props.item.title}
                style={{
                    height: 76,
                    overflow: "hidden",
                    padding: "0 1.25em",
                    position: "relative",
                    display: "table",
                }}
                >
                <h5 className="m-0 w-100 text-center " style={{ lineHeight: 1.0 }} >
                    {props.item.title}
                </h5>
            </div>
            {
                !props.item.is_verified && 
                <div className="mine-corner-no-verified corner-card-project">
                    {__("Project has not yet been approved")}
                </div>
            }
            <ul className="list-group list-group-flush flex-grow-100  mt-auto">
                {
                    props.item.prize
                    ?
                    <li className="list-group-item pb-2"> 
                        <div className=" pe-pennant pe-pumpkit" style={{height:80}}> 
                            {props.item.prize} 
                        </div>
                    </li>
                    :
                    null
                }
                {
                    props.item.nomination
                    ?
                    <li className="list-group-item pb-2">
                        <div className=" pe-pennant pe-pumpkit-dark" style={{height:80}}> 
                            {props.item.nomination} 
                        </div>
                    </li>
                    :
                    null
                }   
                <div className='mt-auto w-100' /> 
                <ExtendedForms 
                    vertical
                    item={ props.item } 
                    isCollapseOpen 
                    hidden="hide_in_card"
                />
                {
                    Array.isArray(props.item.fmru_school) 
                        &&
                        props.item.fmru_school.map(school => {
                            return <li className="list-group-item pb-2" key={school.id}> 
                                <div className="p-3 small text-center text-dark" > 
                                    { school.title } 
                                </div>
                            </li>
                        })
                        
                }
                <li className="list-group-item justify-content-between d-flex align-items-center">
                    <span className="discr text-dark">
                        {__("Rating:")}
                    </span>
                    <span className="data p-2"> 
                        <MemberRatingDisplay 
                            correct_member_rate={props.item.correctMemberRate}
                            countedRating={ props.item.actualRating }
                            className={"text-dark "}
                        />  
                    </span>
                </li>
                <li className="list-group-item justify-content-between d-flex align-items-center">
                    <span className="discr text-dark">
                        {__("Expert count:")}
                    </span>
                    <span className="data selected p-2">
                        {props.item.experts?.length || 0}
                    </span>
                </li>
                <li className="list-group-item hidden-1">
                    <div
                        className="social-likes share text-dark"
                        data-url={url}
                        data-title={props.item.title}
                        style={{ padding: "11px 20px" }}
                    >
                        <div className="facebook" title="Поделиться ссылкой на Фейсбуке" />
                        <div className="twitter" data-via="@Metaversitet" title="Поделиться ссылкой в Твиттере" />
                        <div className="mailru" title="Поделиться ссылкой в Моём мире" />
                        <div className="vkontakte" title="Поделиться ссылкой во Вконтакте" />
                        <div className="odnoklassniki" title="Поделиться ссылкой в Одноклассниках" />
                    </div>
                </li>
            </ul>
            {
                isOwner
                    ?
                    <div className="mine-corner corner-3 ">
                        { __( "My project" ) }
                    </div>
                    :                    
                    isPartner 
                        ?
                        <div className="mine-honeycombs-corner mine-corner1">
                            {__("My honeycombs")}
                        </div>
                        :
                        null
            } 
            {/* {
                this.props.store?.myTracks?.filter(mtID => mtID == track.ID).length > 0
                    ?
                    <div className="my-track-label-stroke">
                    { __( "My Track" ) }
                    </div>
                    :
                    null
            }   */}
        </Link>
    </div>
}
 

export default CardProject