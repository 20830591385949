
import { Json, IRoutingData, ID, IMenuItem, IPost } from "src/libs/interfaces/layouts" 

//
export interface IFolderProps {
  title: string
  hidden: boolean
  id: ID
  isSelect?: boolean
  level?: number
  folders?: IFolderProps[]
}
//
export interface ISingleFolderProps {
  folder?: IFolderProps
  onChangeRoute: (data: any, routeJson: Json ) => void
  moveToFolder: (routeData: any, folderId: ID) => void
  onRemoveRoute: (route: any) => void
}

//
export interface ICardRouteProps {
  module: any
  menu : string
  data: IRoutingData
  hidden: boolean
  moveToFolder: (routeData: any, folderId: ID) => void
  onChangeRoute: (data: any, routeJson: Json) => void
  onRemoveRoute: (route: any) => void
}

export interface IAdminStateProps {
  signType: SIGN_TYPE
  setSignType: () => {}
}

export enum SIGN_TYPE {
  CARD = 'card', 
  ROW  = 'row'
}

export interface IDataTableFormProps {
  data_type: string
  data: any[]
  isList: boolean
} 

export interface IDataFormProps {
  data_type: string 
  isNew?: boolean
  item?: any
  route: string
}

export interface IMedia {
  id: ID
  url?: URL
  large: URL
  title: string
  post_date?: Date
  mime?: string
  ancestors?: IPost[]
}

export const CORRECT_DICTIONARIES_TAG = "correct-dictionary"
export const ALL_DICTIONARIES_TAG = "all-dictionary"