import React, { useEffect, useState } from 'react' 
import { Button, ButtonGroup, Intent } from '@blueprintjs/core'
import { __, sprintf } from '@/libs/utilities'
import FieldInput, { SCALAR_TYPES } from '@/libs/scalars'
import { Loading} from '@/libs/useful'
import { isRole } from '@/settings/zustand/user'
import { IUser, useMainStore, IState } from '@/settings/zustand'
import { ID, Role } from '@/libs/interfaces/layouts'  
import { IFestival, IProject, ITrack } from '../../data/interfaces'
import { useNavigate, useParams } from 'react-router'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import { POST_STATUS } from '../../../../libs/interfaces/layouts'
import { useFestival } from '../../hooks/festival'
import actions from '@/modules/pe-basic-module/data/actions'
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from '@/modules/pe-basic-module/data/actionTypes'
import NoMatchView from '@/modules/pe-basic-module/views/NoMatchView'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import MyTrack from './MyTrack'

const ModerationForm = (props: any): JSX.Element => { 
    const params = useParams()
	const {landId} = params 
    const isLoading = useFestival(true, landId) 
    if(isLoading) return <Loading />
    return <div className="pt-3 container"> 
    {
        isRole([ "administrator","Track_moderator" ])
            ? 
            <ModerationContent />
            : 
            <ModerationNone />
    } 
    </div>
} 
export default ModerationForm

const ModerationNone  = () : JSX.Element => {
    return <NoMatchView />
}

const ModerationContent = ( ) :JSX.Element| JSX.Element[] => {    
	const {landId} = useParams() 
    const user:IUser = useMainStore( (state:IState) => state.user )
    const [isLoading, setIsLoading] = useState<boolean>( true )
	const isFestLoading: boolean = useFestival( true, landId )
	const tracks: ITrack[] = ( useDataStore((state:any) => state.PEFestTrack) || [])
		.filter((track: ITrack) => {
			return (track.moderator?.id === user.id || track.moderator2?.id === user.id) || isRole("administrator") 
		})
	const [projects, setProjects] = useState<IProject[]>([])	 
    useEffect(() => { 
		actions(GET_FEED_ACTION,
			{
				data_type: "PEFestProject", 
				paging: ' post_status: "all" ',
				count: 5, 
				land_id: landId 
			})
			.then((response: any) =>  {
				setProjects( response.feed )
				setIsLoading(false)
			})
    }, [])
    if( isFestLoading && !isLoading ) return <Loading />
	const onChangeProject = (id: ID, field: keyof IProject, value: any) => {
		setProjects( 
			 projects.map(pr => ({
				...pr,
				[field] : pr.id === id ? value : pr[field]
			}))
		)
	}
    return !!projects.length 
        ?
		<>
			{
				tracks.map((track : ITrack) => <MyTrack 
					key={track.id}
					track={track}
					projects={projects.filter(pr => pr.track?.id === track.id)}
					route=""
					onChangeProject={onChangeProject}
					setIsLoading={setIsLoading}
				/>) 
			}
			{
				isRole(['administrator', 'editor']) && 
					<MyTrack 
						track={ {} as ITrack } 
						projects={projects.filter(pr => !pr.track?.id )} 
						route="" 
						onChangeProject={onChangeProject}
						setIsLoading={setIsLoading}
					/>
			} 
		</>
        :
        <div className="alert alert-danger p-5 lead text-center">
            {
                __("No elements exists")
            }
        </div>
}
