import { IUserMana } from "../../data/interfaces"
import { IManaProps } from "../../data/manas"
import { IManituStore, useManituStore } from "../../data/store"
import ManaMonitorSingle from "./ManaMonitorSingle"


const ManaMonitorScreen = () : JSX.Element => {
    const userMana: IUserMana = useManituStore((state:IManituStore) => state.userMana)
    const manaTypes: IManaProps[] = useManituStore((state: IManituStore) => state.manaTypes)
    return <div className="monitor datas widget ">
        {
            manaTypes.map(mana => {
                return <ManaMonitorSingle 
                    mana={ mana } 
                    count={ userMana[ mana.id ].count } 
                    key={ mana.id } 
                /> 
            })
        }
    </div> 
}

export default ManaMonitorScreen