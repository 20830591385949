
       import Scalar from './Scalar'
import IScalarForm, { ISingleField } from '../interface/IScalarForm'
import { ID } from '@/libs/interfaces/layouts' 
import { __ } from '@/libs/utilities'
import { Icon, Intent } from '@blueprintjs/core'
import { useId } from 'react'


const CheckboxGroup = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<CheckboxGroupEnabled {...props} />}
        desabledForm={<CheckboxGroupDesabled {...props} />}
    />
} 

export default CheckboxGroup 

const CheckboxGroupEnabled = (props: IScalarForm) : JSX.Element  => {
    const values: any[] = Array.isArray(props.values)
        ?
        props.values.map(( v: ISingleField ) => {
            return v._id && v.title 
                ? 
                v
                :
                {_id:v.toString(), title: v.toString()}
        })
        :
        []
    const onChange = (bool: boolean, key: ID) => {
        // Array.isArray(props.value) ? props.value : []
        let value: boolean[] = values.map((v:ISingleField, i: number) => {
            return v._id === key 
                ?
                bool
                :
                Boolean( Array.isArray(props.value) ? props.value[i] : false )
        }) 
        if(props.onChange) {
            props.onChange( value, props.field, '' )
        }
    }
    return <div>
    {
        values.map(( val: ISingleField, i: number ) => { 
            return <Checkbox 
                key={val._id}
                _id={val._id}
                i={i}
                title={ val.title }
                value={ Array.isArray(props.value) ? props.value[i] : false }
                values={values}
                onChange={(bool: boolean, key: ID ) => onChange(bool, key )}
            />   
        }) 
    }
    </div> 
} 

const CheckboxGroupDesabled = (props: IScalarForm) : JSX.Element  => {
    const f =  Array.isArray(props.value)
        ?
        props.value.map( (v:boolean, i: number) => <div className='d-flex align-items-end' key={i}>
            <Icon icon={ v ? "tick" : "cross" } intent={ v ? Intent.SUCCESS : Intent.DANGER} />
            <div className='ml-2'>
                { Array.isArray(props.values) ? props.values[i]?.title : "--" }
            </div>
        </div>)
        :
        Array.isArray(props.values) 
            ? 
            props.values.map( (v:boolean, i: number) => {
                return <div className='d-flex' key={i}>
                    <Icon 
                        icon={ v ? "tick" : "cross" } 
                        intent={ v ? Intent.SUCCESS : Intent.DANGER} 
                    />
                </div>
            })
            :
            <></>
    return <div className='my-3'>{ f }</div>
} 

const Checkbox = (props: any) : JSX.Element => { 
    const id = useId()
    const onChange = (evt: React.SyntheticEvent<HTMLInputElement>) => {
        const bool: boolean = evt.currentTarget.checked
        props.onChange( bool, props._id )
    }
    return <div className=" d-flex align-items-center my-4">
        <input 
            id = { id + ( props._id as string ) }
            type = "checkbox" 
            className = "_checkbox m-0" 
            checked = {  props.value || false } 
            onChange={ onChange }
        />
        <label htmlFor={ id + ( props._id as string ) } className="m-0">
        {  
            Array.isArray(props.values) 
                ? 
                __( props.values[ props.i ]?.title ) 
                : 
                props.i 
        }
        </label>  
    </div>
}
