 
import { GET_ADS_FEED_ACTION, GET_EVENTS_ACTION, GET_NEWS_FEED_ACTION, GET_PLACES_ACTION, GET_SINGLE_AD_ACTION, GET_SINGLE_EVENT_ACTION, GET_SINGLE_EVENT_TYPE_ACTION, GET_SINGLE_NEWS_ACTION, GET_SINGLE_PLACE_ACTION, GET_TEAM_ACTION, GET_USER_EVENT_REQUEST_ACTION, GET_USER_SUBSCRIBED_ACTION, GET_VIDEO_FEED_ACTION, GET_VKPOST_FEED_ACTION } from './actionTypes'
import asyncControllers from './controllers' 
import { useToposPostsStore } from './store'


export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    switch (action) {
        case GET_USER_EVENT_REQUEST_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.isAgreed )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_USER_SUBSCRIBED_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.isSubscribed )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_VIDEO_FEED_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        useToposPostsStore.setState({ videos: response.data.getVideos })
                        resolve( response.data.getVideos )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_VKPOST_FEED_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.getPEVKPosts )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_NEWS_FEED_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {  
                        useToposPostsStore.setState({ news: response.data.getNews })
                        resolve( response.data.getNews )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_ADS_FEED_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        useToposPostsStore.setState({ ads: response.data.getAds })
                        resolve( response.data.getAds )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_SINGLE_PLACE_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.getToposPlace )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_SINGLE_NEWS_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {  
                        resolve( response.data.getSingleNews )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_SINGLE_AD_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        useToposPostsStore.setState({ news: response.data.getAd })
                        resolve( response.data.getAd )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_SINGLE_EVENT_TYPE_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.getToposEventType )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_SINGLE_EVENT_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.getToposEvent )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_TEAM_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.getToposTeams )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_EVENTS_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {
                        useToposPostsStore.setState({ events: response.data.getToposEvents })
                        resolve( response.data.getToposEvents )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        case GET_PLACES_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.getToposPlaces )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
        default:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
    }

} 

export const syncAction = (action: string, data: any): any => {
    switch(action) { 
        default:
            return action
    }
    
}