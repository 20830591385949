import { IUser } from "@/settings/zustand"
import { Email, ID, URL, HTML, IMenuItem, POST_STATUS, IPost, Role, IMedia, Color } from "@/libs/interfaces/layouts"

export const TAKT: number = 1000

export enum FABULA_STAFF_ACTION {
    TAKE="TAKE",
    CHARGE="CHARGE",
    TALK="TALK",
}
export interface IFabulaSlide extends IFabulaSpeech {
    thumbnail: URL
    music?: URL
    musicVolume? : number
    musicStart?: number
}
export interface IFabulaPerson extends IUser {
    tileID: ID
    mediaID: ID
    top: number
    left: number
    z: number
    width: number
    height: number
    scaleX?: boolean
    sprite?: boolean
    type: string
    luck: number
    charm: number
    dexterity: number
    force: number
    health: number
    fullness?: number
    currentFullness?: number
    staff: ID[]
}
export interface IFabulaHero extends IFabulaPerson {
    quest: IFabulaSlide[]
}
export interface INPC extends IFabulaPerson {
    aggression: number
    cowardice: number
}
export interface IFabulaTalk {
    speech: IFabulaSpeech[]
    matrix: any
}
export interface IFabulaStaff extends IPost {
    type: string
    disposable: boolean
    x?: number
    y?: number
    action?: string | FABULA_STAFF_ACTION
    parameters?: any | IFabulaTalk
}
export enum FABULA_MODE {
    EXCURSION,
    QUEST,
}
export enum FABULA_MINI_GAME {
    Test="Test",
    Dice="Dice"
}
export enum IFabulaMediaType {
    IMAGE="IMAGE",
    VIDEO="VIDEO",
    AUDIO="AUDIO",
    TEXT="TEXT",
    HTML="HTML",
    MULTI_SCREEN="MULTI_SCREEN"
}
export enum PROTECTION_MODE {
    "CHO0SE" = "CHO0SE",
    "BREAK" = "BREAK",
    "HACK" = "HACK" 
}
export interface IFabulaMiniGame  extends IPost{
    type: FABULA_MINI_GAME
    parameters: any
}
export enum SPEECH_POSITION {
    FULL="FULL",
    LEFT="LEFT",
    LEFT_1="LEFT_1",
    LEFT_2="LEFT_2", 
    RIGHT="RIGHT",
    RIGHT_1="RIGHT_1", 
}
export enum SPEECH_FINISH_TYPE {
    INFINITY="INFINITY",
    PASSED="PASSED"
}
export interface IFabulaSpeech extends IPost {
    order: number
    isHero: boolean
    isDisposable: boolean
    npc?: ID
    completed: boolean,
    audioSource? : string,
    finishType: SPEECH_FINISH_TYPE,
    passedPause: number,
    passedBefore: number,    
    duration: number,
    position: SPEECH_POSITION
}
export interface IFabulaMedia extends IPost {
    label?: string
    type: IFabulaMediaType
    x: number
    y: number
    src?: string | HTML
    top?:   ID | null
    left?:  ID | null
    right?: ID | null
    to?:    ID | null
    parent?: IFabulaMedia
    media?: IFabulaMedia[]
    parameters?: any | IFabulaMultiScreenParams
    defense?:number
    protection?: IFabulaMiniGame
    isBroken?:boolean
    staff?: ID[]
    npc?: ID[]
    speech: ID[]
    music?: URL
}
export interface IFabulaMultiScreenItem {
    id: ID
    title: string
    type: IFabulaMediaType
    thumbnail: string
    thumbnailBounds: DOMRect
    content? : any
}
export interface IFabulaMultiScreenParams {
    id: ID
    items: IFabulaMultiScreenItem[]
    screen: DOMRect
}
export interface IFabulaEpisode extends IPost {
    label?: string
    x: number
    y: number
    enabled?: boolean
    visible?: boolean
    tile_id: ID
    media: IFabulaMedia[]
    staff?: ID[]
    defense?: null
    protection?: null
    isBroken?: boolean
    music?: URL
} 
export interface IPEFabulaMapTile extends IPost {
    x: number
    y: number
    enabled: boolean
    map_id: ID
    episodes: IFabulaEpisode[]
}
export interface IPEFabulaMap extends IPost {
    width: number
    height: number,
    tileX: number
    tileY: number
    tiles: IPEFabulaMapTile[]
}
export enum FABULA_CHARACTERY {
    CHARM="CHARM",
    FORCE="FORCE",
    DEXTERITY="DEXTERITY",
    LUCK="LUCK"
}
export interface IPEFabulaCharactery extends IPost  {
    type: FABULA_CHARACTERY
    color: Color
}
export enum PE_STORY_PHASE {
    NO_START="NO_START",
    QUEST="QUEST",
    START="START"
}
export interface IPEStory extends IPost {
    intro: IFabulaSlide[] 
    characteries: IPEFabulaCharactery[]
    NPC: INPC[]
    users: IUser[]
    background: URL
    map: IPEFabulaMap
    duration: number
    stepDuration: number
    mode: FABULA_MODE,
    heros: IFabulaHero[]
    staff: IFabulaStaff[]
    speech: IFabulaSpeech[]
    phase: PE_STORY_PHASE
    isFullness: boolean // игроки должны "подзаряжаться"
}
export interface IPEFabula extends IPost{
    PEStory : IPEStory[]
}