import { Button, ButtonGroup, Dialog, Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import {default as UUID} from "node-uuid";
import { ID } from "@/libs/interfaces/layouts";
import DialogRight from "./DialogRight";
import GalleryDialog from "./GalleryDialog";

 
export const Gallery = ({data, itemWidth}:any): JSX.Element => {
    const [isOpen, setIsOpen] = useState( false )
    const [i, setI] = useState(0)
    const to = (i:number) => {
        setI(i)
        setIsOpen( true )
    }
    return <>
        <div className="gallery">
        {
            Array.isArray(data)
                ?
                data.map( (url:URL, i:number) => {
                    return <GalleryItem 
                        i={i} 
                        url={url} 
                        key={url.toString()} 
                        galleryId={UUID.v4()} 
                        gallery={ data } 
                        width={ itemWidth }
                        to={to} 
                    />
                })
                :
                null
        }
        </div>
        <GalleryDialog
            i={ i }
            data={ data }
            setI={ to }
            isOpen={ isOpen }
            setIsOpen={ setIsOpen }
        />
    </>
}



interface IGalleryItemProps {
    i: number
    url: URL | string
    width?:number
    height?: number
    type?: "image" | "div" | "span"
    galleryId: ID
    gallery?: any[]
    to?: (i:number) => void
}
export const GalleryItem = (
{
    i, 
    url="", 
    galleryId,
    gallery, 
    type, 
    width,
    height,
    to
}: IGalleryItemProps ) :JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const onOpen = () : void => {
        if(url)  {
            !gallery ? setIsOpen(!isOpen) : to ? to(i) : <></>
        } 
    } 
    let thumbnail : JSX.Element
    switch(type) {        
        case "image" :
            thumbnail = <img 
                src={ url.toString() } 
                className="thumbnail gallery-item pointer" 
                style={{ width, height }} 
                onClick={ onOpen }
                alt=""
            />
            break
        case "span": 
            thumbnail = <span 
                className={`thumbnail gallery-item pointer`} 
                style={{backgroundImage: `url(${ url })`, width, height }} 
                onClick={onOpen}
            />
            break
        case "div":
        default:
            thumbnail = <div 
                className="thumbnail gallery-item pointer" 
                style={{ backgroundImage: `url(${ url })`, width, height }} 
                onClick={onOpen}
            />
            break
    }
    return <>
        { thumbnail }
        <Dialog
            isOpen={isOpen}
            onClose={onOpen}
            className="large flex-centered transparent"
        >
            <div className=" position-relative gallery-dialog"> 
                <img 
                    src={ url.toString() } 
                    alt="" 
                    style={{ maxHeight:"90vh", width: "100%", maxWidth:800 }} 
                />
                <div className="position-absolute top right m-2">
                    <Button onClick={onOpen} icon="cross"></Button>
                </div>            
            </div>
        </Dialog>
    </>
}