import React, { useEffect, useState } from "react"
import IScalarForm from "../interface/IScalarForm"
import Scalar from "./Scalar" 
import "../style.scss"

const Depth = (props: IScalarForm): JSX.Element => {
    return <Scalar
        {...props}
        enabledForm={<DepthEnabled {...props} />}
        desabledForm={<DepthDesabled {...props} />}
    />
}
export default Depth

const DepthDesabled = (props: IScalarForm): JSX.Element => {
    return <div className='my-3 text-center '>{props.value}</div>
}
const DepthEnabled = ({ ...props }: IScalarForm): JSX.Element => {
    const [value, setValue] = useState((props.max || 100) - props.value);
    const [demo, setDemo] = useState(Math.floor(( (props.max || 100) - props.value ) / (props.max || 100) * 100));

    useEffect(() => {
        setValue( (props.max || 100) - props.value );
        setDemo( ( (props.max || 100) - props.value ) / (props.max || 100) * 100 );
    }, [props.value])
    const handleChange = (event: any) => {
        const v: number = (props.max || 100) - parseInt(event.target.value, 10)
        // setValue(v);
        // setDemo( v / (props.max || 100) * 100 );
        if (props.onChange) {
            props.onChange(v, props.field, "")
        }
    };
    return (
        React.createElement("div", { style: {paddingTop: 40}},
            React.createElement("div", { className: "position-relative ", style: { width: "324px", height: "324px" } },
                React.createElement("div", {
                    className: "position-absolute rounded-full",
                    style: {
                        width: "24px",
                        height: "24px",
                        left: "90px",
                        top: `${12 + demo * 3}px `,
                        transform: "translate(-50%, -50%)"
                    }
                },
                    React.createElement("div", { className: "position-relative w-100 h-100" },
                        React.createElement("div", {
                            className: "position-absolute flex-centered rounded-pill text-white ",
                            style: {
                                width: "60px",
                                height: "24px",
                                background: "#424E82",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)"
                            }
                        },
                            ( props.max || 100 ) - value 
                        ),

                        React.createElement("div", {
                            className: "position-absolute rounded-md",
                            style: {
                                width: "20px",
                                height: "3px",
                                background: "#424E82",
                                left: "35px",
                                top: "50%",
                                transform: "translate(0, -50%)"
                            }
                        })
                    )
                ),


                React.createElement("div", { className: "position-absolute", style: { top: 0, left: "100px" } },
                    React.createElement(TestTube, { value: demo })
                ),

                React.createElement("div", { className: "position-absolute", style: { top: "150px", left: "-50px" } },
                    React.createElement("div", { className: "position-relative", style: { width: "324px" } },
                        React.createElement("input", {
                            className: "rt90 b-rage ",
                            type: "range",
                            id: "rangeInput",
                            name: "rangeInput",
                            min: 0,
                            max: props.max || 100,
                            step: 1,
                            value: value,
                            onChange: handleChange
                        })
                    )
                )
            )
        )
    );
}

function TestTube({ value }: any) {
    return (
        React.createElement("svg", {
            width: "119",
            height: "324",
            viewBox: "0 0 119 324",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
        },

            React.createElement("path", {
                fillRule: "evenodd",
                clipRule: "evenodd",
                d: "M30 0C28.3431 0 27 1.34315 27 3V6C27 7.65685 28.3431 9 30 9H34V287C34 300.807 45.1929 312 59 312C72.8071 312 84 300.807 84 287V9H89C90.6569 9 92 7.65685 92 6V3C92 1.34315 90.6569 0 89 0H30Z",
                fill: "#E8EBF9"
            }),

            React.createElement("g", { clipPath: "url(#test_tube_clip)" },
                React.createElement("path", {
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    d: "M30 0C28.3431 0 27 1.34315 27 3V6C27 7.65685 28.3431 9 30 9H34V287C34 300.807 45.1929 312 59 312C72.8071 312 84 300.807 84 287V9H89C90.6569 9 92 7.65685 92 6V3C92 1.34315 90.6569 0 89 0H30Z",
                    fill: "#424E82"
                })
            ),


            React.createElement("defs", null,
                React.createElement("clipPath", { id: "test_tube_clip" },
                    React.createElement("rect", { width: "119", height: "324", fill: "white", y: value * 3 + 12 })
                )
            )
        )
    )  
}