import React from "react" 
import BasicState from "src/libs/basic-view"
import EventTypeForm from "./event/EventTypeForm" 
import "../assets/css/style.scss"

export default class EventTypeView extends BasicState {
    props: any
    addRender = () => {
        return <EventTypeForm {...this.props} />  
    }
}