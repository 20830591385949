import { Position } from "@blueprintjs/core"
import { Email, ID, URL, HTML, IMenuItem, POST_STATUS, IPost, Role, Color, IMedia } from "@/libs/interfaces/layouts"


export interface IErmakSpace extends IPost {
    maps: IErmakMap[]
    spaces: IErmakSpace[]
    thumbnailPositionY?: string
    color: Color
    z: number
    x: number
    y: number
}

export interface IErmakMap extends IPost {
    type: IErmakMapType
    cells?: IErmakMapCell[], 
    width?: number,
    height?: number
    z?: number
    x?: number
    y?: number
}
export interface IErmakMapCell {
    id: ID
    type: IErmakMapCellType
    items?: IErmakItems[]
}
export interface IErmakItems extends IPost {
    youtubeID?: string
    gallery?: IMedia[]
}

export enum IErmakMapType {
    YANDEX_MAP="YANDEX_MAP",
    MATRIX="MATRIX"
}
export interface IErmakMapCellType extends IPost {
    color: Color
}


export const defaultSpace = () : IErmakSpace => {
    return {
        maps: [],
        spaces: [],
        color: "",
        id:"-1",
        z:0,
        x:0,
        y:0,
        title: "",
        post_content: ""
    }
}
