import React from "react"
import BasicState from "@/libs/basic-view"   
import '../assets/css/index.scss'
import AboutForm from "./about/AboutForm"
import PhaseLabel from "./PhaseLabel"

export default class AboutView extends BasicState {
    props: any
    addRender = () => {
        return <div className="about-view w-100 h-100 m-0 position-relative">
            <AboutForm {...this.props} /> 
            <div className="position-md-fixed position-absolute" >
                <PhaseLabel />
            </div>
        </div>
    }
}
