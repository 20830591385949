import { IPETest, PE_TEST_QUESTION_TYPE } from "../interfaces"
import { questions } from "./questions"
import { testCategories } from "./testCategories"

export const test2 = (): IPETest => {
    return {
        id: 2,
        title: "«Иван - крестьянский сын и чудо-юдо»",
        post_content: "Тест по литературе для 5 класса",
        post_author: {
            id: "1",
            display_name: "Геннадий Глазунов",
            roles: []
        },
        pe_academic_discipline: [{
            color: '#214700',
            id: '25',
            order: 6,
            post_content: '',
            thumbnail: 'false',
            title: 'Литература',
        }],
        testCategory: testCategories().filter(t => t.id === 2)[0],
        thumbnail: "https://i.pinimg.com/564x/fb/41/50/fb4150378dce7a2e69332a129b3ba29f.jpg",
        questions: [
            {
                id:1,
                title: "Младшего сына старухи и старика звали:",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Алёшенька",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Илья Муромец",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Иванушка",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 2,
                title: "О ком разнеслась дурная весть в царстве-государстве?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "о Соловье Разбойнике",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "о чуде-юде поганом",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "о Кощее Бессмертном",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 3,
                title: "Как поступили сыновья старухи и старика, услышав дурную весть?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "решили остаться дома",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "решили биться насмерть с чудом-юдом",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "послали вооружённых воинов убить Кощея Бессмертного",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 4,
                title: "Что делал старший брат в дозоре?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "спал под кустом",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "сторожил ракитов куст",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "купался в реке Смородине",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 5,
                title: "В первую ночь дозора чудо–юдо имело",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "три головы",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "две головы",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "шесть голов",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 6,
                title: "Во вторую ночь дозора чудо-юдо имело",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "12 голов",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "9 голов",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "3 головы",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 7,
                title: "В третий раз чудо-юдо было",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "шестиголовое",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "десятиголовое",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "двенадцатиголовое",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 8,
                title: "С помощью чего прирастали срубленные головы чуда-юда?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "волшебной палочки",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "огненного пальца",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "меча булатного",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 9,
                title: "Что потерял Иван в реке Смородине, когда сражался с чудом-юдом?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "платок",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "меч",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "клубок",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 10,
                title: "Кем собиралась обернуться старшая жена чуда-юда?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "змеей",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "колодцем",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "корытом",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 11,
                title: "Чем собиралась стать вторая жена чуда-юда?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "вишней",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "розой",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "яблоней",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 12,
                title: "Во что собиралась превратиться третья жена чуда-юда?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "мягким ковром",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "пушистым одеялом",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "ковром-самолётом",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
            {
                id: 13,
                title: "Кто помог братьям спастись от старой змеихи?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "пахари",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "кузнецы",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "плотники",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
        ],
        is_show_answer:false, 
        is_show_results:true, 
        is_shuffle:true, 
        is_timed:false, 
        duration: 20,
        try_count: 10
    }
}