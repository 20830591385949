import { IFabulaHero, IPEFabulaMapTile, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import TileMenuMap from "./TileMenuMap"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { actions } from "src/modules/pe-fabula-module/data/actions"
import { MOVE_HERO_ACTION } from "src/modules/pe-fabula-module/data/actionTypes"

/* Protopia Ecosystem component */
export interface IStoryMapProps {

}
const StoryMap = (props: IStoryMapProps): JSX.Element => {
    const {width, height} = useScreenSize()
    const PeStory : IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    const hero : IFabulaHero = useStorytellingStore((state: IStorytellingStore) => state.hero)
    
    const heroTile = PeStory.map?.tiles.filter( tile => tile.id === hero.tileID )[ 0 ] || { x:0, y:0 }
    const translateX = width / 2  - (PeStory.map?.width  || 0 ) / ( PeStory.map?.tileX || 1 ) * ( heroTile.x + .5 )
    const translateY = height / 2 - (PeStory.map?.height || 0 ) / ( PeStory.map?.tileY || 1 ) * ( heroTile.y + .5 )
    
    console.log( `translate(${translateX < 0 ? translateX : 0 }px, ${translateY < 0 ? translateY : 0 }px)` )

    const to = (tile: IPEFabulaMapTile) => {
        if(!tile) return
        if(
            ( Math.abs(tile.x - heroTile.x) < 2 && tile.y === heroTile.y ) ||
            ( Math.abs(tile.y - heroTile.y) < 2 && tile.x === heroTile.x )
         ) {
            actions( MOVE_HERO_ACTION, { tileID: tile.id } )
            setTimeout(() => {
                useStorytellingStore.setState({ isMapOpen: false })
            }, 500) 
        }
    }
    return <div 
        className="pe-fabula-map"
        style={{
            width: PeStory.map?.width,
            height: PeStory.map?.height
        }}
    >
        <div 
            className="pe-fabula-map-bg"
            style={{
                width: PeStory.map?.width,
                height: PeStory.map?.height,
                backgroundImage: `url(${PeStory.background})`, 
                transform: `translate(${translateX < 0 ? translateX : 0 }px, ${translateY < 0 ? translateY : 0 }px)`,
            }}
        >
        {
            Array( PeStory.map?.tileX ).fill(1).map((__, i) => {
                return <div key={i}> 
                {
                    Array( PeStory.map?.tileY ).fill(1).map((__, ii) => {
                        const tile: IPEFabulaMapTile = PeStory.map?.tiles.filter(tile => tile.x === i && tile.y === ii)[0]
                        return <TileMenuMap 
                            x={ i }
                            y={ ii }
                            item={ tile } 
                            key={ ii }
                            onClick={ () => to( tile ) }
                        />
                    })
                }
                </div>
            })
        }
        </div>
    </div>
}
export default StoryMap