import React, { useEffect, useId, useState } from "react"
import Editor from "react-simple-code-editor";
import Prism  from "prismjs" 
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import { Button, Icon } from "@blueprintjs/core";
import {default as UUID} from "node-uuid";

const CLOSED_HEIGHT: number = 120
const CodeEditor = (props: any) : JSX.Element =>
{
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [code, setCode] = useState<string>( props.value );
    const uid: string = UUID.v4()
    
    const open = ( bool:boolean = false ) => {
        const textContainer:Element | null = document.querySelector(`.editor_${uid}`);
        //console.log( textContainer )
        if(!textContainer) return
        const element: HTMLElement = textContainer.querySelector(".npm__react-simple-code-editor__textarea") as HTMLElement
        const parent = element.parentElement as HTMLElement
        const grandParent = parent.parentElement as HTMLElement
        const height: string = (bool ?  15 + element.scrollHeight :  CLOSED_HEIGHT) + "px";
        element.style.height = "1px"; 
        parent.style.height = "1px"; 
        grandParent.style.height = "1px"; 
        element.style.height = height; 
        parent.style.height = height; 
        grandParent.style.height = height; 
    } 

    useEffect(() => {
        if(code !== props.value)
        {
            setCode(props.value)
        }
        setTimeout(() => {
            open()
        }, 120)
    }, [])
    const onEdit = (code: any) =>
    {
        setCode(code)
        if(props.onChange)
        {
            props.onChange(code)
        }
    }
    const loadFonts = () =>
    {
        // if($("head link#monoFont").length === 0)
        // {
        //     $("head").append(`<link id="monoFont" href="https://fonts.googleapis.com/css2?family=Fira+Mono&display=swap" rel="stylesheet">`)
        // }
    }
    useEffect(() => {
        Prism.manual = true;
        Prism.highlightAll();
        loadFonts()
    }, [])
    const changeOpen = () => {
        open( !isOpen )
        setIsOpen(!isOpen)
    }
    return <div>
        <Editor
            value={code}
            onValueChange={ onEdit }
            highlight={(code: any) =>  Prism.highlight(code, Prism.languages.html, 'html')}
            padding={10}
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                color: "#111!important"
            }}
            className={`editor_${uid}`}
        />
        <div>
            <Button minimal  onClick={ changeOpen }>
                <Icon icon={ isOpen ? "chevron-up" : "chevron-down" } size={22} />
            </Button>
        </div>
    </div>
        
}

export default CodeEditor