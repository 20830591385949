import React from "react" 
import BasicState from "src/libs/basic-view"
import LandscapeForm from "./landscape/LandscapeForm" 
import "../assets/css/style.scss"

export default class LandscapeView extends BasicState {
    props: any
    addRender = () => {
        return <LandscapeForm {...this.props} />  
    }
}