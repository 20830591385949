import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { UPDATE_STORY_ACTION } from "src/modules/pe-fabula-module/data/actionTypes"
import { actions } from "src/modules/pe-fabula-module/data/actions"
import { IFabulaHero, IPEStory, PE_STORY_PHASE } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { component_url } from "src/libs/utilities/links"
import StoryIntroSlide from "./StoryIntroSlide"
import MusicsEngine from "../musics/MusicsEngine" 
import SoundVolumeEngine from "../pult/SoundVolumeEngine"

/* Protopia Ecosystem component */
export interface IStoryQuestProps {
    isMainIntro: boolean
    onFinishPlay: () => void
}
const StoryQuest = (props: IStoryQuestProps): JSX.Element => {
    const PeStory : IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    const hero : IFabulaHero = useStorytellingStore((state: IStorytellingStore) => state.hero)
    const [ slideOrder, setSlideOrder ] = useState( 0 )
    const navigate = useNavigate()
    const onStartPlay = () => {
        if(props.isMainIntro) {
            actions(UPDATE_STORY_ACTION, {field: "phase", phase: PE_STORY_PHASE.START})
            navigate( `${ component_url("FabulaView") }/story/${PeStory.id}/play`)
        }
        else {
            props.onFinishPlay()
        }
        
    }
    useEffect(() => {
        if( !hero.quest.length ) {
            onStartPlay()
        }
    }, [])
    const onNextBeat = () => {
        if( slideOrder < hero.quest.length - 1) {
            setSlideOrder( slideOrder => slideOrder + 1 )
        }
        else {
            onStartPlay()
        }
    }
    return !!hero.quest.length 
        ?
        <>
            <div className='pe-story-intro-container' onClick={onStartPlay} >
                <StoryIntroSlide
                    item={ hero.quest[slideOrder] }
                    slideOrder={slideOrder}
                    onNextBeat={onNextBeat}
                />
                <MusicsEngine 
                    audio={ hero.quest[slideOrder].music } 
                    musicVolume={hero.quest[slideOrder].musicVolume}  
                    musicStart={hero.quest[slideOrder].musicStart} 
                />
            </div>
            <SoundVolumeEngine />
        </>
         
        :
        <></>
}
export default StoryQuest