
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import {Slider as MSlider} from "@blueprintjs/core"

const Slider = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<SliderEnabled {...props} />}
        desabledForm={<SliderDesabled {...props} />}
    />
} 
export default Slider 

export const SliderEnabled = ( props: IScalarForm ): JSX.Element => { 
    const onChange = (value: number) => { 
        if( props.onChange )
        {
            props.onChange( value,  props.field, "" )
        }   
    }

    return <div className=" d-flex justify-content-center my-3 w-100 py-0 pr-4">
        <MSlider
          min={ props.min ? props.min : 0}
          max={ props.max ? props.max : 100}
          stepSize={ props.stepSize ?  props.stepSize : 10}
          labelStepSize={
					props.labelStepSize
					  ? 
                      props.labelStepSize
					  : 
                      props.stepSize
					    ? 
                        props.stepSize
					    : 
                        20
				}
          value={
            parseFloat(props.value) || ( props.min && props.min > 0
					  ? 
                      props.min
					  : 
                      0
                    )
				}
          onChange={ onChange }
          className="my-2"
        />
    </div>
} 
 
const SliderDesabled = ( props: IScalarForm ): JSX.Element => {
    return <div className='my-3 text-center '>{props.value }</div>
} 
 