import { Button, Overlay } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { MOVE_HERO_ACTION } from "src/modules/pe-fabula-module/data/actionTypes"
import { actions } from "src/modules/pe-fabula-module/data/actions"
import { FABULA_MODE, IFabulaEpisode, IPEFabulaMapTile, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import TileContent from "./TileContent"
import TileEpisode from "./TileEpisode"
import TileEpisodeLabel from "./TileEpisodeLabel"
import { ILayoutStore, useLayoutStore } from "src/settings/zustand"

/* Protopia Ecosystem component */
export interface ITileSlotProps {
    bound: DOMRect
    item: IPEFabulaMapTile | undefined
    onClose: () => void
    x: number
    y: number
}
const TileSlot = ({ ...props }: ITileSlotProps): JSX.Element => {
    const PeStory : IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current) 
    const currentEpisode : IFabulaEpisode = useStorytellingStore((state: IStorytellingStore) => state.currentEpisode) 
    const footerHeight: number = useLayoutStore((state: ILayoutStore) => state.footerHeight)

    const {width, height} = useScreenSize() 
    const [bound, setBound] = useState<any>(props.bound )
    const [ item, setItem ] = useState(props.item )
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
    const [ x, setX ] = useState(props.x )
    const [ y, setY ] = useState(props.y )

    useEffect( () => {
        const wz = width - 0 
        const hz = height - 0
        const borderWidth = 1
        const timeout = setTimeout(() => {
            setBound({
                top: height/2 - hz/2 + borderWidth,
                left: width/2 - wz/2 + borderWidth,
                y: height/2 - hz/2 + borderWidth,
                x: width/2 - wz/2 + borderWidth,
                width: wz  - borderWidth * 2,
                height: hz - borderWidth * 2 - footerHeight,
                bottom: height/2 + hz/2,
                right: width/2 + wz/2,
            })
        }, 40)
        return () => {
          clearTimeout( timeout )  
        } 
    }, [ width, height, footerHeight ] )

    /*
    useEffect( () => {
        console.log( props.item )
        if( props.item ) {
            setItem( props.item )
            setX( props.item.x )
            setY( props.item.y )
            const episode = props.item.episodes[0]
            if( episode ) {
                useStorytellingStore.setState({ currentEpisode : episode })
            }
        }
    }, [ props.item ] )
    */

    const to = (x: number, y: number ) => {
        const tile = PeStory.map.tiles.filter( t => t.x === x && t.y === y )[0] 
        setItem( tile )
        setX(x)
        setY(y)
        useStorytellingStore.setState({isMenuOpen : false})
        actions( MOVE_HERO_ACTION, { tileID: tile.id ? tile!.id : item!.id } )
    }

    const onClick = ( e : any ) => { 
        useStorytellingStore.setState({isMenuOpen : false})
    }
    const onEpisodeClick = ( episode: IFabulaEpisode ) => { 
        setTimeout( () => setIsOverlayOpen( true ), 30 ) 
        useStorytellingStore.setState({isMenuOpen : false})
        useStorytellingStore.setState({ currentEpisode : episode })
    }
    const toggleOverlay = () => {
        setIsOverlayOpen(!isOverlayOpen)
        useStorytellingStore.setState({isMenuOpen : false})
    }
    return <div
        style={{ 
            top:    bound.top,
            left:   bound.left,
            width:  bound.width,
            height: bound.height, 
        }}
        className="pe-fabula-map-tile-container "        
    >
        <TileContent
            item={item}
            onClick={onClick}
        />
        {
            item?.episodes?.map((_episode, i) => {
                return <TileEpisodeLabel
                    key={_episode.id}
                    episode={_episode}
                    onClick={ () => onEpisodeClick( _episode ) }
                    i={i}
                />
            })
        }
        {
            !!PeStory.map.tiles.filter( t => t.x === x && t.y === y - 1 && t.enabled).length &&
                <div 
                    className="pe-fabula-arrow-top pe-fabula-map-tile-arrow" 
                    onClick={ () => to(x, y - 1) }    
                />
        }
        {
            !!PeStory.map.tiles.filter( t => t.x === x + 1 && t.y === y && t.enabled ).length 
                ?
                <div 
                    className="pe-fabula-arrow-right pe-fabula-map-tile-arrow" 
                    onClick={ () => to( x + 1, y ) }  
                />
                :
                <></>
        }
        {
            !!PeStory.map.tiles.filter( t => t.x === x && t.y === y + 1 && t.enabled).length &&
                <div 
                    className="pe-fabula-arrow-bottom pe-fabula-map-tile-arrow" 
                    onClick={  () => to(x, y + 1) }  
                />
        } 
        {
            !!PeStory.map.tiles.filter( t => t.x === x - 1 && t.y === y && t.enabled ).length &&
                <div 
                    className="pe-fabula-arrow-left pe-fabula-map-tile-arrow" 
                    onClick={  () => to(x - 1, y) }  
                />
        }
        {
            PeStory.mode !== FABULA_MODE.QUEST 
                ?
                <Button 
                    minimal 
                    className="m-2 position-absolute top right" 
                    icon="cross" 
                    onClick={props.onClose} 
                />
                :
                <Button 
                    minimal 
                    className="m-2 position-absolute top right" 
                    icon="cross" 
                    onClick={props.onClose} 
                />
        }
        
        <Overlay 
            isOpen={isOverlayOpen} 
            onClose={toggleOverlay}
            // onClosed={onClosed}
            backdropClassName={`flex-centered back-drop-opaciry-02`}
            portalClassName={`tile-portal`}
        >
            <TileEpisode item={ currentEpisode }  onClose={toggleOverlay} />
        </Overlay>
    </div>
}
export default TileSlot