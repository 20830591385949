 
import { GET_Ludens_DATA_ACTION, GET_USER_RESOURCES_TYPE, UPDATE_Ludens_DATA_ACTION } from './actionTypes' 
import asyncControllers from './controllers'
import { useLudensStore } from './store'


export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    switch (action) {
        case GET_Ludens_DATA_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => {
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise 
        case UPDATE_Ludens_DATA_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise 
        case GET_USER_RESOURCES_TYPE:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        useLudensStore.setState({
                            userResource:       response.getUserResources,
                            userMaxResource:    response.getUserResources
                        })
                        resolve( response.getUserResources )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise 
        default:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise
    }

} 

export const syncAction = (action: string, data: any): any => {
    switch(action) { 
        default:
            return action
    }
    
}