import { questions } from "src/modules/pe-tests-module/data/mocks/questions"
import { test } from "src/modules/pe-tests-module/data/mocks/test"
import { IPixel, PIXEL_TYPES } from "../interfaces"
import { getACByID } from "src/modules/pe-game-science-module/data/mocks/academicDisciplines"

export const myPixels = () : IPixel[] => {
    return [
        {
            id: 1,
            title:"Handel: Ombra mai fu",
            post_content: "Countertenor Franco Fagioli’s new album features his personal selection of twelve favorite arias from Handel’s rich and colorful operatic world, including excerpts from Serse, Rinaldo and Ariodante. The album was recorded with specialist period-instrument ensemble Il pomo d’oro. Listen to the key track “Ombra mai fu” (Serse) of Franco Fagioli’s new album “Handel Arias”, released on Deutsche Grammophon.",
            type: PIXEL_TYPES.YOUTUBE,
            url: "https://www.youtube.com/watch?v=FD8eL-1a0As",
            hardness: 1,
            pe_academic_discipline: [getACByID('23')],
            price: 10,
        },
        {
            id: 2,
            title:"Handel: Funniest Musical Experience",
            post_content: "Franco Fagioli speaks about his most fun musical experience. On his new album Fagioli interprets his favourite Handel Arias. Learn more: https://DG.lnk.to/FagioliHandel",
            type: PIXEL_TYPES.YOUTUBE,
            url: "https://www.youtube.com/watch?v=KQkqqnMpxlw",
            hardness: 1,
            price: 10,
            pe_academic_discipline: [getACByID('23')],
        },
        {
            id: 3,
            title:"Краеведение",
            post_content: "цикл вопросов по краеведению",
            thumbnail: test().thumbnail,
            type: PIXEL_TYPES.TEST,
            url: "",
            test: test(),
            hardness: 5,
            price: 33,
            pe_academic_discipline: [getACByID('24')],
        },
        // ...questions()
        //     .filter( (q,i) => i < 9 )
        //     .map(q=> {
        //         return {
        //             id: q.id,
        //             title: q.title,
        //             post_content: q.post_content,
        //             thumbnail: q.thumbnail || "",
        //             type: PIXEL_TYPES.QUESTION,
        //             question: q,
        //             hardness: 1 || 1,
        //             price:1
        //         }
        //     })
    ]
}