import { Tooltip } from "@blueprintjs/core"
import { useState } from "react"
import { IFabulaEpisode, IFabulaHero, IFabulaMedia } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { DIALOG_SIZE, ID } from "src/libs/interfaces/layouts"
import { ScalableDialog } from "src/libs/useful"
import TileProtectionScreen from "./TileProtectionScreen"
import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface ITileEpisodeLabelProps {
    episode: IFabulaEpisode | IFabulaMedia
    i: number
    onClick: () => void
    onWin?: (isBroken: boolean) => void
}
const TileEpisodeLabel = ({episode, i, ...props}: ITileEpisodeLabelProps): JSX.Element => {
    const hero: IFabulaHero = useStorytellingStore( ( state: IStorytellingStore ) => state.hero )
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [size, setSize] = useState<DIALOG_SIZE>(DIALOG_SIZE.MINIMAL)
    const hasStaff = () : ID[] => {
        return episode.protection?.parameters.keys?.filter( (key: ID) => {
            return !!hero.staff.filter(staff => staff === key).length
        }) || []
    }
    const onClick = () => {
        if( episode.isBroken ) {
            if(props.onWin){
                props.onWin( false )
            }
            return
        }
        if( episode.protection || episode.defense) {
            const keys: ID[] = hasStaff()
            if( !keys.length )
            {
                setIsOpen(true)
                return 
            }
            else {
                if(props.onWin){
                    useStorytellingStore.setState({ executeStuff: keys[0] || "-1" })
                    props.onWin( false )
                    return 
                }
            }
        } 
        else {

        }
        props.onClick()
    }
    return <>
        <div
            style={{
                top: `${episode.y}%`,
                left: `${episode.x}%`,
            }}
            className="position-absolute"
        >
            <Tooltip
                content={<div>
                    {episode.label || episode.title} {
                        !!episode.protection 
                            ? 
                            episode.isBroken 
                                ? 
                                __("(broken)") 
                                : 
                                __("(protected)") 
                            : 
                            ""
                    } 
                </div>}
                className="p-3 z-index-100"
                interactionKind="hover"
                position="top"
            >
                <div 
                    className={`pe-fabula-episode-label ${episode.isBroken ? "broken" : ""} ${!!episode.protection ? "protected" : ""} ${!!episode.defense ? "defensed" : ""}`} 
                    onClick={ onClick }
                >
                    <div className="pe-fabula-episode-label-label"> 
                        { i + 1}
                    </div>
                </div>
            </Tooltip>
        </div>
        <ScalableDialog
            isOpen={isOpen}
            // onClose={() => setIsOpen(false)}
            dialogSize={size}
            className="transparent-dialog"
            hideClose            
        >
            <TileProtectionScreen 
                item={ episode } 
                onClick={ props.onClick } 
                onClose={() => setIsOpen(false)}
                onSize={ (size: DIALOG_SIZE) => setSize(size) } 
                onWin={( ) => {
                    if(props.onWin){
                        props.onWin( true )
                    }
                }}

            />
        </ScalableDialog>
    </>
}
export default TileEpisodeLabel