import { ID } from "src/libs/interfaces/layouts"
import { useEffect, useState } from "react"
import { IFabulaHero, IFabulaMedia, IFabulaSpeech, INPC, IPEStory, SPEECH_FINISH_TYPE } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"

/* Protopia Ecosystem component */
export interface ISpeechProps {
    item: IFabulaMedia
    beat: number
    onNextBeat: () => void
}
const Speech = ({item, beat,onNextBeat}: ISpeechProps): JSX.Element => {
    const story: IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current )
    
    const speech: IFabulaSpeech = story.speech?.filter( s => s.id === item.speech[beat])[0]
    //console.log( item )
    //console.log( beat, item.speech[beat] )
    //console.log( speech )
    
    return speech 
        ?
        <SingleSpeech 
            speech={ speech } 
            onNextBeat={ onNextBeat } 
        />
        :
        <></>
}
export default Speech

export interface ISingleSpeechProps {
    speech: IFabulaSpeech
    onNextBeat: () => void
}
export const SingleSpeech = ({speech, onNextBeat}:ISingleSpeechProps): JSX.Element => {
    const voices: boolean = useStorytellingStore((state: IStorytellingStore) => state.voices)
    const [timeOut, seTimeOut] = useState<any>(null)
    const [isAudioPlay, setIsAudioPlay] = useState<boolean>(false)
    const story: IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    const hero: IFabulaHero = useStorytellingStore((state: IStorytellingStore) => state.hero)
    const author: INPC | IFabulaHero = speech.isHero 
        ? 
        hero
        :
        story.NPC.filter(n => n.id === speech.npc)[0]
    //console.log( speech )
    useEffect(
        () => {
            clearTimeout( timeOut ) 
            //console.log( speech ) 
            if( !!speech.duration && speech.finishType === SPEECH_FINISH_TYPE.PASSED ) {
                seTimeOut( setTimeout( () => { 
                    setIsAudioPlay(false)
                    onNextBeat()
                },  speech.duration ) )
                
            } 
            if( !!speech.audioSource ) {
                setIsAudioPlay(true)
            }
            return () => { 
                clearTimeout( timeOut ) 
            }
        },
        [ speech ]
    )  
    const onAudioEnd = () => {
        setIsAudioPlay(false)
        if( speech.finishType === SPEECH_FINISH_TYPE.PASSED ) {
            onNextBeat()
        }   
    }  
    return <div className={`pe-speech-container `} onClick={ onNextBeat }>
        {
            speech.title && <div className="pe-speech-single-title">
                {speech.title}
            </div>
        }
        {
            ( author || speech.post_content ) &&  
            <div className={`pe-speech-single ${ speech.position } ${ isAudioPlay && voices ? "audio-active" : "" }`}>
                {
                    speech.post_content && <div dangerouslySetInnerHTML={{ __html:speech.post_content }} />
                }
                {
                    author && <div className="pe-speech-avatar-cont">
                        <div 
                            className="pe-speech-avatar"
                            style={{
                                backgroundImage: `url(${ author.avatar })`
                            }}
                        />
                        <div className="pe-speech-display-name">
                            { author.display_name }
                        </div>
                    </div>
                }
                
            </div>
        }
        
        <SpeechAudio audio={ speech.audioSource } play={isAudioPlay} onEnd={onAudioEnd}/>
    </div> 
}

interface ISpeechAudioProps {
    audio: string | undefined
    play: boolean
    //onPause: () => void
    onEnd: () => void
}
const SpeechAudio = ({...props}: ISpeechAudioProps) => {   
    const audioValue : number = useStorytellingStore((state: IStorytellingStore) => state.audioVolume)
    const voices : boolean = useStorytellingStore((state: IStorytellingStore) => state.voices)
    const [ audio, ] = useState<any>( new Audio( props.audio || "" ) ) 
    
    const onEnd = () => {
        audio.pause()
        props.onEnd()
    }
    
    const play = () => {
        //console.log( "play audio: " + props.audio )
        if(props.play) {
            //console.log( "play audio: " + props.audio )
            audio.play()
        }
        else {
            audio.pause()
        }
    }
    
    useEffect(() => { 
        if(!voices) {
            audio.pause()
            return
        }
        audio.addEventListener( 'ended', onEnd )
        audio.addEventListener( 'loadeddata', play )
        audio.load()
        audio.volume = audioValue;
        
        return () => {
            audio.removeEventListener( 'ended', onEnd )        
            audio.removeEventListener( 'loadeddata', play )
        }
    }, [ props.play, voices ])

    useEffect(() => {
        //setAudio( new Audio( props.audio || "" ) ) 
        //audio.play()
        audio.src = props.audio || ""
        //console.log( audio.currentSrc )
        audio.load()
    }, [ props.audio ])

    useEffect(() => {
        return () => audio.pause()
    }, [])

    return <></>
}