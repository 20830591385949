import { IDataTableProps } from 'src/modules/pe-fest-module/data/interfaces'
import { __ } from 'src/libs/utilities' 
  
import { DataTable } from "src/modules/pe-admin-module/views/dataTable"

const DataTableForm = ( props: IDataTableProps ): JSX.Element => {
 
    return (
        <div>            
            <div className='display-5 mb-3 ml-3'>{__(props.title)}</div>
            <DataTable 
                data_type={ props.data_type }
                route={ props.path } 
                isList={false} 
            />          
        </div>
    )
} 

export default DataTableForm