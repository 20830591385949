import { useEffect, useState } from "react"
import { IErmakMapCell } from "../../data/interfaces"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { __ } from "src/libs/utilities"
import ImproveBtn from "./slot/ImproveBtn"
import BuildBtn from "./slot/BuildBtn"
import DestructBtn from "./slot/Destruct"
import { useErmakStore } from "../../data/store"
import ErmakSlotItem from "./ErmakSlotItem"

/* Protopia Ecosystem component */
export interface IErmakSlotProps {
    bound: DOMRect
    item: IErmakMapCell | undefined
}
const ErmakSlot = ({ ...props }: IErmakSlotProps): JSX.Element => {
    const {width, height} = useScreenSize()
    const [bound, setBound] = useState<any>(props.bound )
    useEffect(() => {
        const wz = Math.min(width, height) > 600 ? 600 : width - 40 
        const hz = Math.min(width, height) > 600 ? 600 : height - 40
        const timeout = setTimeout(() => {
            setBound({
                top: height/2 - hz/2,
                left: width/2 - wz/2,
                y: height/2 - hz/2,
                x: width/2 - wz/2,
                width: wz,
                height: hz,
                bottom: height/2 + hz/2,
                right: width/2 + wz/2,
            })
        }, 40)
        return () => {
          clearTimeout( timeout )  
        } 
    }, [])
    const onStartBuild = () => {
        useErmakStore.setState({ isStoreOpen: true })
    }
    return <div
        style={{ 
            top:    bound.top,
            left:   bound.left,
            width:  bound.width,
            height: bound.height,
            backgroundColor: props.item?.type?.color
        }}
        className="pe-ermak-slot-container "
    >
        <div className="bg" style={{backgroundImage:`url(${props.item?.type?.thumbnail})`}}></div>
        <div className="p-1 text-light dt">
            <div className="pe-ermak-slot-title">
                {props.item?.type?.title || __("Empty slot") }
            </div> 
            <div className="m-2 small text-center">
                {props.item?.type?.post_content}
            </div>
            <ErmakSlotItem cell={props.item} />
            <div className="d-flex">
                <ImproveBtn />
                <BuildBtn onStartBuild={onStartBuild} />
                <DestructBtn /> 
            </div>
        </div>
    </div>
}
export default ErmakSlot