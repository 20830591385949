import { IManituActionType } from "@/modules/pe-manitu-module/data/interfaces"
import { IManitu, useManitu } from "../../../data/store"

interface IHoverProps {
    width: number
    height: number
    animationPadding: number
    mouseX: number
    mouseY: number
    isHover: boolean

}
const Hoverer = (props : IHoverProps) : JSX.Element => {
    const isActionChoose: boolean = useManitu((state: IManitu) => state.isActionChoose)
    const choosedAction: IManituActionType = useManitu((state: IManitu) => state.choosedAction)
    

    return <div
        className={`pe-matrix-hover-container ${isActionChoose && props.isHover ? "active" : ""}`}
        style={{ width: props.width, height: props.width }} 
    >
        <div 
            className={`pe-matrix-hover ${choosedAction?.icon}`}
            style={{ 
                top:  props.mouseY, 
                left: props.mouseX
            }}    
        > 
            
        </div>
    </div>
} 

export default Hoverer