import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm" 
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities" 
import { Position } from "@blueprintjs/core"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IAddWordProps {
    onAdd: (origin:string, translate: string) => void
}
const AddWord = (props: IAddWordProps): JSX.Element => { 
    const [origin, setOrigin] = useState<string>("")
    const [translate, setTranslate] = useState<string>("")
    const onDesmiss = () => {

    }
    const onConfirm = () => {
        props.onAdd(origin, translate)
    }
    return <ConfirmButton
        buttonIcon="plus"
        onDesmiss={onDesmiss}
        onConfirm={onConfirm}
        dialogTitle={__("Add new Word")}
        buttonMinimal
        isPopover
        popoverPosition={Position.TOP}
        popover={<div className="p-2">{__("Add new Word")}</div>}
    >
        <div className="px-3 w-100 ">
            <FieldInput
                type={SCALAR_TYPES.STRING}
                vertical={VerticalType.HORIZONTAL}
                title={__("Origin")}
                accept="[^a-zA-Z0-9]"
                value={origin}
                editable
                onChange={value => setOrigin(value)}
            />
            <FieldInput
                type={SCALAR_TYPES.STRING}
                vertical={VerticalType.HORIZONTAL}
                title={__("Translate")}
                value={translate}
                editable
                onChange={value => setTranslate(value)}
            />
        </div>
    </ConfirmButton>
}
export default AddWord