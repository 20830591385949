import { Route } from "react-router"
import { IEventRouteData } from "../data/interfaces" 
import NoMatchView from "src/modules/pe-basic-module/views/NoMatchView"

const getDefEventRoutes = ( eventRoutes: any )  => { 

    return Object.keys(eventRoutes).map( key  => {
        const er:IEventRouteData = eventRoutes[ key ]
        let ElComponent
        // console.log(  er.path.toString() ) 
        try {
            //console.log( typeof er.elementName ) 
            //console.log( er.elementName ) 
            ElComponent = require( "src/modules/pe-fabula-module/views/fabula/TaleForm" ).default
        }
        catch(e) {
            console.log("ERROR - STORY")
            console.log( e )
            ElComponent = NoMatchView 
        } 
        const r =  <Route
            key={ er.path.toString() }
            path={ er.path.toString() }
            element={ <ElComponent /> }
        />   
        return r
    })
}

export default getDefEventRoutes