
import BasicExtendView from '@/libs/basic-view/BasicExtendView' 
import TemplateForm from './template/TemplateForm'

export class AdminTemplateView extends BasicExtendView {  
    props: any
    center = ():JSX.Element => {  
       return <div className="w-100 mb-5">
            <TemplateForm {...this.props} />
       </div>
    }
}

export default AdminTemplateView 