import React from 'react' 
import BasicState from "@/libs/basic-view"   
import ProjectForm from './project/ProjectForm'
import PhaseLabel from './PhaseLabel'
import AdminItemLink from '@/modules/pe-admin-module/views/single/AdminItemLink'

class ProjectView extends BasicState {
    props: any
    addRender = () => {  
        return <>
            <ProjectForm {...this.props} />            
            <div className="position-fixed" >
                <PhaseLabel />
            </div>            
            <div className="position-fixed right" >
                <AdminItemLink {...this.props} />
            </div>  
        </> 
    }
} 

export default ProjectView