import { IMenuItem } from "@/libs/interfaces/layouts"
import { moduleExists, routing, template } from "@/libs/layouts"
import FooterMenuItem from "./FooterMenuItem"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { isRole } from "@/settings/zustand/user"

/* Protopia Ecosystem component */
export interface IFooterMenuProps {

}
const FooterMenu = (props: IFooterMenuProps): JSX.Element => {
    const user: IUser = useMainStore((state:IState) => state.user)
    if( ! template().footer ) return <></>
    const items: IMenuItem[] = routing()?.footer || []
        .filter((item:IMenuItem) => {
            return item.islogged && !user.id 
                && ( Array.isArray( item.capability ) && isRole( item.capability ) )
                && moduleExists(item.module)
        })
    return items?.length
        ?
        <div 
            className='pe-footer-menu-container'
            style={{
                background: template().footer.bg
            }}
        >
            <div className="container d-flex flex-centered"> 
            {
                items.map((item: IMenuItem) => {
                    return <FooterMenuItem item={item} key={item.id} />
                })
            }    
            </div>
            
        </div>
        :
        <></>
}
export default FooterMenu