
export const CLEAR_FESTIVAL_PRESCEPTIONS = ( data: any ) => {
    const gql: string =  `mutation clearPEFestivalData {
        clearPEFestivalData ( 
            input : {
                logs: ${data.logs}
                options: ${data.options}
                projects: ${data.projects}
                ratingSystem: ${data.ratingSystem}
                raitingData: ${data.raitingData}
            }
        ) {
            logs
            options
            projects
            ratingSystem
            raitingData
        }
    }`
    return gql
}