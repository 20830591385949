import { Button } from "@blueprintjs/core"
import { helpsTypes } from "../HelpMenuWidget"
import { isRole } from "@/settings/zustand/user"

const HelpMenu = (props:any) : JSX.Element =>
{
    return <div className='pe-fest-help-menu'>
    {
        props.currentList
            .filter( (video: any) => {
                return (!Array.isArray( video.roles ) || video.roles.length === 0 ) ||
                    ( typeof video.roles !== "undefined" && isRole( video.roles ) )
            }) 
            .map((video: any, i: number) =>
            {
                return <Button 
                    key={ video.url+"_"+i } 
                    className="" 
                    fill 
                    minimal 
                    large 
                    onClick={() => props.onClick( props.currentList[ i ] )}
                >
                    <div className=" text-uppercase text-center">
                        <i className={ `${ helpsTypes()[video.type]?.icon } mr-2 ${video.type} ` } />
                        {video.title}
                    </div>
                </Button>
            })
    }
    </div>
}

export default HelpMenu