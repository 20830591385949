import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useParams } from "react-router" 
import { __ } from "src/libs/utilities"
import { getFeedRoute, schema } from "src/libs/layouts" 
import AdminItemLink from "src/modules/pe-admin-module/views/single/AdminItemLink" 
import { IAds } from "../../data/interfaces"
import { actions } from "../../data/actions"
import { GET_SINGLE_AD_ACTION } from "../../data/actionTypes"  
import AdCard from "./AdCard";

const AdForm = (props: any) :JSX.Element => {
    const [item, setItem] = useState<IAds>({} as IAds) 
    const params = useParams()
    useEffect(() => {
        actions(GET_SINGLE_AD_ACTION, {id: Number(params.id)})
            .then((response: IAds | null) => {
                if(response) setItem(response)
            })
    }, [])
     
    const link = getFeedRoute("Ad") ? `/${getFeedRoute("Ad").route}`: "/404"
    return <div className="layot-state h-100"> 
        <div className="container flex-centered flex-column h-100">
            <AdCard className="mb-3" item={item} />
            <Link to={ link } >{__("All Ads")}</Link>  
        </div>
        
        <div className="position-absolute right top mt-3 mr-4" >
            <AdminItemLink singled_data_type={ "Ad" } />
        </div>
        
    </div>
}

export default AdForm