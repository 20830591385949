 
import { Outlet } from "react-router" 
import ToposHead from "./ToposHead"
import ToposFooter from "./ToposFooter"
import ToposNews from "../news/ToposNews"
import ToposVideos from "./ToposVideos"

export const labels: string[] = ["today", "day", "week", "month", 'year']

const ToposContainer = ( ) : JSX.Element => {
    return (                        
        <> 
            <div className="topos-first-head">
                <ToposHead />
                <ToposFooter />
            </div>
            <ToposNews />
            <ToposVideos />
            <Outlet />
        </>  
    )
}

export default ToposContainer