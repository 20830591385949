
import { Button, ButtonGroup, Tooltip } from "@blueprintjs/core"
import { useState } from "react"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { LayoutIcon, ScalableDialog } from "src/libs/useful"
import { __ } from "src/libs/utilities"
import { IPEResourceType } from "../../data/interfaces"
import ResourceMonitorAnimation from "./ResourceMonitorAnimation"
import ResourceSingleIndicator from "./ResourceSingleIndicator"
import TabOperations from "./TabOperations"

export interface IResourceSingleProps {
    resource: IPEResourceType
    count: number,
    clickable? : boolean
}

enum TAB {
    OPERATIONS,
    HISTORY,
    AUTO_PAYMENTS
}
const ResourceMonitorSingle = ({resource, count, ...props}: IResourceSingleProps) : JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [tab, setTab] = useState<TAB>(TAB.OPERATIONS)

    const onClose = () => {
        setIsOpen(false)
    }
    const onOpen =() => {
        if(props.clickable) {
            setIsOpen(true)
        }
    } 

    return <div className="pe-manitu-resource-single-cont">
        <Tooltip
            content={resource.title}
            position="top-right"
        >
            <div 
                className="resource" 
                style={{
                    backgroundImage: `url(${ resource.icon })`,
                    backgroundColor: resource.color
                }} 
                onClick={ onOpen }
            >
                <div className="resource-count">{ count }</div>
            </div>
        </Tooltip> 
        <ScalableDialog
            dialogSize={DIALOG_SIZE.NORMAL}
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className="pe-ludens-resource-dialog-container">
                <div className="pe-ludens-resource-panel">
                    <div>
                        <LayoutIcon
                            src={resource.icon}
                            className="px-4 py-2 mx-auto"
                            style={{
                                width: 75,
                                height: 75
                            }}
                        /> 
                        <div className="title">
                            { resource.title }
                        </div>
                        <div className="nominals" style={{backgroundColor: resource.color}}>
                            {count}
                        </div>
                        <div className="description">
                            { resource.post_content }
                        </div>
                    </div>
                    
                    <div>
                        
                        <ButtonGroup vertical fill> 
                            <Button 
                                minimal fill 
                                className="text-uppercase small" 
                                alignText="right" 
                                onClick={() => setTab(TAB.OPERATIONS)}
                                active={tab === TAB.OPERATIONS}
                            >
                            <span className="small">{__("Operations")}</span> 
                            </Button>
                            <Button 
                                minimal fill 
                                className="text-uppercase small" 
                                alignText="right" 
                                onClick={() => setTab(TAB.AUTO_PAYMENTS)}
                                active={tab === TAB.AUTO_PAYMENTS}
                            >
                                <span className="small">{__("Current auto payments")}</span>
                            </Button>
                            <Button 
                                minimal fill 
                                className="text-uppercase small" 
                                alignText="right" 
                                onClick={() => setTab(TAB.HISTORY)}
                                active={tab === TAB.HISTORY}
                            >
                                <span className="small">{__("Transfers history")}</span>
                            </Button>
                        </ButtonGroup>
                    </div> 
                </div>
                <div className="pe-ludens-resource-content overflow-y-auto">
                    <Tab tab={tab} count={count} resource={resource}/>
                </div>
            </div>
        </ScalableDialog>
        <ResourceMonitorAnimation resource={resource} count={count}/>
        <ResourceSingleIndicator resource={resource} />  
    </div>
    
} 

export default ResourceMonitorSingle


/* Protopia Ecosystem component */
export interface ITabProps {
    tab: TAB
    count: number
    resource: IPEResourceType
}
const Tab = (props: ITabProps) : JSX.Element => {
    switch(props.tab) {
        case TAB.AUTO_PAYMENTS:
            return <TabAutoPayments resource={props.resource}/>
        case TAB.HISTORY:
            return <TabHistory resource={props.resource}/>
        case TAB.OPERATIONS:
        default:
            return<TabOperations count={props.count} resource={props.resource}/>
    } 
} 
 
const TabAutoPayments = (props:any) : JSX.Element => {
    return props.resource.is_transfered 
    ?
     <div className='p-4 pe-resources-operations '>
        Auto Payments
    </div>
    :
    <div className="p-4 pe-resources-operations ">
        <span className="font-weight-bold mr-2">
            {props.resource.title}
        </span> 
        {__("not transferable")}
    </div>
} 
const TabHistory = (props: any) : JSX.Element => {
    return <div className='p-4 pe-resources-operations '>
        HISTORY
    </div>
}  