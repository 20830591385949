import { useEffect } from "react"
import AddFestivalContent from "./add-festival/AddFestivalContent"
import { clearFestival } from "../../hooks/festival"
import switchToBlog from "@/modules/pe-basic-module/hooks/switchToBlog"

/* Protopia Ecosystem component */
export interface IAddFestivalFormProps {

}
const AddFestivalForm = (props: IAddFestivalFormProps): JSX.Element => {
    useEffect(() => {   
        clearFestival()        
        switchToBlog( "-1" )
    }, [])
    return <div className='pe-add-festival-container '> 
        <AddFestivalContent />
    </div>
}
export default AddFestivalForm