import { Navigate, Route, Routes } from "react-router"  
import Today from "./Today"
import { IToposStore, useToposStore } from "../../data/store"
import ToposLayoutContainer from "../components/ToposLayoutContainer" 
import { labels } from "../../data/labels" 
import useEvent from "../../hooks/useEvent"
import EventsList from "./EventsList"

const ToposCalendarForm = (props: any) :JSX.Element => {
    useEvent( "-1" )
    const range: number = useToposStore((state:IToposStore) => state.range)
    return <div className="w-100 h-100">
        <Routes>
            <Route path="/*" element={<ToposLayoutContainer className=" dummy-class" />} >
                <Route index element={ <Navigate replace to={ labels[ range ] } /> } /> 
                <Route path="year" element={ <EventsList /> } />
                <Route path="month" element={ <EventsList /> } />
                <Route path="week" element={ <EventsList /> } />
                <Route path="day" element={ <EventsList /> } />
                <Route path="today" element={ <Today /> } />
            </Route>
        </Routes> 
    </div>
} 

export default ToposCalendarForm