
import BasicExtendView from '@/libs/basic-view/BasicExtendView' 
import AppTab from './settings/AppTab'

export class AdminAppView extends BasicExtendView {  
    props: any
    center = ():JSX.Element => {  
       return <div className="w-100 mb-5">
            <AppTab />
       </div>
    }
}

export default AdminAppView 