import { IOpponent } from "@/modules/pe-ludens-module/data/interfaces"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import { IManituStore, useManituStore } from "src/modules/pe-manitu-module/data/store"
import { IUser } from "src/settings/zustand"

 
interface IOpponentsProps {
    users: IOpponent[]
}
const Opponents = ({ users }: IOpponentsProps) : JSX.Element => {
    const [rows, ] = useState<number>(useManituStore.getState().matrixRows)
    const [columns, ] = useState<number>(useManituStore.getState().matrixColumns)
    const size: number = useManituStore((state: IManituStore) => state.size)
    const padding = 0
    const style = {
        width: columns * (size + 1) + padding * 2,
        height: rows * (size + 1) + padding * 2,
        minWidth: columns * (size + 1) + padding * 2,
        minHeight: rows * (size + 1) + padding * 2,
        padding: padding , 
        //transform: `translateX(${padding}px) translateY(${padding}px)`
    }
    return <div className="flex-centered h-100 mb-4" >
        <div className="p-3 overflow-y-auto flex-centered" >
            <div className="overflow-y-auto" style={style}>
                <div className=" flex-centered flex-wrap ">
                {
                    [ ...users, ].map((user:IUser) => { 
                        return <NavLink key={user.id} to={ `opponents/${ user.id }` } >
                            <div className="pe-manitu-opponent-avatar-btn" >
                                <div className="pe-manitu-opponent-avatar" style={{ backgroundImage: `url(${ user.avatar })` }} />
                            </div>
                        </NavLink>
                    })
                }
                </div>
            </div>
        </div> 
    </div>
} 

export default Opponents