import React from 'react' 
import { IFestival, IProject } from '../../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../../data/store'
import { __ } from '@/libs/utilities'
import FieldInput, { SCALAR_TYPES } from '@/libs/scalars'
import { IMarker, Map } from '@/libs/yandexMap'
import { jsonToData } from '@/modules/pe-fest-module/widgets/project-extended-fields/ExtendFieldSingleForm'
import { Collapsed } from '../ProjectData'


export interface IExtendsFormsProps {
    item : IProject 
    hidden: string
    isCollapseOpen:boolean
    vertical?:boolean
}
const ExtendedForms = (props: IExtendsFormsProps) : JSX.Element[] => {
    const festival : IFestival = useFestDataStore((state:IFestDataStor) => state.festival ) 
    const { extendedFields } = festival
    
    return  extendedFields?.filter( ( field: any ) => !field[ props.hidden ] )
        .map( (field: any, i:number ) => {
            return <SingleExtendedForm
                field={ field }
                key={ field.name }
                data={ props.item.form_fields ? props.item.form_fields[i] : {} }
                i={i} 
                isCollapseOpen={ props.isCollapseOpen }
                vertical={ props.vertical }
            />
        })
} 

export default ExtendedForms

export const SingleExtendedForm = (props:any): JSX.Element => {    
    const festival : IFestival = useFestDataStore((state:IFestDataStor) => state.festival ) 
    if( !Array.isArray( props.data ) || !props.data.length ) return <></>
    const { extendedFields } = festival 
    
    return <Collapsed
        vertical={ props.vertical }
        isOpen={props.isCollapseOpen}
        title={extendedFields[ props.i ].name}
        description={ 
            Array.isArray( props.data ) 
                ?
                extendedFields[ props.i ].count === 1 
                    ?
                    !!props.data[0] 
                        ? 
                        <SingleField
                            item={props.data[0]}
                            type={extendedFields[ props.i ].type}
                        /> 
                        : 
                        <div />
                    :
                    props.data
                        .filter( (d:any) =>  typeof d !== "undefined" )
                        .map( ( d: any, i: number ) => <SingleField
                            key={i}
                            item={ d }
                            type={extendedFields[ props.i ].type}
                        />  )
                :
                <div>--</div>
        }
    />
     
}

const SingleField = (props: any) :JSX.Element => { 
    switch(props.type) {
        case SCALAR_TYPES.MEDIA:
            return <FieldInput
                type={ SCALAR_TYPES.MEDIA }
                value={props.item}
                height={120} 
            />
        case SCALAR_TYPES.GEO:
            return <Map 
                width="100%"
                height={ 230 }
                coordinates={ jsonToData( props.item ) }
                markers={ [ 
                    { coordinates: jsonToData( props.item ) } as IMarker
                ] }
            />
        case SCALAR_TYPES.STRING:
        default:
            return <FieldInput
                type={ props.type }
                value={props.item }
                values={ props.values }
                className="text-center w-100 text-dark opacity_75"
            />
            //return <div>{props.item}</div>
    }
}