
interface IManProps {
    onClick?: () => void
    fill?: string    // ""
    stroke?: string  // "#999"
    isSelect?: boolean
    className?: string
    height?:number
}
const Man = (props:IManProps) => {
    return <svg 
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        x="0px" 
        y="0px"
        viewBox="0 0 257.7 411.2" 
        xmlSpace="preserve"
        style={{ marginRight:40, cursor: "pointer" }}
        height={props.height || 300}
        onClick={props.onClick}
    > 
        <path className={props.className} d="M137,404.7c18.3,0.1,36.6,0.1,54.9,0.2c1.3,2.6,1,4-2.4,4c-16.3,0-32.5-0.1-48.8,0
            C137.5,409,136.8,407.4,137,404.7z"/>
        <path className={props.className} d="M66.1,404.7c18.3,0,36.6,0.1,54.9,0.1c0.3,2.9-0.7,4.2-3.8,4.1c-15.8-0.1-31.5-0.1-47.3,0
            C66.6,409,65.8,407.5,66.1,404.7z"/>
        <path className={props.className} d="M188.4,397.8c-10.1-4.5-20.2-9.1-30.9-12c0.4-4.3,0.3-3.3,0.7-7.6c6.8-0.6,6.7-0.7,6.7-8
            c0-3.4-2.9-5.3-2.9-9.1c0.3-54.4,0.2-108.8,0.2-163.2c0-2.3-0.3-4.8-0.2-7c1.6-4.5-0.1-9.4,1.9-13.9c2.4,10.9,5.2,21.7,7.2,32.6
            c1.6,8.7,6.9,17.3,1.1,26.4c-0.5,0.7-0.3,2-0.2,3c0.2,1.7-0.9,3.8,0.8,5.2c1.7,1.3,2.1-2.5,4-1.4c0.4,3.4,0.8,6.7,1.1,10
            c0.2,1.7,0.3,3.5,2.3,4c2,0.5,3.6-0.4,4.8-2c2.4-3.1,3.7-6.6,4-10.6c0.4-6.4-1.4-12.5-1.8-18.8c-1.9-26.9-2.9-53.8-5.4-80.7
            c2.3-2.8,2.2-6.6-0.2-9.8c-0.5-2.4-1.1-5.7-1.6-8.1c1.2-6,0.5-11.8-1.7-17.4c-0.1-2.5-0.2-5-0.3-7.4c1-6.6,1.8-13.1-2.4-19
            c-0.2-0.2-0.4-0.3-0.6-0.4c-0.3-0.3-0.7-0.7-1-1c-1.7-2.2-4-3.4-6.8-3.7c-8.7-1.2-17.3-3-26.1-3.5c-0.3-3.2-0.6-6.5-0.8-9.7
            c0.5-2.2,1.2-5.5,1.7-7.7c2.2-4,3-9,8.3-11.3c3.3-1.4,4.1-5.8,3.7-9.6c-0.4-4.1-3.8-2.4-6-3.2c-0.4-0.1-0.5-0.8-0.7-1.2
            c0.8-4.1,0.3-8.3,1.5-12.3c0.6-2.1,0.1-4.5-1.8-5.9c-2.2-1.6-3.7-3-2.5-6c0.4-0.9-0.5-2.3-1.6-1c-1.8,1.9-2.3,0.6-3.1-0.8
            c-2.4-4.4-6.2-4.7-10.2-2.7c-2.8,1.4-5.5,1.8-8.6,1.3c-4.5-0.7-9,0-13,2.5c-0.7,0.4-1.2,1.1-1.8,1.7c4.1,3-1.5,4.7-0.9,7.1
            c0.5,2-3.7,6.4,2.8,5.7c1-0.1,0.8,1.3,0.8,2c0.2,1.7,0.2,3.3,0.3,5c-0.3,2.2,1.1,5.5-2.8,5.3c-2.2-0.1-3.8,2-3.3,4.2
            c1,4,2,8.1,6.4,9.8c0.9,0.4,1.8,0.6,2.3,1.7c3.7,8.1,5.7,16.4,4,25.3c-7.2,0.9-14.5,1.5-21.7,2.7c-8.5,1.4-11.8,5-12.9,12.7
            c-1.3,4-1.7,8-0.5,12c0,0.5,0,1.2,0,1.7c-0.2,0.4-0.3,0.9-0.5,1.3c-0.4,6-0.7,11.9-1.1,17.9c1,1.6,0.7,3.1,0.3,4.7
            c0.4-1.6,0.7-3.1-0.3-4.7c-1.1,5.9-2.2,11.8-3.2,17.7c-0.2,1.3,0.1,2.7,1.2,3.6c-0.8,11.1-2,22.1-2.4,33.3
            c-0.4,11.9-2.2,23.8-2.3,35.8c-0.1,10.3-3.3,20.3-2.7,30.7c0.3,4.3,1.7,8.1,4.4,11.4c1.1,1.3,2.6,2.1,4.4,1.6
            c1.7-0.5,2-2.1,2.2-3.6c0.5-3.6,0.8-7.1,1.2-10.6c1.9-0.2,2.1,2.8,4,1.7c1.6-0.9,0.8-2.7,0.9-4.1c0.2-2.7,0-5.6-1.6-7.7
            c-2.2-3-1.6-5.8-0.9-8.9c2.6-11.7,5.8-23.2,7.6-35c1.8,0,2.7,1.5,4,2.4c0,56.8,0,113.5-0.1,170.3c0,1.7,0.9,3.8-0.8,5.2
            c-3,2.4-1.2,5.5-1.4,8.2c-0.6,5.7,4.4,2,6.3,3.5c0.1,0.4,0.1,0.8,0.1,1.2c0,2.5-0.1,3.8-0.1,6.3c-10,3-19.5,7.5-29.2,11.4
            c-3.2,1.3-4.6,4.1-4.8,7.6c18.3,0,36.6,0.1,54.9,0.1c0-3.4,0-6.9-0.1-10.3c-0.1-1.8,0.8-4.1-1.9-5l0-11.4c1-0.1,2-0.3,3-0.2
            c4.4,0.7,5.4-1,5.6-5.3c0.1-3.9-2.6-6.5-2.6-10.3c0.2-45.9,0.2-91.8,0.1-137.8c0-3.3,0.5-4.7,4.3-4.6c3.7,0,4.3,1.4,4.3,4.7
            c-0.1,45.9-0.1,91.8-0.1,137.8c0,1.6,0.3,3.2-1.1,4.6c-2.8,2.9-1.8,6.8-1,9.9c0.6,2.4,4.1,0.5,6.3,1c1.6,0.4,3.5,0,4.6,2.7
            c-1.2,3.3-1.9,5.5-3.1,8.8c-1.8,1.1-1.3,2.8-1.4,4.4c-0.2,3.6,0.3,7.2-0.6,10.8c18.3,0.1,36.6,0.1,54.9,0.2
            C192.4,401.7,191.1,399,188.4,397.8z"/> 
    </svg>

}
export default Man