 
import EditDictionaryForm from "./edit-dictionary/EditDictionaryForm"
import BasicExtendView from "@/libs/basic-view/BasicExtendView"
import ModulesMenu from "./edit-dictionary/ModulesMenu"
import { __ } from "@/libs/utilities"
import Head from "./edit-dictionary/Head"

export default class EditDictionaryView extends BasicExtendView {
    props: any
    center = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <EditDictionaryForm {...this.props} />  
        </div>
    }
    left = () => {
        return <ModulesMenu />
    }
    head = () => {
        return <Head />
    }
}