import Layouts from "../layouts"
//import ReactDynamicImport from "react-dynamic-import"; 

export default function importAll(r:any, exists = [], components: any = {}, lasies: any = {}, prefix = "./" ) 
{
  r.keys().forEach((key: string) => {    
    let key2: string[] = key
      .replace("./", "")
      .split(".")
      .slice(0, -1)
      .join(".")
      .split("/")
    let key1 = key2[key2.length - 1]
    if (exists.length === 0 || exists.filter((elem) => elem === key1).length > 0) {
      components[key1] = r(key)
      //console.log( prefix + key.replace("./", "") )
      lasies[key1] = prefix + key.replace("./", "")
    }
  })
} 


export function getModules()
{
  return Layouts() && Layouts().modules
  ? 
  Layouts().modules
  : 
  { }
}
export function getView( viewName:string ):any {
  const views: any = Layouts().views
  const vewObj: any = Object.keys(views)
    .filter( v => {
      return v === viewName
    })[0] 
    if( vewObj )
    {
      return import( `src/modules/${ views[ vewObj ].module }/views/${vewObj}` )
    }
    else {
      return null
    }
}
export function getAllViews(components:any = {}) {
  const modules = getModules();
  Object.keys(modules).forEach(module => {
    Object.keys(modules[module].views).forEach(view => { 
      try {
        components[ view ] = import( `src/modules/${module}/views/${view}` ) 
      }
      catch (e) {}
    });
  }); 
  // console.log( components )
  return components
}

export function getAllWidgets( components: any = {}) {
  const modules = getModules(); 
  Object.keys(modules).forEach((module) => { 
    Object.keys( 
      {
        ...modules[module].widgets, 
        ...modules[module].extentions 
      }
    ).forEach(async widget => { 
      try {
        components[ widget ] = import(  `src/modules/${module}/widgets/${widget}` ) 
      }
      catch (e : any ) { } 
    })  
  }) 
}