import i18next from "i18next"
import { modules } from "../layouts";
import { IDictionary } from "../interfaces/layouts";
import { useMainStore } from "@/settings/zustand"; 

let ru_orig: IDictionary = {};
export const initDictionary = ( lang: string, src: IDictionary ) : Promise<boolean> =>
{ 
  return new Promise<boolean>(( resolve ) => {
    ru_orig = getAllDictionaries()
    i18next
      .init({
        keySeparator: "",
        nsSeparator: "",
        interpolation: {
          // React already does escaping
          escapeValue: false,

        },
        lng: "ru", // 'en' | 'ru'
        // Using simple hardcoded resources for simple example
        resources: {
          ru: {
            translation: ru_orig,

          },

        },
      })
      resolve(true)
    })  
}
export default i18next

export function __(text : string) {
  return i18next.t( text ) || text
}

export const getAllDictionaries = ( ) : IDictionary => {
  const dictionary : IDictionary = useMainStore.getState().dictionary 
  let dict: IDictionary = {}
  Object.keys( modules() ).forEach(mname => {
    const l = require( `src/modules/${mname}/lng/ru-RU.json` )
    dict = { ...dict, ...l }
  })
  return { ...dict, ...dictionary }
} 
