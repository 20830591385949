import { useState } from 'react';
import { useNavigate, useParams } from "react-router";
import { AppToaster } from "src/libs/useful";
import { ISettingsPageFormProps } from '../../../pe-fest-module/data/interfaces';
import ClearMaster from "../../../pe-fest-module/views/festAdmin/inheritance/ClearMaster";
 
import { Button, Card, Dialog, Icon, Intent } from '@blueprintjs/core';
import { DELETE_SITE_ACTION } from 'src/modules/pe-basic-module/data/actionTypes';
import { default as mainActions } from "src/modules/pe-basic-module/data/actions";
import { DIALOG_SIZE } from 'src/libs/interfaces/layouts';
import { __ } from 'src/libs/utilities';
import { component_url } from 'src/libs/utilities/links';
import { CLEAR_FESTIVAL_PRESCEPTIONS_ACTION } from '../../../pe-fest-module/data/actionTypes';
import { actions } from '../../../pe-fest-module/data/actions';
import SnapShotMaster from '../../../pe-fest-module/views/festAdmin/inheritance/SnapShotMaster';
import { ReactComponent as Clear } from "../../assets/img/clear.svg";
import { ReactComponent as DeleteFest } from "../../assets/img/deleteFest.svg";
import { ReactComponent as Snapshot } from "../../assets/img/snapshot.svg";

 
const EXPORT_GRANDING_SYSTEM = "export"
const TEMPLATE_GRANDING_SYSTEM = "template"

const InheritanceForm = (props: ISettingsPageFormProps) : JSX.Element => {
    const {landId} = useParams()
    const [isOpen1, onOpen1] = useState(true)
    const [isOpen2, onOpen2] = useState(false)
    const [isSnapshotOpen, setSnapshotOpen] = useState(false)
    const [isClearOpen, setClearOpen] = useState(false)
    const [isDeleteOpen, setDeleteOpen] = useState(false)    
    const [isClearDoing, setIsClearDoing] = useState(false)
    const [newTitle, onNewTitle] = useState("")
    const [newURL, onNewURL] = useState("")
    const [newGranding, onNewGranding] = useState( TEMPLATE_GRANDING_SYSTEM )
    const [newStirpe, onNewStirpe] = useState("")
    const [selectProject, changeSelectProjects] = useState([])
    const [selectCategory, changeSelectCategories] = useState([])
    const [selectCritery, changeSelectCriteries] = useState([])
    const [selectGanre, changeSelectGanres] = useState([])
    const [patterns, setPatterns] = useState( [] ) 
    const [newType, onNewType] = useState( "" )
    const [loading, setLoading] = useState( true )
    const navigate = useNavigate()
    
    const iconSize = 50
    const serverURLs = [ "", "wpfest.ru" ]
    const switch1 = () =>
    {
        onOpen1(!isOpen1)
        onOpen2(!isOpen2)
    }
    const changeNewStirpe = (value: string) =>
    { 
        onNewStirpe( value )
    }
    const selectProjects = () =>
    {

    }
    const selectCategories = () =>
    {

    }
    const selectCriteries = () =>
    {

    }
    const selectGanres = () =>
    {

    }
    const onClearOpen = () =>
	{
        setClearOpen(!isClearOpen) 
	}
	const onDeleteOpen = () =>
	{
        setDeleteOpen(!isDeleteOpen) 
	}
    const onStartClean = (data: any) =>
    {
        setIsClearDoing(true)  
        setClearOpen( false )  
        actions(CLEAR_FESTIVAL_PRESCEPTIONS_ACTION, data ) 
            .then(( response: any ) => {
                console.log( response )  
                setIsClearDoing(false)            
                AppToaster.show({
                    message: __("Successful update"),
                    intent: Intent.SUCCESS
                })
            })
    }
	const onDelete = () => { 
        mainActions( DELETE_SITE_ACTION, { land_id: landId } )
            .then((res: boolean) => {
                AppToaster.show({
                    message: __( res ? "Site remove Successful." : "Unknown error" ),
                    intent: res ? Intent.SUCCESS : Intent.DANGER,
                    timeout: 10000
                })
                if( res ) {
                    navigate(component_url( "FestivalAdminListView" ))
                }
            })
    } 
    const onStartSnapshot = ( data: any ) => {

    }
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='display-5 mb-3'>{ __(props.title) }</div> 
            </div>
            <div className="p-5 card w-100 mb-2 m-0">
                <p className="display-6 font-italic">
                    { __( "Be careful my friend! This is Chernobyl. A place that smells of death." ) }
                </p>
                <p className="mt-2 title text-center">
                    { __( "Virgil" ) }
                </p>
            </div>
            <div className='row'>
                <div className='col-12 '>
                    {/* 
                    <Card className="p-4 mb-2" >
                        <div className="lead mb-5">{__("Stirpes")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light">
                                <Stripe width={iconSize} height={iconSize} className="m-4" />
                            </div>
                            <div className="w-100">
                                <FieldInput
                                    type="string"
                                    field="stirpe" 
                                    title={ __("Predecessor") }
                                    value={""}
                                    editable={false}
                                /> 
                                <FieldInput
                                    type="string"
                                    field="stirpe"
                                    editable
                                    title={__("stirpe Festival")}
                                    value={newStirpe}
                                    onChange={ changeNewStirpe }
                                />
                            </div>
                        </div>
                    </Card>

                    <Card className="p-4 mb-2" >
                        <div className="lead mb-2">{__("Creating successor")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3">
                                <Succesor width={iconSize} height={iconSize} className="m-4" />
                            </div>
                            <div className="w-100"> 
                                <Collapse isOpen={isOpen1}>
                                    {
                                        newStirpe
                                            ?
                                            <Button
                                                fill
                                                large
                                                intent={Intent.SUCCESS}
                                                minimal
                                                onClick={switch1}
                                            >
                                                {__("Start creation new successor")}
                                            </Button>
                                            :
                                            <div className="alert alert-danger p-4  lead text-center">
                                                {__("For creating successor need Stripe name")}
                                            </div>
                                    }
                                    
                                </Collapse>
                                <Collapse 
                                    isOpen={isOpen2} 
                                    transitionDuration={1000}
                                    children ={ <>
                                        <div className="pb-5">                        
                                            <FieldInput
                                                type="string"
                                                field="title"
                                                title={__("Insert title successor Festival")}
                                                value={newTitle}
                                                onChange={ (value: string ) => onNewTitle(value) }
                                            />                                        
                                                            
                                            <div className="row  dat strob01">
                                                <div className="col-md-3 layout-label">
                                                    {__("URL of successor Festival (only singleworld name)")}
                                                </div>
                                                <div className="col-md-9 layout-data">
                                                    <div className="py-2 w-100 d-flex align-items-center">  
                                                        <i className="fas fa-desktop mr-2" />                              
                                                        <span className="prefix">{ serverURLs[0] + "//" }</span>
                                                        <input
                                                            type="text"
                                                            className="form-control input dark"
                                                            value={ newURL }
                                                            onChange={ evt => onNewURL( evt.currentTarget.value ) }
                                                        />
                                                        <span className="postfix ml-2">{ "." + serverURLs[serverURLs.length - 1] }</span> 
                                                        <Button
                                                            className="right"
                                                            icon="cross"
                                                            minimal
                                                            onClick={ () => onNewURL('') }
                                                        />
                                                    </div>                                
                                                </div>
                                            </div>                  

                                            <div className="row  dat strob01">
                                                <div className="col-md-3 layout-label">
                                                    {__("Export projects")}
                                                </div>
                                                <div className="col-md-9 layout-data">
                                                    <div className="py-2 w-100">
                                                        <div className="pb-2">
                                                            {sprintf(__("%s projects selected for export to successor"), selectProject.length + "" )}
                                                        </div>
                                                        <Button fill large alignText="left" onClick={selectProjects}>
                                                            {__("Select over projects collection")}
                                                        </Button>
                                                        <div className="small opacity_75 pt-2">
                                                            {__("Check bulk of every Project to export and click «Save selction» button")}
                                                        </div>
                                                    </div>                                
                                                </div>
                                            </div>
                                                        
                                            <FieldInput
                                                type="radio" 
                                                title={__("Grading system transfer settings")}
                                                value={newGranding}
                                                values={[
                                                    {
                                                        _id:    EXPORT_GRANDING_SYSTEM,
                                                        title:  "Export from here",
                                                        commentary: "Transfer selected categories, criteria and genres from this Festival"
                                                    },
                                                    {
                                                        _id:    TEMPLATE_GRANDING_SYSTEM,
                                                        title:  "Use site template",
                                                        commentary: "The rating system will be imported from one of the ready-made Festival templates. It must be chosen"
                                                    }
                                                ]}
                                                onChange={ ( value ) => onNewGranding( value ) }
                                            />  
                                            <Collapse
                                                isOpen={ newGranding === EXPORT_GRANDING_SYSTEM}
                                                children ={ <>
                                                    <div className="row  dat strob01">
                                                        <div className="col-md-3 layout-label">
                                                            {__("Export Categories")}
                                                        </div>
                                                        <div className="col-md-9 layout-data">
                                                            <div className="py-2 w-100">
                                                                <div className="pb-2">
                                                                    {sprintf(__("%s categories selected for export to successor"), selectCategory.length + "" )}
                                                                </div>
                                                                <Button fill large   alignText="left" onClick={selectCategories}>
                                                                    {__("Select over categories collection")}
                                                                </Button>
                                                                <div className="small opacity_75 pt-2">
                                                                    {__("Check bulk of every Category to export and click «Save selction» button")}
                                                                </div>
                                                            </div>                                
                                                        </div>
                                                    </div>              
                                                    <div className="row  dat strob01">
                                                        <div className="col-md-3 layout-label">
                                                            {__("Export Criteries")}
                                                        </div>
                                                        <div className="col-md-9 layout-data">
                                                            <div className="py-2 w-100">
                                                                <div className="pb-2">
                                                                    {sprintf(__("%s criteries selected for export to successor"), selectCritery.length + "" )}
                                                                </div>
                                                                <Button fill large   alignText="left" onClick={selectCriteries}>
                                                                    {__("Select over criteries collection")}
                                                                </Button>
                                                                <div className="small opacity_75 pt-2">
                                                                    {__("Check bulk of every Critery to export and click «Save selction» button")}
                                                                </div>
                                                            </div>                                
                                                        </div>
                                                    </div>            
                                                    <div className="row  dat strob01">
                                                        <div className="col-md-3 layout-label">
                                                            {__("Export Ganres")}
                                                        </div>
                                                        <div className="col-md-9 layout-data">
                                                            <div className="py-2 w-100">
                                                                <div className="pb-2">
                                                                    {sprintf(__("%s ganres selected for export to successor"), selectGanre.length + "" )}
                                                                </div>
                                                                <Button fill large   alignText="left" onClick={selectGanres}>
                                                                    {__("Select over ganres collection")}
                                                                </Button>
                                                                <div className="small opacity_75 pt-2">
                                                                    {__("Check bulk of every Ganre to export and click «Save selction» button")}
                                                                </div>
                                                            </div>                                
                                                        </div>
                                                    </div>
                                                </>}
                                            />    
                                            <Collapse
                                                isOpen={ newGranding === TEMPLATE_GRANDING_SYSTEM}
                                                children={ 
                                                    <div className="row  dat strob01">
                                                        <div className="col-md-3 layout-label">
                                                            {__("Get granding system from template site")}
                                                        </div>
                                                        <div className="col-md-9 layout-data">
                                                            <div className="py-2 w-100 d-flex align-items-center"> 
                                                                <select 
                                                                        className="form-control input dark" 
                                                                        value={newType} 
                                                                        onChange={evt => onNewType( evt.currentTarget.value ) }
                                                                    >
                                                                    {
                                                                        patterns.map((site: any) => {
                                                                            return <option
                                                                                key={ site.domain_id }
                                                                                value={ site.domain_id }
                                                                            >
                                                                                {site.title}
                                                                            </option>
                                                                        })
                                                                    }    
                                                                </select>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                }
                                            />            
                                            
                                        </div> 
                                        <Button
                                            fill
                                            large
                                            intent={ Intent.SUCCESS }
                                            onClick={ switch1 }
                                        >
                                            {__("Create successor right now")}
                                        </Button>
                                    </>}
                                /> 
                            </div>
                        </div>
                    </Card> 
                    */}
                    <Card className="p-4 mb-2">
                        <div className="lead mb-2">{__("Snapshot festival data")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3 ">
                                <Snapshot width={iconSize} height={iconSize} className="m-4" />
                            </div>
                            <div className="w-100">
                                <Button
                                    intent={Intent.SUCCESS}
                                    className="lead p-4" 
                                    fill
                                    onClick={ () => setSnapshotOpen( !isSnapshotOpen ) }            
                                >
                                    <div className='d-flex p-3'>
                                        <Icon 
                                            icon={ "camera" }
                                            className={ "mr-3" }
                                        />
                                        {__("Start Snapshot Master")}
                                    </div>
                                </Button>
                            </div>                 
                        </div>
                    </Card>	
                    
                    <Card className="p-4 mb-2">
                        <div className="lead mb-2">{__("Clean festival data")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3 ">
                                <Clear width={iconSize} height={iconSize} className="m-4" />
                            </div>
                            <div className="w-100">
                                <Button
                                    intent={Intent.WARNING}
                                    className="lead p-4" 
                                    fill
                                    onClick={onClearOpen}            
                                >
                                    <div className='d-flex p-3'>
                                        <Icon 
                                            icon={ isClearDoing ? "repeat" : "filter-remove" }
                                            className={ isClearDoing ? "fa fa-spin mr-3": "mr-3"}
                                        />
                                        {__("Start clean Master")}
                                    </div>
                                    
                                </Button>
                            </div>                 
                        </div>
                    </Card>	
                    <Card className="p-4 mb-2">
                        <div className="lead mb-2">{__("Delete the festival and its data permanently")}</div>
                        <div className="flex-centered w-100">
                            <div className=" bg-light mr-3 ">
                                <DeleteFest width={iconSize} height={iconSize} className="m-4" />
                            </div>
                            <div className="w-100">
                                <Button
                                    intent={Intent.DANGER}
                                    className="lead p-4"
                                    fill
                                    onClick={ onDeleteOpen }
                                >
                                    <div className='d-flex p-3'>
                                        <Icon icon="trash" className="mr-3"/>
                                        {__("Delete now")}
                                    </div>
                                </Button> 
                            </div>
                        </div>
                    </Card>
                    <Dialog
                        isOpen={ isDeleteOpen}
                        onClose={ onDeleteOpen}
                        title={__("Delete the festival and its data permanently")}
                        className={DIALOG_SIZE.MINIMAL}
                    >
                        <div className="p-4">
                            <Button
                                intent={Intent.DANGER}
                                className="lead p-4"
                                fill
                                onClick={ onDelete }
                            >
                                {__("Delete now")}
                            </Button>
                        </div>	
                    </Dialog>
                    <Dialog
                        isOpen={ isClearOpen}
                        onClose={ onClearOpen} 
                        className={DIALOG_SIZE.NORMAL}
                    >
                        <div className="p-0">
                            <div className="p-4">
                                <ClearMaster 
                                    onStartClean={onStartClean}
                                />
                            </div>
                            
                        </div>	
                    </Dialog>
                    <Dialog
                        isOpen={ isSnapshotOpen}
                        onClose={ () => setSnapshotOpen(false) } 
                        className={DIALOG_SIZE.NORMAL}
                    >
                        <div className="p-0">
                            <div className="p-4">
                                <SnapShotMaster 
                                    onStartSnapshot={onStartSnapshot}
                                />
                            </div>
                            
                        </div>	
                    </Dialog>
                </div>
            </div>
        </div>
    )
} 

export default InheritanceForm