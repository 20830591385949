import { Widgets } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface ILayoutMainFooterProps {

}
const LayoutMainFooter = (props: ILayoutMainFooterProps): JSX.Element => {
    return <div className='pe-layout-main-footer-container'>
        <div className="float-container">
            <Widgets areaName={"layout-basic-view-footer"} data={ props } defArea={<></>}/>
        </div>
    </div>
}
export default LayoutMainFooter