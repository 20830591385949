
import Layouts from "."

export function modules(): any {
  return Layouts().modules
    ? 
    Layouts().modules
    : 
    { }
}
export function moduleExists( moduleName: string ) : boolean
{
  return modules()[ moduleName ] && !modules()[ moduleName ].disabled
}
