import { useRef, useState } from "react"
import { IFabulaHero, IPEFabulaMapTile, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"

/* Protopia Ecosystem component */
export interface ITileProps {
    item?: IPEFabulaMapTile
    x: number
    y: number
    onClick: () => void
}
const TileMenuMap = (props: ITileProps): JSX.Element => {
    const ref = useRef(null)
    const current: IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    const hero : IFabulaHero = useStorytellingStore((state: IStorytellingStore) => state.hero)
    const width : number = current.map?.width / current.map?.tileX
    const height : number = current.map?.height / current.map?.tileY
    
    const [isEnabled, setIsEnabled] = useState<boolean>(
        props.item 
            ? 
            ( typeof props.item?.enabled === "undefined" 
                ? 
                true 
                : 
                !!props.item?.enabled 
            )
            :
            false 
    ) 
    return <>
        <div 
            data-x={ props.x }
            data-y={ props.y }
            className={ `pe-story-map-tile ${ isEnabled ? "" : "disabled" }` } 
            style={{
                left:  width * props.x,
                top:  height * props.y,
                width,
                height,
                color: "red"
            }}
            onClick={ props.onClick }
            ref={ ref } 
        >
        {
            hero.tileID === props.item?.id &&
                <div className="pe-fabula-mine-hero-label" /> 
        }
        </div>
        
    </>
}
export default TileMenuMap