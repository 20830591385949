import { useState } from "react"
import StoryIntro from "../story/StoryIntro"
import StoryQuest from "../story/StoryQuest"
import { useStorytellingStore } from "src/modules/pe-fabula-module/data/store"

/* Protopia Ecosystem component */
export interface IFabulaQuestFormProps {

}
const FabulaQuestForm = (props: IFabulaQuestFormProps): JSX.Element => {
    const [phase, setPhase] = useState<"INTRO" | "QUEST">("INTRO")
    const onFinishIntro = () => {
        setPhase("QUEST")
    }
    const onFinishQuest = () => {
        useStorytellingStore.setState({ 
            isMapOpen : false,
            isSettingsOpen : false,
            isBreakOpen : false,
            isQuestOpen : false,
        })
    }
    return <div className='pe-fabula-quest-form-container'>
        <div className=" " >
            {
                phase === "INTRO"
                    ?
                    <StoryIntro
                        isMainIntro={false}
                        onFinishPlay={onFinishIntro}
                    />
                    :
                    <StoryQuest 
                        isMainIntro={false}
                        onFinishPlay={onFinishQuest}
                    />
            }
        </div> 
    </div>
}
export default FabulaQuestForm