
import { Button, Icon, Intent } from "@blueprintjs/core"
import { useState } from "react"
import { schema } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { IAdminStore, IBulk, useAdminStore } from "../../data/store"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION, UPDATE_BULK_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"
import { useParams } from "react-router"

const BulkVisibler = (props: any) : JSX.Element => {
    const {landId} = useParams()
    const [isLoading, setIsLoading]             = useState(false)
    const [isVisible, setIsVisible]             = useState<boolean>(false)
    const bulks: IBulk[]                        = useAdminStore((state:IAdminStore) => state.bulks)
    const offset: number                        = useAdminStore((state:IAdminStore) => state.offset) 
    const paging: string                        = useAdminStore((state:IAdminStore) => state.paging) 
    const pageCount: number                     = useMainStore( ( state: IState ) => state.pageCount )

    const setBulks: ((bulks: IBulk[]) => void)  = useAdminStore((state:IAdminStore) => state.setBulks) 
    const setItems: ((items: any[]) => void)    = useAdminStore( (state:IAdminStore) => state.setItems )

    if( !schema()[ props.data_type ]?.apollo_fields?.post_status ) return <></>

    const onShow = () => {
        onChangeVisible(true)
    }
    const onHide = () => {
        onChangeVisible(false)
    }
    const onChangeVisible = (visible: boolean) => {
        setIsLoading(true)
        setIsVisible(visible)
        console.log( offset )
        const post_status: string = visible  ? "publish" : "draft" 
        actions( 
            UPDATE_BULK_ACTION, 
            { 
                id: bulks.filter(bulk => bulk.checked).map(bulk => bulk.id), 
                data_type : props.data_type, 
                data:{ post_status },
                landId
            } 
        )
            .then((response) => { 
                console.log(response)
                actions(
                    GET_FEED_ACTION, 
                    { 
                      data_type: props.data_type, 
                      offset, 
                      count: pageCount, 
                      paging,
                      land_id: landId 
                    })
                    .then( response2 => {
                        setIsLoading( false )
                        setItems(response2.feed)
                        setBulks( response2.feed.map((f: any) => ({
                            id: f.id,
                            checked: false,
                            data_type: props.data_type
                        })))
                    })
            })
    }
     
    return <>
        <Button 
            className={ `h-100 px-4 `}
            intent={Intent.NONE} 
            minimal 
            onClick={onShow}
        >
            <Icon icon={ isLoading && isVisible ? "repeat" : "eye-open" } className={isLoading && isVisible ? `mr-2 fa fa-spin ` : `mr-2 `} />
            {__("Show bulk items")}
        </Button> 
        <Button  
            className={ `h-100 px-4 `}
            intent={Intent.NONE} 
            minimal 
            onClick={onHide}
        >
            <Icon icon={ isLoading && !isVisible ? "repeat" : "eye-off" } className={isLoading && !isVisible ? `mr-2 fa fa-spin ` : `mr-2 `} />
            {__("Hide bulk items")}
        </Button> 
    </>
} 

export default BulkVisibler