
import BasicState from "src/libs/basic-view" 
import "../assets/css/style.scss"
import ToposStoreForm from "./store/ToposStoreForm"

export default class ToposStoreView extends BasicState {
    props: any
    addRender = () => {
        return <ToposStoreForm { ...this.props } onOpenLogin={ this.onOpenLogin } />  
    }
}