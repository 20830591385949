import React from "react" 
import BasicState from "@/libs/basic-view"
import RestorePasswordForm from "./profile/RestorePasswordForm"

class RememberPasswordView extends BasicState {
	

	addRender = () => {		
		return (
			<div className="row justify-content-center">
				<div className="col-md-4 col-12">
					<RestorePasswordForm />
				</div>
			</div>
		)
	}

}
export default RememberPasswordView
