import { IState, useMainStore } from "src/settings/zustand"
import { MATRIX_MODE } from "../../data/interfaces"
import Matrix from "./matrix" 

 
const Autotraining = () : JSX.Element => {  
  const user = useMainStore((state:IState) => state.user )
  return <Matrix user={user}  mode={MATRIX_MODE.MINE} /> 
} 

export default Autotraining