import { IFabulaHero, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import StoryStuffIcon from "./StoryStuffIcon"

/* Protopia Ecosystem component */
export interface IStoryStuffsProps {

}
const StoryStuffs = (props: IStoryStuffsProps): JSX.Element => {
    const hero: IFabulaHero = useStorytellingStore( ( state: IStorytellingStore ) => state.hero )
    const story: IPEStory = useStorytellingStore( (state: IStorytellingStore) => state.current )
    
    const stuff = hero.staff?.map(stId => {
        return <StoryStuffIcon
            item = { story.staff.filter( stf => stf.id === stId)[0] }
            key  = { stId }
        />
    })
    return <div className='pe-story-stuffs-container'>
        { stuff }
    </div>
}
export default StoryStuffs