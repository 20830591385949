interface IProps {
    item: any
}
const HelpVideo = (props:IProps) : JSX.Element =>
{
    return <div className='flex-centered w-100 h-100'>
        <iframe 
            width="800" 
            height="450" 
            src={ `https://www.youtube.com/embed/${ props.item.id }?autoplay=1` }
            title={ props.item.title }  
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen>

        </iframe>
    </div>
}

export default HelpVideo