import { useEffect, useState } from "react"
import Matrix from "./matrix" 

import Man from "./matrix/Man"
import { IUser } from "@/settings/zustand"
import { actions } from "../../data/actions"
import { GET_PARTNERS_ACTION } from "../../data/actionTypes" 
import { Collapse } from "@blueprintjs/core"
import { MATRIX_MODE } from "../../data/interfaces"

const getTop = (): number => {
  return window.outerHeight < 600
    ?
    180
    :
    window.outerHeight < 1000
      ? 
      100
      :
      0
  
}
 
const ResearchOLD = () : JSX.Element => {
  const [users, setUsers] = useState<IUser[]>([])
  const [selectOrder, setSelecteOrder] = useState<number>(-1)
  const [top, setTop] = useState<number>( getTop() )
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onResizeHandler = () => {
    console.log("resize")
    setTop( getTop() )
  } 
  const onPlayerChoose = (i: number) => {
    setSelecteOrder( i === selectOrder ? -1 : i )
    setIsOpen(i !== selectOrder)  
  }
  useEffect(() => {
    actions(GET_PARTNERS_ACTION, {}).then(resp => setUsers(resp ))
    
    window.addEventListener("resize", onResizeHandler)
    return () => {
      window.removeEventListener("resize", onResizeHandler)
    }
  }, []) 
  const w : number = 60; 
  return <>
    <div 
      className=" position-absolute left overflow-hidden" 
      style={{
        bottom: 0, 
        top: "auto",
        width: 1000,
        height: 500 - top,
        //background: "red"
      }}
    >
      {
        users.map((user, i) => {
          return <div 
            key={user.id} 
            className={` man-div ${ 
              i === selectOrder 
              ? 
              "is_select" 
              : 
              selectOrder === -1 ? "" : "is_unselect" 
            } `}
            style={{ 
              left: i * 60 + 10,
              transform: selectOrder > -1 ? `scale(${ 1.2 - Math.abs( i - selectOrder)*.05  })` : "none",
              zIndex: 20 - Math.abs( i - selectOrder),
              transition: `${900 - Math.abs( i - selectOrder) * 80 }ms transform ease-out`,
              bottom: - top, 
            }}
          >
            <Man 
              fill="#15161C"
              stroke="#1E2028"
              height={250}
              className={`man untouchble ${ 
                i === selectOrder 
                ? 
                "is_select" 
                : 
                selectOrder === -1 ? "" : "is_unselect" 
              } `}
            /> 
          </div>
        })
      }
      {
        users.map((user, i) => {
          return <div 
            style={{
              position: "absolute",
              left: 53 + w * ( i ),
              wordBreak: "keep-all",
              whiteSpace: "nowrap",
              transition: `300ms all ease-out` ,
              transform: `scale(${ i === selectOrder ? 1.6 : 1 })`,
              //top: -100 - ( i === selectOrder ? 40 : 0 ),
              bottom: 290 + ( i === selectOrder ? 60 : 0 ) - top,
              opacity: i === selectOrder ? 1 : .5,
              fontSize:10,
              width: 45,
              height: 45,
              borderRadius: 100,
              backgroundSize: "cover",
              backgroundImage: `url(${ user.avatar })`
            }}
          >
            {/* {user.display_name} */}
          </div> 
        })
      }
      {
        users.map((user, i) => {
          return <div 
            key={i}
            style={{ 
              width: 55,
              zIndex: 50,
              height: 390,
              backgroundColor: "#FFFFFF00",
              bottom:  - top,
              left: w * ( i + .8 ) ,
              position: "absolute",
              cursor: "pointer"

            }} 
            onClick={ () => onPlayerChoose(i) }
          />
        })
      }
    </div> 
    <Collapse className="z-index-100 bg-dark px-3 overflow-hidden position-absolute right" isOpen={isOpen} >
      <Matrix user={ users[selectOrder] } mode={MATRIX_MODE.ENEMY} />
    </Collapse>
  </>
} 

export default ResearchOLD