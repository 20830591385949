import {__} from 'src/libs/utilities'
import IScalarForm from '../interface/IScalarForm'
import React from 'react'
import Scalar from './Scalar'
import { Tag } from '@blueprintjs/core'

const Radio = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<RadioEnabled {...props} />}
        desabledForm={<RdioDesabled {...props} />}
    />
}

const RadioEnabled =  (props: IScalarForm) :JSX.Element => {
    const items = props.values?.map(val => {
        return val._id ? val : {_id: val, title: val}
    }) || []

    const onChange = (evt: any) => {
        const { value } = evt.currentTarget
        if( props.onChange )
            props.onChange( value,  props.field, "" )
    }

    const radios: JSX.Element[] = items.map((e, i) => {
        
        const elem = typeof e._id !== "undefined" 
            ? 
            e 
            : 
            { _id: e, title: __(e), disabled: false, description : ""  }
            
        const id = `__${ props.id }_${elem._id}` 
        
        return <div className="pb-0" key={i}>
                <label className={ `${ elem.marked ? '_check_red_' : '_check_blue_' } ${ elem.disabled ? "disabled" : "" }` } htmlFor={id}>
                    <input
                        value={elem._id}
                        type="radio"
                        checked={elem._id ===  props.value}
                        onChange={ onChange }
                        onClick={ onChange }
                        disabled={elem.disabled}
                        id={id}
                    />
                    { __(elem.title) }
                </label>
                {
                    elem.commentary
                        ?
                        <div className="small text-italic mb-3 opacity_75">
                            {__(elem.commentary)}
                        </div>
                        :
                        null
                }				
            </div> 
    })
    return <div className='my-4'> 
        { radios }
    </div>
} 

export default Radio


const RdioDesabled =  (props: IScalarForm) :JSX.Element => {
    return <Tag >{ props.value._id ? props.value.title : props.value }</Tag>
}