import { useState } from "react"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { UPDATE_MEDIA_ACTION } from "src/modules/pe-fabula-module/data/actionTypes"
import { actions } from "src/modules/pe-fabula-module/data/actions"
import { IFabulaEpisode, IFabulaMedia } from "src/modules/pe-fabula-module/data/interfaces"
import { useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import TileEpisodeContent from "./TileEpisodeContent"
import TileEpisodeMenu from "./TileEpisodeMenu"
import { ILayoutStore, useLayoutStore } from "src/settings/zustand"

/* Protopia Ecosystem component */
export interface ITileEpisodeProps {
    item: IFabulaEpisode
    onClose: () => void
}
const TileEpisode = (props: ITileEpisodeProps): JSX.Element => { 
    const footerHeight: number = useLayoutStore((state: ILayoutStore) => state.footerHeight)
    const {width, height} = useScreenSize()
    const padding = 0
    const [history, setHistory] = useState<IFabulaMedia[]>( [] )
    const [current, setCurrent] = useState<IFabulaMedia>( props.item.media[0] || {} )
    const [isBack, setIsBoolean] = useState<boolean>(false)
    
    const onReturn  = () => {
        const last: IFabulaMedia = history[ history.length - 1 ]
        const _history = [...history ]
        _history.pop()
        setHistory( _history )
        setCurrent( last ? last : props.item.media[0] || {} )
        setIsBoolean( !!_history.length )
        useStorytellingStore.setState({isMenuOpen : false})
    }
    const onCurrent = ( media: IFabulaMedia, _isBack: boolean ) => { 
        if(_isBack) {
            setHistory([...history, current])
        }
        else {
            setHistory([]) 
        }
        setIsBoolean( !!_isBack )
        setCurrent( media )
        useStorytellingStore.setState({isMenuOpen : false})
    }
    const onWin = (item: IFabulaMedia, isBroken: boolean = true) => {
        if( Array.isArray(item.media) && !!item.media.length ) {
            actions(UPDATE_MEDIA_ACTION, { media : { ...item, isBroken } })
            onCurrent( {...item.media[0], isBroken: true}, true )
        } 
    }
    return <div
        style={{ 
            top:    padding,
            left:   padding,
            width:  width  - padding * 2,
            height: height - padding * 2 - footerHeight, 
        }}
        className=" pe-fabula-map-tile-container text-light " 
    >
        <TileEpisodeContent 
            item={ current } 
            onChoose={onCurrent}
            onWin={onWin}
        /> 
        <TileEpisodeMenu
            onTalk={ () => {} }
            onReturn={ () => isBack ? onReturn() : props.onClose() }
        /> 
        {/* <Button minimal className="m-2 position-absolute top right z-index-10" icon="cross" onClick={props.onClose} /> */}
    </div>
}
export default TileEpisode