import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface IMusicsEngineProps {
    audio: string | undefined
    musicVolume?: number // 0 -- 1
    musicStart?: number  //( by secs )
}
const MusicsEngine = ( props: IMusicsEngineProps ): JSX.Element => {
    const audioValue : number = useStorytellingStore((state: IStorytellingStore) => state.audioVolume)
    const music : boolean = useStorytellingStore((state: IStorytellingStore) => state.music) 
    const [ audio, ] = useState<any>( new Audio( ( props.audio || "" ).toString() ) ) 
    useEffect(() => {
        audio.volume = audioValue * (props.musicVolume || 1) * .65
        
    }, [props.musicVolume, audioValue])
    const onEnd = () => {
        audio.play() 
    }
    
    const play = () => {
        console.log( "play audio: " + props.audio )
        if(music) {
            audio.play()
            audio.currentTime  = props.musicStart || 0
        }
        else {
            audio.pause()
        }
    }
    
    useEffect(() => {  
        if(!music) {
            audio.pause()
            return
        }
        audio.addEventListener( 'ended', onEnd )
        audio.addEventListener( 'loadeddata', play )
        audio.load()
        audio.volume = audioValue * (props.musicVolume || 1) * .65;
        
        return () => {
            audio.removeEventListener( 'ended', onEnd )        
            audio.removeEventListener( 'loadeddata', play ) 
        }
    }, [ music ])

    useEffect(() => {
        audio.src = props.audio || "" 
        audio.load()
    }, [ props.audio ])

    useEffect(() => {
        return () => {
            console.log( audio.currentTime  )
            audio.pause()
        }
    }, [])

    return <></>
}
export default MusicsEngine