import { IFestival, IProject } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from "@/modules/pe-fest-module/data/store"
import CorrectHoneycombs from "../projectData/CorrectHoneycombs"
import { isRole } from "@/settings/zustand/user"
import ExtendedForms from "../projectData/ExtendedForms"
import CorrectRaiting from "../projectData/CorrectRaiting"
import { IUser } from "@/settings/zustand"
import { Role } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface IProjectMobileInfoProps {

}
const ProjectMobileInfo = (props: IProjectMobileInfoProps): JSX.Element => {
    const festival: IFestival   = useFestDataStore((state: IFestDataStor) => state.festival) || {}  
    const item: IProject        = useFestProjects( (state:IFestProjectsProps) => state.currentProject ) || {} 
    
    return <div className='pe-project-mobile-info-container'>
        <div className="flex-centered flex-column ">
        {
            item.thumbnail
                && <img 
                    src={item.thumbnail}
                    style={{ height: 160, width: "auto" }}
                    className="mx-auto"
                    alt="thumbn"
                />
        }
        </div>
        {
            item.track?.id &&  
                <div className="flex-centered flex-column text-center p-3 border-bottom-2 ">
                    <div className={` d-flex justify-content-center align-items-center text-secondary text-center descr`}> 
                        { __("Track") }  
                    </div>            
                    {
                        item.track.thumbnail
                            ?
                            <img src={item.track.thumbnail} alt="track" className='track-miniature'/>
                            :
                            null
                    }  
                    { item.track.title }
                </div> 
        } 
        {
            Array.isArray(item.fmru_destrict) && 
            !!item.fmru_destrict.length && 
                <div className="flex-centered flex-column text-center p-3 border-bottom-2 "> 
                    <div className={` d-flex justify-content-center align-items-center text-secondary text-center descr`}> 
                        { __("Destrict") }  
                    </div>
                    { item.fmru_destrict[0].title }
                </div>
        } 
        {
            Array.isArray(item.fmru_school) && 
            !!item.fmru_school.length &&         
                <div className="flex-centered flex-column text-center p-3 border-bottom-2 "> 
                    <div className={` d-flex justify-content-center align-items-center text-secondary text-center descr`}> 
                        { __("Education organization") }  
                    </div>
                    { item.fmru_school[0].title }
                </div>
        } 
        <div className="flex-centered flex-column text-center p-3 border-bottom-2 ">
            <div className={` d-flex justify-content-center align-items-center text-secondary text-center descr`}> 
                { __("Honeycomb") }  
            </div>     
            <CorrectHoneycombs
                honeycombs={ item.honeycombs  || {} } 
                itemId={item.id} 
            />  
        </div>         
        {
            ( festival.availableRoles || [] ).filter( role => role === "Tutor" ).length > 0
                && isRole( [ "Tutor" , "Track_moderator", "administrator" ] )
                && item.tutor?.id 
                &&
                <div className="flex-centered flex-column text-center p-3 border-bottom-2 ">
                    <div className={` d-flex justify-content-center align-items-center text-secondary text-center  descr`}> 
                        { __("Tutor") }  
                    </div>
                    { [item.tutor.display_name, item.tutor.user_email].join(" | ")  }
                </div> 
                
        }         
          
        {
            ( festival?.availableRoles || [] ).filter( (role: Role) => role === "Project_author" ).length > 0 &&
                isRole( [ "Project_author" , "Track_moderator", "administrator" ] ) &&
                item.leader?.id  
                &&
                <div className="flex-centered flex-column text-center p-3 border-bottom-2 ">
                    <div className={` d-flex justify-content-center align-items-center text-secondary text-center descr`}> 
                        { __("Project_author") }  
                    </div>
                    { [item.leader.display_name, item.leader.user_email].join(" | ")}
                </div>
        }  
        
        <ExtendedForms 
            item={ item }  
            hidden="hide_in_page" 
            isCollapseOpen={true}
        />
        <div className="flex-centered flex-column text-center p-3 border-bottom-2 ">
            <div className={` d-flex justify-content-center align-items-center text-secondary text-center descr`}> 
                { __("Valuations:") }  
            </div>
            <CorrectRaiting
                itemId={item.id} 
                countedRating={ item.actualRating }
                correctMemberRate={ item.correctMemberRate }  
            />
            <div className="descr text-primary">
                { item.correctMemberRate?.description }
            </div>
        </div>   
        <div className="flex-centered flex-column text-center p-3 border-bottom-2 ">  
            <div className={` d-flex justify-content-center align-items-center text-secondary text-center descr`}> 
                { __("Experts, who put raiting:") }  
            </div>
            <strong>
                { item.experts.map((e: IUser) => e.display_name).join(", ")}
            </strong>     
        </div>
    </div>
}
export default ProjectMobileInfo