import gql from "graphql-tag";
import Layouts from "..";
import { ID } from "src/libs/interfaces/layouts";
const ls = {
  name: "ecosystem",
  link_type: "http"
};
export default ls;
export function getAllDataTypes() {
  return Layouts().schema;
}
export function getQueryArgs(data_type: string) {
  const {
    apollo_fields
  } = Layouts().schema[data_type];

  if (!apollo_fields) {
    return "error Layouts().json";
  }

  const fl = [];

  for (const fld in apollo_fields) {
    const field = apollo_fields[fld];

    switch (field.kind) {
      case "type":
        // console.log(field);
        // console.log(field.apollo_fields);
        const subfields: any = {};

        for (const k in Layouts().schema[field.component].apollo_fields) {
          const e = Layouts().schema[field.component].apollo_fields[k];

          if (e.kind !== "type") {
            subfields[k] = e;
          }
        }

        const fll = Object.keys(subfields);
        fl.push(`${fld} { ${fll.join(" ")} } `);
        break;

      case "scalar":
      default:
        fl.push(fld);
        break;
    }
  }

  return fl.join(" ");
}
export function getQueryName(data_type: string) {
  if (data_type.slice(-1) === "y") {
    return `get${data_type.slice(0, data_type.length - 1)}ies`;
  }

  return `get${data_type}s`;
}
export function querySingleName(data_type: string) {
  return `get${data_type}`;
} // data_type ? data_query : "get" + data_type + "s";

export function queryCollection(data_type: string, query_name: string, query_args: any, filter_data_types: string) {
  const fields = query_args;
  const query = `query ${query_name} { ${query_name} { ${fields} } }`;
  return gql`${query}`;
}
export function queryCollectionFilter(data_type: string, query_name: string, query_args: any, filter = false) {
  const fields = query_args;
  const paging1 = filter ? "($paging: Paging)" : "";
  const paging2 = filter ? "(paging: $paging)" : "";
  const query = `query ${query_name}${paging1} { ${query_name}${paging2} { ${fields} } }`;
  return gql`${query}`;
}
export function querySingle(data_type: string, query_name: string, query_args: any, id: ID) {
  const fields = query_args;
  const query = `query ${query_name} { ${query_name}(id: "${id}") { ${fields} } }`;
  return gql`${query}`;
}
export function plural(data_type: string): string {
  return Layouts().schema[data_type].plural;
} 
export function singleName(data_type: string): string {
  return Layouts().schema[data_type].name;
}
export function getMutationName(data_type: string) {
  return `change${data_type}`;
}
export function getInputTypeName(data_type: string) {
  return `${data_type}Input`;
}
export function mutationAdd(data_type: string, mutation_name: string, input_type_name: string, mutation_args: any) {
  const fields = mutation_args;
  const mutation_change = `mutation ${mutation_name}($id:ID $input:${input_type_name}){${mutation_name}(_id: $id, input: $input){ ${fields} }}`;
  return gql`${mutation_change}`;
}
export function mutationEdit(data_type: string, mutation_name: string, input_type_name: string, mutation_args: any) {
  const fields = mutation_args;
  const mutation_change = `mutation ${mutation_name}($id:ID $input:${input_type_name}){${mutation_name}(_id: $id, input: $input){ ${fields} }}`;
  return gql`${mutation_change}`;
}
export function mutationDelete(data_type: string) {
  const mutation_change = `mutation delete${data_type}($id:ID!){ delete${data_type}(id: $id)}`;
  return gql`${mutation_change}`;
} // login

export function mutationAvtorize() {
  const mutation_change = "mutation authorize($input: AuthorizeInput){ authorize(input: $input){" + "auth_req_id" + "}}";
  return gql`${mutation_change}`;
}
export function mutationToken() {
  return token();
}
export function token() {
  const mutation_change = "mutation token($input: TokenInput!){ token(input: $input){" + "        access_token\n" + "        token_type\n" + "        expires_in\n" + "        refresh_token" + "}}";
  return gql`${mutation_change}`;
}
export function externalToken() {
  const mutation_change = "mutation token($input: TokenInput){ token(input: $input){" + "        access_token\n" + "        token_type\n" + "        expires_in\n" + "        refresh_token" + "}}";
  return gql`${mutation_change}`;
}
export function queryUserInfo() {
  const query_args = getQueryArgs("User");
  const fields = query_args;
  const mutation_change = `query  userInfo{  userInfo{ ${fields} } }`;
  return gql`${mutation_change}`;
}
export function onSaveGql(state: any, _id: ID) {
  if (state._id) {
    delete state._id;
    delete state.__typename;
  }

  const fl = [];
  const {
    apollo_fields
  } = Layouts().schema[state.data_type];

  for (const el in state) {
    let dating;
    let tt;

    switch (apollo_fields[el].type) {
      case "array":
        switch (apollo_fields[el].component) {
          case "date":
          case "boolean":
          case "integer":
          case "float":
            tt = [];

            for (const t in state[el]) {
              tt.push(state[el]);
            }

            dating = `[${tt.join(", ")}]`;
            break;

          case "string":
          default:
            tt = [];

            for (const t in state[el]) {
              tt.push(`'${state[el]}'`);
            }

            dating = `[${tt.join(", ")}]`;
            break;
        }

        break;

      case "checkbox":
        tt = [];

        for (const t in state[el]) {
          tt.push(`'${state[el]}'`);
        }

        dating = `[${tt.join(", ")}]`;
        break;

      case "geo":
        dating = `[${state[el][0]}, ${state[el][1]}]`;
        break;

      case "date":
      case "boolean":
      case "integer":
      case "float":
        dating = state[el];
        break;

      case "email":
      case "phone":
      case "string":
      default:
        dating = `'${state[el]}'`;
        break;
    }

    fl.push(`${el}:${dating}`);
  }

  console.log(fl);
}
export function getQueryExternalFields(component: any, external_fields: []) {
  const aq = getQueryName(component);
  const fl = external_fields.join(" ");
  const query = `query ${aq} { ${aq} { ${fl} } }`;
  return gql`${query}`;
}
export function getInput(state: any, data_type: string) {
  delete state.current;
  delete state.height;
  delete state.isOpen;
  delete state.allChecked;
  delete state.checked; // Удаляем не-редактируемые поля, которые всё равно запретит сервер

  const {
    apollo_fields
  } = Layouts().schema[data_type];

  for (const i in apollo_fields) {
    if (!apollo_fields[i].editable) delete state[i];
  }

  return state;
}
export function mutationEditName(data_type: string) {
  return `change${data_type}`;
}
export function apolloFields(data_type: string) {
  return Layouts().schema[data_type].apollo_fields;
}
export function getFields(data_type: string) {
  let dataType = "";

  for (const i in Layouts().schema) {
    if (i === data_type) {
      dataType = i;
      break;
    }
  }

  const {
    apollo_fields
  } = Layouts().schema[dataType];

  if (dataType) {
    return apollo_fields;
  }

  return {
    _id: "string",
    title: {
      type: "string"
    }
  };
}
export function getVisibleValue(type: string) {
  const {
    schema
  } = Layouts();
  const visibled_value = type ? schema[type] && schema[type].visibled_value ? schema[type].visibled_value : "title" : "title";
  return visibled_value;
}
export function getIdName() {
  return "_id";
}
export function getExternalPostfix() {
  return "_id";
}
export function getArrayPostfix() {
  return "_ids";
}