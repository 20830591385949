
import { Intent } from "@blueprintjs/core"
import { AppToaster } from "src/libs/useful"
import { 
    GET_FEED_ACTION,
    GET_FEED_COUNT_ACTION,
    MUTATION_TOKEN_ACTION, 
    RESET_PASSWORD_ACTION, 
    RESTORE_FINISH_PASSWORD_ACCESS_ACTION, 
    RESTORE_FINISH_PASSWORD_ACTION, 
    RESTORE_PASSWORD_ACTION, 
    SEARCH_ACTION, 
    UPDATE_CURRENT_USER, 
    USER_INFO_ACTION, 
    VERIFY_USER_ACTION, 
    GET_SINGLE_ACTIONS,
    SWITCH_THEME_ACTION,
    GET_THEME_ACTION,
    UPDATE_SINGLE_TYPE, 
    DELETE_SINGLE_TYPE,
    DELETE_BULK_ACTION,
    UPDATE_BULK_ACTION,
    GET_POST_LIKES_ACTION,
    MUTATION_EXTERNAL_TOKEN_ACTION,
    GET_SEARCH_USERS_ACTION,
    GET_ALL_ROLES_ACTION,
    GET_SERVICE_OPTIONS_ACTION,
    CHANGE_SERVAICE_OPTIONS_ACTION,
    CREATE_SITE_ACTION,
    DELETE_SITE_ACTION,
    GET_DICTIONARY_ACTION,
    LOGOUT_ACTION
} from "./actionTypes"
import asyncControllers from "./controllers"
import { __, initDictionary, sprintf } from "src/libs/utilities"
import { IUser, THEME_NAMES, useMainStore } from "src/settings/zustand"
import { FINISH_RESTORE_STATUSES } from '../views/profile/FinishRestorePasswordForm'
import { getChangeName, getQueryName, querySingleName } from "src/libs/layouts"
import { useDataStore } from "./store"
import { IDictionary } from "@/libs/interfaces/layouts"

const actions = (action: string, data: any): Promise<any> =>
{  
    // console.log(action, data)
    let promise: Promise<any> = new Promise<any>( (resolve, reject) => {
        resolve({})
        reject({})
    })
    switch(action)
    {  
        case GET_ALL_ROLES_ACTION:
            promise = new Promise<any>((resolve, reject) => { 
                asyncControllers( GET_ALL_ROLES_ACTION, data ).then(                    
                    (response:any) => { 
                        resolve( response.data.getAllRoles )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case GET_DICTIONARY_ACTION:
            promise = new Promise<any>((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response:any) => { 
                        try {
                            const dictionary: IDictionary = JSON.parse(response.data.getDictionary.dictionary)
                            // console.log( dictionary ) 
                            useMainStore.setState({ dictionary })
                            initDictionary ('ru_RU', {})
                        }
                        catch(error: any) {
                            console.log( error );
                        }
                        finally {
                            resolve( response?.data?.changeOptions )
                        } 
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case GET_SERVICE_OPTIONS_ACTION:
            promise = new Promise<any>((resolve, reject) => { 
                asyncControllers( GET_SERVICE_OPTIONS_ACTION, data ).then(                    
                    (response:any) => { 
                        resolve( response.data.getServiceOptions )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case CHANGE_SERVAICE_OPTIONS_ACTION:
            promise = new Promise<any>((resolve, reject) => { 
                asyncControllers( CHANGE_SERVAICE_OPTIONS_ACTION, data ).then(                    
                    (response:any) => { 
                        resolve( response.data.changeServiceOptions )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case LOGOUT_ACTION:
            promise = new Promise<any>(() => { 
                asyncControllers( LOGOUT_ACTION, data )
                useMainStore.getState().setUser({})
		        localStorage.removeItem("token")
            })
            return promise
        case USER_INFO_ACTION: 
            promise = new Promise<any>((resolve, reject) => { 
                asyncControllers( USER_INFO_ACTION, data ).then(                    
                    (response:any) => { 
                        const user: IUser = response.data.userInfo 
                        useMainStore.getState().setUser( user ) 
                        resolve( user )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case UPDATE_CURRENT_USER:
            promise = new Promise<any>((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {                        
                        // console.log( response, data )
                        asyncControllers(USER_INFO_ACTION, {})
                            .then( (response2: any) => {
                                const user: IUser = response2.data.userInfo
                                useMainStore.getState().setUser( user )
                                resolve( user )
                            })
                        resolve( response.data.changeCurrentUser )
                    }, 
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case MUTATION_TOKEN_ACTION: 
            promise = new Promise<any>( (resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        if(response.data?.token) { 
                            localStorage.setItem(
                                "token",
                                response.data.token.access_token,
                            ) 
                            setTimeout(() => {    
                                asyncControllers(USER_INFO_ACTION, {})
                                    .then( (response2: any) => { 
                                        // console.log( response2 )
                                        const user: IUser = response2.data.userInfo
                                        AppToaster.show({
                                            intent: Intent.SUCCESS,
                                            icon: "tick",
                                            message: sprintf( 
                                                __( "You enter by %s" ), 
                                                user.display_name || __("User") 
                                            ),
                                        })
                                        useMainStore.getState().setUser( user )
                                        resolve(user)
                                        //window.location.reload()
                                    })
                                    .catch((error: any) => {
                                        console.log( error )
                                        AppToaster.show({
                                            intent: Intent.DANGER,
                                            icon: "tick",
                                            message: error.message,
                                        })
                                    })
                            }, 100 )    
                        }
                    },
                    (error: any) => {
                        console.log( error )
                        AppToaster.show({
                            intent: Intent.DANGER,
                            icon: "tick",
                            message: __("You not enter by User"),
                        })
                        reject(error)
                    }
                ) 
            })
            return promise
        case MUTATION_EXTERNAL_TOKEN_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        // console.log( response )
                        if(response.data.external_token) { 
                            localStorage.setItem(
                                "token",
                                response.data.external_token.access_token,
                            ) 
                            asyncControllers(USER_INFO_ACTION, {})
                                .then( (response2: any) => { 
                                    const user: IUser = response2.data.userInfo
                                    AppToaster.show({
                                        intent: Intent.SUCCESS,
                                        icon: "tick",
                                        message: sprintf( 
                                            __( "You enter by %s" ), 
                                            user.display_name || __("User") 
                                        ),
                                    })
                                    useMainStore.getState().setUser( user )
                                    resolve(user)
                                    //window.location.reload()
                                })
                                .catch(error => {
                                    AppToaster.show({
                                        intent: Intent.DANGER,
                                        icon: "tick",
                                        message: error.message,
                                    })
                                })
                        }  
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER,
                            icon: "tick",
                            message: __("You not enter by User"),
                        })
                        reject(error)
                    }
                )
            })
            return promise
        case RESTORE_PASSWORD_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        console.log( response )
                        resolve( response.data.restorePass )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case RESTORE_FINISH_PASSWORD_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any)  => {
                        if( response.data.compareRestore )
                        {
                            asyncControllers( RESTORE_FINISH_PASSWORD_ACCESS_ACTION, data ).then(  
                                (response1: any) => {
                                    resolve( response1.data.compareRestore ? FINISH_RESTORE_STATUSES.SUCCESS : FINISH_RESTORE_STATUSES.NOT_SUCCESS )
                                })
                        }
                        else {
                            resolve(FINISH_RESTORE_STATUSES.NOT_FOUND)
                        }
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case RESET_PASSWORD_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        resolve( response.data.restorePass )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case VERIFY_USER_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        resolve( response.data.verifyUser ? FINISH_RESTORE_STATUSES.SUCCESS : FINISH_RESTORE_STATUSES.NOT_SUCCESS )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case SEARCH_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        resolve( response.data.getPosts )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case CREATE_SITE_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        resolve( response?.data?.registerLand )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case DELETE_SITE_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => {
                        resolve( response?.data?.deleteLand )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise
        case GET_FEED_ACTION:           
            promise = new Promise((resolve, reject) => { 
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        const name: string = getQueryName(data.data_type) 
                        const seed: string = data.seed || ""
                        const nameCount: string = querySingleName(data.data_type) 
                        if(!!data && !!data.data_type && !!response && !!response.data) { 
                            // console.log(data, data.data_type) 
                            useDataStore.setState({
                                [ data?.data_type + seed ]: response.data[ name ] 
                            })
                            asyncControllers( GET_FEED_COUNT_ACTION, data ).then( (r: any) => {
                                resolve( {feed: response.data[name], count: r.data[`${nameCount}Count`] })
                            })
                        }                        
                    },
                    (error: any) => {
                        console.log(error)
                        reject( error )
                    }
                )
            }) 
            return promise
        case GET_POST_LIKES_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        resolve( response.data.getLikes )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise 
        case GET_SINGLE_ACTIONS:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    (response : any) => { 
                        const query_name: string = querySingleName( data.data_type )
                        // console.log(query_name, response) 
                        resolve( response.data[ query_name ] )
                    },
                    (error: any) => {
                        AppToaster.show({
                            intent: Intent.DANGER, 
                            message: __("Unknown error")
                        })
                        reject( error )
                    }
                )
            })
            return promise 
        case UPDATE_SINGLE_TYPE:
                promise = new Promise((resolve, reject) => { 
                    asyncControllers(action, data).then( 
                        (response : any) => {
                            // console.log( response.data )
                            // console.log( getChangeName( data.data_type ) )
                            resolve( response.data[ getChangeName( data.data_type ) ] )
                        },
                        (error: any) => reject( error )
                    )
                })
                return promise 
        case UPDATE_BULK_ACTION:
                promise = new Promise((resolve, reject) => {  
                    asyncControllers(action, data).then( 
                        (response : any) => { 
                            resolve( response.data[ getChangeName( data.data_type ) ] )
                        },
                        (error: any) => reject( error )
                    )
                })
                return promise 
        case DELETE_SINGLE_TYPE:
                promise = new Promise((resolve, reject) => { 
                    asyncControllers(action, data).then( 
                        (response : any) => { 
                            resolve( response.data[ 'deletePEFestProject' ] )
                        },
                        (error: any) => reject( error )
                    )
                })
                return promise 
        case DELETE_BULK_ACTION:
                promise = new Promise((resolve, reject) => { 
                    asyncControllers(action, data).then( 
                        (response : any) => { 
                            resolve( response.data[ `deleteBulk${data.data_type}` ] )
                        },
                        (error: any) => reject( error )
                    )
                })
                return promise 
        case GET_SEARCH_USERS_ACTION:
            promise = new Promise((resolve, reject) => { 
                asyncControllers(action, data).then( 
                    (response : any) => { 
                        resolve( response.data.getUsers )
                    },
                    (error: any) => reject( error )
                )
            })
            return promise 
        case GET_THEME_ACTION:
            return new Promise(( resolve ) => {
                const setTheme: any = useMainStore.getState().setTheme
                const newTheme: string =  localStorage.getItem("pe-theme") || ''
                setTheme( newTheme )
                resolve ( newTheme )
            })
        case SWITCH_THEME_ACTION:
            const setTheme: any = useMainStore.getState().setTheme
            promise = new Promise(( resolve ) => {
                const newTheme: string = data.theme === THEME_NAMES.NIGHT ? THEME_NAMES.DAY : THEME_NAMES.NIGHT
                localStorage.setItem( "pe-theme", newTheme )
                resolve( setTheme( newTheme ) )
            })
            return promise    
    }
    return new Promise( resolve => resolve(true) )
}
export default actions