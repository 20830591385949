import React from "react"  
import BasicState from "@/libs/basic-view"
import { __ } from "@/libs/utilities"

interface IHTMLSourceVewProps {
	html_source: URL
	className: string
}

class HTMLSourceVew extends BasicState {
	props: any 
	getRoute = ( ) : any => {
		console.log( this.props.html_source )
		fetch( this.props.html_source )
			.then(response => response.text())
			.then(text => {			  
				  const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
				  while (SCRIPT_REGEX.test(text)) {
				    text = text.replace(SCRIPT_REGEX, "")
				  }
				  this.setState({ html: <div className={`${ this.props.className }`} dangerouslySetInnerHTML={{ __html: text }} /> })
				},
				(error: any)=> {
					console.error(error)
				})
	  		return
	}
}
export default HTMLSourceVew
