import { Position, Tooltip } from "@blueprintjs/core"
import { STAFF_EXECUTE_ACTION } from "src/modules/pe-fabula-module/data/actionTypes"
import { actions } from "src/modules/pe-fabula-module/data/actions"
import { FABULA_STAFF_ACTION, IFabulaStaff } from "src/modules/pe-fabula-module/data/interfaces"
import { AppToaster } from "src/libs/useful"

/* Protopia Ecosystem component */
export interface ITileEpisodeStuffLabelProps {
    i: number
    item: IFabulaStaff
}
const TileEpisodeStuffLabel = ({i, item, ...props}: ITileEpisodeStuffLabelProps): JSX.Element => {
    const onClick = () => {
        console.log("click", item )
        switch(item.action) {
            case FABULA_STAFF_ACTION.CHARGE: 
            case FABULA_STAFF_ACTION.TAKE: 
            default:
                actions(
                    STAFF_EXECUTE_ACTION,
                    {
                        action: item.action,
                        item  
                    }
                )
                .then((res: any) => {
                    AppToaster.show({
                        message: res.message || "The staff executed by unknown method"

                    }) 
                })
        }
    }
    return <div 
        className='pe-fabula-staff-label-cont'
        style={{
            top:  `${item.y}%`,
            left: `${item.x}%`
        }} 
    > 
        <Tooltip
            position={Position.TOP}
            content={item.title}
        >
            <div className='pe-fabula-staff-label' onClick={onClick}/>
        </Tooltip> 
    </div>
    
}
export default TileEpisodeStuffLabel