import { CARD_TYPE } from "./interfaces";
import SimpleStrokeProject from '../views/projectList/SimpleStrokeProject'
import StrokeProject from '../views/projectList/StrokeProject'
import TwoSizeProject from '../views/projectList/TwoSizeProject'
import BoxProject from '../views/projectList/BoxProject'
import CardProject from '../views/projectList/CardProject' 
import LineProject from "../views/projectList/LineProject";

export const cardTypes = () => ([
    {
        _id: CARD_TYPE.TWO_SIZE_CARD,
        type: CARD_TYPE.TWO_SIZE_CARD,
        title: "Two sides card",
        icon: 'fas fa-clone',
        description: 'Choose this design if Projects have image icons and many meaningful additional fields',
        card: TwoSizeProject,
        width: 100,
        height:180,
        img: "/assets/img/fest/card_types_two_sides.svg",
        icon_opacity:1,
    },
    {
        _id:CARD_TYPE.CARD,
        type:CARD_TYPE.CARD,
        title: "Card",
        icon: 'fas fa-th-large',
        description: 'Medium duty design. You want projects to be smartly presented but compact enough',
        card: CardProject,
        width: 100,
        height:180,
        img: "/assets/img/fest/card_types_card.svg",
        icon_opacity:1,
    },
    {
        _id: CARD_TYPE.STROKE,
        type: CARD_TYPE.STROKE,
        title: "Stroke",
        icon: 'fas fa-list',
        description: 'Compact and informational design',
        card: StrokeProject,
        width: 100,
        height:180,
        img: "/assets/img/fest/card_types_stroke.svg",
        icon_opacity:1,
    },
    {
        _id: CARD_TYPE.SIPLE_STROKE,
        type: CARD_TYPE.SIPLE_STROKE,
        title: "Simple stroke",
        icon: 'fas fa-align-justify',
        description: 'Only title stroke',
        card: SimpleStrokeProject,
        width: 100,
        height:180,
        img: "/assets/img/fest/card_types_simple_stroke.svg",
        icon_opacity:1,
    },
    {
        _id: CARD_TYPE.ONLY_LINE,
        type: CARD_TYPE.ONLY_LINE,
        title: "Only line",
        icon: "fas fa-grip-lines",
        description: 'Only title stroke',
        card: LineProject,
        width: 100,
        height:180,
        img: "/assets/img/fest/card_types_simple_stroke.svg",
        icon_opacity:1,
    },
    {
        _id: CARD_TYPE.BOX,
        type: CARD_TYPE.BOX,
        title: "Blind box",
        icon: 'fas fa-expand',
        description: 'You need maximum anonymity of projects so that experts and viewers have minimal attachment',
        card: BoxProject,
        width: 100,
        height: 180,
        img: "/assets/img/fest/card_types_icons_box.svg",
        icon_opacity:1,
    }
])