import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import RussionRegionList from "../../data/RussianRegionList"
import { Json } from "@/libs/interfaces/layouts"

const ExtendFieldSingleForm = (props: any) : JSX.Element => {
    const onChange = (value: any, i:number) => {
        if( props.onChange){
            props.onChange( value, i )
        }
    }
    return (
        <div className="w-100 my-2"> 
            {
                Array(props.matrix.count).fill(0)
                    .map( ( _boo: number , i: number) => {
                        return <Field
                            key={ i }
                            { ...props }
                            i={i}
                            onChange={(value:any) => onChange(value, i)}
                        />
                    })
            }
            <div className="small my-1 pl-2">{ props.matrix.description }</div>
        </div>
    )
} 

export default ExtendFieldSingleForm

const Field = (props:any) : JSX.Element => {
    const onChange = (value: any, field: any, name: string) => {
        console.log( value )
        if( props.onChange){
            props.onChange( value, field )
        }
    }
    switch(props.matrix.type) {
        case SCALAR_TYPES.SELECT: 
        case "RussianRegions":
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.SELECT }
                value={ props.item ? props.item[props.i] : "" }
                values={ RussionRegionList().map(r => ({_id: r, title: r})) }
                onChange={ onChange } 
            />
        case SCALAR_TYPES.TAGS: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.TAGS }
                value={ props.item ? props.item[props.i] : "" }
                values={ props.matrix.values.split("/") || [] }
                onChange={ onChange } 
            />
        case SCALAR_TYPES.NUMBER: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.NUMBER }
                value={ props.item ? props.item[props.i] : "" } 
                onChange={ onChange } 
            />
        case SCALAR_TYPES.COLOR: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.COLOR }
                value={ props.item ? props.item[props.i] : "" } 
                onChange={ onChange } 
            />
        case SCALAR_TYPES.CHECKBOX: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.CHECKBOX }
                value={ props.item ? props.item[props.i] : "" } 
                values={ props.matrix.values.split("/") || [] }
                onChange={ onChange } 
            />
        case SCALAR_TYPES.RADIO: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.RADIO }
                value={ props.item ? props.item[props.i] : "" } 
                values={ props.matrix.values.split("/") || [] }
                onChange={ onChange } 
            />
        case SCALAR_TYPES.GEO: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.GEO }
                value={ props.item ? jsonToData( props.item[props.i] ) : "" } 
                onChange={ (value: any, field: any, name: string) => onChange( JSON.stringify(value), field, name ) } 
            />
        case SCALAR_TYPES.BOOLEAN: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.BOOLEAN }
                value={ props.item && props.item[props.i] }  
                onChange={ onChange } 
            />
        case SCALAR_TYPES.UPLOAD: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.UPLOAD }
                value={ props.item ? props.item[props.i] : "" } 
                limitSize={200000}
                onChange={ onChange } 
            />
        case SCALAR_TYPES.MEDIA: 
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.MEDIA }
                value={ props.item ? props.item[props.i] : "" } 
                limitSize={400000}
                onChange={ onChange } 
            />
        case SCALAR_TYPES.STRING:
        default:
            return <FieldInput 
                editable
                type={ SCALAR_TYPES.STRING }
                value={ props.item ? props.item[props.i] : "" }
                onChange={ onChange } 
            />
    }
    
}

export const jsonToData = (json: Json) : any => {
    try {
        const data: any = JSON.parse(json)
        //console.log( data )
        return data
    }
    catch(e: any) {
        console.log( e )
        return ""
    }
}