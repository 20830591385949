import { useEffect, useState } from "react"
import { Loading } from "src/libs/useful"
import { IGoods } from "../../data/interfaces"
import { goods } from "../../data/mocks/goods"
import GoodsCard from "./GoodsCard"
import useEvent from "../../hooks/useEvent"

const ToposStoreForm = (props: any) : any => {
  useEvent("-1")
  const [items, setItems] = useState<IGoods[]>([] ) 
  const [isLoading, setIsLoading] = useState<boolean>( true )

  useEffect(() => {
    setItems( goods() )
    setIsLoading( false )
  }, [])

  if(isLoading) return <div className="layout-state flex-centered"><Loading /></div>

  const list: JSX.Element[] =  items.map( item => <div className=" col-12 col-md-6 col-slg-4 col-glg-3 my-3" key={item.id}>
    <GoodsCard item={item} className=" "/>
  </div> )

  return <div className="container-fluid">
    <div className="row">
      <div className="col-12 col-md-8 col-xxl-8 col-slg-8 col-glg-9 pl-4">
        <div className="container">
          <div className="row">
            {list}
          </div>
        </div> 
      </div>
      <div className="col-12 col-md-4 col-xxl-4 col-slg-4 col-glg-3">
        
      </div>
    </div>
  </div>

}

export default ToposStoreForm