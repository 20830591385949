import { Button, ButtonGroup, Collapse, Intent, Tab, Tabs } from "@blueprintjs/core"
import { useState } from "react"
import { IUser } from "src/settings/zustand"
import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { __ } from "src/libs/utilities"
import { IPEResourceType } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface ITabOperationsProps {
    count: number
    resource: IPEResourceType
}
const TabOperations = (props: ITabOperationsProps): JSX.Element => {
    
    const [writingOffCount, setWritingOffCount] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [isAuto, setIsAuto] = useState<boolean>(false)
    const [purpose, setPurpose] = useState<string>("")
    const [tab, setTab] = useState<string>("main")
    const [recipient, setRecipient] = useState<IUser>({ } as IUser )
    const onTabs = (_tab: string) => {
        setTab(_tab)
    }
    return <div className="pe-resources-operations px-2"> 
        {
            props.resource.is_transfered && 
            <>
                <Button 
                    minimal={tab !== "transfer"}
                    fill 
                    onClick={() => onTabs("transfer")} 
                    intent={tab === "transfer" ? Intent.DANGER : Intent.NONE}
                >
                    <div className="display-7 offset-md-3 w-100">
                        {__("Transfer")}
                    </div>
                </Button>
                <Collapse isOpen={tab === "transfer"} transitionDuration={2000} className="w-100 mx-1" >
                    <TabTransfer
                        count={count} setCount={setCount}
                        max={props.count}
                        isAuto={isAuto} setIsAuto={setIsAuto}
                        purpose={purpose} setPurpose={setPurpose}
                        recipient={recipient} setRecipient={setRecipient}
                    />
                </Collapse> 
            </>
        }
        {
            props.resource.is_writing_off && 
            <>
                <Button 
                    minimal={tab !== "writingOff"} 
                    fill 
                    onClick={() => onTabs("writingOff")}
                    intent={tab === "writingOff" ? Intent.DANGER : Intent.NONE}
                >
                    <div className="display-7 offset-md-3 w-100" >
                        {__("Writing off")}
                    </div>
                </Button>  
                <Collapse isOpen={tab === "writingOff"} transitionDuration={2000} className="w-100 mx-1" > 
                    <TabWritingOff
                        writingOffCount={writingOffCount}
                        setWritingOffCount={setWritingOffCount}
                        max={props.count}
                    />        
                </Collapse>
            </>
        } 
    </div>
}
export default TabOperations


/* Protopia Ecosystem component */
export interface ITabTransferProps {
    max: number
    count: number
    setCount: (value: number) => void
    isAuto: boolean
    setIsAuto: (value: boolean) => void
    purpose: string
    setPurpose: (value: string) => void
    recipient: IUser
    setRecipient: (value: IUser) => void
}
const TabTransfer = (props: ITabTransferProps): JSX.Element => {
    return <div className='p-4 w-100 border border-secondary'> 
    <FieldInput
        title={__("Transfer size")} 
        value={props.count}
        onChange={(value) => props.setCount(value)}
        type={SCALAR_TYPES.SLIDER}
        editable
        min={0}
        max={props.max}
        stepSize={1}
        labelStepSize={ Math.floor( props.max / 5 ) }
    />
    <FieldInput
        title={__("Auto payment")} 
        description={__("Send specified amount every game circle")}
        value={props.isAuto}
        onChange={(value) => props.setIsAuto(value)}
        type={SCALAR_TYPES.BOOLEAN}
        editable 
    />
    <FieldInput
        title={__("Purpose of payment")}
        value={props.purpose}
        onChange={(value) => props.setPurpose(value)}
        type={SCALAR_TYPES.STRING}
        editable 
    />
    <FieldInput
        title={__("Recipient")} 
        value={props.recipient}
        onChange={(value) => props.setRecipient(value)}
        type={ SCALAR_TYPES.EXTERNAL }
        component="User"
        editable 
    />
    <Button 
        intent={!!props.count && !!props.recipient.id ? Intent.SUCCESS : Intent.NONE} 
        large
        disabled={ !props.count || !props.recipient.id}
        rightIcon="cargo-ship"
        className=" offset-md-3"
    >
        {__("Start transfer")}
    </Button>
   
    </div>
} 


/* Protopia Ecosystem component */
export interface ITabWritingOffProps {
    writingOffCount: number
    setWritingOffCount: (value: number) => void
    max: number
}
const TabWritingOff = (props: ITabWritingOffProps): JSX.Element => {
    return <div className='p-4 w-100 border border-secondary'> 
        <FieldInput
            title={__("Writing off size")} 
            value={props.writingOffCount}
            onChange={(value) => props.setWritingOffCount(value)}
            type={SCALAR_TYPES.SLIDER}
            editable
            min={0}
            max={props.max}
            stepSize={1}
            labelStepSize={ Math.floor( props.max / 5 ) }
        />
        <Button 
            intent={!!props.writingOffCount ? Intent.DANGER : Intent.NONE} 
            large 
            disabled={ !props.writingOffCount }
            rightIcon="trash"
            className=" offset-md-3"
        >
            {__("Start writing off")}
        </Button>
    </div>
} 