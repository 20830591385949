import { __ } from "src/libs/utilities"
import { Tooltip } from "@blueprintjs/core"
import { IFabulaHero, IPEFabulaCharactery, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"

/* Protopia Ecosystem component */
export interface IHeroCharacterMenuProps {

}
const HeroCharacterMenu = (props: IHeroCharacterMenuProps): JSX.Element => {
    const story: IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    
    return <div className='pe-hero-character-menu-container'>
    {
        story.characteries?.map(char => {
            return <Picon item={char} key={char.id} />
        })
    } 
    </div>
}
export default HeroCharacterMenu

interface IPiconProps {
    item: IPEFabulaCharactery
}
const Picon = ({ item, ...props } : IPiconProps ) => {
    const hero: IFabulaHero = useStorytellingStore((state: IStorytellingStore) => state.hero)
    return <Tooltip
        content={<div className="px-3 py-1">{__(item.title)}</div>}
    >
        <div 
            className="picon" 
            data-icon-type={ item.id }
            style={{
                backgroundColor: item.color,
                backgroundImage: `url( "/assets/img/fabula/${ item.id }-icon.svg" )`
            }}
        >
            <div className="picon-value">
                { hero[ item.id ] }
            </div>            
        </div>
    </Tooltip> 
}