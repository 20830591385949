import { Button, Popover } from "@blueprintjs/core"
import EmojiPicker, { EmojiClickData, EmojiStyle, Theme } from 'emoji-picker-react';

interface IProps {
    onChoose: ( emoji: EmojiClickData ) => void
}
const EmojiButton = (props: IProps) :JSX.Element => {
    const on = (data: any) => {
        console.log(data)
        props.onChoose(data)
    }
    return <Popover 
        content={
            <EmojiPicker 
                theme={Theme.DARK}
                onEmojiClick={on}
                width={400} 
                autoFocusSearch={false}
                emojiStyle={EmojiStyle.NATIVE}
            /> 
        }
    >
        <Button minimal>
            <i className="far fa-laugh fa-2x"></i>
        </Button>
    </Popover>
    
} 

export default EmojiButton