import { isRole } from "@/settings/zustand/user"
import { __ } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"

const { useSwiper } =  require("swiper/react")  

/* Protopia Ecosystem component */
export interface IDescrButtonSwipeProps {

}
const DescrButtonSwipe = (props: IDescrButtonSwipeProps): JSX.Element | false => {
    const swiper = useSwiper()
    const onClick = () => {
        swiper.slideTo(1)
    }
    return isRole(["Expert"])
        &&
        <div className="d-flex justify-content-center w-100">
            <Button className="mx-auto" minimal onClick={onClick} >
                {__("Add descriptions")}
            </Button>
        </div>
}
export default DescrButtonSwipe