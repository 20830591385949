import React, { useId } from 'react' 
import { Icon, Intent } from '@blueprintjs/core'
import { __ } from "src/libs/utilities"
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const Youtube = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<YoutubeEnabled {...props} />}
        desabledForm={<YoutubeDesabled {...props} />}
    />
} 
export default Youtube 

export const YoutubeEnabled = ( props: IScalarForm ): JSX.Element => { 
    const onChange = (evt: any) => {
        const value: string = evt.target.value
        const myregexp: RegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        const v = value.match( myregexp ) 
        console.log( (v && v[7]?.length === 11) ? v[7] : '' )
        if( props.onChange )
        {
            props.onChange( (v && v[7]?.length === 11) ? v[7] : '' ,  props.field, "" )
        }   
    }

    return <div className=" d-flex flex-column  w-100">
        <iframe 
            width={props.width ? props.width : props.height && typeof props.height === "number" ? props.height * 1.6 : "320"} 
            height={props.height || "160"} 
            src={`https://www.youtube.com/embed/${  props.value }`} 
            title="YouTube video player"
             allowFullScreen
        />
        <input  
            className = "form-control input dark" 
            value = {  props.value } 
            onChange={onChange}
        /> 
    </div>
} 
 
export const YoutubeDesabled = ( props: IScalarForm ): JSX.Element => {
    return props.value
        ?
        <div className=" d-flex flex-column  w-100">
            <iframe 
                width={props.width ? props.width : props.height && typeof props.height === "number" ? props.height * 1.6 : "320"} 
                height={props.height || "160"} 
                src={`https://www.youtube.com/embed/${  props.value }`} 
                title="YouTube video player"
                allowFullScreen
            /> 
        </div>
        :
        props.value
} 
 