import { IUser, useAppoloStore } from '@/settings/zustand'
import { Role } from '@/libs/interfaces/layouts'
import { AppToaster, Loading } from '@/libs/useful'
import { __ } from '@/libs/utilities'
import { Intent } from '@blueprintjs/core'
import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react' 

interface IProps {

}
const AutoCreatedUsersForm = (props: IProps) :JSX.Element => {
    const [users, setUsers] = useState<IUser[]>([])
    const [isLoading, setIsLoading] = useState<boolean> (false )
    useEffect(() => {
        const apolloClient: any = useAppoloStore.getState().client
        apolloClient.query({
            query : gql`
            query getAutoCreatedUsers {
                getAutoCreatedUsers { 
                    id
                    display_name
                    user_login
                    user_email
                    post_content
                    roles
                }
            } 
            `
        }).then(
            (response:any) => {
                setUsers( response.data.getAutoCreatedUsers )
                setIsLoading(true)
            },
            (error:any) => AppToaster.show({
                message: __("Unknown error"),
                className: "p-4",
                intent: Intent.DANGER,
                timeout: 10000,
                icon: "error",                
            })
        )
    }, [])
    
    //if( !isLoading ) return <Loading />

    return !!users.length
        ?
        <table className="table table-striped mb-5" >
            <thead className="table-dark">
                <tr>
                    <td className="thead-dark py-3 pl-2">{__("Display name")}</td>
                    <td className="thead-dark py-3">{__("Login")}</td>
                    <td className="thead-dark py-3">{__("e-mail")}</td>
                    <td className="thead-dark py-3">{__("Password")}</td>
                    <td className="thead-dark py-3">{__("Roles")}</td>
                </tr>
            </thead>
            <tbody>
            {
                users.map((user:IUser) => {
                    return <tr key={user.id}>
                        <td  className='py-3'>{user.display_name}</td>
                        <td className="font-weight-bold py-3">{user.user_login}</td>
                        <td  className='py-3'>{user.user_email}</td>
                        <td  className='py-3'>{user.post_content}</td>
                        <td  className='py-3'>{ user.roles.map((e: Role) => <span className=" member_span">{e}</span> )}</td>
                    </tr>
                })
            }
            </tbody>
        </table>
        :
        <div className="alert alert-danger mt-4 p-4 lead">
            {
                __("No element exists")
            }
        </div>
}

export default AutoCreatedUsersForm