import { Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { timeToReadableString } from "src/libs/utilities"
import { GET_CIRCLE_TICK_ACTION } from "../data/actionTypes"
import { actions } from "../data/actions"
import { ILudensStore, useLudensStore } from "../data/store"
import { ITimes, padZero } from "src/libs/utilities/sprintf"

const CircleMonitor = () : JSX.Element => {
    return <div className="ludens-circle-monitor">
        <CircleLabel />
        <CircleSlider />
    </div>
}
 

export default CircleMonitor

const CircleLabel = () : JSX.Element => {
    const currentCircleId = useLudensStore((state:ILudensStore) => state.currentCircleId)
    return <div className="circle-label">
        <Icon icon="time" />
        <span className="pl-2">
            {currentCircleId}
        </span>
    </div>
}

const CircleSlider = () :JSX.Element => {
    const [currentCircleDuration, setCurrentCircleDuration] = useState<number>(0)
    const [linearDuration, setLinearDuration] = useState<number>(0)
    const [restTime, setRestTime] = useState<number>(0)
    const tick = () => { 
        actions(GET_CIRCLE_TICK_ACTION, {})
            .then(response => {
                setCurrentCircleDuration( response.getCurrentDuration )
                setLinearDuration( response.getLinearDuration )
                setRestTime( response.getRestTime )
            })
    } 
    useEffect(() => {
        const timer = setInterval(() => { 
            tick()
        }, 1000 )
        return () => { clearInterval(timer) }
    }, []) 
    const passed: ITimes = timeToReadableString( ~~(linearDuration/1000) )
    const rest: ITimes = timeToReadableString( ~~(restTime/1000) )
    return <div className="circle-slider">
        <div className="circle-track">
            <div 
                className="slider-progress"
                style={{width : `${ currentCircleDuration }%`}}
            />
            <div className="passed-time">{ passed.hrs }:{ padZero(passed.mins) }:{ padZero(passed.secs) }</div>
            <div className="rest-time">{ rest.hrs }:{ padZero(rest.mins) }:{ padZero(rest.secs) }</div>
        </div>
        
    </div>
}