import { IDataType } from "@/libs/interfaces/layouts"
import { schema } from "@/libs/layouts"
import DataTypeSingle from "./DataTypeSingle"
import React from "react"
const SchemaForm = (): JSX.Element => {
  return <div className="w-100 h-100 overflow-y-auto">
      {
        Object.keys(schema()).map((key: string, i) => {
            return <DataTypeSingle item={ schema()[ key ] } title={key} key={key} i={i} />
        })
      } 
  </div>
} 

export default SchemaForm