import { isRole } from "@/settings/zustand/user"
import { __, data_type_admin_url } from "@/libs/utilities"  
import { Button, Icon } from "@blueprintjs/core/"
import { Link, useParams } from "react-router-dom"

const AdminItemLink = (props: any): JSX.Element => {
    const route = data_type_admin_url( props.singled_data_type )  
    const params = useParams()
    return isRole(["administrator"])
        ?
        <Link to={`/${route}/${params.id}`}>
            <Button minimal className="hint hint--left mr-2" data-hint={__("Administration")}> 
                <i className="fas fa-unlock-alt" />            
            </Button>
        </Link>
        :
        <></> 
} 
export default AdminItemLink 