import { __ } from "@/libs/utilities"
import { Link } from "react-router-dom"

/* Protopia Ecosystem component */
export interface INoMatchFormProps {

}
const NoMatchForm = (props: INoMatchFormProps): JSX.Element => {
    return <div className="row text-center">
        <div className="col-12 my-4">
        <div className="_404" />
        </div>
        <div className="col-12 lead">
        {__("this page no searched")}
        </div>
        <div className="col-12 my-4">
        <Link
            className="btn btn-danger btn-sm"
            to="/"
        >
            {__("Return to main page")}
        </Link>
        </div>
    </div>
}
export default NoMatchForm