import { clearStory } from "src/modules/pe-fabula-module/hooks/story"
import { useEffect } from "react"
import { __ } from "src/libs/utilities"
import { Button, ButtonGroup, Intent } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IStoryBreakProps {

}
const StoryBreak = (props: IStoryBreakProps): JSX.Element => {
    useEffect(() => {
        clearStory()
    }, [])
    return <div className='pe-story-break-container'>
        <div className="display-6">
            {__("Quest broken")}
        </div>
        <div className="my-3">
            {__("Story suddenly finished by timeout")}
        </div>
        <ButtonGroup>
            <Button large intent={Intent.DANGER} >
                {__("Choose new")}
            </Button>
            <Button large intent={Intent.SUCCESS} >
                {__("Restart this story")}
            </Button>
        </ButtonGroup>
    </div>
}
export default StoryBreak