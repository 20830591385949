import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { isRole } from '@/settings/zustand/user'
import { Button, ButtonGroup, Collapse, Popover, Position } from '@blueprintjs/core'
import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { LayoutIcon } from '@/libs/useful'
import { __, compareArrays } from '@/libs/utilities'
import React, { useEffect, useRef, useState } from 'react'
import { template } from 'src/libs/layouts'
import { SET_FILTERS_ACTIONS } from '../data/actionTypes'
import { syncAction } from '../data/actions'
import { IDestrict, IFestFilters } from '../data/interfaces'
import { IFestStore, useFestStore } from '../data/store'
import { isMyDestrict } from '../data/user'
import useClickOutside from './hooks/useClickOutside'
import useProjectListRoute from './hooks/useProjectListRoute'

const SCHOOL_FILTER_ICON_URL = "/assets/img/fest/schools.svg"

const SchoolsFilterMenu = () : JSX.Element => { 
    if(window.innerWidth < WINDOW_WIDTH.TABLET) return <></> 
    return <SchoolFilters />   
}

export const SchoolFilters = () : JSX.Element => { 
    const destrict : IDestrict[] = useDataStore((state:any) => state.PEFestDestrict )  || []
    const filters: IFestFilters = useFestStore((state: IFestStore) => state.filters )
    const setCurrentTitle = () => {  
        switch(filters.tracks[0]) {
            case -1:       
                setTitle( "all Schools" )
                break
            case "mine": 
                setTitle( "Only mine Schools" )
                break
            default: 
                const curTrack = destrict.filter( track => track.id === filters.destrict[0] )[0]
                setTitle( curTrack ? curTrack.title : "all Schools")
        }
    }
    const [title, setTitle] = useState( __( "all Schools" ) )
    const [isOpen, setOpen] = useState(false)
    const [isCollapse, setCollapse] = useState(false)
    console.log( "SCHOOL" )
    const myRef: React.MutableRefObject<null> = useRef( null )
    const isRoute: boolean = useProjectListRoute() && !!destrict.length
    
    const onOpen = (isOpen:boolean) =>
    {
        setOpen(isOpen)
    }
    let timeOut: any = -1    
    useClickOutside( myRef, onOpen, timeOut)

    useEffect( () => {
        setCurrentTitle()
    }, [ filters ] )

    
     
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }
    const selectTrack = (id: ID) =>
    {   
        const selectTracks =  id === -1
            ?
            [-1]
            :
            id === "mine" ? [ "mine" ] : [ id ] 
        syncAction(
            SET_FILTERS_ACTIONS, 
            { 
                filters:{ 
                    destrict: selectTracks[0] === "mine"
                        ?
                        destrict.filter( ( _track: IDestrict ) => {
                            return isMyDestrict( _track )  //|| isOwnerTrack( _track ) 
                        })
                        .map(project => project.id)
                        :
                        selectTracks
                } 
            } 
        )
        setCurrentTitle()
        clearTimeout( timeOut )
        onOpen(false)
    } 
    const onMouseLeave = () =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout(() =>
        {
            onOpen(false)
        }, 1000)
    }
    return <>   
        <div 
            className={`indicator dble lrg classic p-0 m-0 overflow-hidden ${ isRoute ? "" : " closed"}`}             
            onMouseLeave={onMouseLeave}
            ref={ myRef }
        >
            <div className={ `header-menu-element ${ template().header_menu } main-menu-filter-icon ` }  onClick={handleLoginOpen}>
                <LayoutIcon
                    src={ SCHOOL_FILTER_ICON_URL }
                    className="header-menu-icon"
                    
                />
                <span className='header-menu-label px-2 ' title={ __( title ) }>
                    { __( title ) }
                </span>
            </div> 
            <Popover
              className="indicator-popover"
              popoverClassName="p-0" 
              position={ Position.BOTTOM_RIGHT }
              isOpen={isOpen}
              content={(
                <div className='overflow-y-auto' style={{maxHeight:"calc(100vh - 150px)"}}>
                    <ButtonGroup 
                        vertical 
                        fill 
                        minimal 
                        onMouseEnter={() =>  clearTimeout( timeOut ) }
                        style={{ minWidth: 200 }}
                    >
                        {
                            true //filters.honeycombs[0] !== -1 || filters.destrict[0] !== -1 
                                ?
                                <Button 
                                    className={`p-3 btn-stretch text-force-contrast ${ compareArrays( [ -1 ], filters.tracks ) ? " bg-danger  text-light " : "" }`} 
                                    onClick={() => selectTrack( -1 ) }
                                >
                                    {__("all Schools")}
                                </Button>
                                :
                                null
                        }                      
                        {
                            isRole([ "Tutor", "Project_author" ]) 
                                ?   
                                <Button 
                                    className={`p-3 text-force-contrast btn-stretch ${ compareArrays( ['mine'], filters.destrict ) ? " bg-danger  text-light " : "" }`}  
                                    onClick={() => selectTrack( "mine" ) }
                                >
                                    {__("Only mine Schools")}
                                </Button> 
                                :
                                null
                        }
                        {
                            Array.isArray( destrict )
                                ?
                                <>
                                    <Button 
                                        icon="chevron-down" 
                                        minimal
                                        small
                                        className={isCollapse ? "hidden" : " p-3 "} 
                                        onClick={( event: React.MouseEvent<HTMLElement, MouseEvent> ) => {
                                            setCollapse(true)
                                            onOpen(true)
                                            event.preventDefault()
                                            event.stopPropagation()
                                        }}
                                    />
                                    <Collapse isOpen={isCollapse} >
                                        <div className="d-flex flex-column">
                                        {
                                            destrict.map((track:IDestrict) =>
                                                {
                                                    return <Button 
                                                        className={ `p-0 btn-stretch text-force-contrast ${ filters.destrict.includes( track.id ) ? " bg-danger text-light " : "" }` } 
                                                        key={track.id} 
                                                        onClick={() => selectTrack(track.id) }
                                                    >
                                                        <div className='d-flex w-100'>
                                                            <span className='flex-grow-100 p-3 small '>
                                                                { track.title }
                                                            </span>
                                                            <span className ='p-3 bg-secondary-super-light small '>
                                                                { track.count || 0 }
                                                            </span>
                                                        </div>
                                                    </Button>
                                                    
                                                })
                                        }                                    
                                        </div>
                                    </Collapse>
                                </>
                                :                        
                                <div className='p-3' >
                                    {__("no Schools")}
                                </div>   
                        }
                    </ButtonGroup>
                </div>
              )}
            >
                <div style={{ marginTop: 0 }} />
            </Popover> 
        </div> 
    </> 
} 

export default SchoolsFilterMenu



 