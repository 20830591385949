import IScalarForm from "@/libs/scalars/interface/IScalarForm"
import Scalar from "@/libs/scalars/scalars/Scalar"
import SelectMenuGroup from "../views/menu/SelectMenuGroup"
import { __ } from "@/libs/utilities" 
import { default_menu } from "../data/layoutsClone/routing"

const MenuGroupScalarWidget = (props: IScalarForm) : JSX.Element => {
    return <Scalar
        {...props}
        enabledForm={<MenuGroupEdit {...props} />}
    />
}

export default MenuGroupScalarWidget

const MenuGroupEdit = (props:IScalarForm) : JSX.Element => {
    const onSelectMenuGroup = (value: string) => {
        props.onChange!(value, props.field, "" )
    }
    return <div className="w-100 mr-3">
        <SelectMenuGroup currentMenuGroup={props.value} onSelectMenuGroup={onSelectMenuGroup}/> 
        <div className="lead mt-3">
            { __( default_menu()[props.value] ? default_menu()[props.value].title : props.value ) }
        </div> 
        <div className=" ">
            { __( default_menu()[props.value] ? default_menu()[props.value].description : "" ) }
        </div>
    </div>
    
}