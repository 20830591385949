import { IUser } from "@/settings/zustand"
import { __, wordTrim } from "@/libs/utilities"
import { Button, EditableText } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IUserEditCardProps {
    item: IUser
}
const UserEditCard = (props: IUserEditCardProps): JSX.Element => {
    return <div className='pe-project-edit-card-container'>
        <div 
            className="thumb"
            style={{ backgroundImage:`url(${props.item.avatar})` }}
        >
            
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 w-100"
                    alwaysRenderInput={false} 
                    disabled
                    placeholder="Edit title..."
                    selectAllOnFocus={false} 
                    value={props.item.display_name}
                /> 
            </div>
            <div 
                className="content" 
                dangerouslySetInnerHTML={{__html: wordTrim( props.item?.post_content || "", 50 ) }}
            />
            <div className="footer"> 
                <Button icon="trash" minimal >{__("Delete")}</Button>
            </div>
        </div> 
    </div>
}
export default UserEditCard