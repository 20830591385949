
import BasicState from "src/libs/basic-view" 
import "../assets/css/style.scss"  
import TestsForm from "./tests/TestsForm"

export default class TestsView extends BasicState {
    props: any 
    addRender = () => {
        return <TestsForm {...this.props} />   
    }
}