import { IProject } from '../../data/interfaces'
import { IFestProjectsProps, useFestProjects } from '../../data/store' 
import ProjectMilestones from './ProjectMilestones'   
import ProjectRating from './ProjectRating' 
import ExpertDescriptions from './ExpertDescriptions'  
import InsertCritery from './insert-critery' 

const Project = (props: any) : JSX.Element => {   
    
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject) 
    return <div className="container pt-4 pb-5 single-project-container">  
            <ProjectMilestones {...props}  item={item} />
            <ProjectRating {...props}  item={item} />
            <InsertCritery />
            <ExpertDescriptions />
        </div> 
} 

export default Project