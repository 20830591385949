import { IFestival, IFormField } from "../interfaces"
import { useFestDataStore } from "../store"

export const REGISTER_PROJECT = ( data: any ) => { 
    const festival:IFestival = useFestDataStore.getState().festival 
    const form_fields: string[] = festival.extendedFields.map( (field:IFormField, i: number) => { 
        return Array.isArray( data.item.form_fields[i] ) && !!data.item.form_fields[i].length
            ?
            '"' + data.item.form_fields[i].join('","') + '"'
            :
            ""
    }) 
    const site: string = data.land_id
        ?
        ` land_id: "${data.land_id}" `
        :
        `` 
    const gql: string =  `
        mutation register_project {
            registerPEFestProject(
                ${site} 
                input: {
					title : "${ data.item.title }"
                    thumbnail: "${data.item.thumbnail}}"
                    thumbnail_name: "${data.item.thumbnailName}"
                    post_content: "${ data.item.content.split(`
`).join("</p><p>") }"
                    tutor_name: "${ data.item.tutorName }"
                    tutor_email: "${ data.item.tutor_email }"
                    tracks:[ ${ data.item.track } ],
                    form_fields: ${ '[[' + form_fields.join('],[') + ']]' }
                }
            ){
                message
                id
            }
        }
    `
    // console.log( gql ) 
    return gql
}