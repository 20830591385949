import { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { IProject, IRating } from '../../data/interfaces'
import { useFestival } from '../../hooks/festival'
import FullMemberStatistics from '../statistics/FullMemberStatistics'
import About from './About'
import AboutContainer from './AboutContainer'
import InsertNewProject from './InsertNewProject'
import Reglament from './Reglament'
import StatisticForm from './StatisticForm'

import { IUser } from '@/settings/zustand'
import { ID } from '@/libs/interfaces/layouts'
import Loading from '@/stories/Loading'
import { GET_FULL_STATISTICS_ACTION } from '../../data/actionTypes'
import { actions } from '../../data/actions'
import FullExpertStatistics from '../statistics/FullExpertStatistics'
import SpecialStatictics from '../statistics/SpecialStatictics'

const AboutForm = ( { ...props }: any ) : JSX.Element => {
    const params = useParams()
    const festId: ID = params.landId || ""
    const isFestLoading = useFestival( true, festId )
    const [isPojectsLoading, setIsProjectLoading] = useState( true )
    const [projects, setProjects] = useState<IProject[]>( [] )
    const [users, setUsers] = useState<IUser[]>( [] )
    const [ratings, setRatings] = useState<IRating[]>( [] )
 
    useEffect(() => {
        actions(GET_FULL_STATISTICS_ACTION, {land_id: festId} )
            .then(response => {
                setProjects( response.data.getPEFestProjects )
                setUsers( response.data.getUsers )
                setRatings( response.data.getPEFestRatings )
                setIsProjectLoading( false )
            })
    }, [])
 
    if( isFestLoading || isPojectsLoading ) return <div className="h-100 w-100 flex-center">
        <Loading />
    </div>  
    return  <>
        <Routes>
            <Route element={<AboutContainer />} >
                <Route index element={<About />} />
                <Route path="reglament" element={ <Reglament /> } />
                <Route path="insert-project" element={ <InsertNewProject /> } />
                <Route path="statistic/*" element={ <StatisticForm /> } >   
                    <Route 
                        path="all-members" 
                        element={ 
                            <FullMemberStatistics 
                                members={ projects } 
                                users={users}
                                ratings={ratings}
                            /> 
                        } 
                    />
                    <Route 
                        path="users" 
                        element={ 
                            <FullExpertStatistics
                                members={ projects } 
                                users={users}
                                ratings={ratings}
                            /> 
                        } 
                    />                    
                    <Route 
                        path="synthesizer" 
                        element={ 
                            <FullExpertStatistics
                                members={ projects } 
                                users={users}
                                ratings={ratings}
                            /> 
                        } 
                    />               
                    <Route 
                        path="specials" 
                        element={ 
                            <SpecialStatictics
                                members={ projects } 
                                users={users}
                                ratings={ratings}
                            /> 
                        } 
                    />                                          
                    <Route path="*" element={ <Navigate to="all-members" replace /> } />                                             
                </Route>
            </Route>
        </Routes>
        <Outlet />
    </> 
} 

export default AboutForm