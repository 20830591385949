import React, {useEffect, useId, useState} from 'react'  
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import { yandex_map_api_key } from "@/settings/map" 

const Geo = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props }
        enabledForm={<GeoEnabled {...props} />}
        desabledForm={<GeoDesabled {...props} />}
    />
} 

export default Geo
 
export const GeoEnabled = ( props: IScalarForm ): JSX.Element => { 
    //console.log( props.value )
    const [map, setMap]         = useState<any>(false)
    const [marker, setMarker]   = useState<any>(false)
    const id = useId()
    useEffect(() => {
        const { ymaps } = window
        const { ymapReady } = window
        if (ymapReady || ymaps) { 
            drawMap()
            return
        }
        window.ymapReady = true
        const el: any = document.createElement("script") 
        el.type = "text/javascript"
        el.src = `https://api-maps.yandex.ru/2.1/?load=package.full&lang=ru_RU&apikey=${yandex_map_api_key()}`
        el.async = true
        el.id = "ymap-jssdk"
        el.onload = function () {
            window.ymaps?.ready(() => { 
                drawMap()
            })
        }
        document.getElementsByTagName("head")[0].appendChild(el)

        window.ymaps?.ready( drawMap )
        }, []) 
    const drawMap = () => {
        if(!window.ymaps) return 
        const _map = new window.ymaps.Map(
            `map_${ id }`, 
            {
                center: Array.isArray(props.value) ? props.value : [ 55.76, 37.64 ],
                zoom: 7,
                controls: [ 'geolocationControl' ]
            }
        ) 
        const _marker = new window.ymaps.GeoObject(
                {
                    geometry : {
                        type: "Point",
                        coordinates: Array.isArray(props.value) ? props.value : [ 55.76, 37.64 ]
                    }, 
                    propirties: {
                        iconContent: 'I\'m draggable',
                        hintContent: 'Come on, drag already!'
                    }
                },
                {
                    draggable: true
                }
            ) 
        if(props.editable) {
           _marker.events.add(
                [ 'dragend' ], 
                (e: any) => {
                    //console.log( e.get('target').geometry.getCoordinates() )
                    const value = Array.isArray(props.value)
                        ?
                        [ ...e.get('target').geometry.getCoordinates(), props.value[ 2 ] ]
                        :
                        [ ...e.get('target').geometry.getCoordinates(), 10 ]
                    if(props.onChange) {
                        props.onChange( value, props.field, "" )
                    }
                }
            ); 
        }
        
        _map.geoObjects.add (_marker )
        if( !map ) {
            setMap( _map)
            setMarker( _marker )
        }
        
    }
    return <div className="w-100">
        <div className="mt-2" />
        {/* <div>{JSON.stringify( props.value )}</div> */}
        <div 
            id={ `map_${id}` } 
            style={{ width: props.width || 600, height: props.height || 400 }}
        />
    </div> 
}  

export const GeoDesabled = ( props: IScalarForm ): JSX.Element => {
    return <div className="d-flex my-1">
        <i className="fas fa-calendar-alt pr-1" />
        <div className=""> 
            { props.value.toString() } 
        </div>
    </div>
}  



declare global {
    interface Window { 
        ymaps: any; 
        ymapReady: any; 
    }
  }