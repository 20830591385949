import { IPEVKPost } from "src/libs/interfaces/layouts"
import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { GET_VKPOST_FEED_ACTION } from "../../data/actionTypes" 
import { Loading } from "src/libs/useful"
import Message from "../landscape/Message"

const PEVKPostFeed = (props: any) : JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [posts, setPosts] = useState<IPEVKPost[]>([])
  useEffect(() => {
    actions(GET_VKPOST_FEED_ACTION, {} )
      .then((response: IPEVKPost[]) => {
        setIsLoading(false)
        setPosts(response)
      })
  }, [])
  if(isLoading) return <div className="layout-state"><Loading /></div>
  // TODO: change to Feed.tsx
  return <div className="small-container">
  {
    posts.map(post => <Message key={post.id} item={post} />)
  }  
  </div>
}
 

export default PEVKPostFeed