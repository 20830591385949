import { modules } from "@/libs/layouts"
import { Button, ButtonGroup, Collapse, Icon, Intent } from "@blueprintjs/core"
import { IAdminStore, useAdminStore } from "../../data/store"
import { __ } from "@/libs/utilities"
import { ALL_DICTIONARIES_TAG, CORRECT_DICTIONARIES_TAG } from "../../data/interfaces"
import { useState } from "react"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"

/* Protopia Ecosystem component */
export interface IModulesMenuProps {

}
const ModulesMenu = (props: IModulesMenuProps): JSX.Element => {
    const currentDictModule : string = useAdminStore((state: IAdminStore) => state.currentDictModule ) || ALL_DICTIONARIES_TAG
    const [isOnlyInstalled, setIsOnlyInstalled] = useState<boolean>(true)
    const setCurrent = (mname: string) => {
        useAdminStore.setState({ currentDictModule: mname })
    }
    const modulesMenu = Object.keys(modules())
        .filter(mname => {
            return isOnlyInstalled ? !modules()[ mname]?.disabled : true
        })
        .map((mname, i) => {
            return <ModulesMenuButton 
                key={mname}
                currentDictModule={currentDictModule}
                mname={mname}
                setCurrent={setCurrent}
            />
        })
    return <div className='pe-modules-menu-container'>
        <ButtonGroup fill vertical>
            <Button 
                minimal={ currentDictModule !== ALL_DICTIONARIES_TAG } 
                fill
                className={`  px-4 py-2 ${ALL_DICTIONARIES_TAG === currentDictModule ? "bg-light" : "strob03" }`}
                onClick={ () => setCurrent( ALL_DICTIONARIES_TAG ) }
            >
                <div className="display-6">
                    {__("All dictionaries")}
                </div>                
            </Button>
            <div className="w-100">
                <FieldInput
                    type={SCALAR_TYPES.BOOLEAN}
                    value={isOnlyInstalled}
                    editable
                    onChange={value => setIsOnlyInstalled(value)}
                    notClear
                    disabledLabel={__("All modules")}
                    enabledLabel={__("Only installed modules")}
                />
            </div>
            { modulesMenu }
            <Button 
                minimal={ currentDictModule !== CORRECT_DICTIONARIES_TAG } 
                fill
                className={`  px-4 py-2 ${CORRECT_DICTIONARIES_TAG === currentDictModule ? "bg-light" : "strob03" }`}
                onClick={ () => setCurrent( CORRECT_DICTIONARIES_TAG ) }
            >
                <div className="display-6">
                    {__("Corrects of Dictionaries")}
                </div>                
            </Button>
        </ButtonGroup>
    </div>
}
export default ModulesMenu


/* Protopia Ecosystem component */
export interface IModulesMenuButtonProps {
    currentDictModule: string
    mname: string
    setCurrent: (current: string) => void
}
const ModulesMenuButton = ({currentDictModule, mname, setCurrent, ...props}: IModulesMenuButtonProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(true)
    return <Button 
        minimal={ mname !== currentDictModule } 
        className={`stretch px-4 py-0 position-relative ${mname === currentDictModule ? "bg-light" : "strob03" }`}
        onClick={() => setCurrent(mname)}
        fill
    >
        <div 
            className="d-flex w-100 align-items-center justify-content-between"
            // onMouseOver={ () => { 
            //     setIsOpen(true) 
            // }}
            // onMouseOut={ () => {
            //     setIsOpen(false) 
            // }}
        >
            <img 
                src={ modules()[ mname]?.icon || "https://gitlab.com/uploads/-/system/project/avatar/33048044/PE_logo_large.png"}
                className='w_45 mr-3 '
                alt={ "" }
            />
            <div className="flex-grow-100 flex-centered flex-column py-2">
                <div className="lead text-center">
                    { __( modules()[ mname ].title ) }
                </div>
                <Collapse isOpen={isOpen}>
                    <div className="small text-center py-3 px-2">
                        {__(modules()[ mname ].description)}
                    </div>
                </Collapse>
                
            </div>
            {
                modules()[ mname ].disabled
                    ?
                    <Icon icon="cross" intent={Intent.DANGER}  />
                    :
                    <Icon icon="tick"  intent={Intent.SUCCESS} />
            }
        </div>
    </Button>
} 