import { IUserMana } from "../../data/interfaces" 
import { IManaProps } from "../../data/manas"
import { IManituStore, useManituStore } from "../../data/store"

const Monitor = () : JSX.Element => {
    
    const userMana: IUserMana = useManituStore((state:IManituStore) => state.userMana)
    const manaTypes: IManaProps[] = useManituStore((state: IManituStore) => state.manaTypes)
    
    return <div className="monitor datas">
        {
            manaTypes.map(mana => {
                return <div className="mana" style={{backgroundImage: `url(${ mana.icon })`}} key={mana.id}>
                    <div className="mana-count">{ userMana[ mana.id ].count }</div>
                </div>
            })
        }
    </div> 
} 

export default Monitor