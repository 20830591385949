import { Popover } from "@blueprintjs/core"
import { IUserMana } from "../../data/interfaces"
import { IManaProps } from "../../data/manas"
import { IManituStore, useManituStore } from "../../data/store"


const ManaMonitorTablet = () : JSX.Element => {
    const userMana: IUserMana = useManituStore((state:IManituStore) => state.userMana)
    const manaTypes: IManaProps[] = useManituStore((state: IManituStore) => state.manaTypes)

    return <Popover
        interactionKind="click"
        modifiers={{
            arrow: { enabled: false },
            flip: { enabled: true },
            preventOverflow: { enabled: false },
        }}
        position="top-left"
        content={<div className="p-3">
            <div className="monitor datas widget p-0">
            {
                manaTypes.map(mana => {
                    return <div className="mana p-1" style={{backgroundImage: `url(${ mana.icon })`}} key={mana.id}>
                        <div className="mana-count">{ userMana[ mana.id ].count }</div>
                    </div>
                })
            }
            </div> 
        </div>}
    >
        <div className="monitor datas widget">            
            <div className="mana" style={{backgroundImage: `url(${ manaTypes[0].icon })`}} >
                
            </div>
        </div> 
    </Popover> 
}

export default ManaMonitorTablet