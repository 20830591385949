import { __ } from "@/libs/utilities" 
import { Button, Dialog, Icon, Intent } from "@blueprintjs/core"
import { useState } from "react"
import MenuDrawer from "./MenuDrawer"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"

 
interface IProps {

}
const MoveTo = (props: IProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onOpen = () => {
        setIsOpen(!isOpen)
    }
    return <>
        <Button intent={Intent.NONE} large icon="changes" onClick={onOpen}>
            {__("Move item to...")}
        </Button>
        <Dialog
            className={DIALOG_SIZE.FULL} 
            isOpen={isOpen}
            onClose={onOpen} 
        >
            <div className="p-4">
                <MenuDrawer />
            </div>
            <Button minimal onClick={onOpen} className="position-absolute top right px-3 py-2">
                <Icon icon="cross" size={20} color="#FFFFFF"/>
            </Button>
        </Dialog>
    </>
} 

export default MoveTo

