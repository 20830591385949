import { IUser } from "@/settings/zustand"
import { ITimes } from "@/libs/utilities/sprintf"
import { Email, ID, URL, HTML, IMenuItem, POST_STATUS, IPost, Role, Date, Icon, Color, } from "@/libs/interfaces/layouts"

export enum ILudensPhase {
    BEFORE="BEFORE",
    DURING="DURING",
    AFTER="AFTER"
}
export interface ILudens {
    title: string
    description: HTML
    icon: Icon
    thumbnail: URL
    phase: ILudensPhase
    circleDuration: ITimes
    start: Date
    currentCircleId: number 
    currentStart: Date
    pixelTestPrice: number 
    pixelTestHardness: number 
    pixelOpenProblemPrice: number 
    pixelOpenProblemHardness: number 
}
export enum OPPONENT_TYPE {
    USER="USER",
    NPS="NPS"
}
export interface IOpponent extends IUser {
    type?: OPPONENT_TYPE
}

export interface IPEResourceType extends IPost {
    icon: string
    color: Color
    cyclic_update: boolean
    is_transfered: boolean
    is_writing_off: boolean
    max: number
    world_stock: number
}

export interface IPEResource extends IPost {
    type: IPEResourceType
}

export interface IUserResource {
    type: IPEResourceType
    count: number
}

export interface PELudensEngine {
    id: ID
    title: string
    descriptionURL?: URL
    thumbnail: URL
    path: string
    isSepia?: boolean
    component: JSX.Element
}
