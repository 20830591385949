import { ID } from "@/libs/interfaces/layouts"
import { getQueryArgs } from "src/libs/layouts"
 
export const GET_UNVERIFIED = ( land_id: ID = -1 ) => { 
    return `query getOwnProjects {
        getPEFestProjects( 
            paging:{ 
                relation: "OR"
                metas : [
                    { key: "is_verified" value_bool: false }
                    { key: "is_verified" compare: "NOT EXISTS" }
                ] 
            }
            ${
                land_id && land_id !== -1
                    ?
                    `land_id: "${ land_id }"`
                    :
                    ""
            }
        ) 
        {
            ${ getQueryArgs("PEFestProject") }
        }
    }`
        
} 