import React, { useState } from 'react' 
import FieldInput from '@/libs/scalars'
import { __ } from '@/libs/utilities'
import { Button, Intent } from '@blueprintjs/core'
import { SCALAR_TYPES, VerticalType } from '@/libs/scalars/interface/IScalarForm'

interface ICleanMasterProps {
    onStartClean: ( data: any ) => void
}

const ClearMaster = (props: ICleanMasterProps) : JSX.Element => {
    const[raitingData, setRatingData] = useState("IGNORE")
    const[ratingSystem, setRatingSystem] = useState("IGNORE")
    const[options, setOptions] = useState("IGNORE") 
    const[projects, setProjects] = useState("IGNORE") 
    const[logs, setLogs] = useState("IGNORE")
    const[users, setUsers] = useState("IGNORE")
    const changeRatingData = (value: string) =>
    {
        setRatingData(value)
    }
    const changeUsersData = (value: string) =>
    {
        setUsers(value)
    }
    const changeRatingSystem = (value: string) =>
    {
        setRatingSystem(value)
    }
    const changeOptions = (value: string) =>
    {
        setOptions(value)
    }
    const changeProjects = (value: string) =>
    {
        setProjects(value)
    }
    const changeLogs = (value: string) =>
    {
        setLogs(value)
    }
    const onStartClean = () =>
    {
        const data = {
            raitingData, ratingSystem, options, projects, users, logs
        }
        props.onStartClean(data)
    }
    return <div>
        <div className='display-6 mb-3'>
            {__("Clean festival data")}
        </div>
        <FieldInput 
            title={__("Clean all rating data")} 
            type={SCALAR_TYPES.TAGS}
            ID="raitingData"
            id="raitingData"
            commentary={__("Include all expert's rates, commentaries, all expert statistics")}
            value={ raitingData }
            values={[
                { _id:"IGNORE",  title:__("Ignore") },
                { _id:"CLEAR", title:__("Clean") },
               // {_id:"resore",  title:"Restore to default"}
            ]}
            default={ {_id:"IGNORE",  title:"Ignore"} }
            editable
            onChange={changeRatingData}
        />
        <FieldInput  
            title={__("Clear all rating system")}
            type={SCALAR_TYPES.TAGS}
            ID="ratingSystem"
            id="ratingSystem"
            commentary={__("Include all categories, criteries, milestones and ganres")}
            value={ ratingSystem }
            values={[
                { _id:"IGNORE",  title:__("Ignore") },
                { _id:"CLEAR", title:__("Clean") },
                //{_id:"resore",  title:"Restore to default"}
            ]}
            default={ {_id:"IGNORE",  title:"Ignore"} }
            editable
            onChange={changeRatingSystem}
        />
        <FieldInput 
            title={__("Clear all projects system")}
            type={SCALAR_TYPES.TAGS}
            ID="projects"
            id="projects"
            commentary={__("Include all projects, tracks and honeycombs. All projects includes and extened fields data")}
            value={ projects }
            values={[
                { _id:"IGNORE",  title:__("Ignore") },
                { _id:"CLEAR", title:__("Clean") },
                //{_id:"resore",  title:"Restore to default"}
            ]}
            default={ {_id:"IGNORE",  title:"Ignore"} }
            editable
            onChange={changeProjects}
        />
        {/* <FieldInput
            visualization="festFormsFields" 
            title={__("All festival enviroinment data")}
            type="tags"
            commentary={__("Festival parameters (title, description, dates and ets.)") }
            ID="options"
            _id="options"
            value={ options }
            values={[
                { _id:"IGNORE",  title: __("Ignore") },
                { _id:"CLEAR",  title: __("Clean") },
                {_id:"resore",  title: __("Restore to default")}
            ]}
            default={ {_id:"IGNORE",  title:"Ignore"} }
            editable
            onChange={changeOptions}
        />  */}
        <FieldInput 
            title={__("Include all users")}
            type={SCALAR_TYPES.TAGS}
            ID="users"
            id="users"
            commentary={__("")}
            value={ users }
            values={[
                { _id:"IGNORE",  title:__("Ignore") },
                { _id:"CLEAR", title:__("Clean") },
                //{_id:"resore",  title:"Restore to default"}
            ]}
            default={ {_id:"IGNORE",  title:"Ignore"} }
            editable
            onChange={changeUsersData}
        />
        <FieldInput 
            title={__("Clear all logs")}
            type={SCALAR_TYPES.TAGS}
            ID="logs"
            id="logs"
            commentary={__("")}
            value={ logs }
            values={[
                { _id:"IGNORE",  title:__("Ignore") },
                { _id:"CLEAR", title:__("Clean") },
            ]}
            default={ {_id:"IGNORE",  title:"Ignore"} }
            editable
            onChange={changeLogs}
        />
        <div className='mt-4'>
            <Button
                intent={Intent.DANGER}
                className="lead p-4"
                fill
                onClick={ onStartClean }
            >
                {__("Start clean now")}
            </Button>
        </div>
    </div> 
}

ClearMaster.propTypes = {}

export default ClearMaster