import { ID } from "@/libs/interfaces/layouts"

export const COMPARE_CURRENT_PROJECT = (id:ID, land_id: ID="") => {
    const s = land_id
        ?
        `land_id: "${land_id}"`
        :
        ``
    return `
    query getProjectVersion {
        getPEFestProject( id: "${id}" ${s}) {
            version
        }
    }`
}