import { __ } from "@/libs/utilities" 
import { useSearchParams } from 'react-router-dom';

/* Protopia Ecosystem component */
export interface IFestivalFilterProps { 

}
const FestivalFilter = (props: IFestivalFilterProps): JSX.Element => {
    const [searchParams, setSearchParams] = useSearchParams();

    const onClick = ( value: string, isClear: boolean = false ) => {
        setSearchParams(isClear ? {} : {"filter": value}) 
    }
    return <div className='pe-festival-filter-container container flex-centered pb-4'>
         <div className=" flex-centered flex-wrap">
            <div 
                className={`btn btn-sm btn-outline-secondary pl-4 pr-3 ${ !searchParams.get("filter") ? "active" : ""}`} 
                onClick={() => onClick("all", true)}    
            >
                {__("All")} 
            </div> 
            <div 
                className={`btn btn-sm btn-outline-secondary px-3  ${ searchParams.get("filter") === "all-before" ? "active" : ""}`} 
                onClick={() => onClick("all-before")}
            >
                {__("Not started yet")} 
            </div>
            <div 
                className={`btn btn-sm btn-outline-secondary px-3  ${ searchParams.get("filter") === "all-current" ? "active" : ""}`} 
                onClick={() => onClick("all-current")}
            >
                {__("All current")} 
            </div>
            <div 
                className={`btn btn-sm btn-outline-secondary pr-4 pl- ${ searchParams.get("filter") === "all-finished" ? "active" : ""}`} 
                onClick={() => onClick("all-finished")}
            >
                {__("All finished")} 
            </div>
        </div>
    </div>
}
export default FestivalFilter