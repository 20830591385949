import { Button } from '@blueprintjs/core'
import React from 'react'
import { useNavigate } from 'react-router'
import { __ } from '@/libs/utilities'
import { ReactComponent as Cat404 } from "@/assets/img/cat-404.svg"

export const ErrorFolder = () => {
    const navigate = useNavigate()
  return (
    <div className='layout w-100 h-100 flex-centered'>
        <div className='alert alert-secondary w-100 p-5 flex-centered flex-column'> 
            <Cat404
                height={240}
                width={2000} 
            />        
            <div className='lead'>
                This Folder not exists
            </div>
            <Button minimal className="mt-3" onClick={() => navigate(-1)}>{__('Goto prevous page')}</Button>            
        </div>  
    </div>
  )
}
