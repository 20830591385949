
import BasicState from "src/libs/basic-view" 
import LudensModelForm from "./ludens-model/LudensModelForm"

export default class LudensModelView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <LudensModelForm 
                engines={[]}
                modelTitle="Engine title"
                {...this.props} 
            />  
        </div>
    }
}