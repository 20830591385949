import { Button } from "@blueprintjs/core"
import { ITestProps } from "./SingleTest"
import { __ } from "src/libs/utilities"
import { useNavigate } from "react-router"

const Start = ({ item }: ITestProps) : JSX.Element => {
    const navigate = useNavigate()
    const toNext = () => {
        navigate("question/1")
    }
    return <div className="pe-matching-start"> 
        <div className="pe-matchings-thumbnail-cont">
            <div 
                className="pe-matchings-thumbnail "
                style={{backgroundImage:`url(${item.thumbnail})`}}
            /> 
        </div>
        <div className="short-container">
            <div
                className={"pe-matchings-title " } 
                dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <div 
                className={"pe-matchings-description " } 
                dangerouslySetInnerHTML={{ __html: item.post_content }} 
            />
            {/* <div className="my-5">
                <Button large onClick={toNext}>
                    {__("Start test")}
                </Button>
            </div> */}
        </div>
        
    </div>
}

Start.propTypes = {}

export default Start