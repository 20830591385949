 
import { __,  } from "@/libs/utilities"
import { Button } from "@blueprintjs/core"
import { useState } from "react"  
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { IState, useMainStore } from "@/settings/zustand"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"


const SettingsForm = ( ) :JSX.Element => { 
    const pageCount: number = useMainStore(( state: IState ) => state.pageCount)
    const setPageCount: any = useMainStore((state: IState ) => state.setPageCount)
    const [data, setData ] = useState<any>({})
    const onChangeData = ( value: any ) => {
        setData( value )
    }
    const onSave = () => {

    }
    return  <div className="short-container"> 
        {/* <FieldInput
            type={SCALAR_TYPES.CHECKBOX}
            editable
            title={__("is update data from server?")}
            value={ Object.keys(data).filter( e => data[ e ] === true ) } 
            values= {[
                {_id:'is_load_data_type', title: __("Data type map") },
                {_id:'is_load_menus',  title: __("Site Map, Views, Menus") },
                {_id:'is_load_settings', title: __("Settings") },
            ]}
            onChange={ onChangeData }
        />     */}
        <FieldInput
            editable
            type={SCALAR_TYPES.SLIDER}
            min={3}
            max={103}
            stepSize={1}
            labelStepSize={20}
            vertical={VerticalType.VERTICAL}
            title={__("Count of element per page in feeds")}
            value={pageCount}
            onChange={ ( value: number ) => setPageCount( value ) }
        />   
        <div className="row d-none">
            <div className="col-md-9 offset-md-3">
                <Button
                    large
                    minimal
                    onClick={onSave}
                >
                    {__("Update")}
                </Button>
            </div>
        </div>  
    </div>
} 
export default SettingsForm