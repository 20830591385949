
import { useState } from 'react'  
import Map from './Map' 
import ToposLayoutContainer from '../components/ToposLayoutContainer'
import useEvent from '../../hooks/useEvent'

interface IMapProps {
    onOpenLogin: () => void
}
const MapContainer = (props:IMapProps) :JSX.Element => {
    useEvent("-1")
    const [isReloading, setIsReloading] = useState<boolean>( true )
    return <ToposLayoutContainer isReloading ={isReloading}>
        <Map onOpenLogin={props.onOpenLogin} setIsReloading={setIsReloading} />
    </ToposLayoutContainer> 
} 

export default MapContainer