
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { __ } from "src/libs/utilities"
import FilterForm from "./academyDiscipline/FilterForm"
import { IGameStore, useGameStore } from "../data/store"
import { IAcademicDiscipline } from "../data/interfaces"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import LoginRegisterForm from "src/modules/pe-basic-module/views/profile/LoginRegisterForm"


const VerifyPixels = () : JSX.Element => {  
  const [__html, set__html] = useState<string>("")
  //const [filter, setFilter] = useState<IAcademicDiscipline[]>([] ) 
  const filter: IAcademicDiscipline[] = useGameStore((state: IGameStore) => state.verifyPixelsFilter)
  useEffect(() => {
    fetch("/assets/data/manitu/verifyPixels-ru-RU.html")
      .then( response => response.text() ) 
      .then( html =>  set__html(html) ) 
     
  }, [])

  const user: IUser = useMainStore((state: IState) => state.user)
    if(!user.id) {
        return <div className="w-100 h-100 flex-centered">
            <LoginRegisterForm />
        </div>
    }
  const onFilter = (_filter: IAcademicDiscipline[]) => {
    useGameStore.setState({ verifyPixelsFilter: _filter })
  } 
  
  return <div className="p-4 h-100 overflow-y-auto-thin">
  <div className="container mb-5 d-flex">
      <div className="row w-100">
          <div className="col-md-3">
              <div className="my-0" dangerouslySetInnerHTML={{__html}} />
              <div className="my-3 short-container d-flex flex-column"> 
                  <NavLink className="pe-add-pixel-btn" to="new-test" >
                      {__("First button")}
                  </NavLink>
              </div>
          </div>
          <div className="col-md-9 d-flex flex-wrap flex-column">
            <FilterForm onFilter={onFilter} filter={filter} />        
          </div>
      </div>
  </div>
</div>
}

export default VerifyPixels