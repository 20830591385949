import { IProject } from "@/modules/pe-fest-module/data/interfaces"
import { __, wordTrim } from "@/libs/utilities"
import { Button, EditableText } from "@blueprintjs/core"

/* Protopia Ecosystem component */
export interface IProjectEditCardProps {
    item: IProject
    onDelete: () => void
}
const ProjectEditCard = (props: IProjectEditCardProps): JSX.Element => {
    return <div className='pe-project-edit-card-container'>
        <div 
            className="thumb position-relative"
            style={{ backgroundImage:`url(${props.item.thumbnail})` }}
        >
            <div className="position-absolute bottom right small bg-dark text-light m-2 py-1 px-2">
                {
                    props.item.order
                }
            </div>
        </div>
        <div className="d-flex flex-column flex-grow-100">
            <div className="title"> 
                <EditableText
                    className=" px-3 py-1 w-100"
                    alwaysRenderInput={false} 
                    disabled
                    placeholder="Edit title..."
                    selectAllOnFocus={false} 
                    value={props.item.title}
                /> 
            </div>
            <div 
                className="content" 
                dangerouslySetInnerHTML={{__html: wordTrim( props.item.post_content, 50 ) }}
            />
            <div className="footer"> 
                <Button icon="person" minimal >{ props.item.tutor?.display_name || "Tutor" }</Button>
                <Button icon="person" minimal >{ props.item.leader?.display_name || "Leader" }</Button>
                <Button icon="trash" minimal onClick={props.onDelete}>{__("Delete")}</Button>
            </div>
        </div> 
    </div>
}
export default ProjectEditCard