import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { __ } from "src/libs/utilities"
import { IPETest, PETestQuestion } from "../../data/interfaces"
import { ITestsStore, useTestsStore } from "../../data/store"

const Cover = (): JSX.Element => {
    const genTest: IPETest = useTestsStore((state:ITestsStore) => state.genTest)
    const onField = (value: any, field: keyof PETestQuestion, data: any) => { 
        useTestsStore.setState({
            genTest : {
                ...genTest,
                [field] : value
            }
        })
    }
    return <div className="px-3 w-100">
       <FieldInput
            editable
            field="title"
            title={__("Title")}
            value={genTest.title}
            onChange={onField}
        /> 
        <FieldInput
            editable
            type={SCALAR_TYPES.HTML}
            field="post_content"
            title={__("Description")}
            value={genTest.post_content}
            onChange={onField}
        /> 
        <FieldInput
            editable
            type={SCALAR_TYPES.MEDIA}
            field="thumbnail"
            title={__("Thumbnail")}
            value={genTest.thumbnail}
            onChange={onField}
        />
    </div> 
}

export default Cover