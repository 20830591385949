import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useParams } from "react-router" 
import { __, data_type_feed_url } from "src/libs/utilities"
import { schema } from "src/libs/layouts"
import { LeftLayout, RightLayout, Type } from "src/modules/pe-basic-module/views/singleData/SingleDataTypeForm"
import AdminItemLink from "src/modules/pe-admin-module/views/single/AdminItemLink" 
import { INews } from "../../data/interfaces"
import { actions } from "../../data/actions"
import { GET_NEWS_FEED_ACTION, GET_SINGLE_NEWS_ACTION } from "../../data/actionTypes"  
import Moment from "react-moment"
import { IToposPostsStore, useToposPostsStore } from "../../data/store"
import ToposSingleNews from "./ToposSingleNews"
import { Button, Tag } from "@blueprintjs/core"
import useEvent from "../../hooks/useEvent"

const NewsForm = (props: any) :JSX.Element => {
    useEvent("-1")
    const [item, setItem] = useState<INews>({} as INews)
    const [curNavId, setCurNavId] = useState( "tab0" )
    const params = useParams()
    useEffect(() => {
        actions(GET_SINGLE_NEWS_ACTION, {id: Number(params.id)})
            .then((response: INews | null) => {
                if(response) setItem(response)
            })
    }, [params.id])
    
    const getTitleLayout = () =>
    {  
        return <div className="single-data-type-title">
            { item.title?.toString() }
        </div>
    }
    const getColumnProportia = (): number[] => {
        return [8, 4]
    }
    return <div className="layot-state" data-id={item.id}>
        <div className="single-data-type-thumbnail thumbnail-dark overflow-hidden"  >
            {/* <div className="single-data-type-thumbnail thumbnail-dark position-absolute top left m-0" style={{ backgroundImage: `url(${ item.thumbnail })`, filter: "blur(6px)" }} /> */}
            <div className="single-data-type-thumbnail-titles container position-relative">
                <div className="position-absolute bottom left mb-5">
                    <div className="container ">
                        <img src={item.thumbnail} style={{height:260}} alt={item.title} className="mb-3 border border-dark "/>
                        {
                            item.images?.map(image => {
                                return <img src={image} style={{height:260}} alt={item.title} className="mb-3 border border-dark" key={image}/>
                            })
                        }
                    </div>    
                </div>  
                { getTitleLayout() } 
                <Link className="single-data-type-btn ml-auto" to={ "/" + data_type_feed_url( "News" ) }>
                    { __( "all" ) } { __( schema()[ "News" ].plural ) } 
                </Link> 
            </div>
        </div> 
        <div className={ `container position-relative ` }>
            
            <div className="row">
                <div className={`px-3 col-md-${ getColumnProportia()[0] }`}>
                    <div className="row">  
                        <Type 
                            {...props} 
                            field={ "post_content" } 
                            data={item} 
                        /> 
                        <div className=" ">
                            <div className="my-2 mx-3">
                                {
                                    item.tags?.map(tag => <span key={tag.id} className="mr-1 px-3 py-1 border border-secondary" >
                                        {tag.title}
                                    </span>)
                                }
                            </div> 
                            {
                                item.url &&
                                    <a href={item.url} target="_blank" rel="noreferrer" className=" mx-3">
                                        <Button minimal >
                                            {__("Ins")}
                                        </Button>
                                    </a>
                            } 
                        </div>
                        
                    </div>
                    <div>
                        <LeftLayout {...props} curNavId={curNavId} setCurNavId={setCurNavId} /> 
                    </div>    
                </div>
                <div className={`px-3 col-md-${ getColumnProportia()[1] }`}>
                    <Moment  locale="ru" format="D MMMM YYYY" className={`px-3 display-5 `}>
                       {item.date}    
                    </Moment> 
                    <div className="m-3">
                        <span className="px-3 p-2 bg-dark-secondary text-light border-pill">
                            {item.address}
                        </span> 
                    </div> 
                </div>
                
            </div>
            <div className="position-absolute right top mt-3 mr-4" >
                <AdminItemLink singled_data_type={ "News" } />
            </div>
        </div>            
        <div className={ `bg-secondary-super-light pt-3 mt-3 border-top border-secondary` }>            
            <div className={ `container position-relative  ` }>            
                <div className="row">
                    <div className="col-12">
                        <Tag minimal className="px-3 py-1 small text-uppercase" round> 
                            {__("See also")}
                        </Tag>
                    </div>
                    <MoreNews />
                </div>
            </div>
        </div>
    </div>
}

export default NewsForm

const MoreNews = () :JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const news : INews[] = useToposPostsStore( (state:IToposPostsStore) => state.news )
    useEffect(() => {
        actions(GET_NEWS_FEED_ACTION, { count: 6 }).then( () => setIsLoading( false ) )
      }, [])

    if(isLoading)   return <></> 
    return <>
    {
        news 
            .map( n => <div className="col-xxl-6 col-xl-12 col-lg-6 col-md-12 my-3" key={n.id}>
              <ToposSingleNews item={n} />
            </div> )
    }  
    </>
}
