import { useEffect, useState } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router"
import { NavLink } from "react-router-dom"
import { IPixel } from "src/modules/pe-manitu-module/data/interfaces" 
import { __ } from "src/libs/utilities"
import { IGameStore, useGameStore } from "../data/store"
import MyPixelsForm from "./add-pixels/MyPixelsForm"
import NewTestForm from "./add-pixels/NewTestForm"
import { IAcademicDiscipline } from "../data/interfaces"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import LoginRegisterForm from "src/modules/pe-basic-module/views/profile/LoginRegisterForm"

const AddPixels = () : JSX.Element => {
    const [__html, set__html] = useState<string>("")
    
    const [treasures, setTreasures] = useState<IPixel[]>([])
    // const [filter, setFilter] = useState<IAcademicDiscipline[]>([] ) 
    const filter: IAcademicDiscipline[] = useGameStore((state: IGameStore) => state.myPixelsFilter)
    useEffect(() => {
        fetch("/assets/data/manitu/addPixels-ru-RU.html")
            .then( response => response.text() ) 
            .then( html =>  set__html(html) ) 
            
    }, [])

    const user: IUser = useMainStore((state: IState) => state.user)
    if(!user.id) {
        return <div className="w-100 h-100 flex-centered">
            <LoginRegisterForm />
        </div>
    }

    const onFilter = (_filter: IAcademicDiscipline[]) => {
        useGameStore.setState({ myPixelsFilter: _filter })
    }

    return <div className="p-4 h-100 overflow-y-auto">
        <div className="container mb-5 d-flex">
            <div className="row w-100">
                <div className="col-md-3">
                    <div className="my-0" dangerouslySetInnerHTML={{__html}} />
                    <div className="my-3 short-container d-flex flex-column"> 
                        <NavLink className="pe-add-pixel-btn" to="my-pixels" >
                            {__("my Pixels")}
                        </NavLink> 
                        <NavLink className="pe-add-pixel-btn" to="new-test" >
                            {__("Add new test")}
                        </NavLink> 
                        <NavLink className="pe-add-pixel-btn" to="new-open-problem" >
                            {__("Add new open problem")}
                        </NavLink> 
                    </div>
                </div>
                <div className="col-md-9 d-flex flex-wrap">
                    <Routes>
                        <Route element={<Outlet />} >
                            <Route path="*" element={<Navigate to="my-pixels" />} />
                            <Route path="my-pixels" element={<MyPixelsForm 
                                onFilter={onFilter} 
                                filter={filter} 
                                treasures={ treasures } 
                            /> }  />                      
                            <Route path="new-test/*" element={<NewTestForm />} />
                            <Route path="new-open-problem/*" element={<div className="display-5">NEW OPEN PROBLEM</div>} />
                        </Route>
                    </Routes>
                </div>
            </div> 
        </div>          
    </div>
} 

export default AddPixels