
import { Link } from "react-router-dom"
import { Tag } from "@blueprintjs/core"
import { getSingleRoute } from "src/libs/layouts"
import { IPlace } from "../../data/interfaces"

interface IProps {
    places: IPlace[]
    className: string
}
const PlaceLabel = (props: IProps) : JSX.Element => {
    const routeSingle = getSingleRoute("Place")
    return  <div className={props.className}>
    {props.places?.map( place => {
        const url = routeSingle ? `/${routeSingle.route}/${place.id}` : "/" 
        return <Link to={url} key={place.id} >
           <Tag minimal className="m-1">
                <i className="fas fa-map-marker-alt mr-2"></i>
                <span>{place.title}</span>
            </Tag> 
        </Link>
    })}
</div> 
} 
export default PlaceLabel