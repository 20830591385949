import React from 'react' 
import { ICardProjectProps, IFestival } from '../../data/interfaces'
import { Link } from 'react-router-dom'
import {__} from 'src/libs/utilities'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import MemberRatingDisplay from './MemberRatingDisplay'
import HoneycombsLabel from './HoneycombsLabel'
import TrackLabel from './TrackLabel'
import { getRoute, getSingleRoute } from 'src/libs/layouts' 
import { useOwnerPartner, useUserOwner } from '../../hooks/project'

const SimpleStrokeProject = ( props: ICardProjectProps ): JSX.Element => { 
  const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival) 
  const isOwner: boolean = useUserOwner( props.item )
  const isPartner:boolean = useOwnerPartner(props.item)
  const routeSingle = getSingleRoute("PEFestProject") 
  const url = routeSingle 
    ? 
    `/${ getRoute( routeSingle ) }/${props.item.id}` 
    : 
    "/" 

  return <div className="ml-2 w-100 grey2 position-relative overflow-hidden stroke-a-dark"> 
    <Link to={url} className="row hover">  
      <div  className="col-lg-4 col-10 d-flex  py-2 align-items-center" >  
        <div className="px-4">{ props.item.order } </div>  
        <div>
          <h6  className={`display-7 m-0 flex-grow-100 text-center text-lg-left ${!props.item.is_verified ? "opacity_5" : ""}`} >
            { props.item.title }
          </h6>     
          <div className="text-danger title">{props.item.prize}</div>  
          <div className="text-danger text-uppercase small">{props.item.nomination}</div>  
        </div>       
                          
      </div> 
      <div  className="col-lg-1 col-2 d-flex flex-column justify-content-center align-items-center align-items-lg-left px-3" >            
        <MemberRatingDisplay 
          correct_member_rate={props.item.correctMemberRate}
          countedRating={props.item.r2}
          className={"text-dark "}
        />         
      </div> 
      <div  className="col-lg-3 col-12  d-flex flex-column justify-content-center  px-3" >            
        <div  className="text-overflow"> 
          <TrackLabel 
            track={props.item.track} 
            className="title d-flex my-2 ml-2 text-center text-lg-left align-items-center"
          />
        </div>     
      </div> 
      <div  className="col-lg-2 col-12  d-flex flex-column justify-content-center  px-3" >            
        <div>  <HoneycombsLabel honeycombs={props.item.honeycombs}  className=" d-md-inline my-2 ml-2 text-center text-lg-left"/> </div>      
      </div> 
      <div  className="col-lg-2 col-12 d-flex flex-column justify-content-center" >            
      {
        isOwner
          ?
          <div className="mine-label-stroke">
              { __( "My project" ) }
          </div>
          :                    
          isPartner 
              ?
              <div className="mine-label-stroke bg-success">
                  {__("My honeycombs")}
              </div>
              :
              null
      } 
      {
        props.isMineHoneycoms
          ?
          <div className="mine-honeycombs-label-stroke">
            { __( "My honeycombs" ) }
          </div>
          :
          null
      }         
      </div> 
    </Link> 
  </div>
}

SimpleStrokeProject.propTypes = {}

export default SimpleStrokeProject