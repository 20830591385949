import { IUser, useMainStore } from '@/settings/zustand'
import { isRole } from '@/settings/zustand/user'
import { template } from '@/libs/layouts'
import { Button, Dialog, Icon } from '@blueprintjs/core'
import { LayoutIcon } from '@/libs/useful'
import { __ } from '@/libs/utilities'
import { useEffect, useState } from 'react'
import { Location, useLocation } from 'react-router' 
import { Role } from '@/libs/interfaces/layouts'

const HELP_ICON = "/assets/img/fest/help.svg" 

const getCurrentData = ( location: Location, hData: any ): any  => {
    const curr:any =  Object.keys(hData).filter((hD: any) => {
        return hData[hD].route === "/"
            ?
            location.pathname === "/"
            :
            location.pathname.indexOf( hData[hD].route ) >= 0 
    })
    return hData[ curr[ 0 ] ] || { youtube: [] }
}
const existedVideosByUser = (videos: any ) =>
{    
    const user: IUser = useMainStore.getState().user
    //console.log(user.roles, videos)
    if( !Array.isArray(videos) || !videos.length ) return false
    if(user.id )
    {
        const r =  videos.reduce( (prevous, video) => {
            const summ = typeof video.roles === "undefined" || !video.roles.length 
                ?
                [ ...prevous, ...user.roles ]
                :
                [ ...prevous, ...video.roles ] 
            return summ
            }, [] )
            .filter(( role: Role ) => user.roles.includes( role ) )
        return r.length > 1
    }
    else
    {
        const r = videos
            .filter( video => typeof video.roles === "undefined" || !video.roles.length )
        return r.length > 1
    }
} 
const HelpForm = (props: any):JSX.Element | null => {
    const location:Location = useLocation() 
    const [hData, setHData] = useState<any>({})
    const [currentList, setCurrentList] = useState<any[]>( [] ) 
    const [exists, setExists] = useState<boolean>( false )
    const [isOpen, setOpen] = useState( false )
    const [isRoute, setRoute] = useState( false )
    const [isMenu, setMenu] = useState( exists )
    const [currentVideo, setCurrentVideo] = useState( 
        !Array.isArray(currentList) 
            ? 
            currentList 
            :
            currentList[0]
                ?
                currentList[0]
                : 
                {} 
    )
    const changeOpen = () => {
        setOpen( !isOpen )
        setMenu( Array.isArray(currentList) && currentList.length > 1 )
    }
    const chooseCurrentVideo = (current: any) =>
    {
        setMenu(false)
        setCurrentVideo( current )
    }  
    useEffect(() => { 
        fetch("/assets/data/fest/helpData.json")
            .then(json => {  
                return json.json()
            } )
                .then(resp => {
                    console.log( resp )
                    setHData( resp )
                })
    }, [])
    useEffect(() => { 
        console.log( location )   
        setCurrentList( getCurrentData( location, hData ).youtube )
        setExists( existedVideosByUser( currentList ) )
        setRoute( exists ) 
    }, [ location.pathname ])
    
    return !!exists || true
        ?
        <>
            <div className={`indicator lrg classic p-0 m-0 overflow-hidden ${ isRoute ? "" : " --closed"}`} onClick={ changeOpen }>
                <div className={ `header-menu-element ${ template().header_menu } main-menu-filter-icon ` }  >
                    <LayoutIcon
                        src={ HELP_ICON }
                        className="header-menu-icon mt-sm-0 mt-2"
                        isSVG={false}
                    />
                    <span className='header-menu-label d-md-block d-none'>
                        { __( "Help" ) }
                    </span>
                </div> 
            </div>
            <Dialog
                isOpen={isOpen}
                onClose={changeOpen}
                className=""
            >
                {
                    // Если для Пользователя с его набором ролей доступно больше одного ролика - нужно меню
                    exists 
                        ?
                        isMenu
                            ?
                            <HelpMenu {...props} currentList={currentList} onClick={chooseCurrentVideo}/>
                            :
                            <HelpVideo {...props} currentVideo={currentVideo}/>
                        :
                        <HelpVideo {...props} currentVideo={currentVideo}/>
                }
                <Button
                    minimal
                    onClick={changeOpen}
                    className="position-absolute top dialog-close-btn" 
                >
                    <Icon size={22} color="#ffffff" icon="cross"/>    
                </Button>
            </Dialog>
        </>
        :
        null
} 

export default HelpForm

const HelpVideo = (props:any) : JSX.Element =>
{
    return <div className='flex-centered w-100 h-100'>
        <iframe 
            width="800" 
            height="450" 
            src={ `https://www.youtube.com/embed/${ props.currentVideo.url }?autoplay=1` }
            title={ props.currentVideo.title }  
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen>

        </iframe>
    </div>
}
const HelpMenu = (props:any) : JSX.Element =>
{
    return <div className='pe-fest-help-menu'>
    {
        props.currentList
            .filter( (video: any) => {
                return (!Array.isArray( video.roles ) || video.roles.length === 0 ) ||
                    ( typeof video.roles !== "undefined" && isRole( video.roles ) )
            }) 
            .map((video: any, i: number) =>
            {
                return <Button 
                    key={video.url} 
                    className="lead text-uppercase" 
                    fill 
                    minimal 
                    large 
                    onClick={() => props.onClick( props.currentList[ i ] )}
                >
                    {video.title}
                </Button>
            })
    }
    </div>
}