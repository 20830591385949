import { CSSProperties } from "react"

export interface ISDivProps {
    args?: string
    fn?: string
    label?: string
    className?: string
    style?: CSSProperties
    children?: JSX.Element | null
    onClick?: ( evt: React.MouseEvent<any> | any ) => void
} 
const SDiv = (props: ISDivProps) : JSX.Element => {
  return (
    <div 
        className={props.className} 
        style={props.style}
        context-title={props.label}   
        context-fn={props.fn}   
        context-args={ props.args }  
        onClick={props.onClick} 
    >
        {props.children}
    </div>
  )
}  
export default SDiv

 
declare module 'react' {
    interface HTMLProps<T> {
        ["context-fn"]?: string
        ["context-title"]?: string
        ["context-args"]?: string
    }
}