
import { ID, Icon, URL } from "@/libs/interfaces/layouts";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { IFabulaEpisode, IFabulaHero, IFabulaMedia, IPEFabula, IPEStory } from "./interfaces";

export interface IStorytellingStore { 
    fabula: IPEFabula
    current: IPEStory
    currentEpisode: IFabulaEpisode
    currentMedia: IFabulaMedia
    hero: IFabulaHero
    timer: number
    isPaused: boolean
    isMenuOpen: boolean
    isMapOpen: boolean
    isSettingsOpen: boolean
    isBreakOpen: boolean
    isQuestOpen: boolean
    executeStuff: ID
    audioVolume: number 
    voices: boolean
    music: boolean
    effects: boolean
    hunger: () => void
}

export const useStorytellingStore: any = create( 
    devtools( 
        persist<IStorytellingStore>( 
            (set: any, get: any ) => ({ 
                fabula: {} as IPEFabula,
                current: {} as IPEStory,
                hero: {} as IFabulaHero,
                currentEpisode: {} as IFabulaEpisode,
                currentMedia: {} as IFabulaMedia,
                timer: 0,
                isPaused: false,
                isMenuOpen: false,
                isMapOpen: false,
                isSettingsOpen: false,
                isBreakOpen: false,
                isQuestOpen: false,
                executeStuff: "-1",
                audioVolume: .75, 
                voices: true,
                music: true,
                effects: true,
                hunger: () =>  set( ( state: IStorytellingStore ) => ({ 
                    ...state,
                    hero: {
                        ...state.hero,
                        currentFullness: state.hero.currentFullness 
                            ? 
                            state.hero.currentFullness - ( 1 / 60 ) 
                            : 
                            0
                    }
                }) )
            }),
            {
                name: 'pe-fabula-storage', 
                storage: createJSONStorage( () => localStorage ), 
            } 
        )
    )
)
