import { useEffect, useState } from "react"
import { IRSSItem } from "../../data/interfaces"
import parseRSS from "./parseRSS"
import { Button, Callout } from "@blueprintjs/core"
import { Loading } from "src/libs/useful"
import { __ } from "src/libs/utilities"

const ScriniariiForm = (props:any) :JSX.Element => {
    const [ rss, setRss ] = useState<string[]>(
        Array.isArray(props.extend_params?.src)
            ?
            props.extend_params?.src
            :
            []
    )
    const [ items, setItems ] = useState<IRSSItem[]>([]) 
    const [ isload, setIsload ] = useState<boolean>(false) 
    const [ currentFeed, setCurrentFeed ] = useState<number>(0)
    useEffect( () => { 
        setRss(
           Array.isArray(props.extend_params?.src)
            ?
            props.extend_params?.src
            :
            []  
        ) 
        parseRSS( rss[ currentFeed ][0] )
            .then((resp => {
                console.log( resp )
                setItems( resp )
            }))
    }, [])
    useEffect( () => {  
        // rss.forEach((src:URL, i: number) => { 
        //     parseRSS(src[0])
        // }) 
    }, [ currentFeed ] )

    const onRss = (i:number) => {
        setCurrentFeed( i )
        setIsload(true)
        console.log(rss[ i ])
        parseRSS( rss[ i ][ 0 ] )
            .then((resp => { 
                setIsload(false)
                console.log( resp )
                setItems( resp )
            }))
    }
    return <div className=" container ">
        <div className="row">
            <div className="col-md-3">
                <div className="d-block w-100 h-100">
                    <div className="position-sticky top pt-3">
                    {
                        rss.map((r, i) => <Button 
                            className="stretch"
                            minimal={ i !== currentFeed } 
                            fill 
                            onClick={ () => onRss(i) }
                            key={ i }
                        >
                            <div className="d-flex w-100">
                                {i}. { r[1] }
                            </div>
                        </Button>)
                    }    
                    </div> 
                </div>
                
            </div>
            <div className="col-md-9">
                <div className="row">
                {
                    isload
                        ?
                        <Loading />
                        :
                        !!items.length
                            ?
                            items.map((item, i) => {
                                return <div className="col-md-6" key={item.title + "_" + i}>
                                    <img src={item.enclosure?.url} loading="lazy" alt={item.enclosure?.type} />
                                    <div className="lead">{item.title}</div>
                                    <div className="mt-3" dangerouslySetInnerHTML={{__html: item.description || ""}} />
                                    <a href={item.link} className="btn btn-link" target="_blank" rel="noreferrer">
                                        {item.channel?.title || "Link"}
                                    </a>
                                </div>
                            })
                            :
                            <Callout className="p-4">
                                {__("No elements exists")}
                            </Callout>

                }     
                </div>                
            </div>           
        </div> 
    </div> 
} 

export default ScriniariiForm