import { Button, ButtonGroup, Dialog, Intent, Tab, Tabs, Collapse } from "@blueprintjs/core"
import React, { useState } from "react"
import { roles } from "src/libs/layouts" 
import FieldInput from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import PageTypeSettings from "./PageTypeSettings"

const EditPageDialog = (props: any): JSX.Element =>
{
    const [navbarTabId, setNavbarTabId] = useState("settings") 
    const {
        data, 
        onEditOpen,
        onField,
        onPageTypeField,
        onSaveRote,
        isEditOpen,
        setEditOpen,
        jsonData,
        dialogTitle,
        saveButtonTitle
    } = props
    
    const checkPageType = () =>
    {
        return ["html", "component"].filter(type => { 
            return typeof data[type] !== "undefined" 
        })[0]
    }
    return <Dialog
        isOpen={isEditOpen}
        onClose={onEditOpen} 
        title={ __( dialogTitle ) }
        className="full-height-dialog"
    >   
        <div className="page-setting-sub-container">
            <div className="page-setting-container">
                <Tabs
                    className="d-flex flex-column align-items-center tab-light-head w-100 " 
                    selectedTabId={navbarTabId}
                    vertical={false}
                    large={true}
                    onChange={ (nb: string) => setNavbarTabId( nb ) }
                >  
                    <Tab 
                        id="settings"  
                        panelClassName="w-100 h-500"
                        title={ __( "Settings" ) }
                        panel={ <>
                            <div className=" setting-tab-panel overflow-y-auto">
                                <FieldInput
                                    value={ data.title} 
                                    field="title" 
                                    title={__("Title")}
                                    commentary={__("Как эта страница будет отображаться в разных меню?")}
                                    onChange={ onField } 
                                />
                                <FieldInput
                                    value={data.route} 
                                    type="string"
                                    commentary={ __( "Адрес страницы внутри сайта" ) }
                                    field="route" 
                                    prefix={`${window.location.origin}/`}
                                    title={__("Route")}
                                    onChange={ onField } 
                                /> 
                                <FieldInput
                                    value={data.icon} 
                                    type="icon"
                                    commentary={ __( "Icon of this page in menus" ) }
                                    field="icon"  
                                    title={__("Icon")}
                                    onChange={ onField } 
                                /> 
                                <FieldInput
                                    value={data.thumbnail} 
                                    type="media"
                                    field="thumbnail" 
                                    title={__("Thumbnail")}
                                    commentary={__("Идентифицирует страницу в кабинете редактора. Тспользуется в некоторых компонентах для отрисовки контента")}
                                    onChange={ onField } 
                                />
                                <FieldInput
                                    value={data.description} 
                                    type="html"
                                    field="description" 
                                    title={__("Description")}
                                    commentary={__("Это описание будет видно в некоторых метсах в панели администрирования сайтом")}
                                    onChange={ onField } 
                                />
                            </div> 
                        </>
                                            
                        }
                    /> 
                    <Tab 
                        id="type"  
                        title={__("Page type settings")}
                        panelClassName="w-100 h-500"
                        panel={
                            <div className="setting-tab-panel overflow-y-auto">
                                <div className="strob01" /> 
                                <FieldInput
                                    value={checkPageType()}
                                    type="radio"
                                    field="type" 
                                    title={__("Content type")}
                                    values={[ 
                                        {
                                            _id:"component",
                                            title:"Special component"
                                        },
                                        {
                                            _id:"html",
                                            title:"Simple editible html-content"
                                        }
                                    ]}         
                                    onChange={ onPageTypeField }
                                />
                                <Collapse
                                    isOpen={checkPageType() === "component"}
                                >
                                    <PageTypeSettings
                                        data={data}
                                        onField={onField}
                                    />
                                </Collapse>
                                <Collapse
                                    isOpen={checkPageType() === "html"}
                                >
                                    <FieldInput
                                        value={ data.html }
                                        type="html"
                                        field="html" 
                                        title={__("Content")}
                                        onChange={ onField } 
                                    />
                                </Collapse>
                                
                            </div>
                        }
                    />   
                    <Tab 
                        id="SEO"  
                        title={__("SEO")}
                        panelClassName="w-100 h-500"
                        className="hidden"
                        panel={
                            <div className="setting-tab-panel overflow-y-auto">
                                SEO
                            </div>
                        }
                    />   
                    <Tab 
                        id="menu"  
                        title={__("Menu")}
                        panelClassName="w-100 h-500"
                        className=""
                        panel={
                            <div className="setting-tab-panel overflow-y-auto">
                                <FieldInput
                                    value={ props.menu }
                                    field="menu" 
                                    title={__("Menu choose")}  
                                    onChange={ onField } 
                                    editable 
                                />
                            </div>
                        }
                    /> 
                    <Tab 
                        id="permissions"  
                        title={__("Permissions")}
                        panelClassName="w-100 h-500"
                        panel={
                            <div className="setting-tab-panel overflow-y-auto">
                                <FieldInput
                                    title={__("Hidden for unlogged")}
                                    type="boolean"
                                    field="islogged"
                                    editable 
                                    value={ data.islogged } 
                                    id="islogged"
                                    onChange={onField}
                                    />
                                <FieldInput
                                    title={__("Exlusive access by role")}
                                    type="checkbox"
                                    field="capability"
                                    editable 
                                    value={ data.capability || [] }
                                    values={roles()}
                                    id="contenttype"
                                    onChange={ onField }
                                />
                            </div>
                        }
                    />
                    <Tab 
                        id="actions"  
                        title={__("Actions")}
                        panelClassName="w-100 h-500"
                        className="hidden"
                        panel={
                            <div className="setting-tab-panel overflow-y-auto">
                                Actions
                            </div>
                        }
                    />
                    <Tab 
                        id="additionally"  
                        title={__("Additionally")}
                        panelClassName="w-100 h-500"
                        panel={
                            <div className="setting-tab-panel overflow-y-auto">
                                <FieldInput
                                    value={ data.is_left }
                                    type="boolean"
                                    field="is_left" 
                                    title={__("Show left menu on Page")}
                                    onChange={ onField } 
                                />
                                <FieldInput
                                    value={ data.help_url }
                                    type="url"
                                    field="help_url" 
                                    title={__("Help source")}
                                    onChange={ onField } 
                                />
                            </div>
                        }
                    /> 
                </Tabs>
            </div>
            <div className="mt-2">
                <ButtonGroup fill large>
                    <Button 
                        intent={ Intent.SUCCESS }  
                        className={`flex-grow-100 p-3 ${ JSON.stringify(data) === jsonData ? " bg-secondary opacity_5 " : "" }`}
                        onClick={ onSaveRote }    
                    >
                        {__(saveButtonTitle)}
                    </Button>      
                    <Button 
                        intent={ Intent.DANGER } 
                        icon="cross" 
                        className="p-3"
                        onClick={ () => setEditOpen(false) }
                    />      
                </ButtonGroup>
            </div>
        </div>
    </Dialog>
}
export default EditPageDialog