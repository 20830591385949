import React, { useState } from "react" 
import { ChromePicker, ColorResult } from "react-color" 
 

const ColorPicker = (props: any) :JSX.Element => { 
	const [color, setColor] = useState<any>( props.color )
	const onChange = (_color: ColorResult ) => { 
		const clr: string = `${
			_color.rgb.a !== 1
				?
				_color.hex + Number(( _color.rgb.a || 0 ) * 256 ).toString(16).split(".")[0] 
				:
				_color.hex
		}`
		//console.log( clr, _color )
		props.onChoose( clr )
		setColor(clr)
	}
  	return <div className="m-0"> 
		<ChromePicker color={ color } onChange={ onChange } />
  	</div> 
}
 

export default ColorPicker
