import { IVideo, VIDEO_SOURCE } from "../interfaces"

export const videos = () : IVideo[] => {
    return [
        {
            id: "456239087",
            title: "Сила игры",
            post_content: `✍Я не очень люблю выступать на конференциях. И, слава богу, теперь это у меня не обязанность, а просто я иногда соглашаюсь, либо потому что интересная тема, либо возможность узнать что-то новое, или познакомиться с какими-то людьми...
            <p>
            И вот сегодня вела круглый стол по играм на большой конференции Сила игры. Безумно хотела пригласить много -много людей, потому что по теме игре есть что сказать, поспорить и помечтать...`,
            date: "",
            thumbnail: "https://images.unsplash.com/photo-1696520644831-4a1193a9e0d7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",

            tags:[],
            link: "https://vk.com/video_ext.php?oid=-221144320&id=456239087&hash=9c0e62004b8a5083",
            type: VIDEO_SOURCE.VK
        },
        {
            id: "VopBpKEA0VE",
            //id: "jwyp8c4PjcQ",
            title: "Я пройду по лесу тихо-тихо, не спеша...",
            post_content: "",
            date: "",
            thumbnail: "https://images.unsplash.com/photo-1691786063311-defbb52b6056?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2080&q=80",
            tags:[],
            link: "https://www.youtube.com/embed/jwyp8c4PjcQ?i=0",
            type: VIDEO_SOURCE.YOUTUBE
        },
        {
            id: "5074103503541",
            title: "Видео от Топос. Краеведение",
            post_content: "",
            thumbnail: "https://images.unsplash.com/photo-1547702128-9ba861bc847d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8N3wyMzExMzA3fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            date: "",
            tags:[],
            link: "https://vk.com/video_ext.php?oid=-191335239&id=456239257&hash=daa65b4d167021e1",
            type: VIDEO_SOURCE.VK
        },
        {
            id: "8I8vwckALF4",
            title: "Трек «Топос. Краткий метр». Анонс. 2023",
            post_content: `Межрегиональная просветительская краеведческая программа «Топос. Краефест»
            <p>Марафон "Что мы соТворили?!" 2023
            <p>Трек "Топос. Краткий метр". Куратор трека - гл. редактор видеожурнада "Говорю как краевед" Мария Михайловна Ленская`,
            thumbnail: "https://i.ytimg.com/vi_webp/8I8vwckALF4/maxresdefault.webp",
            date: "",
            tags:[],
            link: "https://www.youtube.com/embed/8I8vwckALF4?i=0",
            type: VIDEO_SOURCE.YOUTUBE
        },
        {
            id: "aVdJnV48MeY",
            title: "Трек «Исследовательская публицистика/Путевые заметки» (журнальный)",
            post_content: `Трек «Исследовательская публицистика/Путевые заметки». Куратор трека - главный редактор журнала «Исследователь/ Researcher» А.С. Обухов`,
            thumbnail: "https://i.ytimg.com/vi/aVdJnV48MeY/maxresdefault.jpg",
            date: "",
            tags:[],
            link: "https://www.youtube.com/embed/aVdJnV48MeY?i=0",
            type: VIDEO_SOURCE.YOUTUBE
        },
        {
            id: "PDO92OIwTVI",
            title: "Редкое видео. Незаписанный спектакль «Голый король» театра «Современник». Фрагмент (1977)",
            post_content: `Встречи с Евгением Евстигнеевым. Фрагмент спектакля театра "Современник" по пьесе Е.Шварца "Голый король". 
            <p>Во фрагменте заняты актеры - Е.Евстигнеев, И.Кваша, О.Табаков, В.Хлевинский и др.
            <p>Фрагмент передачи "Встречи с Евгением Евстигнеевым"`,
            thumbnail: "https://i.ytimg.com/vi/PDO92OIwTVI/hqdefault.jpg",
            date: "",
            tags:[],
            link: "https://www.youtube.com/embed/PDO92OIwTVI?i=0",
            type: VIDEO_SOURCE.YOUTUBE
        },
    ]
}