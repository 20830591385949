import React from 'react' 
import BasicState from "@/libs/basic-view"   
import ModerationForm from './moderation/ModerationForm'
import PhaseLabel from './PhaseLabel'

class ModerationView extends BasicState {
    props: any
    addRender = () => {
        return <>
            <ModerationForm {...this.props} />
            <div className="position-absolute top" >
                <PhaseLabel /> 
            </div>
        </> 
    }
} 

export default ModerationView