import { Route, Routes, useParams } from 'react-router' 
import About from './About'
import AboutContainer from './AboutContainer' 
 
import { ID } from 'src/libs/interfaces/layouts' 
import { useFestival } from 'src/modules/pe-fest-module/hooks/festival'
import { Loading } from 'src/libs/useful' 

const AboutForm = ( ) : JSX.Element => {
    const params = useParams()
    const eventId: ID = params.id || ""
    const isFestLoading = useFestival( true, eventId ) 
  
 
    if( isFestLoading ) return <div className="h-100 w-100 flex-center">
        <Loading />
    </div>  
    return  <>
        <Routes>
            <Route element={<AboutContainer />} > 
                <Route index element={<About />} /> 
            </Route> 
        </Routes> 
    </> 
} 

export default AboutForm