const { useSwiper } =  require("swiper/react") 

/* Protopia Ecosystem component */
export interface INextButtonProps {
    onClick: () => void
}
const NextButton = (props: INextButtonProps): JSX.Element => {
    const swiper = useSwiper()
    const onClick = () => { 
        swiper.slideNext()
        props.onClick()
    }
    return <div className='pe-next-button-container' onClick={onClick} >
        <i className="fas fa-chevron-right" />
    </div>
}
export default NextButton