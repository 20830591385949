import { ICritery, IProject, IRating } from '@/modules/pe-fest-module/data/interfaces'
import { IFestProjectsProps, useFestProjects } from '@/modules/pe-fest-module/data/store'
import { Slider } from '@blueprintjs/core'
import React, { useState } from 'react' 
import CriteryCommentaryDialog from '../projectData/CriteryCommentaryDialog'
import { actions } from '@/modules/pe-fest-module/data/actions'
import { UDATE_PROJECT_RATING_ACTION } from '@/modules/pe-fest-module/data/actionTypes'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import { updateCurrentRating } from '@/modules/pe-fest-module/data/updateCurrentRating'
import { useParams } from 'react-router'

interface IProps { 
    critery: ICritery
    maxRating: number
    rating: IRating
}
const ExpertSlider = (props: IProps): JSX.Element => {
  const {landId} = useParams() 
  const user:IUser = useMainStore( ( state:IState ) => state.user )
  const project: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject) 
  const setIsBlockedUpdate: Function = useFestProjects.getState().setIsBlockedUpdate 

  const [rate, changeRate] = useState<number>( props.rating?.rating )
  const [isOpen, setIsOpen] = useState( false )
  const [description, setDescription] = useState( props.rating?.description || "" )
  
  const changeRating = (rating: number) => {
    changeRate(rating)    
    updateCurrentRating( rating, props.rating )
  }

  const releaseRating = (rating: number) => {
    changeRate(rating)
    updateCurrentRating( rating, props.rating )
    onOnlyOpen()
  }
  
  const onOnlyOpen = () => {
    setIsOpen(true)
    setIsBlockedUpdate(true)
  }
  const onClose = () => {
    setIsOpen(false)
    setIsBlockedUpdate( false )
  }

  const onSend = (description:string) => { 
    setDescription( description )
    sending()
  }
  const sending = () => {
    const data: any = {
        id: "-1",
        item : { 
            expertId: user.id,
            display_name: user.display_name,
            criteryId: props.critery.id,
            memberId: project.id,
            description,
            rating: rate  
        }, landId
    }
    console.log( data )
    actions( UDATE_PROJECT_RATING_ACTION, data )
}

  const stepSize: number = Math.floor( props.maxRating / 100 < 1 ? 1 : props.maxRating / 100 )

  return <div className="mr-4"> 
    <Slider
        min={ 0 }
        max={ props.maxRating }
        stepSize={ stepSize }
        labelStepSize={ stepSize > 10 ? stepSize * 10 : 1 }
        onChange={changeRating }
        onRelease={releaseRating }
        value={ Number(rate) || 0 } 
        vertical={ false }
    />
    <CriteryCommentaryDialog
        description={ description }
        isOpen={ isOpen }
        onOpen={ onClose }
        lockWithoutComment={ props.maxRating === rate }
        onSend={onSend}
        sendLabel={"send label"}
        isHideChangeDescr={false}
    />
  </div>
} 

export default ExpertSlider