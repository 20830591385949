import IScalarForm from "src/libs/scalars/interface/IScalarForm"
import Scalar from "src/libs/scalars/scalars/Scalar"
import { Button, Tag } from "@blueprintjs/core"
import { useState } from "react"
import { PETestQuestionAnswer } from "../data/interfaces"
import Answer from "./answers/Answer"
import { __ } from "src/libs/utilities"
import {default as UUID} from "node-uuid";

const TestQuestionAnswerWidget = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<AnswerEnabled {...props} />}
        desabledForm={<AnswerDesabled {...props} />}
    />
}

export default TestQuestionAnswerWidget

const AnswerEnabled =  (props: IScalarForm) :JSX.Element => {
    const [currentAnswer, setCurrentAnswer] = useState<PETestQuestionAnswer>({} as PETestQuestionAnswer)
    const [answers, setAnswers] = useState<PETestQuestionAnswer[]>([])
    const onChangeAnswer = (
        value: any, 
        field: string, 
        data: any
    ) => {

    }
    const onAdd = () => {
        const _answer = {
            id: UUID.v4(),
            title: `Answer ${answers.length}`,
            post_content: "",
            is_subquestion: false,
            order: answers.length
        }
        setAnswers([
            ...answers, 
            _answer
        ])
        setCurrentAnswer( _answer )
    }
    const onChoose = (_answer: PETestQuestionAnswer) => {
        setCurrentAnswer( _answer )
    }
    const onRemove =(i:number) => {
        setAnswers( answers.filter((a, n) => i !== n) )
    }
    
    return <div className="my-2 d-flex">
        <div className=" w_350 mr-2 ">
            {
                answers.map((a:any, i) => {
                        return <div className="d-flex">
                            <Button 
                                key={a.id} 
                                minimal 
                                className="p-1 w-100 m-1 text-overflow flex-grow-100 "
                                onClick={() => onChoose( a ) }
                            >
                                {
                                    a.title
                                }
                            </Button>
                            <Button 
                                minimal 
                                alignText="left" 
                                onClick={() => onRemove(i)} 
                                className="p-1 pl-2 ml-1 my-1"
                            >
                                <i className="far fa-trash-alt " />
                            </Button>  
                        </div>
                    })
            }
            <Button minimal alignText="left" onClick={onAdd} className="py-1 px-3 w-100">
                <i className="fas fa-plus mr-2" />{__("add question")}
            </Button> 
        </div>
        <div className="flex-grow-100 w-100">
            <Answer item={currentAnswer} onChange={onChangeAnswer}/>
        </div>
    </div>
}

const AnswerDesabled =  (props: IScalarForm) :JSX.Element => {
    return <div>
        {
            Array.isArray(props.value) 
                ?
                props.value.map((a:any) => {
                    return <Tag key={a.id} minimal>
                        {
                            a.title
                        }
                    </Tag>
                })
                :
                <></>
        }
    </div>
}