import { __ } from "src/libs/utilities"
import { ICellDescriptionProps, IManituAction } from "../../../data/interfaces"
import { Tab, Tabs } from "@blueprintjs/core"
import { useState } from "react"
import { Dummy } from "src/libs/useful" 
import Moment from "react-moment"

 
const CellMineDescription = ({organoid, actions, ...props}: ICellDescriptionProps) : JSX.Element => {
    const [tabId, setTabId] = useState<string>("organoid")
    const handleTabChange = (tabId:string)=> {
        setTabId( tabId )
    }
    const size : number = 60
    return <div className="position-relative"> 
        <Dummy width={100} height={100} />
        <div className="d-flex w-100 h-100 position-absolute top left">
            <Tabs selectedTabId={tabId} onChange={ handleTabChange } vertical className="w-100">
                <Tab
                    id={ "organoid" } 
                    className="p-0 m-0 w-100"
                    title={ <div className="flex-centered flex-column w-100 px-3 ">
                        <img 
                            src="/assets/img/manitu/icons/organoids.svg" 
                            alt="organoid" 
                            width={size}
                            height={size}
                            className="p-1"
                        /> 
                        <div>{__("Organoid")}</div>
                    </div>}
                    panel={ <OrganoidTab organoid={organoid} /> }
                />
                <Tab
                    id={ "actions" } 
                    className="p-0 m-0 w-100"
                    title={ <div className="flex-centered flex-column w-100 px-3 ">
                        <img 
                            src="/assets/img/manitu/icons/actionTab.svg" 
                            alt="organoid" 
                            width={size}
                            height={size}
                            className="p-1"
                        /> 
                        <div>{__("Medical card")}</div>
                    </div> }
                    panel={ <ActionsTab organoid={organoid} actions={actions}/> }
                />
                <Tab
                    id={ "defense" } 
                    className="p-0 m-0 w-100"
                    title={ <div className="flex-centered flex-column w-100 px-3 ">
                        <img 
                            src="/assets/img/manitu/icons/defenseTab.svg" 
                            alt="defense" 
                            width={size}
                            height={size}
                            className="p-1"
                        /> 
                        <div>{__("Define")}</div>
                    </div> }
                    panel={ <DefenseTab organoid={organoid} actions={actions}/> }
                />
            </Tabs>
        </div>
    </div>
     
} 
export default CellMineDescription

const OrganoidTab = ({organoid}:any) :JSX.Element => {
    return <div className=" w-100">
        <div className="d-flex w-100">
            <img 
                src={ organoid?.type?.icon } 
                width={90} 
                height={90} 
                alt={ organoid?.title } 
            />
            <div>
                <div className="py-2 pl-4 pr-5 lead">
                    {__( organoid.title || "Empty" )}
                </div>
                <div className="pb-2 pl-4 pr-5">
                    {__( organoid.post_content )}
                </div>
            </div> 
        </div>
    </div>
}
const ActionsTab = ({organoid, actions}:any) :JSX.Element => {
    return <div className=" w-100 h-100 d-flex flex-column "> 
        <div className="pt-2 pl-4 pr-5 lead">
            {__( "Medical card" )}
        </div> 
        <div className="pb-2 pl-4 pr-5">
            {__( "history of actions by cell" )}
        </div>
        <div className="w-100 h-100 overflow-y-auto px-2">
            <div className=" ">
                {actions.map((action: IManituAction) => {
                    return <Action key={action.id} action={action} />
                })}
            </div>
        </div>
    </div>
}

interface IActionProps {
    action: IManituAction
}
const Action = ( {action}: IActionProps ) : JSX.Element => { 
    return <div>
        <div className="d-flex align-items-center p-2 w-100">
            <div className={action.type.icon } style={{fontSize: 30, paddingRight:10 }}/> 
            <div className=" flex-grow-100">
                <div className="title">
                    {action.type.title} 
                    <span className="ml-2 px-1 p-0 bg-danger">{action.count}</span>
                </div>
                <div className="small">
                    {action.type.post_content}
                </div>
                <Moment locale="ru" format="D MMMM YYYY LTS" >
                    {action.time}
                </Moment>
            </div>
            <div 
                className="w_55 h_55 ml-3 rounded-circle" 
                style={{backgroundImage:`url(${ action.opponent.avatar })`}}
            />
        </div>
        <div className=" p-2 w-100 small">
            {/* {JSON.stringify( action )} */}
            
        </div>
    </div>
}

const DefenseTab = (props:any): JSX.Element => {
    return <div className=" w-100 h-100 d-flex flex-column "> 
        <div className="pt-2 pl-4 pr-5 lead">
            {__( "Define" )}
        </div> 
        <div className="pb-2 pl-4 pr-5">
            {__( " Define " )}
        </div>
        <div className="w-100 h-100 overflow-y-auto px-2">
            <div className=" ">
                
            </div>
        </div>
    </div>
}