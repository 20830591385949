import { Button } from "@blueprintjs/core"
import { IManitu, useManitu } from "src/modules/pe-manitu-module/data/store"

const UnderMatrixClose = () : JSX.Element => {
    const isActionChoose: boolean = useManitu((state:IManitu) => state.isActionChoose)
    
    const onCansel = () => {
        useManitu.setState({ choosedAction: null })
        useManitu.setState({ isActionChoose: false })
    }
    return <div 
        className={ `pe-manitu-undermatrix-close-collapse ${ isActionChoose ? "open" : "" }` }
    >
        <Button  
            minimal 
            className="ml-2" 
            onClick={onCansel}
        > 
            <i className="fas fa-times px-1 py-2  text-danger" />
        </Button>
    </div> 
} 

export default UnderMatrixClose