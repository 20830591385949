
/* Protopia Ecosystem component */
export interface ILabyrinthSettingsFormProps {

}
const LabyrinthSettingsForm = (props: ILabyrinthSettingsFormProps): JSX.Element => {
    return <div className='pe-labyrinth-settings-form-container'>
        <div className="display-6">
            Labyrinth Settings Form
        </div>
    </div>
}
export default LabyrinthSettingsForm