import React, { useState } from 'react'
import { __ } from "@/libs/utilities"
import { AppToaster } from "@/libs/useful"
import actions from '../../data/actions'
import { RESET_PASSWORD_ACTION } from '../../data/actionTypes'
import { Intent, Position } from '@blueprintjs/core'


const ResetPasswordForm = (props: any): React.ReactElement => {
    const [password, setPassword] = useState("")
    const [passwordNew1, setPasswordNew1] = useState("")
    const [passwordNew2, setPasswordNew2] = useState("") 
    
    const onReset = (evt: any) => {
        evt.preventDefault() 
        if( passwordNew1 === passwordNew2)
            actions( RESET_PASSWORD_ACTION, { password, passwordNew: passwordNew1 } )
        else 
        {
            AppToaster.show({
                intent: Intent.DANGER,
                // position: Position.BOTTOM_RIGHT,
                message: __("Fields «New password» and «Repeat new password» must be equal.")
            })
        }
    }
    return <form onSubmit={ onReset }>
        <div className="form-group">
            <label htmlFor="exampleInputPassword1">
                {__("Old Password")}
            </label>
            <input
                type="password"
                className="form-control"
                placeholder={__("Insert old password")}
                value={password}
                onChange={evt => setPassword( evt.target.value ) }
            />
        </div>
        <div className="form-group">
            <label htmlFor="exampleInputPassword1">
                {__("New password")}
            </label>
            <input
                type="password"
                className="form-control"
                placeholder={__("Insert new password")}
                value={passwordNew1}
                onChange={evt => setPasswordNew1( evt.target.value )}
            />
        </div>
        <div className="form-group">
            <label htmlFor="exampleInputPassword1">
                {__("Repeat new password")}
            </label>
            <input
                type="password"
                className="form-control"
                placeholder={__("Repeat new password")}
                value={passwordNew2}
                onChange={evt => setPasswordNew2( evt.target.value )}
            />
        </div>
        <input type="submit" className="btn btn-primary py-2 px-5 rounded-pill" value={__("Change")} />
    </form> 
}

export default ResetPasswordForm