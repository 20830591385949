
import {  DocumentNode } from "graphql"
import { useAppoloStore, useMainStore } from "../../../settings/zustand" 
import { 
    getChangeName,
    getInputTypeName,
    getMutationArgs,
    getQueryArgs, 
    getQueryName, 
    mutationEdit, 
    querySingle, 
    querySingleName, 
} from "src/libs/layouts" 
import {  
    GET_FABULA_ACTIONS, GET_STORY_ACTIONS, MOVE_HERO_ACTION
} from "./actionTypes"  
import { PEFabula } from "./mocks/PEFabule"
import { useStorytellingStore } from "./store"
import { IPEStory } from "./interfaces"
const { gql } = require("@apollo/client/core")

const asyncControllers =  async(action:string, data: any) => {
    let response : any, 
        query : DocumentNode,
        query_name : string, fields : any
        
    const apolloClient: any = useAppoloStore.getState().client
    switch( action )
    {
        case GET_FABULA_ACTIONS:   
            response = new Promise<any>( (resolve, reject) => { 
                resolve({
                    getPEFabula : PEFabula(),          
                })
            })
            return response   
        case GET_STORY_ACTIONS:   
            response = new Promise<any>( (resolve, reject) => { 
                const fabula = PEFabula()
                resolve({
                    getPEStory : fabula.PEStory.filter((s: IPEStory) => s.id === data.id)[ 0 ],          
                })
            })
            return response   
        case MOVE_HERO_ACTION:   
            response = new Promise<any>( (resolve, reject) => { 
                resolve({ })
            })
            return response   
        default: 
            return  new Promise<any>( (resolve, reject) => { resolve(`Unknown action type: ${action}`)} )
    }
}
export default asyncControllers