
import BasicExtendView from '@/libs/basic-view/BasicExtendView' 
import SchemaForm from "./schema/SchemaForm"
export class AdminSchemaView extends BasicExtendView {  
    props: any
    center = ():JSX.Element => {  
       return <SchemaForm />
    }
}

export default AdminSchemaView 