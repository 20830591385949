

import { ID, Icon, URL } from "src/libs/interfaces/layouts";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { IAcademicDiscipline } from "./interfaces";

export interface IGameStore {
    myPixelsFilter: IAcademicDiscipline[],
    verifyPixelsFilter: IAcademicDiscipline[]
}
export const useGameStore: any = create( 
    devtools( 
        persist<IGameStore>( 
            (set: any, get: any ) => ({ 
                myPixelsFilter:[],
                verifyPixelsFilter:[]
            }),
            {
                name: 'pe-game-storage', 
                storage: createJSONStorage( () => localStorage ), 
            } 
        )
    )
)
