import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { schema } from "src/libs/layouts"
import { __, data_type_feed_url } from "src/libs/utilities"
import AdminItemLink from "src/modules/pe-admin-module/views/single/AdminItemLink"
import { LeftLayout, RightLayout, Type } from "src/modules/pe-basic-module/views/singleData/SingleDataTypeForm"
import { GET_SINGLE_PLACE_ACTION } from "../../data/actionTypes"
import { actions } from "../../data/actions"
import { IPlace } from "../../data/interfaces"
import { Gallery } from "../components/gallery/Gallery"

const PlaceForm = (props: any) :JSX.Element => {
    const [item, setItem] = useState<IPlace>({} as IPlace)
    const [curNavId, setCurNavId] = useState( "tab0" )
    const params = useParams()
    useEffect(() => {
        actions(GET_SINGLE_PLACE_ACTION, {id: Number(params.id)})
            .then((response: IPlace | null) => {
                console.log( response, Number(params.id) )
                if(response) setItem(response)
            })
    }, [])
    
    const getTitleLayout = () =>
    {  
        return <span className="single-data-type-title">
            { item.title?.toString() }
        </span>
    }
    const getColumnProportia = (): number[] => {
        return [8, 4]
    }
    return <div className="layot-state">
        <div className="single-data-type-thumbnail" style={{ backgroundImage: `url(${ item.thumbnail })` }}>
            <div className="single-data-type-thumbnail-titles container ">  
                { getTitleLayout() } 
                <Link className="single-data-type-btn ml-auto" to={ "/" + data_type_feed_url( "Place" ) }>
                    { __( "all" ) } { __( schema()[ "Place" ].plural ) } 
                </Link> 
            </div>
        </div>
        <div className={ `container position-relative ` }>
            <div className="row">
                <div className={`px-3 col-md-${ getColumnProportia()[0] }`}>
                    <div className="row">  
                        <Type 
                            {...props} 
                            field={ "post_content" } 
                            data={item} 
                        />
                        <Gallery data={item.images} />
                    </div>
                    <div>
                        <LeftLayout {...props} curNavId={curNavId} setCurNavId={setCurNavId} /> 
                    </div>    
                </div>
                <div className={`px-3 col-md-${ getColumnProportia()[1] }`}>
                    <RightLayout {...props} data={item} /> 
                </div>
            </div>
            <div className="position-absolute right top mt-3 mr-4" >
                <AdminItemLink singled_data_type={ "Event" } />
            </div>
        </div>
    </div>
}

export default PlaceForm
