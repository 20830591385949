import { IErmakMap } from "../../data/interfaces"
import { IErmakStore, useErmakStore } from "../../data/store"
import ErmakMapCell from "./ErmakMapCell"

/* Protopia Ecosystem component */
export interface IErmakMapBtnProps {
    item: IErmakMap
}
const ErmakMapBtn = (props: IErmakMapBtnProps): JSX.Element => {
    return <div className='pe-ermak-map-container' >
        <div className="pe-ermak-map-title">
            { props.item.title }
        </div>
        {
            Array(props.item.height || 10).fill(1)
                .map((_, y) => {
                    return <div className="pe-ermak-map-row" key={y}>{
                         Array(props.item.width || 10).fill(1)
                            .map((_, x) => {
                                return <ErmakMapCell
                                    key={ x }
                                    id={ (props.item.width || 10) * y + x } 
                                    x={ x } 
                                    y={ y } 
                                    map={ props.item } 
                                />
                            })
                        }</div>
                })
        }
    </div>
}
export default ErmakMapBtn