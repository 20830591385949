import { __ } from "@/libs/utilities"
import { Outlet } from "react-router"
import FestAdminMenu from "./FestAdminMenu"
import { useEffect } from "react"
import { clearFestival } from "../../hooks/festival"
import switchToBlog from "@/modules/pe-basic-module/hooks/switchToBlog"

const FestivalAdminList = ( ): JSX.Element => {
    useEffect(() => {   
        clearFestival()        
        switchToBlog( "-1" )
    }, [])
    return <div className=' w-100 h-100 d-flex flex-column flex-grow-100 align-items-center '>
        <h3 className="pt-4 text-center"> 
            {__("Events managment")}
        </h3>
        <FestAdminMenu />
        <Outlet />
    </div>
}
export default FestivalAdminList