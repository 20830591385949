

export const UPDATE_PROJECT_RATING = (data: any) => {
    const site = data.landId
        ?
        ` land_id: "${ data.landId }" `
        :
        ``
    return `
        mutation updateRating {
            changePEFestRating( 
                id: "${data.id}" 
                ${site}
                input: { 
                    rating: ${data.item.rating},
                    criteryId: "${data.item.criteryId}",
                    memberId: "${data.item.memberId}",
                    expertId: "${data.item.expertId}",
                    display_name: "${data.item.display_name}",
                    description: "${data.item.description}"
                }
        ) {
            id
            rating
            expertId
            display_name
            criteryId
            memberId
            description
        }
    }`
}