import { ID } from "@/libs/interfaces/layouts"

export const GET_DIARIES = ( festId: ID = -1 ) => {

    return `query getDiaries  {
        getPEFestDiarys ( paging: {count: 5 } land_id: ${festId}) {
            id title post_content thumbnail post_date
            project { id title }
            post_author {id display_name}
        }
        getPosts ( paging: {count: 5 } land_id: ${festId}) {
            id title post_content thumbnail post_date 
            post_author {id display_name}
        }
    }`
        
} 