import React, { useEffect, useState } from "react"
import IScalarForm from "../interface/IScalarForm"
import Scalar from "./Scalar"
import { Slider } from "@blueprintjs/core"

const Percentage = (props: IScalarForm): JSX.Element => {
    return <Scalar
        {...props}
        enabledForm={<PercentageEnabled {...props} />}
        desabledForm={<PercentageDesabled {...props} />}
    />
}
export default Percentage

const PercentageDesabled = (props: IScalarForm): JSX.Element => {
    return <div className='my-3 text-center '>{props.value}</div>
} 

const PercentageEnabled = (props: IScalarForm): JSX.Element => {
    const [value, setValue] = useState(props.value);
    const [demo, setDemo] = useState(Math.floor(props.value / (props.max || 100) * 100));

    useEffect(() => {
        setValue( props.value )
        setDemo( props.value / (props.max || 100) * 100 ) 
    }, [props.value])

    const handleChange = (value: number) => {
        setValue(value) 
        setDemo(value / (props.max || 100) * 100) 
        if (props.onChange) {
            props.onChange(value, props.field, "")
        }
    };
    return (
        React.createElement("div", { },
            React.createElement("div", { style: { height: "30px" } }),
            React.createElement("div", { style: { padding: "0px 12px" } },
                React.createElement("div", { className: "relative", style: { width: "300px" } },
                    React.createElement("svg", {
                        width: "300",
                        height: "150",
                        viewBox: "0 0 300 150",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                    },

                        React.createElement("path", {
                            d: "M12 150C12 131.878 15.5695 113.933 22.5046 97.1897C29.4398 80.4467 39.6048 65.2337 52.4193 52.4193C65.2338 39.6048 80.4468 29.4398 97.1897 22.5046C113.933 15.5695 131.878 12 150 12C168.122 12 186.067 15.5695 202.81 22.5046C219.553 29.4398 234.766 39.6048 247.581 52.4193C260.395 65.2338 270.56 80.4468 277.495 97.1897C284.431 113.933 288 131.878 288 150",
                            stroke: "none",
                            strokeWidth: "22"
                        }),

                        React.createElement("path", {
                            strokeDasharray: "434",
                            strokeDashoffset: `${434 - 4.34 * demo}`,
                            d: "M12 150C12 131.878 15.5695 113.933 22.5046 97.1897C29.4398 80.4467 39.6048 65.2337 52.4193 52.4193C65.2338 39.6048 80.4468 29.4398 97.1897 22.5046C113.933 15.5695 131.878 12 150 12C168.122 12 186.067 15.5695 202.81 22.5046C219.553 29.4398 234.766 39.6048 247.581 52.4193C260.395 65.2338 270.56 80.4468 277.495 97.1897C284.431 113.933 288 131.878 288 150",
                            stroke: "#424E82",
                            strokeWidth: "22",
                            strokeLinecap: "round"
                        })), 
                )
            ),
            <div className="position-relative" style={{ width: "324px", height: "24px" } }>
                <Slider
                    min={0}
                    max={ props.max || 100 }
                    stepSize={1}
                    labelStepSize={Math.floor( (props.max || 100) / 3 )}
                    value={value}
                    onChange={handleChange}
                />     
            </div>
        )
    );
}