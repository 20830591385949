import React from "react" 
import BasicState from "@/libs/basic-view" 
import ProfileForm from "./profile/ProfileForm"

class ProfileView extends BasicState { 
  props: any
  addRender() { 
    return <ProfileForm {...this.props } />
  }

   
}
export default ProfileView
