import { ContextMenuContentProps, MenuDivider, MenuItem } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { __ } from "../utilities"
import { useNavigate } from "react-router"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { profile } from "../layouts"
import { IMenuItem } from "../interfaces/layouts"

interface IExtendsProps {
    menuProps : ContextMenuContentProps
}
const ExtendMenu = ({menuProps}: IExtendsProps) :JSX.Element => { 
    return (
        <>
            <MenuDivider />
            <ExtendedMenus {...{menuProps}} />
            <CopyTextMenuItem {...{menuProps}} />
            <PasteTextMenuItem {...{menuProps}} />
        </>
    )
}  
export default ExtendMenu

const CopyTextMenuItem = ({menuProps}: IExtendsProps) :JSX.Element => {
    const [text, setText] = useState<string | null>(null)
    useEffect(
        () => {
            const target : any = menuProps.mouseEvent?.target
            var selObj = window.getSelection();
            var selRange = selObj && !!selObj.rangeCount ? selObj.getRangeAt(0) : "" 
            if( 
                target instanceof HTMLInputElement ||
                target instanceof HTMLTextAreaElement ||
                target instanceof HTMLSelectElement
            ) {
                setText( target.value ) 
            }   
            else if( selRange ) {
                setText( selRange.toString() )
            }       
            else {
                setText( target ? ( target as HTMLElement )?.innerText : null ) 
            } 
            
        }, 
        [ menuProps.mouseEvent?.target ]
    )
    const onClick = () => { 
        try {
            if( text ) {
                navigator.clipboard.writeText(text);
            } 
          } 
          catch (err) 
          {
            console.error('Failed to copy: ', err);
          }
    }
    return text
        ? 
        <MenuItem 
            label={ __("Copy") } 
            text={ text } 
            textClassName="w_250 text-overflow" 
            icon="clipboard" 
            onClick={onClick}
        /> 
        : 
        <></>
}
const PasteTextMenuItem = ({menuProps}: IExtendsProps) :JSX.Element => {
    const [text, setText] = useState<JSX.Element>( <></> )
    const onClick = () => {
        const target : any = menuProps.mouseEvent?.target
        if(target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)
        {
            navigator.clipboard.readText()
                .then(text => {
                    target.value = target.value + text
                })
                .catch(err => { 
                    console.log('Something went wrong', err);
                })
        }
    }
    useEffect(
        () => {
            const target : any = menuProps.mouseEvent?.target
            if(target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)  
            {
                navigator.clipboard.readText()
                    .then(text => {
                        setText(<MenuItem 
                            text={ __("Paste from Clipboard") } 
                            textClassName="w_250 text-overflow" 
                            icon="arrow-bottom-right" 
                            onClick={onClick}
                        />)
                    })
                    .catch(err => {
                        // возможно, пользователь не дал разрешение на чтение данных из буфера обмена
                        console.log('Something went wrong', err);
                    })
            }
        }, 
        [ menuProps.mouseEvent?.target ]
    )
    return text
}

const ExtendedMenus = ({menuProps}: IExtendsProps) :JSX.Element => { 
    const target : any = menuProps.mouseEvent?.target
    if(target instanceof HTMLElement && target.getAttribute('context-title'))  
    {
        return <>
            {
                target.getAttribute('context-title')?.split("|||")
                    .map(title => <SingleExtendedMenu label={ title }/>)
            }
            <MenuDivider />
        </>
    } 
    return <UserMenu />
}

interface  ISingleProps {
    label: string
}
const SingleExtendedMenu = ({ label }:ISingleProps) : JSX.Element => {
    const onClick = () => {

    }
    const menus: string[] = label.split("||")
    return menus.length > 1
        ?
        <MenuItem text={menus[0]} icon="blank" >
            {
                menus.splice(1).map(title => {
                    return <MenuItem 
                        text={ title } 
                        textClassName="w_250 text-overflow" 
                        icon="blank" 
                        onClick={onClick}
                    />
                })
            }
        </MenuItem>
        :
        <MenuItem text={menus[0]} icon="blank" />
}

const UserMenu = () : JSX.Element => {
    const navigate = useNavigate()
    const user: IUser = useMainStore((state: IState) => state.user )
    const menus = user.id 
        ? 
        profile().filter((m:IMenuItem) => !!m.capability?.filter( role => user.roles.includes( role )).length )
        :
        []
    if(!!menus.length)
    {
        return <>
            {
               menus.map( (m:IMenuItem) => {
                    return <MenuItem 
                        text={ m.title } 
                        onClick={() => navigate(`/${ m.route }`)} 
                        icon="blank"  
                        key={m.id}
                    /> 
                }) 
            }
            <MenuDivider />
        </>
    }
    else{
        return <></>
    }
}
