import { IFabulaMediaType, IPEFabulaMapTile } from "../../interfaces";

export const Kachalinskaya = () : IPEFabulaMapTile => {
    return {
        id: "5",
        title: "Биозаповедник «Качалинская сторожка»",
        post_content: "",
        enabled: true,
        x: 1,
        y: 1,
        map_id: "2",
        thumbnail: "/assets/img/fabula/Bear/bear5.png",
        episodes: [ 
            {
                id: "51",
                title: "Лаборатория био-кибернизации. Объект М-1.",
                post_content: "",
                tile_id: "4",
                visible: true,
                x: 90,
                y: 17,
                media: [ 
                    {
                        id: "511",
                        title: "Лаборатория био-кибернизации. Объект М-1.",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear511.jpg",
                        speech:[],
                        media:[
                            
                        ],
                    }
                ]
            },
        ]
    }
}