import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { Icon, IconName, MaybeElement } from "@blueprintjs/core"
import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface IFabulaSwitchMenuProps {

}
const FabulaSwitchMenu = (props: IFabulaSwitchMenuProps): JSX.Element => { 
    const isMapOpen : boolean       = useStorytellingStore((state: IStorytellingStore) => state.isMapOpen)
    const isSettingsOpen : boolean  = useStorytellingStore((state: IStorytellingStore) => state.isSettingsOpen)
    const isBreakOpen : boolean     = useStorytellingStore((state: IStorytellingStore) => state.isBreakOpen)
    const isQuestOpen : boolean     = useStorytellingStore((state: IStorytellingStore) => state.isQuestOpen)
    return isMapOpen || isSettingsOpen || isBreakOpen || isQuestOpen
        ?
        <div className='pe-fabula-switch-menu-container'>
            <SwitchBtn icon="map-marker" text={__("Map") } isActive={isMapOpen} switch={"isMapOpen"} />
            <SwitchBtn text={__("Quest") } isActive={isQuestOpen} switch={"isQuestOpen"}  />
            <SwitchBtn text={__("Setting") }  isActive={isSettingsOpen} switch={"isSettingsOpen"} />
            <SwitchBtn text={__("Break quest and quit") } switch={"isBreakOpen"} />
        </div>
        :
        <></>
}
export default FabulaSwitchMenu

export interface IFabulaSwitchProps {
    icon?: IconName | MaybeElement 
    isActive?: boolean
    text? : string
    switch?: string
}
const SwitchBtn = (props: IFabulaSwitchProps) : JSX.Element => {
    const onClick = () => {
        ["isMapOpen", "isSettingsOpen", "isQuestOpen", "isBreakOpen"]
            .filter(is => is !== props.switch)
                .forEach(is => {
                    useStorytellingStore.setState({[is]: false} )
                })
        if( props.switch) {
            useStorytellingStore.setState({[props.switch]: true} )
        }
    }
    return <div className="pe-fabula-switch-menu-btn" onClick={onClick}>
        { props.icon && <Icon icon={props.icon} size={22} color={props.isActive ? "#FFF" : "#ffffff4a" } /> }
        { props.text && <div className="pl-1">{props.text}</div>}
    </div>
}