import IScalarForm from "../../interface/IScalarForm"

const AddItemForm = (props: IScalarForm): JSX.Element => {
    return <div 
        className='pe-gallery-add-btn'
        onClick={ () => { if(props.onChange) props.onChange("", "", "") } }
        style={{
            height: props.height || 120,
            width:  props.width  || 120 
        }}
    >
        <div>
            +
        </div>
    </div>
} 
export default AddItemForm