
interface IDummyProps {
    width: number
    height: number
    className? : string
}
const Dummy = ({width, height, className} : IDummyProps) : JSX.Element => {
    return <svg 
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox={`0 0 ${width} ${height}`}
        className={`w-100 untouchble ${ className }`}
    />
}

Dummy.propTypes = {}

export default Dummy