import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { GET_OPTIONS_ACTION, UPDATE_OPTIONS_ACTION } from "../../data/actionTypes"
import {options as optionsData} from "../../data/options"
import FieldInput from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import { Icon } from "@blueprintjs/core"

const AppTab = (props: any) :JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [options, getOptions] = useState<any>({})
  const [defaultOptions, getDefaultOptions] = useState<any>("")
  useEffect(() => {
    actions(GET_OPTIONS_ACTION, {})
      .then(response => {
        let _options = {...response}
        delete _options.__typename
        getOptions( _options )
        getDefaultOptions( JSON.stringify( _options ) )
      })
      .finally( () => setIsLoading(false))
  }, [])
  const updateOptions = () => {
    setIsLoading(true)
    actions( UPDATE_OPTIONS_ACTION, options )
      .then(response => {
        let _options = {...response}
        delete _options.__typename
        console.log( _options )
        getOptions( _options )
        getDefaultOptions( JSON.stringify( _options ) )
      })
      .finally( () => setIsLoading(false))
  }
  const onChange = (value: any, field: string, data:any) => {
    console.log( value, field, data )
    let _options = {...options}
    if(data && data.name && data.size) {
      _options[ `${field}_name` ] = data.name
    }
    _options[ field ] = value
    getOptions( _options )
  }
  const list: JSX.Element[] | JSX.Element = Object.keys(options)
    .filter(key => key !== "__typename")
      .map(key => {
        return <FieldInput
          value={ options[ key ] }
          { ...optionsData()[ key ] }
          field={ key }
          key={ key }
          editable
          onChange={ onChange }
        />
      })
  return <div className="w-100 h-100 flex-centered flex-column">
    <div className="short-container position-relative ">
      <div className={ ` position-sticky mr-4 top z-index-100` } >
        <div 
          onClick={updateOptions}
          className={`position-absolute top mt-2 right btn btn-sm btn-${defaultOptions !== JSON.stringify(options) ? 'danger' : 'secondary opacity_5 untouchble'}`}
        >
          <Icon 
            icon={isLoading ? "repeat" : "tick"} 
            className={`mr-2 ${isLoading ? "fa fa-spin" : ""}`} 
          /> 
          {__("Update Options")}   
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
          <div className='display-6 mb-3'>{ __("Options") }</div>  
      </div>
      { list }
    </div>
  </div> 
} 
export default AppTab