import React, { ChangeEvent } from 'react' 
import { __ } from '@/libs/utilities' 
import { getTableRows } from './utils'
import DataTableCell from './DataTableCell'
import { Link } from 'react-router-dom' 
import { IAdminStore, IBulk, useAdminStore } from '../../data/store'
import VisibleCheckBox from './VisibleCheckBox'

const DataTypeRow = (props: any) : JSX.Element => {
    const bulks: IBulk[]    = useAdminStore((state:IAdminStore) => state.bulks) 
    const setBulks: ((bulks: IBulk[]) => void)  = useAdminStore((state:IAdminStore) => state.setBulks) 
    const fields = { ...props.params.fields }
    const threads : any[] = getTableRows( fields )  

    const onChange  = (checked: boolean) => {
        setBulks( 
            bulks.map((bulk: IBulk) => {
                if(bulk.id === props.item.id) {
                    bulk.checked = checked
                }
                return bulk
            })
        )
    }
    return (
        <tr>
            <td>
                <TableCheckBox 
                    {...props}
                    checked={ bulks.filter((bulk:IBulk) => bulk.id === props.item.id)[0]?.checked }
                    onChecked={onChange}
                />
                <VisibleCheckBox {...props} />
            </td>
            {
                threads.map((thread : string) => {
                    return <DataTableCell 
                        item={ props.item } 
                        thread={ thread } 
                        type={ fields.apollo_fields[thread].type }
                        apollo_fields={ fields.apollo_fields[thread] }
                        key={thread} 
                        data_type={props.data_type}
                        className={ props.item.post_status && props.item.post_status !== "publish" ? "opacity_5 grayscale " : " "}
                    />
                })
            }
            <td>
                <Link className='table-btn' to={`${props.item.id}`}>
                    {__("Edit")}
                </Link>
            </td>
        </tr>
    )
} 

export default DataTypeRow

export const TableCheckBox = (props: any) :JSX.Element => {
    return <label className={`_check_blue_ ml-2 mt-1 mb-0 data-${ props.col }`}>
        <input
            type="checkbox"
            data-id={ props.id }
            value={ props.id }
            checked={ props.checked }
            onChange={ (evt:ChangeEvent<HTMLInputElement> ) =>  props.onChecked(evt.currentTarget.checked ) }
        />
    </label>
}