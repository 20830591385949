import { Link } from "react-router-dom"
import { getSingleRoute } from "src/libs/layouts"
import { IEvent } from "../../data/interfaces"
import EventTypeLabel from "../labels/EventTypeLabel"

interface IProps {
    item: IEvent
    height?: number | string
}
const EventCard = (props: IProps) : JSX.Element => {
    const routeSingle = getSingleRoute("Event") 
    const url = routeSingle ? `/${routeSingle.route}/${props.item.id}` : "/" 
    return <Link to={url} className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 card rounded-0 p-0" > 
        <div 
            className="thumbnail card-top-img" 
            style={{ 
                backgroundImage:`url(${props.item.thumbnail})`, 
                height: props.height 
            }} 
        >
            <EventTypeLabel isShort item={ props.item } className="position-absolute small flex-wrap mx-1 my-1 z-index-100"/>
            <h4 className="text-light text-center p-3 position-absolute bottom left w-100 z-index-100">
                {props.item.title}
            </h4>
        </div>
        <div className="card-body d-none">
            
        </div> 
    </Link>
} 

export default EventCard