import { IUser, useMainStore } from "src/settings/zustand"
import { IMana, IManituAction, IUserMana, MANA_TYPE, MANITU_ENERGY_TYPE } from "../interfaces"
import { players } from "./players"
import { useManituStore } from "../store"
import { actionTypes } from "./actionTypes"

export const manaMocks = (): IUserMana[] => {
    let list: any[] = players().map(user => {
        return getUserMana(user)
    })
    return list
}
export const mineMana = () => {
    const user: IUser = useMainStore.getState().user 
    return getUserMana(user)
}
export const getUserMana = (user: IUser): IUserMana => { 
    const mana: IUserMana =  {
        id: user.id,
        user: user,
        [MANA_TYPE.MANA]: getMana(MANA_TYPE.MANA),
        [MANA_TYPE.DEFENCE]: getMana(MANA_TYPE.DEFENCE),
        [MANA_TYPE.RICH]: getMana(MANA_TYPE.RICH),
        [MANA_TYPE.IMPACT]: getMana(MANA_TYPE.IMPACT),
        actions: getManituActions(user)
    }
    return mana
}
const getMana = (type: MANA_TYPE): IMana => {
    return {
        type,
        count: Math.floor(Math.random() * 15) + 55
    }
}
const getManituActions = (user: IUser): IManituAction[] => { 
    const count: number = Math.floor(Math.random() * 5 + 1 ) 
    const cellCount: number = useManituStore.getState().matrixColumns || 3 
    return Array(count).fill(1).map((action, i) => {
        return {
            id: i,
            type: actionTypes()[4],
            time: (new Date()),
            count: Math.floor(Math.random() * 3 + 1),
            rowOrder: Math.floor(Math.random() * useManituStore.getState().matrixRows),
            cellOrder: Math.floor(Math.random() * cellCount),
            opponent: players()[Math.floor(Math.random() * players().length)],
            recipient: user,
        } as IManituAction
    })
}