import React from 'react' 
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import { Button, Tag } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'

const ImageRadio = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<ImageRadioEnabled {...props} />}
        desabledForm={<ImageRadioDesabled {...props} />}
    /> 
} 

export default ImageRadio

const ImageRadioEnabled  = ( props: IScalarForm ): JSX.Element => {
    const onClick = ( i:number) => {
        const v = [ ...props.values || [] ].filter((val, n) => n === i)[0]
        if( props.onChange && v ) props.onChange(v, props.field, "")
    }
    return <div className="d-flex flex-wrap align-items-start">
    {
        props.values?.map((value, i) => {
            const checked = Array.isArray( props.value )
                ?
                props.value.filter( v => v === value._id ).length > 0
                :
                value._id === props.value
            return <Button
                key={i}
                className="scalar-imgage-radio-label"
                onClick={( ) => onClick( i )}
                data-v={ value._id }
                minimal={ !checked }
            >
                <img
                    src={ value.img }
                    alt={ value.title }
                    className="scalar-imgage-radio-img"
                    style={{
                        height: value.height,
                        top: 0,
                        opacity: checked
                            ? 
                            value.icon_opacity
                                ? 
                                value.icon_opacity
                                : 
                                1
                            : 
                            0.125,
                    }}
                />
                <div className="scalar-imgage-radio-title">
                    { __(value.title) }
                </div>
                <div 
                    className="scalar-imgage-radio-description" 
                    style={{maxWidth: value.height}}
                >
                    { __(value.description) }
                </div>
            </Button>
        })
    }    
    </div>
}
const ImageRadioDesabled  = ( props: IScalarForm ): JSX.Element => {
    const checkedValue = props.values?.filter( v => v._id === props.value )[0]

    return checkedValue
        ?
        <div>
            <img
                src={checkedValue.img}
                alt={props.value.title}
                className="scalar-imgage-radio-img"
                style={{
                    height: checkedValue.height,
                    top: 0 
                }}
            />
            <div>{ props.value.title }</div>
        </div>
        :
        <Tag>{props.value.title}</Tag>
}