import { IState, IUser, useMainStore } from "src/settings/zustand"
import { Popover } from "@blueprintjs/core"
import { NavLink } from "react-router-dom"
import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { WINDOW_WIDTH } from "src/libs/interfaces/layouts"
import { getRouteByComponent } from "src/libs/layouts"
import { LayoutIcon } from "src/libs/useful"
import { __ } from "src/libs/utilities"

const PixelsMonitorWidget = () : JSX.Element => {
    const {width} = useScreenSize()
    const user: IUser = useMainStore((state: IState) => state.user)
    if(!user.id) {
        return <></>
    }
    if( width < WINDOW_WIDTH.TABLET) {
        return <PixelsMonitorTablet />
    }
    else {
        return <PixelsMonitorScreen />
    }
}
const PixelsMonitorTablet = () : JSX.Element => {
    const style = { width: 50, height: 50 }
    return <Popover
        interactionKind="click" 
        position="top-right"
        modifiers={{
            arrow: { enabled: false },
            flip: { enabled: true },
            preventOverflow: { enabled: false },
        }}
        className="p-0"
        content={<PixelsPopover />}
    >
        <div
            style={{
                position: "relative", 
                color: "#FFF", 
                overflow: "hidden",
                minWidth: 44,
                width: 44,
                height: 44,
                transform: "translateY(-5px) translateX(-12px)",
                zIndex:10
            }}
        >
            <img src="/assets/img/manitu/icon1.png" style={style} alt="" />
        </div>
    </Popover>
}
const PixelsMonitorScreen = () : JSX.Element => { 
    return <div className="pe-manitu-pixels-monitor-container"> 
            <NavLink 
                to={ getRouteByComponent("MyPixelsView")?.route || ""}
                className="pe-manitu-pixel-monitor-btn" 
            >
                <div className="pe-pixel-btn-popover">
                    <span>
                       { __("Mine Pixels") } 
                    </span>
                </div>
                <LayoutIcon src="/assets/img/manitu/icon1.png" />
            </NavLink> 
            <NavLink 
                to={ getRouteByComponent("ConquestPixelsView")?.route || ""}
                className="pe-manitu-pixel-monitor-btn" 
            >
                <div className="pe-pixel-btn-popover">
                    <span>
                       { __("Conquest Pixels") } 
                    </span>
                </div>
                <LayoutIcon src="/assets/img/manitu/icon2.png" />
            </NavLink>
            
            <NavLink 
                to={ getRouteByComponent("VerifyPixelsView")?.route || ""}
                className="pe-manitu-pixel-monitor-btn" 
            >
                <div className="pe-pixel-btn-popover">
                    <span>
                       { __("Verify Pixels") } 
                    </span>
                </div>
                <LayoutIcon src="/assets/img/manitu/icon4.png" />
            </NavLink>
            
    </div> 
} 

export default PixelsMonitorWidget

const PixelsPopover = (): JSX.Element => {
    return <div
        style={{
            width:  190, 
        }}
        className="flex-centered flex-column"
    >
        <NavLink to="/manitu/research/add-pixel" className="pe-pixel-btn" >
            
            <span>
                { __("Mine Pixels") } 
            </span>
            
        </NavLink> 
        <NavLink to="/manitu/research/complete-pixels" className="pe-pixel-btn" >
            
            <span>
                { __("Conquest Pixels") } 
            </span>
            
        </NavLink>
        
        <NavLink to="/manitu/research/verify-pixels" className="pe-pixel-btn" >
            
            <span>
                { __("Verify Pixels") } 
            </span>
           
        </NavLink>
    </div>
}