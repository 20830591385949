import { IDoActionReturn } from "@/modules/pe-manitu-module/data/mocks/doAction"
import { IManituAction, IManituActionType, IManituCell, IOrganoid, MATRIX_MODE } from "../../../data/interfaces"
import CellEnemy from "./CellEnemy"
import CellMine from "./CellMine"
import CellPartner from "./CellPartner" 

export interface ICellProps {
    item: IManituCell
    mode: MATRIX_MODE
    actions: IManituAction[]
    organoids: IOrganoid[]
    onActionResult: (cell: IManituCell, action: IManituActionType, result: IDoActionReturn) => void
}
const Cell = (props: ICellProps): JSX.Element => {
    switch(props.mode) {
        case MATRIX_MODE.MINE:
            return <CellMine 
                item={props.item} 
                mode={props.mode} 
                actions={props.actions}
                organoids={ props.organoids }
                onActionResult={props.onActionResult}
            />
        case MATRIX_MODE.ENEMY:
            return <CellEnemy 
                item={props.item} 
                mode={props.mode} 
                actions={props.actions} 
                organoids={ props.organoids }
                onActionResult={props.onActionResult}
            />
        case MATRIX_MODE.PARTNER:
        default:
            return <CellPartner 
                item={props.item} 
                mode={props.mode} 
                actions={props.actions} 
                organoids={ props.organoids }
                onActionResult={props.onActionResult}
            />
    }
    
} 

export default Cell
 