import IScalarForm from "@/libs/scalars/interface/IScalarForm"
import Scalar from "@/libs/scalars/scalars/Scalar"
import ExtendedFieldsEnabled from "./project-extended-fields/ExtendedFieldsEnabled"
import { useFestival } from "../hooks/festival"
import { Loading } from "@/libs/useful"

const ProjectExtendedFieldsWidget = (props: IScalarForm) : JSX.Element => {
    const isLoading1 = useFestival(true)
    if(isLoading1) return <Loading />
    return <Scalar 
        { ...props } 
        enabledForm={<ExtendedFieldsEnabled {...props} />}
        desabledForm={<ExtendedFieldsDesabled {...props} />}
    />
} 
export default ProjectExtendedFieldsWidget

export const ExtendedFieldsDesabled = ( props: IScalarForm ): JSX.Element => {
    return <>{JSON.stringify(props.value)}</>
}
