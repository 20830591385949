import React, { useEffect, useState } from 'react' 
import {ILayoutBodyProps} from '@/libs/interfaces/layouts' 
import LayoutBodyLocal from './LayoutBodyLocal'
import actions from 'src/modules/pe-basic-module/data/actions'
import { USER_INFO_ACTION} from '@/modules/pe-basic-module/data/actionTypes'
import { Loading } from '@/libs/useful'


const LayoutBodyStatic = (props: ILayoutBodyProps) => {
  const [isLoading, setIsLoading] = useState(true) 
    useEffect(() => { 
      const token: string | null = localStorage.getItem('token')   
      if( token ) {  
        actions(USER_INFO_ACTION, {}).finally(() => setIsLoading(false))
      }
      else {
        setIsLoading(false)
      }
    }, [])
    if( isLoading ) {
      return <div className="layout-state flex-centered w-100 h-100"><Loading /></div>
    } 
    return  <LayoutBodyLocal {...props} />  
}
 

export default LayoutBodyStatic