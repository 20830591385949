import { IExhortation, IProject } from "@/modules/pe-fest-module/data/interfaces"
import { IFestProjectsProps, useFestProjects } from "@/modules/pe-fest-module/data/store"
import ProjectExhortionBanForm from "../ProjectExhortionBanForm"
import Moment from "react-moment"
import { __ } from "@/libs/utilities"

const { A11y, Navigation, Pagination, } =  require("swiper/modules") 
const { Swiper, SwiperSlide, } =  require("swiper/react")  

/* Protopia Ecosystem component */ 
const ProjectMobileQuotes = ( ): JSX.Element[] | JSX.Element => {
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)
    const exhortations: any[] = item.exhortations
    
    return !!exhortations?.length 
        ?
        <Swiper 
            modules={[ Navigation, Pagination, A11y ]}
            spaceBetween={0}
            slidesPerView={1} 
            pagination={{ clickable: true }}   
        >
        {
            exhortations.map((exhortation: IExhortation, index: number ) => {
                const classes =  "col-md-12 p-2" 
                return <SwiperSlide key={ `exp_descr_${ item.id }_${ index }` }>
                    <div className={ classes } >
                        <blockquote>
                            <div className=" d-flex align-items-center quote-text overflow-y-auto ">
                                <i className="fas fa-quote-left fa-2x mr-4 mb-5 text-secondary opacity_5 d-md-block d-none" />
                                <div className=" w-100 text-center py-2 flex-grow-100">
                                    { exhortation.descr }
                                </div>
                                <i className="fas fa-quote-right fa-2x ml-4 mt-5 text-secondary opacity_5 d-md-block d-none" />
                            </div>
                            <div className="mt-0 d-flex flex-md-row flex-column justify-content-between align-items-center">
                                <div className="pe-surface2">
                                    <ProjectExhortionBanForm 
                                        item={exhortation} 
                                    />
                                </div>
                                <div className="d-flex align-items-center iconic">
                                    <div 
                                        className="user-ava mr-3" 
                                        style={{ backgroundImage: `url(${exhortation.author.avatar})`, backgroundColor: "grey"}}
                                    />
                                    <div>
                                        <div>{ exhortation.author.display_name }</div>
                                        <Moment locale="ru" format="D MMMM YYYY, HH:mm" className='small mt-2'>
                                            {new Date( Number( exhortation.unixtime ) * 1000  )}
                                        </Moment>
                                    </div>
                                </div>
                            </div>
                        </blockquote>
                    </div>
                </SwiperSlide>
            })
        }
        </Swiper>
        
        :
        <div className="col-md-12 critery_cell3 grey2 first_row pt-3">
            {__("No descriptions")}
        </div>
}
export default ProjectMobileQuotes
 