
import { ID, Icon, URL } from "@/libs/interfaces/layouts";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export interface IQuaestioStore {
    /* It's example. Clear this stroke. */
    exampleToDelete: string
}

export const useQuaestioStore: any = create( 
    devtools( 
        persist<IQuaestioStore>( 
            (set: any, get: any ) => ({ 
                /* It's example. Clear this stroke. */
                exampleToDelete: ""
            }),
            {
                name: 'pe-quaestio-storage', 
                storage: createJSONStorage( () => localStorage ), 
            } 
        )
    )
)
