import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface IDestructBtnProps {

}
const DestructBtn = (props: IDestructBtnProps): JSX.Element => {
    return <div className='pe-slot-btn lock'>
        <svg
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
            viewBox="0 0 313.4 298.1"
        >
            <path d="M240.9,95.5c-16.1,21-32.3,42-48.3,63.1c-0.7,0.9-0.7,3.1-0.1,4.3c1.8,3.5,4.1,6.8,6.2,10.2c11.7,19.3-0.5,41.9-23.1,42.5
                c-5.4,0.2-10.9,0-16.6,0c0,5.1,0,9.7,0,15.1c7.3,0,14.5-0.1,21.8,0c14.8,0.2,25.1,13.1,21.9,27.5c-2.2,10.2-11.9,17.4-23.9,17.4
                c-34.3,0-68.7,0-103,0c-5.8,0-11.7,0.1-17.5,0c-13.5-0.2-23.6-10-23.5-22.7c0.1-12.4,10.3-22.1,23.5-22.2c8.3-0.1,16.6,0,25.2,0
                c0-5.1,0-9.7,0-15.1c-2.6,0-5.4,0.1-8.1,0c-7-0.2-14.1,0.2-20.9-0.8c-11.5-1.8-19.4-11.5-19.6-23.1c-0.2-8.5-0.4-17,0.1-25.5
                c0.2-4,1.9-8,3.4-11.8c4.9-12.3,10.1-24.5,15-36.8c0.4-1-0.4-2.8-1.2-3.8c-11.7-15-4.8-33.1,13.8-37.4c10.8-2.5,21.3-6.4,32.2-9.8
                C97.6,64,97,62,96.5,60c-1.3-4.8,0.2-8.5,4.8-10c13.4-4.4,26.9-8.7,40.4-12.9c5-1.5,8.4,0.4,10.2,5.3c0.7,1.9,1.3,3.8,2.2,6.2
                c5.7-1.7,11.4-3.4,17-5.2c23.5-7.3,46.9-14.8,70.5-21.9c17.1-5.2,33.7,4.4,37.8,21.5c3.4,14.1-4.5,29.3-18.1,34.5
                c-3.2,1.2-4.2,2.6-4.2,6c0.1,30.3,0.1,60.7,0,91c0,3.6,1,5.1,4.6,6.2c19.4,5.9,31.9,25.2,29,44.1c-3.2,20.7-19.8,35.8-39.7,36.1
                c-20.8,0.3-38-13.9-41.7-34.4c-3.5-19.6,8.6-39.5,28.1-45.6c3.8-1.2,5.1-2.7,5.1-6.8c-0.2-24.5-0.1-49-0.1-73.5c0-1.6,0-3.2,0-4.8
                C241.7,95.7,241.3,95.6,240.9,95.5z M160.2,140.3c-3.9-8.4-8.4-14.8-17.7-15.1c-9.7-0.3-19.3-0.3-29,0c-6.3,0.2-9.7,4-9.8,10.3
                c-0.1,4,0,8,0,12c0,7.5-2.6,10.1-10.3,10.1c-4.4,0-8.9,0-13.4,0c0,14.7,0,28.8,0,42.6c0.5,0.3,0.6,0.4,0.8,0.4
                c31.8,0.1,63.6,0.1,95.5,0.1c5,0,8.9-2.6,11-7.3c2.7-5.9,1.6-7.7-4.8-7.7c-19.2,0-38.3,0-57.5,0c-7,0-9.6-2.6-9.7-9.6
                c-0.1-3,0-6,0-9c0.1-17.5,9.3-26.7,26.9-26.7C148,140.3,153.8,140.3,160.2,140.3z M88.5,131c6.1-16.1,12.9-20.7,29.1-20.7
                c2.2,0,4.4-0.3,6.5,0c9.4,1.4,16.6-2.2,24-8c23.3-18.3,47.1-35.9,70.7-53.9c1.2-0.9,1.6-2.9,2.9-5.7c-22.1,7-42.5,13.5-63.2,20.1
                c0.7,2.4,1.4,4.2,1.9,6.1c1.4,5.3-0.3,8.9-5.4,10.6c-13.3,4.3-26.6,8.5-39.9,12.7c-4.7,1.4-8.2-0.4-9.9-5c-0.8-2.1-1.5-4.3-2.3-6.7
                c-5.4,1.8-10.4,3.4-15.4,5.1c5.3,10.7,5,20.2-2.8,28.9c-0.7,0.8-1.1,2.5-0.8,3.5C85.2,122.1,86.8,126.1,88.5,131z M249.8,193.7
                c-14.6,0-26.1,11.2-26.2,25.7c-0.1,14.8,11.3,26.4,25.9,26.5c14.6,0,26.3-11.7,26.3-26.2C275.8,205.3,264.2,193.7,249.8,193.7z
                M119,245.7c-20.2,0-40.3,0-60.5,0c-6.1,0-10.1,4.4-8.5,9.4c1.4,4.5,5,5.7,9.4,5.7c39.7,0,79.3,0,119,0c6.4,0,10.2-3.1,10.1-7.9
                c-0.2-4.7-3.6-7.2-10-7.2C158.7,245.7,138.8,245.7,119,245.7z M130.7,170.5c16.1,0,32,0,48.8,0c-2.9-4.6-5.1-8.8-7.9-12.6
                c-1.1-1.4-3.3-2.7-5.1-2.7c-7.8-0.3-15.6-0.1-23.4-0.1C132.3,155.1,128.9,159.1,130.7,170.5z M99.2,215.9c0,5.3,0,10,0,14.6
                c15,0,29.6,0,44.3,0c0-5,0-9.7,0-14.6C128.6,215.9,114,215.9,99.2,215.9z M64.6,157.8c-11.7-0.9-14.8,1.9-14.8,12.8
                c0,5.8,0,11.6,0,17.4c0,10.3,4.4,13.9,14.8,12.1C64.6,186.2,64.6,172.2,64.6,157.8z M235.8,47.1c8.7,2.8,14.2,8.7,17,17
                c8.3-1.6,12.7-7.6,12.1-15.9c-0.5-7.1-6.5-13-13.7-13.4C243.4,34.3,237.3,39,235.8,47.1z M112.7,62c1.6,4.9,3,9.3,4.6,14.1
                c9.1-2.9,17.9-5.7,27-8.6c-1.6-4.9-3-9.3-4.5-14C130.5,56.3,121.9,59,112.7,62z M205.6,117.1c-6-4.7-11.6-9.1-17.7-13.9
                c0,12.7,0,24.2,0,36.8C194.2,131.9,199.7,124.8,205.6,117.1z M223.5,91.9c0.4,0.1,0.8,0.2,1.1,0.4c4.2-5.5,8.6-10.9,12.6-16.6
                c2.6-3.7,2.1-7.7-0.9-11.1c-2.6-3-6-4.3-9.8-2.8c-1.2,0.5-2.8,2.1-2.9,3.3C223.4,74,223.5,82.9,223.5,91.9z M191.9,87.3
                c5.7,4.5,10.8,8.5,16.5,13c0-8.9,0-16.8,0-25.6C202.6,79.1,197.5,82.9,191.9,87.3z M172.5,102.2c-5.5,4.3-10.2,7.9-14.4,11.1
                c5,5.5,9.6,10.6,14.4,15.9C172.5,120.4,172.5,111.8,172.5,102.2z M69.2,121.3c-0.4,0-0.8,0-1.2,0c-2.5,6.8-5.1,13.7-7.8,21
                c5.8,0,10.8,0,16.5,0C74.1,135.1,71.6,128.2,69.2,121.3z M68.5,91.1c-4,0.2-7.5,3.9-7.3,7.9c0.2,4.2,3.7,7.3,8,7
                c4-0.2,7.1-3.5,7-7.5C76.2,94.5,72.5,90.9,68.5,91.1z"/>
        </svg>
        {__("Destruct")}
    </div>
}
export default DestructBtn