import { ICritery } from '@/modules/pe-fest-module/data/interfaces'
import React from 'react' 

interface IProps {
    critery:    ICritery
    index:      number
    onCheck:    ( rating: number ) => void
    rating:     number
}
const ExpertCheck = (props: IProps) :JSX.Element => {
    const chid = `c_${props.critery.id}_${props.index}`
    return <span>
        <input
            type="radio" 
            value={ props.index }
            className="radio srait"
            id={chid}
            checked={ props.rating ? props.index === props.rating : props.index === 0 }
            onChange={ () => props.onCheck( props.index ) } 
            data-id={ props.critery.id }
        />
        <label htmlFor={ chid } data-hint={ props.index === 0 ? "-" : props.index } />
    </span>
} 

export default ExpertCheck