import { __, setMaxHeightByClass, sprintf } from "src/libs/utilities"
import { Button } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { IFabulaHero } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"

/* Protopia Ecosystem component */
export interface IDiceProps {
    defense: number[]
    attack: number[]
    isActive: boolean
    onWin: () => void
    onLose: () => void
    dictionary: any
}
const Dice = ( {dictionary, ...props}: IDiceProps | any ): JSX.Element => {
    const hero: IFabulaHero = useStorytellingStore( ( state: IStorytellingStore ) => state.hero )
    const [addDexterity, ] = useState<number>( hero.dexterity < 6 ? Math.floor( hero.dexterity / 2 ) : 3 )
    const [addLuck, ] = useState<number>( hero.luck < 6 ? Math.floor( hero.luck / 2 ) : 3  )
    const [timeOut, set_TimeOut] = useState<any>( null )
    const [isActive, setIsActive] = useState<boolean>( true )
    const [isResult, setIsResult] = useState<boolean>( false )
    const [dResult, setDResult]   = useState<number[]>( 
        () => { 
            return props.defense?.map((dice: number, i: number) => {
                return Math.floor(Math.random() * (6 - addDexterity)) + 1
            })
        }
     )
    const [aResult, setAResult]   = useState<number[]>( [] )
    const [defense, ] = useState<number>(Math.floor(Math.random() * (9 - props.defense.length)) + props.defense.length)
    const [attack, ]  = useState<number>(Math.floor(Math.random() * (9 - props.attack.length))  + props.attack.length)
    const [summDefense, setSummDefense]  = useState<number>(0)
    const [summAttack,  setSummAttack]   = useState<number>(0)
    
    useEffect(() => {
        setMaxHeightByClass("def-hack-container")
        return () => {
            clearTimeout(timeOut)
        }
    }, [])

    const doDice = () => { 
        const _ar: number[] = props.attack?.map((dice: number, i: number) => {
            return Math.floor(Math.random() * (6 - addLuck)) + (1 + addLuck)
        })
        const _summDefense = dResult.reduce((partialSum, a) => partialSum + a, 0)
        const _summAttack = _ar.reduce((partialSum, a) => partialSum + a, 0)
        setAResult( _ar )
        setSummDefense( _summDefense ) 
        setSummAttack( _summAttack  )
        // console.log(_summDefense, _summAttack)
        set_TimeOut(
            setTimeout( () => {
                if( _summDefense < _summAttack ) {
                    props.onWin()
                }
                else {
                    props.onLose()
                }
                doResult()
            }, 3300 )
        )
        setIsActive( false )
    }
    const doResult = () => {
        setIsResult(true) 
    }
    const showResult = (): JSX.Element => {
        return <div>
            <div className="d-flex w-100 text-center">
                <div className="w-100 mx-5">
                    <div className="lead">
                        {__(dictionary.Defense || "Defense")}
                    </div>
                    <div className="display-4">
                        {summDefense}
                    </div>
                </div>
                <div className="w-100 mx-5">
                    <div className="lead">
                        {__(dictionary.Hacker || "Hacker")}
                    </div>
                    <div className="display-4">
                        {summAttack}
                    </div>
                </div> 
            </div>
            <div className={`text-center display-6 text-light m-3 p-5 ${summDefense < summAttack ? "bg-success": "bg-danger"}`}>
                {
                    __( summDefense < summAttack 
                        ? 
                        dictionary.YouLuck || "You hack" 
                        : 
                        dictionary.DefenseSurvived || "Defense survived" )
                }
            </div>
        </div>
    }

    return <div className='pe-mini-game-container pe-dice-container'> 
        <div className="flex-centered flex-column h-100 position-relative ">  
            <div className="flex-centered h-100"> 
                <div className="def-hack-container">
                    <div className="w-100">
                        <div className="lead text-center">
                            { __(dictionary.Defense || "Defense") }
                        </div>
                        <div className=" text-center my-2">
                            { sprintf(__("Dexterity coefficient: -%s"), addDexterity.toString())}
                        </div>  
                    </div>
                    <div className="dice-container">
                    {
                        props.defense?.map((dice: number, i: number) => { 
                            return <DiceSingle 
                                { ...props } 
                                key={i}
                                i={ i } 
                                start={ defense } 
                                result={ dResult[i] } 
                                isActive={false}
                                anti
                            />
                        })
                    } 
                    </div>
                </div>
                <div className="def-hack-container">
                    <div className="w-100">
                        <div className="lead text-center">
                            {__(dictionary.Hacker || "Hacker")}
                        </div>
                        <div className=" text-center my-2">
                            {sprintf(__("Luck coefficient: +%s"), addLuck.toString())}
                        </div>  
                    </div>
                    <div className="dice-container">
                    {
                        isActive
                            ?
                            <Button minimal large onClick={ doDice } className="w-100 h-100 danger-alarm">
                                <div className="display-6 text-uppercase  text-light">
                                    {__(dictionary.DoDice || "Do dice")}
                                </div>
                            </Button> 
                            :
                            props.attack?.map((dice: number, i: number) => { 
                                return <DiceSingle 
                                    { ...props } 
                                    i={ i } 
                                    key={ i }
                                    start={ attack } 
                                    result={ aResult[i] } 
                                    isActive={ isActive } 
                                />
                            })
                    } 
                    </div>
                </div>
            </div>  
            {
                isResult
                ?
                <div className="flex-centered h-100 position-absolute top left w-100 h-100 bg-blur ">
                    { showResult() }
                </div> 
                :
                <></>
            }
        </div>
        
    </div>
}
export default Dice

export const DiceSingle = (props:  IDiceProps | any ) => { 
    
    const [className, setClassName] = useState<string>( `cube${ props.start + props.i }` )
    //const [isActive, setIsActive] = useState<boolean>( !!props.isActive )
    useEffect(() => {
        let timeout: any  = null
        if(!props.isActive) {
            timeout = setTimeout(() => {
            setClassName( `to${props.result}` )
        }, 300)
        }        
        return () => { 
            if( timeout ) 
                clearTimeout(timeout) 
        }
    }, [ props.isActive ])
    
    return !props.isActive
        ?
        <div className={ `dice` } >
            <div className="mycube">
                <div className={`cube  ${className} ${ props.anti ? "anti" : "" }`}>
                    <div className="a">
                        <div className=" dots dots-one">
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                        </div>
                    </div>
                    <div className="b">
                        <div className="dots dots-two">
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                        </div>
                    </div>
                    <div className="c">
                        <div className="dots dots-three">
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                        </div>
                    </div>
                    <div className="d">
                        <div className="dots dots-four">
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                        </div>
                    </div>
                    <div className="e">
                        <div className="dots dots-five">
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                        </div>
                    </div>
                    <div className="f">
                        <div className="dots dots-six">
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-no-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-dot"></div>
                            <div className="fabula-dice-dot"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <></>
}