import { Button, ButtonGroup, Intent, NumberRange, RangeSlider } from '@blueprintjs/core'
import { __ , sprintf } from '@/libs/utilities'
import React, { useState } from 'react' 

const MetaFilterElement = ({ field }: any) : JSX.Element => {
    const [select, setSelect] = useState<any>( null )

    const changeSelect = (val: any) => {
        setSelect( val )
    }
    
    switch(field.type)
    {
        case "number":
            return <div className="p-2 w-32 w-sm-100 card m-1 opacity_75" >
                <div className="title text-secondary">
                    { __( field.title ) }
                </div>
                <div>
                    <RangeSlider
                        min={ field.min || 0 }
                        max={ field.max || 100 }
                        stepSize={1}
                        labelStepSize={ field.max - field.min < 20 ? 1 : 20 } 
                        onChange={(range: NumberRange) => changeSelect( range ) }
                        value={select || [ 0, field.max || 100 ]} 
                        handleHtmlProps={
                            { 
                                start:  { "aria-label": "example start" }, 
                                end:    { "aria-label": "example end" } 
                            }
                        }
                    />
                </div>
            </div>
        case "boolean":
            return <div className="p-2 w-sm-100 card m-1 opacity_75" >
                <div className="title text-secondary">
                    { __( field.title ) }
                </div>
                <ButtonGroup>
                    <Button
                        intent={Intent.SUCCESS}
                        minimal={select !== "1"}
                        data-val="1"
                        data-field={field.field}
                        onClick={ () => changeSelect( "1" ) }
                    >
                        {__("Yes")}
                    </Button>
                    <Button
                        intent={Intent.DANGER}
                        minimal={select !== ""}
                        data-val="2"
                        data-field={field.field}
                        onClick={ () => changeSelect( "2" ) }
                    >
                        {__("No")}
                    </Button>
                    <Button
                        intent={Intent.NONE}
                        minimal
                        data-val="0"
                        data-field={field.field}
                        onClick={ () => changeSelect( "0" ) }
                    >
                        {__("clear filter")}
                    </Button>
                </ButtonGroup>
            </div> 
        case "component":
            const sel = [].map(( e: any, i: number ) => { 
                const selct = false
                return <option value={e.id} key={e.id} selected={selct}>
                    {e.post_title}
                </option>
            })
            return <div className="p-2 w-sm-100 card m-1 opacity_75" >
                    <div className="title text-secondary">
                        { __(field.title) }
                    </div>
                    <select
                        className="filter form-control"
                        name={field.name}
                        onChange={ () => changeSelect( field.name ) }
                    >
                        <option value="-1">---</option>
                        {sel}
                    </select>
                </div>  
        default:
            return <div className="p-2 w-32 w-sm-100  card m-1 opacity_75" >
                <div className="title text-secondary">
                    { __( field.title ) }
                </div>
                <div>
                    <input 
                        type="text"
                        placeholder={ sprintf ( __("Insert %s for filter"), __(field.title) ) } 
                        className="form-control input dark"
                        onChange={ evt => changeSelect( evt.target.value ) }
                    />
                </div>
            </div>
    }
} 

export default MetaFilterElement