import { Button, Intent } from "@blueprintjs/core"
import { useEffect } from "react"
import { useState } from "react" 
import SingleFormField from "./SingleFormField"
import { __ } from "@/libs/utilities"
import {default as UUID} from "node-uuid"

interface IProps {
    data: any
    onChange: (field: any) => void
}
const FormFields = (props : IProps) : JSX.Element =>
{
    const [fields, changeFields] = useState( props.data )
    useEffect(() => {
        changeFields( props.data )
    }, [props.data])
    //console.log( fields )
    const onSwitchUp = (i: number) =>
    {
        let _fields = [...fields];
        const fld = _fields[i]
        _fields.splice(i, 1)
        _fields.splice(i - 1, 0, fld)
        changeFields(_fields)
        props.onChange(_fields )
    }
    const onSwitchDown = (i : number) =>
    {
        let _fields = [...fields];
        const fld = _fields[i]
        _fields.splice(i, 1)
        _fields.splice(i + 1, 0, fld)
        changeFields(_fields)
        props.onChange(_fields )
    }
    const onRemove = (i: number) =>
    {
        let _fields = [...fields];
        _fields.splice(i,1)
        changeFields(_fields)
        props.onChange(_fields )
    }
    const onChange = (i: number, data: any) =>
    {
        let _fields = [...fields];
        _fields[i] = {..._fields[i], ...data}
        changeFields(_fields)
        props.onChange(_fields )
    }
    const OnSubMenu = (i: number, value: any, field: string) =>
    {
        //console.log(i, value, field)
        let _fields = [...fields];
        _fields[i][field] = value
        changeFields(_fields)
        props.onChange(_fields )
    }
    const onAdd = () =>
    {
        let _fields = [...fields];
        _fields.push({
            id: UUID.v4(),
            count:1,
            name: __("Added property ") +  fields.length,
            description:"",
            type:"string"
        })
        changeFields(_fields)
        props.onChange(_fields )
    }
   
    //console.log( fields )
    const forms = fields.map((form: any, i: number) =>
    {
        if( !form.id ) form.id = UUID.v4()
        return <SingleFormField
            key={form.id}
            i={i}
            {...form} // value, name, description, type
            disabledUp={ i === 0 }
            disabledDown={ i === fields.length - 1 }
            onSwitchUp={ onSwitchUp }
            onSwitchDown={ onSwitchDown }
            onRemove={ onRemove }
            onChange={ onChange } 
            OnSubMenu={ OnSubMenu } 
        />
    })
    return <div>
        {forms}
        <div>
            <Button intent={Intent.DANGER} icon="plus" onClick={onAdd} >
                {__("Add new field")}
            </Button>
        </div>
    </div>
}
export default FormFields