import React from 'react' 
import { ICardProjectProps } from '../../data/interfaces'
import { Link } from 'react-router-dom'
import empty from "../../assets/img/empty.png"  
import { getRoute, getSingleRoute } from 'src/libs/layouts'
import { useOwnerPartner, useUserOwner } from '../../hooks/project'
import { __ } from '@/libs/utilities'

const BoxProject = ( props: ICardProjectProps ): JSX.Element => {
    const isOwner: boolean = useUserOwner( props.item )
    const isPartner:boolean = useOwnerPartner(props.item)
    const routeSingle = getSingleRoute("PEFestProject") 
    const url = routeSingle ? `/${ getRoute(routeSingle) }/${props.item.id}` : "/" 
    return (
        <div className="col-xl-1_8 col-lg-2 col-md-3 col-sm-4 col-6">
            <Link to={url} className={`overflow-hidden ${!props.item.is_verified ? "opacity_5" : ""}`}>
                <div className={`member ${ "cl" }`}>
                    <img src={empty} className="empty" alt="" />
                    <div className="member_title">
                        {props.item.order}
                    </div>
                    <div className="xperts " data-cl={props.i === 0 ? "hidden" : ""}>
                        {props.i}
                    </div>
                    {
                        isOwner
                            ?
                            <div className="mine-corner mine-corner-box">
                                { __( "My project" ) }
                            </div>
                            :                    
                            isPartner 
                                ?
                                <div className="mine-honeycombs-corner mine-corner-box">
                                    {__("My honeycombs")}
                                </div>
                                :
                                null
                    }
                    {
                        !props.item.is_verified && 
                            <div 
                                className="mine-corner-no-verified corner-card-project"
                                style={{
                                    right: -30,
                                    top: -26,
                                    fontSize: 8,
                                    width: 120,
                                    height: 51,
                                }}
                            >
                                {__("Project has not yet been approved")}
                            </div>
                    }
                </div>
            </Link>
        </div>
    )
} 

export default BoxProject