import { ID } from "src/libs/interfaces/layouts" 
import { IAcademicDiscipline } from "../interfaces"


export const academicDiscipline = (): IAcademicDiscipline[] => {
    const list: IAcademicDiscipline[] = [
        {
            id: '24',
            thumbnail: 'false',
            title: 'Краеведение',
            post_content: '', 
            order: 1,
            color: '#007926',
        },
        {
            color: '#00964a',
            id: '21',
            order: 2,
            post_content: '',
            thumbnail: '',
            title: 'природа',
        },
        {
            color: '#aa4500',
            id: '20',
            order: 3,
            post_content: '',
            thumbnail: 'https://barmingrad.wpfest.ru/wp-content/uploads/sites/105/2023/12/2e4ef6119922393.60a75b0a2ab17-scaled.jpg',
            title: 'транспорт',
        },
        {
            color: '#001979',
            id: '22',
            order: 4,
            post_content: '',
            thumbnail: 'false',
            title: 'социология',
        },
        {
            color: '#c005ff',
            id: '23',
            order: 5,
            post_content: '',
            thumbnail: 'false',
            title: 'культура',
        },
        {
            color: '#214700',
            id: '25',
            order: 6,
            post_content: '',
            thumbnail: 'false',
            title: 'Литература',
        },
        {
            color: '#052900',
            id: '26',
            order: 7,
            post_content: '',
            thumbnail: 'false',
            title: 'Математика',
        },
        {
            color: '#004e8d',
            id: '27',
            order: 9,
            post_content: '',
            thumbnail: 'false',
            title: 'География',
        },
    ]
    return list
}

export const getACByID = (id: ID) => {
    const d = academicDiscipline().filter(ad => ad.id === id)[0]
    return d ? d : academicDiscipline()[0]
}
