import { IFabulaMedia, IFabulaStaff, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { Fragment } from "react"
import TileEpisodeStuffLabel from "./TileEpisodeStuffLabel"

/* Protopia Ecosystem component */
export interface ITileEpisodeStuffsProps {
    item: IFabulaMedia
}
const TileEpisodeStuffs = (props: ITileEpisodeStuffsProps): JSX.Element => {
    const story: IPEStory = useStorytellingStore( (state: IStorytellingStore) => state.current )
    return <>
    {
        props.item.staff?.map(( staffID, i ) => {
            const staff : IFabulaStaff = story.staff.filter(s => s.id === staffID)[0]
            if( !staff )    return <Fragment key={staffID} />
            return <TileEpisodeStuffLabel item={staff} i={i} key={ staffID } />
        })
    }
    </>
}
export default TileEpisodeStuffs