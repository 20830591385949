import { Button } from "@blueprintjs/core"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IFilterProps {
    onFilter: (value: string) => void
}
const Filter = (props: IFilterProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [value, setValue] = useState<string>( "" )
    const onOpen = () => {
        setIsOpen(!isOpen)
    }
    const onChange = (string: string) => {
        setValue( string )
        props.onFilter( string )
    }
    return <div className='pe-filter-container'>
        <Button minimal icon="filter" onClick={onOpen} />
        <FilterPanel isOpen={isOpen} value={value} onChange={onChange}/>
    </div>
}
export default Filter

/* Protopia Ecosystem component */
export interface IFilterPanelProps {
    isOpen: boolean
    value: string
    onChange: (value: string) => void
}
const FilterPanel = (props: IFilterPanelProps): JSX.Element => {
    const onChange = (evt: any) => {
        props.onChange(evt.currentTarget.value)
    }
    const onClear = () => {
        props.onChange("")
    }
    return <div className={`pe-filter-panel-container ${props.isOpen ? "" : "closed"}`}>
        <input type="text" value={props.value} onChange={onChange} className="dark input"/>
        <Button minimal icon="cross" onClick={onClear}/>
    </div>
} 