import { __ } from "@/libs/utilities"
import FestivalStatusForm from "./FestivalStatusForm"
import { useParams } from "react-router"
import { FEST_ADMIN_STATUSES, IFestival } from "../../data/interfaces"
import { useAppoloStore } from "@/settings/zustand"
import gql from "graphql-tag"
import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { GET_FESTIVAL_SETTINGS_ACTION } from "../../data/actionTypes"
import FestivalSiteOwnerForm from "../festival-admin-list/FestivalSiteOwnerForm"
import FestAdminForm from "./FestAdminForm"
import RoleCarriersForm from "../../../pe-topos-module/views/admin/RoleCarriersForm"
import EventTypeLabel from "@/modules/pe-topos-module/views/labels/EventTypeLabel"
import { eventTypes } from "@/modules/pe-topos-module/data/mocks/eventTypes"
import { useFestDataStore } from "../../data/store"
import { LoaderBallsLine, LoaderMinimal } from "@/libs/useful/Loading"

/* Protopia Ecosystem component */
export interface ICabinetPhaseFormProps {

}
const CabinetPhaseForm = (props: ICabinetPhaseFormProps): JSX.Element => {
    const {landId} = useParams()
    const [festival, setFestival] = useState<IFestival>( {} as IFestival )
    const [isLoad, setIsLoad] = useState<boolean>(true)
    useEffect(() => { 
        actions(GET_FESTIVAL_SETTINGS_ACTION, {id: landId})
            .then(
                response => {
                    // console.log(response) 
                    setIsLoad(false)
                    setFestival(response) 
                }
            ) 
    }, [])
    const onPhase = ( status: FEST_ADMIN_STATUSES ) => {
        setFestival({ ...festival, status })
        setIsLoad(true)
        const land_id = landId 
            ?
            ` land_id:"${landId}" `
            :
            ``
        const mutation: string =  `
            mutation changePEFestival {
                changePEFestival (
                    input: { 
                        status: ${ status }
                    }
                    ${ land_id }
                ) {
                    status
                }
            }`
        const apolloClient: any = useAppoloStore.getState().client
        apolloClient.mutate({
            mutation : gql`${mutation}`
        })
        .then((resp: any) => {
            setIsLoad(false)
            console.log( resp.data.changePEFestival.status ) 
        })
    }
    return <div className='card mb-4' >
        <div className="card-body p-0">
            <div className="d-flex justify-content-between align-items-center border-bottom-1 border-secondary ">
                <div className="flex-centered">
                    <h5 className='card-title pt-3 px-4 pb-1 text-nowrap'>
                        <span className="mr-3">
                            <i className="fas fa-marker"></i>    
                        </span> 
                        {__( "Prompt actions" )}
                    </h5>
                    { isLoad && <LoaderBallsLine /> }  
                </div> 
                <EventTypeLabel 
                    className=""
                    item={{ 
                        ...useFestDataStore.getState().site, 
                        eventTypes: eventTypes().filter(et => {
                            return useFestDataStore.getState().site.event_types.includes(et.title)
                        }) 
                    }} 
                /> 
            </div>
            <div className="p-4 row">
                <div className="col-md-5">
                    <FestivalStatusForm festival={festival} onPhase={onPhase} />
                </div> 
                <div className="col-md-7">
                    <RoleCarriersForm />
                </div>
            </div>
        </div>
    </div>
}
export default CabinetPhaseForm