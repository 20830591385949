
/* Protopia Ecosystem component */
export interface IBreakNewsFormProps {

}
const BreakNewsForm = (props: IBreakNewsFormProps): JSX.Element => {
    return <div className='pe-breaknewsform-container'>
        <div className="p-md-4 display-7">Последние новости</div>
    </div>
}
export default BreakNewsForm