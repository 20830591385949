import { schema } from "@/libs/layouts"
import { Type } from "./Type"

/* Protopia Ecosystem component */
export interface IRightLayoutProps {
    singled_data_type: string
    extend_params: any
    contentClassName: string
    data: any
}

export const RightLayout = (props:IRightLayoutProps) : JSX.Element[] => {
    const { singled_data_type, extend_params } = props
    const data_scheme: any = schema()[singled_data_type] 
    const fields: JSX.Element[] = Object.keys(data_scheme.apollo_fields)
        .filter((field: string) => field !== "__typename" && 
            field !== "post_title" &&
            field !== "post_content" &&
            !data_scheme.apollo_fields[field].hidden
        )
            .filter((field: string) => { 
                return Array.isArray(extend_params?.show_fields) && 
                    extend_params.show_fields.includes( field )
            })
                .map((field: string, i: number) => {
                    return <div className="row" key={i}>
                        <Type {...props} field={field} /> 
                    </div>
                })
    return fields 
}