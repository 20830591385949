import React, { useEffect, useState } from 'react' 
import { initArea } from '../utilities/getWidget'
import { NavLink } from 'react-router-dom'
import { title, description } from "@/libs/layouts"
import { iconHeight, iconUrl, iconWidth, iconBg } from "@/libs/layouts"
import LayoutHeaderMenu from './header/LayoutHeaderMenu'
import LayoutUser from './header/LayoutUser'
import LayoutHeaderMobile from './header/LayoutHeaderMobile'
import LayoutHeaderHeader from './header/LayoutHeaderHeader'
import { WINDOW_WIDTH } from '../interfaces/layouts'
import { Widgets } from '../utilities'

const LayoutHeader = ( props: any ) : JSX.Element => {
  const [isFixed, setFixed] = useState(false)
  const [isHumburger, setHumburger] = useState(false)
  const [isMobyle, setMobyle] = useState( window.innerWidth < WINDOW_WIDTH.TABLET )
  const fixedClass = isFixed ? "layout-header fixed animation-opened animated2" : "layout-header"
  useEffect(() => {
	const onResizeHandler = () => {
		setMobyle( window.innerWidth < WINDOW_WIDTH.TABLET )
	}
	window.addEventListener("resize", onResizeHandler)
    return () => window.removeEventListener("resize", onResizeHandler)
  }, [])
  const onHumburger = () => {

  }
  return isMobyle
  	?
  	<LayoutHeaderMobile />
	:
    <div className={fixedClass}>
      	<div className="layout-header-left">
		  <LayoutHeaderHeader />
		</div>
		<Widgets areaName="layout-header-center" data={{...props}} /> 
		<div className={`d-flex-menu ${ isHumburger ? "open" : ""}`}>
			<LayoutHeaderMenu onHumburger={ onHumburger}  />
		</div>
		<div
			className={`layout-menu-right ${ isHumburger ? "open" : ""}`}
			id="layout-menu-right"
		>
			<LayoutUser isOpen={false} />
		</div>
    </div> 
} 

export default LayoutHeader