import { FABULA_MINI_GAME, IFabulaMedia, IFabulaMediaType } from "../../interfaces";

export const KriogenmashLibrary = () : IFabulaMedia => {
    return {
        id: "612112131",
        title:"Старый фильтр кислородного компрессора",
        post_content: "Компрессор давно демонтирован, но артерия фильтра до сих пор не закрыта. Интересно, почему стоит замок?",
        type: IFabulaMediaType.IMAGE,
        x: 14,
        y: 61,
        thumbnail: "/assets/img/fabula/Bear/bear612112131.png",
        speech:[],
        music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
        media: [
            {
                id: "6121121311",
                title:"Старый фильтр кислородного компрессора",
                post_content: "Компрессор давно демонтирован, но артерия фильтра до сих пор не закрыта. Интересно, почему стоит замок?",
                type: IFabulaMediaType.IMAGE,
                x: 42,
                y: 41,
                thumbnail: "/assets/img/fabula/Bear/bear612112131.png",
                speech:[],
                protection: {
                    id: "612112131",
                    title: "Электронный замок",
                    post_content: "Попробуйте взломать. Необходимо с двух попыток выбросить больше, чем противник.",
                    type: FABULA_MINI_GAME.Dice,
                    parameters : {
                        dictionary: {
                            Defense: "Замок",
                            Hacker: "Хакер",
                        },
                        defense: [ 6, 6, ],
                        attack: [ 6, 6, 6, ],
                        times: 2,
                        keys: [ "1", "2" ]
                    },
                },
                media: [
                    {
                        id: "base-1",
                        title:"Коридор старого заброшенного цеха",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 42,
                        y: 46,
                        thumbnail: "/assets/img/fabula/Bear/base-3.png",
                        speech:[],
                        music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                        media: [                                                                                                                
                            {
                                id: "base-2",
                                title:"Коридор старого заброшенного цеха",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 56,
                                y: 52,
                                thumbnail: "/assets/img/fabula/Bear/base-2.png",
                                speech:[],
                                music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                media: [
                                    {
                                        id: "base-3",
                                        title:"Воздушный фильтр заброшенного цеха",
                                        post_content: "",
                                        type: IFabulaMediaType.IMAGE,
                                        x: 59,
                                        y: 67,
                                        thumbnail: "/assets/img/fabula/Bear/base-4.png",
                                        speech:[],
                                        music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                        media: [
                                            {
                                                id: "filter-listen",
                                                title:"Герметизационная гелевая мембрана",
                                                post_content: "",
                                                type: IFabulaMediaType.IMAGE,
                                                x: 74,
                                                y: 46,
                                                thumbnail: "/assets/img/fabula/Bear/base-4-1.png",
                                                speech:[],
                                                music: "/assets/data/fabula/Bear/music/vacuum1.mp3",
                                                defense: 3,
                                                protection: {
                                                    id: "base-4-1",
                                                    title: "Вакуумная мембрана",
                                                    post_content: "Проникнуть сквозь гель",
                                                    type: FABULA_MINI_GAME.Dice,
                                                    parameters : {
                                                        dictionary: {
                                                            Defense: "Вакуумная мембрана",
                                                            Hacker: "Проникающий",
                                                            YouLuck: "Проникновение успешно",
                                                            DefenseSurvived: "Гель слишком плотный",
                                                            DoDice: "Пробиться",
                                                        },
                                                        defense: [ 6, 6, ],
                                                        attack: [ 6, 6, 6, ],
                                                        times: 2,
                                                        keys: [ "1", "2" ]
                                                    },
                                                },
                                                media: [ 
                                                    {
                                                        id: "filter-listen-1",
                                                        title: "Вид через решетку фильтра",
                                                        post_content: "",
                                                        type: IFabulaMediaType.IMAGE,
                                                        x: 74,
                                                        y: 46,
                                                        thumbnail: "/assets/img/fabula/Bear/base-4-1.png",
                                                        speech:[],
                                                        media: [ 
                                                            {
                                                                id: "filter-listen",
                                                                title:"Фойе конспиративной лаборатории",
                                                                post_content: "",
                                                                type: IFabulaMediaType.IMAGE,
                                                                x: 58,
                                                                y: 58,
                                                                thumbnail: "/assets/img/fabula/Bear/base-4-2.png",
                                                                speech:[],
                                                                media: [
                                                                    {    id: "base-1",
                                                                        title:"Зал конспиративной лаборатории",
                                                                        post_content: "",
                                                                        type: IFabulaMediaType.IMAGE,
                                                                        x: 42,
                                                                        y: 46, 
                                                                        thumbnail: "/assets/img/fabula/Bear/base-5.jpg",
                                                                        media: [],
                                                                        speech:[],
                                                                    },
                                                                    {    
                                                                        id: "base-2",
                                                                        title:"Запасной выход",
                                                                        post_content: "",
                                                                        type: IFabulaMediaType.IMAGE,
                                                                        x: 82,
                                                                        y: 46, 
                                                                        thumbnail: "/assets/img/fabula/Bear/base-5-1.png",
                                                                        speech:[],
                                                                        media: [
                                                                            {
                                                                                id: "base-3",
                                                                                title:"Засекреченный шлюз",
                                                                                post_content: "",
                                                                                type: IFabulaMediaType.IMAGE,
                                                                                x: 48,
                                                                                y: 61,
                                                                                thumbnail: "/assets/img/fabula/Bear/base-5-2.png",
                                                                                media: [
                                                                                    {
                                                                                        id: "lift-1",
                                                                                        title:"Лифт в вакуумную зону",
                                                                                        label: "Кингстон лифта в вакуумную зону",
                                                                                        post_content: "",
                                                                                        type: IFabulaMediaType.IMAGE,
                                                                                        x: 49,
                                                                                        y: 45,
                                                                                        thumbnail: "/assets/img/fabula/Bear/lift-1.jpg",
                                                                                        media: [
                                                                                            {
                                                                                                id: "lift-1",
                                                                                                title:"Выход в вакуумный цех",
                                                                                                label: "Подняться в производственную зону",
                                                                                                post_content: "",
                                                                                                type: IFabulaMediaType.IMAGE,
                                                                                                x: 49,
                                                                                                y: 50,
                                                                                                thumbnail: "/assets/img/fabula/Bear/lift-2.jpg",
                                                                                                media: [
                                                                                                    {
                                                                                                        id: "lift-1",
                                                                                                        title:"Задворки Качалинской сторожки",
                                                                                                        post_content: "",
                                                                                                        type: IFabulaMediaType.IMAGE,
                                                                                                        x: 1,
                                                                                                        y: 54,
                                                                                                        to: "511",
                                                                                                        thumbnail: "/assets/img/fabula/Bear/lift-1.jpg",
                                                                                                        media: [
                        
                                                                                                        ],
                                                                                                        speech:[],
                                                                                                    },
                                                                                                ],
                                                                                                speech:[],
                                                                                            },
                                                                                        ],
                                                                                        speech:[],
                                                                                    },
                                                                                ],
                                                                                speech:[],
                                                                            },
                                                                        ],
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    } 
                                                ],
                                            },
                                        ],
                                    }
                                ],
                            }
                        ],
                    }
                ] 
            }
        ]
    }
}