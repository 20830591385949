
import { useToposStore } from "../pe-topos-module/data/store"
import LabyrinthSettingsForm from "./views/admin/LabyrinthSettingsForm" 

const init = () => {
    return new Promise<any>((resolve, reject) => {
        useToposStore.setState({
            eventTypeAdminData: {
                ...useToposStore.getState().eventTypeAdminData,
                Labyrinth: { 
                    settings : {
                        title: "Fabula setting",
                        group: "Labyrinth setting", 
                        component: LabyrinthSettingsForm,
                        position: "left",
                        icon: <i className="fas fa-dungeon"></i>, 
                    },  
                    editor : {
                        title: "New Story Editor",
                        group: "Labyrinth setting", 
                        component: LabyrinthSettingsForm,
                        position: "left", 
                    },  
                    stories : {
                        title: "Stories",
                        group: "Labyrinth setting",
                        undergroup: "stories", 
                        component: LabyrinthSettingsForm,
                        position: "left"
                    }, 
                },
            },
            eventRoutes: {
                ...useToposStore.getState().eventRoutes,
                story: { 
                    path: "story",  
                    elementName: "../../pe-fabula-module/views/fabula/TaleForm",
                }
            }
        })
        resolve( true )
        reject( false )
    }) 
}
export default init