import { IFestStore, useFestStore } from "@/modules/pe-fest-module/data/store"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import { Button, Dialog, Icon, Intent } from "@blueprintjs/core"
import { useState } from "react" 

const SearchEngineMobile = () : JSX.Element => {
    const search: string                        = useFestStore((state: IFestStore) => state.search)
    const setSearch:(search: string) => void    = useFestStore((state: IFestStore) => state.setSearch)
    const [ isOpen, setIsOpen ]                 = useState( false ) 
    const [ curSeach, setCurSeach ]             = useState( search )

    const onSearch = ( value: string ) => {
        console.log( value )
        setCurSeach( value )
    }
    const startSearch = () => {
        setSearch( curSeach )
        setIsOpen(false)
    }
    const onClear = () => { 
        setCurSeach( "" )
        setSearch( "" )
    }
    const onOpen = () => setIsOpen(!isOpen)
    return <>
        <Button 
            intent={Intent.DANGER} 
            className={ !!search ? "" : " d-none "} 
            icon="cross"
            onClick={onClear}
        />
        <Button icon="search" minimal onClick={onOpen} />
        <Dialog
            isOpen={isOpen}
            onClose={onOpen}
        >            
            <Button
                minimal
                onClick={onOpen}
                className="position-absolute top dialog-close-btn" 
            >
                <Icon size={22} color="#ffffff" icon="cross"/>    
            </Button>
            <div className="w-100 pl-3">
                <FieldInput
                    type={SCALAR_TYPES.STRING}
                    editable
                    placeholder={__("Search Member")}
                    onChange={ onSearch }
                    value={ curSeach }
                />
                <Button
                    minimal
                    onClick={ startSearch }
                >
                    {__("Search")}
                </Button>
            </div>
        </Dialog>
    </>
}
export default SearchEngineMobile