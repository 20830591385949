
import BasicState from "@/libs/basic-view"
import TGLoginFinishForm from "./t-g-login-finish/TGLoginFinishForm"

export default class TGLoginFinishView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 m-0 position-relative">
            <TGLoginFinishForm {...this.props} />  
        </div>
    }
}