import React from 'react' 
import LayoutMenuLeft from '../body/LayoutMenuLeft'
import LayoutMenuMain from '../body/LayoutMenuMain'
import LayoutMain from '../LayoutMain'
import LayoutServices from '../body/LayoutServices'
import LayoutRight from '../right/LayoutRight'
import useScrollDirection from '@/modules/pe-basic-module/hooks/useScrollDirection'

const LayoutContent = () => {
  const scrollDirection = useScrollDirection() 
  return (
    <div className={`layout-content dgsdrgdf ${ scrollDirection } ${ scrollDirection === "none" ? "opened" : "closed" }`} >
      <LayoutMenuLeft />
      <LayoutMenuMain />
      <LayoutMain />
      <LayoutServices />
      <LayoutRight />
    </div>
  )
} 

export default LayoutContent