import { Loading } from "@/libs/useful"
import { useEffect, useState } from "react"

/* Protopia Ecosystem component */
export interface IHelpHTMLSourceProps {
    item: any
}
const HelpHTMLSource = (props: IHelpHTMLSourceProps): JSX.Element => { 
    const [loading, changeLoading] = useState(true)
    const [html, setHtml] = useState<string>("")
    useEffect(() => { 
        fetch(
            props.item.url,
            {
                method: 'GET' 
            }
        )
        .then(response => {
            console.log( response )
            return response.text()
        })
        .then(response => {
            setHtml( response.toString() )
            changeLoading(false)
        })
    }, [])
    return <div className='pe-help-html-source-container '>
        <h2 className="px-4 pt-3">{ props.item.title }</h2>
        <div>
        { 
            loading
                ?
                <div className="w-100 h-100 d-flex flex-column align-items-center ">
                    <Loading />
                </div>
                :
                <div 
                    className={ `pe-help-html-source overflow-y-auto `}
                    dangerouslySetInnerHTML={{ __html: html }}
                /> 
        }
        </div>
    </div>
}
export default HelpHTMLSource