import { __ } from "@/libs/utilities"
import FestAdminForm from "../festAdmin/FestAdminForm"
import { useParams } from "react-router"
import { useEffect, useState } from "react"
import { actions } from "../../data/actions" 
import { GET_FESTIVAL_SETTINGS_ACTION } from "../../data/actionTypes"

/* Protopia Ecosystem component */
export interface IFestivalSingleAdminProps {
    path: string
}
const FestivalAdminSingle = (props: IFestivalSingleAdminProps): JSX.Element => {
    const params = useParams()  
    const [title, setTitle] = useState<string>("")
    useEffect(() => {
        actions(GET_FESTIVAL_SETTINGS_ACTION, { id: params.landId, fields:["title"], ignoreSet:true })
            .then(resp => {
                setTitle( resp.title )
            })
    }, [ params.landId ])
    return <div className=' container '>
        <div className=" my-4 border-bottom border-secondary ">
            <div className="display-8 mx-4 mb-2">
                {__("Fesival control board")} <span className="font-weight-bold">{title}</span>
            </div>
        </div>
        <FestAdminForm 
            route={ `${ props.path }/${ params.landId }` } 
            title={ title }
        />
    </div>
}
export default FestivalAdminSingle