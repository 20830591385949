
import BasicState from "@/libs/basic-view"
import FestivalViewForm from "./festival-view/FestivalViewForm"

export default class FestivalView extends BasicState {
    props: any
    addRender = () => {
        return <div className="d-flex w-100 h-100 m-0 position-relative">
            <FestivalViewForm {...this.props} />  
        </div>
    }
}