 import React from 'react'
 import BasicState from "@/libs/basic-view"
import DataForm from './single/DataForm' 

export class DataView extends BasicState {  
    props: any
    render() {
        return <div className="container mb-5">
            <DataForm {...this.props} />
        </div>
    }
}

export default DataView 