import { useEffect, useRef, useState } from "react"
import { IPEVKPost, URL } from "src/libs/interfaces/layouts" 
import { GalleryItem } from "../components/gallery/Gallery"
import GalleryDialog from "../components/gallery/GalleryDialog"

interface IThumbnail {
    item: IPEVKPost
}
const ThumbnailTile = ({item, ...props}: IThumbnail) : JSX.Element => {
    const [isOpen, setIsOpen] = useState( false )
    const [i, setI] = useState(0)
    if(!item.thumbnail && !!item.images?.length ) return <></> 
    const gallery = [ item.thumbnail, ...item.images! ]
    const to = (i :number) => {
        setI(i)
        setIsOpen( true )
    }
    let _gallery : JSX.Element
    switch(gallery.length)
    {
        case 0:
            _gallery = <></>
            break
        case 1:
            _gallery = <Gallery1 gallery={gallery}  to={to} start={0}/>
            break
        case 2:
            _gallery = <Gallery2 gallery={gallery}  to={to} start={0}/>
            break
        case 3:
            _gallery = <Gallery3 gallery={gallery}  to={to} start={0} />
            break
        case 4:
            _gallery = <Gallery4 gallery={gallery}  to={to} start={0} />
            break
        case 5:
            _gallery = <Gallery5 gallery={gallery}  to={to} start={0} />
            break
        case 6:
            _gallery = <Gallery6 gallery={gallery}  to={to} start={0} />
            break
        default:
            _gallery = <Gallery gallery={gallery}   to={to} start={0} />
    }
    return <>
        { _gallery }
        <GalleryDialog
            i={ i }
            data={ gallery }
            setI={ setI }
            isOpen={ isOpen }
            setIsOpen={ setIsOpen }
        />
    </>
}

export default ThumbnailTile

interface IGalleryProps {
    gallery: (string | undefined)[]
    to: (i:number) => void
    forseLine?: boolean
    start: number
}
const Gallery1 = (props:IGalleryProps) : JSX.Element => { 
    const [imageP, setImageP] = useState<number[]>([])
    const [refWidth, setRedWidth] = useState<number>(100)
    const start:number = props.start || 0
    const ref = useRef(null)
    useEffect(() => {
        const widths: number[] = []
        const height: number[] = []
        const p: number[] = []
        props.gallery.forEach( (g, i) => { 
            var img = new Image();
            img.onload = function() { 
                if(!this) return
                const _this: any = this as any 
                widths.push( _this.width ) 
                height.push( _this.height )
                p.push(_this.width / _this.height)  
                setImageP( p )
             }
            img.src = g!
        }) 
        const _ref: any  = ref.current as any
        setRedWidth( _ref.offsetWidth - 8 )
    }, [])
    return imageP[0] > 1
        ?
        <div className="vk-gallery" ref={ref}>
            {/* <img src={props.gallery[0]} alt={props.gallery[0]} style={{ width:refWidth }} className="thumbnail" /> */}
            <GalleryItem
                url={props.gallery[0] as URL}
                i={start}
                type="image"
                galleryId=""
                gallery={[]}
                width={ refWidth } 
                height={ refWidth / imageP[ 0 ] }
                to={props.to}
            />
        </div>
        :
        <div className="vk-gallery" ref={ref}>
            {/* <img src={props.gallery[0]} alt={props.gallery[0]} className="thumbnail" /> */}
            <GalleryItem
                url={props.gallery[0] as URL}
                i={start}
                type="image"
                galleryId=""
                gallery={[]} 
                to={props.to}
            />
        </div>
}

const Gallery2 = (props:IGalleryProps) : JSX.Element => { 
    const [imageP, setImageP] = useState<number[]>([])
    const [refWidth, setRedWidth] = useState<number>(100)
    const start:number = props.start || 0
    const ref = useRef(null)
    useEffect(() => {
        const widths: number[] = []
        const height: number[] = []
        const p: number[] = []
        props.gallery.forEach( (g, i) => { 
            var img = new Image();
            img.onload = function() { 
                if(!this) return
                const _this: any = this as any 
                widths.push( _this.width ) 
                height.push( _this.height )
                p.push(_this.width / _this.height)  
                setImageP( p )
             }
            img.src = g!
        }) 
        const _ref: any  = ref.current as any
        setRedWidth( _ref.offsetWidth - 8 )
    }, [])
    return <div className="vk-gallery" ref={ref}>
        {
            props.gallery.map((g, i) => { 
                const width: number = refWidth * imageP[i] / imageP.reduce((s, a) => s + a, 0)  
                //return <img src={g} key={i} alt={g} style={{ width }} className="thumbnail" />
                return <GalleryItem
                    url={g! }
                    i={start + i}
                    type="image"
                    galleryId=""
                    gallery={[]}
                    width={ width }
                    height={ width / imageP[i] }
                    to={props.to}
                />
            })
        }
    </div>
}
const Gallery = (props:IGalleryProps) : JSX.Element => { 
    const [imageP, setImageP] = useState<number[]>([])
    const [refWidth, setRedWidth] = useState<number>(100)
    const start:number = props.start || 0
    const ref = useRef(null)
    useEffect(() => {
        const widths: number[] = []
        const height: number[] = []
        const p: number[] = []
        props.gallery.forEach( (g, i) => { 
            var img = new Image();
            img.onload = function() { 
                if(!this) return
                const _this: any = this as any 
                widths.push( _this.width ) 
                height.push( _this.height )
                p.push(_this.width / _this.height)  
                setImageP( p )
             }
            img.src = g!
        }) 
        const _ref: any  = ref.current as any
        setRedWidth( _ref.offsetWidth - 4 * props.gallery.length )
    }, []) 
    const more: boolean = props.gallery.length > 6
    const _w = more ? refWidth - 100 : refWidth
    return  more
        ?
        imageP[0] > 1
            ?
            <div ref={ref}>
                <Gallery1 gallery={ [ props.gallery[0] ] }  to={props.to} start={start}/>
                <GalleryMore gallery={ props.gallery.slice(1, -1) }  to={props.to} start={start + 1}/>
            </div>
            :
            <div ref={ref}>
                <Gallery2 gallery={ [ props.gallery[0], props.gallery[1] ] } start={start}  to={props.to}/>
                <GalleryMore gallery={ props.gallery.slice(2, -1) }  start={start + 2} to={props.to}/>
            </div>
        :
        <div className="vk-gallery" ref={ref}>
            {
                props.gallery.map((g, i) => { 
                    const width: number = _w * imageP[i] / imageP.reduce((s, a) => s + a, 0)  
                    //return <img src={g} key={i} alt={g} style={{ width }} className="thumbnail" />
                    return <GalleryItem
                        url={g! }
                        i={start + i}
                        type="image"
                        galleryId=""
                        gallery={[]} 
                        width={ width }
                        height={ width / imageP[i] }
                        to={props.to}
                    />
                })
            }
            {
                more && <div className="thumb-tile-btn">
                    ...
                </div>
            }
        </div>
    
}
const GalleryMore = (props:IGalleryProps) : JSX.Element => { 
    const [imageP, setImageP] = useState<number[]>([])
    const [refWidth, setRedWidth] = useState<number>(100)
    const start:number = props.start || 0
    const ref = useRef(null)
    const count = 5
    useEffect(() => {
        const widths: number[] = []
        const height: number[] = []
        const p: number[] = []
        props.gallery.forEach( (g, i) => { 
            var img = new Image();
            img.onload = function() { 
                if(!this) return
                const _this: any = this as any 
                widths.push( _this.width ) 
                height.push( _this.height )
                p.push(_this.width / _this.height)  
                setImageP( p )
             }
            img.src = g!
        }) 
        const _ref: any  = ref.current as any
        setRedWidth( _ref.offsetWidth - 4 * count )
    }, []) 
    const more: boolean = props.gallery.length > count
    const _w = more ? refWidth - 100 : refWidth
    return <div className="vk-gallery" ref={ref}>
            {
                props.gallery.map((g, i) => { 
                    const _ip = imageP.length <= count ? imageP : imageP.slice(1, count + 1 )
                    const width: number = _w * imageP[i] / _ip.reduce((s, a) => s + a, 0)  
                    return i < count 
                        ? 
                        // <img src={g} key={i} alt={g} style={{ width }} className="thumbnail" />
                        <GalleryItem
                            url={g! }
                            i={start + i}
                            type="image"
                            galleryId=""
                            gallery={[]}                            
                            width={ width }
                            height={ width / imageP[i] }
                            to={props.to}
                        />
                        :
                        <></>
                })
            }
            {
                more && <div className="thumb-tile-btn" onClick={() => props.to( count )} >
                    + {props.gallery.length - count}
                </div>
            }
        </div>
    
}

const Gallery3 = (props:IGalleryProps) : JSX.Element => { 
    const [imageP, setImageP] = useState<number[]>([])
    const [refWidth, setRedWidth] = useState<number>(100)
    const start:number = props.start || 0
    const ref = useRef(null)
    useEffect(() => {
        const widths: number[] = []
        const height: number[] = []
        const p: number[] = []
        props.gallery.forEach( (g, i) => { 
            var img = new Image();
            img.onload = function() { 
                if(!this) return
                const _this: any = this as any 
                widths.push( _this.width ) 
                height.push( _this.height )
                p.push(_this.width / _this.height)  
                setImageP( p )
             }
            img.src = g!
        }) 
        const _ref: any  = ref.current as any
        setRedWidth( _ref.offsetWidth - 8 )
    }, [])
    return <div className="vk-gallery" ref={ref}>
        {
            imageP[0] > 1 && !props.forseLine
                ?
                <div>
                    <Gallery1 gallery={[ props.gallery[0] ]} to={props.to} start={start}/>
                    <Gallery2 gallery={[ props.gallery[1], props.gallery[2] ]} to={props.to} start={start + 1}/>
                </div>                
                :
                props.gallery.map((g, i) => { 
                    const width: number = refWidth * imageP[i] / imageP.reduce((s, a) => s + a, 0)   
                    return <GalleryItem
                        url={g! }
                        i={start + i}
                        type="image"
                        galleryId=""
                        gallery={[]}                        
                        width={ width }
                        height={ width / imageP[i] }
                        to={props.to}
                    />
                })
        }
    </div>
}

const Gallery4 = (props:IGalleryProps) : JSX.Element => {
    const [isVertical, setIsVertical] = useState<boolean>(false)
    const start:number = props.start || 0
    useEffect(() => { 
        var img = new Image();
        img.onload = function() { 
            if(!this) return
            const _this: any = this as any 
            setIsVertical( _this.width / _this.height  > 1 ) 
        }
        img.src = props.gallery[0]!
    }, [])
    return props.forseLine
        ?
        <Gallery gallery={ props.gallery } to={props.to} start={start}/>
        :
        isVertical
            ?
            <div className="position-relative">
                <Gallery1 gallery={ [ props.gallery[0] ] } to={props.to} start={start}/>
                <Gallery3 gallery={ [ props.gallery[1], props.gallery[2], props.gallery[3] ]} to={props.to} start={start+1}/>
            </div>
            :
            <div className="position-relative">
                <Gallery2 gallery={[props.gallery[0], props.gallery[1]]} to={props.to} start={start}/>
                <Gallery2 gallery={[props.gallery[2], props.gallery[3]]} to={props.to} start={start+2}/>
            </div>
}

const Gallery4Mini = (props:IGalleryProps) : JSX.Element => {
    const [imageP, setImageP] = useState<number[]>([])
    const [refWidth, setRedWidth] = useState<number>(100)
    const start:number = props.start || 0
    const ref = useRef(null)
    useEffect(() => {
        const widths: number[] = []
        const height: number[] = []
        const p: number[] = []
        props.gallery.forEach( (g, i) => { 
            var img = new Image();
            img.onload = function() { 
                if(!this) return
                const _this: any = this as any 
                widths.push( _this.width ) 
                height.push( _this.height )
                p.push(_this.width / _this.height)  
                setImageP( p )
             }
            img.src = g!
        }) 
        const _ref: any  = ref.current as any
        setRedWidth( _ref.offsetWidth - 22 )
    }, [])
    return <div className="vk-gallery" ref={ref}>
        {
            props.gallery.map((g, i) => { 
                const width: number = refWidth * imageP[i] / imageP.reduce((s, a) => s + a, 0)   
                return <GalleryItem
                    url={g! }
                    i={start + i}
                    type="image"
                    galleryId=""
                    gallery={[]}                    
                    width={ width }
                    height={ width / imageP[i] }
                    to={props.to}
                />
            })
        }
    </div>
}

const Gallery5 = (props:IGalleryProps) : JSX.Element => {
    const [isVertical, setIsVertical] = useState<boolean>(false)
    const start:number = props.start || 0
    useEffect(() => { 
        var img = new Image();
        img.onload = function() { 
            if(!this) return
            const _this: any = this as any 
            setIsVertical( _this.width / _this.height  > 1 ) 
        }
        img.src = props.gallery[0]!
    }, [])
    return isVertical
    ?
    <div className="position-relative">
        <Gallery1 gallery={[props.gallery[0], ]} to={props.to} start={start} />
        <Gallery4Mini gallery={[props.gallery[1], props.gallery[2], props.gallery[3], props.gallery[4], props.gallery[5]]} to={props.to} start={start + 1}/>
    </div>
    : 
    <div className="position-relative">
        <Gallery2 gallery={[props.gallery[0], props.gallery[1], ]} to={props.to} start={start}/>
        <Gallery3 gallery={[props.gallery[2], props.gallery[3], props.gallery[4], props.gallery[5]]} forseLine to={props.to} start={2}/>
    </div>
}

const Gallery6 = (props:IGalleryProps) : JSX.Element => {    
    const start:number = props.start || 0
    return <div className="position-relative">
        <Gallery3 gallery={[props.gallery[0], props.gallery[1], props.gallery[2]]} forseLine to={props.to} start={start}/>
        <Gallery3 gallery={[props.gallery[3], props.gallery[4], props.gallery[5]]} forseLine to={props.to} start={3+start}/>
    </div>
}
