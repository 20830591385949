import React, { Fragment, useMemo, useState } from 'react'  
import { __ } from 'src/libs/utilities'
import { IFestival, IFormField } from '../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../data/store'
import FieldForm from './kraevedRegister/FieldForm'
import { SCALAR_TYPES } from '@/libs/scalars'


const KraefestRegisterForm = (props : any) : JSX.Element | null => {    
    const festival: IFestival = useFestDataStore( (state: IFestDataStor) => state.festival )

    const fieldValues: IFormField[][] = useMemo<IFormField[][]>(() => {
        return Array.isArray( props.form_fields ) ?  props.form_fields : []
    }, [])

    const onExtendFetchParams = (value: any, field: string, n: number, i: any) =>
    { 
        // console.log( value, field, n, i ) 
        props.onExtendFetchParams( value, field, n, i ) 
        return
        // let val: any = value
        // if( festival.extendedFields[ n ].type === SCALAR_TYPES.GEO )
        // {
        //     val = JSON.stringify(value)
        // } 
        // let fields: IFormField[][] = [...form_fields].map( f => [ ...( f || [] ) ] ) 
        // fields[n].splice(i, 1, val) 
        // // console.log( fields )
        // setForm_fields( fields )

    }
    switch ( props.role )
    { 
        case "Tutor": 
        case "Project_author":
            if( !Array.isArray( fieldValues ) ) return null
            const _form_fields = festival.extendedFields
                .map( ( field: any, n: number ) => 
                {         
                    return <Fragment key={ n }> 
                        <FieldForm 
                            n={n}
                            field={ field } 
                            value={ fieldValues[n] || [] } 
                            onChange={onExtendFetchParams}
                        />
                    </Fragment> 
                })  
            return  <>
                { _form_fields }
            </>
        case "Expert":
        default:
            return null
    }
} 

export default KraefestRegisterForm