
import BasicState from "src/libs/basic-view" 
import TaleForm from "./fabula/TaleForm"
import "../assets/css/style.scss" 
import "./../assets/css/style.scss"

export default class FabulaView extends BasicState {
    props: any
    addRender = () => {
        return <> 
            <TaleForm /> 
        </>
    }
}