import React from 'react' 
import { Button, ButtonGroup, Icon, IconName, Intent } from '@blueprintjs/core'  
import { __ } from '@/libs/utilities' 
import { cardSortType } from '../../data/cardSortType' 

const CardFilterEngine = (props: any) :JSX.Element => {
    return <ButtonGroup className='mb-1'>
    {
        cardSortType().map(cardSort =>
            {
                return <Button
                    key={cardSort.id}
                    intent={cardSort.id !== props.cardSort ? Intent.NONE : Intent.DANGER}
                    data-hint={ __(cardSort.type) }
                    minimal={cardSort.id !== props.cardSort}
                    disabled={cardSort.id === props.cardSort}
                    className={`hint hint--top flex-centered `}
                    onClick={ () => props.onClick(cardSort.id) }
                >
                    <Icon icon={ cardSort.icon as IconName } size={12} />
                </Button>
            })
    }
    </ButtonGroup>
} 

export default CardFilterEngine