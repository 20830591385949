import { Collapse, Popover, Position } from "@blueprintjs/core"
import { IManituCell, IOrganoid } from "../../../../data/interfaces"
import { useEffect, useState } from "react"
import { IManitu, useManitu } from "src/modules/pe-manitu-module/data/store"

interface IProps {
    item: IManituCell
    organoid : IOrganoid
    isBroken? : boolean
}
const Organoid = ({organoid, ...props}: IProps) : JSX.Element => {
    let timer: any = -1
    const isActionChoose: boolean = useManitu((state:IManitu) => state.isActionChoose)
    const [isOpen, setIsOpen] = useState<boolean>(false) 
    useEffect(() => { 
        return () => {
            clearTimeout( timer )
        }
    }, [])
    const startTimeout = () => {
        console.log("mouse over")
        timer = setTimeout(() => {
            setIsOpen(true)
        }, 1000 )
    }
    const clearTimeOut = () => {
        console.log("mouse out")
        setIsOpen(false)
        clearTimeout( timer )
    }
    return organoid.id 
        ?
        props.isBroken
        ?
            <div 
                className={` organoid `} 
                style={{
                    backgroundColor: organoid.type?.fill
                }}
            />
            :
            <Popover
                interactionKind="hover"
                hoverOpenDelay={1000}
                position={Position.TOP_LEFT}
                className={`organoid-popover ${isActionChoose ? "untouchble" : ""}`}
                content={
                <div onMouseOver={startTimeout} onMouseLeave={clearTimeOut}>
                    <img 
                        src={ organoid.type?.icon } 
                        width={120} 
                        height={120} 
                        alt={organoid?.title} 
                    />
                    <div className="p-1 text-center small w_120">
                        <div className=" title text-uppercase">
                            { organoid.title }    
                        </div> 
                        <Collapse className="w-100" isOpen={isOpen}>
                            { organoid.post_content } 
                        </Collapse>
                    </div>
                    
                </div>
                }
            >
                <div 
                    className={` organoid `} 
                    style={{
                        backgroundColor: organoid.type?.fill
                    }}
                />
            </Popover>
            :
            <></>
    
} 

export default Organoid