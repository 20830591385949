import { IUser } from "@/settings/zustand"
import { IPlace, ITeam, ITeamType } from "../interfaces"
import { ID } from "@/libs/interfaces/layouts"
import { teamTypes } from "./teamTypes"
import { places } from "./places"

export const teams = () : ITeam[] => {
    const _teams = [
        {
            id: 1 as ID,
            title: "КубИК",
            post_content: "",
            coordinates: [ 45.218626, 39.222946 ],
            type: [1],
            teamTypes: [] as ITeamType[],
            places: [] as IPlace[],
            users: [] as IUser[],
            core: [] as IUser[],
            thumbnail: "https://images.unsplash.com/photo-1641968912757-a8ef0e02ee93?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8OTR8NTgyMTUwfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            images: [
                "https://images.unsplash.com/photo-1693844452683-f179884d5dbd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1553004604-15512a2287f4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1664737063281-1e6c08230650?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8M3w1MTcwNjR8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1625492922316-069306fab13d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1566053166046-30acafc8c60e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTR8NTgyMTUwfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60"
            ],
            url: ""
        },
        {
            id: 2 as ID,
            title: "Мокашовы",
            post_content: "Мы -- дружная семья",
            coordinates: [ 55.498066, 46.417537 ],
            type: [4],
            teamTypes: [] as ITeamType[],
            places: [] as IPlace[],
            users: [] as IUser[],
            core: [] as IUser[],
            thumbnail: "https://images.unsplash.com/photo-1642349920650-2b10b299a54a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8NzV8NTgyMTUwfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
            images: [
                "https://images.unsplash.com/photo-1693844452683-f179884d5dbd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1553004604-15512a2287f4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1664737063281-1e6c08230650?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8M3w1MTcwNjR8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1625492922316-069306fab13d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1566053166046-30acafc8c60e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTR8NTgyMTUwfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60"
            ],
            url: ""
        },
        {
            id: 3 as ID,
            title: "Один экспонат-одна история",
            post_content: `<p>Один экспонат — одна история
            <p>Целью проекта является создание серий видеороликов об истории экспонатов, а все это посвящено юбилею нашего музея.
            Поэтапно решаются поставленные задачи, а это работа над видеороликами: продумывается сюжет, пишется сценарий, делается раскадровка. Затем вся команда приступает к съемкам. Это самый интересный процесс работы, когда приходится делать по несколько дублей, переснимать сцены. Заключительный этап – монтаж видео. Свои фильмы мы публикуем в соц.сетях ВК на рабочей Страничке Мир музея, создали свой канал на Ютубе, где выставляются все работы.
            <p>Сделаны кюаркоды к роликам.
            <p>Имея богатый потенциал нашего музея, больше людей узнает о нем, о его интересных и уникальных экспонатах. Нам захотелось выйти за стены музея, открыть виртуальные возможности его посещения.
            <p>Таким образом, наш проект дает возможность погрузиться в увлекательный мир музея.
            <p>А как же начиналась наша работа? …
            <p>20 мая 2022 года была снята первая (пробная) серия про Репродуктор, в ней мы рассказали об истории Репродуктора, сделали театрализованную постановку военного времени… Этот формат представления истории экспонатов (видеороликом — виртуальной экскурсией) нам очень понравился.
            <p>Так в сентябре появилась идея снять целый сериал «Один экспонат — одна история» и посвятить 55 — летнему юбилею музея. В рамках Проекта Музейного марафона мы реализовали и данный проект.
            <p>Первая серия была выпущена 2 декабря 2022 года, называлась «Живая история».
            <p>В ней мы рассказали об основателях музея, супругах Первушиных, Валентине Трофимовне и Ефиме Савельевиче.
            <p>12 января 2023 года опубликовали 2 серию «Чудесный туесок»! Этот фильм знакомит с историей туеска, изготовлением, со свойствами и широким применением предмета в жизни крестьян.
            <p>В конце февраля вышли 3 серия «Волшебная палочка». В серии рассказывается о палочке-выручалочке, так необходимой нашим хозяйкам на кухне в далеком прошлом. Поэтому- то и фильм мы назвали «Волшебная палочка».
            <p>В апреле к юбилею школы была снята серия «О чем рассказал букварь».
            <p>Наш проект имеет продолжение. Мы планируем продолжить интересную работу!!!
            <p>Данным проектом мы вышли на новый формат экскурсии, знакомим с экспонатами виртуально. Вы можете посмотреть и узнать информацию в соц. сетях, а так же вы можете по QR выйти на видеоролики. Это еще одна новинка нашего проекта.
            <p>Проект имеет практическую значимость, материалы могут быть использованы и применятся на различных уроках, внеклассных мероприятиях, занятиях в музее.
            <p>Надеемся, что продвижение материалов в информационном пространстве, поможет узнать о нашем сельском музее. Привлечет внимание людей разного возраста, в частности молодежи.
            <p>Работа продолжается! Готовится второй сезон съемок сериала.
            <p>https://youtube.com/playlist?list=PLm0M0H14Fx-NHAEmMNdgMQoc32ylQLQvJ&si=jIqdZQp3u9aVxunI ссылка на плейлист`,
            coordinates: [ 54.988695, 73.366878 ],
            type: [3, 2],
            teamTypes: [] as ITeamType[],
            places: [] as IPlace[],
            users: [] as IUser[],
            core: [] as IUser[],
            thumbnail: "https://images.unsplash.com/photo-1596661832058-ed1c1abeddf1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw1ODIxNTB8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
            images: [
                "https://images.unsplash.com/photo-1503093928907-326ec3f06115?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTF8NTgyMTUwfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",    
                "https://images.unsplash.com/photo-1508129974009-c577db1078e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTB8NTgyMTUwfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",    
                "https://images.unsplash.com/photo-1536087199301-871f4ea11e85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8Nnw1ODIxNTB8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",    
            ],
            url: ""
        },
        {
            id: 4 as ID,
            title: "Нижнекамчане",
            post_content: `<p>`, 
            type: [2, 1],
            teamTypes: [] as ITeamType[],
            placesID : [3],
            places: [] as IPlace[],
            users: [] as IUser[],
            core: [] as IUser[],
            thumbnail: "https://images.unsplash.com/photo-1591117752671-541f3495dc93?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8M3w1ODIxNTB8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
            images: [
                "https://images.unsplash.com/photo-1542822319-b54d6adeb664?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTl8NTgyMTUwfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1546789287-6dcdfce962fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTh8NTgyMTUwfHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1609523474327-ace563ba159e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8NXwzMjU3MjF8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
            ],
            url: ""
        },
        {
            id: 5 as ID,
            title: "Kамча развелась",
            post_content: ``, 
            type: [ 3, 2 ],
            teamTypes: [] as ITeamType[],
            placesID : [3],
            places: [] as IPlace[],
            users: [] as IUser[],
            core: [] as IUser[],
            thumbnail: "https://images.unsplash.com/photo-1641263897546-7a13fd15acbb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTI5fDU4MjE1MHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
            images: [
                "https://images.unsplash.com/photo-1638095718355-26cadcdbdab2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTM2fDU4MjE1MHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1640542906114-ceaf00462b0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTU1fDU4MjE1MHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
                "https://images.unsplash.com/photo-1640791441879-509ba4dfdd7e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTY4fDU4MjE1MHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60",
            ],
            url: ""
        },
    ]
    return _teams.map(team => {
        const _teamTypes = teamTypes().filter(tt => team.type.includes( Number(tt.id) ))
        const _places = places().filter(place => team.placesID?.includes( Number( place.id  ) ))
        delete team.placesID
        return {...team, teamTypes: _teamTypes, places: _places}
    }) 
}