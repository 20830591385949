import { getMediaById } from "src/modules/pe-fabula-module/hooks/story"
import { IFabulaMedia } from "src/modules/pe-fabula-module/data/interfaces"

/* Protopia Ecosystem component */
export interface IMediaTopArrowProps {
    item: IFabulaMedia
    to: ( media: IFabulaMedia) => void
}
const MediaTopArrow = (props: IMediaTopArrowProps): JSX.Element => {
    if(!props.item.top) {
        return <></>
    }
    const onClick = () => {
        const to: IFabulaMedia | undefined = props.item.top ? getMediaById( props.item.top ) : undefined
        if(to) {
            props.to( to )
        }
    }
    
    return <div className='pe-fabula-arrow-top pe-fabula-map-tile-arrow' onClick={onClick} >
    
        </div> 
}
export default MediaTopArrow