import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { useParams } from "react-router"
import { GET_PROJECT_DIARY_ACTION } from "../../data/actionTypes"
import { IDiary } from "../../data/interfaces"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import FeedComponent from "@/modules/pe-basic-module/views/core/FeedComponent"
import PEFestDiarySingleFeedView from "../PEFestDiarySingleFeedView"
import { __ } from "@/libs/utilities"

const Diary = (): JSX.Element => {
    const {id} = useParams()
    // const [diaries, setDiaries] = useState<IDiary[]>( [] as IDiary[] )
    // useEffect(() => {
    //    actions(GET_PROJECT_DIARY_ACTION, {id})
    //         .then((response: IDiary[]) => {
    //             setDiaries(response )
    //         })
    // }, [])
    return (
        <div className="short-container">
            <div className="display-6 text-center my-2">
                { __("Project Diary")}
            </div>
            <Feed
                component={ PEFestDiarySingleFeedView }
                data_type={ "PEFestDiary" }
                is_hide_pagi={false}
                offset={ 0 }
                count={ 20 } 
                paging={ `metas: { key: "project", value: "${id}" }` }
                class_name={` row`}
                params={{ 
                    col: 1
                }}
                style={{}}
                containerClassName={ "short-container" }
                isEmptyComment="Дневник не начат"
            />
        </div>
    )
} 

export default Diary