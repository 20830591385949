import { useMainStore } from "../../../settings/zustand"
import { useClientStore } from '../data/store'
import { 
    getQueryArgs, 
    getQueryName,  
    querySingle, 
    querySingleName, 
} from "../../../libs/layouts" 
import { 
    GET_FEED_ACTION,
    GET_FEED_COUNT_ACTION, 
    GET_SINGLE_ACTIONS 
} from "./actionTypes" 
import { DocumentNode } from 'graphql'

const { gql } = require( "@apollo/client/core" )


const asyncControllers =  async(action:string, data: any) => {
    let response : any,  
        query : DocumentNode,
    query_name : string, fields : any
    const apolloClient: any = useClientStore.getState().client
    switch( action )
    {
        case GET_FEED_ACTION:
            query_name = getQueryName(data.data_type)
            fields = getQueryArgs(data.data_type) 
            query = gql`
                query ${query_name} {
                    ${query_name}( paging:{ count:${data.count || useMainStore.getState().pageCount}, offset:${ data.offset || 0 }, ${ data.paging || "" }  })
                    {
                        ${fields}
                    }
                }`
            response = await apolloClient.query({
                query,
                variables: {
                    input: data
                }
            })
            return response
        case GET_FEED_COUNT_ACTION:
            query_name = `${querySingleName(data.data_type)}Count`  
            query = gql`
                query ${query_name} {
                    ${query_name} 
                }`
            response = await apolloClient.query({
                query,
                variables: {
                    input: data
                }
            })
            return response
        case GET_SINGLE_ACTIONS:
            query_name = querySingleName( data.data_type )
            fields = getQueryArgs( data.data_type ) 
            query = querySingle( data.data_type, query_name, fields, data.id )
            response = await apolloClient.query({
                query,
                variables: {
                    id: data.id
                }
            })
            return response
        default: 
            return  new Promise<any>( (resolve, reject) => { resolve(`Unknown action type: ${action}`)} )
    }
}
export default asyncControllers