import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { IMilestone } from "@/modules/pe-fest-module/data/interfaces"
import { ID } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IMilestoneSwitchFormProps {
    onChange: (id: ID) => void
}
const MilestoneSwitchForm = (props: IMilestoneSwitchFormProps): JSX.Element => {
    const PEFestMilestone: IMilestone[] = useDataStore( (state: any) => state.PEFestMilestone ) || []
    const [chooseNewMilestone, setchooseNewMilestone] = useState<ID>("-1")
    const chooseNewMilestoneHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setchooseNewMilestone( evt.target.value )
        props.onChange( evt.target.value )
    }
    if(!PEFestMilestone.length)
        return <></>
    return <div className='pe-milestone-switch-form-container  mt-3'>
        
        <div className="mb-2 small">
            { __("Choose parent milestone") }
        </div>
        {
            PEFestMilestone.map(cat => {
                return <div className="w-100" key={`newCrit${cat.id}`}>
                <input
                    type="radio"
                    id={ `choose_milestone_${cat.id}` }
                    value={ cat.id }
                    data-id={ cat.id }
                    className="radio_full"
                    name="choose_milestone"
                    onChange={chooseNewMilestoneHandler}
                    checked={ chooseNewMilestone === cat.id}
                />
                <label htmlFor={`choose_milestone_${cat.id}`} data-hint={cat.title}>
                    <div className="cat_color_label" style={{ backgroundColor: "#444444" }} />
                </label>
            </div>
            })
        }
        <input
            type="radio"
            id={ `choose_milestone_-1` }
            value={ "-1" }
            data-id={ "-1" }
            className="radio_full"
            name="choose_milestone"
            onChange={chooseNewMilestoneHandler}
            checked={ chooseNewMilestone === "-1" }
        />
        <label htmlFor={`choose_milestone_-1`} data-hint={__("No Milestone")}>
            <div className="cat_color_label" style={{ backgroundColor: "#888888" }} />
        </label>
    </div>
}
export default MilestoneSwitchForm