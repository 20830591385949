import { useState } from "react"
import GSLinkButton from "./GSLinkButton"
import { Outlet } from "react-router"
import { PELudensEngine } from "../../data/interfaces"
import "../../assets/css/style.scss"

/* Protopia Ecosystem component */
export interface ILudensModelProps {
    modelTitle: string
    engines : PELudensEngine[]
}
const LudensModel = (props: ILudensModelProps): JSX.Element => {
    const [__html, set__html] = useState<string>("") 
      
    return <div className=" pl-4 menu-row w-100 overflow-y-auto-thin">
        <div className="container-fluid d-flex">
        <div className="row w-100 mr-0">
            <div className="col-md-3">
                <div className="my-3 row"> 
                    {
                        props.engines.map(engine => {
                            return <GSLinkButton 
                                key={ engine.id }
                                color="#2c526994"
                                img={ engine.thumbnail }
                                title={ engine.title }
                                path={ engine.path }
                                isSepia={ engine.isSepia }
                            />
                        })
                    } 
                    <div 
                        className="my-0" 
                        dangerouslySetInnerHTML={{__html}} 
                    />
                </div>
            </div>
            <div className="col-md-9 d-flex mr-0 pr-0 flex-wrap flex-column">  
                <Outlet /> 
            </div>
        </div>
        </div>
    </div> 
}
export default LudensModel