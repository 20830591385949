import { ID } from "../interfaces/layouts";
import Layouts from "."; 
export function cssStyle() {
  const css = localStorage.getItem("css");
  const style = css && css !== "undefined" ? css : Layouts().template.style;
  return style;
}
export function styles() {
  // console.log(Layouts());
  return Layouts().template.styles;
}
export function currentStyles() {
  return Layouts().template.style;
}
export function byId(id: ID) {
  const st = styles()?.filter((e: any) => e._id === id)[0];
  return st || {};
}
export function isMenuLeft() { 
  if (Layouts().template) {
    return Layouts().template.menu_left || 0;
  }
}
export function login() {
  return Layouts().template.login;
}
export function template() {
  if (Layouts().template) return Layouts().template;
}
export function widgets(): any {
  if (Layouts().widgets) return Layouts().widgets;
}
export function extentions(): any {
  if (Layouts().extentions) return Layouts().extentions;
}
export function areas() {
  const area:any = Layouts()["widget-area"] ? Layouts()["widget-area"] : {};
  const modules:any = Layouts().modules;
  Object.keys(modules).forEach( (plugin:string) => {
    Object.keys(modules[plugin].extentions).forEach(add_on => {
      modules[plugin].extentions[add_on].area.forEach((ar:any) => {
        // console.log( area )
        // const areaArea = area[ ar.id ] ? area[ ar.id ].area : []
        area[ar.id] = {
          title: modules[plugin].extentions[add_on].title,
          description: modules[plugin].extentions[add_on].description,
          area: [// ...areaArea,
          {
            component: add_on
          }]
        };
      }); //
    });
  }); // console.log( area )

  return area;
}
export function avatar() {
  if (Layouts().template && Layouts().template.avatar) return Layouts().template.avatar;
}
export function loginPage() {
  return Layouts().template.login;
}
export function iconUrl() {
  return `url(${Layouts().template.icon})`;
}
export function iconHeight() {
  return Layouts().template.icon_height ? Layouts().template.icon_height : 30;
}
export function iconBg() {
  return Layouts().template.icon_background;
}
export function iconWidth() {
  return Layouts().template.icon_width ? Layouts().template.icon_width : 30;
}
export function iconPadding() {
  return Layouts().template.icon_padding ? Layouts().template.icon_padding : 0;
}
export function getAllVisibleValue(object : object, visile_value_srting : string) {
  const arr = visile_value_srting ? visile_value_srting.split(".") : [];
  if (!object) return {};
  let adr: any = { ...object };

  for (let i = 0; i < arr.length; i++) {
    adr = adr[arr[i]];
  }

  return adr;
}