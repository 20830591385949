import { Icon, Popover } from "@blueprintjs/core"
import { __ } from "../utilities"

/* Protopia Ecosystem component */
export interface IAttantionProps {
    text: string
}
const Attantion = (props: IAttantionProps): JSX.Element => {
    return <Popover
        interactionKind="hover"
        content={ 
           <div className="p-4 square">
                { __( props.text ) }
           </div> 
        }
    >
        <div className='pe-attantion-container'>
            <Icon icon="error" />
        </div>
    </Popover>
    
}
export default Attantion