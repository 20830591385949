import { ID } from "src/libs/interfaces/layouts"
import { IFabulaMedia, IPEStory } from "src/modules/pe-fabula-module/data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { getMediaById } from "src/modules/pe-fabula-module/hooks/story"
import MediaContent from "../media/MediaContent"
import MusicsEngine from "../musics/MusicsEngine"
import SpeechEngine from "../speech/SpeechEngine"
import MediaTopArrow from "./MediaTopArrow"
import TileEpisodeLabel from "./TileEpisodeLabel"
import TileEpisodeNPCLabel from "./TileEpisodeNPCLabel"

/* Protopia Ecosystem component */
export interface ITileEpisodeContentProps {
    item: IFabulaMedia
    onChoose: (media: IFabulaMedia, isBack: boolean) => void
    onWin: (episode: IFabulaMedia, isBroken: boolean) => void
}
const TileEpisodeContent = (props: ITileEpisodeContentProps): JSX.Element => {
    const story: IPEStory = useStorytellingStore( (state: IStorytellingStore) => state.current )
    
    return <div className="h-100">
    <div 
        className="bg-episode" 
        style={{ backgroundImage: `url(${ props.item.thumbnail})`}} 
    />
    <div className="p-3 text-light dt">
        <div className="pe-fabula-map-tile-title">
            <span>
                { props.item.title }
            </span> 
        </div> 
        <div className="pe-fabula-map-tile-content" >
            <span dangerouslySetInnerHTML={{__html:props.item.post_content}} /> 
        </div>
    </div>
    <MediaContent item={props.item} />
    {
        props.item.media?.map((episode, i) => {
            return <TileEpisodeLabel
                key={episode.id}
                episode={episode}
                onClick={ () => { 
                    props.onChoose( 
                        !episode.to 
                            ? 
                            episode 
                            : 
                            getMediaById( episode.to )!,
                        true 
                    )  
                } }
                onWin={( isBroken: boolean ) => props.onWin(episode, isBroken)}
                i={i}
            />
        })
    }
    { 
        props.item.npc?.map((npcId: ID) => {
            return <TileEpisodeNPCLabel
                item={ story.NPC.filter( n => n.id === npcId )[0] || {} }
                key={ npcId }
            />
        })
    }
    <SpeechEngine item={props.item} />
    <MusicsEngine audio={props.item.music} />
    <MediaTopArrow item={props.item} to={ item => props.onChoose( item, true ) }/>
</div>
}
export default TileEpisodeContent