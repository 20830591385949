import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { Widgets } from "@/libs/utilities"
import { description, iconBg, iconHeight, iconPadding, iconUrl, iconWidth, title } from "@/libs/layouts"
import { NavLink } from "react-router-dom"

const LayoutHeaderHeader = (props: any) :JSX.Element => {
  return <Widgets 
        areaName="layout-header-left" 
        data = {{ ...props }}
        defArea = { <LayoutHeaderTitle /> }
    />
}
export default LayoutHeaderHeader


/* Protopia Ecosystem component */
interface ILayoutHeaderTitleProps {

}
export const LayoutHeaderTitle = (props: ILayoutHeaderTitleProps): JSX.Element => {
    return <NavLink to="/" style={{ display: "flex" }} className="mainLink pl-0 mr-3">
        <div
            className="layout-header-icon p-0 m-0"
            style={{
                backgroundImage: iconUrl(),
                backgroundSize: `${ iconWidth() }px ${ iconHeight() }px `,
                height: iconHeight() + iconPadding(),
                width: iconWidth() + iconPadding(),
                minWidth: iconWidth(),
                backgroundColor: iconBg(),
            }}
        />
            <div className="layout-header-title ml-4">
                { title() }
                <div className="layout-header-description d-sm-flex ">
                    { description() }
                </div>
            </div>
    </NavLink>
} 