import { IManaProps } from "../../data/manas"
import ManaMonitorAnimation from "./ManaMonitorAnimation"
import ManaSingleIndicator from "./ManaSingleIndicator"

export interface IManaSingleProps {
    mana: IManaProps
    count: number
}
const ManaMonitorSingle = ({mana, count}: IManaSingleProps) : JSX.Element => {
    return <div className="pe-manitu-mana-single-cont">
        <div className="mana" style={{backgroundImage: `url(${ mana.icon })`}} >
            <div className="mana-count">{ count }</div>
        </div>
        <ManaMonitorAnimation mana={mana} count={count}/>
        <ManaSingleIndicator mana={mana} />  
    </div>
    
} 

export default ManaMonitorSingle