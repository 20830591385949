import { useEffect, useState } from "react";
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";
import { routing } from "../../data/layoutsClone/routing";
import MenuItemBtn from "./MenuItemBtn";
import { default_menu } from "@/libs/layouts";
import { __ } from "@/libs/utilities";

// fake data generator
const getItems = (count: number, prefix: string) =>
  Array.from({ length: count }, (v, k) => k).map((k) => {
    const randomId = Math.floor(Math.random() * 1000);
    return {
      id: `item-${randomId}`,
      prefix,
      content: `item ${randomId}`
    };
  });

const removeFromList = (list: any[], index: number) => {
  console.log( list )
  if(!Array.isArray(list)) return [] 
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list: any[], index: number, element:any ) => {
    console.log( list )
    if(!Array.isArray(list)) return [] 
    const result = Array.from(list);
    result.splice(index, 0, element );
    return result;
};

const lists = Object.keys(routing())

const generateLists = (): any =>
  lists.reduce(
    (acc, listKey) => ({ ...acc, [listKey]: getItems(4, listKey) }),
    {}
  );

const MenuDrawer = () => {
    const [elements, setElements] = useState<any>(generateLists());
  
    useEffect(() => {
        setElements(generateLists());
    }, []);
  
    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const listCopy: any = { ...elements };
    
        const sourceList = listCopy[result.source.droppableId];
        const [removedElement, newSourceList] = removeFromList(
            sourceList,
            result.source.index
        );
        listCopy[result.source.droppableId] = newSourceList;
        const destinationList = listCopy[result.destination.droppableId];
        listCopy[result.destination.droppableId] = addToList(
            destinationList,
            result.destination.index,
            removedElement
        ); 
        setElements(listCopy);
    };
  
    return <div className="p-3 w-100 h-100 overflow-y-auto ">
        <DragDropContext onDragEnd={onDragEnd}>
          <div style={{
            //display:"grid", 
            //gridTemplateColumns: lists.map(l => "1fr").join(" "), // "1fr 1fr 1fr 1fr 1fr", 
            //gridGap: 8 
            display: "flex"
        }}>
            {lists.map((listKey) => (
              <DraggableElement
                elements={ routing()[listKey] }
                key={listKey}
                prefix={ __(default_menu()[listKey]?.title || listKey) }
              />
            ))}
          </div>
        </DragDropContext>
    </div> 
}

interface IDragElement {
    prefix: string
    elements: any[]
}
interface IListItem {
    index: number
    item: any
}

const DraggableElement = ({ prefix, elements }: IDragElement) => (
    <div style={{padding: 0, width: `${100 / lists.length}%` }}>
        <div className="lead text-uppercase text-light p-3">{prefix}</div>
        <Droppable droppableId={`${prefix}`}>
            {(provided) => (
            <div 
                {...provided.droppableProps} ref={provided.innerRef} 
            >
                {
                    elements.map((item, index) => (
                        <ListItem key={item.id} item={item} index={index} />
                    ))
                }
                {provided.placeholder}
            </div>
            )}
        </Droppable>
    </div>
);

const ListItem = ({ item , index }: IListItem) => { 
    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot ) => {
                return (
                    <div
                        className=" text-force-contrast m-1 bg-dark"
                        style={{background: "#111d2a63"}}
                        ref={provided.innerRef}
                        //snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className=" ">
                            <MenuItemBtn  
                                item={{...item, id: item.id  }} 
                                level={0} 
                                isClosed
                                isMayVisible={false}
                                parentRoute={ "" } //window.location.origin
                                i={index}  
                            > 
                            </MenuItemBtn> 
                        </div> 
                    </div>
                );
            }}
        </Draggable>
    );
};

export default MenuDrawer