import { Button, ButtonGroup, Dialog, Intent, Overlay } from "@blueprintjs/core"
import { useState } from "react"
import { useNavigate } from "react-router"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { __ } from "src/libs/utilities"
import StoryMap from "../map/StoryMap"
import FabulaTimer from "./FabulaTimer"
import MenuItem from "./MenuItem"
import StoryStuffs from "./StoryStuffs"
import HeroCharacterMenu from "./HeroCharacterMenu"
import FabulaSettingsForm from "./FabulaSettingsForm"
import FabulaSwitchMenu from "./FabulaSwitchMenu"
import FabulaBreakForm from "./FabulaBreakForm"
import HeroFullness from "./HeroFullness/HeroFullness"
import FabulaQuestForm from "./FabulaQuestForm"

/* Protopia Ecosystem component */
export interface IFabulaPultProps {

}
const FabulaPult = (props: IFabulaPultProps): JSX.Element => {
    const isPaused : boolean        = useStorytellingStore((state: IStorytellingStore) => state.isPaused)
    const isMenuOpen : boolean      = useStorytellingStore((state: IStorytellingStore) => state.isMenuOpen)
    const isMapOpen : boolean       = useStorytellingStore((state: IStorytellingStore) => state.isMapOpen)
    const isSettingsOpen : boolean  = useStorytellingStore((state: IStorytellingStore) => state.isSettingsOpen)
    const isBreakOpen : boolean  = useStorytellingStore((state: IStorytellingStore) => state.isBreakOpen)
    const isQuestOpen : boolean  = useStorytellingStore((state: IStorytellingStore) => state.isQuestOpen)
    
    const [isOverlayOpen, ]             = useState<boolean>(true)  
     
    
    const onClose = () => {
        useStorytellingStore.setState({isPaused : false})
        useStorytellingStore.setState({ isMapOpen : false }) 
        useStorytellingStore.setState({ isSettingsOpen : false }) 
    }
    const onCart = () => {
        useStorytellingStore.setState({
            isPaused : !isPaused
        })
        if( isMapOpen || isSettingsOpen || isBreakOpen || isQuestOpen ) {
            useStorytellingStore.setState({ 
                isMapOpen : false,
                isSettingsOpen : false,
                isBreakOpen : false,
                isQuestOpen : false,
            })
        }
        else {
            useStorytellingStore.setState({ 
                isMapOpen : true
            })
        }
        
    } 
    return <>
        <Overlay 
            isOpen={isOverlayOpen}  
            backdropClassName={`pe-fabula-pult-backdrop`}
            portalClassName={`pe-fabula-pult-portal`}
        >
            {/*
            <MenuItem isOpen={ isMenuOpen } className="first" icon={isPaused ? "play" : "pause"} onClick={onPause}/>
            <MenuItem isOpen={ isMenuOpen } className="second" icon="stop" onClick={onIsBreakOpen} /> 
            
            <MenuItem isOpen={ isMenuOpen } className="third" icon="map-marker" onClick={onCart} />
             <MenuItem isOpen={ isMenuOpen } className="fourth" icon="star" onClick={ onSettings } />
            
            <MenuItem isOpen={ isMenuOpen } className="fifth" icon="mobile-video" onClick={() => {} } />
            <MenuItem isOpen={ isMenuOpen } className="sixth" icon="mobile-video" onClick={() => {} } /> 
            */}
            <div 
                className='pe-fabula-pult-container ' 
                onClick={onCart}
            >
                <FabulaTimer /> 
            </div>
        </Overlay>
        <Overlay 
            isOpen={ isMapOpen || isSettingsOpen || isBreakOpen || isQuestOpen }
            portalClassName={isMapOpen || isSettingsOpen || isBreakOpen || isQuestOpen ? `pe-fabula-map-portal` : ''}
        >
            <div className="pe-fabula-menu-map">
                <div className="pe-fabula-menu-map-bg"  onClick={ onClose }/>
                {
                    isMapOpen && <StoryMap />
                }
                {
                    isSettingsOpen && <FabulaSettingsForm />
                }
                {
                    isBreakOpen && <FabulaBreakForm />
                }
                {
                    isQuestOpen && <FabulaQuestForm />
                }
            </div> 
        </Overlay> 
        <Overlay 
            isOpen={ true }
            portalClassName={ `pe-fabula-stuff-portal` }
            backdropClassName={`pe-fabula-stuff-backdrop`}
        >
            <StoryStuffs /> 
        </Overlay>
        <Overlay 
            isOpen={ true }
            portalClassName={ `pe-fabula-character-portal` }
            backdropClassName={`pe-fabula-character-backdrop`}
        >
            <HeroFullness />
            <HeroCharacterMenu /> 
        </Overlay>
        <Overlay 
            isOpen={ isMapOpen || isSettingsOpen || isBreakOpen || isQuestOpen }
            portalClassName={ `pe-fabula-switch-menu-portal` }
            backdropClassName={`pe-fabula-switch-menu-backdrop`}
        >
            <FabulaSwitchMenu /> 
        </Overlay> 
    </>
}
export default FabulaPult