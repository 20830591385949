import { IUser, useMainStore } from "src/settings/zustand"
import { IManituMatrix, IManituRow, IManituCell, ORGANOID_TYPE, IOrganoid, IOrganoidType } from "./interfaces"
import { useManituStore } from "./store"
import {default as UUID} from "node-uuid"
import OrganoidGenerator from "../views/manitu/matrix/organoid/OrganoidGenerator"
import { organoidTypes } from "./organoidTypes"

export const getDefaultMatrix = ( user: IUser) : IManituMatrix => {
    const rows: number = useManituStore.getState().matrixRows
    const columns: number = useManituStore.getState().matrixColumns
    const maxDefense: number = useManituStore.getState().maxDefense
    const organoidsTypes: IOrganoidType[] = useManituStore.getState().organoidsTypes
    const defaultBrokenCells = useManituStore.getState().defaultBrokenCells
    let matrix: IManituMatrix = { rows: [], cells:[], user, organoids:[] } as IManituMatrix
    matrix.organoids = organoidsTypes.map(ot => { 
        return OrganoidGenerator( ot )
    }) 
    Array( rows ).fill(1).map((r, i) => {
        let row: IManituRow = {} as IManituRow
        row.id = i
        row.cells = []
        Array( columns ).fill(1).map((c, ii) => {
            const cell: IManituCell = {
                id: UUID.v4(),
                rowID: i,
                i: ii,
                defense: isDefenseOrganoid( ii, i, matrix.organoids )
                    ?
                    Math.floor(Math.random() * maxDefense * 2 + 0)
                    :
                    Math.random() <.03 
                        ? 
                        Math.floor(Math.random() * maxDefense)
                        : 
                        0
            }
            row.cells.push(cell)
            matrix.cells.push(cell)
        })
        matrix.rows.push(row)
    })

    // brokens
    Array( Math.floor( Math.random() *  defaultBrokenCells ) ).fill(1).forEach((__, i) => {
        const x = Math.floor( Math.random() * columns )
        const y = Math.floor( Math.random() * rows )
        matrix.rows[y].cells[x].isBroken = true
    })
    
    return matrix
}

export const getCurrentUserMatrix = () : IManituMatrix => {
    const user : IUser = useMainStore.getState().user
    const m = getDefaultMatrix(user)
    // console.log( m )
    return m
}

const isDefenseOrganoid = (x:number, y: number, organoids: IOrganoid[] ): boolean => {
    return !!organoids.filter( or => {
        return !!or.cells.filter(o => {
            return o.x === x && o.y === y
        }).length
    }).length   

}