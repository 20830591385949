import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import { template } from "@/libs/layouts"

/* 
    Protopia Ecosystem component 
    Strip under bottom position menu for fix content height in mobyle
*/
export interface ILayoutFooterMenuStripProps {

}
const LayoutFooterMenuStrip = (props: ILayoutFooterMenuStripProps) => {
    const {width} = useScreenSize() 
    return <div 
        className={'pe-layoutfootermenustrip-container w-100'}
        style={{
            // minHeight: template().header && width < WINDOW_WIDTH.TABLET ? 75 : 0
        }}
    
    >
   
    </div>
}
export default LayoutFooterMenuStrip