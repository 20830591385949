
import {  DocumentNode } from "graphql"
import { useAppoloStore } from "../../../settings/zustand" 
import { 
    getChangeName,
    getInputTypeName,
    getMutationArgs,
    getQueryArgs, 
    getQueryName, 
    mutationEdit, 
    querySingle, 
    querySingleName, 
} from "src/libs/layouts" 
import { comparePeriods } from "src/libs/utilities"
import {  
    EXAMPLE_ACTIONS, GET_ADS_FEED_ACTION, GET_EVENTS_ACTION, GET_NEWS_FEED_ACTION, GET_PLACES_ACTION, GET_SINGLE_AD_ACTION, GET_SINGLE_EVENT_ACTION, GET_SINGLE_EVENT_TYPE_ACTION, GET_SINGLE_NEWS_ACTION, GET_SINGLE_PLACE_ACTION, GET_TEAM_ACTION, GET_USER_EVENT_REQUEST_ACTION, GET_USER_SUBSCRIBED_ACTION, GET_VIDEO_FEED_ACTION, GET_VKPOST_FEED_ACTION
} from "./actionTypes"  
import { places } from "./mocks/places"
import { events } from "./mocks/events"
import { eventTypes } from "./mocks/eventTypes" 
import { teams } from "./mocks/teams"
import { feed } from "src/modules/pe-basic-module/data/mocks/feed"
import { news } from "./mocks/news"
import { ads } from "./mocks/ads"
import { videos } from "./mocks/videos"
import { USER_EVENT_REQUEST_STATUS } from "./interfaces"

const { gql } = require("@apollo/client/core")

const asyncControllers =  async(action:string, data: any) => {
    let response : any, 
        query : DocumentNode,
        query_name : string, 
        fields : any
        
    const apolloClient: any = useAppoloStore.getState().client
    switch( action )
    {
        case GET_USER_EVENT_REQUEST_ACTION: 
            response = new Promise<any>((resolve, reject) => {  
                const statuses: string[] = Object.values( USER_EVENT_REQUEST_STATUS )   
                const invite = Math.floor( Math.random() * statuses.length )         
                resolve({ data: { isAgreed: statuses[ invite ] } })
            }) 
            return response
        case GET_USER_SUBSCRIBED_ACTION: 
            response = new Promise<any>((resolve, reject) => {                
                resolve({ data: { isSubscribed: Math.random() < .5  } })
            }) 
            return response
        case GET_VKPOST_FEED_ACTION: 
            response = new Promise<any>((resolve, reject) => {                
                resolve({ data: { getPEVKPosts: feed() }})
            }) 
            return response
        case GET_VIDEO_FEED_ACTION: 
            response = new Promise<any>((resolve, reject) => { 
                let getVideos = videos()   
                if(data.count) {
                    getVideos = getVideos.splice(0, data.count)
                }                
                resolve({ data: { getVideos }})
            }) 
            return response
        case GET_NEWS_FEED_ACTION: 
            response = new Promise<any>((resolve, reject) => { 
                let getNews = news()   
                if(data.count) {
                    getNews = getNews.splice(0, data.count)
                }                
                resolve({ data: { getNews }})
            }) 
            return response
        case GET_ADS_FEED_ACTION: 
            response = new Promise<any>((resolve, reject) => {   
                let getAds = ads()   
                if(data.count) {
                    getAds = getAds.splice(0, data.count)
                }          
                resolve({ data: { getAds }})
            }) 
            return response
        case GET_SINGLE_NEWS_ACTION: 
            response = new Promise<any>((resolve, reject) => {
                let _new = news().filter( n => n.id === data.id )[0]
                if(!_new) reject("Error search news") 
                resolve({ data: { getSingleNews: {..._new } } })
            }) 
            return response
        case GET_SINGLE_AD_ACTION: 
            response = new Promise<any>((resolve, reject) => {
                let _new = ads().filter( n => n.id === data.id )[0]
                if(!_new) reject("Error search ad") 
                resolve({ data: { getAd: {..._new } } })
            }) 
            return response
        case GET_SINGLE_PLACE_ACTION: 
            response = new Promise<any>((resolve, reject) => {
                let place = places().filter( place => place.id === data.id )[0]
                if(!place) reject("Error search place") 
                resolve({ data: { getToposPlace: {...place } } })
            }) 
            return response
        case GET_SINGLE_EVENT_TYPE_ACTION: 
            response = new Promise<any>((resolve, reject) => {
                resolve({ data: { getToposEventType: eventTypes().filter( event => event.id === data.id )[0] } })
            }) 
            return response 
        case GET_SINGLE_EVENT_ACTION:  
            response = new Promise<any>((resolve, reject) => {
                resolve({ data: { getToposEvent: events().filter( event => event.id === data.id )[0] } })
            }) 
            return response 
        case GET_TEAM_ACTION: 
            response = new Promise<any>((resolve, reject) => {
                resolve({ data: { getToposTeams: teams() } })
            }) 
            return response 
        case GET_EVENTS_ACTION: 
            response = new Promise<any>((resolve, reject) => {
                // const now: number = Date.now()
                // const end: number = now + 1000 * 60 * 60 * 24
                let getToposEvents = !!data.period 
                    ?
                    events()
                        .filter( event => {
                            const start = (new Date(event.start)).getTime() 
                            if(!event.finish)
                            { 
                                return comparePeriods (
                                    { start, finish: data.period.start },
                                    { start, finish: data.period.finish }
                                )
                            }
                            const finish = (new Date(event.finish)).getTime()
                            return comparePeriods (
                                data.period,
                                { start, finish }
                            )
                        }) 
                    : 
                    events( )
                    if( data.count ) {
                        getToposEvents =  getToposEvents.splice(0, data.count)
                    }
                resolve({ 
                    data: { getToposEvents } 
                })
            }) 
            return response 
        case GET_PLACES_ACTION: 
            response = new Promise<any>((resolve, reject) => {
                resolve({ data: { getToposPlaces: places() } })
            }) 
            return response  
        case EXAMPLE_ACTIONS:   
            query_name = `${querySingleName("Post")}`  
            query = gql`
                query ${query_name} {
                    ${query_name} 
                }`
            response = await apolloClient.query({
                query,
                variables: {
                    input: data
                }
            })      
            return response   
        default: 
            return  new Promise<any>( (resolve, reject) => { resolve(`Unknown action type: ${action}`)} )
    }
}
export default asyncControllers