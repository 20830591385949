import { CARD_SORT_TYPE } from "./interfaces"

export const cardSortType = () =>
{
    return [
        {
            id: CARD_SORT_TYPE.NACS,
            type:"By order", 
            icon: "sort-numerical",

        },
        {
            id: CARD_SORT_TYPE.NDECS,
            type:"By order reverse", 
            icon: "sort-numerical-desc",

        },
        {
            id: CARD_SORT_TYPE.ACS,
            type:"By alphabet", 
            icon: "sort-alphabetical",

        },
        {
            id: CARD_SORT_TYPE.DECS,
            type:"By alphabet reverse", 
            icon: "sort-alphabetical-desc",

        },
        {
            id: CARD_SORT_TYPE.RACS,
            type:"By rating", 
            icon: "sort-desc",

        },
        {
            id: CARD_SORT_TYPE.RDECS,
            type:"By rating reverse", 
            icon: "sort-asc",

        },

    ]
}