import { IFabulaSpeech, SPEECH_FINISH_TYPE, SPEECH_POSITION } from "../../interfaces"

export const speech = () : IFabulaSpeech[] => {
    return [ 
        {
            id: "61-1",
            title: "Hallo",
            post_content: "Здравствуйте. Приглашаю Вас на экскурсию по нашему музею.",
            order: 1,
            isHero: false,
            isDisposable: false,
            position: SPEECH_POSITION.LEFT_1,
            completed: false,
            finishType: SPEECH_FINISH_TYPE.PASSED,  
            audioSource: "/assets/data/fabula/Bear/speech/61-1.mp3",                            
            duration: 0,
            passedPause: 0,
            passedBefore: 0,
            npc: "3"
        },
        {
            id: "61-2",
            title: "Hallo",
            post_content: "Хочу показать Вам наш завод...",
            order: 2,
            isHero: false,
            isDisposable: false,
            position: SPEECH_POSITION.RIGHT_1,
            completed: false,                                
            finishType: SPEECH_FINISH_TYPE.PASSED,  
            audioSource: "/assets/data/fabula/Bear/speech/61-2.mp3",                             
            duration: 0,
            passedBefore: 0,  
            passedPause: 0,
            npc: "4"
        },
        {
            id: "61-3",
            title: "Mo, me",
            post_content: "Нет-нет, не слушайте его! Вы обязательно должны посетить нашу экспозицию",
            order: 3,
            isHero: false,
            isDisposable: false,
            position: SPEECH_POSITION.LEFT_1,
            completed: false,                                
            finishType: SPEECH_FINISH_TYPE.PASSED,
            audioSource: "/assets/data/fabula/Bear/speech/61-3.mp3",  
            duration: 0,
            passedBefore: 500,
            passedPause: 500,
            npc: "3"
        },
        {
            id: "61-4",
            title: "Hi-hi!",
            post_content: "Да что Вы себе позволяете, молодой человек! Я здесь служил, когда Ваш создатель под стол пешком...",
            order: 4,
            isHero: false,
            isDisposable: false,
            position: SPEECH_POSITION.RIGHT_1,
            completed: false,                                
            finishType: SPEECH_FINISH_TYPE.PASSED,
            audioSource: "/assets/data/fabula/Bear/speech/61-4.mp3",  
            duration: 0,
            passedBefore: 500,
            passedPause: 200,
            npc: "4"
        },
        {
            id: "61-5",
            title: "Oy!",
            post_content: "Ой,мамочки мои, боже! Всё!",
            order: 3,
            isHero: false,
            isDisposable: false,
            position: SPEECH_POSITION.LEFT_1,
            completed: false,                                
            finishType: SPEECH_FINISH_TYPE.PASSED,
            audioSource: "/assets/data/fabula/Bear/speech/61-5.mp3",  
            duration: 0,
            passedBefore: 100,
            passedPause: 200,
            npc: "3"
        },
        {
            id: "61-6",
            title: "Hi-hi!",
            post_content: "Какая бестактность! Посмотрите на него, уважаемый гость. От горшка два вершка, а уже хорошо сформировавшийся хам!",
            order: 4,
            isHero: false,
            isDisposable: false,
            position: SPEECH_POSITION.RIGHT_1,
            completed: false,                                
            finishType: SPEECH_FINISH_TYPE.PASSED,
            audioSource: "/assets/data/fabula/Bear/speech/Patrick-61-5.mp3",  
            duration: 0,
            passedBefore: 500,
            passedPause: 200,
            npc: "4"
        },
        {
            id: "61-7",
            title: "Oy!",
            post_content: "Пожалуйста, не указывайте мне, как мне выполнять свои обязаннсти! Прошу Вас, мой уважаемый кислородо дышащий друг, пройдёмте, ознакомимся с нашей экспозицией.",
            order: 3,
            isHero: false,
            isDisposable: false,
            position: SPEECH_POSITION.LEFT_1,
            completed: false,                                
            finishType: SPEECH_FINISH_TYPE.PASSED,
            audioSource: "/assets/data/fabula/Bear/speech/Adam-61-6.mp3",  
            duration: 0,
            passedBefore: 100,
            passedPause: 200,
            npc: "3"
        },
        {
            id: "613-1",
            title: "Милости просим",
            post_content: "О! У Вас действительно есть вкус! Прошу Вас последовать за мной. Приготовьтесь! Сейчас самые сокрытые от людей сундуки тайн раскроются перед Вашим взором!",
            order: 1,
            isHero: false,
            isDisposable: false,
            position: SPEECH_POSITION.LEFT_2,
            completed: false,
            finishType: SPEECH_FINISH_TYPE.INFINITY,  
            audioSource: "/assets/data/fabula/Bear/speech/Patrick-613-1.mp3",                            
            duration: 0,
            passedPause: 0,
            passedBefore: 0,
            npc: "4"
        },
    ]
}