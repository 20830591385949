import { Link } from "react-router-dom"
import { getSingleRoute } from "src/libs/layouts"
import { __ } from "src/libs/utilities"
import { IGoods } from "../../data/interfaces"

interface ICardProp {
    item: IGoods
    className?: string
}
const GoodsCard = (props: ICardProp) :JSX.Element => {
    const routeSingle = getSingleRoute("Goods") 
    const url = routeSingle ? `/${routeSingle.route}/${props.item.id}` : "/" 
    return  <Link to={url} className={`card goods-card ${props.className}`}>
        <div className="thumbnail " style={{ backgroundImage: `url(${props.item.thumbnail})` }}>
            <div className="price">{props.item.price ? props.item.price : __("free price")}</div>
        </div>
        {<div className="card-body mb-auto">
            <div className="card-title h lead">{props.item.title}</div>
            {/* <p className="card-text">
                { wordTrim( props.item.post_content, 10 ) }
            </p> */}
        </div>
        /* <ul className="list-group list-group-flush mt-auto">
            <li className="list-group-item">
                <div className="px-3 py-2">
                    An item
                </div>
            
            </li>
            <li className="list-group-item">
                <div className="px-3 py-2">A second item</div>
                
            </li>
            <li className="list-group-item">
                <div className="px-3 py-2">A third item</div>
                
            </li>
        </ul>
        <div className="p-3">
            <a href="#" className="card-link">
                Card link
            </a>
            <a href="#" className="card-link">
                Another link
            </a>
        </div> */}
    </Link>
} 

export default GoodsCard