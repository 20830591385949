import React from "react" 
import BasicState from "src/libs/basic-view"
import ToposCalendarForm from "./calendar/ToposCalendarForm" 
import "../assets/css/style.scss"

export default class ToposCalendarView extends BasicState {
    props: any
    addRender = () => {
        return <ToposCalendarForm {...this.props} />  
    }
}