export type DatePeriod = {
    start : Date | number
    finish: Date | number
}
export const comparePeriods = (first: DatePeriod, second: DatePeriod) : boolean => {
    try {
        let _first: any     = {
            start   : typeof first.start  === "string" ? (new Date(first.start)).getTime()  : first.start,
            finish  : typeof first.finish === "string" ? (new Date(first.finish)).getTime() : first.finish,
        }
        let _second: any    = {
            start   : typeof second.start  === "string" ? (new Date(second.start)).getTime()  : second.start,
            finish  : typeof second.finish === "string" ? (new Date(second.finish)).getTime() : second.finish,
        }

        return _second.start < _first.start && _second.finish > _first.finish 
    }
    catch (e) {
        return false
    }
}