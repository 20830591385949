import { IFabulaMediaType, IPEFabulaMapTile } from "../../interfaces";

export const Gorenki = (): IPEFabulaMapTile => {
    return {
        id: "9",
        title: "Музей-усадьба «Горенки»",
        post_content: "",
        enabled: true,
        x: 2,
        y: 2,
        map_id: "2",
        thumbnail: "/assets/img/fabula/Bear/bear9.png",
        episodes: [
            {
                id: "91",
                title: "Музей-усадьба «Горенки»",
                post_content: "",
                x: 39,
                y: 40,
                tile_id: "9", 
                media: [
                    {
                        id: "911",
                        title: "Лужайка перед центральным входом",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 55.5,
                        y: 71,
                        thumbnail: "/assets/img/fabula/Bear/bear911.jpg",
                        speech:[],
                        media: [
                            {
                                id: "9111",
                                title: "Входная зала",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 7.5,
                                y: 49,
                                thumbnail: "/assets/img/fabula/Bear/bear910.jpg",
                                speech:[],
                                media: [
                                    {
                                        id: "91111",
                                        title: "Мэрилин?!!",
                                        post_content: "",
                                        type: IFabulaMediaType.IMAGE,
                                        x: 55.5,
                                        y: 71,
                                        thumbnail: "/assets/img/fabula/Bear/bear9111.jpg",
                                        speech:[],
                                        media: [
        
                                        ]
                                    }    
                                ]
                            }
                        ]
                    }, 
                ],
                visible: true
            },
            {
                id: "92",
                title: "Новый шахматный мост",
                post_content: "",
                x: 63,
                y: 30,
                tile_id: "9",
                media: [                                
                    {
                        id: "920",
                        title: "Новый шахматный мост",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear920.png",
                        speech:[],
                        media: [
                            {
                                id: "9200",
                                title: "История парка Горенки",
                                post_content: "",
                                type: IFabulaMediaType.HTML,
                                src: "/assets/data/fabula/Bear/Usadba.html",
                                x: 75,
                                y: 18,
                                thumbnail: "/assets/img/fabula/Bear/bear9200.png",
                                speech:[],
                                media: [ ],
                            },
                            {
                                id: "921",
                                title: "Грот под прудом",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 75,
                                y: 66,
                                thumbnail: "/assets/img/fabula/Bear/bear921.jpg",
                                speech:[],
                                media: [                                                
                                    {
                                        id: "9211",
                                        title: "Курорт",
                                        post_content: "",
                                        type: IFabulaMediaType.IMAGE,
                                        x: 10,
                                        y: 88,
                                        thumbnail: "/assets/img/fabula/Bear/bear922-1.jpg",
                                        speech:[],
                                        media: [
                                            {
                                                id: "92111",
                                                title: "Старый грот",
                                                post_content: "",
                                                type: IFabulaMediaType.IMAGE,
                                                x: 20,
                                                y: 71,
                                                thumbnail: "/assets/img/fabula/Bear/bear9211.png",
                                                npc: [ "1" ],
                                                defense: 3,
                                                speech:[],
                                                media : [
                                                    {
                                                        id: "bear921111-0",
                                                        title: "",
                                                        post_content: "",
                                                        type: IFabulaMediaType.IMAGE,
                                                        x: 0,
                                                        y: 0,
                                                        thumbnail: "/assets/img/fabula/Bear/bear9211.png",
                                                        speech:[],
                                                        media: [
                                                            {
                                                                id: "bear921111",
                                                                title: "Забытая штольня",
                                                                post_content: "",
                                                                type: IFabulaMediaType.IMAGE,
                                                                x: 36,
                                                                y: 65,
                                                                thumbnail: "/assets/img/fabula/Bear/bear92111.png",
                                                                speech:[],
                                                                media : [
                                                                    {
                                                                        id: "bear921121",
                                                                        title: "Выход",
                                                                        post_content: "",
                                                                        type: IFabulaMediaType.IMAGE,
                                                                        x: 53,
                                                                        y: 58, 
                                                                        to: "9411",
                                                                        speech:[],
                                                                        media : [ ]
                                                                    }
                                                                ],
                                                            },
                                                            {
                                                                id: "bear92112",
                                                                title: "Забытая штольня",
                                                                post_content: "",
                                                                type: IFabulaMediaType.IMAGE,
                                                                x: 59,
                                                                y: 60,
                                                                thumbnail: "/assets/img/fabula/Bear/bear92112.png",
                                                                speech:[],
                                                                media : [
                                                                    {
                                                                        id: "bear921121",
                                                                        title: "Киборг-медведь",
                                                                        post_content: "",
                                                                        type: IFabulaMediaType.IMAGE,
                                                                        x: 97,
                                                                        y: 70,
                                                                        thumbnail: "/assets/img/fabula/Bear/bear921121.jpg",
                                                                        top: "951",
                                                                        speech:[],
                                                                        media : [
                                                                            
                                                                        ]
                                                                    }
                                                                ],
                                                                npc: [ 
                                                                    "2"
                                                                ],
                                                            }
                                                        ],
                                                    }, 
                                                ]
                                            },
                                        ]
                                    },
                                    
                                ],
                            },
                        ],                               
                    },                               
                    {
                        id: "912",
                        title: "Лужайка перед центральным входом",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 44,
                        y: 24,
                        thumbnail: "/assets/img/fabula/Bear/bear912.jpg",
                        speech:[],
                    },
                ],
                visible: true
            },
            {
                id:"94",
                title: "Где-то в лесу",
                post_content: "", 
                x: 68,
                y: 28,
                thumbnail: "/assets/img/fabula/Bear/bear941.png",
                tile_id: "9", 
                media: [
                    {
                        id: "941",
                        title: "Беседка-бунгало",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear941.png",
                        speech:[],
                        media: [
                            {
                                id: "9411",
                                title: "Засада",
                                post_content: "",
                                type: IFabulaMediaType.IMAGE,
                                x: 2,
                                y: 85,
                                thumbnail: "/assets/img/fabula/Bear/bear9411.png",
                                speech:[],
                            }
                        ]
                    }
                ],
                visible: true
            }, 
            {
                id: "93",
                title: "Конюшня усадьбы-музея",
                post_content: "Интерактивный тактильный 3D-экспонат познакомит Вас и Ваших подростков с настоящей, живой лошадью. На одном из таких скакунов юная княгиня Долгорукова совершала ежеутренние прогулки. (Восстановлено по музейным изображениям). Экспозиция временно недоступна.",
                x: 95,
                y: 75,
                tile_id: "9",
                media: [  

                    {
                        id: "931",
                        title: "Кони и лошади",
                        post_content: "<p>Интерактивный тактильный 3D-экспонат познакомит Вас и Ваших подростков с настоящей, живой лошадью.</p><p>На одном из таких скакунов юная княгиня Долгорукова совершала ежеутренние прогулки. </p><p>(Восстановлено по музейным изображениям). </p><p>Экспозиция временно недоступна.</p>",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear931.png",
                        speech:[],
                        media: [ 
                        ]                                
                    }, 
                ],
                visible: true
            }, 
            {
                id: "95",
                title: "Благоустройство леса",
                post_content: "",
                x: 95,
                y: 33,
                tile_id: "9",
                media: [
                    {
                        id: "951",
                        title: "Благоустройство леса. Бригада лесничих за работой",
                        post_content: "",
                        type: IFabulaMediaType.IMAGE,
                        x: 0,
                        y: 0,
                        thumbnail: "/assets/img/fabula/Bear/bear951.png",
                        speech:[],
                        media: [] 
                    }
                 ],
                visible: true
            },
        ]
    }
}