import { VerticalType } from "src/libs/scalars/interface/IScalarForm"
import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { __ } from "src/libs/utilities"
import { useEffect, useState } from "react"
import { PETestQuestionAnswer } from "../../data/interfaces"

const Answer = (props: any) : JSX.Element => {
    const [item, setItem] = useState<PETestQuestionAnswer>(props.item as PETestQuestionAnswer)
    useEffect(() => {
        setItem(props.item)
    }, [props.item])
    
    const onChange = (value: any, field: string, data: any) => {
        props.onChange(value, field, data )
    }

    return item.id
        ?
        <div className="my-1 py-1 w-100 border-bottom border-secondary"> 
            <FieldInput
                editable
                vertical={VerticalType.VERTICAL} 
                placeholder={__("Title")}
                value={ item.title}
                field="title"
                className={"form-control input dark w-100 lead"}
                notClear
                onChange={onChange}
            />
            <FieldInput
                editable
                vertical={VerticalType.VERTICAL}
                placeholder={__("Description")}
                value={ item.post_content}
                field="post_content"
                type={SCALAR_TYPES.HTML}
                notClear
                onChange={onChange}
            /> 
            <FieldInput
                editable
                vertical={VerticalType.VERTICAL}
                type={SCALAR_TYPES.MEDIA}
                field="thumbnail"
                placeholder={__("Thumbnail")}
                value={ item.thumbnail}
                notClear
                onChange={onChange}
            />
        </div>
        :
        <></>
} 

export default Answer