import React from 'react'
import CardRoute from './edtorCabinet/CardRoute'
import { IAdminStateProps, SIGN_TYPE } from '../data/interfaces'
import { useAdminStore } from '../data/store'
import RowRoute from './edtorCabinet/RowRoute'

export const SingRoute = ({
    view,
    module,
    routes,
    folder,
    moveToFolder,
    onChangeRoute,
    onRemoveRoute
}: any) : JSX.Element => { 
    const signType: SIGN_TYPE = useAdminStore( ( state:IAdminStateProps ) => state.signType)
    switch( signType ) {
        case SIGN_TYPE.ROW:
            return <RowRoute
                module={ module }
                menu={ routes[view].menu }
                data={ routes[view] }
                hidden={ folder?.hidden }
                moveToFolder={moveToFolder}
                onChangeRoute={ onChangeRoute }
                onRemoveRoute={ route => onRemoveRoute( route ) }
            />
        case SIGN_TYPE.CARD: 
        default:
            return <CardRoute 
                module={ module }
                menu={ routes[view].menu }
                data={ routes[view] }
                hidden={ folder?.hidden }
                moveToFolder={moveToFolder}
                onChangeRoute={ onChangeRoute }
                onRemoveRoute={ route => onRemoveRoute( route ) }
            />
    } 
}
