import { useEffect, useState } from "react" 
import Cover from "./Cover"
import { ITestsStore, useTestsStore } from "../../data/store"
import { IPETest, defaultTest } from "../../data/interfaces"
import { Navigate, Route, Routes } from "react-router"
import TestGenerator from "./TestGenerator"
import Question from "./Question"
import Finish from "./Finish"
//import { useBlocker } from "react-router/dist/lib/hooks"
import { Json } from "src/libs/interfaces/layouts"

const TestGeneratorForm = ( ): JSX.Element => {
    const genTest: IPETest = useTestsStore((state:ITestsStore) => state.genTest) 
    const [json, setJson] = useState<Json>("")
    // let blocker = useBlocker(
    //     ({ currentLocation, nextLocation }) =>
    //         json !== JSON.stringify(genTest) &&
    //         currentLocation.pathname !== nextLocation.pathname
    //   );
    useEffect(() => {
        useTestsStore.setState({genTest: defaultTest(), genStep: 0})
        setJson( JSON.stringify(genTest))
    }, [])
    return <>
        <Routes>
            <Route element={<TestGenerator />} >
                <Route index element={<Navigate to="cover" />} />
                <Route path="cover" element={<Cover />} />
                <Route path=":question_id" element={<Question />} />
                <Route path="finish" element={<Finish />} />
            </Route>
        </Routes>
        {
            // blocker.state === "blocked" 
            //     ? <div>
            //         <p>Are you sure you want to leave?</p>
            //         <button onClick={() => {
            //             if(blocker.proceed){
            //                 blocker.proceed()
            //             }
            //         }}>
            //             Proceed
            //         </button>
            //         <button onClick={() => {
            //             if(blocker.reset){
            //                 blocker.reset()
            //             }
            //         }}>
            //             Cancel
            //         </button>
            //     </div> 
            //     : 
            //     null
        }
    </>
        // 
}

export default TestGeneratorForm