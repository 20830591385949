import React from 'react' 

interface ISubMenuProps {
  subMenus: any,
  on: any
}
const SubMenu = ({ subMenus, on }: ISubMenuProps ) :React.ReactElement | null => {
  return subMenus 
    ?
    <div>SubMenu</div>
    :
    null
}

export default SubMenu