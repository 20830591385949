import React from "react"
import BasicState from "@/libs/basic-view" 
import { __ } from "@/libs/utilities"
import { DataTable } from "./dataTable"
import { schema } from "src/libs/layouts"
import { sprintf } from "@/libs/utilities" 
import { DataCards } from "./DataCards"

class DataTableView extends BasicState {
  props: any
  addRender = () => {
    return this.props.data_type && !!schema()[ this.props.data_type ]
      ?
      <div className="container">
        {this.get_data( this.props.data_type, this.props.filter, this.props.order )}
      </div> 
      :
      <div className="alert alert-secondary p-5">
      { 
        sprintf( 
          __("Data type %s not defined. The administrator needs to rebuild the client"), 
          this.props.data_type
        ) 
      }
      </div>
  }

  get_data = (data_type: string, filter: any[], order: any ) => { 
    switch( schema()[this.props.data_type].admin_view ) {
      case "cards":
        return <DataCards
          data_type={data_type}
          filter={filter}
          order={order}
          route={this.props.route}
          isList={this.props.is_list} 
        />
      case "table":
      default:
        return <DataTable 
          data_type={data_type}
          filter={filter}
          order={order}
          route={this.props.route}
          isList={this.props.is_list} 
        /> 
    }
    
  }
}

export default DataTableView
