import { IModule } from "@/libs/interfaces/layouts"
import Layouts, { modules } from "@/libs/layouts"
import IScalarForm from "@/libs/scalars/interface/IScalarForm"
import Scalar from "@/libs/scalars/scalars/Scalar" 
import { __ } from "@/libs/utilities"
import { Button, Dialog } from "@blueprintjs/core"
import { useState } from "react"
import TypeButtonGroup from "./TypeButtonGroup"

const ViewTypeChooseWidget = (props: IScalarForm) : JSX.Element => {
    return <Scalar
        { ...props } 
        enabledForm={<EditForm {...props} />} 
    />
}  
export default ViewTypeChooseWidget

const EditForm = (props: IScalarForm) : JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    
    const onChoose = (key:string) => {
        props.onChange!(key, props.field, "" )
    } 
    const module: IModule = modules()[ Layouts().views[props.value]?.module ] || {}
    return <div className="w-100">
        <div className="w-100"> 
            <Button 
                minimal 
                fill 
                className="btn-stretch p-3 border border-secondary-light"
                onClick={() => setIsOpen(true)}
            >
                {
                    Layouts().views[props.value]
                        ?
                        <div className="d-flex align-items-center w-100">
                            <div className="small text-center hint hint--top" data-hint={ __( module?.title ) }>
                                <img
                                    src={ module?.icon }
                                    className="w_45 h_45"
                                    alt=""
                                />
                            </div>
                            <div className="pl-3 flex-column w-100 flex-grow-100">
                                <div className="display-5">
                                    { __(Layouts().views[props.value]?.title) }
                                </div>
                                <div className=" text-force-contrast">
                                    {  __(Layouts().views[props.value]?.description) }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="d-flex align-items-center w-100 text-center">
                            { __("No exists View. Click me for choose") }
                        </div>
                } 
            </Button> 
            <Dialog
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title={__("Choose need component type")}
                className="pe-large-height-dialog"
            >
                <div className="p-0 overflow-y-auto">
                    <TypeButtonGroup value={props.value} onChoose={onChoose}/>
                </div> 
            </Dialog>
        </div>
    </div>
} 