import { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import { Fragment } from "react"
import { festSatuses } from "../../data/festStatuses"
import { FEST_ADMIN_STATUSES, IFestival } from "../../data/interfaces"

/* Protopia Ecosystem component */
export interface IFestivalStatusFormProps {
    festival: IFestival
    onPhase: (status: FEST_ADMIN_STATUSES) => void
}
const FestivalStatusForm = ({ festival, ...props }: IFestivalStatusFormProps): JSX.Element => {
    const statusForm = festSatuses()
        .map((elem: any, i) => <Fragment key={i}>
            <div className='mb-1'> 					
                <input 
                    type={SCALAR_TYPES.RADIO}
                    name='card=types'
                    className='radio_full' 
                    id={'status' + elem._id}
                    value={ elem._id }
                    checked={ festival.status === elem._id }
                    onChange={ () => props.onPhase(elem._id) }
                    data-n={ i }
                />
                <label htmlFor={ 'status' + elem._id} data-hint={__(elem.title) }>
                    <div className={ festival.status === elem._id ? "cat_color_label ggreen" : "cat_color_label" } />
                </label>					
            </div>
        </Fragment>) 
    return <div className='pe-festivalstatusform-container'>
        { statusForm }
    </div>
}
export default FestivalStatusForm