import { IPETest, PE_TEST_QUESTION_TYPE } from "../interfaces";
import { testCategories } from "./testCategories";

export const test8 = () : IPETest => {
    return {
        id: 8,
        title: "Это должен знать каждый!",
        post_content: "Тест по географии",
        testCategory: testCategories().filter(t => t.id === 4)[0],
        pe_academic_discipline: [{
            color: '#214700',
            id: '25',
            order: 6,
            post_content: '',
            thumbnail: 'false',
            title: 'Литература',
        }],
        thumbnail: "https://i.pinimg.com/564x/b3/2d/46/b32d46f3ce754a96ed8ba180c9da61e7.jpg",
        questions: [
            {
                id: 1,
                title: "Как называется карта мира, на которой отмечены государства с границами и важными городами?",
                thumbnail: "http://static.tildacdn.com/tild6262-3561-4865-a534-663438373936/IMG_20200704_173102.jpg",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Политическая",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Физическая",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Карта народов мира",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 2,
                title: "Какое море не ограничивается сушей?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Берингово",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Саргассово",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Охотское",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 1,
                title: "Сколько слоёв входит в состав материковой земной коры?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Один",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Два",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Три",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 4,
                title: "Как называется нижний слой атмосферы, в котором мы живём?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Тропосфера",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Мезосфера",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Стратосфера",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 5,
                title: "Какой континент имеет самую маленькую площадь?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Южная Америка",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Австралия",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Антарктида",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 6,
                title: "Какое море омывает берега Италии?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Средиземное",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Чёрное",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Красное",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 7,
                title: "Назовите государство, которое граничит с Норвегией.",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Швеция",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Австрия",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Ирландия",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 8,
                title: "Горная система, которая расположена в Северной Америке.",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Анды",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Кордельеры",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Альпы",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 9,
                title: "Что такое муссон?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Течение",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Ветер",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Тропический дождь",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 10,
                title: "Христофор Колумб открыл Америку, хотя на самом деле его целью была совсем другая страна. Какая?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Индия",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Мексика",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Китай",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 11,
                title: "Как называется самый известный водопад в Канаде?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Виктория",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Анхель",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Ниагарский",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 1,
                title: "Географический пояс, который отличается самым большим количеством осадков.",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Тропический",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Умеренный",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Экваториальный",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 13,
                title: "Самое маленькое государство в Европе.",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Мальта",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Андорра",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Ватикан",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 14,
                title: "Назовите самую полноводную реку на Земле, которая впадает в Атлантический океан.",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Нил",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Конго",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "",
                        post_content: "Амазонка",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 16,
                title: "В каком океане находится знаменитая Марианская впадина?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "В Тихом океане",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "В Индийском океане",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "В Атлантическом океане",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 17,
                title: "Какой остров отделён от континента Мозамбикским проливом?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Гренландия",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Мадагаскар",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Суматра",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 18,
                title: "Другое название горы Эверест, самой высокой горной вершины в мире.",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Джомолунгма",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Монблан",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Эльбрус",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 1,
                title: "В какой природной зоне большую часть территории покрывают мхи и лишайники?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Тайга",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Тундра",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Степь",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            }, 
            {
                id: 20,
                title: "Какие континенты разделяет Панамский канал?",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "Северную и Южную Америку",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "Евразию и Африку",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "Евразию и Северную Америку",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },
        ],
        is_show_answer:false, 
        is_show_results:true, 
        is_shuffle:true, 
        is_timed:false, 
        duration: 20,
        try_count: 10
    }
}