import { areas } from "../layouts"
import Widgets from "./Widgets"
import getWidgets from "./getWidgets"

export function initArea(areaName:string, data = {}, defArea:any = null) {
  return <Widgets areaName={areaName} data={data} defArea={defArea} /> 
}


export function initDataArea(areaName:string, data={}, defaultData=null)
{
  
}

export function widgetAreas() {
  return areas()
}
export default function getWidget(widget:string, templatePart:string) {
  const ts = areas()[templatePart]
  if (ts && Array.isArray(ts)) {
    const w = ts.filter((e) => e.component === widget)
    if (!w[0]) return null
    if (!getWidgets[w[0].component]) return null
    const ElWidget = getWidgets[w[0].component].default
    return <ElWidget {...w[0]} />
  }
  return null
}

export function initWidget(widget: string, data:any = {}) {
  const ElWidget = getWidgets[widget].default
  return <ElWidget {...data} />
}
