import { IFestival } from "@/modules/pe-fest-module/data/interfaces"
import { __ } from "@/libs/utilities"
import { Button, Callout } from "@blueprintjs/core"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { IUser } from "@/settings/zustand"
import UserEditCard from "./UserEditCard"

/* Protopia Ecosystem component */
export interface IProjectsContentProps {
    festival: IFestival
    users: IUser[]
    setUsers: (projects: IUser[]) => void
}
const UsersContent = ({users, ...props}: IProjectsContentProps): JSX.Element => {    
    // a little function to help us with reordering the result
    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed); 
        return result;
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({ 
        userSelect: "none",
        padding: 0, 
        margin: `0 0 0 0`, 
        width: "100%",
        color: "#FFFFFF!important",
        // change background colour if dragging
        background: isDragging ? "#45526d" : "transparent", 
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? "var(--drop-select-color)" : "transparent",
        padding: 0, 
    })
    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        } 
        const _items: IUser[] = reorder(
            users,
            result.source.index,
            result.destination.index
        );   
        props.setUsers( _items )
    } 

    return <div className='pe-projects-content-container'>
        {
            !!users.length
                ?
                <div>
                    <DragDropContext onDragEnd={ onDragEnd }>
                        <Droppable 
                            droppableId="droppable"
                            direction="vertical" 
                        >
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        ...getListStyle(snapshot.isDraggingOver),
                                        padding: "20px 20px 0 20px"
                                    }}
                                >
                                    { users.map((item, index) => (
                                        <div key={item.id} className="d-flex mb-3"> 
                                            <Draggable 
                                                draggableId={item.id.toString()} 
                                                index={index}
                                            >
                                                {(provided, snapshot) => ( 
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <UserEditCard item={item} key={item.id} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    { provided.placeholder }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext> 
                </div> 
                :
                <Callout className="p-5 lead text-center">
                    {__("No elements exists")}
                </Callout>
        }
        <div className="d-flex justify-content-end w-100">
            <Button icon="reset" minimal >
                {__("Restore")}
            </Button> 
        </div>
    </div>
}
export default UsersContent