import { ICritery, IProject, IRating } from '@/modules/pe-fest-module/data/interfaces'
import ExpertCheck from './ExpertCheck'
import { actions } from '@/modules/pe-fest-module/data/actions'
import { UDATE_PROJECT_RATING_ACTION } from '@/modules/pe-fest-module/data/actionTypes'
import { useEffect, useState } from 'react'
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from '@/modules/pe-fest-module/data/store'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import CriteryCommentaryDialog from '../projectData/CriteryCommentaryDialog'
import { updateCurrentRating } from '@/modules/pe-fest-module/data/updateCurrentRating'
import {default as UUID} from "node-uuid";
import { useParams } from 'react-router'

interface IProps {
    critery: ICritery
    maxRating: number
    rating: IRating
}

const ExpertCheckList = (props: IProps): JSX.Element => {
    const {landId} = useParams()  
    const [rate, changeRate] = useState<number>(props.rating?.rating)
    // это кэш старой оценки.  Если условия оценивания не соответствуют правилам он используется для автоматическеого возвращения значения rate
    const [cachedRate, changeCachedRate] = useState<number>(props.rating?.rating)
    const [isOpen, setIsOpen] = useState(false)
    const [description, setDescription] = useState(props.rating.description || '') 
    const project: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)
    const isComment: boolean = useFestDataStore((state: IFestDataStor) => state.isComment)
    const setIsBlockedUpdate: Function = useFestProjects.getState().setIsBlockedUpdate
    
    useEffect(() => {
        changeRate( props.rating?.rating || 0 )
        //changeCachedRate( props.rating?.rating || 0 )
        if( props.rating?.description )
            setDescription( props.rating?.description )
    }, [ props.rating ])
    
    const onOnlyOpen = () => {
        setIsOpen(true)
        setIsBlockedUpdate(true)
    }
    const onOnlyClose = () => {
        // console.log(props.maxRating === rate, description, cachedRate)
        changeRate( cachedRate )
        setIsOpen( false )
        setIsBlockedUpdate( false )
    }

    const onCheck = ( value: number ) => {
        if ( props.maxRating === value ) { 
            onOnlyOpen()
        } 
        updateCurrentRating( value, props.rating ) 
        //changeRate( value )
        if (isComment) {
            //setIsOpen(!isOpen)
            onOnlyOpen()
        } else {
           setTimeout(() => sending( description ), 100) 
        }
    }

    const onSend = (descr: string) => {
        setDescription( descr )
        sending(descr)
    }
    const sending = (description: string) => { 
        project.ratings.forEach((rating: IRating) => {
            if( rating.id === props.rating.id ) {
                const _newRating:IRating = {...props.rating}
                _newRating.description = description
                _newRating.rating = rate
                // console.log( _newRating )
                // гасим кэш
                changeCachedRate( rate )
                actions( UDATE_PROJECT_RATING_ACTION, { id: rating.id, item: _newRating, landId } )
            }
        })
    }
    const checks = Array(props.maxRating + 1)
        .fill(1)
        .map((r: any, i: number) => {
            return (
                <ExpertCheck
                    critery={props.critery}
                    index={i}
                    key={i}
                    onCheck={onCheck}
                    rating={rate}
                />
            )
        })
    return (
        <div className='d-flex flex-wrap justify-content-md-between justify-content-center'>
            <div>{checks}</div>
            <CriteryCommentaryDialog
                description={description}
                isOpen={ isOpen }
                onOpen={ onOnlyClose }
                lockWithoutComment={props.maxRating === rate}
                onSend={onSend}
                sendLabel={'send label'}
                isHideChangeDescr={false}
            />
        </div>
    )
}

export default ExpertCheckList
