import { Button } from "@blueprintjs/core"
import { ChangeEvent, useState } from "react"

const SearchLandscape = (props: any) : JSX.Element => {
    const [search, setSearch] = useState<string>(props.search)
    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setSearch(evt.target.value)
    }
    return (
        <div className="topos-landscape-search">
            <input
                type="text"
                value={search}
                onChange={onChange}
                className="form-control input dark"
            />
            <Button minimal icon="search" onClick={ () => props.onChange(search) } />
        </div>
    )
} 

export default SearchLandscape