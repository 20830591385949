import { __ } from "src/libs/utilities"
import { Button, ButtonGroup, Dialog, Icon, Intent } from "@blueprintjs/core"
import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import FieldInput from "src/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "src/libs/scalars/interface/IScalarForm"
import { useNavigate } from "react-router"
import { useState } from "react"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import AudioSettings from "./AudioSettings"

/* Protopia Ecosystem component */
export interface IFabulaSettingsFormProps {

}
const FabulaSettingsForm = (props: IFabulaSettingsFormProps): JSX.Element => {
    const navigate = useNavigate()
    const audioVolume : number = useStorytellingStore((state: IStorytellingStore) => state.audioVolume)
    const voices : boolean = useStorytellingStore((state: IStorytellingStore) => state.voices)
    const music : boolean = useStorytellingStore((state: IStorytellingStore) => state.music)
    const effects : boolean = useStorytellingStore((state: IStorytellingStore) => state.effects)
    
    const [isBreakOpen, setIsBreakOpen] = useState<boolean>(false) 
    
    const onAudioVolume = (value: number) => { 
        useStorytellingStore.setState({ audioVolume: value })
    }
    const onAudio = (value:  any ) => {
        console.log( value ) 
        useStorytellingStore.setState({
            voices :    !!value.filter( (v: any) => v._id === "voices" ).length,
            music :     !!value.filter( (v: any) => v._id === "music" ).length,
            effects :   !!value.filter( (v: any) => v._id === "effects" ).length,
        }) 
    }
    const getAudios = () => {
        let v = [ ] 
        if(voices) v.push("voices")
        if(music) v.push("music")
        if(effects) v.push("effects")
        return v
    }
    const breakStory = () => {
        useStorytellingStore.setState({isMenuOpen : false})
        setIsBreakOpen(!isBreakOpen)
    }
    const onBreak = () => {
        useStorytellingStore.setState({isMenuOpen : false})
        navigate("break")
    }
    const onIsBreakOpen = () => {
        useStorytellingStore.setState({isMenuOpen : false})
        setIsBreakOpen(!isBreakOpen)
    }

    return <div className='pe-fabula-settings-form-container p-4'>
        <div className="small-container">
            <FieldInput
                vertical={VerticalType.HORIZONTAL}
                title={ <Icon icon="volume-up" size={33} /> }
                editable
                type={SCALAR_TYPES.SLIDER}
                value={audioVolume}
                min={0}
                max={1}
                stepSize={.01}
                labelStepSize={.2}
                onChange={ onAudioVolume }
            />
            <AudioSettings /> 
        </div>
        <div className="py-4">
            <Button minimal large onClick={breakStory} className="text-light">
                {__("Break quest and quit")}
            </Button>
        </div>

        <Dialog 
            className={` ${DIALOG_SIZE.MINIMAL} transparent-dialog `}
            isOpen={isBreakOpen}
            onClose={onIsBreakOpen}
        >
            <div className="p-4">
                <div className="pb-4 text-center lead">
                    {__("Are you want break and leave quest?")}
                </div>
                <ButtonGroup fill>
                    <Button intent={Intent.DANGER} large onClick={onIsBreakOpen}>
                        {__("No, return to quest")}
                    </Button>
                    <Button intent={Intent.SUCCESS} large onClick={onBreak}>
                        {__("Break and leave")}
                    </Button>
                </ButtonGroup>
            </div>
        </Dialog>
    </div>
}
export default FabulaSettingsForm