
import { Button } from "@blueprintjs/core"
import { useEffect, useId, useState } from "react"
import { URL } from "src/libs/interfaces/layouts"
import { Dummy, Loading } from "src/libs/useful"
import { __ } from "src/libs/utilities"
import { IVideo, VIDEO_SOURCE } from "../../data/interfaces"

interface IProps {
    item: IVideo
    isPlay: boolean
    id?: string
    onPlay?: () => void
    onStop?: () => void
    player?: any
    setPlayer?: (player: any) => void
} 
 

const ToposSingleVideo = ({item, ...props}: IProps) :JSX.Element => {
  const sv: JSX.Element = <SingleVideo item={item} {...props} /> 
  return sv
}

const SingleVideo = ({item, ...props}: IProps) :JSX.Element => {
  const [player, setThePlayer] = useState<any>( null )
  const [isLoading, setIsLoading] = useState<boolean>( item.type === VIDEO_SOURCE.YOUTUBE )
  const [isPlay, setIsPlay] = useState(false) 
  useEffect(() => { 
    loadApi().finally(() => setIsLoading(false) )
  }, [])
  const id = useId()
  
  if( isLoading) {
    return <div className="w-100 h-100 flex-centered">
      <Dummy width={100} height={55} />
      <div className="position-absolute w-100 h-100 ">
        <Loading />
      </div>
    </div>
  }
  const setPlayer = (player: any) => {
    // console.log(player)
    setThePlayer(player)
  }
  const onPlay = () => {  
    setIsPlay(true) 
    try { player?.playVideo() } catch(e) { }
    
  }
  const onStop = () => {  
    setIsPlay(false)
    try { player?.pauseVideo() } catch(e) { } 
  }
  return <Video 
    player={player} 
    setPlayer={setPlayer} 
    item={item} 
    onPlay={onPlay} 
    onStop={onStop} 
    id={id} 
    isPlay={isPlay}
  />  
} 

export default ToposSingleVideo

export const Thumbnail = ({item, ...props}: IProps) :JSX.Element => {
  return <div 
      className={`video-container ${props.isPlay ? "isPlay" : ""}`}  
      onClick={props.onPlay}
    >  
      <div 
        className={`thumbnail card-top-img1 `} 
        style={{backgroundImage:`url(${item.thumbnail})`}}
        onClick={props.onPlay}
      /> 
      <h4 className="text-light text-center p-2 position-absolute bottom left w-100 z-index-100">
        {item.title}
      </h4>
      <svg 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        className="video-icon" 
      >
        <path className="round" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="#00000077" stroke="#FFFFFF" strokeWidth=".33" strokeLinecap="round" strokeLinejoin="round"/>
        <path className="tre" d="M15.0015 11.3344C15.3354 11.5569 15.5023 11.6682 15.5605 11.8085C15.6113 11.9311 15.6113 12.0689 15.5605 12.1915C15.5023 12.3318 15.3354 12.4431 15.0015 12.6656L11.2438 15.1708C10.8397 15.4402 10.6377 15.5749 10.4702 15.5649C10.3243 15.5561 10.1894 15.484 10.1012 15.3674C10 15.2336 10 14.9908 10 14.5052V9.49481C10 9.00923 10 8.76644 10.1012 8.63261C10.1894 8.51601 10.3243 8.44386 10.4702 8.43515C10.6377 8.42515 10.8397 8.55982 11.2438 8.82917L15.0015 11.3344Z" stroke="#FFFFFF" strokeWidth=".33" strokeLinecap="round" strokeLinejoin="round"/>
      </svg> 
    </div>
}

export const Video = ({item, ...props}: IProps) : JSX.Element => { 
  let video:any
  switch(item.type) {
    // case VIDEO_SOURCE.YOUTUBE:
    //   video = <YouTubeVideo item={item} {...props} />
    //   break
    case VIDEO_SOURCE.VK:
    default:
      video = <SomeVideo item={item} {...props} />
  }
  return <div 
    className="single-video-container"
    onClick={() => props.isPlay ? props.onStop!() : props.onPlay!() }
  >
    <Dummy width={100} height={55} />
    {video}
    <Thumbnail item={item} {...props} />
  </div>
}

export const YouTubeVideo = ({item, ...props}: IProps) : JSX.Element => {
  const yid = youtube_parser( item.link ) 
  useEffect(() => {
    console.log( youtube_parser( item.link )  )
    const player: any = new window.YT.Player(
      props.id, 
      {
        width: "100%",
        height: "100%",
        videoId: youtube_parser( item.link )  
      }
    )
    console.log(player) 
    props.setPlayer! (player) 
  }, [])
  
  return yid && props.player && props.player.playVideo
    ?
    <div className="youtube-cont ">
      <div className="w-100 h-100 position-absolute" id={props.id} /> 
      <VideoPanel item={item} {...props} />
    </div> 
    :
    <div className="w-100 h-100 flex-centeref bg-secondary-super-light text-light position-absolute top left flex-centered">
      {__( "Error link" )}
    </div> 
}

export const SomeVideo = ({item, ...props}: IProps) : JSX.Element => { 
  useEffect(() => {
    setTimeout(() => {
      // const el: any = document.getElementById( props.id!) 
      // console.log( el.contentWindow )
    }, 1000)
  }, [])
  return <iframe
    id={props.id }
    className="position-absolute top left" 
    src={`${item.link}&autoplay=0`} 
    width="100%" 
    height="100%" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
    allowFullScreen 
    title={item.title} 
  />
}

//=======//
export const VideoPanel =({item, ...props}: IProps) : JSX.Element => { 
  const [ timer, setTimer] = useState("00:00")
  useEffect(() => {
    let timout = setTimeout(() => {
      setTimer( `${props.player ? formatTime( props.player.getCurrentTime() ) : "00" } } | ${ props.player ? formatTime( props.player.getDuration() ) : "00" }`)
    }, 500)
    return () => {
      clearTimeout(timout)
    }
  },[])
  
  return <div className="panel">
    <div className="flex-grow-100 flex-centered">
      { timer }
    </div>
    <Button minimal className="ml-auto">
      <i className="fas fa-expand"></i>
    </Button>
  </div>
}

const formatTime = (time: number) => {
  time = Math.round(time); 
  const minutes: number = Math.floor(time / 60) 
  const seconds: number = time - minutes * 60;
  const secs = seconds < 10 ? '0' + seconds : seconds;
  return minutes + ":" + secs;
}
const  youtube_parser = (url: URL) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length === 11)? match[7] : false;
} 

export const loadApi = () : Promise<any> => {
  return new Promise<any>((resolve, reject) => { 
    if( document.getElementById( "ymap-youtube-api" ) ) {
      resolve(true)
      return
    }
    const el: any = document.createElement("script") 
    el.type = "text/javascript"
    el.src = `https://www.youtube.com/iframe_api`
    el.async = true
    el.id = "ymap-youtube-api"
    el.onload =  () => {
      // console.log(window.YT) 
      if(window.YT) {
        window.YT.ready(() => { 
          resolve(true)
        })        
      }
      else {
        reject(false)
      }
    }
    document.getElementsByTagName("head")[0].appendChild(el)
  })
}
declare global {
  interface Window { YT: any; }
}