
/* Protopia Ecosystem component */
export interface IArchiveFormProps {

}
const ArchiveForm = (props: IArchiveFormProps): JSX.Element => {
    return <div className='pe-archiveform-container'>
        <div className="p-md-4 display-7">Архив</div>
    </div>
}
export default ArchiveForm