import { Color, HTML, ID } from "../interfaces/layouts"
import Map from "./Map"
export {
    Map
}
export interface IMarker { 
    coordinates: any[]
    icon: string
    hintContent?: HTML
    draggable?: boolean
    data?: any
    color: Color
    onClick?: (evt:any, coords: number[], data: any) => void
}

export enum MAP_DIALOG_MODE {
    NONE="none",
    MARKER_DATA="markerData",
    CLUSTER_DATA="clusterData"
}