import { PE_TEST_QUESTION_TYPE } from "../interfaces"
import { testCategories } from "./testCategories"

export const test6 = () => {
    return {
        id: 6,
        title: "Величины №3",
        post_content: "Математика  »  4 класс  »  Величины №3",
        testCategory: testCategories().filter(t => t.id === 3)[0],
        pe_academic_discipline: [{
            color: '#214700',
            id: '25',
            order: 6,
            post_content: '',
            thumbnail: 'false',
            title: 'Литература',
        }],
        thumbnail: "https://i.pinimg.com/564x/21/86/53/21865302420820353effb9f8b4278d5b.jpg",
        questions: [ 
            {
                id: 2,
                title: "Какова будет общая длина отрезка FS в см, если длина FK составляет 2 дм 5 см, а длина KS - 1 дм?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "45 см",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "35 см",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "40 см",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "25 см",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "1100 мм",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 3,
                title: "Сколько суток в 480 часах?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "35",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "18",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "41",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "20",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "34",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 33,
                title: "Какое равенство верное?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "5 л 250 мл = 5150 мл",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "3 кг 50 г = 3500 г",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "744 часа = 31 день",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "1500 см = 15 км",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "12 л 250 мл = 21150 мл",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 4,
                title: "В каком варианте единицы измерения размещены в порядке убывания?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "час, минута, день, секунда",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "килограмм, тонна, грамм, миллиграмм",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "год, месяц, эра, век",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "километр, метр, сантиметр, дециметр",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "сантиметр, метр, километр, дециметр",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 5,
                title: "Сколько месяцев содержится в 20 годах?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "240",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "200",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "280",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "260",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "11",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 6,
                title: "Чему равны 4 м.кв?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "400 мм.кв",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "400 см.кв",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "400 км.кв",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "400 дм.кв",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    }, 
                ],
                right_answer_id: 4,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 7,
                title: "На сколько грамм уменьшится торт весом в 4 кг, если от него отрезать один кусок весом в 240 г?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "на 3800 г",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "на 3760 г",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "на 3250 г",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "3620 г",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "3690 г",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 77,
                title: "Как записать в метрах 20 км?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "2000 м",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "200 м",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "20000 м",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "2 м",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "2000000 м",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 3,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 9,
                title: "Всего в двух кувшинах 5 л сока. Сколько мл сока в первом кувшине, если во втором 2 л 750 мл?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "2250 мл",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "3750 мл",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "1650 мл",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "2850 мл",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "2890 мл",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 10,
                title: "Сколько дней составляет август?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "27",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "29",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "30",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "28",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "31",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 5,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 11,
                title: "Какая мера длины самая большая?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "гектар",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "метр.кв",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "акр",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "сантиметр.кв",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 12,
                title: "Скольким мм2 равны 7 дм2?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "7000",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "70000",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "70",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "700",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    }, 
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 13,
                title: "Ваня решил отнести бабушке 6 кг яблок. Сколько кг яблок осталось у Вани, если всего было 10 кг?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "4 кг",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "5 кг",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "1 кг",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "2 кг",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    }, 
                ],
                right_answer_id: 1,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
            {
                id: 14,
                title: "Сколько тонн и килограмм содержится в 5160 кг?",
                thumbnail: "",
                post_content: "",
                answers : [
                    {
                        id: 1,
                        title: "51 т 60 кг",
                        post_content: "",
                        is_subquestion: false,
                        order: 1
                    },
                    {
                        id: 2,
                        title: "5 т 160 кг",
                        post_content: "",
                        is_subquestion: false,
                        order: 2
                    },
                    {
                        id: 3,
                        title: "5 т 16 кг",
                        post_content: "",
                        is_subquestion: false,
                        order: 3
                    },
                    {
                        id: 4,
                        title: "5160 т",
                        post_content: "",
                        is_subquestion: false,
                        order: 4
                    },
                    {
                        id: 5,
                        title: "516 т",
                        post_content: "",
                        is_subquestion: false,
                        order: 5
                    },
                ],
                right_answer_id: 2,
                single: true,
                type: PE_TEST_QUESTION_TYPE.MATCHING,
            },   
        ],
        is_show_answer:true, 
        is_show_results:true, 
        is_shuffle:true, 
        is_timed:true, 
        duration: 30,
        try_count: 10
    }
}