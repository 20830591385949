import React, { useRef, useEffect, useState, useId } from 'react' 
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import {ColorPicker} from "src/libs/useful"
import { Button, ButtonGroup, HandleInteractionKind, Intent, MultiSlider, Popover, Position } from '@blueprintjs/core'
import { COLOR_TYPE, Color as ColorScalar } from '@/libs/interfaces/layouts'
import { GRADIENT_DISTINATION, GRADIENT_TYPE, IColorGradient, IGradient, parseGradient } from '@/libs/utilities/colors' 
import chroma from "chroma-js";

const Color = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<ColorEnabled {...props} isHideInput={false} />}
        desabledForm={<ColorDesabled {...props} />}
    /> 
} 
export default Color

export const ColorEnabled = ({isHideInput, ...props}: any) : JSX.Element =>  {
    const [colorType, setColorType] = useState<COLOR_TYPE>(
        props.colorType || 
        props.onlyPlainColor 
            ? 
            COLOR_TYPE.PLAIN 
            :
            props.onlyGradientColor 
                ? 
                COLOR_TYPE.GRADIENT 
                :
                props.value?.toString().indexOf( "-gradient" ) > -1 
                    ? 
                    COLOR_TYPE.GRADIENT 
                    : 
                    COLOR_TYPE.PLAIN || 
        COLOR_TYPE.PLAIN
    )
    const onChangeType = () => {
        const next = () => {
            return {
                [ COLOR_TYPE.GRADIENT ] : COLOR_TYPE.PLAIN,
                [ COLOR_TYPE.PLAIN ] : COLOR_TYPE.GRADIENT
            }
        }
        setColorType( next()[ colorType ] )
    }
    const onColor = (color: any) => { 
        if( props.onChange) props.onChange( color, props.field, "" )
    } 
    let scalar: JSX.Element =<></>
    switch(colorType) {
        case COLOR_TYPE.GRADIENT:
            scalar = <GradientExample value={props.value} field={props.field}  onChange={onColor} />
            break
        case COLOR_TYPE.PLAIN:
        default:
            scalar = <ColorExample value={props.value} field={props.field} onChange={onColor} isHideInput={isHideInput} />
    }
    return <div className={ `d-flex w-100 align-items-center ${props.className ? props.className : ""}` }>  
        <div className="d-flex flex-grow-100">
            {scalar}
        </div>  
        <TypeButton 
            onlyPlainColor={props.onlyPlainColor} 
            onlyGradientColor={props.onlyGradientColor} 
            colorType={ colorType } 
            onChangeType={onChangeType}
        /> 
    </div>
}
export const ColorDesabled = (props: IScalarForm) : JSX.Element => {
    return <div 
        className={`pe-color-picker-example ${props.className}`} 
        style={{ backgroundColor: props.value }} 
    /> 
}
const ColorExample = ({value, field, onChange, isHideInput}: any) : JSX.Element => {
    const onColor = (color: any) => { 
        if( onChange) {
            onChange( color, field, "" )
        }
    }
    const _onChange = (fileData: any ) => {
        if( onChange)  onChange( fileData,  field, "" )
    }
    return <>
        <Popover content={ <ColorPicker color={ value || "#FFFFFF" } onChoose={onColor} /> } >            
            <div className='pe-color-picker-example' style={{backgroundColor: value || "#FFFFFF"}}/> 
        </Popover> 
        {
            !isHideInput && <input
                type="string"
                className="form-control input dark"
                value={ value  === null ? "" : value }
                onChange={ (evt: any) => _onChange( evt.currentTarget.value ) }
            />
        }
        
    </>
}

const GradientExample = ({value, field, onChange}: any) : JSX.Element => { 
    const [data, setData] = useState<IGradient>(parseGradient(value)) 
    useEffect(() => {
        setData( parseGradient(value) )
    }, [value])
    console.log(value, data)
    const  onDistination = (distination: string) => {

    }
    const onType = ( ) => {
        const next = ( ) => {
            return {
                [GRADIENT_TYPE.LINEAR] : GRADIENT_TYPE.RADIAL,
                [GRADIENT_TYPE.RADIAL] : GRADIENT_TYPE.LINEAR,
            }
        }
        let _data = {...data}
        _data.type = next()[ data.type || GRADIENT_TYPE.LINEAR ]
        setData(_data)
    }
    return <>
        <GradientHandler data={data} />
        <GradientDistination distination={ data.line || GRADIENT_DISTINATION.TO_LEFT } onClick={onDistination}/>
        <GradientType type={ data.type || GRADIENT_TYPE.LINEAR } onClick={onType} />
    </> 
}

const GradientType = (props: any) : JSX.Element => {

    const onClick = () => {
        props.onClick()
    }
    return <Button 
        minimal 
        className='p-2 mx-0 hint hint--top' 
        data-hint={props.type === GRADIENT_TYPE.LINEAR ? "Linear gradient" : "Radial gradient"} 
        onClick={onClick} 
    >
        <svg 
            version="1.1"
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px"
	        viewBox="0 0 223.8 222.2"
            width={26}
            height={26}
        > 
        {
            props.type === GRADIENT_TYPE.LINEAR
                ?
                <>
                    <path d="M59.4,205.8L59.4,205.8c-16.2,0-29.4-13.2-29.4-29.4V41.8c0-16.2,13.2-29.4,29.4-29.4h0c16.2,0,29.4,13.2,29.4,29.4v134.6C88.8,192.7,75.6,205.8,59.4,205.8z"/>
                    <path d="M132.1,205.8L132.1,205.8c-9.6,0-17.3-7.7-17.3-17.3V29.8c0-9.6,7.7-17.3,17.3-17.3h0c9.6,0,17.3,7.7,17.3,17.3v158.8C149.4,198.1,141.7,205.8,132.1,205.8z"/>
                    <path d="M185.4,205.8L185.4,205.8c-3.7,0-6.6-3-6.6-6.6V19.1c0-3.7,3-6.6,6.6-6.6l0,0c3.7,0,6.6,3,6.6,6.6v180.1C192,202.9,189.1,205.8,185.4,205.8z"/>
                </> 
                :
                <>
                    <path d="M110.4,206.5c-51.6,0-93.5-42-93.5-93.5s42-93.5,93.5-93.5c51.6,0,93.5,42,93.5,93.5S161.9,206.5,110.4,206.5z M110.4,59.4c-29.5,0-53.5,24-53.5,53.5s24,53.5,53.5,53.5s53.5-24,53.5-53.5S139.9,59.4,110.4,59.4z"/>
                    <path d="M110.4,160.5c-26.2,0-47.5-21.3-47.5-47.5c0-26.2,21.3-47.5,47.5-47.5s47.5,21.3,47.5,47.5C157.9,139.2,136.5,160.5,110.4,160.5z M110.4,81.5C93,81.5,78.9,95.6,78.9,113s14.1,31.5,31.5,31.5s31.5-14.1,31.5-31.5S127.7,81.5,110.4,81.5z"/>
                    <path d="M110.4,136.1c-12.7,0-23.1-10.4-23.1-23.1c0-12.7,10.4-23.1,23.1-23.1s23.1,10.4,23.1,23.1C133.4,125.7,123.1,136.1,110.4,136.1z M110.4,95.9c-9.4,0-17.1,7.7-17.1,17.1s7.7,17.1,17.1,17.1s17.1-7.7,17.1-17.1S119.8,95.9,110.4,95.9z"/>
                </>      
        }
        </svg>
    </Button>
}
const GradientDistination = (props: any) : JSX.Element => {
    const getDistination = (distination: GRADIENT_DISTINATION) : string => {
        switch( distination ) {
            case GRADIENT_DISTINATION.TO_LEFT:
                return ("left")  
            case GRADIENT_DISTINATION.TO_RIGHT:
                return ("right" ) 
            case GRADIENT_DISTINATION.TO_TOP:
                return ("up" ) 
            case GRADIENT_DISTINATION.TO_BOTTOM:
            default:
                return("down")  
        }
    }
    const [distination, setDistination] = useState<string>(  getDistination( props.distination ) )
    const onClick = () => {
        const next = (): any => ({
            left: "up",
            up: "right",
            right: "down",
            down: "left", 
        })
        setDistination( next()[distination].toString() )
        if(props.onClick) {
            props.onClick( `to_${ next()[distination].toString() }` )
        }
    }
    
    return <Button minimal className='p-2 ml-3' onClick={onClick}>
        <i className={`fas fa-arrow-${distination}`}></i>
    </Button>
}

const TypeButton = (props: any) : JSX.Element => {
    return props.onlyPlainColor || props.onlyPlainColor
        ?
        <></> 
        : 
        <Button minimal className="mr-3 px-3  w_25" onClick={props.onChangeType}>
            <span className="small">
            {
                props.colorType === COLOR_TYPE.PLAIN
                    ? 
                    "P"
                    :
                    "G"
            }
            </span>
        </Button>
}

interface IGradientHandlerProps {
    data: IGradient
}
const GradientHandler = (props: IGradientHandlerProps): JSX.Element => {
    const [data, setData] = useState<IGradient>(props.data)
    const boxRef = useRef<HTMLDivElement>(null)
    const id = useId()
    const handleChange = (values: number[]) => {
        // console.log(values)
        if( values.length === data.colorStopList.length ) {
            let _data: IGradient = { ...data }
            _data.colorStopList = _data.colorStopList.map(( csl, i ) => {
                return { ...csl, position: `${values[i]}%` }
            })
            setData( _data )
        }
    }
    const onRealesHandler =( newValue: number, n: number ) => {
        console.log( newValue, n )
    }
    
    const onClick = (evt: any ) => { 
        const offset = evt.target.getBoundingClientRect() 
        const newPos: number = Math.floor((evt.clientX - offset.left)/ offset.width * 100)
        let _data: IGradient = {...data}
        data.colorStopList.forEach((csl, i) => {
            if(
                parseInt( csl.position ) > newPos + 1 && 
                data.colorStopList[ i - 1 ] 
                    ? 
                    parseInt( data.colorStopList[i - 1].position ) < newPos - 1
                    :
                    false 
            ) {
                _data.colorStopList.splice(i, 0, { position: newPos + "%", color: getGradientCalc( data.colorStopList[i - 1], csl, newPos ) })
            }
        })
        setData( _data )
    }
    const onColor = ( color: any, csl: IColorGradient, i: number) => {
        console.log( color, csl, i )
        let _data: IGradient = {...data}
        _data.colorStopList[i].color = color
        setData( _data )
    }
    const onDelete = (i:number) => {
        if(data.colorStopList.length > 2) {
            let _data: IGradient = {...data}
            _data.colorStopList.splice(i, 1)
            setData( _data )
        }
    } 
    const getLeft = (n: number) : number => {
        try {
            var element = document.querySelector(`[aria-label="handle_${id}_${ n }"]`)
            var style = getComputedStyle( element! );
            var left = style?.getPropertyValue("left") || ""
            console.log( left )
            return parseInt(left) - 7
        }
        catch {
            return 0
        }
        
    }
    return <div className="pe-gradient-handler-container w-100">
        <div className='position-relative'>
        {
            data.colorStopList.map((csl, i) => {
                return <div
                    key={i}
                    style={{   
                        position: "absolute",
                        top: -26,
                        left: getLeft(i)
                    }}
                >
                    <Popover position={Position.TOP} content={ <div className="p-1">
                        <ColorPicker color={ csl.color} onChoose={( color: any ) => onColor(color, csl, i)} />
                        <div className="d-flex">
                            {
                                data.colorStopList.length > 2 && <ButtonGroup >
                                    <Button minimal icon="trash" onClick={ ()=> onDelete(i) }/>
                                </ButtonGroup>
                            } 
                            <input 
                                type="string" 
                                className='flex-grow-100 w-100'
                                value={ csl.color } 
                                onChange={ (evt) => onColor(evt.currentTarget.value, csl, i) } 
                            /> 
                        </div>
                    </div> } >            
                        <div 
                            className='pe-color-picker-example' 
                            style={{
                                backgroundColor: csl.color, 
                                height:18, 
                                margin:0,
                                width: 22, 
                            }}
                        /> 
                    </Popover> 
                </div>
            })
        }
        </div>
        <MultiSlider
            defaultTrackIntent={Intent.SUCCESS}
            labelStepSize={200}
            max={100}
            min={0}
            onChange={handleChange} 
            stepSize={1} 
            vertical={false}
            labelRenderer={ false }
            showTrackFill={false}
        >
            {
                data.colorStopList.map((csl, i) => {
                    return <MultiSlider.Handle
                        key={ i }
                        type="full"
                        value={ parseInt( csl.position ) }
                        intentBefore="danger"
                        interactionKind={ HandleInteractionKind.PUSH }
                        htmlProps={{ "aria-label": `handle_${id}_${ i }` }}
                        onRelease={newval => onRealesHandler(newval, i)}
                    />
                })
            } 
        </MultiSlider>
        <div 
            style={{
                width: "100%",
                height: 27,
                background: `linear-gradient(to right, ${ data.colorStopList.map(scl => scl.color + " " + scl.position).join(",") })`
                
            }}
            className='pe-color-picker-example111'
            ref={ boxRef }
            onClick={ onClick }
        >

        </div>
    </div>
}

const getGradientCalc = (prev: IColorGradient, next : IColorGradient, newPos: number) : ColorScalar => {
    // const prevPos : number = parseInt(prev.position)
    // const nextPos : number = parseInt(next.position)
    return chroma.average([ prev.color, next.color ], 'rgb').toString()
}