
import { IEvent, IEventType,  } from "../../data/interfaces"
import { LayoutIcon } from "src/libs/useful"
import { __, getContrastColor } from "src/libs/utilities"
 
interface IProps {
    item: IEvent 
    className: string
    isShort?: boolean
}
const EventTypeLabel = (props: IProps) : JSX.Element => {
    const sz = 16
    return  <div className={` d-flex flex-wrap ${props.className}`}> 
        {
            props.item.eventTypes?.map((pt: IEventType, i: number) => {
                return props.isShort && i > 0
                    ?                    
                    <div 
                        className=" m-1 d-flex align-items-center rounded-pill pointer" 
                        key={pt.id} 
                        style={{background:pt.color}}
                        title={ __(pt.title) }
                    >
                        <span className="topos-placetype-label-icon rounded-pill" style={{ height: sz + 12 }}>
                            <LayoutIcon
                                src={ pt.icon }
                                className=""
                                style={{ width:sz, height: sz }}
                            />
                        </span> 
                    </div>
                    :
                    <div 
                        className=" m-1 d-flex align-items-center rounded-pill pointer"  
                        key={ pt.id } 
                        style={{ background:pt.color, height: sz + 12 }}
                    >
                        <span className="topos-placetype-label-icon" style={{ height: sz + 12, width:sz + 18 }}>
                            <LayoutIcon
                                src={ pt.icon }
                                className=""
                                style={{ width:sz, height: sz }}
                            />
                        </span>
                        <span className="px-2 py-1" style={{ color: getContrastColor(pt.color) }}>
                            {__(pt.title)}
                        </span>
                    </div>
            })
        }
    </div> 
}
 
export default EventTypeLabel