
import { IEvent } from "../../data/interfaces"
import EventCard from "../components/EventCard"
import { IToposPostsStore, useToposPostsStore } from "../../data/store"

/*
  TODO: удалить ссылку на useToposPostsStore. Заменить на реальный источник [FestivalSite]
*/
const Day = (props: any) : JSX.Element => { 
  
  const  events: IEvent[] = useToposPostsStore((state:IToposPostsStore) => state.events) 
  return <div >
    <div className=" d-flex justify-content-center position-relative " >
      <div className="row w-100">
      { 
        events.map( event => <EventCard item={event} key={event.id} height={420} /> )
      }  
      </div>
    </div> 
  </div>
} 
export default Day
 