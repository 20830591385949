import { IMenuItem } from "../interfaces/layouts";
import { getAllRoutes, getRouteByComponent } from "../layouts";

export function data_type_link_url(data_type_class_name: string): string | JSX.Element {
    if( data_type_class_name)
    {
      const routeObj = getAllRoutes().filter((route: any) => route.singled_data_type === data_type_class_name)[0]
      return routeObj ? routeObj.route : <></>
    }   
    return <></>
  }
  
  // value of route
  export function data_type_feed_route(data_type_class_name: string): IMenuItem {
    if( data_type_class_name)
    {
      const routeObj = getAllRoutes().filter((route: any) => route.feed_data_type === data_type_class_name)[0]
      return routeObj  || {}
    }   
    return {} as IMenuItem
  }

  // value of route.realRoute
  export function data_type_feed_url(data_type_class_name: string): string {
    if( data_type_class_name)
    {
      const routeObj = getAllRoutes().filter((route: any) => route.feed_data_type === data_type_class_name)[0]
      return routeObj ? routeObj.realRoute : "/"
    }   
    return "/"
  }
  export function data_type_admin_url(data_type_class_name: string): string {
    if( data_type_class_name)
    {
      const routeObj = getAllRoutes().filter((route: any) => route.data_type === data_type_class_name)[0] 
      return routeObj ? routeObj.realRoute : "/"
    }   
    return "/"
  }
  export const component_url = (componentName: string) : string => {
    return getRouteByComponent(componentName)?.realRoute || ""
  }