import { Role } from "@/libs/interfaces/layouts"
import FieldInput from "@/libs/scalars"
import IScalarForm, { SCALAR_TYPES } from "@/libs/scalars/interface/IScalarForm"
import Scalar from "@/libs/scalars/scalars/Scalar"
import { YoutubeDesabled } from "@/libs/scalars/scalars/Youtube"
import { __ } from "@/libs/utilities"
import { Button, Collapse } from "@blueprintjs/core"
import { useState } from "react"

const AdminMenuItemHelpYoutubeWidget = (props: IScalarForm) : JSX.Element => {
    return <Scalar
        {...props}
        enabledForm={<HelYoutubeEnabled {...props} />}
    />
} 

export default AdminMenuItemHelpYoutubeWidget

const HelYoutubeEnabled = (props: IScalarForm) : JSX.Element => {
    const onAdd = () => {
        let val = Array.isArray(props.value) 
            ? 
            [ ...props.value, { id: "", title: "", description: "", roles:[] } ] 
            : 
            [ { id: "", title: "", description: "", roles:[]  } ] 
        if( props.onChange ) {
            props.onChange( val, props.field, "" )
        } 
    }
    const onSingleChange = (i: number, id: string, title: string, description: string, roles: Role[] ) => {
        let val = Array.isArray(props.value) ? [ ...props.value ] : [ ] 
        if( props.onChange ) {
            val.splice( i, 1, { id, title, description, roles } )
            props.onChange( val, props.field, "" )
        } 
    }
    const onDelete = (i:number) => {
        let val = Array.isArray(props.value) ? [ ...props.value ] : [ ] 
        if( props.onChange ) {
            val.splice( i, 1 )
            props.onChange( val, props.field, "" )
        } 
    }
    return <div className="w-100 my-2">
        <div>
            <Button minimal icon="plus"  onClick={onAdd} >{__("Add")}</Button>
        </div>
        {/* <div className="p-4">{JSON.stringify(props.value)}</div> */}
        <div className="">
        {
            Array.isArray(props.value) 
                ?
                props.value.map( (v: any, i: number) => {
                    return  <Single 
                        {...v} 
                        i={i} 
                        key={i} 
                        onChange={onSingleChange} 
                        onDelete={onDelete} 
                    /> 
                })
                :
                <div></div>
        }
        </div>
    </div>
}

interface ISingleProps {
    id: string
    title: string
    description: string
    roles: Role[]
    i: number
    onChange: (i:number, id: string, title: string, description: string, roles: Role[]) => void
    onDelete: (i:number) => void
}
const Single = (props: ISingleProps) : JSX.Element => { 
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onIDChange = ( value:string, field: string ) => {  
        const myregexp: RegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        const v = value.match( myregexp )      
        props.onChange( 
            props.i, 
            (v && v[7]?.length === 11) ? v[7] : '', 
            props.title, 
            props.description,
            props.roles
        ) 
    }
    const onTitle = (title: string) => {
        props.onChange( props.i, props.id, title, props.description, props.roles )  
    }
    const onDescription = (description: string) => {
        props.onChange( props.i, props.id, props.title, description, props.roles )  
    }
    const onRoles = (roles: string[]) => {
        props.onChange( props.i, props.id, props.title, props.description, roles )  
    }
    const onOpen = () => {
        setIsOpen(!isOpen)
    }
    return <div className="p-1 border-bottom border-secondary "> 
        <div className="flex-grow-100 d-flex flex-justify-between align-items-end">
            <YoutubeDesabled  
                value={props.id}
                onChange={(value:any, field: string, data: any) => onIDChange(value, field )}
            />
            <Button minimal icon={`caret-${ isOpen ? "up" :"down"}`} onClick={onOpen} />
        </div>
        <Collapse isOpen={isOpen}>
            <FieldInput
                type={SCALAR_TYPES.STRING}
                description={__("Paste here URL of Youtube video")}
                editable
                title={__( "Video ID" )}
                onChange={onIDChange}
                value={props.id}
            />
            <FieldInput
                type={SCALAR_TYPES.STRING}
                editable
                title={__( "Title" )}
                onChange={onTitle}
                value={props.title}
            />
            <FieldInput
                type={SCALAR_TYPES.HTML}
                editable
                title={__( "Description" )}
                onChange={onDescription}
                value={props.description} 
            />
            <FieldInput
                type={SCALAR_TYPES.TAGS}
                editable
                title={__( "Roles" )}
                onChange={onRoles}
                value={props.roles} 
            />
            <Button minimal icon="trash" onClick={() => props.onDelete(props.i) } />
        </Collapse>
        
    </div>
}