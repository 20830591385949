import { IStorytellingStore, useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { Button, Icon } from "@blueprintjs/core"
import { NavLink, useNavigate } from "react-router-dom"
import ConfirmButton from "src/libs/useful/ConfirmButton"
import { __ } from "src/libs/utilities"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface IFabulaPlayPauseProps {

}
const FabulaPlayPause = (props: IFabulaPlayPauseProps): JSX.Element => {
    const isPaused : boolean = useStorytellingStore((state: IStorytellingStore) => state.isPaused)
    const navigate = useNavigate()
    const onClick = () => {
        useStorytellingStore.setState({isPaused : !useStorytellingStore.getState().isPaused})
    }
    const onBreak = () => {
        navigate("break")
    }
    return <div className='pe-fabula-play-pause-container'>
        <Button minimal large onClick={onClick} >
            <Icon icon={isPaused ? "play" : "pause"} size={22} />
        </Button> 
        <ConfirmButton
            buttonMinimal
            buttonLarge
            buttonLabel={<Icon icon="stop" size={22} />}
            dialogClasssName={DIALOG_SIZE.MINIMAL}
            dialogAddedClassName={"transparent-dialog"}
            onConfirm={onBreak}
        >
             <div className="m-4">
                {__("Are you really want break your quest?")}    
            </div>   
        </ConfirmButton>        
    </div>
}
export default FabulaPlayPause