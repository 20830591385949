import React from 'react' 
import { __ } from '@/libs/utilities'
import { ITrack } from '../../data/interfaces' 
import FestTrackCard from './FestTrackCard' 
import { useDataStore } from '@/modules/pe-basic-module/data/store'

const Tracks = (props : any) : JSX.Element | false => {
    const tracks: ITrack[] = useDataStore((state: any) => state.PEFestTrack) || []
    //console.log(tracks)
    
    return !!tracks?.length && <div className="container" id="tracks">
        <div className="row justify-content-center py-4 px-md-5 px-2">
            <div className="col-md-12 d-flex justify-content-center">
                <div className="role_descr">
                    {__("Tracks on Festival")}
                </div>
            </div>
        </div>
        <div className="row justify-content-center px-2">
        {
            tracks?.map( 
                (track: ITrack) => <FestTrackCard item={track} key={track.id} /> 
            )
        }
        </div>
    </div> 
} 

export default Tracks