import React from 'react'
import BasicState from "@/libs/basic-view"
import ClientForm from './client/ClientForm' 
import { client } from '../data/cliets'

const {ApolloProvider} = require('@apollo/client')


export default class ClientView extends BasicState {
    props: any
    addRender() {
        const serverUrl = this.props.extend_params?.serverUrl
        return  serverUrl ?
            <ApolloProvider client={ client( serverUrl ) } >
                <ClientForm {...this.props} />
            </ApolloProvider>
            :
            <ClientForm {...this.props} />
    }
}
