import { Button, Dialog, Icon, Intent } from "@blueprintjs/core"
import { __ } from "src/libs/utilities"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { All, IEvent, USER_EVENT_REQUEST_STATUS } from "../../data/interfaces"
import { useEffect, useState } from "react"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import { actions } from "../../data/actions"
import { GET_USER_EVENT_REQUEST_ACTION } from "../../data/actionTypes"

interface IProps {
    item : All
    className?: string
    buttonClassName?: string
}

const EventRequestForm = (props:IProps) :JSX.Element => {
    
    const user:IUser = useMainStore((state:IState) =>state.user)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isRequested, setIsRequested] = useState<string>( USER_EVENT_REQUEST_STATUS.NONE )
    useEffect(() => {
        actions(GET_USER_EVENT_REQUEST_ACTION, {userId: user?.id, eventId: props.item?.id})
            .then(result => { 
                setIsRequested( result )
            })
    }, [props.item])
    const onOpen = () => {
        setIsOpen(true)
    }
    const onClose = () => {
        setIsOpen(false)
    }
    const btn =  user?.id
        ?
        isRequested === USER_EVENT_REQUEST_STATUS.NONE
            ?
            <div className={` ${props.className}`}>
                <Button large intent={Intent.SUCCESS} onClick={onOpen} className={props.buttonClassName}>
                    {__("Send request")}
                </Button> 
            </div>
            :
            isRequested === USER_EVENT_REQUEST_STATUS.WAITING
                ?
                <div className={` ${props.className}`}>
                    <Button large intent={Intent.DANGER} onClick={onOpen}>
                        {__("Withdraw request")}
                    </Button> 
                </div>
                :
                <div className={` ${props.className}`}>
                    <Button large intent={Intent.NONE} onClick={onOpen}>
                        {__("You are invited")}
                    </Button>
                </div>
        :
        <></>
    return <>
        { btn }
        <Dialog
            className={DIALOG_SIZE.FULL}
            isOpen={isOpen}
        >
            <div className=" h-100">
                <DialogContent type={isRequested} onClose={onClose} />
            </div>
            <Button minimal onClick={onClose} className="position-absolute top right px-3 py-2">
                <Icon icon="cross" size={20} color="#FFFFFF"/>
            </Button>
        </Dialog>
    </>
} 
export default EventRequestForm

const DialogContent = ({ type, onClose, ...props }: any) :JSX.Element => {
    switch(type) {
        case USER_EVENT_REQUEST_STATUS.WAITING:
            return <div className="flex-centered h-100">
                <div className="small-container ">
                    <h4>
                        {__("Send request")}
                    </h4> 
                    <div className="my-4 text-larger">
                        {__("The request for participation has been sent. A response from the organizers is awaited")}
                    </div>
                </div>
            </div>
        case USER_EVENT_REQUEST_STATUS.AGREED:
            return <div className="flex-centered h-100">
                <div className="small-container ">
                    <h4>
                        {__("Send request")}
                    </h4> 
                    <div className="my-4 text-larger">
                        {__("You are invited. The organizers are looking forward to your presence")}
                    </div>
                </div>
            </div>
        case USER_EVENT_REQUEST_STATUS.NONE:
        default:
            return <div className="flex-centered h-100">
                <div className="small-container ">
                    <h4>
                        {__("Send request")}
                    </h4> 
                    <div className="my-4 text-larger">
                        {__("Send a request for participation to the organizers")}
                    </div>
                </div>
            </div>
    }
    
}