import { AppToaster } from "@/libs/useful"
import { Intent } from "@blueprintjs/core"

export const setRequestError = ( 
    action: string, 
    data: undefined,  
    error: any
) => {
    // console.log( action, data ) 
    // console.log( error.message )
    // console.log( error.stack )
    AppToaster.show({
        message: error.message.toString(),
        timeout: 10000,
        className: "p-4 lead",
        intent:Intent.DANGER
    })
}