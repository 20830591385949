import React, { useEffect, useState } from "react" 
import {ReactComponent as Loader} from 'src/assets/img/loader.svg'
import { WINDOW_WIDTH } from "../interfaces/layouts"

interface ILoadingProps {
  classNames?: string
  style?: any
  fill?: string
}
const DefaultLoaderProps = {
  style: {  width:"100%", height: 70, display:"flex", justifyContent:"center" },
  fill: "#444"
}

const Loading = (props: ILoadingProps | any):JSX.Element => { 
  return <div className="loader-cont">
    <div className={`fa-spin ${props?.classNames && ""} ` } >
      <Loader fill={props.fill} width={60} height={60} />
    </div>
  </div> 
} 

export default Loading 

export const LoaderLine = (props:ILoadingProps = DefaultLoaderProps):JSX.Element =>
{
  const [isMobyle, setMobyle] = useState( window.innerWidth < WINDOW_WIDTH.TABLET)
    const onResizeHandler = () => {
      setMobyle( window.innerWidth < WINDOW_WIDTH.TABLET )
    }
    useEffect(() => {
      onResizeHandler()
      window.addEventListener("resize", onResizeHandler)
      return () => window.removeEventListener("resize", onResizeHandler)
    }, [])
  //console.log( "isMobyle", isMobyle )
  return <div style={ props.style }>
    {
      isMobyle
        ?
        <i className="fas fa-sync fa-spin fa-" />
        :
        <svg 
          version="1.1" 
          xmlns="http://www.w3.org/2000/svg" 
          xmlnsXlink="http://www.w3.org/1999/xlink" 
          x="0px"
          y="0px"
          height={80}
          viewBox="0 0 80 100" 
          enableBackground="new 0 0 0 0" 
          xmlSpace="preserve"
        >
          <circle fill={props.fill} stroke="none" cx="6" cy="50" r="6">
            <animateTransform 
              attributeName="transform" 
              dur="1s" 
              type="translate" 
              values="0 15 ; 0 -15; 0 15" 
              repeatCount="indefinite" 
              begin="0.1"/>
          </circle>
          <circle fill={props.fill} stroke="none" cx="30" cy="50" r="6">
            <animateTransform 
              attributeName="transform" 
              dur="1s" 
              type="translate" 
              values="0 10 ; 0 -10; 0 10" 
              repeatCount="indefinite" 
              begin="0.2"/>
          </circle>
          <circle fill={props.fill} stroke="none" cx="54" cy="50" r="6">
            <animateTransform 
              attributeName="transform" 
              dur="1s" 
              type="translate" 
              values="0 5 ; 0 -5; 0 5" 
              repeatCount="indefinite" 
              begin="0.3"/>
          </circle>
        </svg>  
    }
      
    </div> 
}

export const LoaderMinimal = (props:ILoadingProps = {...DefaultLoaderProps }):JSX.Element => {
  return <i 
    className="fa-spin" 
    style={{ 
      width: 14, 
      height: 14, 
      borderTop:"2px #777777 solid", 
      borderLeft:"2px #777777 solid", 
      borderBottom:"2px #777777 solid", 
      borderRadius: 20, 
    }}
  />
}
export const LoaderBallsLine = (props:ILoadingProps = {...DefaultLoaderProps }):JSX.Element =>
{
  const [fill, ] = useState(props.fill )  
  const [isMobyle, setMobyle] = useState( window.innerWidth < WINDOW_WIDTH.TABLET)
    const onResizeHandler = () => {
      setMobyle( window.innerWidth < WINDOW_WIDTH.TABLET )
    }
    useEffect(() => {
      onResizeHandler()
      window.addEventListener("resize", onResizeHandler)
      return () => window.removeEventListener("resize", onResizeHandler)
    }, [])
  // console.log( "isMobyle", isMobyle )

  return isMobyle
        ?
        <i className="fas fa-sync fa-spin fa-" />
        :
        <div style={ props.style }>
          <svg 
            version="1.1" 
            className="loader-ball-line-svg" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px"
            y="0px"
            width="218px" 
            height="20px" 
            viewBox="0 0 512 47"
            enableBackground="new 0 0 0 0" 
            xmlSpace="preserve"
          >
            <g><circle fill={fill} cx="-14.781" cy="22.328" r="12.813"/><animateTransform attributeName="transform" type="translate" values="88 0;182 0;251 0;298 0;321 0;323.33 0;325.66 0;327.99 0;330.32 0;332.65 0;334.98 0;337.31 0;339.64 0;341.97 0;344.3 0;346.63 0;348.96 0;351.29 0;353.62 0;356 0;379 0;426 0;494 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0;542 0" dur="5180ms" repeatCount="indefinite"/></g><g><circle fill={fill} cx="-50.328" cy="22.328" r="12.797"/><animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;88 0;182 0;251 0;298 0;321 0;323.33 0;325.66 0;327.99 0;330.32 0;332.65 0;334.98 0;337.31 0;339.64 0;341.97 0;344.3 0;346.63 0;348.96 0;351.29 0;353.62 0;356 0;406 0;452 0;522 0;577 0;577 0;577 0;577 0;577 0;577 0;577 0;577 0;577 0;577 0" dur="5180ms" repeatCount="indefinite"/></g><g><circle fill={fill} cx="-87.203" cy="22.328" r="12.797"/><animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;88 0;182 0;251 0;298 0;321 0;323.33 0;325.66 0;327.99 0;330.32 0;332.65 0;334.98 0;337.31 0;339.64 0;341.97 0;344.3 0;346.63 0;348.96 0;351.29 0;353.62 0;356 0;403 0;450 0;520 0;614 0;614 0;614 0;614 0;614 0;614 0" dur="5180ms" repeatCount="indefinite"/></g><g><circle fill={fill} cx="-125.234" cy="22.328" r="12.797"/><animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;88 0;182 0;251 0;298 0;321 0;323.33 0;325.66 0;327.99 0;330.32 0;332.65 0;334.98 0;337.31 0;339.64 0;341.97 0;344.3 0;346.63 0;348.96 0;351.29 0;353.62 0;356 0;402 0;448 0;518 0;611 0" dur="5180ms" repeatCount="indefinite"/></g>

          </svg>
        </div> 
}