import { Color } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface IMultiTagProps { 
    labels: string[]
    className?: string 
    round?: boolean
    minimal?: boolean
    fill?: Color
    onClick: ( ) => void
}
const MultiTag = (props: IMultiTagProps): JSX.Element => {
    const length = props.labels.length 
    return <div 
        className={`multi-tag ${ props.className || ""} ${ props.round ? " round " : "" } pointer`} 
        style={{backgroundColor: props.fill || "#5f6b7c"}}
    >
        {
            props.labels.map((label, i) => {
                const alpha =  i === 0 ? "AA" : Math.floor(120 / (length) * (length + 1 - i) ).toString( 16 ) 
                return <div 
                    className={ ` ${ props.minimal ? "minimal" : "" }  maxw-160` } 
                    style={{ backgroundColor: `#000000${ alpha }`, color: "#EEE" }} 
                    title={ label }
                    key={i}
                >
                    <div className="text-overflow" dangerouslySetInnerHTML={{__html: ` ${ label }`}} />
                </div>
            })
        }
    </div>
}
export default MultiTag