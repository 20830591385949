const addCSSClass = ( id: string, classNameText: string | Node ): void => {
    
    const old: HTMLElement | null  = document.getElementById( id )
    if( old)  old.remove();
    const el = document.createElement("style") 
    el.type = "text/css"  
    el.id = id
    el.append(classNameText)
    document.getElementsByTagName("head")[0].appendChild(el)
}
export default  addCSSClass