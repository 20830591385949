import { Widgets } from "@/libs/utilities"
import { IFestival, ILand } from "../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../data/store" 
import LastDiary from "../views/about/LastDiary"
import Laureates from "../views/about/Laureates"
import MyProjects from "../views/about/MyProjects"
import Tracks from "../views/about/Tracks"

/* Protopia Ecosystem component */ 
const FestivalAboutWidget = (props: any): JSX.Element => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const site: ILand = useFestDataStore((state: IFestDataStor) => state.site)
    if( !site.event_types.includes("Festival") ) {
        return <></>
    }
    
    return <div className='pe-festival-about-widget-container'>
        {
            festival.isShowLaureates && <Laureates />
        }
        <MyProjects />
        {
            festival.isDiary && <LastDiary />
        }
        {
            festival.isShowTracks &&  <Tracks />
        }
        <Widgets 
            areaName={ "fest-about" }
            data={ {} }
            defArea={ null }
        />
    </div>
}
export default FestivalAboutWidget