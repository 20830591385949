import { Button } from "@blueprintjs/core"
import IScalarForm from "../interface/IScalarForm"
import Scalar from "./Scalar"
import { YoutubeDesabled, YoutubeEnabled } from "./Youtube"
import { __ } from "@/libs/utilities"

 
const YoutubeList = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<YoutubeListEnabled {...props} />}
        desabledForm={<YoutubeListDesabled {...props} />}
    />
} 

export default YoutubeList

export const YoutubeListEnabled = (props: IScalarForm) : JSX.Element => {
    const onAdd = () => {
        let val = Array.isArray(props.value) ? [ ...props.value, "" ] : [ "" ] 
        if( props.onChange ) {
            props.onChange( val, props.field, "" )
        } 
    }
    const onSingleChange = ( value:any, field: string, i: number ) => {
        let val = Array.isArray(props.value) ? [ ...props.value ] : [ ] 
        if( props.onChange ) {
            val.splice(i, 1, value)
            props.onChange( val, props.field, "" )
        } 
    }
    const onDelete = (i:number) => {
        let val = Array.isArray(props.value) ? [ ...props.value ] : [ ] 
        if( props.onChange ) {
            val.splice(i, 1 )
            props.onChange( val, props.field, "" )
        } 
    }
    return <div className="w-100 my-2">
        <div>
            <Button minimal icon="plus"  onClick={onAdd} >{__("Add")}</Button>
        </div>
        <div className="">
        {
            Array.isArray(props.value) 
                ?
                props.value.map( (v: any, i: number) => {
                    return <div key={i} className="d-flex align-items-end ">
                        <div className="flex-grow-100">
                            <YoutubeEnabled 
                                {...{...props, value: v}} 
                                value={v}
                                onChange={(value:any, field: string, data: any) => onSingleChange(value, field, i)}
                            />
                        </div>
                        <Button minimal icon="trash" onClick={() => onDelete(i) } />
                    </div>
                })
                :
                <div></div>
        }
        </div>
    </div>
}

export const YoutubeListDesabled = (props: IScalarForm) : JSX.Element => {
    return <div>
        {
            Array.isArray(props.value) 
            ?
            props.value.map( (v: any) => {
                return <div>
                    <YoutubeDesabled {...{...props, value: v}} />
                </div>
            })
            :
            <div>{ props.value.toString() }</div>
        }
    </div>
}