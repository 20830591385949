import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Button, Intent } from '@blueprintjs/core'
import actions from 'src/modules/pe-basic-module/data/actions'
import { AppToaster, Loading } from 'src/libs/useful'
import { __, sprintf } from "src/libs/utilities"
import { getFields, getSingleRoute, schema } from "src/libs/layouts"
import { IAppoloField, IMenuItem } from "src/libs/interfaces/layouts"
import { Json } from 'src/libs/interfaces/layouts'
import { SCALAR_TYPES } from 'src/libs/scalars'
import {
    GET_SINGLE_ACTIONS,
    UPDATE_SINGLE_TYPE
} from 'src/modules/pe-basic-module/data/actionTypes'
import { IDataFormProps } from '../../data/interfaces'  
import { Link } from 'react-router-dom'
import FieldInputGroup from './FieldInputGroup'

const DataForm = (props: IDataFormProps): JSX.Element => {

    const navigate = useNavigate()
    const params = useParams()
    // console.log( params )
    const { id, landId } = params 
    const { data_type } = props
    const [isLoad, setIsLoad] = useState(true) 
    const [isUpdated, setIsUpdated] = useState(false) 
    const [item, setItem] = useState<any>({})
    const [itemJson, setItemJson] = useState<Json>("{}")
    const [fields, setFields] = useState<any>([])

    useEffect(() => {
        if(id === "new") {
            setItem({ })
            setItemJson(JSON.stringify({}))
            setIsLoad(false)
            const _fields = getFields(data_type)
            setFields(Object.keys(_fields).map(f => ({ name: f, ..._fields[f] })))
            return
        }
        actions(GET_SINGLE_ACTIONS, { data_type, id, land_id : landId })
            .then((response: any) => {
                setItem({ ...response })
                setItemJson(JSON.stringify(response))
                setIsLoad(false)
                const _fields = getFields(data_type)
                setFields(Object.keys(_fields).map(f => ({ name: f, ..._fields[f] })))
            })
        const handleBeforeUnload = () => {
            if (itemJson !== JSON.stringify(item)) {
                //console.log("GOTO AWAY!")
            };
        };
        // handles when page is unloaded
        window.addEventListener("beforeunload", handleBeforeUnload);
        // cleanup function handles when component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            handleBeforeUnload();
        };
    }, [])

    if (isLoad) return <Loading />

    const onUpdateItem = () => {
        const _item = {...item}
        const old : any = JSON.parse( itemJson ) || {}
        console.log("_item", _item)
        console.log("old", old)
        Object.keys(_item).forEach((key: string) => {
            if( JSON.stringify(_item[key]) ===  JSON.stringify( old[key] ) ) {
                delete _item[ key ]
            }
        })
        // setIsUpdated( true )
        Object.keys( _item ).forEach( (key:string) => {
            const schem: IAppoloField = schema()[ data_type ].apollo_fields[ key ]
            console.log( key, schem?.editable, schem?.type )
            if( typeof schem?.editable !== "undefined" &&  !schem?.editable) {
                return
            }
            if( schem?.type === SCALAR_TYPES.EXTERNAL ) {
                //console.log( schem.component )
                _item[key] = parseInt( _item[key]?.id || -1 )
            }
            if( schem?.type === SCALAR_TYPES.EXTERNAL_ARRAY ) {
                _item[key] = _item[key].map((it: any) => {
                    return parseInt( it?.id || -1 )
                })
            }
        })
        console.log( id, _item )
        actions(UPDATE_SINGLE_TYPE, { 
            data_type, 
            id: id === "new" || !id ? "-1" : id, 
            item: _item, 
            landId
        })
            .then(
                response => {
                    setIsUpdated( false )
                    console.log(response)
                    setItem(response)
                    setItemJson(JSON.stringify(response))
                    navigate( `${props.route}/${response.id}` )
                    AppToaster.show({
                        message:    __( id === "new" ? "Success create" : "Success update"),
                        className:  "p-3",
                        intent:     Intent.SUCCESS
                    })
                },
                error => {
                    console.error(error);
                    setIsUpdated( false )
                })
    }
    const onChange = ( _item: any ) => {
        setItem( _item )
    }

    const onBack = () => {
        navigate(-1)
    }
    const compare: boolean = JSON.stringify(item) === itemJson
    const singleRoute: IMenuItem | null = getSingleRoute(props.data_type) 
    return <>
        <div 
            className='display-6 my-4' 
            dangerouslySetInnerHTML={{
                __html: sprintf ( 
                    __(id === "new" ? "Now creating new %s" : "Now editing %s"), 
                    `<b>${__(schema()[props.data_type]?.name)}</b>` 
                )
            }}
        /> 
        <div className={isUpdated ? 'pre-hidden ' : ''}>
            <div className='mb-4 col-md-9 offset-md-3'>
                <Button large disabled={compare} minimal={compare} intent={compare ? Intent.NONE : Intent.DANGER} onClick={onUpdateItem}>
                    {__("Update")}
                </Button>
                <Button large minimal={compare} intent={Intent.SUCCESS} onClick={onBack}>
                    {__("Go back")}
                </Button>
                {
                    singleRoute && item?.id
                        ?
                        <Link
                            to={ `/${singleRoute.route}/${item.id}` }
                        >                            
                            <Button large minimal={compare} intent={Intent.SUCCESS} >
                                { __( "Goto" ) }
                            </Button>
                        </Link>
                        :
                        <div></div>
                }
            </div>
            <FieldInputGroup
                item={ item }
                fields={ fields }
                onChange={ onChange }
                landId={landId}
            />
            <div className='mt-4 col-md-9 offset-md-3'>
                <Button large disabled={compare} minimal={compare} intent={compare ? Intent.NONE : Intent.DANGER} onClick={onUpdateItem}>
                    {__("Update")}
                </Button>
                <Button large minimal={compare} intent={Intent.SUCCESS} onClick={onBack}>
                    {__("Go back")}
                </Button>
                {
                    singleRoute && item
                        ?
                        <Link
                            to={ `/${singleRoute.route}/${item.id}` }
                        >                            
                            <Button large minimal={compare} intent={Intent.SUCCESS} >
                                { __( "Goto" ) }
                            </Button>
                        </Link>
                        :
                        null
                }
            </div>
        </div>
    </>
}
export default DataForm