export const EXAMPLE_ACTIONS: string = 'example' 
export const GET_PLACES_ACTION: string = 'getPlaces' 
export const GET_EVENTS_ACTION: string = 'getEvents' 
export const GET_TEAM_ACTION: string = 'getTeam' 
export const GET_SINGLE_EVENT_ACTION: string = 'getSingleEvent' 
export const GET_SINGLE_PLACE_ACTION: string = 'getSinglePlace' 
export const GET_SINGLE_NEWS_ACTION: string = 'getSingleNews' 
export const GET_SINGLE_AD_ACTION: string = 'getSingleAd' 
export const GET_SINGLE_EVENT_TYPE_ACTION: string = 'getSingleEventType'  
export const GET_USER_SUBSCRIBED_ACTION: string = 'getUserSubscribed' 
export const GET_VKPOST_FEED_ACTION: string = 'getVKPostFeed' 
export const GET_NEWS_FEED_ACTION: string = 'getNews' 
export const GET_ADS_FEED_ACTION: string = 'getAds' 
export const GET_VIDEO_FEED_ACTION: string = 'getVideos' 
export const GET_USER_EVENT_REQUEST_ACTION: string = 'getUserEventRequest' 
