
import React, { Component } from "react"
import { Button, Dialog } from "@blueprintjs/core"
import { __ } from "../../utilities";
import Loading from "../Loading"; 
import gql from "graphql-tag" 

 class WPMediaLibrary extends Component
 {
    props: any
    state: any
    constructor(props: any)
    {
        super(props) 
        this.state = {
            isOpen : false,
            loading: false,
            thumbnails : [], 
            select : {}
        }
    }
    componentDidMount()
    {
        
    }
    onOpen = () =>
    {
        this.setState({
            isOpen  : !this.state.isOpen,
            loading : !this.state.isOpen
        })
        if(!this.state.isOpen)
        {
            const query = gql` query getMedias{ getMedias(paging:{ count:200, offset: 0 }){ id url mime large} }`
            this.props.client.query({ query })
                .then((result: any) => {
                    //console.log(result)	
                    this.setState({
                        loading : false,
                        thumbnails : result.data.getMedias
                    })				
                })
        }
    }
    onThumbSelect = (thumb: any, i: number) =>
    {
        //console.log(thumb, i)
        this.setState({ select: this.state.select.id !== thumb.id ? thumb : {} })
    }
    getMedias = () =>
    {
        const medias = this.state.thumbnails.map((thumb:any, i: number) =>
        {
            return thumb.url !== "false"
            ?
            <div 
                key={i}
                className={ "wp-thumbnail " + (thumb.id === this.state.select.id ? " active " : "") }
                style={{ backgroundImage: "url(" + thumb.url +")" }}
                onClick={() => this.onThumbSelect(thumb, i)}
            />
            :
            null
        })
        return medias

    }
    onLibrary = () =>
    {
        if(!this.state.select.id) return;
        if(this.props.onLibrary)
        {
            this.props.onLibrary(this.state.select)
        }
        this.setState({ 
            isOpen  : false
        })
    }
    render()
    {
        return <>
                <Button className=" ml-2 mt-3" onClick={ this.onOpen }>
                    {__("Media library")}
                </Button>
                <Dialog
                    isOpen={this.state.isOpen}
                    onClose={ this.onOpen }
                    title={__("Select media file")}
                    className="layout-outer-container  "
                >    
                    <div className="d-flex flex-column" style={{ height: "calc(100% - 50px)" }}> 
                        <div className="d-flex overflow-y-auto flex-grow-100 ">              
                        {
                            this.state.loading 
                                ?
                                <Loading />
                                :
                                <div className="p-4 d-flex flex-wrap">
                                    { this.getMedias() }
                                </div>

                        }
                        </div>
                        <div 
                            className="d-flex flex-grow-1 justify-content-center align-items-center "
                            style={{height:50}}
                        >
                            <Button onClick={this.onLibrary}>
                                {__("Select")}
                            </Button>
                            <Button icon="cross" onClick={this.onOpen} />
                        </div>
                    </div>                   
                </Dialog>
            </>
    }
    
}
export default WPMediaLibrary