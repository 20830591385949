import React, { FC, useEffect } from 'react' 
import { useState } from 'react' 
import helps from './helps'
import { Icon } from '@blueprintjs/core'
import { IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'

interface IProps {
  phase: string

}

const HelpContent = (props: IProps) : JSX.Element => {
    const [content, setContent] = useState(<></>)
    useEffect(() =>
    {
        if(!helps()[props.phase]) return 
        const ElComponent: FC = helps()[props.phase].component
        setContent( <ElComponent  /> )
    }, [])
    return <> {content} </>
}
export default HelpContent

export const HelpContactsDisplay = () : JSX.Element =>
{
  const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
  let vk, email, phone
  if( festival.vk || festival.vk2 )
  {
    vk = <div className="d-flex px-2">
      <Icon icon={<i className="fab fa-vk mx-2"/>} color="#FFF"/>
      {
        festival.vk
        ?
        <span className=" mx-2">{festival.vk}</span>
        :
        null
      }
      {
        festival.vk2
        ?
        <span className=" mx-2">{festival.vk2}</span>
        :
        null
      }
    </div>
  }
  if( festival.email || festival.email2 )
  {
    email = <div className="d-flex px-2">
      <Icon icon={<i className="fas fa-envelope mx-2"/>} color="#FFF"/>
      {
        festival.email
        ?
        <span className=" mx-2">{festival.email}</span>
        :
        null
      }
      {
        festival.email2
        ?
        <span className=" mx-2">{festival.email2}</span>
        :
        null
      }
    </div>
  }
  if( festival.phone || festival.phone2 )
  {
    phone = <div className="d-flex px-2">
      <Icon icon={<i className="fas fa-phone mx-2" />}  color="#FFF"/>
      {
        festival.phone
        ?
        <span className=" mx-2">{festival.phone}</span>
        :
        null
      }
      {
        festival.phone2
        ?
        <span className=" mx-2">{festival.phone2}</span>
        :
        null
      }
    </div>
  } 
  return <div className='my-3'>
      {vk} 
      {email} 
      {phone}
  </div>
}