
import { useEffect, useState } from "react"
import { Button } from "@blueprintjs/core"
import { WINDOW_WIDTH } from "src/libs/interfaces/layouts"
import PEVKPostFeed from "../components/PEVKPostFeed"
import { IToposStore, useToposStore } from "../../data/store"
import SearchLandscape from "./SearchLandscape"
import { friends } from "../../data/mocks/friends"

interface IProps {
    onOpenLogin: () => void
}
const LandscapeContainer = (props: IProps) : JSX.Element => {
    const isLandscapeClapanOpen : boolean = useToposStore((state: IToposStore) => state.isLandscapeClapanOpen ) 
    const [isOpen, setIsOpen] = useState<boolean>( isLandscapeClapanOpen )
    useEffect(() => { 
        setIsOpen( true ) 
    }, []) 

    const onFrienClick = (friend:any) => {
        window.innerWidth < WINDOW_WIDTH.TABLET && setIsOpen(false)
    }
    return <div className="topos-landscape-container">
        <div className={`topos-landscape-panel card overflow-y-hidden ${isOpen ? "open" : ""}`}>
            <SearchLandscape />
            {
                friends().map(friend => <Button 
                    minimal 
                    key={ friend.id } 
                    className=" landscape-fliend-btn " 
                    alignText="left"
                    onClick={ () => onFrienClick(friend)} 
                >
                        <div className="text-larger text-left text-overflow">
                           { friend.title } 
                        </div>
                    </Button>
                )
            }
        </div>
        <div className={`topos-landscape-feed overflow-y-auto-thin  ${isOpen ? "open" : ""}`}>
            <PEVKPostFeed />
        </div>
    </div>
    
} 

export default LandscapeContainer