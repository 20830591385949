import { Button, Dialog, Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { Color } from "src/libs/interfaces/layouts";
import { __ } from "src/libs/utilities";
import "./tennisus.scss";

type NumF = (n: number)=> void
/* Protopia Ecosystem component */
export interface ITennisProps {
    defense: number[]
    attack: number[]
    isActive: boolean
    onWin: () => void
    onLose: () => void
    dictionary: any
}
const Tennisus = (props: ITennisProps ): JSX.Element => { 
    const WINNING_SCORE: number = 1
    const CANVAS_WIDTH = 800
    const CANVAS_HEIGHT = 400
    const AGILITY = 3 // коэфициент замедления противника
    const PADDLE_THICKNESS: number = 5
    const PADDLE_HEIGHT: number = 300
    let canvas: any = document.getElementsByTagName('canvas')[0]  
    let canvasContext: CanvasRenderingContext2D 
    const [score1, setScore1] = useState<number>(0)
    const [score2, setScore2] = useState<number>(0)
    const [isFinScreen, setIsFinScreen] = useState<boolean>(false)
    let player1Score: number = 0 
    let player2Score: number = 0
    let showingWinScreen = false
	let ballX = 50 
    let ballY = 50 
    let ballSpeedX = 10 
    let ballSpeedY = 3  
    let deltaY: number
    let paddle1Y = 250 
    let paddle2Y = 250 

    useEffect(() => {
        canvas          = document.getElementById('gameCanvas')!
        canvasContext   = canvas.getContext('2d')!
        canvas.width    = CANVAS_WIDTH 
        canvas.height   = CANVAS_HEIGHT
        ballReset() 

        var framesPerSecond = 30 
        setInterval( 
            () => {
                moveEverything(score1,score2,setScore1,setScore2,) 
                drawEverything(score1,score2,) 
            }, 
            1000/framesPerSecond,
            score1, setScore1, score2, setScore2
        ) 

        canvas.addEventListener('mousedown', handleMouseClick)
        canvas.addEventListener('mousemove', handleDown)
        window.addEventListener('resize', resize)
        return () => {
            canvas.removeEventListener('mousedown', handleMouseClick)
            canvas.removeEventListener('mousemove', handleDown)
            window.removeEventListener('resize', resize)
        }    
    }, [])

    const resize = () => {
        canvas.width  = 800 
        canvas.height = 600
    }
    const calculateMousePos = (evt : any) => {
        var rect = canvas.getBoundingClientRect() 
        var root = document.documentElement 
        var mouseX = evt.clientX - rect.left - root.scrollLeft 
        var mouseY = evt.clientY - rect.top - root.scrollTop 
        return {
            x:mouseX,
            y:mouseY
        } 
    }

    const handleDown = function(evt: any) {
        var mousePos = calculateMousePos(evt) 
        paddle1Y = mousePos.y - ( attack() / 2 ) 
    }
    const handleMouseClick = ( ) => {
        if(showingWinScreen) {
            // player1Score = 0 
            // player2Score = 0 
            // setScore1( 0 )
            // setScore2( 0 )
            // showingWinScreen = false 
            // setIsFinScreen(false)
        }
    } 

    const ballReset = () => {
        if(player1Score >= WINNING_SCORE || player2Score >= WINNING_SCORE) { 
            showingWinScreen = true 
            setIsFinScreen(true) 
            onFinish()
        } 
        ballSpeedX = -ballSpeedX 
        ballX = canvas.width/2 
        ballY = canvas.height/2 
    }

    const computerMovement = () => {
        var paddle2YCenter = paddle2Y + ( defense() / 2 ) 
        if(paddle2YCenter < ballY - 35) {
            paddle2Y = ((paddle2Y + 60) * AGILITY + 1 )/ (AGILITY + 1)
        }
        else if(paddle2YCenter > ballY + 35) {
            paddle2Y = ((paddle2Y - 60) * AGILITY + 1 )/ (AGILITY + 1)
        }
    }

    const attack = () => {
        return PADDLE_HEIGHT / 3 * props.attack.length
    }
    const defense = () => {
        return PADDLE_HEIGHT / 3 * props.defense.length
    }
    const moveEverything = (score1:number,score2: number,setScore1: any,setScore2: any) => {
        if(showingWinScreen) {
            return 
        } 
        computerMovement() 
        ballX = ballX + ballSpeedX 
        ballY = ballY + ballSpeedY 

        if(ballX < 0) {
            if( ballY > paddle1Y && ballY < paddle1Y + attack() ) {
                ballSpeedX = -ballSpeedX
                deltaY = ballY - ( paddle1Y + attack() / 2 ) 
                ballSpeedY = deltaY * 0.35 
            }
            else {
                player2Score++ // must be BEFORE ballReset()
                setScore2( (score2: number) => score2 + 1)
                ballReset() 
            }
        }
        if( ballX > canvas.width ) {
            if(ballY > paddle2Y &&
                ballY < paddle2Y + defense()) {
                ballSpeedX = -ballSpeedX
                deltaY = ballY - (paddle2Y + defense() / 2) 
                ballSpeedY = deltaY * 0.35 
            }
            else {
                player1Score++ // must be BEFORE ballReset()
                setScore1( (score1: number) => score1 + 1)
                ballReset() 
            }
        }
        if( ballY < 0 ) {
            ballSpeedY = -ballSpeedY 
        }
        if( ballY > canvas.height ) {
            ballSpeedY = -ballSpeedY 
        }
    }

    const drawNet = () => {
        for(var i=0; i<canvas.height; i += 40 ) {
            colorRect( canvas.width / 2 - 1, i, 2, 20, 'white' ) 
        }
    }

    const drawEverything = ( score1: number,score2: number ) => {
        // next line blanks out the screen with canvas color
        // colorRect( 0, 0, canvas.width, canvas.height, '#00000011' )
        canvasContext.clearRect(0, 0, canvas.width, canvas.height) 

        if(showingWinScreen) {
            /*
            canvasContext.fillStyle = 'white' 
            canvasContext.textAlign = "center" 
            if(player1Score >= WINNING_SCORE) {
                canvasContext.font="50px Open Sans"
                canvasContext.fillText( "You won", canvas.width / 2, canvas.height / 2 ) 
            } 
            else if(player2Score >= WINNING_SCORE) {
                canvasContext.font = "50px Arial"
                canvasContext.fillText( "Computer won", canvas.width / 2, canvas.height / 2 ) 
            }
            canvasContext.font = "40px Open Sans"
            canvasContext.fillText( "click to continue", canvas.width / 2, 500 ) 
            */
            return
        } 
        drawNet() 
        // this is left player paddle
        canvasContext.globalAlpha   = 1
        colorRect(
            0, 
            paddle1Y, 
            PADDLE_THICKNESS, 
            attack(), 
            'paleGreen'
        ) 
        // this is right computer paddle
        colorRect( 
            canvas.width - PADDLE_THICKNESS, 
            paddle2Y, 
            PADDLE_THICKNESS, 
            defense(), 
            'hotpink' 
        ) 
        // next line draws the ball
        colorCircle( ballX, ballY, 10, 'white' ) 
        /*
        canvasContext.font = "75px Open Sans"
        canvasContext.globalAlpha = 0.6
        canvasContext.fillStyle = 'white'
        canvasContext.fillText(player1Score.toString(), 150, 300) 
        canvasContext.fillText(player2Score.toString(), canvas.width - 150, 300)
        */ 
    }

    const colorCircle = (centerX: number, centerY: number, radius : number, drawColor : Color) => {
        canvasContext.fillStyle = drawColor 
        canvasContext.globalAlpha   = 0.6
        canvasContext.beginPath() 
        canvasContext.arc(centerX, centerY, radius, 0, Math.PI*2, true) 
        canvasContext.fill() 
    }

    const colorRect = (leftX: number,topY: number, width: number,height: number, drawColor: string) => {
        canvasContext.fillStyle = drawColor 
        canvasContext.fillRect(leftX,topY, width,height) 
    } 

    const onFinish = () => {  
        if( WINNING_SCORE <= player1Score ){
            props.onWin()
        }
        if(WINNING_SCORE <= player2Score) { 
            props.onLose()
        }
    }
    const showResult = (): JSX.Element => {
        return <div>
            <div className="d-flex w-100 text-center">
                 
            </div>
            <div className={`text-center display-6 text-light m-3 p-5 ${WINNING_SCORE <= score1 ? "bg-success": "bg-danger"}`}>
                {
                    __( WINNING_SCORE <= score2 
                        ? 
                        props.dictionary.DefenseSurvived || "Defense survived"
                        : 
                        props.dictionary.YouLuck || "You hack"  
                    )
                }
            </div>
        </div>
    }
    return <div className='pe-tennis-container' style={{ width: CANVAS_WIDTH, height: CANVAS_HEIGHT }}>
        <canvas id="gameCanvas" />
        <div className="attack-balls-cont">
        {
           Array(WINNING_SCORE - score2).fill(0).map((ball, i) => <BallAttack key={i} /> )
        }
        </div>
        <div className="defense-balls-cont">
        {
            Array(WINNING_SCORE - score1).fill(0).map((ball, i) => <BallDefense key={i} /> )
        }
        </div>
        <div className={`flex-centered h-100 position-absolute top left w-100 h-100 bg-blur ${isFinScreen ? "" : " d-none"}`}>
            { showResult() }
        </div>
    </div>
}
export default Tennisus

const BallAttack = () => {
    return <div className="pe-fabula-ball-attack"></div>
}
const BallDefense = () => {
    return <div className="pe-fabula-ball-defense"></div>
}