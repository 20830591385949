import React, { useEffect, useState } from 'react' 
import { WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import SearchEngineMobile from './search/SeachEngineMobile'
import SearchEngineScreen from './search/SearchEngineScreen'

const SearchEngine = () :JSX.Element => {
    
    const [isMobyle, setMobyle] = useState( window.innerWidth < WINDOW_WIDTH.TABLET )
    const onResizeHandler = () => {
        setMobyle( window.innerWidth < WINDOW_WIDTH.TABLET )
      }
      useEffect(() => { 
        onResizeHandler()
        window.addEventListener("resize", onResizeHandler)
        return () => window.removeEventListener("resize", onResizeHandler)
      }, [])

      return isMobyle
        ?
        <SearchEngineMobile />
        :
        <SearchEngineScreen />
} 

export default SearchEngine