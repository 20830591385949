import { IDoActionReturn } from "@/modules/pe-manitu-module/data/mocks/doAction"
import { IManituAction, IManituActionType, IManituCell, IManituRow, IOrganoid, MATRIX_MODE } from "../../../data/interfaces"
import Cell from "./Cell"

interface IRowProps {
    item: IManituRow
    mode: MATRIX_MODE
    actions: IManituAction[]
    organoids: IOrganoid[]
    onActionResult: ( cell: IManituCell, action: IManituActionType, result: IDoActionReturn ) => void
}
const Row = (props: IRowProps): JSX.Element => {
    return <div className="row p-0 m-0"  >
    {
        props.item.cells.map((cell, ii) => {
            return <Cell 
                item={ cell } 
                key={cell.id} 
                mode={props.mode} 
                actions={props.actions.filter( action => action.cellOrder === ii )}
                organoids={ props.organoids }
                onActionResult={props.onActionResult}
            />
        })
    }
    </div>
} 

export default Row