


/* Protopia Ecosystem component */
export interface IProjectMobileProps {

}
const ProjectMobile = (props: IProjectMobileProps): JSX.Element => {
    return <div className='pe-project-mobile-container'>
        
    </div>
}
export default ProjectMobile