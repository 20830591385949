import useScreenSize from "src/modules/pe-basic-module/hooks/useScreenSize"
import { WINDOW_WIDTH } from "src/libs/interfaces/layouts"
import ManaMonitorScreen from "./manaMonitor/ManaMonitorScreen"
import ManaMonitorTablet from "./manaMonitor/ManaMonitorTablet"
import { IState, IUser, useMainStore } from "src/settings/zustand"

const ManaMonitorWidget = () : JSX.Element => {
    const {width} = useScreenSize()
    const user: IUser = useMainStore((state: IState) => state.user)
    if(!user.id) {
        return <></>
    }
    if( width < WINDOW_WIDTH.TABLET) {
        return <ManaMonitorTablet />
    }
    else {
        return <ManaMonitorScreen />
    }
} 

export default ManaMonitorWidget
