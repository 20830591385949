import { Button, ButtonGroup, Intent } from "@blueprintjs/core"
import { useNavigate } from "react-router"
import { useStorytellingStore } from "src/modules/pe-fabula-module/data/store"
import { clearStory } from "src/modules/pe-fabula-module/hooks/story"
import { __ } from "src/libs/utilities"
import { component_url } from "src/libs/utilities/links"

/* Protopia Ecosystem component */
export interface IFabulaBreakFormProps {

}
const FabulaBreakForm = (props: IFabulaBreakFormProps): JSX.Element => {
    const navigate = useNavigate()
    const onClose = () => {
        useStorytellingStore.setState({ isBreakOpen: false })
    }
    const onBreak = () => { 
        clearStory()
        navigate( component_url("FabulaView") )
    }
    return <div className="p-4">
        <div className="pb-4 text-center display-6 text-light">
            {__("Are you want break and leave quest?")}
        </div>
        <ButtonGroup fill>
            <Button intent={Intent.DANGER} large onClick={onClose}>
                {__("No, return to quest")}
            </Button>
            <Button intent={Intent.SUCCESS} large onClick={onBreak}>
                {__("Break and leave")}
            </Button>
        </ButtonGroup>
    </div>
}
export default FabulaBreakForm