
import React, { useState } from 'react' 
import { useNavigate } from 'react-router'
import Layouts from "src/libs/layouts" 
import { LayoutIcon } from "@/libs/useful"
import { ID, IMenuItem, IRoutingData } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { ICardRouteProps } from '../../data/interfaces'
import { Button, ButtonGroup, Dialog, Intent } from '@blueprintjs/core'
import EditPageDialog from './EditPageDialog'

const RowRoute = ( props: ICardRouteProps ): JSX.Element => {
    const [data, setData] = useState<IRoutingData>(props.data)
    const [menu, setMenu] = useState(props.menu)
    const [jsonData, setJsonData] = useState<string>(JSON.stringify(props.data))
    const [module, setModule] = useState(props.module) 
    const [isMoveOpen, setMoveOpen] = useState(false)  
    const [isEditOpen, setEditOpen] = useState(false)  
    const [isRemoveRouteOpen, setRemoveRouteOpen] = useState(false)  

    const navigate = useNavigate() 

    const changeHide = () =>
    {
        const _data: IRoutingData = {...data}
        _data.is_hidden = !_data.is_hidden
        setData( _data )
        props.onChangeRoute( _data, jsonData )
    }
    const onGotoTo = () => 
    {
      navigate( "/" + data.route )
    }
    const onMoveToFolderOpen = () =>
    {
        setMoveOpen( !isMoveOpen )
    }
    const moveToFolder = ( evt: React.MouseEvent<HTMLElement>, folderId: ID ) => {
        props.moveToFolder( props.data, folderId )
    }
    const onEditOpen = () =>
    {
        setEditOpen( !isEditOpen )
    }
    const onRemoveRouteOpen = () =>
    {
        setRemoveRouteOpen( !isRemoveRouteOpen )
    }
    const onRemoveRoute =() =>
    {
        setRemoveRouteOpen( false )
        props.onRemoveRoute( data )
    }
    const onField = (value: any, field: string) =>
    {
        console.log( value, field )
        let _data = { ...data }
        _data[ field ] = value
        if(field === "component")
        {
            const _module = Layouts().views[ value ]?.module 
            _data.module = _module
            setModule(Layouts().modules[_module])
        }
        setData( _data )
        console.log( _data )
        
    }
    const onSaveRote = () =>
    {
        props.onChangeRoute( data, jsonData )
        setEditOpen( false )
    }
    const onPageTypeField = (value: string) =>
    { 
        let _data = {...data}
        delete _data.component
        delete _data.html
        _data[value] = ""
        setData( _data )
    }

    return <div className='col-12'>
        <div className='card row flex-row my-1'>
            <div className=" col-md-4 d-flex  align-items-center p-3">
                <LayoutIcon
                    isSVG
                    src={ data.icon }
                    className="mr-4"
                />
                <h4 className="card-title m-0">
                    {data.title}
                </h4>
            </div>
            <div className=" col-md-4 d-flex  align-items-center p-3"> 
                <div className="small m-0">
                    { `${ window.location.origin }/${ data.route }` }
                </div>
            </div>
            <div className='col-md-4 d-flex align-items-center p-3'>
                <ButtonGroup vertical={false} minimal className='ml-auto'>
                    <Button 
                        className=" " 
                        rightIcon="double-chevron-right"
                        onClick={onGotoTo}
                    >
                        { __( "Go to" ) }
                    </Button> 
                    
                    <Button 
                        rightIcon="cog" 
                        className=" hint-- hint--top "
                        data-hint={__("Setting")}
                        onClick={onEditOpen}
                    >
                        {__("Setting")}
                    </Button>
                    <Button 
                        rightIcon={ data.hidden ? "eye-off" : "eye-open" }
                        className=" hint hint--top  hidden"
                        data-hint={__("Change visible")}
                        onClick={changeHide}
                    />
                    <Button 
                        rightIcon="pivot" 
                        className=" hint hint--top  hidden"
                        data-hint={__("Change route")}
                    />
                    <Button 
                        rightIcon="folder-shared" 
                        className=" hint hint--top hidden"
                        data-hint={__("Move to folder")}
                        onClick={ onMoveToFolderOpen }
                    />                            
                    <Button 
                        rightIcon="trash" 
                        className=" hint hint--top "
                        data-hint={__("Remove")}
                        onClick={onRemoveRouteOpen}
                    />
                </ButtonGroup>
            </div>
        </div>
        <Dialog
            isOpen={isMoveOpen}
            className="little"
            title={__("Move page to:")}
            onClose={onMoveToFolderOpen}
        >
            <div className="p-0">
            {
                [{ title:__("...to root"), id: null }, ...Layouts().folders?.folders].map(folder => {
                    return <Button 
                        fill 
                        minimal
                        large 
                        key={folder.id}
                        onClick={( evt:React.MouseEvent<HTMLElement> ) => moveToFolder(evt, folder.id) }
                    >
                        { folder.title}
                    </Button>
                })
            }
            </div>
        </Dialog>
        <Dialog
            isOpen={isRemoveRouteOpen}
            className="little"
            title={__("Remove page?")}
            onClose={onRemoveRouteOpen}
        >
            <div className="p-5">
                <div className="pb-4">
                    {__("Realy remove page?")}
                </div>
                <ButtonGroup >
                    <Button intent={Intent.SUCCESS} onClick={() => onRemoveRoute() }>
                        {__("Yes")}
                    </Button>
                    <Button intent={Intent.DANGER} onClick={onRemoveRouteOpen}>
                        {__("Yes")}
                    </Button>
                </ButtonGroup>
            </div>
        </Dialog>
        <EditPageDialog
             data={data} 
             menu={ menu }
             onEditOpen={onEditOpen}
             onField={ ( value: any, field: string ) => onField( value, field )}
             onPageTypeField={onPageTypeField}
             onSaveRote={onSaveRote}
             isEditOpen={isEditOpen}
             setEditOpen={setEditOpen}
             jsonData={jsonData}
             dialogTitle="Page Settings"
             saveButtonTitle="Save"
        />
    </div> 
} 

export default RowRoute