import { Route, Routes, useNavigate } from "react-router"
import Story from "./map/Story"
import Fabula from "./Fabula"
import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { GET_FABULA_ACTIONS } from "../../data/actionTypes"
import { Loading } from "src/libs/useful"
import StoryCover from "./map/StoryCover"
import { IPEStory, PE_STORY_PHASE } from "../../data/interfaces"
import { IStorytellingStore, useStorytellingStore } from "../../data/store" 
import StoryBreak from "./map/StoryBreak"
import { component_url } from "src/libs/utilities/links"
import { IState, IUser, useMainStore } from "src/settings/zustand"
import StoryIntro from "./story/StoryIntro"
import StoryQuest from "./story/StoryQuest"

/* Protopia Ecosystem component */
export interface ITaleFormProps {

}
const TaleForm = (props: ITaleFormProps): JSX.Element => { 
    const user: IUser = useMainStore((state: IState) => state.user)
    const PeStory : IPEStory = useStorytellingStore((state: IStorytellingStore) => state.current)
    const navigate = useNavigate()   
    const [isLoading, setIsLoading] = useState(true) 
    useEffect(() => { 
        if(PeStory.id) {
            setIsLoading(false)
            const link = PeStory.phase === PE_STORY_PHASE.START ? `play` : `intro`
            if(user.id) {
                useStorytellingStore.setState({ isBreakOpen: false })
                navigate(`story/${ PeStory.id }/${link}/`);
            }
            
        }
        else {
            actions(
                GET_FABULA_ACTIONS, 
                { id:"-1" }
            )
            .then( () => setIsLoading(false) ) 
        } 
        return () => {
            // clearFabula()
        } 
    }, []) 
    if( isLoading ) {
        return <div className="layout-state">
            <Loading />
        </div>
    }
    return <div className='pe-taleform-container menu-row ' >
        <Routes>
            <Route path="/" element={<Fabula />} />
            <Route path="story/:story_id" element={<StoryCover />} />
            <Route path="story/:story_id/intro" element={<StoryIntro isMainIntro onFinishPlay = {() => {}} />} />
            <Route path="story/:story_id/quest" element={<StoryQuest isMainIntro onFinishPlay = {() => {}} />} />
            <Route path="story/:story_id/play/*" element={<Story />} />
            <Route path="story/:story_id/play/break" element={<StoryBreak />} />
        </Routes>
    </div>
}
export default TaleForm
