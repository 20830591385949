import { ID, IMenuItem, IRoutingData } from "@/libs/interfaces/layouts"
import { routing } from "./routing"
import { useAdminMenuStore } from "../store"

 
export const changeRoute = ( routeId: ID, routeData: IMenuItem ) => {
    const handler : (item: IMenuItem, data: IMenuItem) => IMenuItem = (item: IMenuItem, data: any ) => {
        return {...data}
    }
    updateRoutings(
        routeId,
        routeData,
        handler
    )
}
export const deleteRoute = ( routeId: ID, routeData: IMenuItem ) => { 
    updateRoutings(
        routeId,
        routeData,
        removeParent
    )
}
export const addRoute = ( parentRouteId: ID, routeData: IMenuItem ) => {
    const handler : (parent: IMenuItem, data: IMenuItem) => IMenuItem = (item: IMenuItem, data: any )=> {
         return item
    }
    updateRoutings(
        parentRouteId,
        routeData,
        handler
    )
}

const updateRoutings = ( 
    routeId: ID, 
    routeData: IMenuItem, 
    handler: (item: IMenuItem, data: any) => IMenuItem 
) => {
    const rt: any = routing()
    Object.keys( rt ).forEach(( key:string ) => {
        rt[key]  =  rt[key].map((r: IMenuItem) => {
            return  updateRoutingItem(routeId, routeData, r, handler) 
        }) 
    }) 
    const layouts: any = useAdminMenuStore.getState().layoutsClone
    useAdminMenuStore.setState({ layoutsClone: {...layouts, routing: rt} })
}
 

const updateRoutingItem = ( 
    routeId: ID, 
    routeData: IMenuItem, 
    item: IMenuItem, 
    handler: (item: IMenuItem, data: any) => IMenuItem 
) : IMenuItem => {
    if( item.id === routeId) { 
        //item = {...routeData} 
        item = handler(item, routeData)
        return item
    }
    if( Array.isArray( item.children )) {
        item.children = item.children.map((child: IMenuItem ) => {
            return updateRoutingItem(routeId, routeData, child, handler ) 
        })
    }
    return item
}

export const changeRoutings = ( items: IMenuItem[], currentMenu: string ) => {
    const layouts: any = useAdminMenuStore.getState().layoutsClone
    const routings = { ...layouts.routing }
    routings[currentMenu] = items 
    useAdminMenuStore.setState({ 
        layoutsClone: {
            ...layouts, 
            routings 
        } 
    })
}

/*
    @element:   find to delete element. Have id
    @arr:       nested array of elements for search
    return:     new array without @element
*/
export const removeParent = ( element: IMenuItem, arr: any ) : any => {
    //let _arr: any[] = arr.map(e => {
        console.log(arr)
        return rParent(element, arr, arr)
    //}) 
    //return _arr
}

const rParent = (element: any, e: any, arr: any): any => {
    if(Array.isArray(e.children)) {
        return e.children.map( ( child: any ) => {
            return rParent( element, child, e.children as any[] )
        }) 
    }
    if(e.id  === element.id) {
        console.log(arr , e)
        return arr.children?.filter((a: any) => a.id === element.id)
    }
}