import { default_menu, routing } from "@/libs/layouts" 
import { Icon } from "@blueprintjs/core"
import { SyntheticEvent } from "react"
import { IAdminMenuStore, useAdminMenuStore } from "../../data/store"

interface IProps {
    currentMenuGroup: string
    onSelectMenuGroup : (area: string) => void
}
const SelectMenuGroup = ({currentMenuGroup, ...props}: IProps) : JSX.Element => {
    //const currentMenuGroup: string = useAdminMenuStore( ( state: IAdminMenuStore ) => state.currentMenuGroup )
    const onChoose = (evt: SyntheticEvent) => {
        const area: string = evt.currentTarget.getAttribute( "area-name" )|| "" 
        //useAdminMenuStore.setState({ currentMenuGroup: area })
        props.onSelectMenuGroup(area)
    }
    return <div>  
        <div >
            <div className=" s-menu-cont">
                <div 
                    className={`s- s-header ${ currentMenuGroup === "main_menu" ? "active" : "" }`} 
                    area-name="main_menu"
                    onClick={onChoose}
                /> 
                <div 
                    className={`s- s-header-profile  ${ currentMenuGroup === "profile" ? "active" : "" }`} 
                    area-name="profile"
                    onClick={onChoose}
                    
                />
                <div 
                    className={`s- s-main-menu  ${ currentMenuGroup === "menu" ? "active" : "" }`} 
                    area-name="menu"
                    onClick={onChoose}

                />
                <div 
                    className={` s-main d-flex flex-column `}  
                    
                >
                    <div 
                        className={`s- w-100 h-100   ${ currentMenuGroup === "extended_routes" ? "active" : "" }`} 
                        area-name="extended_routes"
                        onClick={onChoose} 
                    >
                    </div>
                    {
                        Object.keys( routing() ).filter(menu => {
                            return Object.keys(default_menu()).filter(def => def === menu).length === 0
                        })
                        .map(menu => {
                            return <div
                                key={menu} 
                                className={`s- p-2 w-100 mt-1  ${ currentMenuGroup === menu ? "active" : "" }`} 
                                area-name={menu}
                                onClick={onChoose}
                            >
                                <Icon icon="menu" /> <b>{menu}</b>  
                            </div>
                        })
                    }                        
                </div>
                <div 
                    className={`s- s-footer  ${ currentMenuGroup === "footer" ? "active" : "" }`} 
                    area-name="footer"
                    onClick={onChoose} 
                />
            </div>   
        </div>            
        <div className=" col-md-6 col-12">
            {/* <CurrentMenuWidget
                menu={  currentMenuGroup }
            /> */}
        </div>
    </div>
} 
export default SelectMenuGroup
