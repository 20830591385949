
import { getSingleRoute } from '@/libs/layouts'
import Moment from 'react-moment'
import { NavLink } from 'react-router-dom'
import { IDiary } from '../../data/interfaces'
import { IPost } from '@/libs/interfaces/layouts'

interface IProps {
    item : IDiary
}
const DiaryPost = (props: IProps) : JSX.Element => {
    const {item} = props
    const content: string =  item.post_content.split(" ").filter((p, i) => i < 40 ).join( " " )
    return (
        <div className="row">
            <div className="col-12">
                <div className="diary_post d-flex mt-2">
                    <NavLink 
                        to={ `${getSingleRoute( "PEFestDiary" )?.route || "" }/${item.id}` }
                        className="diary_thumbnail hover"
                        style={{backgroundImage:`url(${item.thumbnail})`}}
                    />
                    <div className='d-flex flex-column mt-auto'>
                        <NavLink 
                            to={ `${getSingleRoute( "PEFestDiary" )?.route || "" }/${item.id}` }
                            className="diary_title text-light"
                        >
                            {item.title}
                        </NavLink>
                        <div
                            className="diary_body"
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                        <div className="diary_footer">
                            <NavLink
                                to={ `${getSingleRoute( "PEFestProject" )?.route || "" }/${item.project?.id}` }
                                className="diary-link light small "
                            >
                                <i className="fas fa-folder mr-3" style={{ opacity: 0.5 }} />
                                {item.project?.title}
                            </NavLink>
                            <div className='small mt-1'>
                                <span>
                                    <i className="fas fa-clock mr-3" style={{ opacity: 0.5 }} />
                                    <Moment  locale="ru" format="D MMMM YYYY года" >
                                        { Number(item.post_date) * 1000 }
                                    </Moment>
                                    
                                </span>
                                <span>
                                    <i className="fas fa-user mr-3" style={{ opacity: 0.5 }} />
                                    {item.post_author?.display_name}
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
} 

export default DiaryPost