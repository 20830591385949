import React, { useEffect, useState }  from "react"
import { NavLink,   } from "react-router-dom" 
import { __ } from "../../utilities" 
import { ID, IMenuItem } from "../../interfaces/layouts" 
import { IState, useMainStore } from "@/settings/zustand"
import { isRouteOpen } from "../utils/isRouteOpen"

const HierarhicalMenu = (props: IMenuItem) => {
	const landId:ID = useMainStore( ( state: IState ) => state.landId )  
    const event_types: string[] =  useMainStore( ( state: IState ) => state.event_types ) || []
    const [isOpen, setIsOpen] = useState<boolean>(isRouteOpen(props, landId, event_types))
	useEffect(() => {
        setIsOpen( isRouteOpen(props, landId, event_types) )
    }, [ props, landId, event_types ])

	const myRoute = `${props.parent_route}/${props.route}`
	const parents = props.children && props.children.length > 0 
		? 
		props.children.map((e:any, i: number) => (
				<HierarhicalMenu
					level={props.level + 1}
					parent_route={myRoute}
					{...e} 
					i={i}
					key={i}
				/>
			))
			:		
			null
	const btn = props.is_hidden
		?			
		null
		:
		<NavLink
			to={myRoute} 
			className={`layout-left-btn ${ isOpen ? "opened" : "closed" }`}  
		>
			<div
				className={ `layout-menu-left-label` }
				style={{ paddingLeft: props.level * 20, }}
			>
				{ __(props.title) }
			</div>
		</NavLink>
				
	return (
		<div
			className={`layout-left-group ${props.route}`}
		>
			{btn}
			{parents}
		</div>
	)
}
 

export default HierarhicalMenu

// class HierarhicalMenu extends Component<any> {
// 	state = {
// 	  current: this.props.current,
// 	  hover: false,
// 	}

// 	componentWillReceiveProps(nextProps) {
// 	  this.setState({
// 	    current: nextProps.current,
// 	  })
// 	}

// 	render() {
// 	  const {
// 	    children, parent_route, route, title,  level, razdel,
// 	  } = this.props
// 	  const myRoute = `${parent_route}/${route}`
// 	  const parents = children && children.length > 0 ? children.map((e:any, i: number) => (
// 		<HierarhicalMenu
// 			level={level + 1}
// 			parent_route={myRoute}
// 			{...e}
// 			razdel={razdel}
// 			i={i}
// 			key={i}
// 		/>
// 	  ))
// 	    :		null
// 	  const btn = this.props.is_hidden
// 	    ?			null
// 	    :			(
// 		<NavLink
// 			to={myRoute} 
// 			className="layout-left-btn "
// 			activeClassName="active"
// 			i={this.props.current}
// 		>
// 			<div
// 				className={`layout-menu-left-label ${this.state.hover ? "hover" : null}`}
// 				style={{ paddingLeft: level * 20, }}
// 			>
// 				{ __(title) }
// 			</div>

// 		</NavLink>
// 	    )
// 	  return (
// 		<div
// 			className={`layout-left-group ${route}`}
// 		>
// 			{btn}
// 			{parents}
// 		</div>
// 	  )
// 	}

// 	getRoutes() {
// 	  return this.props.razdel ? this.props.razdel : []
// 	}

// 	insertRoute(level = 1, n = 0) {
// 	  //const urls = this.getRoutes()
// 	  return `${this.props.parent_route}/${this.props.route}`
// 	  /*
// 		let route = "";
// 		for(let i = 0; i < level ; i++)
// 		{
// 			console.log( urls );
// 			if( urls[i] )
// 			{
// 				//route = urls[i] ? route + "/" + urls[i].route : route;
// 				route = route + "/" + urls[i].route;
// 			}
// 			else
// 			{
// 				route = route;
// 			}
// 		}
// 		//console.log( route );
// 		return route;
// 		*/
// 	}

// 	getFirstRoute() {
// 	  const url = this.getRoutes()[1]
// 	  return url || ""
// 	}

// 	getParent() {
// 	  const rts = this.getFirstRoute()
// 	  // console.log(rts);

// 	  let routing: any[] = []
// 	  routing = concatRouting()

// 	  return routing.filter((e) => e.route === rts)
// 	}

// 	getGrandChildren(chldrn) {
// 	  if (!chldrn) return false
// 	  // console.log(chldrn);
// 	  if (chldrn.children && chldrn.children.length > 0) {
// 	    return chldrn.children
// 	  }
// 	  return false
// 	}

// 	getChildren() {
// 	  const chldrn = this.getParent()
// 	  if (chldrn.length > 0) {
// 	    // console.log( chldrn[0].children );
// 	    if (chldrn[0].children && chldrn[0].children.length > 0) {
// 	      return chldrn[0].children
// 	    }
// 	    return false
// 	  }

// 	  return false
// 	}
// }
// export default withRouter(HierarhicalMenu)
